import type { FC } from 'react';

import type { BreakPatternProps } from './breakPatternTypes';

export const HeadFlippedBreak: FC<BreakPatternProps> = props => {
  const { topColor, bottomColor, ...rest } = props;
  return (
    <svg
      width="1440"
      height="96"
      viewBox="0 0 1440 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      role="presentation"
      {...rest}
    >
      <path
        d="M719.383 80C470.108 80 228.827 51.9727 0 0H1440C1210.8 52.1273 969.104 80 719.383 80Z"
        fill={topColor}
      />
      <path
        d="M0 96V0H1C228.5 52 469.726 79 719.44 79C969.155 79 1211 52 1439 0H1440V96H0Z"
        fill={bottomColor}
      />
    </svg>
  );
};
