import { useApolloClient } from '@apollo/client';
import { parseError } from '@snapchat/core';
import type { Banner } from '@snapchat/mw-contentful-schema';
import type { FC } from 'react';
import { useContext, useRef } from 'react';

import { AppContext } from '../../AppContext';
import { Config } from '../../config';
import { logWarning } from '../../helpers/logging';
import type { ContentfulIdsVariable, ContentfulIdVariable } from '../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../hooks/useContentfulQuery';
import type { ContentfulSysProps } from '../../types/contentful';
import { contentfulTypeQueries } from '../../utils/contentful/contentfulTypeQueries';
import { getQueryName } from '../../utils/contentful/getQueryName';
import type { BlockDataProps, BlockShallowWithContentIds } from '../Block';
import type { HeroDataProps } from '../Hero/types';
import { SlugContext } from '../Slug/SlugContext';
import { Page } from './Page';
import type { PageShallowDataProps } from './pageQuery';
import { pageAllQuery, pageBlocksCollectionQuery } from './pageQuery';
import type { PageDataHandlerProps } from './types';

export interface PageBlocksCollectionData {
  entryCollection: {
    items: Array<BlockDataProps | HeroDataProps | Banner>;
  };
}

export const PageShallow: FC<PageShallowDataProps> = props => {
  const { data } = useContentfulQuery<PageDataHandlerProps, ContentfulIdVariable>(pageAllQuery, {
    variables: { id: props.sys.id },
  });
  const prefetchedIds = useRef(new Set<string>());
  const apolloClient = useApolloClient();
  const { currentLocale, getCurrentUrl } = useContext(AppContext);
  const { replacements } = useContext(SlugContext);

  /** Sys IDs of the entries we need to fetch data for since they are replacing something */
  const replacementIds = replacements
    ? props.blocksCollection.items.reduce<string[]>((acc, block) => {
        if (replacements?.[block.sys.id]) {
          acc.push(replacements[block.sys.id]?.sys.id ?? '');
        }

        return acc;
      }, [])
    : [];

  /** Data of the replacement entries. We skip this if there are no replacements */
  const replacementData = useContentfulQuery<PageBlocksCollectionData, ContentfulIdsVariable>(
    pageBlocksCollectionQuery,
    {
      skip: !replacementIds.length,
      variables: {
        ids: replacementIds,
      },
    }
  );

  /** Kick off a single query to contentful to seed the cache for further rendering. */
  function preloadItem(preload: ContentfulSysProps) {
    const query = contentfulTypeQueries[preload.__typename!];

    if (!query) {
      logWarning({
        component: 'PageShallow',
        message: `Cannot preload item "${preload.__typename}". Add it to 'contentfulTypeQueries'`,
        context: {
          type: preload.__typename,
        },
      });

      return;
    }
    const queryId = `${getQueryName(query)}:${preload.sys.id}`;

    if (prefetchedIds.current.has(queryId)) {
      return;
    }

    // Note: we don't await this here. We don't do anything with the return except initial this
    // request sooner rather than later.
    void apolloClient
      .query({
        query,
        context: { currentUrl: getCurrentUrl() },
        variables: { id: preload.sys.id, preview: Config.isPreview, locale: currentLocale },
      })
      .catch(error => {
        logWarning({
          component: 'PageShallow',
          message: `Failed to preload item "${preload.__typename}" due to error: ${
            parseError(error).message
          }`,
          context: {
            type: preload.__typename,
          },
        });
      });
    prefetchedIds.current.add(queryId);
  }

  /** Loops through the ids in the shallow page content and triggers a preload on them. */
  function preloadPageData() {
    for (const shallowBlock of props.blocksCollection.items) {
      // The list of supported types is defined in <Block>
      // TODO: generate list outside of this pageData function so typings are less weird
      if (shallowBlock.__typename === 'Block') {
        let finalBlock = shallowBlock as BlockShallowWithContentIds;

        if (replacements?.[shallowBlock.sys.id]?.sys.id) {
          finalBlock = {
            ...shallowBlock,
            sys: { id: replacements[shallowBlock.sys.id]?.sys.id },
          } as BlockShallowWithContentIds;
        }

        // The block will get loaded as a part of the page, but we can preload the contents.
        for (const blockItem of finalBlock.contentsCollection.items) {
          preloadItem(blockItem);
        }
      } else {
        // TODO: Handle custom components.
        preloadItem(shallowBlock);
      }
    }
  }

  // If we don't have the base page data OR the replacement data hasn't resolved yet
  // we just preload page data
  if (!data || replacementData.loading) {
    preloadPageData();
    return null;
  }

  let finalBanner = data.page.banner;
  let finalBlocksCollection = data.page.blocksCollection;

  // handle the banner swapping here
  if (replacements && data.page.banner && data.page.banner.sys.id in replacements) {
    finalBanner = replacementData.data?.entryCollection.items.find(entry => {
      if (!data.page.banner) return false;
      return entry.sys.id === replacements[data.page.banner.sys.id]?.sys.id;
    }) as Banner | undefined;
  }

  // handle the block swapping here
  if (data.page.blocksCollection && replacements) {
    const blocksCollectionCopy = [...data.page.blocksCollection.items];

    for (let i = 0; i < blocksCollectionCopy.length; i++) {
      const blockId = blocksCollectionCopy[i]?.sys?.id;

      if (blockId && blockId in replacements) {
        const replacement = replacementData.data?.entryCollection.items.find(entry => {
          return entry.sys.id === replacements[blockId]?.sys.id;
        });

        if (replacement === undefined) {
          blocksCollectionCopy.splice(i, 1);
        } else if (replacement && replacement.__typename !== 'Banner') {
          blocksCollectionCopy[i] = replacement as BlockDataProps | HeroDataProps;
        }
      }
    }

    finalBlocksCollection = { items: blocksCollectionCopy };
  }

  return <Page {...data.page} blocksCollection={finalBlocksCollection} banner={finalBanner} />;
};

PageShallow.displayName = 'PageShallow';
