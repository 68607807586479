import { cx } from '@emotion/css';
import type React from 'react';
import { useContext, useRef } from 'react';

import { useDisableScrollOutside } from '../../../hooks/useDisableBodyScroll';
import { ToggleState } from '../../../hooks/useToggleState';
import { MotifComponent } from '../../../motif/motifConstants';
import { dataSetToAttributes, getBackgroundClassName } from '../../../utils';
import { globalLinksClassName, globalNavMobileLocalNavClassName } from '../GlobalHeader.constants';
import { GlobalHeaderContext } from '../GlobalHeaderContext';
import { testIds } from './GlobalNavScreen.constants';
import { globalNavMobileCss, globalNavMobileHeadingCss } from './GlobalNavScreen.styles';
import type { GlobalNavScreenMobileProps } from './types';

export const GlobalNavScreenMobile: React.FC<GlobalNavScreenMobileProps> = ({
  className,
  localNavMobile,
  localNavMobileFooter,
  globalNav,
  globalNavHeading,
  backgroundColor,
  showMobileGlobalLinks: showGlobalLinks,
  dataset,
}) => {
  const navScreenRef = useRef<HTMLElement>(null);
  const { screenState } = useContext(GlobalHeaderContext);
  const inTransition = screenState === (ToggleState.TURNING_OFF || ToggleState.TURNING_ON);
  useDisableScrollOutside(navScreenRef.current, inTransition);

  const LocalNavSection = () => {
    return (
      <section className={globalNavMobileLocalNavClassName}>
        {localNavMobile}
        {localNavMobileFooter && (
          <>
            <hr />
            {localNavMobileFooter}
          </>
        )}
      </section>
    );
  };

  if (!dataset?.testid) {
    dataset = { ...dataset, testid: testIds.navTopLevel } as DOMStringMap;
  }

  return (
    <nav
      ref={navScreenRef}
      className={cx(
        MotifComponent.HEADER,
        globalNavMobileCss,
        getBackgroundClassName(backgroundColor),
        className
      )}
      {...dataSetToAttributes(dataset)}
    >
      {(localNavMobile || localNavMobileFooter) && <LocalNavSection />}
      {showGlobalLinks && (
        <section className={cx(globalLinksClassName, getBackgroundClassName(backgroundColor))}>
          <label className={globalNavMobileHeadingCss}>{globalNavHeading}</label>
          {globalNav}
        </section>
      )}
    </nav>
  );
};

GlobalNavScreenMobile.displayName = 'GlobalNavScreenMobile';
