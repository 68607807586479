// Dependencies
import { Axis } from '@visx/xychart';
import type { FC } from 'react';

// Utils
import { tickFormatter } from '../utils';
// Styles
import { leftAxisCss } from './styles';

interface IDefaultAxis {
  xLabel?: string;
  yLabel?: string;
}

export const DefaultAxis: FC<IDefaultAxis> = ({ xLabel, yLabel }) => {
  return (
    <>
      <Axis
        orientation="bottom"
        tickFormat={value => tickFormatter(value)}
        hideAxisLine
        label={xLabel}
      />
      <Axis
        axisClassName={leftAxisCss}
        orientation="left"
        tickFormat={value => tickFormatter(value)}
        label={yLabel}
      />
    </>
  );
};
