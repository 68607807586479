import { css, cx } from '@emotion/css';
import type { FC, MouseEventHandler, ReactNode } from 'react';
import { useCallback, useContext } from 'react';

import { MotifComponent, useMotifStyles } from '../../motif';
import type { BaseComponentProps, ImageSources } from '../../types';
import type { OnNavigateHandler } from '../../types/activationEvents';
import { Media } from '../Media';
import { Picture } from '../Picture';
import { PrimitivesContext } from '../Primitives';
import {
  imageHeightForImageSize,
  imgHeightWithIcon,
  labelLineHeight,
  titleHeightForImageSize,
} from './constants';
import {
  anchorCss,
  containCss,
  coverCss,
  innerTitleCss,
  innerTitleWithUserIconCss,
  pointerCss,
  tileCss,
  tileImageClassName,
  tileImageWithUserIconClassName,
  tileLabelCss,
  tileLabelWithUserIconCss,
  tileNoBgImgCss,
  tilePictureCss,
  tileTitleCss,
  tileTitleWithUserIconCss,
  tileUserIconCss,
  tileVideoWithUserIconClassName,
  tileWithUserIconCss,
} from './Tile.styled';
import { TileImageFit, TileImageSize } from './types';
import { tileTitleHeight, tileTitleLines } from './utils';

export interface TileProps extends BaseComponentProps {
  /** @deprecated - Use `onClick` instead. Links open in new window if Super is pressed. */
  onSuperClick?: OnNavigateHandler;
  /** @deprecated - Does nothing */
  bgImgClassName?: string;

  label?: ReactNode;
  title?: ReactNode;
  onClick?: OnNavigateHandler;
  link?: string;

  /** If the media is a video, source goes here. */
  videoSource?: string;

  imageSource?: string;
  imgSrcs?: ImageSources;

  imgAltText?: string;
  imagePadding?: boolean;
  imageSize?: TileImageSize;
  imageFit?: TileImageFit;

  iconImageSource?: string;

  /** If true, adds width and height to the container. */
  tilesLayout?: boolean;

  isRTL?: boolean;

  iconImgSrcs?: ImageSources;
  iconAltText?: string;
}

export const Tile: FC<TileProps> = ({
  className,
  title,
  imageSource,
  videoSource,
  iconImageSource,
  label,
  onClick,
  imageSize = TileImageSize.Large,
  imageFit = TileImageFit.Cover,
  imagePadding = false,
  link,
  tilesLayout,
  isRTL,
  iconImgSrcs,
  iconAltText,
  imgSrcs,
  imgAltText,
}) => {
  useMotifStyles(MotifComponent.TILE);
  const { Anchor } = useContext(PrimitivesContext);

  // Have to use 'undefined' for LTR to make it dynamic (discard dir attr). Only need override for RTL.
  const dir = isRTL ? 'rtl' : undefined;

  const onClickWrapped = useCallback<MouseEventHandler>(
    event => {
      link && onClick && onClick(link, event);
    },
    [link, onClick]
  );

  const imageHeight = imageHeightForImageSize[imageSize];

  const useTileImageClassName = tileImageClassName({
    imageSource,
    imageFit,
    imageHeight,
    imagePadding,
  });

  const useTileImageWithUserIconClassName = tileImageWithUserIconClassName({
    imageSource,
    imageFit,
    imageHeight: imgHeightWithIcon,
    imagePadding,
  });

  const useTileVideoWithUserIconClassName = tileVideoWithUserIconClassName({
    imageFit,
    imageHeight: imgHeightWithIcon,
    imagePadding,
  });

  const defaultInner = (
    <div
      data-test-id="sdsm-tile-default-inner"
      className={cx(
        MotifComponent.TILE,
        {
          [pointerCss]: !!onClick,
        },
        tileCss,
        className
      )}
      onClick={link ? undefined : onClickWrapped}
      dir={dir}
    >
      {(imageSource || imgSrcs) && (
        <div
          data-test-id="sdsm-tile-image"
          className={cx(useTileImageClassName, imgSrcs ? tileNoBgImgCss : undefined)}
        >
          {imgSrcs && (
            <Picture
              altText={imgAltText}
              imgSrcs={imgSrcs}
              imgClassName={cx(
                tilePictureCss,
                imageFit === TileImageFit.Cover ? coverCss : containCss
              )}
            />
          )}
        </div>
      )}
      {(title || label) && (
        <div
          data-test-id="sdsm-tile-text"
          className={cx(
            tileTitleCss,
            css`
              height: ${titleHeightForImageSize[imageSize] + labelLineHeight}px;
            `
          )}
        >
          {label && <div className={tileLabelCss}>{label}</div>}
          <div
            className={innerTitleCss}
            style={{
              WebkitLineClamp: tileTitleLines({ imageSize, label }),
              maxHeight: tileTitleHeight({ imageSize, label }),
            }}
          >
            {title}
          </div>
        </div>
      )}
    </div>
  );

  const tilesInner = (
    <div
      data-test-id="sdsm-tile-alternate-inner"
      className={cx(MotifComponent.TILE, tileWithUserIconCss, className)}
      onClick={link ? undefined : onClickWrapped}
      dir={dir}
    >
      {(imageSource || imgSrcs) && (
        <div
          data-test-id="sdsm-tile-alternate-image"
          className={cx(useTileImageWithUserIconClassName, imgSrcs ? tileNoBgImgCss : undefined)}
        >
          {imgSrcs && (
            <Picture
              altText={imgAltText}
              imgSrcs={imgSrcs}
              imgClassName={cx(
                tilePictureCss,
                imageFit === TileImageFit.Cover ? coverCss : containCss
              )}
            />
          )}
        </div>
      )}

      {!imageSource && videoSource && (
        <div data-test-id="sdsm-tile-alternate-video" className={useTileVideoWithUserIconClassName}>
          <Media
            showVideoControls={false}
            videoSource={videoSource}
            maxHeight={imgHeightWithIcon}
            isBackgroundVideo
          />
        </div>
      )}

      {(iconImageSource || iconImgSrcs) && (
        <Picture
          altText={iconAltText}
          imgSrcs={iconImgSrcs}
          imgClassName={tileUserIconCss}
          defaultSrc={iconImageSource}
        />
      )}

      {(title || label) && (
        <div data-test-id="sdsm-tile-alternate-text" className={tileTitleWithUserIconCss}>
          {title && <div className={innerTitleWithUserIconCss}>{title}</div>}
          {label && <div className={tileLabelWithUserIconCss}>{label}</div>}
        </div>
      )}
    </div>
  );

  if (link) {
    return (
      <Anchor
        data-test-id="sdsm-tile-click-handler"
        href={link}
        className={anchorCss}
        onClick={onClickWrapped}
      >
        {tilesLayout ? tilesInner : defaultInner}
      </Anchor>
    );
  }

  return tilesLayout ? tilesInner : defaultInner;
};

Tile.displayName = 'Tile';
