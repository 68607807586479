import { gql } from '@apollo/client';

import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';
import { buttonFragment } from '../Button';

export const fragments = {
  all: gql`
    fragment TabsAll on Tabs {
      ...ContentfulSysId
      itemsCollection(limit: 10) {
        items {
          ...ContentfulSysId
          title
          button {
            ...ButtonAll
          }
          contentsCollection(limit: 10) {
            items {
              ...ContentfulSysId
            }
          }
          maxColumns
        }
      }
    }
    ${buttonFragment}
    ${contentfulSysIdFragment}
  `,
};

export const queries = {
  shallow: gql`
    query TabsShallowQuery($preview: Boolean!, $locale: String!, $id: String!) {
      tabs(preview: $preview, locale: $locale, id: $id) {
        ...ContentfulSysId
      }
    }
    ${contentfulSysIdFragment}
  `,
  all: gql`
    query TabsQuery($preview: Boolean!, $locale: String!, $id: String!) {
      tabs(preview: $preview, locale: $locale, id: $id) {
        ...TabsAll
      }
    }
    ${fragments.all}
  `,
};
