import type { NavigatorCallsToActionItem } from '@snapchat/mw-contentful-schema';
import { ButtonType, Size, useIsMobile } from '@snapchat/snap-design-system-marketing';
import cloneDeep from 'lodash-es/cloneDeep';
import type { FC } from 'react';

import { CallToAction } from '../CallToAction';
import { DisplayMode } from '../CallToAction/types';
import { NavigationCta } from '../NavigationCta';

export enum DisplayRegion {
  Header = 'Header',
  Screen = 'Screen',
}

interface GlobalHeaderCtaProps {
  // TODO: This should be passed in through the context.
  displayRegion?: DisplayRegion;
  callsToAction?: Array<NavigatorCallsToActionItem>;
}

export const GlobalHeaderCta: FC<GlobalHeaderCtaProps> = ({
  displayRegion = DisplayRegion.Header,
  callsToAction,
}) => {
  const isMobile = useIsMobile();

  if (!callsToAction) {
    return null;
  }

  let displayMode = DisplayMode.DesktopHeader;

  if (isMobile && displayRegion === DisplayRegion.Header) {
    displayMode = DisplayMode.MobileHeader;
  }

  if (isMobile && displayRegion === DisplayRegion.Screen) {
    displayMode = DisplayMode.MobileScreen;
  }

  const navigationCta = callsToAction.map(cta => {
    switch (cta.__typename) {
      // Are there any setups that dont use this NavCta?
      case 'NavigationCallToAction': {
        const ctaProps = cloneDeep(cta);
        const buttonProps = ctaProps.callToAction?.presentation;

        // if desktop or mobile screeen, use compact buttons
        if (!isMobile || displayRegion === DisplayRegion.Screen) {
          buttonProps && (buttonProps.size = Size.Compact);
        } else {
          // for mobile header still use flat
          buttonProps && (buttonProps.buttonType = ButtonType.Flat);
        }

        return <NavigationCta key={cta.sys.id} {...ctaProps} displayMode={displayMode} />;
      }

      case 'CallToAction': {
        const ctaProps = cloneDeep(cta);
        const buttonProps = ctaProps.presentation;

        // if desktop or mobile screeen, use compact buttons
        if (!isMobile || displayRegion === DisplayRegion.Screen) {
          buttonProps && (buttonProps.size = Size.Compact);
        } else {
          // for mobile header still use flat
          buttonProps && (buttonProps.buttonType = ButtonType.Flat);
        }

        return <CallToAction key={cta.sys.id} {...ctaProps} />;
      }

      default: {
        throw new Error(`Unknown navigation CTA ${cta.__typename}`);
      }
    }
  });

  return <>{navigationCta}</>;
};
