import { gql } from '@apollo/client';
import type { Experiment, PageAsset } from '@snapchat/mw-contentful-schema';
import type { BackgroundColor } from '@snapchat/snap-design-system-marketing';

import type { ContentfulSysProps, ContentfulTypedSysProps } from '../../types/contentful';
import type { Items } from '../../types/Items';
import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';
import { createExperimentFragmentNoAb } from '../Experiment/experimentQuery';
import { pageAssetFragment } from '../Page/pageAssetQuery';

// =================================================================================================
// Page Category Title Fragment
// =================================================================================================

const pageCategoryTitleFragment = gql`
  fragment PageCategoryTitle on Page {
    ...ContentfulSysId
    title
    category {
      ...ContentfulSysId
      title
    }
  }
  ${contentfulSysIdFragment}
`;

export interface PageCategoryTitle extends ContentfulTypedSysProps<'Page'> {
  title: string;
  category: {
    title: string;
  };
}

// =================================================================================================
// Latest post block query
// =================================================================================================

export const latestPostBlockQuery = gql`
  query LatestPostBlockQuery($preview: Boolean!, $locale: String!, $id: String!) {
    latestPosts(preview: $preview, locale: $locale, id: $id) {
      ...ContentfulSysId
      title
      maxPosts
      backgroundColor
      cta {
        ...ContentfulSysId
      }
      anchorId
    }
    slugCollection(
      preview: $preview
      locale: $locale
      limit: 4
      order: postedDate_DESC
      where: { AND: [{ slugType_contains: "Blog" }, { slug_not: "home" }] }
    ) {
      items {
        sys {
          id
          firstPublishedAt
        }
        __typename
        slug
        postedDate
        author {
          ...ContentfulSysId
          name
          position
          slug
        }
        page {
          ...PageAssetAll
          ...ExperimentOnPageLoadingPageCategoryTitle
        }
      }
    }
  }
  ${contentfulSysIdFragment}
  ${createExperimentFragmentNoAb('Page', 'PageCategoryTitle', pageCategoryTitleFragment)}
  ${pageAssetFragment}
`;

export interface LatestPostBlockDataProps extends ContentfulTypedSysProps<'LatestPosts'> {
  backgroundColor: BackgroundColor;
}

export interface LatestPostBlockData {
  latestPosts: {
    title: string;
    maxPosts: number;
    backgroundColor: BackgroundColor;
    cta?: ContentfulSysProps;
    anchorId?: string;
  };
  slugCollection: Items<{
    __typename: 'Slug';
    sys: {
      id: string;
      firstPublishedAt: string;
    };
    slug: string;
    postedDate?: string;
    author?: {
      name: string;
      position: number;
      slug: string;
    };
    page: PageCategoryTitle | Experiment | PageAsset;
  }>;
}
