import type { FC } from 'react';
import { useContext } from 'react';
import { Helmet } from 'react-helmet-async';

import { AppContext } from '../../AppContext';
import { defaultLocale } from '../../helpers/locale';

interface AlternateLangProps {
  /**
   * Array of locales that a page is localized in. Defaults to en-US in case the field is undefined
   * (this happens if the Page has no titles set yet)
   */
  localizedIn?: string[];
}

/**
 * Adds the locale alternate links.
 *
 * See this doc: https://developers.google.com/search/docs/advanced/crawling/localized-versions
 */
export const AlternateLang: FC<AlternateLangProps> = ({ localizedIn = [defaultLocale] }) => {
  const { getCurrentUrl } = useContext(AppContext);

  const url = new URL(getCurrentUrl());

  // TODO: filter query params to persist only the relevant ones: https://jira.sc-corp.net/browse/WEBP-10759
  url.hash = '';
  url.search = '';

  const nonDefaultLocales = localizedIn.filter(locale => locale !== defaultLocale);

  return (
    <Helmet>
      <link rel="alternate" hrefLang="x-default" href={url.href} />
      <link key={defaultLocale} rel="alternate" hrefLang={defaultLocale} href={url.href} />
      {nonDefaultLocales?.map(locale => {
        url.searchParams.set('lang', locale);
        return <link key={locale} rel="alternate" hrefLang={locale} href={url.href} />;
      })}
    </Helmet>
  );
};
