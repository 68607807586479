import { css, cx } from '@emotion/css';
import type { FC } from 'react';
import { Children, useContext, useEffect, useState } from 'react';

import { MediaMode } from '../../../constants';
import { GlobalHeaderContext } from '../GlobalHeaderContext';
import { reelDesktopCss } from './GlobalNavHighlight.styles';
import type { GlobalNavHighlightReelProps } from './types';

export const GlobalNavHighlightReel: FC<GlobalNavHighlightReelProps> = ({
  className,
  highlights,
}) => {
  const headerContext = useContext(GlobalHeaderContext);
  const isMobile = headerContext.mode === MediaMode.Mobile;

  const [selectedIndex, setSelectedIndex] = useState(0);

  const nonNullHighlights = Object.fromEntries(
    [...highlights.entries()].filter(([_groupKey, highlight]) => !!highlight)
  );

  useEffect(() => {
    const allKeys = Object.keys(nonNullHighlights);
    const index = allKeys.indexOf(headerContext.groupKey ?? '');

    if (index !== -1 && index !== selectedIndex) {
      setSelectedIndex(index);
    }
  }, [headerContext.groupKey, nonNullHighlights, selectedIndex]);

  // Currently we don't show any highlight in the mobile view.
  // This is to be re-implemented once we have a design that works for mobile and does not look
  // like advertising.
  if (isMobile) {
    return null;
  }

  const selectedCss = css`
    --selected-index: ${selectedIndex};
  `;

  return (
    <section className={cx(selectedCss, reelDesktopCss, className)}>
      {Children.toArray(Object.values(nonNullHighlights))}
    </section>
  );
};
