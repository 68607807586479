import type { WatchQueryFetchPolicy } from '@apollo/client';
import type { Context } from 'react';
import { createContext } from 'react';

import { Config } from '../../config';
import { UrlParameter } from '../../constants/urlParameters';
import { defaultLocale } from '../../helpers/locale';

/** Context for the apollo client. */
export const ContentfulContext: Context<ContentfulContextType> = createContext({
  preview: false,
  locale: defaultLocale,
} as ContentfulContextType);
ContentfulContext.displayName = 'ContentfulContext';

export function getContentfulContext(
  locale: string,
  params: URLSearchParams
): ContentfulContextType {
  // Default policy is to check cache, and if not found, ping network.
  let fetchPolicy: WatchQueryFetchPolicy = 'cache-first';

  // On the client, for non-prod builds we allow URL overrides.
  const param = params.get(UrlParameter.CONTENTFUL_FETCH_POLICY) as WatchQueryFetchPolicy;

  if (Config.isClient && !Config.isDeploymentTypeProd && !!param) {
    fetchPolicy = param;
  }

  // On the server in preview mode, we force network load, but store data in
  // cache (using network-only instead of no-cache) so we can write out the cache as SSR output.
  if (!Config.isClient && Config.isPreview) {
    fetchPolicy = 'network-only';
  }

  return {
    preview: Config.isPreview,
    locale,
    fetchPolicy,
  };
}

export interface ContentfulContextType {
  preview: boolean;
  locale: string;
  fetchPolicy?: WatchQueryFetchPolicy;
}
