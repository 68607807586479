import type { FC } from 'react';
import { useEffect, useRef } from 'react';

import { progressIndicatorBarCss, progressIndicatorCss } from './styles';
import type { ProgressIndicatorProps } from './types';

export const ProgressIndicator: FC<ProgressIndicatorProps> = ({
  durationMs,
  isActive,
  isPaused,
  onAnimationComplete,
}) => {
  const barRef = useRef<HTMLDivElement>(null);
  const animation = useRef<Animation>();

  // biome-ignore lint/correctness/useExhaustiveDependencies: This effect does not need to run when the component updates
  useEffect(() => {
    if (!barRef.current) {
      return;
    }

    animation.current = barRef.current.animate(
      [{ transform: 'scaleY(0)' }, { transform: 'scaleY(1)' }],
      {
        duration: durationMs,
        iterations: 1,
        fill: 'forwards',
      }
    );

    animation.current.pause();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!animation.current) return;

    if (isActive) {
      if (isPaused) {
        animation.current.pause();
      } else {
        animation.current.play();

        // Re-apply "onfinish" handler for each play request to ensure it
        // fires, and with the correct "onAnimationComplete" reference
        animation.current.onfinish = () => onAnimationComplete?.();
      }
    } else {
      animation.current.pause();
      animation.current.currentTime = 0;
    }
  }, [isPaused, isActive, onAnimationComplete]);

  return (
    <div className={progressIndicatorCss}>
      <div ref={barRef} className={progressIndicatorBarCss} />
    </div>
  );
};
