import type { TooltipData } from '@visx/xychart';
import { Tooltip } from '@visx/xychart';
import type { FC } from 'react';

import type { LineChartAccessors } from '../types';
import {
  containerCss,
  dataCss,
  dataKeyCss,
  dotCss,
  tooltipBottomCss,
  tooltipCss,
  tooltipTopCss,
} from './styles';

interface IDefaultToolTip<Datum extends object> {
  accessors: LineChartAccessors<Datum, string | number, string | number>;
}

export const DefaultTooltip: FC<IDefaultToolTip<object>> = ({ accessors }) => {
  const getAccessors = (tooltipData: TooltipData<object> | undefined) => {
    const dataKey = tooltipData?.nearestDatum?.key ?? '';
    return {
      xAccessor: accessors.x[dataKey]!,
      yAccessor: accessors.y[dataKey]!,
    };
  };

  return (
    <Tooltip
      offsetTop={-85}
      unstyled
      applyPositionStyle
      className={tooltipCss}
      snapTooltipToDatumX
      snapTooltipToDatumY
      showDatumGlyph
      renderTooltip={({ tooltipData, colorScale }) =>
        tooltipData?.nearestDatum ? (
          <article>
            <section className={tooltipTopCss}>
              {getAccessors(tooltipData).xAccessor(tooltipData.nearestDatum.datum)}
            </section>
            <section className={tooltipBottomCss}>
              <div className={containerCss}>
                <div
                  className={dotCss}
                  style={{ backgroundColor: colorScale?.(tooltipData.nearestDatum.key) }}
                />
                <p className={dataKeyCss}>{tooltipData.nearestDatum.key}</p>
              </div>
              <p className={dataCss}>
                {getAccessors(tooltipData).yAccessor(tooltipData.nearestDatum.datum)}
              </p>
            </section>
          </article>
        ) : null
      }
    />
  );
};
