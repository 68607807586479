/**
 * @deprecated - Use the `--size-**` variables.
 *
 *   Tracking: https://jira.sc-corp.net/browse/ENTWEB-8938
 */
export enum Spacing {
  '3XS' = 2,
  '2XS' = 4,
  XS = 8,
  SM = 12,
  MD = 16,
  LG = 24,
  XL = 32,
  '2XL' = 40,
  '3XL' = 48,
  '4XL' = 64,
}
