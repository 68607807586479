import type { ForBusinessBaseEvent } from '@snapchat/blizzard-schema';
import type { LoggedEvent } from '@snapchat/logging';
import { LoggingEventType } from '@snapchat/logging';
import type { BlizzardBaseEvent } from '@snapchat/logging-browser';
import findLast from 'lodash-es/findLast';
import size from 'lodash-es/size';

import { SubscribedEventType } from '../../eventListenerTypes';
import type { LoggingContext, LoggingCustomEvents } from '../../loggingTypes';
import { formatMarketingWebEvents } from './marketingWeb';

/**
 * Site specific Event Formatting for ForBusiness.snapchat.com
 *
 * Also generates standard MarketingWeb events via `formatMarketingWebEvents`
 *
 * Event Formatting function for ForBusiness.snapchat.com. Wraps the `formatMarketingWebEvents`
 * function and adds site specific events
 *
 * Table: `sc-analytics.prod_analytics_ads_manager`
 */
export const formatForBusinessEvents = (
  event: LoggedEvent<LoggingCustomEvents>,
  context: Partial<LoggingContext>
): BlizzardBaseEvent[] => {
  const url = context.url ?? new URL(window.location.href);
  const uriMetrics = JSON.stringify({
    href: url.href,
    host: url.host,
    path: url.pathname,
    filename: findLast(url.pathname.split('/'), size) ?? '',
    query: Object.fromEntries(url.searchParams.entries()),
  });

  const forBusinessData: ForBusinessBaseEvent = {
    uri_info: uriMetrics,
    experiment_name: context.experimentId,
    experiment_variation: context.variantId,
    page_locale: context.locale,
  };

  const events = formatMarketingWebEvents(event, context);

  switch (event.type) {
    case LoggingEventType.CUSTOM: {
      switch (event.subscribedEventType) {
        case SubscribedEventType.FIRST_PAGE_LOAD: {
          events.push({
            event_name: 'FOR_BUSINESS_PAGE_VIEW',
            ...forBusinessData,
          });
          break;
        }

        case SubscribedEventType.PAGE_LOAD: {
          events.push({
            event_name: 'FOR_BUSINESS_PAGE_NAVIGATION',
            ...forBusinessData,
          });
          break;
        }
      }
      break;
    }

    case LoggingEventType.USER_ACTION: {
      events.push({
        event_name: 'FOR_BUSINESS_CLICK',
        ...forBusinessData,
        event_category: event.component,
        event_action: event.action,
        event_label: event.label,
      });
      break;
    }
  }

  return events;
};
