import type { FC } from 'react';
import { useContext } from 'react';

import { MediaMode } from '../../../constants';
import { GlobalHeaderContext } from '../GlobalHeaderContext';
import { GlobalNavHighlightDesktop } from './GlobalNavHighlightDesktop';
import type { GlobalNavHighlightProps } from './types';

export const GlobalNavHighlight: FC<GlobalNavHighlightProps> = props => {
  const headerContext = useContext(GlobalHeaderContext);
  const isMobile = headerContext.mode === MediaMode.Mobile;
  // only showing highlights on desktop
  return isMobile ? null : <GlobalNavHighlightDesktop {...props} />;
};

GlobalNavHighlight.displayName = 'GlobalNavHighlight';
