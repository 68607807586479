/**
 * Deployment type enum.
 *
 * Used by multiple packages but technically only applies to the [web] package et al.
 */
export /* not-const */ enum DeploymentType {
  /** Latest dev. For dev reference to ensure website functionality. */
  LATEST_DEV = 'latest-dev',

  /** Production instance. Serving live users. */
  PRODUCTION = 'production',

  /** Staging / preview instance. Serving content editors for preview purposes. */
  STAGING = 'staging',
}
