import { userBucketCount } from '../constants/experiments';

/**
 * Simple hash function that turns a string into a number. Roughly based on
 * https://en.wikipedia.org/wiki/Adler-32
 */
export const adler32 = (source: string): number => {
  let alpha = 0;
  let beta = 0;

  for (let i = 0; i < source.length; i++) {
    alpha = (alpha + source.charCodeAt(i)) % userBucketCount;
    beta = (beta + i * source.charCodeAt(i)) % userBucketCount;
  }

  return alpha + beta;
};
