import { css } from '@emotion/css';

import { m } from '../../motif/m';

export const ghostOutlineCss = css`
  fill: ${m('--logo-stroke-color')};
`;

export const ghostCss = css`
  fill: ${m('--logo-fill-color')};
`;

export const snapLogoCss = css`
  fill: ${m('--logo-stroke-color')};
  fill-rule: evenodd;
`;
