import { cx } from '@emotion/css';
import type React from 'react';
import type { FC } from 'react';
import { useContext, useRef } from 'react';

import { dataSetToAttributes } from '../../utils';
import { isExternal } from '../../utils/domains';
import { PrimitivesContext } from '../Primitives';
import { activeLinkCss, linkCss } from './styles';
import type { SideNavigationItemProps, SideNavigationLinksProps } from './types';

export const SideNavigationLinks: FC<SideNavigationLinksProps> = ({
  links,
  isUrlCurrent,
  focusSection,
  isRTL,
}) => {
  const linksRefs = useRef<Array<HTMLAnchorElement | null>>([]);
  const { Anchor } = useContext(PrimitivesContext);

  const handleLinkKeyPress = (idx: number) => (event: React.KeyboardEvent) => {
    // focus next link
    if (event.key === 'ArrowDown') {
      const next: HTMLAnchorElement | null | undefined = linksRefs.current[idx + 1];

      if (next) {
        next.focus();
      }
    }

    // focus previous link
    if (event.key === 'ArrowUp') {
      const prev: HTMLAnchorElement | null | undefined = linksRefs.current[idx - 1];

      if (prev) {
        prev.focus();
      }
    }

    // focus parent section
    if (event.key === (isRTL() ? 'ArrowRight' : 'ArrowLeft')) {
      focusSection();
    }
  };

  const handleOnClick = (url: string) => () => {
    if (isExternal(url)) {
      requestAnimationFrame(() => window.scrollTo(0, 0));
    }
  };

  const createLink = (link: SideNavigationItemProps, id: number) => {
    const linkProps = {
      ref: (element: HTMLAnchorElement) => {
        linksRefs.current[id] = element;
      },
      onKeyDown: handleLinkKeyPress(id),
      onClick: handleOnClick(link.url),
      role: 'menuitem',
    };

    const ariaCurrent = isUrlCurrent?.(link.url) ? 'page' : undefined;

    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <Anchor
        href={link.url}
        target={link.inNewTab ? '_blank' : undefined}
        rel={link.inNewTab ? 'noopener' : undefined}
        {...linkProps}
        aria-current={ariaCurrent}
      >
        {link.title}
      </Anchor>
    );
  };

  return (
    <>
      {links.map((link, idx) => {
        const isCurrent = isUrlCurrent?.(link.url);
        return (
          <li
            key={idx}
            className={cx(linkCss, {
              [activeLinkCss]: isCurrent,
            })}
            {...dataSetToAttributes(link.dataset)}
          >
            {createLink(link, idx)}
          </li>
        );
      })}
    </>
  );
};
