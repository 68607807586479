/** Gets file name from url. Must be asset url or it will not work correctly */
type FileInfo = {
  fileName: string;
  extension: string;
};

export const getFileInfo = (url?: string): FileInfo => {
  if (url) {
    const nameAndExt = url.split('?')[0]!.split('/').pop();

    if (nameAndExt) {
      // just in case file name has "." in it
      const nameAndExtArr = nameAndExt.split('.');
      const extension = nameAndExtArr.slice(-1)[0]!;
      const fileName = nameAndExtArr
        .slice(0, nameAndExtArr.length - 1)
        .join('.')
        .trim();

      return {
        fileName,
        extension,
      };
    }
  }

  return { fileName: '', extension: '' };
};
