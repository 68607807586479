import type { FC } from 'react';

import type { BreakPatternProps } from './breakPatternTypes';

export const HeadBreak: FC<BreakPatternProps> = props => {
  const { topColor, bottomColor, ...rest } = props;
  return (
    <svg
      width="1440"
      height="96"
      viewBox="0 16 1440 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      role="presentation"
      {...rest}
    >
      <path
        d="M0 0V96H1C228.5 44 469.726 17 719.44 17C969.155 17 1211 44 1439 96H1440V0H0Z"
        fill={topColor}
      />
      <path
        d="M719.383 16C470.108 16 228.827 44.0273 0 96H1440C1210.8 43.8727 969.104 16 719.383 16Z"
        fill={bottomColor}
      />
    </svg>
  );
};
