import { Icon } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useContext } from 'react';

import { ShopifyContext } from '../ShopifyProvider';
import { cartIconButtonCss, cartIconCss, cartIconQuantityCss } from './styles';

export const CartIcon: FC = () => {
  const { cart, products } = useContext(ShopifyContext);

  const total = Object.keys(cart.lineItems).reduce((acc, lineItem) => {
    if ((products.extras.bundleItems ?? []).includes(lineItem)) {
      return acc;
    }

    return acc + cart.lineItems[lineItem]!;
  }, 0);

  return (
    <button className={cartIconButtonCss} onClick={() => cart.setCartOpen(true)}>
      {total !== 0 && <span className={cartIconQuantityCss}>{total}</span>}
      <Icon name="cart" size={27} className={cartIconCss} />
    </button>
  );
};
