import type { FC, PropsWithChildren } from 'react';
import { createContext } from 'react';

import {
  Anchor,
  Bold,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Italics,
  ListItem,
  OrderedList,
  Paragraph,
  Superscript,
  Underline,
  UnorderedList,
} from './Primitives';

export const defaultPrimitivesContext = {
  Bold,
  Italics,
  Underline,
  Superscript,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  ListItem,
  UnorderedList,
  OrderedList,
  Paragraph,
  Anchor,
};

/**
 * The context for providing primitive components.
 *
 * This is in a context to allow for overrides, i.e. replacing all list or anchors with a custom
 * implementation.
 *
 * Usage:
 *
 *     <App>
 *       <PrimitiveContext.Provider value={...(defaultPrimitiveContext, Anchor)}>
 *         ...stuff...
 *       </PrimitiveContext.Provider>
 *     </App>;
 */
export const PrimitivesContext = createContext(defaultPrimitivesContext);

/** Helper function to provide a primitives context with overrides only. */
export const PrimitivesProvider: FC<
  PropsWithChildren & {
    value: Partial<typeof defaultPrimitivesContext>;
  }
> = ({ value, children }) => {
  const primitives = { ...defaultPrimitivesContext };

  for (const [component, implementation] of Object.entries(value)) {
    if (!implementation) continue;
    primitives[component as keyof typeof defaultPrimitivesContext] = implementation;
  }

  return <PrimitivesContext.Provider value={primitives}>{children}</PrimitivesContext.Provider>;
};
