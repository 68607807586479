import { Site } from '@snapchat/mw-common';

/** Keys for the dev tokens in contentful by site */
export const contentfulDevTokenKeys: Record<Site, string> = {
  [Site.A523]: 'SNAP_523_CONTENTFUL_DEV_ACCESS_TOKEN',
  [Site.ARCADIA]: 'ARCADIA_CONTENTFUL_DEV_ACCESS_TOKEN',
  [Site.AR]: 'AR_CONTENTFUL_DEV_ACCESS_TOKEN',
  [Site.AVALON]: 'AVALON_CONTENTFUL_DEV_ACCESS_TOKEN',
  [Site.CAREERS]: 'CAREERS_CONTENTFUL_DEV_ACCESS_TOKEN',
  [Site.CHEERIOS]: 'CHEERIOS_CONTENTFUL_DEV_ACCESS_TOKEN',
  [Site.CITIZEN]: 'CITIZEN_CONTENTFUL_DEV_ACCESS_TOKEN',
  [Site.CREATORS]: 'CREATORS_CONTENTFUL_DEV_ACCESS_TOKEN',
  [Site.DIVERSITY]: 'DIVERSITY_CONTENTFUL_DEV_ACCESS_TOKEN',
  [Site.EDUCATORS]: 'EDUCATORS_CONTENTFUL_DEV_ACCESS_TOKEN',
  [Site.ENG_BLOG]: 'ENG_BLOG_CONTENTFUL_DEV_ACCESS_TOKEN',
  [Site.EXPERIENCE]: 'EXPERIENCE_CONTENTFUL_DEV_ACCESS_TOKEN',
  [Site.FOR_BUSINESS]: 'FOR_BUSINESS_CONTENTFUL_DEV_ACCESS_TOKEN',
  [Site.NEWSROOM]: 'NEWSROOM_CONTENTFUL_DEV_ACCESS_TOKEN',
  [Site.ORIGINALS]: 'ORIGINALS_CONTENTFUL_DEV_ACCESS_TOKEN',
  [Site.PARENTS]: 'PARENTS_CONTENTFUL_DEV_ACCESS_TOKEN',
  [Site.SANDBOX]: 'SANDBOX_CONTENTFUL_DEV_ACCESS_TOKEN',
  [Site.TRUST]: 'TRUST_CONTENTFUL_DEV_ACCESS_TOKEN',
  [Site.SNAP]: 'SNAP_CONTENTFUL_DEV_ACCESS_TOKEN',
};

/** Keys for the preview tokens in contentful by site */
export const contentfulPreviewTokenKeys: Record<Site, string> = {
  [Site.A523]: 'SNAP_523_CONTENTFUL_PREVIEW_ACCESS_TOKEN',
  [Site.ARCADIA]: 'ARCADIA_CONTENTFUL_PREVIEW_ACCESS_TOKEN',
  [Site.AR]: 'AR_CONTENTFUL_PREVIEW_ACCESS_TOKEN',
  [Site.AVALON]: 'AVALON_CONTENTFUL_PREVIEW_ACCESS_TOKEN',
  [Site.CAREERS]: 'CAREERS_CONTENTFUL_PREVIEW_ACCESS_TOKEN',
  [Site.CHEERIOS]: 'CHEERIOS_CONTENTFUL_PREVIEW_ACCESS_TOKEN',
  [Site.CITIZEN]: 'CITIZEN_CONTENTFUL_PREVIEW_ACCESS_TOKEN',
  [Site.CREATORS]: 'CREATORS_CONTENTFUL_PREVIEW_ACCESS_TOKEN',
  [Site.DIVERSITY]: 'DIVERSITY_CONTENTFUL_PREVIEW_ACCESS_TOKEN',
  [Site.EDUCATORS]: 'EDUCATORS_CONTENTFUL_PREVIEW_ACCESS_TOKEN',
  [Site.ENG_BLOG]: 'ENG_BLOG_CONTENTFUL_PREVIEW_ACCESS_TOKEN',
  [Site.EXPERIENCE]: 'EXPERIENCE_CONTENTFUL_PREVIEW_ACCESS_TOKEN',
  [Site.FOR_BUSINESS]: 'FOR_BUSINESS_CONTENTFUL_PREVIEW_ACCESS_TOKEN',
  [Site.NEWSROOM]: 'NEWSROOM_CONTENTFUL_PREVIEW_ACCESS_TOKEN',
  [Site.ORIGINALS]: 'ORIGINALS_CONTENTFUL_PREVIEW_ACCESS_TOKEN',
  [Site.PARENTS]: 'PARENTS_CONTENTFUL_PREVIEW_ACCESS_TOKEN',
  [Site.SANDBOX]: 'SANDBOX_CONTENTFUL_PREVIEW_ACCESS_TOKEN',
  [Site.TRUST]: 'TRUST_CONTENTFUL_PREVIEW_ACCESS_TOKEN',
  [Site.SNAP]: 'SNAP_CONTENTFUL_PREVIEW_ACCESS_TOKEN',
};
