import type { ComponentType, LazyExoticComponent } from 'react';
import { lazy } from 'react';

/**
 * Wrapper to untangle imports that do not have default exports
 *
 * Based on https://github.com/fuse-box/fuse-box/issues/1646#issuecomment-572242548
 */
// eslint-disable-next-line import/no-unused-modules
export function lazyComponent(
  factory: () => Promise<{ default: ComponentType<any> }>
): LazyExoticComponent<ComponentType<any>> {
  const defaultDynamicImport = async () => {
    const componentModule = await factory();
    return typeof componentModule.default === 'function'
      ? componentModule
      : componentModule.default;
  };

  return lazy(defaultDynamicImport);
}
