import { cx } from '@emotion/css';
import type { FC } from 'react';

import type { BackgroundColor } from '../../constants';
import { MotifComponent, useMotifStyles } from '../../motif';
import { checkedSliderCss, sliderCss } from './styles';

export interface ToggleSliderProps {
  backgroundColor?: BackgroundColor;
  onToggle: () => void;
  id: string;
  isChecked: boolean;
  className?: string;
}

export const ToggleSlider: FC<ToggleSliderProps> = ({
  isChecked,
  className,
  onToggle,
  id,
}: ToggleSliderProps) => {
  useMotifStyles(MotifComponent.TOGGLE_SLIDER);
  return (
    <div
      className={cx(
        MotifComponent.TOGGLE_SLIDER,
        sliderCss,
        { [checkedSliderCss]: isChecked },
        className
      )}
    >
      <label>
        <input onChange={onToggle} checked={isChecked} id={id} type="checkbox" />
        <span />
      </label>
    </div>
  );
};

ToggleSlider.displayName = 'ToggleSlider';
