import { css } from '@emotion/css';

import { m } from '../../motif/m';

export const containerCss = css`
  &.small {
    --size: 28px;
    --icon-size: 24px;
  }
  &.medium {
    --size: 32px;
    --icon-size: 24px;
  }
  &.large {
    --size: 40px;
    --icon-size: 24px;
  }
  &.extra_large {
    --size: 64px;
    --icon-size: 40px;
  }

  /* These are meant to be easily overridable via className prop. */
  width: var(--size);
  height: var(--size);

  cursor: pointer;
  transition: transform 0.2s linear;

  border-radius: 50%;
  box-shadow: ${m('--box-shadow-xs')};

  /* Center */
  display: flex;
  align-items: center;
  justify-content: center;

  --icon-color: ${m('--icon-button-fg-color')};
  background: ${m('--icon-button-bg-color')};
  border: ${m('--icon-button-border-width')} solid ${m('--icon-button-border-color')};

  :hover {
    background: ${m('--icon-button-hover-bg-color')};
    border: ${m('--icon-button-border-width')} solid ${m('--icon-button-hover-border-color')};
    transform: translate(0, -1px);
  }

  :active,
  .active {
    transform: translate(0, 1px);
  }

  :disabled,
  .disabled {
    cursor: not-allowed;
    transform: none;
    --icon-color: ${m('--icon-button-disabled-fg-color')};
    background: ${m('--icon-button-disabled-bg-color')};
    border: ${m('--icon-button-border-width')} solid ${m('--icon-button-disabled-border-color')};
  }

  > .icon {
    width: var(--icon-size);
    height: var(--icon-size);
  }
`;
