import { cx } from '@emotion/css';
import type { FC, MouseEventHandler, ReactNode } from 'react';
import { useCallback, useContext } from 'react';

import type { OnNavigateHandler } from '../../../types/activationEvents';
import { Icon } from '../../Icon';
import { PrimitivesContext } from '../../Primitives';
import {
  arrowCss,
  contentCss,
  descriptionCss,
  linkCss,
  subTextCss,
} from './CarouselTextItem.styles';

export interface CarouselTextItemProps {
  description?: ReactNode;
  subText?: ReactNode;
  url?: string;
  openNewWTab?: boolean;
  onClick?: OnNavigateHandler;
}

export const CarouselTextItem: FC<CarouselTextItemProps> = ({
  description,
  subText,
  url,
  openNewWTab,
  onClick,
  ...rest
}) => {
  const { Anchor } = useContext(PrimitivesContext);
  const onClickWrapped = useCallback<MouseEventHandler>(
    event => {
      url && onClick && onClick(url, event);
    },
    [url, onClick]
  );

  if (url) {
    return (
      <Anchor
        href={url}
        className={cx(contentCss, linkCss)}
        {...(openNewWTab ? { target: '_blank', rel: 'noreferrer' } : {})}
        onClick={onClickWrapped}
        draggable="false"
        {...rest}
      >
        <Icon className={arrowCss} name="arrow-right" />
        <div>
          {description && <div className={descriptionCss}>{description}</div>}
          {subText && <div className={subTextCss}>{subText}</div>}
        </div>
      </Anchor>
    );
  }

  return (
    <div className={contentCss} {...rest}>
      {description && <div className={descriptionCss}>{description}</div>}
      {subText && <div className={subTextCss}>{subText}</div>}
    </div>
  );
};

CarouselTextItem.displayName = 'CarouselTextItem';
