import { Black, White, Yellow } from '../../../constants';

export const chartToggleBlackBgVars = {
  '--chart-toggle-buttons-bg-color': White,
  '--chart-toggle-buttons-color': Black.V50,
  '--chart-toggle-buttons-border-color': Black.V50,
  '--chart-toggle-buttons-active-bg-color': Yellow.V100,
  '--chart-toggle-buttons-active-color': Black.V200,
  '--chart-toggle-buttons-active-border-color': Yellow.V100,
};

export const chartToggleYellowBgVars = {
  '--chart-toggle-buttons-bg-color': White,
  '--chart-toggle-buttons-color': Black.V50,
  '--chart-toggle-buttons-border-color': Black.V50,
  '--chart-toggle-buttons-active-bg-color': Black.V200,
  '--chart-toggle-buttons-active-color': White,
  '--chart-toggle-buttons-active-border-color': Black.V200,
};

export const chartToggleWhiteBgVars = {
  '--chart-toggle-buttons-bg-color': White,
  '--chart-toggle-buttons-color': Black.V50,
  '--chart-toggle-buttons-border-color': Black.V50,
  '--chart-toggle-buttons-active-bg-color': Yellow.V100,
  '--chart-toggle-buttons-active-color': Black.V200,
  '--chart-toggle-buttons-active-border-color': Yellow.V100,
};

export const chartToggleGrayBgVars = {
  '--chart-toggle-buttons-bg-color': White,
  '--chart-toggle-buttons-color': Black.V50,
  '--chart-toggle-buttons-border-color': Black.V50,
  '--chart-toggle-buttons-active-bg-color': Black.V200,
  '--chart-toggle-buttons-active-color': White,
  '--chart-toggle-buttons-active-border-color': Black.V200,
};
