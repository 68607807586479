import { gql } from '@apollo/client';
import type { DropdownField, InputField } from '@snapchat/mw-contentful-schema';
import type { Alignment, BackgroundColor } from '@snapchat/snap-design-system-marketing';

import { fragments as analyticsFragments } from '../../../../components/Analytics';
import type { AnalyticsDataProps } from '../../../../components/Analytics/types';
import type { MediaDataProps } from '../../../../components/Media';
import { fragments as assetsFragments } from '../../../../components/Media';
import type { ContentfulSysProps } from '../../../../types/contentful';
import type { RichText } from '../../../../types/RichText';
import { contentfulSysIdFragment } from '../../../../utils/contentful/contentfulQuery';
import type { ArDownloadFormSpacing, ArDownloadFormStyle } from './types';

export interface ArDownloadFormBlockQuery {
  arDownloadFormBlock: ArDownloadFormBlockDataProps;
}

export interface ArDownloadFormBlockDataProps extends ContentfulSysProps {
  title?: RichText | string;
  subtitle?: RichText | string;
  backgroundColor: BackgroundColor;
  style: ArDownloadFormStyle;
  spacing: ArDownloadFormSpacing;
  alignment: Alignment;
  endpoint: string;
  eula: InputField;
  genAiOptIn: InputField;
  version: InputField;
  platform: DropdownField;
  analytics: AnalyticsDataProps;
  submitText: string;
  submitSuccessText?: string;
  backgroundMedia?: MediaDataProps;
  logo?: MediaDataProps;
}

export const arDownloadFormBlockFragment = {
  all: gql`
    fragment ArDownloadFormBlockAll on ArDownloadFormBlock {
      ...ContentfulSysId
      title {
        json
      }
      subtitle {
        json
      }
      style
      spacing
      alignment
      backgroundColor
      backgroundMedia {
        ...AssetAll
      }
      logo {
        ...AssetAll
      }
      endpoint
      submitText
      submitSuccessText
      analytics {
        ...AnalyticsAll
      }
      eula {
        ...ContentfulSysId
        name
        type
        label
        richLabel {
          json
        }
        initialValue
        shouldResetToInitial
        placeholder
        required
        validation
        helpText
        error {
          json
        }
      }
      genAiOptIn {
        ...ContentfulSysId
        name
        type
        label
        richLabel {
          json
        }
        initialValue
        shouldResetToInitial
        placeholder
        required
        validation
        helpText
        error {
          json
        }
      }
      version {
        ...ContentfulSysId
        name
        type
        label
        initialValue
      }
      platform {
        ...ContentfulSysId
        name
        optionsCollection {
          items {
            ... on DropdownFieldOption {
              ...ContentfulSysId
              key
              value
            }
          }
        }
        initialOption {
          ... on DropdownFieldOption {
            ...ContentfulSysId
            key
            value
          }
        }
        shouldResetToInitial
        error {
          json
        }
      }
    }
    ${contentfulSysIdFragment}
    ${assetsFragments.all}
    ${analyticsFragments.all}
  `,
};

export const query = gql`
  query ARDownloadFormBlockQuery($id: String!, $preview: Boolean!, $locale: String!) {
    arDownloadFormBlock(id: $id, preview: $preview, locale: $locale) {
      ...ArDownloadFormBlockAll
    }
  }
  ${arDownloadFormBlockFragment.all}
`;
