import { cx } from '@emotion/css';
import type { FC } from 'react';

import { Icon } from '../Icon';
import { navigationButtonContainerCss } from './styles';
import type { NavigationButtonProps } from './types';

export const NavigationButtonRight: FC<NavigationButtonProps> = ({
  onClick,
  isDisabled = false,
  label,
  showArrow = true,
  className,
}) => {
  return (
    <button
      onClick={onClick}
      type="button"
      disabled={isDisabled}
      className={cx(navigationButtonContainerCss, className)}
    >
      {label}
      {showArrow && (
        <i>
          <Icon name="arrow-right" />
        </i>
      )}
    </button>
  );
};

NavigationButtonRight.displayName = 'NavigationButtonRight';
