import type { OperationVariables, QueryHookOptions, QueryResult } from '@apollo/client';
import { useQuery } from '@apollo/client';
import type { Entity } from '@contentful/live-preview/dist/types';
import type { DocumentNode } from 'graphql';
import clone from 'lodash-es/clone';
import noop from 'lodash-es/noop';
import { useContext, useEffect, useState } from 'react';

import { AppContext } from '../AppContext';
import { ContentfulLivePreviewContext } from '../components/ContentfulLivePreview';
import { useContentfulContext } from '../components/useContentfulContext';
import { Config } from '../config';
import { convertNullsToUndefined } from '../utils/contentful/convertNullsToUndefined';

/** Variables common for all contentful queries. */
export interface ContentfulQueryVariables {
  preview?: boolean;
  locale?: string;
}

/** Generic interface for queries requiring a sys id. */
export interface ContentfulIdVariable {
  id?: string;
}

/** Generic interface for queries requiring a collection of sys ids. */
export interface ContentfulIdsVariable {
  ids?: string[];
}

/** Context common for all contentful queries. */
export type ContentfulQueryOptions<TData, TVariables extends OperationVariables> = QueryHookOptions<
  TData,
  TVariables
>;

/**
 * Hook for executing `useQuery` from apollo with some context and variables pulled from the
 * environment.
 *
 * Usage:
 *
 *     const { data } = useContentfulQuery<DataInterface, VariableInterface>(query.all);
 *     return <FooSDS {...data} />;
 */
export const useContentfulQuery = <TData extends Entity, TVariables = ContentfulQueryVariables>(
  query: DocumentNode,
  options?: ContentfulQueryOptions<TData, TVariables & ContentfulQueryVariables>
): QueryResult<TData, TVariables & ContentfulQueryVariables> => {
  const { getCurrentUrl } = useContext(AppContext);
  const { preview, locale, fetchPolicy } = useContentfulContext();
  const { hasLivePreview } = useContext(ContentfulLivePreviewContext);

  const queryOptions: QueryHookOptions<TData, ContentfulQueryVariables & TVariables> = {
    fetchPolicy,
    ...options,
    context: { currentUrl: getCurrentUrl(), ...options?.context },
    variables: {
      preview,
      locale,
      ...options?.variables,
    } as TVariables & ContentfulQueryVariables,
    ssr: options?.ssr ?? Config.isSSR,
  };

  const queryResponse = useQuery<TData, TVariables & ContentfulQueryVariables>(query, queryOptions);

  // Restart trigger for polling data.
  // The regular behavior for polling is to stop after the first update.
  // This effect restarts the polling so we can continue to receive updates.
  useEffect(
    () => {
      if (!options?.pollInterval) return;
      queryResponse.startPolling(options!.pollInterval);
      return queryResponse.stopPolling;
    },
    // Rule of hooks gets this one wrong.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryResponse.data, options, queryResponse.startPolling, queryResponse.stopPolling]
  );

  const [updatedEntries, setUpdatedEntries] = useState<TData | object>({});

  // Enable Live Updates if applicable
  useEffect(() => {
    let cleanup = noop;

    async function subscribeToLiveUpdates(): Promise<void> {
      const { ContentfulLivePreview } = await import('@contentful/live-preview');

      const singleItemKeys = Object.keys(queryResponse.data!).filter(
        key => queryResponse.data?.[key].sys && queryResponse.data?.[key].__typename
      );

      const collectionKeys = Object.keys(queryResponse.data!).filter(
        key => queryResponse.data?.[key].items
      );

      const singleItemUnsubscribeFns = singleItemKeys.map(key => {
        return ContentfulLivePreview.subscribe({
          data: queryResponse.data![key],
          locale,
          callback: newData => {
            setUpdatedEntries(entries => {
              return { ...entries, [key]: newData };
            });
          },
          query,
        });
      });

      // Live Updates only work when you pass an Entry or an array of Entry, so we need to pass the items array directly.
      const collectionUnsubscribeFns = collectionKeys.map(key => {
        return ContentfulLivePreview.subscribe({
          data: queryResponse.data![key].items,
          locale,
          callback: newData => {
            setUpdatedEntries(entries => {
              return { ...entries, [key]: { items: newData } };
            });
          },
          query,
        });
      });

      cleanup = () =>
        [...singleItemUnsubscribeFns, ...collectionUnsubscribeFns].forEach(fn => fn());
    }

    if (hasLivePreview && queryResponse.data) {
      void subscribeToLiveUpdates();
    }

    return cleanup;
  }, [queryResponse.data, hasLivePreview, locale, query]);

  let maybeClonedResponse = queryResponse;

  if (queryResponse.data) {
    // We need to keep queryResponse.data the same to prevent it from triggerring useEffect repeatedly when we
    // set the query data. A shallow clone works here.
    maybeClonedResponse = clone(queryResponse);
    const dataToUse =
      hasLivePreview && Object.keys(updatedEntries).length ? updatedEntries : queryResponse.data;

    // Per team decision: we remove any values that are 'null'.
    maybeClonedResponse.data = convertNullsToUndefined(dataToUse) as TData;
  }

  return maybeClonedResponse;

  // NOTE: Apollo client already captured any errors.
};
