import type { FC, PropsWithChildren } from 'react';
import { useContext } from 'react';

import { DesignSystemContext } from '../../context/DesignSystemContext';
import type { BackgroundType } from '../types';

export interface DesignAgnosticSectionProps extends PropsWithChildren {
  backgroundType?: BackgroundType;
  className?: string;
}

export const DesignAgnosticSection: FC<DesignAgnosticSectionProps> = props => {
  const { sectionComponent } = useContext(DesignSystemContext);
  return sectionComponent(props);
};
