import { css } from '@emotion/css';

import { mobileMediaQuery } from './layout';

/**
 * @deprecated Use `<h*>` tags or h*Css classname instead.
 *
 *   Tracking: https://jira.sc-corp.net/browse/ENTWEB-8939
 */
export const MobileHeading = {
  M100: css`
    font-size: 36px;
    line-height: 42px;
    font-weight: 600;
  `,
  M200: css`
    font-size: 32px;
    line-height: 40px;
    font-weight: 600;
  `,
  M300: css`
    font-size: 24px;
    line-height: 28px;
    font-weight: 500;
  `,
  M400: css`
    font-size: 20px;
    font-weight: 600;
    line-height: 23px;
  `,
  M500: css`
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
  `,
  M600: css`
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
  `,
  M700: css`
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
  `,
};

/**
 * @deprecated Use `<h*>` tags or h*Css classname instead.
 *
 *   Tracking: https://jira.sc-corp.net/browse/ENTWEB-8939
 */
export const Heading = {
  H100: css`
    font-size: 64px;
    font-weight: 600;
    line-height: 70px;
    ${mobileMediaQuery} {
      ${MobileHeading.M100}
    }
  `,
  H200: css`
    font-size: 48px;
    font-weight: 600;
    line-height: 64px;
    ${mobileMediaQuery} {
      ${MobileHeading.M200}
    }
  `,
  H300: css`
    font-size: 28px;
    font-weight: 500;
    line-height: 34px;
    ${mobileMediaQuery} {
      ${MobileHeading.M300}
    }
  `,
  H400: css`
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    ${mobileMediaQuery} {
      ${MobileHeading.M400}
    }
  `,
  H500: css`
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    ${mobileMediaQuery} {
      ${MobileHeading.M500}
    }
  `,
  H600: css`
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    ${mobileMediaQuery} {
      ${MobileHeading.M600}
    }
  `,
  H700: css`
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    ${mobileMediaQuery} {
      ${MobileHeading.M700}
    }
  `,
};

/** @deprecated Use p2Css instead. */
export const FontParagraph = {
  P100: css`
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
  `,
};
