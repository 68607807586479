import { Black, Gray, White } from '../../../constants';
import type { MotifComponent, MotifComponentProps } from '../../../motif';

export const chartSkeletonLightVars: MotifComponentProps<MotifComponent.CHART_SKELETON> = {
  '--chart-skeleton-bg-color': Gray.V50,
  '--chart-skeleton-mask-color': Gray.V150,
  '--chart-skeleton-dark-animation-color': Gray.V150,
  '--chart-skeleton-light-animation-color': White,
};

export const chartSkeletonDarkVars: MotifComponentProps<MotifComponent.CHART_SKELETON> = {
  '--chart-skeleton-bg-color': Black.V125,
  '--chart-skeleton-mask-color': Black.V50,
  '--chart-skeleton-dark-animation-color': Black.V50,
  '--chart-skeleton-light-animation-color': Gray.V300,
};
