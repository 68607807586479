import { cx } from '@emotion/css';
import { Alignment, Block as BlockSDS } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import type { ContentfulIdVariable } from '../../../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../../../hooks/useContentfulQuery';
import type { ContentfulIdProps } from '../../../../types/contentful';
import { parseMedia } from '../../../../utils/media';
import { renderRichTextMarkingsOnly } from '../../../../utils/renderText/renderRichText';
import { ArDownloadForm } from './ArDownloadForm';
import {
  alignCenterCss,
  alignRightCss,
  blockMarginBottonCss,
  blockWrapperCss,
  contentCss,
  contentHeightBlockDenseCss,
  contentHeightBlockSpaciousCss,
  contentResetPaddingCss,
  logoCss,
} from './ArDownloadFormBlock.styles';
import type { ArDownloadFormBlockQuery } from './arDownloadFormBlockQuery';
import { query } from './arDownloadFormBlockQuery';
import { ArDownloadFormHeader } from './ArDownloadFormHeader';
import { ArDownloadFormSpacing, ArDownloadFormStyle } from './types';

type ArDownloadFormProps = ContentfulIdProps & {
  id: string;
};

export const ArDownloadFormBlock: FC<ArDownloadFormProps> = ({ id }) => {
  const { data } = useContentfulQuery<ArDownloadFormBlockQuery, ContentfulIdVariable>(query, {
    variables: { id },
  });

  if (!data?.arDownloadFormBlock) {
    return null;
  }

  const {
    backgroundColor,
    alignment,
    style,
    spacing,
    title: titleRichText,
    subtitle: subtitleRichText,
    backgroundMedia,
    logo,
    endpoint,
    analytics,
    submitText,
    submitSuccessText,
    version,
    platform,
    eula,
    genAiOptIn,
  } = data.arDownloadFormBlock;

  const title = renderRichTextMarkingsOnly(titleRichText);
  const subtitle = renderRichTextMarkingsOnly(subtitleRichText);

  const { imageSource: backgroundImageSource } = parseMedia(backgroundMedia);
  const { imageSource: logoImageSource, imageAltText: logoAltText } = parseMedia(logo);

  return (
    <div
      className={cx('ar-download-from-container', blockWrapperCss, {
        [contentResetPaddingCss]: style === ArDownloadFormStyle.Block,
        [blockMarginBottonCss]: style !== ArDownloadFormStyle.Block,
      })}
    >
      <BlockSDS
        backgroundColor={backgroundColor}
        style={{ backgroundImage: `url("${backgroundImageSource}")` }}
      >
        <div
          className={cx('ar-dowload-form-content', contentCss, {
            [alignRightCss]: alignment === Alignment.Right,
            [alignCenterCss]: alignment === Alignment.Center,
            [contentHeightBlockDenseCss]:
              style === ArDownloadFormStyle.Block && spacing !== ArDownloadFormSpacing.Spacious,
            [contentHeightBlockSpaciousCss]:
              style === ArDownloadFormStyle.Block && spacing === ArDownloadFormSpacing.Spacious,
          })}
        >
          <img className={logoCss} src={logoImageSource} alt={logoAltText} />
          <ArDownloadFormHeader
            title={title}
            subtitle={subtitle}
            titleStyle={style === ArDownloadFormStyle.Block ? 'H200' : 'H300'}
            subtitleStyle="H400"
            alignment={alignment}
          />
          <ArDownloadForm
            id={id}
            formStyle={style}
            alignment={alignment}
            analytics={analytics}
            endpoint={endpoint}
            version={version}
            eula={eula}
            genAiOptIn={genAiOptIn}
            platform={platform}
            submitText={submitText}
            submitSuccessText={submitSuccessText}
          />
        </div>
      </BlockSDS>
    </div>
  );
};
