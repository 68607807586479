import { gql } from '@apollo/client';

import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';
import { fragments as contentFragments } from '../Content';

export const fragments = {
  /** Note: Requires calling query set the `$accordionItemsLimit` gql variable. */
  all: gql`
    fragment AccordionAll on Accordion {
      ...ContentfulSysId
      title
      multipleOpen
      itemsCollection(limit: 30) {
        items {
          ...ContentfulSysId
          ...ContentAll
        }
      }
    }
    ${contentfulSysIdFragment}
    ${contentFragments.all}
  `,
};

export const queries = {
  all: gql`
    query accordionQuery($preview: Boolean!, $locale: String!, $id: String!) {
      accordion(preview: $preview, locale: $locale, id: $id) {
        ...AccordionAll
      }
    }
    ${fragments.all}
  `,
};
