import Cookies from 'js-cookie';

import type { CategorizedCookie } from './createCookieMapping';

/**
 * Helper function that detects cookies that are not mapped to a known cookie category.
 *
 * @param cookieMappings A map where the key is the cookie category and the value is a set of all
 *   registered tracking cookies for that category.
 * @returns A string array containing the names of all uncategorized cookies.
 */
export const auditForUncategorizedCookies = (
  cookieMappings: Map<string, Set<CategorizedCookie>>
): string[] => {
  const currentCookies: Record<string, string> = Cookies.get();

  // convert categorized cookies to formats for easy checking
  const allStaticCookieNames = new Set<string>();
  const allCookieRegexPatterns: RegExp[] = [];

  for (const [_, categoryCookies] of cookieMappings) {
    for (const { name, regex } of categoryCookies) {
      if (regex) {
        allCookieRegexPatterns.push(regex);
      } else {
        allStaticCookieNames.add(name);
      }
    }
  }

  const uncategorizedCookies: string[] = [];

  for (const cookieName in currentCookies) {
    if (allStaticCookieNames.has(cookieName)) continue;

    if (allCookieRegexPatterns.some(regex => regex.test(cookieName))) continue;

    uncategorizedCookies.push(cookieName);
  }

  return uncategorizedCookies;
};
