import { css } from '@emotion/css';
import { m, nonMobileMediaQuery } from '@snapchat/snap-design-system-marketing';

export const localeDropdownContainerCss = css`
  width: 100%;

  ${nonMobileMediaQuery} {
    width: auto;
  }
`;

export const logoWrapperCss = css`
  display: flex;
  align-items: center;
  margin-top: ${m('--spacing-xxxs')};

  padding-block-end: ${m('--spacing-xs')};

  ${nonMobileMediaQuery} {
    padding-block-end: 0;
  }
`;

// This fill color is taken from the official logo svg
export const lightBgSnapLogoCss = css`
  &.sdsm-logo {
    fill: #221f20;
  }
`;
