import type { FC } from 'react';

import type { ContentfulIdVariable } from '../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../hooks/useContentfulQuery';
import type { ContentfulSysProps } from '../../types/contentful';
import { Form } from './Form';
import { queries } from './queries';
import type { FormDataHandlerProps } from './types';

export const FormShallow: FC<ContentfulSysProps> = props => {
  const { data } = useContentfulQuery<FormDataHandlerProps, ContentfulIdVariable>(queries.all, {
    variables: { id: props.sys.id },
  });

  if (!data?.form) return null;
  return <Form {...data.form} />;
};

FormShallow.displayName = 'Form';
