import type { Locale } from 'contentful';
import noop from 'lodash-es/noop';
import type { RefObject } from 'react';
import { createContext } from 'react';

import type { ExperimentBucketData } from './components/Experiment';
import type { CookieManager } from './utils/cookies/CookieManager';
import type { CreateUuidV4 } from './utils/uuid/uuidFactory';

export interface UserLocation {
  country: string;
  // NOTE: We do not include region here because the location is computed on
  // the server and is cached in CDN, and adding region here would destroy
  // the CDN cache hit rate.
  // If you need to get the region of the user, do it on the client-side only
  // by fetching https://web-platform.snap.com/cookies/user_location
}

/**
 * Specific meta about the user that we only compute on the client-side because it's based on things
 * that we aren't able to compute on the server-side because it would destroy the CDN cache hit
 * rate.
 */
export interface UserInfo {
  experimentBucket: ExperimentBucketData;
}

export type RedirectOptions = {
  isPermanent?: boolean;
  newTab?: boolean;
};

/**
 * Properties of the AppContext that are passed-in from outside of the App, and are typically
 * different between client and server.
 */
export interface PassThroughAppProviderProps {
  /** Locale in which the app should render in. */
  currentLocale: string;
  /** All supported locales that the app can be rendered in. */
  supportedLocales: Record<string, Locale>;
  /** The location of the user. */
  userLocation: UserLocation;
  getCurrentUrl: () => string;
  onRedirect?: (location: string, options?: RedirectOptions) => void;
  cookieManager: CookieManager;
  createUuidV4: CreateUuidV4;
  getUserInfo: () => Promise<UserInfo>;

  /**
   * Custom site data. Used for passing server-rendered info into the app.
   *
   * TODO: Deprecate this after SPS 2023.
   */
  siteData?: Record<string, unknown>;
}

/** App Props that can be grabbed from the AppContext. */
export interface AppProviderProps extends PassThroughAppProviderProps {
  isRTL: boolean;
  /**
   * Setter for custom site data.
   *
   * DO NOT USE. This forces a page re-render. Instead copy this context into another local context
   * and change the value there.
   *
   * TODO: Deprecate this after SPS 2023.
   */
  setSiteData: (siteData: Record<string, unknown>) => void;
  /** Reference for the header portal. */
  headerPortalRef?: RefObject<HTMLElement>;
  /** Reference for the sticky portal at the bottom of the <Page />. */
  pageBottomStickyPortalRef?: RefObject<HTMLElement>;
}

export const defaultContext: AppProviderProps = {
  currentLocale: 'en-US',
  supportedLocales: {
    'en-US': {
      code: 'en-US',
      name: 'English (United States)',
      default: true,
      fallbackCode: null,
      sys: {
        id: '[Redacted]',
        type: 'Locale',
        version: 1,
      },
    },
  },
  userLocation: {
    country: 'US',
  },
  isRTL: false,
  getCurrentUrl: () => 'https://marketing-web-platform.appspot.com/',
  setSiteData: () => undefined,
  getUserInfo: () => Promise.resolve({ experimentBucket: { id: '' } }),
  cookieManager: {
    getCookie: _ => undefined,
    getCookieJson: _ => undefined,
    setCookie: noop,
    setCookieJson: noop,
    deleteCookie: noop,
  },
  createUuidV4: () => '00000000-0000-0000-0000-000000000000',
};

export const AppContext = createContext<AppProviderProps>(defaultContext);
