import type { FC } from 'react';
import { useContext } from 'react';

import { PrimitivesContext } from '../../Primitives';
import { footerItemContainerCss, footerItemCss } from './styles';
import type { FooterItemProps } from './types';

export const FooterItemV2: FC<FooterItemProps> = ({ id, url, title, onClick }) => {
  const { Anchor } = useContext(PrimitivesContext);
  return (
    <li data-testid="footer-item" id={id} className={footerItemContainerCss}>
      <Anchor href={url} onClick={onClick} className={footerItemCss}>
        {title}
      </Anchor>
    </li>
  );
};
