import type { ApolloClient } from '@apollo/client';
import { createContext } from 'react';

export interface ContentfulProps {
  isPreview: boolean;
  isSSR: boolean;
  currentLocale: string;
  /**
   * Optional: if defined, will use the provided Apollo client. Otherwise one will be created using
   * default settings.
   */
  client?: ApolloClient<object>;
}

/** Context used to configure Contentful Client */
export const ContentfulContext = createContext<ContentfulProps>({
  isPreview: false,
  isSSR: false,
  currentLocale: 'en-US',
});
