import { cx } from '@emotion/css';
import type { FC, PropsWithChildren } from 'react';

import { MotifComponent, useMotifStyles } from '../../motif';
import { dataSetToAttributes, getBackgroundClassName } from '../../utils';
import { itemsCss, itemsWrapCss, navCss } from './styles';
import type { SubNavigationProps } from './types';

export const SubNavigation: FC<PropsWithChildren<SubNavigationProps>> = ({
  backgroundColor,
  className,
  children,
  horizontalScrollRef,
  dataset,
}) => {
  useMotifStyles(MotifComponent.SUB_NAVIGATION);

  return (
    <nav
      className={cx(
        MotifComponent.SUB_NAVIGATION,
        navCss,
        getBackgroundClassName(backgroundColor),
        className
      )}
      {...dataSetToAttributes(dataset)}
    >
      <div ref={horizontalScrollRef} className={itemsWrapCss}>
        <ul className={itemsCss}>{children}</ul>
      </div>
    </nav>
  );
};
