import type React from 'react';

import type { CookieCategoryType } from '../../generated/contentful-types';
import { DesignAgnosticButton } from '../designAgnostic';
import { CookieCategories } from '../shared/CookieCategories';
import type { BackgroundType, CategoryOptInCookie, CookieAcceptance, LocaleOption } from '../types';
import { CookieModalFooter } from './CookieModalFooter';
import { CookieModalHeader } from './CookieModalHeader';

interface Props {
  backgroundType: BackgroundType;
  supportedLocales: Record<string, LocaleOption>;
  title: string;
  cookieCategories: readonly CookieCategoryType[];
  activeToggleLabel: string;
  inactiveToggleLabel: string;
  categoriesState: CookieAcceptance;
  updateCategoriesState: (key: CategoryOptInCookie, value: boolean) => void;
  acceptAllButtonText: string;
  essentialOnlyButtonText: string;
  acceptSelectedButtonText: string;
  onAcceptAllButtonClick: () => void;
  onAcceptEssentialButtonClick: () => void;
  onAcceptSelectedButtonClick: () => void;
  onLocaleChange?: (locale: string) => void;
}

export const CookieSettingsScreen: React.FC<Props> = ({
  backgroundType,
  supportedLocales,
  onLocaleChange,
  title,
  cookieCategories,
  activeToggleLabel,
  inactiveToggleLabel,
  categoriesState,
  updateCategoriesState,
  acceptAllButtonText,
  essentialOnlyButtonText,
  acceptSelectedButtonText,
  onAcceptAllButtonClick,
  onAcceptEssentialButtonClick,
  onAcceptSelectedButtonClick,
}) => {
  const settingScreen = (
    <div data-testid="mwp-cookie-modal-settings-screen" className="cookie-settings-screen">
      <CookieModalHeader
        backgroundType={backgroundType}
        supportedLocales={supportedLocales}
        onLocaleChange={onLocaleChange}
      />
      <div className="modal-body">
        <CookieCategories
          title={title}
          cookieCategories={cookieCategories}
          categoriesState={categoriesState}
          updateCategoriesState={updateCategoriesState}
          activeToggleLabel={activeToggleLabel}
          inactiveToggleLabel={inactiveToggleLabel}
          isModal={true}
        />
      </div>
      <CookieModalFooter>
        <DesignAgnosticButton
          isPrimary={true}
          text={essentialOnlyButtonText}
          onClick={onAcceptEssentialButtonClick}
        />
        <DesignAgnosticButton
          isPrimary={true}
          text={acceptAllButtonText}
          onClick={onAcceptAllButtonClick}
        />
        <DesignAgnosticButton
          isPrimary={true}
          text={acceptSelectedButtonText}
          onClick={onAcceptSelectedButtonClick}
        />
      </CookieModalFooter>
    </div>
  );

  return settingScreen;
};
