import { cx } from '@emotion/css';
import {
  type FC,
  type PropsWithChildren,
  type ReactElement,
  type ReactNode,
  Children,
  isValidElement,
} from 'react';

import { MotifComponent, useMotifStyles } from '../../../motif';
import {
  collapsedMobileTableCss,
  columnCountVariable,
  overflowOnMobileCss,
  tableCss,
  tableWithHeadersClassName,
  tableWrapperCss,
} from './PrimitiveTable.styles';
import {
  PrimitiveTableCell,
  PrimitiveTableHeader,
  PrimitiveTableRow,
} from './PrimitiveTableComponents';

export type PrimitiveTableProps = {
  children?: ReactNode | undefined;
  collapseMobileTable?: boolean;
};

/** PrimitiveTable is a wrapper around an HTML div table element adding Snap's design system styles. */
export const PrimitiveTable: FC<PrimitiveTableProps> = ({
  children,
  collapseMobileTable = false,
}) => {
  useMotifStyles(MotifComponent.PRIMITIVE_TABLE);

  const childrenArray = Children.toArray(children) as ReactElement[];
  const firstTableRow = childrenArray.find(
    child => isValidElement(child) && child.type === PrimitiveTableRow
  );
  const columnCount = firstTableRow
    ? Children.toArray(firstTableRow.props.children).filter(
        child =>
          isValidElement(child) &&
          (child.type === PrimitiveTableHeader || child.type === PrimitiveTableCell)
      ).length
    : 0;
  const hasTableHeaders = firstTableRow
    ? Children.toArray(firstTableRow.props.children).some(
        child => isValidElement(child) && child.type === PrimitiveTableHeader
      )
    : false;

  // Set the column count as a CSS variable to allow for a dynamic grid-template-columns property
  const columnCountVariableStyles = {
    [columnCountVariable]: columnCount,
  } as React.CSSProperties;

  return (
    // A wrapper is needed to make the table horizontally scrollable
    <article
      style={columnCountVariableStyles}
      className={cx(tableWrapperCss, 'sdsm-primitive-table')}
    >
      <div
        aria-label="Table"
        role="table"
        className={cx(MotifComponent.PRIMITIVE_TABLE, tableCss, {
          [collapsedMobileTableCss]: collapseMobileTable && !hasTableHeaders,
          [tableWithHeadersClassName]: hasTableHeaders,
          [overflowOnMobileCss]:
            (hasTableHeaders && columnCount >= 2) || (columnCount >= 2 && !collapseMobileTable),
        })}
      >
        {children}
      </div>
    </article>
  );
};

export const CollapseTableMobile: FC<PropsWithChildren> = ({ children }) => (
  <PrimitiveTable collapseMobileTable>{children}</PrimitiveTable>
);

export const NoCollapseTableMobile = PrimitiveTable;
