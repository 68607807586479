import { cx } from '@emotion/css';
import type { ChangeEvent, FC } from 'react';
import { useMemo } from 'react';

import { MotifComponent, useMotifStyles } from '../../motif';
import type { BaseComponentProps } from '../../types';
import { dataSetToAttributes } from '../../utils';
import type { DropdownItem, DropdownMenuButtonProps } from '../DropdownMenu';
import { DropdownMenu } from '../DropdownMenu';
import { Icon } from '../Icon';
import {
  caratCss,
  desktopWrapperCss,
  dropdownCss,
  dropdownMainTextCss,
  dropdownMobileCss,
  dropdownMobileWrapperCss,
  dropdownSecondaryTextCss,
  dropdownTextCss,
} from './filterDropdownMenuStyles';

export const allValue = 'all';
// We'll generate the onClick ourselves.
type AbridgedDropdownItem = Pick<DropdownItem, 'title' | 'id'>;

export interface FilterDropdownMenuProps extends BaseComponentProps {
  title: string;
  id: string;
  allItemTitle: DropdownItem['title'];
  items: AbridgedDropdownItem[];
  value?: string;
  onChange?: (itemId?: string) => void;
  isStandalone?: boolean;
}

/**
 * Returns the dropdown used for filtering.
 *
 * @param title - Title of filter dropdown
 * @param onChange - Callback function for filter change
 * @param isStandalone - Is filter standalone (defaults to true )
 * @param value - Current value of filter dropdown
 * @param allItemsTitle - Title for all values selection
 * @param items - Dropdown option items
 * @param dataset - Dataset attributes
 * @param id - Dropdown id
 */
export const FilterDropdownMenu: FC<FilterDropdownMenuProps> = ({
  title,
  onChange,
  isStandalone = true,
  value,
  allItemTitle,
  items,
  dataset,
}) => {
  useMotifStyles(MotifComponent.FILTER_DROPDOWN_MENU);
  const itemsWithEvents = useMemo(() => {
    const result = [
      {
        title: allItemTitle,
        id: allValue,
        onClick: () => onChange?.(undefined),
      },
      ...items.map(({ title, id }) => ({ title, id, onClick: () => onChange?.(id) })),
    ];

    return result;
  }, [allItemTitle, items, onChange]);

  const onChangeMobile = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === allValue) {
      onChange?.(undefined);
    } else {
      onChange?.(e.target.value);
    }
  };

  const FilterButton: FC<DropdownMenuButtonProps> = props => {
    return (
      <button
        onClick={props.onClick}
        data-testid="sds-dropdown-menu"
        className={dropdownCss}
        aria-haspopup="listbox"
        aria-label={props.ariaLabel}
        role="combobox"
        {...dataSetToAttributes(dataset)}
      >
        <div
          data-testid="sds-dropdown-menu-title"
          className={cx(dropdownTextCss, dropdownMainTextCss)}
        >
          {props.children}
        </div>
        <div className={caratCss}>
          <Icon size={24} name={props.isExpanded ? 'chevron-up' : 'chevron-down'} />
        </div>
      </button>
    );
  };

  return (
    <>
      <div
        data-testid="sds-dropdown-menu-container"
        className={cx(MotifComponent.FILTER_DROPDOWN_MENU, desktopWrapperCss)}
      >
        <DropdownMenu
          placeholderText={title}
          selectedItemId={value}
          items={itemsWithEvents}
          ariaLabel={title}
          buttonComponent={FilterButton}
        />
      </div>
      <div
        data-testid="sds-dropdown-menu-container-mobile"
        className={dropdownMobileWrapperCss}
        {...dataSetToAttributes(dataset)}
      >
        <select
          data-testid="sds-dropdown-menu-mobile"
          className={cx(dropdownMobileCss, dropdownTextCss, {
            [dropdownMainTextCss]: isStandalone,
            [dropdownSecondaryTextCss]: !isStandalone,
          })}
          value={value}
          onChange={onChangeMobile}
        >
          {itemsWithEvents.map(({ title, id }) => (
            <option key={id} value={id}>
              {title}
            </option>
          ))}
        </select>
        <div className={caratCss}>
          <Icon name="chevron-down" />
        </div>
      </div>
    </>
  );
};

FilterDropdownMenu.displayName = 'FilterDropdownMenu';
