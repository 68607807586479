import { css, cx } from '@emotion/css';
import { getBackgroundClassName, MessageContext } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useContext } from 'react';

import { backgroundColor, containerCss, headingCss, minHeightCss } from './styles';

const ghostCss = css`
  max-width: 500px;
  width: 80%;
`;

export const NoMatchDefault: FC = () => {
  // Fetch localized content from contentful `global-components` space's Messages collection.
  const { formatMessage } = useContext(MessageContext);
  const title = formatMessage({ id: 'noMatch.title', defaultMessage: 'Well, this is awkward!' });
  const subtitle = formatMessage({
    id: 'noMatch.subtitle',
    defaultMessage: 'We could not find what you were looking for.',
  });

  return (
    <div
      className={cx(getBackgroundClassName(backgroundColor), containerCss, minHeightCss)}
      data-testid="mwp-404-page"
    >
      <h1 className={headingCss}>{title}</h1>
      <h2>{subtitle}</h2>
      <img className={ghostCss} src="/images/404.png" alt="404 Ghost Image" />
    </div>
  );
};

NoMatchDefault.displayName = 'NoMatchDefault';
