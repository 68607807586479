import type {
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
} from '@apollo/client';
import { useQuery } from '@apollo/client';

import { globalComponentsClientName } from '../contentful/contentfulConstants';

export interface ContentfulContext {
  currentLocale: string;
  isPreview: boolean;
  isSSR: boolean;
}

/**
 * Wrapper around `useQuery` hook that adds variables from the GlobalComponentsContext and adds a
 * query context with `clientName` set to `global`.
 */
export function useGlobalComponentsContentfulQuery<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TData = any,
  TVariables extends OperationVariables = OperationVariables
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  context: ContentfulContext,
  options?: QueryHookOptions<TData, Omit<TVariables, 'locale' | 'preview'>>
): QueryResult<TData, TVariables> {
  const { currentLocale, isPreview, isSSR } = context;

  const mergedOptions: QueryHookOptions<TData, TVariables> = {
    ...options,
    context: { clientName: globalComponentsClientName, ...options?.context },
    // @ts-ignore: We assume that all queries require a 'locale' and 'preview' vars.
    variables: {
      locale: currentLocale,
      preview: isPreview,
      ...options?.variables,
    },
    ssr: isSSR ?? options?.ssr,
  };

  return useQuery(query, mergedOptions);
}
