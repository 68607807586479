import { css, cx } from '@emotion/css';
import type { FC, PropsWithChildren } from 'react';

import { Black, Gray, Spacing } from '../../constants';

export interface PageNavProps {
  title?: string;
}

const pageNavTitleCss = css`
  color: ${Gray.V300};
  display: block;
  margin-bottom: ${Spacing.XS}px;
`;

const pageNavLinkCss = css`
  a {
    color: ${Black.V200};
    font-weight: 600;
    text-decoration: none;
  }
`;

const previousCss = css`
  position: absolute;
  padding: 64px;
  span {
    text-align: left;
  }
`;

const nextCss = css`
  position: absolute;
  padding: 64px;
  right: 0;
  span {
    text-align: right;
  }
`;

export const PreviousPage: FC<PropsWithChildren<PageNavProps>> = ({ title, children }) => (
  <div className={cx(previousCss, pageNavLinkCss)}>
    {title && <span className={pageNavTitleCss}>{title}</span>}
    &larr; {children}
  </div>
);

PreviousPage.displayName = 'PageNav';

export const NextPage: FC<PropsWithChildren<PageNavProps>> = ({ title, children }) => (
  <div className={cx(nextCss, pageNavLinkCss)}>
    {title && <span className={pageNavTitleCss}>{title}</span>}
    {children} &rarr;
  </div>
);

NextPage.displayName = 'PageNav';
