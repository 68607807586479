import mapValues from 'lodash/mapValues.js';

import { Spacing } from '../../constants';
import { TileImageSize } from './types';

export const labelLineHeight = 29;
export const labelHeight = labelLineHeight + Spacing.SM;
// This Heading.H500.lineHeight, can't reference it directly because it can be
// a string, a number, or a string[] (wtf???)
export const textLineHeight = 28;

export const imgWidth = 284;
export const imgWidthWithIcon = 260;
export const imgHeight = 461;
export const imgHeightWithIcon = 419;
export const tileWidth = imgWidth;
export const tileHeight = Spacing.SM + imgHeight + labelHeight + 4 * textLineHeight;

export const imageHeightForImageSize = {
  [TileImageSize.Small]: Math.floor(tileWidth / 2),
  [TileImageSize.Medium]: tileWidth,
  [TileImageSize.Large]: imgHeight,
};

export const titleHeightForImageSize = mapValues(imageHeightForImageSize, (height: number) => {
  const preresult = tileHeight - height;
  return preresult - labelHeight;
});

export const titleLinesForImageSize = mapValues(titleHeightForImageSize, (height: number) => {
  return Math.floor(height / textLineHeight);
});

// This is the left/right margin of a tile whenever it is inside a Carousel.
export const tileCarouselMargin = Spacing.MD * 2;
