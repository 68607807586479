import type { MediaDataProps } from '../components/Media';
import type { MediaSds } from '../types/Media';

export const parseMedia = (media?: MediaDataProps | null): MediaSds => {
  if (!media || !media.url) {
    return {};
  }

  if (media.contentType.startsWith('video')) {
    return { videoSource: media.url };
  }

  if (media.contentType.startsWith('image')) {
    return {
      imageSource: media.url,
      imageAltText: media.description,
      imageSize: {
        height: media.height,
        width: media.width,
      },
    };
  }

  return {};
};
