import { Plain } from '../../constants';
import type { MotifComponent } from '../../motif/motifConstants';
import type { MotifComponentProps } from '../../motif/motifTypes';

export const spinnerDarkBgVars: MotifComponentProps<MotifComponent.SPINNER> = {
  '--spinner-fg-color': Plain.WHITE,
};

export const spinnerLightBgVars: MotifComponentProps<MotifComponent.SPINNER> = {
  '--spinner-fg-color': Plain.BLACK,
};
