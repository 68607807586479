import { css } from '@emotion/css';
import { AxisLeft } from '@visx/axis';
import { Group } from '@visx/group';
import type { AnyScaleBand } from '@visx/shape/lib/types';
import type { ReactElement } from 'react';

import { defaultMargin } from '../utils/helpers';

export type AxisLeftGroupLabelProps = {
  yScale: AnyScaleBand;
};

const labelWidth = 90;

/**
 * The height of the label container. Much more height than any reasonable label would need, but
 * this is to ensure that the label is always fully visible and lets us center the label
 * vertically.
 */
const labelHeight = 180;

const labelContainerCss = css`
  display: flex;
  /* Center the label vertically within the container */
  align-items: center;
  overflow-wrap: break-word;
  width: ${labelWidth}px;
  height: ${labelHeight}px;

  p {
    min-width: 0;
    line-height: 1;
    font-weight: bold;
  }
`;

export const AxisLeftGroupLabel = (props: AxisLeftGroupLabelProps): ReactElement => {
  const { yScale } = props;
  return (
    <Group top={defaultMargin.top} left={defaultMargin.left}>
      <AxisLeft
        hideTicks
        scale={yScale}
        stroke={'black'}
        tickStroke={'black'}
        hideAxisLine
        tickLabelProps={() => ({
          fill: 'black',
          fontSize: 14,
          fontWeight: 'bold',
          textAnchor: 'start',
          verticalAnchor: 'middle',
          width: labelWidth,
        })}
        // Override the tickComponent to render a custom label using HTML instead of the default svg <text /> because
        // svg <text /> doesn't support word break wrapping of long words.
        tickComponent={props => {
          return (
            <svg ref={props.innerRef} x={0} y={0} fontSize={props.fontSize}>
              {/* foreignObject lets us render HTML alongside svg elements. Instead of rendering labels using svg <text>,
                we'll use an html <p> so that we can take advantage of the browser's word wrapping capabilities. */}
              <foreignObject
                x={0}
                // Center the label vertically by offsetting the y position by half the label height
                y={props.y - labelHeight / 2}
                width={labelWidth}
                height={labelHeight}
              >
                <div className={labelContainerCss}>
                  <p>{props.formattedValue}</p>
                </div>
              </foreignObject>
            </svg>
          );
        }}
      />
    </Group>
  );
};
