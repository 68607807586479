import type { NavigatorItem } from '@snapchat/mw-contentful-schema';
import type { NavigationItem as SDSNavigationItem } from '@snapchat/snap-design-system-marketing';

import type { RichText } from '../../types/RichText';
import type { CriteriaChecker } from '../Experiment';

// TODO: Delete as part of cleanup for https://jira.sc-corp.net/browse/WEBP-11120

/** Decorator type that tracks whether the current nav item is part of the current navigation path. */
export interface PathAwareNavItem {
  id: string;
  analyticsId?: string;
  title: RichText;
  url?: string;
  isSelected: boolean;
  subItems: PathAwareNavItem[];
  dataset?: DOMStringMap;
}

/** We do not allow nav depths beyond this point. No point in processing the tree past this level. */
const defaultMaxDepth = 3;

/**
 * Processes input from Contentful to a Nav tree that can be rendered:
 *
 * - Removes nav items that should be hidden based on criteria
 * - Flags nav tree items that should be marked active based on current url
 * - Restricts nav tree allowed depth
 */
export const convertToPathAwareNavItems = (
  items?: NavigatorItem[],
  isUrlCurrent?: (url: string) => boolean,
  checkCriteria?: CriteriaChecker,
  maxDepth = defaultMaxDepth
): PathAwareNavItem[] => {
  const output: PathAwareNavItem[] = [];

  for (const item of items ?? []) {
    const node = convertNavItemsInternal(item, isUrlCurrent, checkCriteria, maxDepth);
    node && output.push(node);
  }

  return output;
};

const convertNavItemsInternal = (
  item: NavigatorItem,
  isUrlCurrent?: (url: string) => boolean,
  checkCriteria?: CriteriaChecker,
  maxDepth = defaultMaxDepth,
  parents: PathAwareNavItem[] = []
): PathAwareNavItem | null => {
  const isVisible = checkCriteria?.(item.hideCriteria) ?? true;
  if (!isVisible) return null;

  if (parents.length === maxDepth) return null;

  const node: PathAwareNavItem = {
    id: item.sys.id,
    title: item.title as RichText,
    url: item.url,
    analyticsId: item.analytics?.label,
    isSelected: false,
    subItems: [],
  };

  // handle leaf node
  if (!item.navigatorItemsCollection?.items.length) {
    const isSelected = item.url && isUrlCurrent?.(item.url);

    // mark node and ancestors as selected, and add to breadcrumbs
    if (isSelected) {
      node.isSelected = true;

      parents.forEach(parent => {
        parent.isSelected = true;
      });
    }
  } else {
    // handle trunk node
    for (const child of item.navigatorItemsCollection.items) {
      const ancestors = [...parents, node];
      const subItem = convertNavItemsInternal(
        child,
        isUrlCurrent,
        checkCriteria,
        maxDepth,
        ancestors
      );
      subItem && node.subItems.push(subItem);
    }
  }

  return node;
};

/**
 * Helper function that transforms input into navigation tree to type used by
 * GlobalHeader.headerNavigationTree prop
 */
// eslint-disable-next-line import/no-unused-modules
export function transformNavItems(item: NavigatorItem): SDSNavigationItem {
  return {
    id: item.sys.id,
    url: item.url,
    subItems: item.navigatorItemsCollection?.items.map(transformNavItems),
  };
}
