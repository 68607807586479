import { BrowserFeaturesContext } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useContext } from 'react';
import { Helmet } from 'react-helmet-async';

export const Viewport: FC = () => {
  const features = useContext(BrowserFeaturesContext);
  let viewport = 'width=device-width, initial-scale=1';

  const isIphone = features.getLowEntropyHints().platform === 'iOS';

  if (isIphone) {
    viewport += ', viewport-fit=cover';
  }

  return (
    <Helmet>
      <meta name="viewport" content={viewport} />
    </Helmet>
  );
};
