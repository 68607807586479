import type { ValueOf } from '../types';

export const Alignment = {
  Left: 'Left',
  Start: 'Start',
  Center: 'Center',
  End: 'End',
  Right: 'Right',
} as const;

export type Alignment = ValueOf<typeof Alignment>;

export const VerticalAlignment = {
  Top: 'Top',
  Middle: 'Middle',
  Bottom: 'Bottom',
};

export type VerticalAlignment = ValueOf<typeof VerticalAlignment>;

export const VideoPanelAlignment = {
  TopRight: 'TopRight',
  TopLeft: 'TopLeft',
  TopCenter: 'TopCenter',
  BottomLeft: 'BottomLeft',
  BottomRight: 'BottomRight',
  BottomCenter: 'BottomCenter',
};

export type VideoPanelAlignment = ValueOf<typeof VideoPanelAlignment>;
