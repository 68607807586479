import { Black, Gray, Plain, Yellow } from '../../constants';
import type { MotifComponent } from '../../motif/motifConstants';
import type { MotifComponentProps } from '../../motif/motifTypes';

// NOTE: Style inspiration taken from:
// https://www.figma.com/file/XZO1oDmIMJMWZ1aof1TWFD/SDS-Marketing---Buttons?type=design&node-id=0-1&mode=design&t=SvVssh7qqdov17ce-0
// There have been many updates since then. I.e. no more "primary" color. These are always either
// black or white.

const commonVars = {
  '--icon-button-border-width': '2px',
};

export const contrastBlackIconButtonVars: MotifComponentProps<MotifComponent.ICON_BUTTON> = {
  ...commonVars,
  '--icon-button-fg-color': Black.V100,
  '--icon-button-bg-color': Plain.WHITE,
  '--icon-button-border-color': Black.V100,
  '--icon-button-hover-bg-color': Plain.WHITE,
  '--icon-button-hover-border-color': Yellow.V150,
  '--icon-button-disabled-bg-color': Black.V100,
  '--icon-button-disabled-fg-color': Black.V200,
  '--icon-button-disabled-border-color': Black.V150,
};

export const contrastWhiteIconButtonVars: MotifComponentProps<MotifComponent.ICON_BUTTON> = {
  ...commonVars,
  '--icon-button-fg-color': Plain.WHITE,
  '--icon-button-bg-color': Black.V150,
  '--icon-button-border-color': Plain.WHITE,
  '--icon-button-hover-bg-color': Black.V150,
  '--icon-button-hover-border-color': Yellow.V150,
  '--icon-button-disabled-bg-color': Gray.V100,
  '--icon-button-disabled-fg-color': Gray.V300,
  '--icon-button-disabled-border-color': Gray.V250,
};
