import { Black, Gray, Plain } from '../../constants';

export const paginationDarkBgVars = {
  '--pagination-text-active-color': Plain.WHITE,
  '--pagination-text-color': Gray.V250,
  '--pagination-text-hover-color': Gray.V150,
};

export const paginationLightBgVars = {
  '--pagination-text-active-color': Plain.BLACK,
  '--pagination-text-color': Black.V100,
  '--pagination-text-hover-color': Gray.V250,
};
