import { cx } from '@emotion/css';
import type { FC } from 'react';
import type React from 'react';
import { memo, useCallback } from 'react';

import { ToggleButton } from '../ToggleButton';
import { filtersCss } from './styles';
import type { MosaicFiltersProps } from './types';

export const MosaicFilters: FC<MosaicFiltersProps> = memo(
  ({ filters, currentFilter, onSelectFilter }) => {
    const handleClick = useCallback(
      (filterId: string) => () => {
        onSelectFilter(currentFilter === filterId ? '' : filterId);
      },
      [currentFilter, onSelectFilter]
    );

    const handleKeyPress = useCallback(
      (filterId: string) => (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
          onSelectFilter(currentFilter === filterId ? '' : filterId);
        }

        if (event.key === 'Escape') {
          onSelectFilter('');
        }
      },
      [currentFilter, onSelectFilter]
    );

    return (
      <div className={cx(filtersCss)}>
        {filters.map(({ id, icon, text }, i) => {
          return (
            <div
              key={`${id}-${i}`}
              aria-pressed={currentFilter === id}
              onKeyPress={handleKeyPress(id)}
              role="button"
              tabIndex={0}
            >
              {icon && (
                <ToggleButton
                  icon={icon}
                  onClick={handleClick(id)}
                  selected={currentFilter === id}
                  id={id}
                  index={i}
                  text={text}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  }
);

MosaicFilters.displayName = 'MosaicFilters';
