export enum ScreenSize {
  width_320_mobile_small = 320,
  width_375_mobile_medium = 375,
  width_425_mobile_large = 425,
  width_768_desktop_small = 768, // Matches mobile/desktop breakpoint at SDS-M
  width_1024_desktop_medium = 1024,
  width_1440_desktop_large = 1440,
  width_2560_desktop_larger = 2560,
}

const assetSizesDesc = [
  ScreenSize.width_2560_desktop_larger,
  ScreenSize.width_1440_desktop_large,
  ScreenSize.width_1024_desktop_medium,
  ScreenSize.width_768_desktop_small,
  ScreenSize.width_425_mobile_large,
  ScreenSize.width_375_mobile_medium,
  ScreenSize.width_320_mobile_small,
];

export const assetSizesAsc = assetSizesDesc.reverse();
