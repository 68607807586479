import { cx } from '@emotion/css';
import type { FC } from 'react';

import { Icon } from '../Icon';
import {
  dropdownButtonCss,
  dropdownButtonIconCss,
  dropdownButtonIconUpCss,
} from './DropdownButton.styles';
import type { DropdownMenuButtonProps } from './types';

/**
 * Button to be used to trigger a dropdown menu.
 *
 * Any button can be used in its place.
 */
export const DropdownButton: FC<DropdownMenuButtonProps> = ({
  onClick,
  isExpanded,
  children,
  ariaLabel,
}) => {
  return (
    <button
      className={cx('sdsm-dropdown-button', dropdownButtonCss)}
      onClick={onClick}
      role="combobox"
      aria-expanded={isExpanded}
      aria-haspopup="listbox"
      aria-label={ariaLabel}
    >
      {children}
      <Icon
        size={20}
        className={cx(dropdownButtonIconCss, { [dropdownButtonIconUpCss]: isExpanded })}
        name="chevron-down"
      />
    </button>
  );
};
