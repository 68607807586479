import { gql } from '@apollo/client';

import type { ContentfulTypedSysProps } from '../../types/contentful';
import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';

// =================================================================================================
// Redirect Fragment
// =================================================================================================

export interface RedirectDataProps extends ContentfulTypedSysProps<'Redirect'> {
  externalUrl?: string;
  isPermanent?: boolean;
  slug?: ContentfulTypedSysProps<'Slug'> & { slug: string };
}

export const redirectFragment = gql`
  fragment Redirect on Redirect {
    ...ContentfulSysId
    externalUrl
    isPermanent
    slug {
      sys {
        id
      }
      slug
    }
  }
  ${contentfulSysIdFragment}
`;

// =================================================================================================
// Redirect SiteMap Fragment
// =================================================================================================
export const redirectSitemapFragment = gql`
  fragment SiteMapRedirect on Redirect {
    ...ContentfulSysId
  }
  ${contentfulSysIdFragment}
`;

export type RedirectSitemapData = ContentfulTypedSysProps<'Redirect'>;
