import { gql } from '@apollo/client';
import type { Criteria, Experiment } from '@snapchat/mw-contentful-schema';

import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';
import type { ExperimentSitemapData } from '../Experiment/experimentQuery';
import {
  createExperimentFragmentNoAb,
  createExperimentFragmentWithAbReplacements,
  criteriaFragment,
  experimentSitemapFragment,
} from '../Experiment/experimentQuery';
import type { PageAssetDataProps } from '../Page/pageAssetQuery';
import { pageAssetFragment } from '../Page/pageAssetQuery';
import type { PageShallowDataProps, PageSitemapData } from '../Page/pageQuery';
import { pageFragmentShallow, pageSitemapFragment } from '../Page/pageQuery';
import type { RedirectDataProps, RedirectSitemapData } from '../Redirect/redirectQuery';
import { redirectFragment, redirectSitemapFragment } from '../Redirect/redirectQuery';

// =================================================================================================
// Slug Shallow Fragment
// =================================================================================================

export interface SlugShallowData {
  __typename: 'Slug';
  sys: {
    id: string;
    publishedAt?: string;
    firstPublishedAt?: string;
  };
  slug: string;
  page: PageShallowDataProps | Experiment | PageAssetDataProps | RedirectDataProps;
  criteria?: Criteria;
  shareable?: boolean;
  postedDate?: string;
}

export const slugShallowFragment = gql`
  fragment Slug on Slug {
    sys {
      id
      publishedAt
      firstPublishedAt
    }
    __typename
    slug
    postedDate
    page {
      ...PageAssetAll
      ...ExperimentOnPageLoadingPageShallow
      ...ExperimentOnRedirectLoadingRedirect
      ...Redirect
    }
    unlistedCriteria {
      ...CriteriaAll
    }
    shareable
  }
  ${createExperimentFragmentWithAbReplacements('Page', 'PageShallow', pageFragmentShallow)}
  ${createExperimentFragmentNoAb('Redirect', 'Redirect', redirectFragment)}
  ${contentfulSysIdFragment}
  ${pageAssetFragment}
  ${redirectFragment}
`;

// =================================================================================================
// Slug Sitemap Fragment
// =================================================================================================

export const slugSitemapFragment = gql`
  fragment SiteMapSlug on Slug {
    sys {
      id
      firstPublishedAt
    }
    __typename
    postedDate
    slug
    page {
      ...SiteMapPage
      ...SiteMapExperiment
      ...SiteMapRedirect
    }
    unlistedCriteria {
      ...CriteriaAll
    }
  }
  ${pageSitemapFragment}
  ${experimentSitemapFragment}
  ${redirectSitemapFragment}
  ${criteriaFragment}
`;

/** Slug content model optimized for generating site map data. */
export interface SlugSitemapData {
  __typename: 'Slug';
  sys: {
    id: string;
    firstPublishedAt?: string;
  };
  postedDate?: string;
  slug: string;
  page?: PageSitemapData | ExperimentSitemapData | RedirectSitemapData;
  unlistedCriteria?: Criteria;
}
