import map from 'lodash-es/map';
import unescape from 'lodash-es/unescape';
import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';

export interface HeaderProps {
  title?: string;
  description?: string;
  keywords?: string[];
  ogImage?: string;
  ogVideo?: string;
  noIndex?: boolean;
  noFollow?: boolean;
  customMetas?: {
    name: string;
    content: string;
  }[];
}

export const Header: FC<HeaderProps> = ({
  title,
  description,
  keywords,
  ogImage,
  ogVideo,
  noIndex,
  noFollow,
  customMetas,
}) => {
  let robots: string | undefined;

  if (noIndex && noFollow) {
    robots = 'noindex, nofollow';
  } else if (noIndex) {
    robots = 'noindex';
  } else if (noFollow) {
    robots = 'nofollow';
  }

  return (
    // @ts-ignore - Need to update to latest to support children type with React 18.
    <Helmet>
      {!!title && <title>{unescape(title)}</title>}
      {!!title && <meta property="og:title" content={unescape(title)} />}
      {!!description && <meta name="description" content={unescape(description)} />}
      {!!description && <meta property="og:description" content={unescape(description)} />}
      {!!keywords && <meta name="keywords" content={unescape(keywords.join(','))} />}
      {!!ogImage && <meta property="og:image" content={ogImage} />}
      {!!ogImage && <meta property="og:image:secure_url" content={ogImage} />}
      {!!ogVideo && <meta property="og:video" content={ogVideo} />}
      {!!ogVideo && <meta property="og:video:secure_url" content={ogVideo} />}
      {!!robots && <meta name="robots" content={robots} />}
      {!!customMetas &&
        map(customMetas, customMeta => (
          <meta key={customMeta.name} name={customMeta.name} content={customMeta.content} />
        ))}
    </Helmet>
  );
};

Header.displayName = 'Header';
