import { GlobalNavItem as GlobalNavItemSDS } from '@snapchat/snap-design-system-marketing';
import type { FC, MouseEventHandler } from 'react';
import { useContext } from 'react';

import { Action } from '../../analytics/Action';
import { GlobalComponentsContext } from '../../context/GlobalComponentsContext';
import type { GlobalNavItemAllType } from '../../generated/contentful-types';

const isHiddenNavItem = (hideHostnameRegex: string | undefined, hostname: string) => {
  return !!hideHostnameRegex && new RegExp(hideHostnameRegex).test(hostname);
};

export const GlobalNavItem: FC<GlobalNavItemAllType> = ({
  hideHostnameRegex,
  title,
  url,
  analytics,
}) => {
  const { onEvent } = useContext(GlobalComponentsContext);

  const onClick: MouseEventHandler = (): void => {
    if (onEvent) {
      onEvent({
        action: Action.Click,
        component: 'GlobalNavItem',
        label: analytics?.label,
        url,
      });
    }
  };

  return !isHiddenNavItem(hideHostnameRegex, window.location.hostname) ? (
    <GlobalNavItemSDS title={title} href={url} showExternalIcon={false} onClick={onClick} />
  ) : null;
};

GlobalNavItem.displayName = 'GlobalNavItem';
