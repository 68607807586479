import { css, cx } from '@emotion/css';
import type { FC, PropsWithChildren, ReactElement } from 'react';
import { Children, cloneElement, useEffect } from 'react';

const scrollSnapItemCss = css`
  scroll-snap-align: center;
`;

interface SliderElement extends ReactElement {
  className: string;
}

export const ScrollSnapContainer: FC<PropsWithChildren> = ({ children }) => {
  useEffect(() => {
    if (typeof window === 'undefined') return;

    const html = document.getElementsByTagName('html')[0]!;
    const body = document.body;
    // NOTE: We want scroll snap to be view height so it needs to be added to the html element.
    // Though it's possible the style needs to be on the body.
    // See https://css-tricks.com/practical-css-scroll-snapping/#aa-example-3-vertical-full-screen
    // TODO: This doesn't seem to work anymore. Tracking ticket: https://jira.sc-corp.net/browse/ENTWEB-8974
    html.style.scrollSnapType = 'y proximity';
    body.style.scrollSnapType = 'y proximity';

    return () => {
      html.style.scrollSnapType = 'none';
      body.style.scrollSnapType = 'none';
    };
  }, []);

  const childrenArray = Children.toArray(children);

  return (
    <>
      {children &&
        childrenArray.map((child, index) => {
          const sliderChild = child as SliderElement;
          const isLastItem = index === childrenArray.length - 1;
          return cloneElement(sliderChild, {
            className: cx({ [scrollSnapItemCss]: !isLastItem }, sliderChild.className),
          });
        })}
    </>
  );
};
