import { Gray, Plain, Yellow } from '../../constants';
import type { MotifComponent, MotifComponentProps } from '../../motif';

export const progressBarBlackBgVars: MotifComponentProps<MotifComponent.PROGRESS_BAR> = {
  '--progress-bar-background-color': Gray.V200,
  '--progress-bar-progress-color': Yellow.V100,
};

export const progressBarWhiteBgVars: MotifComponentProps<MotifComponent.PROGRESS_BAR> = {
  '--progress-bar-background-color': Gray.V200,
  '--progress-bar-progress-color': Yellow.V100,
};

export const progressBarGrayBgVars: MotifComponentProps<MotifComponent.PROGRESS_BAR> = {
  '--progress-bar-background-color': Plain.WHITE,
  '--progress-bar-progress-color': Yellow.V100,
};

export const progressBarYellowBgVars: MotifComponentProps<MotifComponent.PROGRESS_BAR> = {
  '--progress-bar-background-color': Plain.WHITE,
  '--progress-bar-progress-color': Gray.V250,
};
