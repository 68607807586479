import { cx } from '@emotion/css';
import { htmlEscape } from '@snapchat/core';
import type { FC } from 'react';
import { useEffect } from 'react';
import type { RouteProps } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { Config } from '../../config';
import { LayoutContext } from '../../contexts/LayoutContext';
import { logWarning } from '../../helpers/logging';
import { Feature, useFeatureFlags } from '../FeatureFlags';
import { SideNavigationShallow } from '../SideNavigation';
import { hasSideNavCss, minHeightCss } from './Slug.styles';
import { SlugCollection } from './SlugCollection';
import { useSlugToNavMap } from './useSlugToNavMap';

export const Slug: FC<RouteProps> = () => {
  const location = useLocation();
  // NOTE: We don't use the useParams() hook here because we want to check
  // the pathname exactly. Otherwise we run into issues like "///foo" not matching
  // routes like "/:slug*", and router thinking it should be a homepage match.
  // See https://jira.sc-corp.net/browse/WEBP-9117
  const originalSlug = location.pathname.substring(1);
  const slug = originalSlug === '' ? 'home' : htmlEscape(originalSlug);
  const featureFlags = useFeatureFlags();

  // TODO: Delete once we no longer need this functionality, after snapchat
  // home is fully migrated to consumer web.
  const shouldRedirectHome = featureFlags[Feature.HOME_REDIRECT] === 'true';

  useEffect(() => {
    if (shouldRedirectHome && originalSlug === 'home' && Config.isClient) {
      const url = new URL(window.location.href);
      url.pathname = '';
      window.history.replaceState(null, '', url); // or pushState
    }
  }, [originalSlug, shouldRedirectHome]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [slug]);

  const { sideNavMap, subNavMap } = useSlugToNavMap();

  const hasSideNav = sideNavMap.has(slug);
  const sideNavId = sideNavMap.get(slug)?.sys.id;
  const hasSubNav = subNavMap.has(slug);
  const subNavId = subNavMap.get(slug)?.sys.id;

  if (sideNavId && subNavId) {
    logWarning({
      component: 'Slug',
      message: `Having both a SideNav and a SubNav isn't supported.`,
      context: { slug },
    });
  }

  // NOTE: There can be only 1 <main> in the HTML and this is it.
  // TODO: Technically, the Page should have the <main>, but SDS-M uses a regular <div>.
  return (
    <div
      data-testid="mwp-slug"
      className={cx(
        {
          [hasSideNavCss]: hasSideNav,
        },
        minHeightCss
      )}
    >
      <LayoutContext.Provider value={{ hasSideNav, hasSubNav }}>
        {sideNavId && <SideNavigationShallow sys={{ id: sideNavId }} />}
        <SlugCollection key={slug} slug={slug} />
      </LayoutContext.Provider>
    </div>
  );
};
