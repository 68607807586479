import { css } from '@emotion/css';

import { largeDesktopMediaQuery, nonMobileMediaQuery } from '../../constants';
import { m } from '../../motif';

export const galleryV2Css = css`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: ${m('--spacing-m')};
  margin-bottom: ${m('--spacing-m')};
  margin-inline: auto;

  ${largeDesktopMediaQuery} {
    gap: ${m('--spacing-l')};
    margin-bottom: ${m('--spacing-l')};
  }

  > * {
    width: 100%;

    ${nonMobileMediaQuery} {
      width: calc(50% - ${m('--spacing-xs')});
    }

    ${largeDesktopMediaQuery} {
      width: calc(50% - ${m('--spacing-m')});
    }
  }
`;
