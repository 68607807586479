import type { FC } from 'react';

import type { BreakPatternProps } from './breakPatternTypes';

export const SkirtBreak: FC<BreakPatternProps> = props => {
  const { topColor, bottomColor, ...rest } = props;
  return (
    <svg
      width="1440"
      height="96"
      viewBox="0 0 1440 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      role="presentation"
      {...rest}
    >
      <path
        d="M314.31 47.5299C510.98 36.2699 546.9 80.1799 818.11 81.2999C935
        82.0499 1223.03 67.04 1440 1V0H0V54.2899C27.99 55.7899 117.64 58.7899
        314.31 47.5399V47.5299Z"
        fill={topColor}
      />
      <path
        d="M818.11 80.31C546.9 79.18 510.98 35.28 314.31 46.54C117.64 57.8 27.99
        54.8 0 53.29V96H1440V0C1223.03 66.04 935 81.05 818.11 80.3V80.31Z"
        fill={bottomColor}
      />
    </svg>
  );
};
