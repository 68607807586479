import { cx, injectGlobal } from '@emotion/css';
import { ControlledMenu, MenuItem, SubMenu } from '@szhsin/react-menu';
import size from 'lodash/size.js';
import type { FC, KeyboardEventHandler } from 'react';
import { Children, useContext, useEffect, useRef, useState } from 'react';

import { BackgroundColor } from '../../../constants';
import { MotifComponent, useMotifStyles } from '../../../motif';
import { dataSetToAttributes, getBackgroundClassName } from '../../../utils';
import { GlobalHeaderContext } from '../../GlobalHeader/GlobalHeaderContext';
import { Icon } from '../../Icon';
import type { AnchorComponent } from '../../Primitives';
import { PrimitivesContext } from '../../Primitives';
import {
  anchorCss,
  clickableNavigatorItemClassName,
  iconCss,
  itemCss,
  menuItemCss,
  navItemSelectedCss,
  reactMenuCss,
} from './Item.styled';
import type { NavigatorItemProps } from './types';
import { getItemProps } from './utils';

/**
 * Helper function to avoid the cludge needed when wrapping @szhsin/react-menu components
 *
 * NOTE: It's IMPOSSIBLE to make this into a component. It throws exceptions when you do saying it
 * shouldn't be a component.
 */
const renderChildrenWithBreadcrumbs = (
  props: NavigatorItemProps,
  Anchor: AnchorComponent,
  navigationBreadcrumbs?: Set<string>
) => {
  const { id, title, url, isSelected, children, onClick } = props;
  const isMenuItem = Children.count(children) === 0;
  const isSelectedBreadcrumb = navigationBreadcrumbs?.has(id) ?? false;
  const shouldSelect = isSelected ?? isSelectedBreadcrumb;

  if (isMenuItem) {
    return (
      <MenuItem
        key={id}
        onClick={event => onClick?.(event.syntheticEvent)}
        className={cx(menuItemCss, { [navItemSelectedCss]: shouldSelect })}
      >
        {url ? (
          <Anchor className={anchorCss} href={url}>
            {title}
          </Anchor>
        ) : (
          <span className={anchorCss}>{title}</span>
        )}
      </MenuItem>
    );
  }

  return (
    <SubMenu
      key={id}
      label={title}
      itemProps={{ onClick }}
      className={cx(menuItemCss, { [navItemSelectedCss]: shouldSelect })}
    >
      {Children.map(children ?? [], ({ props }) =>
        renderChildrenWithBreadcrumbs(props, Anchor, navigationBreadcrumbs)
      )}
    </SubMenu>
  );
};

// inject styles to global at import (needed for SSR)
injectGlobal(reactMenuCss);

export const ItemDesktop: FC<NavigatorItemProps> = ({
  id,
  children,
  url,
  target,
  onClick,
  isSelected,
  title,
  dataset,
}) => {
  useMotifStyles(MotifComponent.HEADER);
  const { Anchor } = useContext(PrimitivesContext);

  const { navigationBreadcrumbs } = useContext(GlobalHeaderContext);

  const [shouldSelect, setShouldSelect] = useState(isSelected ?? false);

  useEffect(() => {
    const isSelectedBreadcrumb = navigationBreadcrumbs?.has(id) ?? false;
    setShouldSelect(isSelected === undefined ? isSelectedBreadcrumb : isSelected);
  }, [id, isSelected, navigationBreadcrumbs]);

  const [isExpanded, setIsExpanded] = useState(false);
  const ref = useRef(null);
  const [menuPosition, setPosition] = useState<'first' | 'last' | number>('first');
  const iconName = isExpanded ? 'chevron-up' : 'chevron-down';

  const clickableItemClassName = onClick || url ? clickableNavigatorItemClassName : null;
  const titleClassName = cx({ selected: shouldSelect });
  const linkOrText = <span className={titleClassName}>{title}</span>;

  const items = getItemProps(children, id);

  const handleKeyPress: KeyboardEventHandler<HTMLElement> = e => {
    // Only move dropdown nav up/down
    e.stopPropagation();

    if (e.key === 'Enter' || e.key === ' ' || e.key === 'ArrowDown') {
      setIsExpanded(true);
      setPosition('first');
    } else if (e.key === 'ArrowUp') {
      setIsExpanded(true);
      setPosition('last');
    }
  };

  if (items && size(items)) {
    return (
      <section className={MotifComponent.HEADER}>
        <Anchor
          href={url}
          target={target}
          className={cx(itemCss, clickableItemClassName)}
          onClick={event => {
            setIsExpanded(true);
            onClick?.(event);
          }}
          ref={ref}
          onMouseEnter={() => setIsExpanded(true)}
          onMouseLeave={() => setIsExpanded(false)}
          onKeyDown={handleKeyPress}
          tabIndex={0}
          {...dataSetToAttributes(dataset)}
        >
          {linkOrText}
          <Icon size={24} className={iconCss} name={iconName} />
        </Anchor>
        <ControlledMenu
          className={
            /* Dropdown background is always white, so we need styles to match it. */
            getBackgroundClassName(BackgroundColor.White)
          }
          state={isExpanded ? 'open' : 'closed'}
          anchorRef={ref}
          onMouseEnter={() => setIsExpanded(true)}
          onMouseLeave={() => setIsExpanded(false)}
          onClose={() => setIsExpanded(false)}
          menuItemFocus={{
            position: menuPosition,
          }}
        >
          {Children.map(children ?? [], ({ props }) => {
            return renderChildrenWithBreadcrumbs(props, Anchor, navigationBreadcrumbs);
          })}
        </ControlledMenu>
      </section>
    );
  }

  return (
    <Anchor
      href={url}
      className={cx(MotifComponent.HEADER, itemCss, clickableItemClassName)}
      onClick={onClick}
      tabIndex={0}
      {...dataSetToAttributes(dataset)}
    >
      {linkOrText}
    </Anchor>
  );
};

ItemDesktop.displayName = 'Navigator.Item (Desktop)';
