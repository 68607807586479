import type { FC } from 'react';
import { useContext } from 'react';

import { DesignSystemContext } from '../../context/DesignSystemContext';

export interface DesignAgnosticToggleProps {
  id: string;
  isChecked: boolean;
  onToggle?: () => void;
}

export const DesignAgnosticToggle: FC<DesignAgnosticToggleProps> = props => {
  const { toggleComponent } = useContext(DesignSystemContext);
  return toggleComponent(props);
};
