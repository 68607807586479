import { gql } from '@apollo/client';

import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';

export const seriesFragment = gql`
  fragment SeriesAll on Series {
    ...ContentfulSysId
    schema
    headerNames
    localizableProperties
  }
`;

export const chartDataFragment = gql`
  fragment ChartDataAll on ChartData {
    ...ContentfulSysId
    seriesName {
      ...SeriesAll
    }
    label
    data
    localizedData
  }
  ${contentfulSysIdFragment}
  ${seriesFragment}
`;
