import { gql } from '@apollo/client';

import type { ContentfulTypedSysProps } from '../../types/contentful';
import type { RichText } from '../../types/RichText';
import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';

// =================================================================================================
// FAQ Page Schema Fragment
// =================================================================================================

export const faqPageSchemaFragment = gql`
  fragment FaqPageSchemaAll on FaqPageSchema {
    itemsCollection {
      items {
        ...ContentfulSysId
        question
        answer {
          json
        }
      }
    }
  }
  ${contentfulSysIdFragment}
`;

export interface FaqPageSchemaData extends ContentfulTypedSysProps<'FaqPageSchema'> {
  itemsCollection?: {
    items?: {
      question: string;
      answer: RichText;
    }[];
  };
}
