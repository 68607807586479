import type { ScaleInput } from '@visx/scale';
import type {
  AnimatedAxis,
  AnimatedGrid,
  Axis,
  AxisScale,
  Grid,
  Tooltip,
  XYChart,
} from '@visx/xychart';
import type { ComponentProps, ReactElement } from 'react';

import type { OnActivateHandler } from '../../types/activationEvents';

type XyChartProps = ComponentProps<typeof XYChart>;

export type RequiredChartProps<Datum, XScale extends AxisScale, YScale extends AxisScale> = {
  data: Datum[];
  /** Object with functions to control what data is used to render the chart */
  accessors: {
    /** Function to control what data is used to render the x-axis in the chart */
    xAccessor: (d: Datum) => ScaleInput<XScale>;
    /** Function to control what data is used to render the y-axis in the chart */
    yAccessor: (d: Datum) => ScaleInput<YScale>;
  };
};

export type OptionalChartProps = {
  chartTitle?: string;
  /**
   * Instance of the visx Axis component to have full control, pass a fragment with axis children if
   * you would like multiple. Default is left and bottom
   */
  axis?: ReactElement<typeof AnimatedAxis | typeof Axis>;
  /** Instance of the visx Grid component to have full control. Default is horizontal lines */
  grid?: ReactElement<typeof AnimatedGrid | typeof Grid>;
  /**
   * Instance of the visx Tooltip component to have full control. Default is a title with value from
   * xAccessor and body with value from yAccessor
   */
  tooltip?: ReactElement<typeof Tooltip>;
  locale?: string;
};

export type BaseXyChartProps<
  XScale extends AxisScale,
  YScale extends AxisScale,
  Datum extends object
> = Omit<XyChartProps, 'children'> & OptionalChartProps & RequiredChartProps<Datum, XScale, YScale>;

export type BarData<XScale extends AxisScale, YScale extends AxisScale, Datum extends object> = {
  data: RequiredChartProps<Datum, XScale, YScale>['data'][0];
  accessors: RequiredChartProps<Datum, XScale, YScale>['accessors'];
  dataKey: string;
  dataKeysToColors: Record<string, string>;
  isHorizontal: boolean;
  onClick?: OnActivateHandler;
  locale?: string;
};

export interface LineChartAccessors<Datum, XScale, YScale> {
  x: Record<string, (d: Datum) => XScale>;
  y: Record<string, (d: Datum) => YScale>;
}

/** Identifier (key) and display name (title) for a Chart field */
export type FieldMetadata = {
  key: string;
  title: string;
};
