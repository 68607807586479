import { NoOpTracer } from './noopTracer';
import type { Tracer } from './tracingTypes';

// This is a writeable variable because we can't import both browser and server-side implementations here.
// eslint-disable-next-line prefer-const
let tracer: Tracer = new NoOpTracer();

export function getTracer(): Tracer {
  return tracer;
}

export function setTracer(tracerImpl: Tracer): void {
  tracer = tracerImpl;
}
