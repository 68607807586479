import { css } from '@emotion/css';
import { mobileMediaQuery, nonMobileMediaQuery } from '@snapchat/snap-design-system-marketing';

export const stickToHeaderCss = css`
  ${nonMobileMediaQuery} {
    & > * {
      position: sticky;
      top: var(--total-header-height);
    }
  }

  ${mobileMediaQuery} {
    position: sticky;
    top: var(--total-header-height);
  }
`;
