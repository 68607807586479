import { useContentfulImages } from '@snapchat/mw-contentful-client';
import type { Block as BlockType, BlockContentsItem } from '@snapchat/mw-contentful-schema';
import type { WidthType } from '@snapchat/snap-design-system-marketing';
import { Block as BlockSDS } from '@snapchat/snap-design-system-marketing';
import type { FC, ReactElement } from 'react';
import { useContext } from 'react';

import { logError } from '../../helpers/logging';
import { combineImageSources } from '../../utils/combineImageSources';
import { getContentfulInspectorProps } from '../../utils/contentful/getContentfulInspectorProps';
import { parseMedia } from '../../utils/media';
import { renderRichTextMultiLineWithMarkings } from '../../utils/renderText/renderRichText';
import { Accordion } from '../Accordion';
import { AnimatedAccordion } from '../AnimatedAccordion/AnimatedAccordion';
import { CallToAction } from '../CallToAction';
import { CarouselV3 } from '../CarouselV3';
import { ContentShallow } from '../Content/ContentShallow';
import { FormShallow } from '../Form';
import { LazyGallery } from '../Gallery/LazyGallery';
import type { ImageDataProps } from '../Image';
import { MosaicShallow } from '../Mosaic/MosaicShallow';
import { SlugContext } from '../Slug/SlugContext';
import { Tabs } from '../Tabs';
import type { VideoDataProps } from '../Video/types';
import { BarChartShallow } from '../visualizations/BarChart';
import { ChartToggleWrapper } from '../visualizations/ChartToggle';
import { GeoMapShallow } from '../visualizations/GeoMap';
import { LineChartShallow } from '../visualizations/LineChart';
import { MultiVisualizationShallow } from '../visualizations/MultiVisualization';
import { TableShallow } from '../visualizations/Table';
import type { BlockProps } from './types';

type BlockContentProps = Pick<BlockContentsItem, 'sys' | '__typename'>;

export const BlockContent: FC<BlockContentProps> = (props: BlockContentProps): ReactElement => {
  switch (props.__typename) {
    case 'Gallery': {
      return <LazyGallery {...props} />;
    }

    case 'CarouselV3': {
      return <CarouselV3 {...props} />;
    }

    case 'Tabs': {
      return <Tabs {...props} />;
    }

    case 'Mosaic': {
      return <MosaicShallow {...props} __typename="Mosaic" />;
    }

    case 'Form': {
      return <FormShallow {...props} />;
    }

    case 'ChartToggle': {
      return <ChartToggleWrapper {...props} />;
    }

    case 'BarChart': {
      return <BarChartShallow {...props} />;
    }

    case 'LineChart': {
      return <LineChartShallow {...props} />;
    }

    case 'GeoVisualization': {
      return <GeoMapShallow {...props} />;
    }

    case 'VisualizationSelector': {
      return <MultiVisualizationShallow {...props} />;
    }

    case 'TableVisualization': {
      return <TableShallow {...props} />;
    }

    case 'Content': {
      return <ContentShallow {...props} />;
    }

    case 'Accordion': {
      return <Accordion {...props} />;
    }

    case 'AnimatedAccordion': {
      return <AnimatedAccordion {...props} />;
    }

    default: {
      logError({
        component: 'Block',
        message: `Unknown content type: ${props.__typename} with id: ${props.sys.id}`,
      });

      return <>👿</>;
    }
  }
};
BlockContent.displayName = 'BlockContent';

export const Block: FC<BlockProps> = props => {
  const {
    maxColumns,
    eyebrow,
    title,
    subtitle,
    titleAlignment,
    titleAlignmentMobile,
    contentsCollection,
    callsToActionCollection,
    anchorId,
    isNarrow,
    widthStyle,
    fullHeight,
    backgroundColor,
    backgroundMediaV2,
    theme,
    isNextSameBackgroundColor,
    isPreviousSameBackgroundColor,
    className,
    sys,
    preChildren,
    postChildren,
    showCurtain: givenShowCurtain,
  } = props;
  const { replacements } = useContext(SlugContext);
  let finalContentsCollection = contentsCollection;

  if (contentsCollection?.items.length && replacements) {
    const contentsCollectionCopy = [...contentsCollection.items];

    for (let i = 0; i < contentsCollectionCopy.length; i++) {
      const contentId = contentsCollectionCopy[i]?.sys?.id;
      const contentType = contentsCollectionCopy[i]?.__typename;

      if (contentId && replacements && contentId in replacements) {
        const replacement = replacements[contentId];

        if (replacement === undefined) {
          contentsCollectionCopy.splice(i, 1);
        } else if (replacement && replacement.__typename === contentType) {
          contentsCollectionCopy[i] = {
            sys: { id: replacement.sys.id },
            __typename: replacement.__typename,
          };
        }
      }
    }

    finalContentsCollection = { items: contentsCollectionCopy };
  }

  const backgroundAssetMedia = (backgroundMediaV2 as ImageDataProps | VideoDataProps)?.media;
  const { imageSource: backgroundImageSource, videoSource: backgroundVideoSource } =
    parseMedia(backgroundAssetMedia);

  const backgroundMobileAssetMedia = (backgroundMediaV2 as ImageDataProps | VideoDataProps)
    ?.mobileMedia;
  const { imageSource: mobileBackgroundImageSource, videoSource: mobileBackgroundVideoSource } =
    parseMedia(backgroundMobileAssetMedia);

  const blockChildren = (finalContentsCollection?.items ?? []).map(item => (
    <BlockContent key={item.sys.id} {...(item as BlockContentProps)} />
  ));

  const callsToAction = callsToActionCollection.items.map(item => (
    <CallToAction key={item.sys.id} {...item} />
  ));

  const { getImageSources } = useContentfulImages();

  const bgImgSrc = combineImageSources({
    desktop: getImageSources(backgroundImageSource),
    mobile: getImageSources(mobileBackgroundImageSource),
  });

  const inspectorDatasets = getContentfulInspectorProps<BlockType>({
    entryId: sys.id,
    fieldIds: ['eyebrow', 'title', 'subtitle'],
  });

  // We are checking if showCurtain is different from false
  // because we need to take in consideration the undefined value,
  // if its undefined and there is a background image or video, the curtain should be shown too.
  const showCurtain = (!!bgImgSrc || !!backgroundVideoSource) && givenShowCurtain !== false;

  return (
    <BlockSDS
      callsToAction={callsToAction}
      anchorId={anchorId}
      maxColumns={maxColumns ?? undefined}
      subtitle={renderRichTextMultiLineWithMarkings(subtitle)}
      eyebrow={eyebrow}
      title={renderRichTextMultiLineWithMarkings(title)}
      titleAlignment={titleAlignment}
      titleAlignmentMobile={titleAlignmentMobile}
      widthStyle={(widthStyle as WidthType) ?? (isNarrow ? 'narrow' : undefined)}
      backgroundColor={backgroundColor ?? theme}
      isNextSameBackgroundColor={isNextSameBackgroundColor}
      isPreviousSameBackgroundColor={isPreviousSameBackgroundColor}
      fullHeight={fullHeight}
      className={className}
      {...inspectorDatasets}
      preChildren={preChildren}
      postChildren={postChildren}
      backgroundVideoSource={backgroundVideoSource}
      mobileBackgroundVideoSource={mobileBackgroundVideoSource}
      backgroundImageSources={bgImgSrc}
      showCurtain={showCurtain}
    >
      {blockChildren}
    </BlockSDS>
  );
};

Block.displayName = 'Block';
