import type { CookieCategoryType } from '../generated/contentful-types';

/** Helper function that returns a cookie state where user has accepted all cookie categories. */
export const acceptAllCookies = (
  categories: readonly CookieCategoryType[]
): Record<string, boolean> => {
  const output: Record<string, boolean> = {};

  for (const category of categories) {
    const { categoryCookieName } = category;
    output[categoryCookieName] = true;
  }

  return output;
};

/**
 * Helper function that returns a cookie state where user has accepted only the essential cookie
 * categories.
 */
export const essentialOnlyCookies = (
  categories: readonly CookieCategoryType[]
): Record<string, boolean> => {
  const output: Record<string, boolean> = {};

  for (const category of categories) {
    const { categoryCookieName, isEssential } = category;
    output[categoryCookieName] = isEssential;
  }

  return output;
};

/**
 * Helper function that returns a cookie state where user has a opted into some categories but not
 * others. Includes logic to default to opting out of cookie categories if not explicitly set in the
 * custom settings.
 */
export const acceptCustomCookies = (
  categories: readonly CookieCategoryType[],
  custom: Record<string, boolean>
): Record<string, boolean> => {
  const baseline = essentialOnlyCookies(categories);
  const merged = { ...baseline, ...custom };
  return merged;
};
