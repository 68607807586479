import { defaultLocale } from '../../helpers/locale';

// TODO: Import this from SDSM
// eslint-disable-next-line import/no-unused-modules
export type Direction = 'rtl' | 'ltr';

/**
 * Codes that are Right-to-left.
 *
 * Based off of this page: https://lingohub.com/academy/best-practices/rtl-language-list
 */
// eslint-disable-next-line import/no-unused-modules
export const rtlLanguageCodes = new Set([
  'ar', //	Arabic
  'arc', //	Aramaic
  'dv', //	Divehi
  'fa', //	Persian
  'ha', //	Hausa
  'he', //	Hebrew
  'khw', //	Khowar
  'ks', //	Kashmiri
  'ku', //	Kurdish
  'ps', //	Pashto
  'ur', //	Urdu
  'yi', // Yiddish
]);

export const directionFromLocale = (locale?: string): Direction => {
  const currentLocale = locale ?? defaultLocale;
  const [languageCode] = currentLocale.split('-');
  return rtlLanguageCodes.has(languageCode as string) ? 'rtl' : 'ltr';
};
