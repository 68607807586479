import type { MutableRefObject, ReactNode } from 'react';

import type { ToggleTarget } from '../../hooks/useToggleState';
import type { BaseComponentProps, BaseInteractiveComponentProps } from '../../types';
import type { IconProps } from '../Icon';

export interface DetailsSummaryProps
  extends BaseComponentProps,
    BaseInteractiveComponentProps<HTMLDetailsElement> {
  showChevron?: boolean;
  onToggle?: (state: ToggleTarget) => void;
  /** Whether to force the open/close state. */
  open?: boolean;
  summary: ReactNode;
  summaryProps?: BaseComponentProps & BaseInteractiveComponentProps<HTMLElement>;
  chevronProps?: Omit<IconProps, 'name'>;
  detailsRef?: MutableRefObject<HTMLDetailsElement | null>;
  // TODO: figure out why there is no HTMLSummaryElement type
  summaryRef?: MutableRefObject<HTMLElement | null>;
  /**
   * We need this prop because the DetailsSummary component defaults to `open` and is set to not be
   * open via javascript if controlled, which sometimes causes onToggle to fire still (some kind of
   * race condition).
   *
   * Cannot reproduce 100% of the time, but is happening often when using Accordion and Animated
   * Accordion. Adding this prop so we can disable it where problematic until we rewrite
   * DetailsSummary
   */
  disableScrollToOnOpen?: boolean;
  /**
   * Whether to play the default fade-in animations.
   *
   * Turn this off to apply custom animations.
   */
  fadeInAnimation?: boolean;

  /** Duration of the ToggleState.ON and ToggleState.OFF. */
  transitionDurationMs?: number;
  /**
   * Used to always have the open attribute present on the <details> element. Useful in some cases
   * where the open attribute is needed for styling purpose, and the parent doesn't want to bother
   * toggling the open attribute state since the height is fixed and animated by CSS (see
   * GlobalNavGroup for an example).
   */
  forceOpenAttribute?: boolean;
}

export interface ChevronProps {
  width?: number;
  height?: number;
  color?: string;
}
