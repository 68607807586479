import type { FC, PropsWithChildren } from 'react';

import type { BaseComponentProps } from '../../types';
import { formRowCss } from './styles';

export const FormRow: FC<BaseComponentProps & PropsWithChildren> = ({ children, ...props }) => (
  <div data-testid="sdsm-form-row" className={formRowCss} {...props}>
    {children}
  </div>
);
