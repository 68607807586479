import { css } from '@emotion/css';

import { mobileMediaQuery, nonMobileMediaQuery } from '../../constants';
import { m } from '../../motif';

const baseStyleCss = css`
  ${nonMobileMediaQuery} {
    font-size: ${m('--action-desktop-font-size')};
    line-height: ${m('--action-desktop-font-line-height')};
    font-weight: ${m('--action-desktop-font-weight')};
  }

  ${mobileMediaQuery} {
    font-size: ${m('--action-mobile-font-size')};
    line-height: ${m('--action-mobile-font-line-height')};
    font-weight: ${m('--action-mobile-font-weight')};
  }
`;

// TODO: Extract the style and apply it conditionally.
const colorStylesCss = css`
  color: ${m('--dropdown-item-fg-color')};
  background-color: transparent;

  &.selected {
    color: ${m('--dropdown-item-fg-active-color')};
    background-color: ${m('--dropdown-item-bg-active-color')};
  }

  /* Has to be listed after selected so it can override. */
  &:hover {
    color: ${m('--dropdown-item-fg-hover-color')};
    background-color: ${m('--dropdown-item-bg-hover-color')};
  }
`;

export const dropdownMenuItemCss = css`
  /* Reset <button> style */
  display: block;
  border: none;

  padding: calc(${m('--dropdown-menu-padding')} / 2) ${m('--dropdown-menu-padding')};
  width: 100%;
  text-align: start;

  ${baseStyleCss}
  ${colorStylesCss}

  position: relative;
  cursor: pointer;
`;
