import type { ValueOf } from '../types';

export const Device = {
  None: 'None',
  Laptop: 'Laptop',
  Phone: 'Phone',
  Shadow: 'Shadow',
} as const;

export type Device = ValueOf<typeof Device>;

export const MediaMode = {
  Desktop: 'Desktop',
  Mobile: 'Mobile',
} as const;

export type MediaMode = ValueOf<typeof MediaMode>;
