import { isBrowser } from './environment';

export function useScrollbarSize(): { width: number; height: number } {
  if (!isBrowser()) {
    return { width: 0, height: 0 };
  }

  const { innerWidth } = window;
  const { body } = document;

  const scrollbarWidth = innerWidth - body.offsetWidth;
  const scrollbarHeight = 0; // TODO(jm)

  return {
    width: scrollbarWidth,
    height: scrollbarHeight,
  };
}
