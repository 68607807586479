import type { Link } from 'contentful-management/dist/typings/common-types';

/**
 * Fields of an entity (either an entry or an asset).
 *
 * NOTE: Ignoring localization here. This is here to help extract field values.
 */
export type EntityFields = Record<string, { 'en-US': unknown }>;

/** Contentful webhook body when an environment alias changes. */
export interface EnvironmentAliasBody {
  sys: {
    id: string;
    type: 'EnvironmentAlias';
    aliasedEnvironment: Link<string>;
    space: Link<string>;
  };
}

/** Contentful webhook body when an entry changes. */
export interface EntryBody {
  metadata: Link<'Tag'>[];
  sys: {
    type: 'Entry';
    id: string;
    space: Link<'Space'>;
    environment: Link<'Environment'>;
    contentType: Link<'ContentType'>;
    createdBy: Link<'User'>;
    updatedBy: Link<'User'>;
    revision?: number;
    createdAt: string;
    updatedAt: string;
    publishedCounter?: number;
    publishedVersion?: number;
    publishedAt?: string;
    firstPublishedAt?: string;
  };
  fields: EntityFields;
}

export interface AssetBody {
  metadata: {
    tags: Link<'Tag'>[];
  };
  sys: {
    type: 'Asset';
    id: string;
    space: Link<'Space'>;
    environment: Link<'Environment'>;
    createdBy: Link<'User'>;
    updatedBy: Link<'User'>;
    revision?: number;
    createdAt: string;
    updatedAt: string;
    publishedCounter?: number;
    publishedVersion?: number;
    publishedAt?: string;
    firstPublishedAt?: string;
  };
  fields: EntityFields;
}

/** Contentful webhook body when an entry is deleted. */
export interface DeleteEntryBody {
  metadata: Link<'Tag'>[];
  sys: {
    type: 'DeletedEntry';
    id: string;
    space: Link<'Space'>;
    environment: Link<'Environment'>;
    contentType: Link<'ContentType'>;
    revision?: number;
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
  };
}

export type ContentfulWebhookBody = EnvironmentAliasBody | EntryBody | DeleteEntryBody | AssetBody;

export function isAliasChangeBody(body: ContentfulWebhookBody): body is EnvironmentAliasBody {
  return body.sys.type === 'EnvironmentAlias';
}

export function isEntryChangeBody(body: ContentfulWebhookBody): body is EntryBody {
  return body.sys.type === 'Entry';
}

export function isAssetChangeBody(body: ContentfulWebhookBody): body is AssetBody {
  return body.sys.type === 'Asset';
}

export function isEntryDeleteBody(body: ContentfulWebhookBody): body is DeleteEntryBody {
  return body.sys.type === 'DeletedEntry';
}

/**
 * Types of action props that contentful webhook api exposes.
 *
 * Inspired by https://www.contentful.com/developers/docs/concepts/webhooks but note that the
 * documentation there isn't complete.
 */
export type ContentfulWebhookProps =
  | ContentfulWebhookContentProps
  | ContentfulWebhookSpaceManagementProps
  | ContentfulWebhookReleaseProps;

export interface ContentfulWebhookContentProps {
  api: 'ContentManagement';
  entity: 'Entry' | 'ContentType' | 'Asset' | 'Task' | 'Comment' | 'Release';
  action:
    | 'create'
    | 'save'
    | 'auto_save'
    | 'archive'
    | 'unarchive'
    | 'publish'
    | 'unpublish'
    | 'delete';
}

// TODO: Verify these. I (Alex) didn't get to test all of these, but it's OK since they're not used yet.
export interface ContentfulWebhookReleaseProps {
  api: 'ReleaseActionManagement' | 'BulkActionManagement' | 'ScheduledActionManagement';
  entity: 'ReleaseAction' | 'BulkAction' | 'ScheduledAction';
  action: 'create' | 'execute';
}

export interface ContentfulWebhookSpaceManagementProps {
  api: 'SpaceManagement';
  entity: 'EnvironmentAlias';
  action: 'change_target';
}
