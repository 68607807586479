import type { MapOf } from '@snapchat/snap-design-system-marketing';
import forEach from 'lodash/forEach.js';
import isNil from 'lodash/isNil.js';

export const filtersToUrlParams = (filters: MapOf<string | undefined>): string => {
  let urlParams = '';

  forEach(filters, (value, key) => {
    if (isNil(value)) {
      return;
    }

    if (urlParams.length === 0) {
      urlParams = `${key}=${value}`;
    } else {
      urlParams = `${urlParams}&${key}=${value}`;
    }
  });

  return urlParams;
};

export const urlParamsToFilters = (params: string): MapOf<string> => {
  const result: MapOf<string> = {};

  new URLSearchParams(params).forEach((value, key) => {
    if (value) {
      result[key] = value;
    }
  });

  return result;
};
