import { gql } from '@apollo/client';

import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';
import { fragments as analyticsFragments } from '../Analytics/query';
import { fragments as videoFragments } from '../Video';

export const fragments = {
  all: gql`
    fragment MosaicAll on Mosaic {
      ...ContentfulSysId
      columns
      mobileColumns
      filtersCollection(limit: 20) {
        items {
          ...ContentfulSysId
          ... on MosaicFilters {
            ...ContentfulSysId
            text
            icon
            isAll
          }
        }
      }
      tilesCollection(limit: 100) {
        items {
          ...ContentfulSysId
          ... on MosaicTile {
            ...ContentfulSysId
            analytics {
              ...AnalyticsAll
            }
            title
            highlight
            showOverlay
            duration
            columns
            mobileColumns
            rows
            mobileRows
            preview {
              ...VideoAll
            }
            video {
              ...VideoAll
            }
            thumbnail {
              url
              contentType
            }
            filtersCollection(limit: 50) {
              items {
                ...ContentfulSysId
              }
            }
          }
        }
      }
    }
    ${analyticsFragments.all}
    ${videoFragments.all}
    ${contentfulSysIdFragment}
  `,
};

export const query = {
  shallow: gql`
    query MosaicQuery($preview: Boolean!, $locale: String!, $id: String!) {
      mosaic(preview: $preview, locale: $locale, id: $id) {
        ...ContentfulSysId
      }
    }
    ${contentfulSysIdFragment}
  `,
  all: gql`
    query MosaicQuery($preview: Boolean!, $locale: String!, $id: String!) {
      mosaic(preview: $preview, locale: $locale, id: $id) {
        ...MosaicAll
      }
    }
    ${fragments.all}
  `,
};
