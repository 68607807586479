import type { ReactElement, ReactNode } from 'react';

import type { BaseComponentProps } from '../../../types';
import type { OnActivateHandler } from '../../../types/activationEvents';

export interface GlobalNavHighlightProps {
  background: ReactNode;
  callToAction: ReactNode;
  cardTitleV2?: string;
  cardBodyV2?: string;
  /** Deprecated */
  cardTitle?: ReactNode;
  cardBody?: ReactNode;
  cardContent?: ReactNode;

  /**
   * Replication of the click action from the `callToAction`.
   *
   * Used for rendering the highlight on mobile which do not render the CTA.
   */
  onActivate?: OnActivateHandler;
}

export interface GlobalNavHighlightReelProps extends BaseComponentProps {
  highlights: Map<string, ReactElement<GlobalNavHighlightProps> | null>;
}
