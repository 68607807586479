import { css, cx } from '@emotion/css';
import type { FC } from 'react';
import { useContext } from 'react';

import { MotifComponent, useMotifStyles } from '../../motif';
import type { BaseComponentProps, ImageSources } from '../../types';
import type { OnActivateHandler } from '../../types/activationEvents';
import { Picture } from '../Picture/Picture';
import { PrimitivesContext } from '../Primitives';

interface ImageButtonProps extends BaseComponentProps {
  height?: number;
  imgSrcs?: ImageSources;
  url?: string;
  width?: number;
  onClick?: OnActivateHandler;
  altText?: string;
  pictureClassName?: string;
}

const imageButtonCss = css`
  background: none;
  border: none;
  cursor: pointer;
  outline: inherit;
`;

export const ImageButton: FC<ImageButtonProps> = ({
  height,
  imgSrcs,
  url,
  width,
  onClick,
  altText,
  pictureClassName,
  className,
}) => {
  useMotifStyles(MotifComponent.IMAGE_BUTTON);
  const { Anchor } = useContext(PrimitivesContext);
  return url ? (
    <Anchor href={url} className={cx(MotifComponent.IMAGE_BUTTON, className)}>
      <Picture
        className={pictureClassName}
        altText={altText}
        height={height}
        width={width}
        imgSrcs={imgSrcs}
      />
    </Anchor>
  ) : (
    <button
      className={cx(MotifComponent.IMAGE_BUTTON, imageButtonCss, className)}
      onClick={onClick}
    >
      <Picture
        className={pictureClassName}
        altText={altText}
        height={height}
        width={width}
        imgSrcs={imgSrcs}
      />
    </button>
  );
};
