import { cx } from '@emotion/css';
import type { FC } from 'react';
import { Children } from 'react';

import { Alignment, BackgroundColor } from '../../constants';
import { getMotifSchemeForLegacyBackgroundColor, MotifComponent } from '../../motif';
import { alignmentCss, justifyContentCss, mobileAlignmentCss } from '../../utils';
import { backgroundCss } from '../Block';
import { Picture } from '../Picture';
import { Video } from '../Video';
import {
  bodyCss,
  contentWrapperCss,
  ctaWrapperCss,
  fitWindowCss,
  mediaCaptionCss,
  mediaWrapperCss,
  reverseSplitCss,
  splitBlockWrapperCss,
  subtitleCss,
  textWrapperCss,
  titleCss,
} from './styles';
import type { SplitBlockProps } from './types';

/**
 * A SDS-M component for displaying content in a split layout, with media section (image or video)
 * on one side and content with calls to action on the other.
 *
 * @property {BackgroundColor} [backgroundColor] - The background color of the component. Default is
 *   `BackgroundColor.Black`
 * @property {ReactNode} [body] - The main text content to be displayed.
 * @property {Buttons} [callsToAction] - An array of call-to-action buttons.
 * @property {boolean} [fitWindow] - Whether the component should expand to fit the entire viewport
 *   height. Default is `false`
 * @property {ImageSources} [imageSources] - An object containing image sources for different screen
 *   sizes (if using an image).
 * @property {string} [mediaCaption] - A caption for the media (image or video).
 * @property {string} [mediaContentType] - The content type of the media (e.g., 'image/jpeg',
 *   'video/mp4').
 * @property {Alignment} [mediaDirection] - The position of the media section (start or end).
 *   Default is `Alignment.End`
 * @property {string} [mobileMediaContentType] - The content type of the media on mobile devices.
 * @property {string} [mobileVideoSource] - The video source for mobile devices.
 * @property {string} [subtitle] - A subtitle to be displayed below the title.
 * @property {Alignment} [textAlignment] - The horizontal alignment of the text content. Default is
 *   `Alignment.Start`
 * @property {Alignment} [textAlignmentMobile] - The horizontal alignment of the text content on
 *   mobile devices. Default is `Alignment.Center`
 * @property {string} [title] - The main title to be displayed.
 * @property {Alignment} [verticalTextAlignment] - The vertical alignment of the text content.
 *   Default is `Alignment.Center`
 * @property {string} [videoSource] - The video source (if using a video).
 * @returns {JSX.Element} The rendered SplitBlock component.
 */

export const SplitBlock: FC<SplitBlockProps> = props => {
  const {
    backgroundColor = BackgroundColor.Black,
    body,
    callsToAction,
    fitWindow = false,
    imageSources,
    mediaCaption,
    mediaContentType,
    mediaDirection = Alignment.End,
    mobileMediaContentType,
    mobileVideoSource,
    subtitle,
    textAlignment = Alignment.Start,
    textAlignmentMobile = Alignment.Center,
    title,
    verticalTextAlignment = Alignment.Center,
    videoSource,
  } = props;

  const callsToActionCount = Children.toArray(callsToAction).length;

  return (
    <article
      className={cx(
        MotifComponent.SPLIT_BLOCK,
        getMotifSchemeForLegacyBackgroundColor(backgroundColor),
        splitBlockWrapperCss,
        {
          [fitWindowCss]: fitWindow,
          [reverseSplitCss]: mediaDirection === Alignment.Start,
        }
      )}
    >
      <section
        className={cx(textWrapperCss, {
          [reverseSplitCss]: mediaDirection === Alignment.Start,
        })}
      >
        <div
          className={cx(
            contentWrapperCss,
            alignmentCss[textAlignment],
            mobileAlignmentCss[textAlignmentMobile],
            justifyContentCss[verticalTextAlignment]
          )}
        >
          {title && <h2 className={titleCss}>{title}</h2>}
          {subtitle && <div className={subtitleCss}>{subtitle}</div>}
          {body && <div className={bodyCss}>{body}</div>}
          {callsToActionCount > 0 ? (
            <section
              className={cx(
                ctaWrapperCss,
                alignmentCss[textAlignment],
                mobileAlignmentCss[textAlignmentMobile]
              )}
            >
              {callsToAction}
            </section>
          ) : null}
        </div>
      </section>
      {(imageSources || videoSource) && (
        <section className={mediaWrapperCss}>
          {imageSources && (
            <Picture
              altText={mediaCaption}
              imgSrcs={imageSources}
              imgClassName={backgroundCss}
              fetchPriority="auto"
            />
          )}
          {videoSource && (
            <Video
              className={backgroundCss}
              isBackgroundVideo
              mobileSource={mobileVideoSource}
              mobileSourceType={mobileMediaContentType}
              source={videoSource}
              sourceType={mediaContentType}
            />
          )}
          {mediaCaption && <div className={mediaCaptionCss}>{mediaCaption}</div>}
        </section>
      )}
    </article>
  );
};
