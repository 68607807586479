import { css } from '@emotion/css';

import {
  maxWidth,
  mobileMediaQuery,
  nonMobileMediaQuery,
  nonTwoColumnCollapseMediaQuery,
  twoColumnCollapseMediaQuery,
} from '../../../constants';
import { m } from '../../../motif/m';

export const wrapperCss = css`
  color: ${m('--footer-bar-fg-color')};
  background-color: ${m('--footer-bar-bg-color')};
  border-top: 1px solid ${m('--footer-bar-divider-border-color')};
  display: flex;
  justify-content: center;
`;

export const footerBarContainerCss = css`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: ${maxWidth}px;
  padding-block: ${m('--spacing-xl')};
  position: relative;
  white-space: nowrap;
  width: 100%;

  ${nonMobileMediaQuery} {
    padding-block: ${m('--spacing-s')};
    padding-inline: ${m('--spacing-xl')};
  }

  ${twoColumnCollapseMediaQuery} {
    gap: 27px;
    padding-block: ${m('--spacing-l')};
    padding-inline: ${m('--spacing-xxxxl')};
  }

  ${nonTwoColumnCollapseMediaQuery} {
    flex-direction: row;
    gap: 0;
    padding-inline: ${m('--spacing-xxxxl')};

    div {
      width: auto;
    }
  }

  ${mobileMediaQuery} {
    gap: 0;
    padding-inline: ${m('--spacing-xl')};
  }
`;
