import { getLocalStorageItem, setLocalStorageItem } from '@snapchat/mw-common';
import noop from 'lodash-es/noop';
import { useCallback, useContext, useEffect, useState } from 'react';

import { AppContext } from '../../AppContext';
import { Config } from '../../config';
import { logEvent, SubscribedEventType } from '../../helpers/logging';
import { UserAction } from '../../types/events';
import type { EcommerceEventProduct } from '../../types/gtm';
import type { BasicCart, CartEvent, CartState } from './types';

const shopifyCartKey = 'mwp-shopify-cart';

export const initialCartState: CartState = {
  lineItems: {},
  cartOpen: false,
  addOne: noop,
  removeOne: noop,
  setCartOpen: noop,
};

export const useCart = (): CartState => {
  const appContext = useContext(AppContext);
  const country = appContext.userLocation.country;

  const [cart, setCart] = useState<CartState>(initialCartState);

  const setCartOpen = (cartOpen: boolean) => {
    if (cartOpen) {
      logEvent({
        eventCategory: 'Cart',
        eventAction: UserAction.Click,
        eventLabel: 'Open Cart',
        subscribedEventType: SubscribedEventType.USER_INTERACTION,
      });
    } else {
      logEvent({
        eventCategory: 'Cart',
        eventAction: UserAction.Click,
        eventLabel: 'Close Cart',
        subscribedEventType: SubscribedEventType.USER_INTERACTION,
      });
    }

    setCart({ ...cart, cartOpen });
  };

  const addOne = useCallback(
    ({ handle, contentfulProduct, shopifyProducts, currencyCode }: CartEvent) => {
      const newLineItems = cart.lineItems;
      const productInCart = newLineItems[handle]!;
      const bundleItems = contentfulProduct.bundleItemsCollection?.items ?? [];
      const shopifyProduct = shopifyProducts[handle]!;
      const ecommerceEventProducts: EcommerceEventProduct[] = [];

      if (productInCart) {
        newLineItems[handle] += 1;
      } else {
        newLineItems[handle] = 1;
      }

      ecommerceEventProducts.push({
        name: contentfulProduct.title,
        id: shopifyProduct.sku,
        variant: shopifyProduct.title,
        price: shopifyProduct.price.toString(),
        shopifyid: shopifyProduct.id,
        quantity: 1,
      });

      bundleItems.forEach(({ title, productHandle }) => {
        const bundleItemFromCart = newLineItems[productHandle];
        const bundleShopifyProduct = shopifyProducts[productHandle]!;

        if (bundleItemFromCart) {
          newLineItems[productHandle] += 1;
        } else {
          newLineItems[productHandle] = 1;
        }

        ecommerceEventProducts.push({
          name: title,
          id: bundleShopifyProduct.sku,
          variant: bundleShopifyProduct.title,
          price: bundleShopifyProduct.price.toString(),
          shopifyid: bundleShopifyProduct.id,
          quantity: 1,
        });
      });

      setLocalStorageItem(shopifyCartKey, JSON.stringify({ lineItems: newLineItems }));

      logEvent({
        eventCategory: 'Cart',
        eventAction: 'Add',
        eventLabel: ecommerceEventProducts.map(product => product.shopifyid).join(','),
        numberOfProducts: ecommerceEventProducts.length,
        ecommerce: {
          currencyCode,
          add: {
            products: ecommerceEventProducts,
          },
        },
        subscribedEventType: SubscribedEventType.ECOMMERCE,
      });

      setCart({
        ...cart,
        lineItems: newLineItems,
        cartOpen: true,
      });
    },
    [cart]
  );

  const removeOne = useCallback(
    ({ handle, contentfulProduct, shopifyProducts, currencyCode }: CartEvent) => {
      const newLineItems = cart.lineItems;
      const productInCart = newLineItems[handle];
      const shopifyProduct = shopifyProducts[handle]!;
      const ecommerceEventProducts: EcommerceEventProduct[] = [];

      if (productInCart) {
        newLineItems[handle] -= 1;

        if (newLineItems[handle] === 0) {
          delete newLineItems[handle];
        }
      }

      ecommerceEventProducts.push({
        name: shopifyProduct.title,
        id: shopifyProduct.sku,
        variant: shopifyProduct.title,
        price: shopifyProduct.price.toString(),
        shopifyid: shopifyProduct.id,
        quantity: 1,
      });

      const bundleItems = contentfulProduct.bundleItemsCollection?.items ?? [];

      bundleItems.forEach(({ productHandle }) => {
        const bundleItemFromCart = newLineItems[productHandle];
        const bundleShopifyProduct = shopifyProducts[productHandle]!;

        if (bundleItemFromCart) {
          newLineItems[productHandle] -= 1;

          if (newLineItems[productHandle] === 0) {
            delete newLineItems[productHandle];
          }
        }

        ecommerceEventProducts.push({
          name: bundleShopifyProduct.title,
          id: bundleShopifyProduct.sku,
          variant: bundleShopifyProduct.title,
          price: bundleShopifyProduct.price.toString(),
          shopifyid: bundleShopifyProduct.id,
          quantity: 1,
        });
      });

      setLocalStorageItem(shopifyCartKey, JSON.stringify({ lineItems: newLineItems }));

      logEvent({
        eventCategory: 'Cart',
        eventAction: 'Remove',
        eventLabel: ecommerceEventProducts.map(product => product.shopifyid).join(','),
        currencyCode,
        productIds: ecommerceEventProducts.map(product => product.shopifyid).join(','),
        ecommerce: {
          remove: {
            products: ecommerceEventProducts,
          },
        },
        subscribedEventType: SubscribedEventType.ECOMMERCE,
      });

      setCart({
        ...cart,
        lineItems: newLineItems,
      });
    },
    [cart]
  );

  const cartInit = useCallback(() => {
    const initialCartString: string | BasicCart | null = getLocalStorageItem(shopifyCartKey);
    let initialCart: BasicCart;

    if (initialCartString) {
      initialCart = JSON.parse(initialCartString) as BasicCart;
    } else {
      initialCart = {
        lineItems: {},
      } as BasicCart;

      setLocalStorageItem(shopifyCartKey, JSON.stringify(initialCart));
    }

    setCart(currentCart => ({
      ...currentCart,
      ...(initialCart as BasicCart),
      cartOpen: false,
    }));
  }, []);

  useEffect(() => {
    if (!Config.shopify) {
      return;
    }

    cartInit();
  }, [country, cartInit]);

  return {
    ...cart,
    addOne,
    removeOne,
    setCartOpen,
  };
};
