import { css } from '@emotion/css';

import { maxWidth, nonMobileMediaQuery } from '../../../constants';
import { m } from '../../../motif/m';

export const wrapperCss = css`
  color: ${m('--footer-fg-color')};
  background-color: ${m('--footer-bg-color')};
  border-top-color: ${m('--footer-divider-border-color')};
  border-top-style: solid;
  border-top-width: 2px;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const footerSocialCss = css`
  align-items: center;
  color: ${m('--foreground-color')};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${m('--spacing-l')};
  max-width: ${maxWidth}px;
  padding-block: ${m('--spacing-m')};
  padding-inline: ${m('--spacing-xl')};
  position: relative;
  width: 100%;

  ${nonMobileMediaQuery} {
    flex-direction: row;
    justify-content: center;
    padding-block: ${m('--spacing-m')};
    padding-inline: ${m('--spacing-xxxxl')};
  }
`;

export const footerSocialIconsWrapperCss = css`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${m('--spacing-s')};
`;
