import clone from 'lodash-es/clone';
import merge from 'lodash-es/merge';

import type { FieldMetadata, FieldValue, FormAction, FormState, Validation } from './Form.types';
import { FormEventType, FormStateType } from './Form.types';

export const validations: Validation = {
  /** Standard pattern for Email validation (includes non-English characters) */
  Email: new RegExp(
    `^(([^<>()[\\]\\.,;:\\s@"]+(\\.[^<>()[\\]\\.,;:\\s@"]+)*)|(".+"))@` +
      '((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]{1,24}\\.)+[a-zA-Z]{2,}))$'
  ),
  Name: /^(?!\s*$).+/,
  /**
   * Pattern for English only Email Validation (needed for specific 3rd party integrations where the
   * vendor doesn't support unicode characters e.g. Salesforce)
   * https://developer.salesforce.com/docs/marketing/marketing-cloud/guide/using_regular_expressions_to_validate_email_addresses.html#validate-email-addresses
   *
   * Applies case invariant flag instead of specifying upper case letters in pattern.
   */
  'Email (English only)':
    // NOTE: eslint tries to remove escape characters that are necessary for the pattern to work in jest...
    // eslint-disable-next-line no-useless-escape
    /^[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i,
};

/** Returns if neither the form nor the any field has errors. */
function checkNoError(state: FormState): boolean {
  if (state.error) return false;

  for (const name of Object.keys(state.fields)) {
    if (!state.fields[name]) continue;
    if (state.fields[name]!.hasError) return false;
  }

  return true;
}

/**
 * Checks if a field is invalid based on its value and validation rules.
 *
 * @param field - The field to check for validity.
 * @returns A boolean indicating whether the field is invalid.
 */
export const isInvalid = (field: FieldMetadata, value: FieldValue): boolean => {
  // Invalid if required but empty.
  if (field.required) {
    if (Array.isArray(value) && value.length === 0) {
      return true;
    }

    if (!Array.isArray(value) && (String(value).trim() === '' || Boolean(value) === false)) {
      return true;
    }
  }

  // Invalid if has validation and validation fails.
  if (!field.validation || !validations[field.validation] || typeof value !== 'string') {
    return false;
  }

  return !validations[field.validation].test(value);
};

export const initialFormState: FormState = {
  type: FormStateType.INITIAL,
  fields: {},
  formBody: {},
};

/** Reducer for the `Form` component. */
export function formReducer(state: FormState, action: FormAction): FormState {
  // Force state re-render even if nothing changes.
  // This is sadly a requirement for strict mode.
  // See: https://react.dev/reference/react/useReducer#my-reducer-or-initializer-function-runs-twice
  // Without this, some events do not work in dev mode.
  // Note that we don't use `cloneDeep` here because we don't necessarily want to
  // trigger all the `useEffects` that trigger on `formData`.
  state = clone(state);

  switch (action.type) {
    case FormEventType.REGISTER_FIELD: {
      // NOTE: Doing a mutation for fields that initialize over multiple calls.
      const metadata = merge(state.fields[action.name] ?? {}, action.field);
      state.fields[action.name] = metadata;
      state.formBody[action.name] = action.field.initialValue;

      // In case user already interacted with the form, validate it when registering.
      if (state.type !== FormStateType.INITIAL) {
        metadata.hasError = isInvalid(metadata, action.field.initialValue);
      }
      break;
    }

    case FormEventType.CHANGE_FIELD_VALUE: {
      // Save last changed field name.
      state.lastChangedFieldName = action.name;

      // Set the new value
      state.formBody = clone(state.formBody); // Trigger state change for useEffects
      state.formBody[action.name] = action.value;

      // Update error state.
      if (!state.fields[action.name]) state.fields[action.name] = {};
      const metadata = state.fields[action.name]!;
      metadata.hasError = isInvalid(metadata, action.value);

      // Update state type.
      state.type = checkNoError(state) ? FormStateType.READY : FormStateType.INVALID;
      state.error = undefined;
      break;
    }

    case FormEventType.INVALIDATE_FIELD: {
      state.fields[action.name]!.hasError = true;
      state.type = FormStateType.INVALID;
      break;
    }

    case FormEventType.SUBMIT_TRIGGER: {
      // Validate all fields first.
      for (const name of Object.keys(state.fields)) {
        const metadata = state.fields[name];
        if (!metadata) continue;
        metadata.hasError = isInvalid(metadata, state.formBody[name]);
      }
      // Update state based on validation.
      state.type = checkNoError(state) ? FormStateType.SUBMITTING : FormStateType.INVALID;
      break;
    }

    case FormEventType.SUBMIT_FAILURE: {
      state.type = FormStateType.SUBMIT_FAILURE;
      state.errorStatusCode = action.errorStatusCode;
      state.error = action.error;
      // NOTE: `formBody` isn't affected.
      break;
    }

    case FormEventType.SUBMIT_SUCCESS: {
      state.type = FormStateType.SUBMIT_SUCCESS;

      state.formBody = {}; // Explicit reset to trigger state changes.

      for (const name of Object.keys(state.fields)) {
        const meta = state.fields[name]!; // Safe
        meta.hasError = false;
        state.formBody[name] = meta.shouldResetToInitial ? meta.initialValue : undefined;
      }
      state.error = undefined;
      break;
    }

    default: {
      throw new Error(`Unhandled action type for ${JSON.stringify(action)}`);
    }
  }

  return state;
}
