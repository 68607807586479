import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { globalComponentsApolloLink } from '@snapchat/mw-global-components-schema';
import type { FC, PropsWithChildren } from 'react';

import type { ContentfulProps } from '../../context/ContentfulContext';
import { ContentfulContext } from '../../context/ContentfulContext';
import type { DesignSystemProps } from '../../context/DesignSystemContext';
import { DesignSystemContext } from '../../context/DesignSystemContext';

export type CookieProviderProps = Partial<ContentfulProps> & DesignSystemProps & PropsWithChildren;

export const CookieProvider: FC<CookieProviderProps> = ({ children, client, ...otherProps }) => {
  const { isPreview = false, isSSR = false, currentLocale = 'en-US' } = otherProps;
  const contentfulContext: ContentfulProps = {
    isPreview,
    isSSR,
    currentLocale,
    client,
  };

  const wrappedWithContext = (
    <ContentfulContext.Provider value={contentfulContext}>
      <DesignSystemContext.Provider value={otherProps}>{children}</DesignSystemContext.Provider>
    </ContentfulContext.Provider>
  );

  if (client) {
    return wrappedWithContext;
  }

  // If a client was not provided, wrap with ApolloProvider with default settings
  const defaultClient = new ApolloClient({
    link: globalComponentsApolloLink,
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={defaultClient}>{wrappedWithContext}</ApolloProvider>;
};
