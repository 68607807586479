import { css } from '@emotion/css';

import { MobileHeading, mobileMediaQuery, nonMobileMediaQuery, Spacing } from '../../constants';
import { m } from '../../motif/m';

export const desktopWrapperCss = css`
  ${mobileMediaQuery} {
    display: none;
  }

  ${nonMobileMediaQuery} {
    display: block;
  }
`;

export const dropdownCss = css`
  border: none;
  background-color: inherit;
  color: ${m('--foreground-color')};
  align-items: center;
  display: flex;
  padding-block: ${Spacing.MD}px;
  margin-inline-end: ${Spacing.XL}px;
`;

export const dropdownMobileWrapperCss = css`
  ${mobileMediaQuery} {
    position: relative;
  }

  ${nonMobileMediaQuery} {
    display: none;
  }
`;

export const dropdownMobileCss = css`
  ${mobileMediaQuery} {
    width: 100%;
  }
`;

export const dropdownTextCss = css`
  cursor: pointer;
  font-family: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  *[dir='rtl'] & {
    text-align: right;
  }

  ${mobileMediaQuery} {
    background: none;
    border: none;
    padding-block: ${Spacing.XS}px;
    padding-inline-end: ${Spacing.XL}px;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
  }
`;

export const dropdownMainTextCss = css`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;

  ${mobileMediaQuery} {
    ${MobileHeading.M400};
    line-height: 28px;
  }
`;

export const dropdownSecondaryTextCss = css`
  color: ${m('--foreground-color')};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-inline-end: ${Spacing.XS}px;
  width: 100%;
`;

export const caratCss = css`
  font-size: 16px;
  pointer-events: none;

  svg {
    fill: ${m('--foreground-color')};
  }

  ${nonMobileMediaQuery} {
    margin-block-start: 3px;
    margin-inline-start: 9px;

    *[dir='rtl'] & {
      margin-right: ${Spacing.XS}px;
    }
  }

  ${mobileMediaQuery} {
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);

    *[dir='rtl'] & {
      left: 4px;
      right: auto;
    }
  }
`;
