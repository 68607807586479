import { cx } from '@emotion/css';
import type { FC } from 'react';

import { usePrice } from './usePrice';

interface PriceProps {
  className?: string;
  productPrice: number;
}

export const Price: FC<PriceProps> = ({ productPrice, className }) => {
  const price = usePrice(productPrice);
  return <div className={cx('mw-price', className)}>{price}</div>;
};
