import { css } from '@emotion/css';

import { Black, Gray, Plain, White } from '../../../constants/colors';
import { ZIndex } from '../../../constants/zIndex';
import { m } from '../../../motif/m';

export const tooltipPortalCss = css`
  position: absolute;
  border-radius: ${m('--border-radius-m')};
  box-shadow: 0 1px 2px ${Black.V50};
  line-height: 1em;
  pointer-events: none;
  background-color: ${Plain.WHITE};
  color: ${Plain.BLACK};
  padding: 0;
  z-index: ${ZIndex.CHART_TOOLTIP};
`;

export const tooltipHeadingCss = css`
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: 400;
  line-height: 16px;
  padding: 6px;
`;

export const tooltipDescriptionCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  gap: 7px;
  border-radius: 0 0 4px 4px;
  font-size: 16px;
  line-height: 12px;
`;

export const tooltipWrapperCss = css`
  border-radius: 4px;
`;

export const tooltipColoredDotCss = css`
  width: 12px;
  height: 12px;
  border-radius: 50%;
`;

export const titleCss = css`
  margin-bottom: 20px;
  color: ${Black.V200};
  text-align: center;
`;

export const wrapperCss = css`
  background-color: ${White};
  border-radius: 40px;
  padding: 60px 0;
`;

export const barChartContainerCss = css`
  position: relative;
  width: fit-content;
  margin: 0 auto;
`;

export const chartLegendContainerCss = css`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
  color: ${Black.V100};
`;

export const buttonFilterCss = (active: boolean): ReturnType<typeof css> => css`
  border-radius: 4px;
  background-color: ${active ? White : Gray.V150};
  display: flex;
  padding: 5px 10px;
  border: 1px solid ${Gray.V200};
  font-family: inherit;
  cursor: pointer;
  margin: 1rem 0 0 1rem;
  color: ${Black.V100};
  *[dir='rtl'] & {
    margin: 1rem 1rem 0 0;
  }
`;

export const colorIndicatorCss = (color: string | undefined): ReturnType<typeof css> => css`
  background-color: ${color};
  width: 15px;
  height: 15px;
  margin: 0 5px 0 0;
  *[dir='rtl'] & {
    margin: 0 0 0 5px;
  }
`;
