import type { Asset, Slug } from '@snapchat/mw-contentful-schema';
import type { FC, PropsWithChildren } from 'react';

import { Config } from '../../config';
import { logError } from '../../helpers/logging';
import { Hyperlink } from './Hyperlink';

type AssetEntryHyperlink = {
  item: Asset | Slug;
};

export const AssetEntryHyperlink: FC<PropsWithChildren<AssetEntryHyperlink>> = props => {
  const { item, children } = props;

  const typename = item.__typename;

  // We should only get Asset and Slugs, but not all spaces/models have that validation
  const notAcceptedTypes = typename !== 'Slug' && typename !== 'Asset';
  const slugNoUrl = typename === 'Slug' && !item.slug;
  const assetNoUrl = typename === 'Asset' && !item.url;

  if (!typename || notAcceptedTypes || slugNoUrl || assetNoUrl) {
    logError({
      component: 'AssetEntryLink',
      message: 'Invalid data for hyperlink',
      context: { sysId: item.sys.id },
    });

    return null;
  }

  let url = '';

  if (item.__typename === 'Slug') {
    url = `https://${Config.domainName}/${item.slug}`;
  }

  if (item.__typename === 'Asset' && item.url) {
    url = item.url;
  }

  return <Hyperlink uri={url}>{children}</Hyperlink>;
};

AssetEntryHyperlink.displayName = 'AssetEntryHyperlink';
