import type { ValueOf } from '../types';

export const BackgroundSize = {
  Cover: 'cover',
  Contain: 'contain',
} as const;

export type BackgroundSize = ValueOf<typeof BackgroundSize>;

export const BackgroundRepeat = {
  Repeat: 'repeat',
  RepeatX: 'repeat-x',
  RepeatY: 'repeat-y',
  Space: 'space',
  Round: 'round',
  NoRepeat: 'no-repeat',
};

export type BackgroundRepeat = ValueOf<typeof BackgroundRepeat>;

export const BackgroundPosition = {
  Top: 'top',
  Bottom: 'bottom',
  Left: 'left',
  Right: 'right',
  Center: 'center',
};

export type BackgroundPosition = ValueOf<typeof BackgroundPosition>;

// This is not meant to be CSS styling but an enum for predefined styles
// for the background media
export const BackgroundStyle = {
  Normal: 'Normal',
  Fixed: 'Fixed',
} as const;

export type BackgroundStyle = ValueOf<typeof BackgroundStyle>;
