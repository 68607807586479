import { cx } from '@emotion/css';
import type { FC } from 'react';

import { getBackgroundClassName } from '../../../utils';
import { footerBarContainerCss, wrapperCss } from './styles';
import type { FooterBarProps } from './types';

export const FooterBar: FC<FooterBarProps> = ({ children, backgroundColor }) => {
  return (
    <div
      data-testid="footer-bar"
      className={cx(getBackgroundClassName(backgroundColor), wrapperCss)}
    >
      <div className={footerBarContainerCss}>{children}</div>
    </div>
  );
};
