import { useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { ConsumerContext } from '../components/ConsumerContextProvider';

export const useQueryParams: () => URLSearchParams = () => {
  // Used to trigger updates when path changes on Client
  const { search } = useLocation();
  // Used to enable fetching values on both Client and Server
  const { getUrlParams } = useContext(ConsumerContext);

  return useMemo(() => {
    const searchParams = getUrlParams?.() ?? {};
    return new URLSearchParams(searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, getUrlParams]);
};
