import { css } from '@emotion/css';
import type { RefObject } from 'react';
import { useEffect } from 'react';

import type { Animation } from './types';

let staticObserver: IntersectionObserver;

const getObserver = () => {
  if (staticObserver) return staticObserver;

  staticObserver = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      const target = entry.target;

      if (entry.isIntersecting && target instanceof HTMLElement) {
        // Note: using a data-animation-delay attribute because window.computedStyles have SSR issues
        const delayAttr = target.getAttribute('data-animation-delay');

        let delay = 0;

        if (delayAttr) {
          delay = delayAttr.endsWith('ms')
            ? Number.parseFloat(delayAttr)
            : Number.parseFloat(delayAttr) * 1000;
        }

        setTimeout(() => {
          // Note: removing the animationName style previously inserted
          // to trigger the interception observer if the initial animation
          // style has a transform and puts the element outside of the viewport
          target.style.animationName = '';
          target.style.animationPlayState = 'running';
        }, delay);

        staticObserver.unobserve(target);
      }
    });

    return staticObserver;
  });

  return staticObserver;
};

const getAnimationClassName = (animation: Animation) =>
  css`
    animation-play-state: paused;
    animation-name: animation-${animation.name};
    animation-duration: ${animation.duration};
    animation-timing-function: ${animation.timingFunction};
    animation-iteration-count: ${animation.iterationCount};
    animation-direction: ${animation.direction};
    transform-origin: ${animation.transformOrigin};
  `;

const containerTargetKey = 'container';

/**
 * Hook for animations using a static IntersectionObserver for starting the animation
 *
 * @param ref Reference to the main animatable container
 * @param animations Animations array
 * @param componentName Name of the component using this hook used as a prefix to avoid clashes
 * @returns Object with keys being the target of the animation and values being the className
 *   Example: { container: 'classname-1', cta: 'classname-2', title: 'classname-3' }
 *
 *   Usage:
 *
 *       const animationsClassNames = useAnimations(ref, animationsArray, 'content');
 */
export function useAnimations(
  ref: RefObject<HTMLElement | undefined>,
  animations: Animation[] | undefined,
  componentName: string
): Record<string, Record<string, string | undefined>> | undefined {
  useEffect(() => {
    if (!animations || animations.length === 0) {
      return undefined;
    }

    const observer = getObserver();
    const toObserve: Array<HTMLElement> = [];

    animations.forEach(animation => {
      const targetRef = animation.target
        ? (ref.current?.querySelector(
            `*[data-animation-id="${componentName}-${animation.target}"]`
          ) as HTMLElement)
        : ref.current;

      if (!targetRef) {
        return;
      }

      // Note: adding animationName style with a global animation 'no-animation'
      // with transform: none, to trigger the interception observer if the initial
      // animation style has a transform and puts the element outside of the viewport
      targetRef.style.animationName = `animation-${animation.name}, no-animation`;

      observer.observe(targetRef);
      toObserve.push(targetRef);
    });

    return () => {
      toObserve.forEach(element => observer.unobserve(element));
    };
  }, [animations, ref, componentName]);

  const animationsProps: Record<string, Record<string, string | undefined>> = {};

  animations?.forEach(item => {
    animationsProps[item.target ?? containerTargetKey] = {
      className: getAnimationClassName(item),
      delay: item.delay,
    };
  });

  return animationsProps;
}
