import { css } from '@emotion/css';
import type { FC, PropsWithChildren } from 'react';
import { Children } from 'react';

const maxWidthCss = css`
  max-width: 100%;
`;

/**
 * Wrapper for the rich text rendering.
 *
 * This is required so that we have a block component that has a static position so that the
 * vertical margins can collapse in its children.
 *
 * TODO: Revisit if we want this behavior. It's possible we can replace this implementation with a
 * <Fragment> instead of a <div> and in flex contexts margins won't collapse.
 */
export const RichTextDocument: FC<PropsWithChildren> = ({ children }) => (
  <div data-test-id="mwp-rich-text" className={maxWidthCss}>
    {Children.toArray(children)}
  </div>
);

const noMarginCss = css`
  margin: 0;
`;

export const RichTextNoPaddingParagraph: FC<PropsWithChildren> = ({ children }) => {
  return <p className={noMarginCss}>{Children.toArray(children)}</p>;
};
