import { gql } from '@apollo/client';
import type { BackgroundColor, Template } from '@snapchat/snap-design-system-marketing';

import type { ContentfulTypedSysProps } from '../../types/contentful';
import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';

export const breakFragment = gql`
  fragment BreakAll on Break {
    ...ContentfulSysId
    type
    topColor
    bottomColor
    isOverlaid
  }
  ${contentfulSysIdFragment}
`;

export const breakQuery = gql`
  query BreakQuery($preview: Boolean!, $locale: String!, $id: String!) {
    break(preview: $preview, locale: $locale, id: $id) {
      ...BreakAll
    }
  }
  ${breakFragment}
`;

export type BreakType = Template | 'None';

export type BreakBackgroundColor = BackgroundColor | 'Transparent';

export interface BreakDataProps extends ContentfulTypedSysProps<'Break'> {
  type: BreakType;
  topColor?: BreakBackgroundColor;
  bottomColor?: BreakBackgroundColor;
  isOverlaid?: boolean;
}
