import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import type { AnchorComponent } from '@snapchat/snap-design-system-marketing';
import merge from 'lodash-es/merge';
import type { FC, PropsWithChildren } from 'react';
import { createContext } from 'react';

import type { Action } from '../analytics/Action';

export interface EventLog {
  // NOTE: Some of these map to GA actions. See this doc: https://support.google.com/analytics/answer/1033068
  action: Action; // Action being performed
  component: string; // Roughly maps to GA category like "Video", "Hero"
  label?: string; // Label of the item that the event is for
  url?: string; // URL if this is for a link
}

/**
 * Interface for locale options. Note that doesn't use contentful's Locale to support sites that do
 * not store data in contentful.
 */
export interface LocaleOption {
  /** Locale Code */
  code: string;
  /** Display Text for the Locale */
  name: string;
}

/** Properties that are shared between multiple global components. */
export interface GlobalComponentsProps {
  /**
   * Whether to get unpublished entries from contentful.
   *
   * DO NOT USE on production. For testing new links on staging builds only.
   */
  isPreview?: boolean;

  /** Passes this value to the creation of the default apollo client. */
  isSSR?: boolean;

  /** Logger for interaction events. */
  onEvent?: (event: EventLog) => void;

  /** Logger for error events. */
  onError?: (error: Error | string) => void;

  /**
   * Check for whether the URL is current.
   *
   * TODO: Describe what this affects.
   */
  isUrlCurrent?: (url: string) => boolean;

  /**
   * The domain of the current site.
   *
   * On the client it's `window.location.hostname`.
   *
   * On the server this needs to be provided.
   *
   * Used for hiding/showing certain links and determining if links lead to the same domain.
   */
  hostname: string;

  /**
   * The code for the currently selected locale and the language in which the elements will be
   * presented in.
   */
  currentLocale?: string;

  /**
   * Collection of options in the locale dropdown. Without these locale dropdown will not be
   * rendered.
   */
  supportedLocales?: Record<string, LocaleOption>;

  /** Handles changing the langauge on the site. */
  onLocaleChange?: (locale: string) => void;

  /** Override for the apollo client. */
  globalApolloClient?: ApolloClient<NormalizedCacheObject>;

  /**
   * Override for which component to use when rendering links. This is useful when you want to
   * override how links are rendered to apply redirects, target and other attributes.
   */
  Anchor?: AnchorComponent;
}

export const defaultLocaleCode = 'en-US';
export const defaultSupportedLocales = {
  [defaultLocaleCode]: {
    code: defaultLocaleCode,
    name: 'English (United States)',
  },
};

/** Default check for whether a URL is the one we're currently on. */
const defaultIsUrlCurrent = (pathOrUrl: string): boolean => {
  const url = new URL(pathOrUrl, window.location.href);
  if (window.location.hostname !== url.hostname) return false;
  return window.location.pathname !== url.pathname;
};

/** Sensible defaults. Here to simplify library usage. */
const defaults: Partial<GlobalComponentsProps> = {
  currentLocale: defaultLocaleCode,
  supportedLocales: defaultSupportedLocales,
  onError: console.error,
  // No default onEvent because spamming console isn't useful.
  isPreview: false,
  isSSR: false,
  isUrlCurrent: defaultIsUrlCurrent,
};

export const GlobalComponentsContext = createContext<GlobalComponentsProps>({
  onError: error => console.error(error),
  currentLocale: defaultLocaleCode,
  hostname: 'unknown',
  supportedLocales: defaultSupportedLocales,
});

/** Provider for GlobalComponentsContext that populates the defaults. */
export const GlobalComponentsContextProvider: FC<
  PropsWithChildren<{ value: GlobalComponentsProps }>
> = ({ children, value }) => {
  return (
    <GlobalComponentsContext.Provider value={merge(defaults, value)}>
      {children}
    </GlobalComponentsContext.Provider>
  );
};
