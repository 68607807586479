import { cx } from '@emotion/css';
import type { FC } from 'react';

import type { DropdownItemProps } from '../../DropdownMenu';
import { DropdownMenu } from '../../DropdownMenu';
import { dropdownCss, dropdownWrapperClassName, noLabelCss } from './styles';
import type { ChartToggleDropdownsProps } from './types';

export const ChartToggleDropdown: FC<ChartToggleDropdownsProps> = ({
  label,
  items,
  selectedChart,
  selectChart,
}) => {
  const handleOnClick = (item: DropdownItemProps) => {
    if (selectedChart !== item.id) {
      selectChart(item.id);
    }
  };

  const dropdownItems = items.map((item, index) => ({
    id: index.toString(),
    title: item.title,
    isSelected: index.toString() === selectedChart,
  }));

  return (
    <div
      className={cx(dropdownWrapperClassName, dropdownCss, {
        [noLabelCss]: !label,
      })}
    >
      {label && <label>{label}</label>}
      <DropdownMenu
        items={dropdownItems}
        selectedItemId={selectedChart}
        onItemSelect={handleOnClick}
      />
    </div>
  );
};
