import { cx } from '@emotion/css';
import { type FC, useContext } from 'react';

import { FormContext } from '../Form';
import { getStringValue } from '../Form/formUtils';
import {
  inputErrorCss,
  inputRadioWrapperCss,
  radioCss,
  radioLabelCss,
  readonlyCss,
} from './styles';
import type { InputRadioProps } from './types';

export const InputRadio: FC<InputRadioProps> = props => {
  const {
    id,
    handleChange,
    handleInvalid,
    hasError = false,
    label,
    name,
    readOnly,
    value,
    ...inputProps
  } = props;

  const { state } = useContext(FormContext);
  const formFieldValue = getStringValue(state.formBody[name]);
  const inputValue = getStringValue(value);

  return (
    <div className={inputRadioWrapperCss}>
      <input
        type="radio"
        name={name}
        id={id}
        value={inputValue}
        checked={formFieldValue === inputValue}
        className={cx(radioCss, { [inputErrorCss]: hasError })}
        disabled={readOnly}
        onChange={handleChange}
        onBlur={handleChange}
        onInvalid={handleInvalid}
        readOnly={readOnly}
        {...inputProps}
      />
      <label htmlFor={id} className={cx(radioLabelCss, { [readonlyCss]: readOnly })}>
        {label}
      </label>
    </div>
  );
};
