import { cx } from '@emotion/css';
import type { FC, ReactNode } from 'react';
import { memo, useContext } from 'react';

import { MotifComponent, useMotifStyles } from '../../motif';
import type { OnActivateHandler } from '../../types/activationEvents';
import { PrimitivesContext } from '../Primitives';
import { hyperLinkCss } from './Hyperlink.styled';

export interface HyperlinkProps {
  link?: string;
  target?: string;
  onClick?: OnActivateHandler;
  children?: ReactNode;
}

export const Hyperlink: FC<HyperlinkProps> = memo(({ children, link, target, onClick }) => {
  useMotifStyles(MotifComponent.HYPERLINK);
  const { Anchor } = useContext(PrimitivesContext);

  return (
    <Anchor
      href={link}
      target={target}
      className={cx(MotifComponent.HYPERLINK, hyperLinkCss)}
      onClick={onClick}
    >
      {children}
    </Anchor>
  );
});

Hyperlink.displayName = 'Hyperlink';
