import type { NavigationCallToAction } from '@snapchat/mw-contentful-schema';
import type React from 'react';

import { CallToAction } from '../CallToAction';
import { DisplayMode } from '../CallToAction/types';
import { useExperiment } from '../Experiment';

interface Props extends NavigationCallToAction {
  displayMode?: DisplayMode;
}

export const NavigationCta: React.FC<Props> = props => {
  const { checkCriteria } = useExperiment();
  const { displayMode = DisplayMode.DesktopHeader } = props;
  // Only render if the displayMode prop matches the Contentful settings
  const matchesDisplayMode = props.displayModes?.includes(displayMode);
  // This is mis-named, and the model should be "showCriteria". :facepalm:.
  const shouldShow = !props.hideCriteria || checkCriteria(props.hideCriteria);
  if (!matchesDisplayMode || !shouldShow) return null;
  return props.callToAction ? <CallToAction {...props.callToAction} /> : null;
};
