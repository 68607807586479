import type { ReactNode } from 'react';

import type { BaseComponentProps } from '../../types';

export interface AccordionProps extends BaseComponentProps {
  multipleOpen?: boolean;
  title?: ReactNode;
  children?: ReactNode;
  titleDataset?: DOMStringMap;
}
