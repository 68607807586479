import { css } from '@emotion/css';

export const footnoteInlineNoDisplayTextCss = css`
  padding-bottom: 0;
`;

export const footnoteInlineSuperscriptCss = css`
  font-size: 10px;
  line-height: 18px;
`;

export const footnoteInlineSuperscriptOverlayCss = css`
  float: left;
  font-size: 10px;
  line-height: 20px;
  margin-inline-end: 0.125em;
`;
