/** Typical contentful environment aliases. */
export /* not-const */ enum ContentfulAlias {
  LATEST_DEV = 'latest-dev',
  MASTER = 'master',
  PRODUCTION = 'production',
}

export const contentfulMainEnvName = 'main';

export const contentfulDevEnvPrefix = 'dev-';

export const contentfulBackupEnvPrefix = 'backup-';

export function isDevEnv(name: string): boolean {
  return name.startsWith(contentfulDevEnvPrefix);
}

export function isBackupEnv(name: string): boolean {
  return name.startsWith(contentfulBackupEnvPrefix);
}
