import {
  type Motif,
  Black,
  Blue,
  defaultMotif,
  FontFamily,
  Gray,
  m,
  mergeMotifs,
  MotifComponent,
  MotifScheme,
  Plain,
} from '@snapchat/snap-design-system-marketing';

/** Generated by figmaTokensImport on 2023-11-18T04:39:42.862Z. */
export const customBlueMotif: Motif = mergeMotifs(defaultMotif, {
  name: 'Blue',
  fontFamily: FontFamily.GRAPHIK,
  [MotifScheme.DEFAULT]: {
    name: 'Imported Deafult',
    legacyName: 'Yellow',
    [MotifComponent.HYPERLINK]: {
      '--hyperlink-desktop-font-text-decoration': 'underline',
      '--hyperlink-mobile-font-text-decoration': 'underline',
      '--hyperlink-mobile-font-weight': 'Medium',
      '--hyperlink-desktop-font-weight': 'Medium',
      '--hyperlink-color': `${m('--action-hover-color')}`,
    },
    [MotifComponent.ROOT]: {
      '--border-radius-l': '12px',
      '--border-radius-xl': '16px',
      '--box-shadow-l': '0px 16px 32px 0 #000 12%',
      '--background-color': Blue.V100,
      '--action-default-color': Black.V50,
      '--action-hover-color': Black.V100,
    },
    [MotifComponent.BUTTON]: {
      '--button-primary-fg-color': Blue.V50,
      '--button-secondary-bg-color': Blue.V50,
      '--button-secondary-hover-bg-color': Blue.V50,
      '--button-secondary-border-color': Blue.V50,
      '--button-secondary-hover-border-color': Blue.V50,
      '--button-flat-fg-color': `${m('--foreground-color')}`,
      '--button-border-radius': `${m('--border-radius-s')}`,
      '--button-hover-shadow': `${m('--box-shadow-l')}`,
      '--button-regular-padding': 'var(--spacing-s) var(--spacing-m)',
      '--button-compact-padding': 'var(--spacing-xs) var(--spacing-m)',
    },
    [MotifComponent.HEADER]: {
      '--global-header-nav-screen-fg-color': Black.V150,
    },
    [MotifComponent.BLOCK]: {
      '--block-eyebrow-color': Plain.BLACK,
      '--block-title-color': Plain.BLACK,
      '--block-subtitle-color': Plain.BLACK,
    },
    [MotifComponent.TABS]: {},
    [MotifComponent.BREAK]: {},
    [MotifComponent.ACCORDION]: {
      '--accordion-header-color': `${m('--foreground-color')}`,
      '--accordion-divider-border-color': `${m('--foreground-color')}`,
    },
    [MotifComponent.QUOTE]: {
      '--quote-bg-color': Blue.V50,
    },
    [MotifComponent.FOOTER]: {
      '--footer-bg-color': `${m('--background-color')}`,
      '--footer-divider-border-color': `${m('--foreground-color')}`,
      '--footer-bar-bg-color': `${m('--background-color')}`,
    },
    [MotifComponent.SUMMARY_CARD]: {
      '--summary-card-title-color': Plain.BLACK,
      '--summary-card-description-color': Black.V100,
      '--summary-card-bg-color': Blue.V50,
      '--summary-card-border-radius': `${m('--border-radius-l')}`,
    },
    [MotifComponent.FORM]: {
      '--form-input-bg-color': Blue.V50,
      '--form-input-border-radius': `${m('--border-radius-xl')}`,
      '--form-input-padding': 'var(--spacing-m) var(--spacing-l)',
      '--form-grid-gap': `${m('--spacing-m')}`,
    },
    [MotifComponent.FOOTNOTE]: {
      '--footnote-fg-color': Black.V50,
      '--footnote-bg-color': Blue.V50,
    },
    [MotifComponent.DROPDOWN_MENU]: {
      '--dropdown-menu-bg-color': Blue.V50,
    },
    [MotifComponent.BANNER]: {
      '--banner-bg-color': `${m('--background-color')}`,
      '--banner-fg-color': `${m('--foreground-color')}`,
    },
    [MotifComponent.CONTENT]: {
      '--content-mobile-grid-gap': `${m('--spacing-l')}`,
    },
    [MotifComponent.MOSAIC]: {
      '--mosaic-border-radius': '24px',
      '--mosaic-grid-gap': '32px',
      '--mosaic-highlight-color': Blue.V100,
      '--mosaic-title-color': Blue.V50,
    },
    [MotifComponent.MEDIA]: {},
    [MotifComponent.SEARCH]: {},
    [MotifComponent.PAGINATION]: {},
    [MotifComponent.SIDE_NAVIGATION]: {},
    [MotifComponent.SPINNER]: {
      '--spinner-fg-color': `${m('--button-primary-fg-color')}`,
    },
    [MotifComponent.MODAL]: {
      '--modal-background-color': 'rgba(0,0,0,0.7)',
      '--modal-close-foreground-color': Blue.V50,
    },
    [MotifComponent.ICON_BUTTON]: {
      '--icon-button-bg-color': Plain.BLACK,
      '--icon-button-hover-bg-color': Black.V100,
      '--icon-button-disabled-bg-color': 'rgba(0,0,0,0)',
    },
  },
  [MotifScheme.SECONDARY]: {
    name: 'Imported secondary',
    legacyName: 'Black',
    [MotifComponent.HYPERLINK]: {
      '--hyperlink-desktop-font-text-decoration': 'underline',
      '--hyperlink-mobile-font-text-decoration': 'underline',
      '--hyperlink-mobile-font-weight': 'Medium',
      '--hyperlink-desktop-font-weight': 'Medium',
      '--hyperlink-color': `${m('--action-hover-color')}`,
    },
    [MotifComponent.ROOT]: {
      '--box-shadow-s': '0px 4px 8px 0 rgba(0, 0, 0, 0.12)',
      '--box-shadow-m': '0px 8px 16px 0 rgba(0, 0, 0, 0.12)',
      '--border-radius-xs': '1px',
      '--border-radius-s': '4px',
      '--border-radius-m': '8px',
      '--border-radius-l': '12px',
      '--border-radius-xl': '16px',
      '--spacing-xxxs': '2px',
      '--spacing-xxs': '4px',
      '--spacing-xs': '8px',
      '--spacing-s': '12px',
      '--spacing-m': '16px',
      '--spacing-l': '24px',
      '--spacing-xl': '32px',
      '--spacing-xxl': '40px',
      '--spacing-xxxxl': '64px',
      '--box-shadow-l': '0px 16px 32px 0 #000 12%',
      '--foreground-color': Blue.V50,
      '--action-default-color': Black.V50,
      '--action-hover-color': Black.V100,
      '--action-active-color': Plain.BLACK,
    },
    [MotifComponent.BUTTON]: {
      '--button-primary-bg-color': Blue.V100,
      '--button-primary-hover-bg-color': Blue.V100,
      '--button-primary-border-color': Blue.V100,
      '--button-primary-hover-border-color': Blue.V100,
      '--button-secondary-bg-color': Blue.V50,
      '--button-secondary-hover-bg-color': Blue.V50,
      '--button-secondary-border-color': Blue.V50,
      '--button-secondary-hover-border-color': Blue.V50,
      '--button-flat-fg-color': Blue.V50,
      '--button-border-radius': `${m('--border-radius-s')}`,
      '--button-hover-shadow': `${m('--box-shadow-l')}`,
      '--button-regular-padding': 'var(--spacing-s) var(--spacing-m)',
      '--button-compact-padding': 'var(--spacing-xs) var(--spacing-m)',
    },
    [MotifComponent.HEADER]: {
      '--global-header-nav-screen-fg-color': Black.V150,
      '--global-header-fg-color': Gray.V150,
    },
    [MotifComponent.BLOCK]: {
      '--block-eyebrow-color': Blue.V50,
      '--block-title-color': Blue.V50,
      '--block-subtitle-color': Blue.V50,
    },
    [MotifComponent.TABS]: {
      '--tabs-underline-color': Black.V100,
    },
    [MotifComponent.BREAK]: {},
    [MotifComponent.ACCORDION]: {
      '--accordion-header-color': `${m('--foreground-color')}`,
      '--accordion-divider-border-color': `${m('--foreground-color')}`,
    },
    [MotifComponent.QUOTE]: {
      '--quote-bg-color': Blue.V50,
    },
    [MotifComponent.FOOTER]: {
      '--footer-bg-color': `${m('--background-color')}`,
      '--footer-divider-border-color': `${m('--foreground-color')}`,
      '--footer-bar-bg-color': `${m('--background-color')}`,
    },
    [MotifComponent.SUMMARY_CARD]: {
      '--summary-card-title-color': Plain.BLACK,
      '--summary-card-description-color': Black.V100,
      '--summary-card-bg-color': Blue.V50,
      '--summary-card-border-radius': `${m('--border-radius-l')}`,
    },
    [MotifComponent.FORM]: {
      '--form-input-bg-color': Blue.V50,
      '--form-input-border-radius': `${m('--border-radius-xl')}`,
      '--form-input-padding': 'var(--spacing-m) var(--spacing-l)',
      '--form-grid-gap': `${m('--spacing-m')}`,
    },
    [MotifComponent.FOOTNOTE]: {
      '--footnote-fg-color': Black.V50,
      '--footnote-bg-color': Blue.V50,
    },
    [MotifComponent.DROPDOWN_MENU]: {
      '--dropdown-menu-bg-color': Black.V100,
    },
    [MotifComponent.BANNER]: {
      '--banner-bg-color': `${m('--background-color')}`,
      '--banner-fg-color': `${m('--foreground-color')}`,
    },
    [MotifComponent.CONTENT]: {
      '--content-mobile-grid-gap': `${m('--spacing-l')}`,
    },
    [MotifComponent.MOSAIC]: {
      '--mosaic-border-radius': '24px',
      '--mosaic-grid-gap': '32px',
      '--mosaic-highlight-color': Blue.V100,
      '--mosaic-title-color': Blue.V50,
    },
    [MotifComponent.MEDIA]: {},
    [MotifComponent.SEARCH]: {
      '--search-no-results-bg-color': Gray.V100,
      '--search-no-results-fg-color': Black.V150,
    },
    [MotifComponent.PAGINATION]: {
      '--pagination-text-active-color': Blue.V50,
      '--pagination-text-color': Gray.V300,
      '--pagination-text-hover-color': Gray.V250,
    },
    [MotifComponent.SPINNER]: {
      '--spinner-fg-color': `${m('--button-primary-fg-color')}`,
    },
    [MotifComponent.MODAL]: {
      '--modal-background-color': 'rgba(0,0,0,0.7)',
      '--modal-close-background-color': Plain.BLACK,
      '--modal-close-foreground-color': Blue.V50,
    },
    [MotifComponent.ICON_BUTTON]: {
      '--icon-button-hover-bg-color': Gray.V150,
      '--icon-button-disabled-bg-color': Black.V50,
      '--icon-button-border-color': Plain.WHITE,
      '--icon-button-fg-color': Plain.BLACK,
      '--icon-button-disabled-fg-color': Gray.V300,
    },
  },
  [MotifScheme.TERTIARY]: {
    name: 'Imported tertiary',
    legacyName: 'White',
    [MotifComponent.HYPERLINK]: {
      '--hyperlink-desktop-font-text-decoration': 'underline',
      '--hyperlink-mobile-font-text-decoration': 'underline',
      '--hyperlink-mobile-font-weight': 'Medium',
      '--hyperlink-desktop-font-weight': 'Medium',
      '--hyperlink-color': `${m('--action-hover-color')}`,
    },
    [MotifComponent.ROOT]: {
      '--box-shadow-s': '0px 4px 8px 0 rgba(0, 0, 0, 0.12)',
      '--box-shadow-m': '0px 8px 16px 0 rgba(0, 0, 0, 0.12)',
      '--border-radius-xs': '1px',
      '--border-radius-s': '4px',
      '--border-radius-m': '8px',
      '--border-radius-l': '12px',
      '--border-radius-xl': '16px',
      '--spacing-xxxs': '2px',
      '--spacing-xxs': '4px',
      '--spacing-xs': '8px',
      '--spacing-s': '12px',
      '--spacing-m': '16px',
      '--spacing-l': '24px',
      '--spacing-xl': '32px',
      '--spacing-xxl': '40px',
      '--spacing-xxxxl': '64px',
      '--box-shadow-l': '0px 16px 32px 0 #000 12%',
      '--background-color': Blue.V50,
      '--action-default-color': Black.V50,
      '--action-hover-color': Black.V100,
    },
    [MotifComponent.BUTTON]: {
      '--button-primary-bg-color': Blue.V100,
      '--button-primary-hover-bg-color': Blue.V100,
      '--button-primary-border-color': Blue.V100,
      '--button-primary-hover-border-color': Blue.V100,
      '--button-secondary-fg-color': Blue.V50,
      '--button-border-radius': `${m('--border-radius-s')}`,
      '--button-hover-shadow': `${m('--box-shadow-l')}`,
      '--button-regular-padding': 'var(--spacing-s) var(--spacing-m)',
      '--button-compact-padding': 'var(--spacing-xs) var(--spacing-m)',
    },
    [MotifComponent.HEADER]: {
      '--global-header-nav-screen-bg-color': Blue.V50,
      '--global-header-nav-screen-fg-color': Black.V150,
      '--global-header-nav-screen-global-links-bg-color': Blue.V50,
      '--global-header-fg-color': Black.V150,
    },
    [MotifComponent.BLOCK]: {
      '--block-eyebrow-color': Plain.BLACK,
      '--block-title-color': Plain.BLACK,
      '--block-subtitle-color': Plain.BLACK,
    },
    [MotifComponent.TABS]: {},
    [MotifComponent.BREAK]: {},
    [MotifComponent.ACCORDION]: {
      '--accordion-header-color': `${m('--foreground-color')}`,
      '--accordion-divider-border-color': `${m('--foreground-color')}`,
    },
    [MotifComponent.QUOTE]: {
      '--quote-bg-color': Blue.V50,
    },
    [MotifComponent.FOOTER]: {
      '--footer-bg-color': `${m('--background-color')}`,
    },
    [MotifComponent.SUMMARY_CARD]: {
      '--summary-card-title-color': Plain.BLACK,
      '--summary-card-description-color': Black.V100,
      '--summary-card-bg-color': Blue.V50,
      '--summary-card-border-radius': `${m('--border-radius-l')}`,
    },
    [MotifComponent.FORM]: {
      '--form-input-bg-color': Blue.V50,
      '--form-input-border-radius': `${m('--border-radius-xl')}`,
      '--form-input-padding': 'var(--spacing-m) var(--spacing-l)',
      '--form-grid-gap': `${m('--spacing-m')}`,
    },
    [MotifComponent.FOOTNOTE]: {
      '--footnote-fg-color': Black.V50,
      '--footnote-bg-color': Blue.V50,
    },
    [MotifComponent.DROPDOWN_MENU]: {
      '--dropdown-menu-bg-color': Blue.V50,
    },
    [MotifComponent.BANNER]: {
      '--banner-bg-color': `${m('--background-color')}`,
      '--banner-fg-color': `${m('--foreground-color')}`,
    },
    [MotifComponent.CONTENT]: {
      '--content-mobile-grid-gap': `${m('--spacing-l')}`,
    },
    [MotifComponent.MOSAIC]: {
      '--mosaic-border-radius': '24px',
      '--mosaic-grid-gap': '32px',
      '--mosaic-highlight-color': Blue.V100,
      '--mosaic-title-color': Blue.V50,
    },
    [MotifComponent.MEDIA]: {},
    [MotifComponent.SEARCH]: {
      '--search-no-results-bg-color': Gray.V100,
      '--search-no-results-fg-color': Black.V150,
    },
    [MotifComponent.PAGINATION]: {},
    [MotifComponent.SPINNER]: {
      '--spinner-fg-color': `${m('--button-primary-fg-color')}`,
    },
    [MotifComponent.MODAL]: {
      '--modal-background-color': 'rgba(0,0,0,0.7)',
      '--modal-close-background-color': Plain.BLACK,
      '--modal-close-foreground-color': Blue.V50,
    },
    [MotifComponent.ICON_BUTTON]: {
      '--icon-button-bg-color': Plain.BLACK,
      '--icon-button-hover-bg-color': Black.V100,
      '--icon-button-disabled-bg-color': 'rgba(0,0,0,0)',
    },
  },
  [MotifScheme.QUATERNARY]: {
    name: 'Imported quaternary',
    legacyName: 'Gray',
    [MotifComponent.HYPERLINK]: {
      '--hyperlink-desktop-font-text-decoration': 'underline',
      '--hyperlink-mobile-font-text-decoration': 'underline',
      '--hyperlink-mobile-font-weight': 'Medium',
      '--hyperlink-desktop-font-weight': 'Medium',
      '--hyperlink-color': `${m('--action-hover-color')}`,
    },
    [MotifComponent.ROOT]: {
      '--box-shadow-s': '0px 4px 8px 0 rgba(0, 0, 0, 0.12)',
      '--box-shadow-m': '0px 8px 16px 0 rgba(0, 0, 0, 0.12)',
      '--border-radius-xs': '1px',
      '--border-radius-s': '4px',
      '--border-radius-m': '8px',
      '--border-radius-l': '12px',
      '--border-radius-xl': '16px',
      '--spacing-xxxs': '2px',
      '--spacing-xxs': '4px',
      '--spacing-xs': '8px',
      '--spacing-s': '12px',
      '--spacing-m': '16px',
      '--spacing-l': '24px',
      '--spacing-xl': '32px',
      '--spacing-xxl': '40px',
      '--spacing-xxxxl': '64px',
      '--box-shadow-l': '0px 16px 32px 0 #000 12%',
      '--action-default-color': Black.V50,
      '--action-hover-color': Black.V100,
    },
    [MotifComponent.BUTTON]: {
      '--button-primary-bg-color': Blue.V100,
      '--button-primary-hover-bg-color': Blue.V100,
      '--button-primary-border-color': Blue.V100,
      '--button-primary-hover-border-color': Blue.V100,
      '--button-secondary-bg-color': Blue.V50,
      '--button-secondary-hover-bg-color': Blue.V50,
      '--button-secondary-border-color': Blue.V50,
      '--button-secondary-hover-border-color': Blue.V50,
      '--button-border-radius': `${m('--border-radius-s')}`,
      '--button-hover-shadow': `${m('--box-shadow-l')}`,
      '--button-regular-padding': 'var(--spacing-s) var(--spacing-m)',
      '--button-compact-padding': 'var(--spacing-xs) var(--spacing-m)',
    },
    [MotifComponent.HEADER]: {
      '--global-header-nav-screen-bg-color': Blue.V50,
      '--global-header-nav-screen-fg-color': Black.V150,
      '--global-header-nav-screen-global-links-bg-color': Blue.V50,
      '--global-header-fg-color': Black.V150,
    },
    [MotifComponent.BLOCK]: {
      '--block-eyebrow-color': Blue.V200,
      '--block-title-color': Blue.V200,
      '--block-subtitle-color': Plain.BLACK,
    },
    [MotifComponent.TABS]: {},
    [MotifComponent.BREAK]: {},
    [MotifComponent.ACCORDION]: {
      '--accordion-header-color': `${m('--foreground-color')}`,
      '--accordion-divider-border-color': `${m('--foreground-color')}`,
    },
    [MotifComponent.QUOTE]: {
      '--quote-bg-color': Blue.V50,
    },
    [MotifComponent.FOOTER]: {
      '--footer-bg-color': `${m('--background-color')}`,
      '--footer-bar-bg-color': `${m('--background-color')}`,
    },
    [MotifComponent.SUMMARY_CARD]: {
      '--summary-card-title-color': Plain.BLACK,
      '--summary-card-description-color': Black.V100,
      '--summary-card-bg-color': Blue.V50,
      '--summary-card-border-radius': `${m('--border-radius-l')}`,
    },
    [MotifComponent.FORM]: {
      '--form-input-bg-color': Blue.V50,
      '--form-input-border-radius': `${m('--border-radius-xl')}`,
      '--form-input-padding': 'var(--spacing-m) var(--spacing-l)',
      '--form-grid-gap': `${m('--spacing-m')}`,
    },
    [MotifComponent.FOOTNOTE]: {
      '--footnote-fg-color': Black.V50,
      '--footnote-bg-color': Blue.V50,
    },
    [MotifComponent.DROPDOWN_MENU]: {
      '--dropdown-menu-bg-color': Blue.V50,
    },
    [MotifComponent.BANNER]: {
      '--banner-bg-color': `${m('--background-color')}`,
      '--banner-fg-color': `${m('--foreground-color')}`,
    },
    [MotifComponent.CONTENT]: {
      '--content-mobile-grid-gap': `${m('--spacing-l')}`,
    },
    [MotifComponent.MOSAIC]: {
      '--mosaic-border-radius': '24px',
      '--mosaic-grid-gap': '32px',
      '--mosaic-highlight-color': Blue.V100,
      '--mosaic-title-color': Blue.V50,
    },
    [MotifComponent.MEDIA]: {},
    [MotifComponent.SEARCH]: {
      '--search-no-results-bg-color': Gray.V100,
      '--search-no-results-fg-color': Black.V150,
    },
    [MotifComponent.PAGINATION]: {},
    [MotifComponent.SPINNER]: {
      '--spinner-fg-color': `${m('--button-primary-fg-color')}`,
    },
    [MotifComponent.MODAL]: {
      '--modal-background-color': 'rgba(0,0,0,0.7)',
      '--modal-close-background-color': Plain.BLACK,
      '--modal-close-foreground-color': Blue.V50,
    },
    [MotifComponent.ICON_BUTTON]: {
      '--icon-button-bg-color': Plain.BLACK,
      '--icon-button-hover-bg-color': Black.V100,
      '--icon-button-disabled-bg-color': 'rgba(0,0,0,0)',
    },
  },
});
