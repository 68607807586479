import { Icon } from '../../Icon';
import { Picture } from '../../Picture';
import type { IconOrImageProps } from './types';

export const IconOrImage = ({ fill, icon, media, size }: IconOrImageProps) => {
  // Icon takes precendence if provided
  if (icon) {
    return <Icon size={size} name={icon.name} fill={icon.fill ? icon.fill : fill} />;
  }

  // Otherwise use image if provided
  if (media) {
    const { altText, imgSrcs } = media;
    return <Picture imgSrcs={imgSrcs} width={size} height={size} altText={altText} />;
  }

  return null;
};

IconOrImage.displayName = 'IconOrImage';
