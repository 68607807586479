import type { FC, PropsWithChildren } from 'react';
import { useContext } from 'react';

import { DesignSystemContext } from '../../context/DesignSystemContext';
import type { BackgroundType } from '../types';

export interface DesignAgnosticModalProps extends PropsWithChildren {
  backgroundType?: BackgroundType;
  className?: string;
  isDisplayed?: boolean;
  portalRoot?: HTMLElement;
}

export const DesignAgnosticModal: FC<DesignAgnosticModalProps> = props => {
  const { modalComponent } = useContext(DesignSystemContext);
  return modalComponent(props);
};
