import { css } from '@emotion/css';

import { mobileMediaQuery, VerticalAlignment } from '../../constants';
import { ZIndex } from '../../constants/zIndex';
import { m } from '../../motif/m';

export const alignmentCss: Record<VerticalAlignment, string> = {
  [VerticalAlignment.Top]: css`
    left: 50%;
    top: 0;
    transform: translateX(-50%);
  `,
  [VerticalAlignment.Bottom]: css`
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
  `,
  [VerticalAlignment.Middle]: css`
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  `,
};

export const modalBackdropCss = css`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  box-shadow: none;
  z-index: ${ZIndex.MODAL};
  overflow: hidden;
  background: ${m('--modal-background-color')};
  backdrop-filter: blur(4px);

  /* ==========================================================================================================
  Specific fix to address errant behavior in Safari browser when in fullscreen mode.
    Bug Behavior: certain CSS transforms (like blur) will cause the browser to stop honoring z-index values.
      Since this style is only applied when in full-screen mode, there should be no issues with
      removing the background blur since it should be hidden by the fullscreen content.
    Use Case: Rendering a Video player inside of the SDS-M Modal component.
  ========================================================================================================== */
  :-webkit-full-screen-ancestor:not(iframe) {
    /* NOTE: Safari needs the prefix. See https://caniuse.com/css-backdrop-filter */
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-backdrop-filter: unset;
  }
`;

export const modalContentCss = css`
  position: fixed;
`;

export const isBlockingClassName = 'sdsm-modal-blocking';

export const closeButtonCss = css`
  --close-button-size: 40px;

  ${mobileMediaQuery} {
    right: 0;
    --close-button-size: 32px;
  }

  /* Render over video content. */
  z-index: ${ZIndex.MODAL + 1};

  *[dir='rtl'] {
    left: -80px;
  }

  position: absolute;
  right: calc(0px - calc(var(--close-button-size) + ${m('--spacing-s')}));
  top: calc(0px - calc(var(--close-button-size) + ${m('--spacing-s')}));
`;
