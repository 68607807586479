import type { FC } from 'react';
import { createContext } from 'react';

import type {
  DesignAgnosticButtonProps,
  DesignAgnosticLocaleDropdownProps,
  DesignAgnosticModalProps,
  DesignAgnosticSectionProps,
  DesignAgnosticToggleProps,
} from '../components/designAgnostic';

export interface DesignSystemProps {
  buttonComponent: FC<DesignAgnosticButtonProps>;
  localeDropdownComponent: FC<DesignAgnosticLocaleDropdownProps>;
  modalComponent: FC<DesignAgnosticModalProps>;
  sectionComponent: FC<DesignAgnosticSectionProps>;
  toggleComponent: FC<DesignAgnosticToggleProps>;
}

interface NotImplementedProps {
  component: string;
}

const NotImplemented: FC<NotImplementedProps> = ({ component }) => {
  console.error(`${component} not implemented! This must be specified via DesignSystemContext.`);
  return null;
};

/** Context used by consumers to define UI implementations used to render Cookie Components. */
export const DesignSystemContext = createContext<DesignSystemProps>({
  buttonComponent: () => <NotImplemented component="buttonComponent" />,
  localeDropdownComponent: () => <NotImplemented component="localeDropdownComponent" />,
  modalComponent: () => <NotImplemented component="modalComponent" />,
  sectionComponent: () => <NotImplemented component="sectionComponent" />,
  toggleComponent: () => <NotImplemented component="toggleComponent" />,
});
