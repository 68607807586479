import { cx } from '@emotion/css';
import type { KeyboardEventHandler, ReactNode } from 'react';
import { forwardRef, useContext } from 'react';

import type { ButtonSize } from '../../constants';
import { Size } from '../../constants';
import type { MotifVar } from '../../motif';
import { m } from '../../motif/m';
import { MotifComponent } from '../../motif/motifConstants';
import { useMotifStyles } from '../../motif/motifReactUtils';
import type { BaseComponentProps, ImageSources } from '../../types';
import type { OnActivateHandler } from '../../types/activationEvents';
import { dataSetToAttributes } from '../../utils';
import type { IconName } from '../Icon';
import { Icon } from '../Icon';
import { Picture } from '../Picture';
import { PrimitivesContext } from '../Primitives';
import { Spinner } from '../Spinner';
import {
  buttonCss,
  childrenContainerCss,
  flatSpinnerButtonCss,
  inlineSpinnerLargeCss,
  inlineSpinnerRegularCss,
  primarySpinnerButtonCss,
  secondarySpinnerButtonCss,
} from './Button.styled';
import type { Image } from './types';
import { ButtonType } from './types';

export interface ButtonProps extends BaseComponentProps {
  link?: string;
  target?: string;
  onClick?: OnActivateHandler;
  size?: ButtonSize;
  type?: ButtonType;
  nativeButtonType?: 'button' | 'submit' | 'reset';
  /** @depracted use imgSrcs and imgAltText instead */
  image?: Image;
  iconName?: IconName;
  loading?: boolean;
  disabled?: boolean;
  children?: ReactNode;
  imgSrcs?: ImageSources;
  imgAltText?: string;
  buttonTextDataset?: DOMStringMap;
}

// Display name is added in Object.assign in default export at bottom of file
// eslint-disable-next-line react/display-name
export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    children,
    link,
    onClick,
    size = Size.Regular,
    type = ButtonType.Secondary,
    image,
    iconName,
    className,
    loading,
    disabled,
    dataset,
    imgSrcs,
    imgAltText,
    buttonTextDataset,
    nativeButtonType,
    ...passThroughProps
  } = props;

  const { Anchor } = useContext(PrimitivesContext);

  if (disabled && link) {
    console.warn('You are trying to disable an anchor with a link. This does nothing.');
  }

  if (loading && link) {
    console.warn('You are trying to load an anchor with a link. This does nothing.');
  }

  const onKeyPress: KeyboardEventHandler<HTMLElement> = event => {
    if (event.key === 'Enter') {
      onClick?.(event);
    }
  };

  useMotifStyles(MotifComponent.BUTTON);

  const buttonProps = {
    className: cx(
      MotifComponent.BUTTON,
      {
        'button-regular': size === Size.Regular,
        'button-compact': size === Size.Compact,
        'button-large': size === Size.Large,
        'button-primary': type === ButtonType.Primary,
        'button-secondary': type === ButtonType.Secondary,
        'button-flat': type === ButtonType.Flat || size === Size.Flat, // TODO: Resolve conflict.
        'button-loading': loading,
      },
      buttonCss,
      className
    ),
    type: nativeButtonType,
    onClick,
    onKeyPress,
    ...(link && { href: link }),
    disabled: loading || disabled,
    'aria-disabled': disabled,
    ...dataSetToAttributes(dataset),
  };

  const iconFillColor: Record<ButtonType, MotifVar> = {
    [ButtonType.Flat]: '--button-flat-fg-color',
    [ButtonType.Primary]: '--button-primary-fg-color',
    [ButtonType.Secondary]: '--button-secondary-fg-color',
  };

  const picture =
    image || imgSrcs ? (
      <Picture altText={imgAltText || image?.title} imgSrcs={imgSrcs} defaultSrc={image?.url} />
    ) : undefined;

  const loadingSpinner = (
    <Spinner
      className={cx({
        [primarySpinnerButtonCss]: type === ButtonType.Primary,
        [secondarySpinnerButtonCss]: type === ButtonType.Secondary,
        [flatSpinnerButtonCss]: type === ButtonType.Flat || size === Size.Flat,
        [inlineSpinnerRegularCss]: size === Size.Regular && type !== ButtonType.Flat,
        [inlineSpinnerLargeCss]: size === Size.Large && type !== ButtonType.Flat,
      })}
    />
  );

  const content = (
    <>
      {loading && !link && loadingSpinner}
      {!loading && iconName && <Icon name={iconName} fill={m(iconFillColor[type])} />}
      {!loading && picture}
      {children && (
        <span className={childrenContainerCss} {...dataSetToAttributes(buttonTextDataset)}>
          {children}
        </span>
      )}
    </>
  );

  if (link) {
    return (
      <Anchor {...passThroughProps} {...buttonProps}>
        {content}
      </Anchor>
    );
  }

  return (
    <button {...passThroughProps} {...buttonProps} ref={ref}>
      {content}
    </button>
  );
});
Button.displayName = 'Button';
