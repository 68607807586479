import { createContext } from 'react';

interface LayoutContextProps {
  hasSideNav: boolean;
  hasSubNav: boolean;
}

const defaultContext: LayoutContextProps = {
  hasSideNav: false,
  hasSubNav: false,
};

export const LayoutContext = createContext(defaultContext);
