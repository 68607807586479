import { gql } from '@apollo/client';

import type { ContentfulSysProps, ContentfulTypedSysProps } from '../../types/contentful';
import type { Items } from '../../types/Items';
import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';

// =================================================================================================
// Pages with SubNavigation or SideNavigation
// =================================================================================================

export const pagesWithSubOrSideNavQuery = gql`
  query PagesWithSubOrSideNavQuery($preview: Boolean!) {
    pageCollection(
      locale: "en-US" # Always en-US because "linkedFrom" do not work in other languages.
      preview: $preview
      where: { sideNavigation_exists: true }
      limit: 500
    ) {
      total
      items {
        sideNavigation {
          ...ContentfulSysId
        }
        linkedFrom {
          entryCollection {
            total
            items {
              ...ContentfulSysId
            }
          }
        }
        ...ContentfulSysId
      }
    }
  }
  ${contentfulSysIdFragment}
`;

export interface PagesWithSubOrSideNavData {
  pageCollection: Items<
    ContentfulTypedSysProps<'Page'> & {
      sideNavigation: ContentfulSysProps;
      subNavigation: ContentfulSysProps;
      linkedFrom: {
        entryCollection: Items<ContentfulSysProps>;
      };
    }
  >;
}

// =================================================================================================
// Experiments By Ids
// =================================================================================================

export const experimentsByIdsLinkingToSlugs = gql`
  query ExperimentsByIdsLinkingToSlugsQuery($preview: Boolean!, $ids: [String]!) {
    experimentCollection(
      locale: "en-US" # Always en-US because "linkedFrom" do not work in other languages.
      preview: $preview
      where: { sys: { id_in: $ids } }
      limit: 500
    ) {
      total
      items {
        ...ContentfulSysId
        linkedFrom {
          entryCollection {
            total
            items {
              ...ContentfulSysId
            }
          }
        }
      }
    }
  }
  ${contentfulSysIdFragment}
`;

export interface ExperimentsByIdsLinkingToSlugsData {
  experimentCollection: Items<
    ContentfulTypedSysProps<'Experiment'> & {
      linkedFrom: {
        entryCollection: Items<ContentfulSysProps>;
      };
    }
  >;
}

// =================================================================================================
// Slugs by Ids
// =================================================================================================
export const slugsByIdsQuery = gql`
  query SlugCollectionByIdQuery($preview: Boolean!, $locale: String!, $ids: [String]!) {
    slugCollection(
      locale: $locale
      preview: $preview
      limit: 500
      where: { sys: { id_in: $ids } }
    ) {
      total
      items {
        ...ContentfulSysId
        slug
      }
    }
  }
  ${contentfulSysIdFragment}
`;

export interface SlugsByIdsData {
  slugCollection: Items<
    ContentfulTypedSysProps<'Slug'> & {
      slug: string;
    }
  >;
}
