import { cx } from '@emotion/css';
import type { FC } from 'react';
import type React from 'react';
import { memo, useCallback, useMemo, useRef, useState } from 'react';

import { MotifComponent, useMotifStyles } from '../../motif';
import { MosaicFilters } from './MosaicFilters';
import { MosaicLightBox } from './MosaicLightBox';
import { MosaicTile } from './MosaicTile';
import { containerCss, tileCss } from './styles';
import type { MosaicProps } from './types';

/** A component that lays out videos onto a grid. */
export const Mosaic: FC<MosaicProps> = memo(
  ({ tiles, columns, mobileColumns, filters, className, lightboxClassName }) => {
    useMotifStyles(MotifComponent.MOSAIC);

    const [currentTile, setCurrentTile] = useState<number>();
    const [currentFilter, setCurrentFilter] = useState<string>('');
    const [isRTL, setIsRTL] = useState(false);
    const containerRef = useCallback((node: HTMLDivElement) => {
      setIsRTL(!!node?.closest('[dir="rtl"]'));
    }, []);

    const tileRefs = useRef<Array<HTMLDivElement | null>>([]);

    const goNext = useCallback(() => {
      if (currentTile === undefined) {
        return;
      }
      const next = (currentTile + 1) % tiles.length;
      setCurrentTile(next);
    }, [currentTile, tiles.length]);
    const goPrevious = useCallback(() => {
      if (currentTile === undefined) {
        return;
      }
      const previous = (currentTile - 1 + tiles.length) % tiles.length;
      setCurrentTile(previous);
    }, [currentTile, tiles.length]);

    const close = () => {
      setCurrentTile(undefined);
    };

    const handleKeyPress = useCallback(
      (idx?: number) => (event: React.KeyboardEvent) => {
        if (idx === undefined) {
          return;
        }

        if (event.key === (isRTL ? 'ArrowLeft' : 'ArrowRight')) {
          const next = (idx + 1) % tiles.length;

          if (currentTile !== undefined) {
            setCurrentTile(next);
          }
          tileRefs.current[next]?.focus();
        }

        if (event.key === (isRTL ? 'ArrowRight' : 'ArrowLeft')) {
          const previous = (idx - 1 + tiles.length) % tiles.length;

          if (currentTile !== undefined) {
            setCurrentTile(previous);
          }
          tileRefs.current[previous]?.focus();
        }

        if (event.key === 'Enter' && currentTile === undefined) {
          setCurrentTile(idx);
        }

        if (event.key === 'Escape' && currentTile !== undefined) {
          setCurrentTile(undefined);
        }
      },
      [currentTile, isRTL, tiles.length]
    );

    const filteredTiles = useMemo(() => {
      if (!currentFilter) {
        return tiles;
      }

      return tiles.filter(t => t.filters?.includes(currentFilter));
    }, [currentFilter, tiles]);

    return (
      <div className={cx(MotifComponent.MOSAIC, className)}>
        <MosaicLightBox
          className={lightboxClassName}
          tile={currentTile !== undefined ? tiles[currentTile] : undefined}
          handleKeyPress={handleKeyPress(currentTile)}
          goNext={goNext}
          goPrevious={goPrevious}
          close={close}
        />
        {filters?.length ? (
          <MosaicFilters
            filters={filters}
            onSelectFilter={setCurrentFilter}
            currentFilter={currentFilter}
          />
        ) : undefined}
        <div
          ref={containerRef}
          className={cx(containerCss(columns, mobileColumns))}
          data-active={currentTile !== undefined}
        >
          {filteredTiles.map((tile, i) => (
            <div
              className={cx(tileCss(tile.rows, tile.columns, tile.mobileRows, tile.mobileColumns))}
              aria-pressed={currentTile === i}
              role="button"
              tabIndex={0}
              onClick={() => setCurrentTile(i)}
              onKeyDown={handleKeyPress(i)}
              key={`${i}-${tile.poster?.src}`}
              ref={el => {
                tileRefs.current[i] = el;
              }}
            >
              <MosaicTile {...tile} />
            </div>
          ))}
        </div>
      </div>
    );
  }
);

Mosaic.displayName = 'Mosaic';
