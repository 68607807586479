import { Black, Gray, Plain, Yellow } from '../../constants';
import type { MotifComponent, MotifComponentProps } from '../../motif';

export const definitionYellowBgVars: MotifComponentProps<MotifComponent.DEFINITION> = {
  '--definition-active-bg-color': Plain.WHITE,
  '--definition-active-border-color': Black.V200,
  '--definition-bg-color': Yellow.V150,
  '--definition-border-color': Black.V200,
  '--definition-hover-bg-color': Yellow.V200,
  '--definition-hover-border-color': Black.V200,
} as const;

export const definitionBlackBgVars: MotifComponentProps<MotifComponent.DEFINITION> = {
  '--definition-active-bg-color': Black.V50,
  '--definition-active-border-color': Yellow.V100,
  '--definition-bg-color': Black.V125,
  '--definition-border-color': Yellow.V200,
  '--definition-hover-bg-color': Black.V100,
  '--definition-hover-border-color': Yellow.V200,
} as const;

export const definitionWhiteBgVars: MotifComponentProps<MotifComponent.DEFINITION> = {
  '--definition-active-bg-color': Yellow.V100,
  '--definition-active-border-color': Yellow.V100,
  '--definition-bg-color': Gray.V50,
  '--definition-border-color': Yellow.V200,
  '--definition-hover-bg-color': Gray.V150,
  '--definition-hover-border-color': Yellow.V200,
} as const;

export const definitionGrayBgVars: MotifComponentProps<MotifComponent.DEFINITION> = {
  '--definition-active-bg-color': Yellow.V150,
  '--definition-active-border-color': Yellow.V150,
  '--definition-bg-color': Gray.V150,
  '--definition-border-color': Yellow.V200,
  '--definition-hover-bg-color': Gray.V200,
  '--definition-hover-border-color': Yellow.V200,
} as const;
