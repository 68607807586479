import type { BroadcastEventType, ContentfulActions } from './pubSub.constants';

/**
 * Typed attributes for pub-sub messages.
 *
 * These can be used for typecasting the body or for filtering subscriptions.
 *
 * You can sign up for a single type via `topic.createSubscription(<id>, { filter:
 * 'attributes.type="<type>"'})` See:
 * https://cloud.google.com/pubsub/docs/subscription-message-filter#node.js
 */
export type PubSubAttributes = ContentfulWebhookPubSubAttributes | BitmojiReactionPubSubAttributes;

/**
 * Message attributes for a contentful webhook for a changed Entry. These are published to the
 * CLIENT_BROADCAST topic.
 */
export interface ContentfulWebhookPubSubAttributes {
  type: BroadcastEventType.CONTENTFUL_WEBHOOK;
  entityId: string;
  entityType: string;
  spaceId: string;
  environmentId: string;
  webhookApi: string;
  webhookEntity: string;
  webhookAction: string;
  eventKey: string;
  contentType?: string;
}

/** Message attributes for a bitmoji reaction. */
export interface BitmojiReactionPubSubAttributes extends Record<string, string | undefined> {
  type: BroadcastEventType.BITMOJI_REACTION;
  videoId: string;
  eventKey: string;
  skipProcessing?: string;
}

/**
 * Message attributes for a contentful action. Unused since current implementation calls the cloud
 * function directly, but when we do need pubsub heres a basic type
 */
export interface ContentfulActionPubSubAttributes extends Record<string, unknown> {
  type: BroadcastEventType.CONTENTFUL_ACTION;
  action: ContentfulActions;
  spaceId: string;
  environmentId: string;
}
