import { Children, isValidElement } from 'react';

import { selectedCss } from './Item.styled';
import type { NavigatorItemProps } from './types';

export function isItemProps(props: unknown): props is NavigatorItemProps {
  if ((props as NavigatorItemProps).id) {
    return true;
  }

  return false;
}

export const getItemProps = (children: unknown, parentId?: string): NavigatorItemProps[] =>
  Children.map(children, child => {
    if (!children) {
      return undefined;
    }

    if (!isValidElement(child)) {
      return undefined;
    }

    if (child?.props) {
      if (isItemProps(child.props)) {
        const toReturn: NavigatorItemProps = { ...child.props };

        if (parentId) {
          toReturn.id = `${parentId}.${toReturn.id}`;
        }

        if (child.props.isSelected) {
          toReturn.title = <span className={selectedCss}>{toReturn.title}</span>;
        }

        return toReturn;
      }
    }

    return undefined;
  });
