import type { ValueOf } from '../../types';

export const ButtonType = {
  Primary: 'Primary',
  Secondary: 'Secondary',
  Flat: 'Flat',
} as const;

export type ButtonType = ValueOf<typeof ButtonType>;

export interface Image {
  url: string;
  title: string;
  width: number;
  height: number;
}
