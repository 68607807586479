/* eslint-disable max-len */
import type { FC } from 'react';

import type { GenericSvgIconProps } from '../CustomIcon';

export const OrbitIcon: FC<GenericSvgIconProps> = props => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.69 0.59C10.32 0.58 8.00 1.30 6.03 2.65C4.05 3.99 2.51 5.91 1.60 8.15C0.70 10.39 0.46 12.85 0.92 15.23C1.38 17.61 2.52 19.80 4.20 21.51C5.87 23.23 8.01 24.40 10.34 24.87C12.67 25.34 15.08 25.10 17.27 24.17C19.46 23.25 21.34 21.68 22.65 19.66C23.97 17.64 24.68 15.27 24.68 12.85C24.68 9.60 23.41 6.48 21.17 4.18C18.92 1.88 15.87 0.59 12.69 0.59ZM12.69 20.66C10.66 20.66 8.71 19.84 7.26 18.39C5.81 16.93 4.98 14.95 4.94 12.88L3.41 14.43L2.28 13.27L5.59 9.87L8.91 13.27L7.78 14.43L6.42 13.04C6.49 14.36 6.94 15.62 7.74 16.67C8.53 17.71 9.61 18.47 10.84 18.86C12.08 19.25 13.40 19.24 14.62 18.84C15.85 18.44 16.93 17.66 17.71 16.60L18.76 17.68C18.03 18.60 17.11 19.35 16.06 19.87C15.01 20.39 13.86 20.66 12.70 20.66L12.69 20.66ZM10.10 12.74C10.10 12.23 10.24 11.73 10.52 11.30C10.80 10.87 11.20 10.54 11.66 10.35C12.13 10.15 12.64 10.10 13.13 10.20C13.62 10.30 14.07 10.55 14.43 10.91C14.78 11.27 15.03 11.73 15.12 12.24C15.22 12.74 15.17 13.26 14.98 13.74C14.79 14.21 14.46 14.62 14.04 14.90C13.63 15.19 13.14 15.34 12.63 15.34C11.96 15.34 11.32 15.06 10.84 14.58C10.36 14.09 10.10 13.43 10.10 12.74ZM19.70 15.76L16.39 12.37L17.51 11.22L18.96 12.69C18.94 11.34 18.51 10.02 17.72 8.93C16.93 7.84 15.82 7.03 14.56 6.62C13.3 6.22 11.94 6.23 10.68 6.65C9.42 7.08 8.33 7.90 7.55 9.01L6.50 7.93C7.20 6.93 8.13 6.13 9.21 5.58C10.29 5.03 11.48 4.76 12.69 4.80C14.73 4.80 16.70 5.63 18.15 7.10C19.60 8.58 20.43 10.58 20.44 12.67L21.88 11.21L23.01 12.36L19.70 15.76Z"
      />
    </svg>
  );
};
