import { Black, Gray } from '../../constants/colors';
import type { MotifComponent, MotifComponentProps } from '../../motif';

export const searchDefaultVars: MotifComponentProps<MotifComponent.SEARCH> = {
  '--search-container-max-width': '896px',
  '--search-no-results-bg-color': Gray.V100,
  '--search-no-results-fg-color': Black.V150,
  '--search-results-list-gap': '36px',
  '--search-subtitle-color': Black.V50,
};
