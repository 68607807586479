import type { ReactElement } from 'react';

import type { ValueOf } from '../../../types';

/** @deprecated We only support Large toggle size. */
export const ChartToggleSizes = {
  Large: 'Large',
  Small: 'Small',
} as const;

/** @deprecated We only support Large toggle size. */
export type ChartToggleSize = ValueOf<typeof ChartToggleSizes>;

export interface ChartToggleProps {
  /** @deprecated We only support Large toggle size. */
  size?: ChartToggleSize;
  items: Array<ChartToggleItemProps>;
}

export interface ChartToggleItemProps {
  title: string;
  content: Array<ReactElement>;
  onToggle?: () => void;
}

export interface ChartToggleV2Props {
  items: Array<ChartToggleItemProps>;
  label: string;
  isLoading: boolean;
}
export interface ChartToggleDropdownsProps {
  items: Array<ChartToggleItemProps>;
  label: string;
  selectChart: (index: string) => void;
  selectedChart: string;
}
