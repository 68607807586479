import { gql } from '@apollo/client';

import { contentfulSysIdFragment } from '../../../utils/contentful/contentfulQuery';
import { chartDataFragment } from '../query';

export const fragments = {
  all: gql`
    fragment LineChartAll on LineChart {
      ...ContentfulSysId
      chartTitle
      chartData {
        ...ChartDataAll
      }
      xKey
      yKeys
      curve
    }
    ${contentfulSysIdFragment}
    ${chartDataFragment}
  `,
  withTitle: gql`
    fragment LineChartWithTitle on LineChart {
      ...ContentfulSysId
      chartTitle
    }
    ${contentfulSysIdFragment}
  `,
};

export const queries = {
  shallow: gql`
    query lineChartEntryQuery($preview: Boolean!, $locale: String!, $id: String!) {
      lineChart(preview: $preview, locale: $locale, id: $id) {
        ...ContentfulSysId
      }
    }
    ${contentfulSysIdFragment}
  `,
  all: gql`
    query lineChartEntryQuery($preview: Boolean!, $locale: String!, $id: String!) {
      lineChart(preview: $preview, locale: $locale, id: $id) {
        ...LineChartAll
      }
    }
    ${fragments.all}
  `,
};
