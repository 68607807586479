import noop from 'lodash-es/noop';

import type {
  RunningTrace,
  Tracer,
  TracerEventListener,
  TracerListenerRemover,
} from './tracingTypes';

export class NoOpTracer implements Tracer {
  startSpan: (name: string) => RunningTrace = _name => ({ endSpan: noop });
  addEventListener: (callback: TracerEventListener) => TracerListenerRemover = _callback => {
    return noop;
  };
}
