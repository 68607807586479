import { cx } from '@emotion/css';
import type { FC } from 'react';

import { MotifComponent, useMotifStyles } from '../../../motif';
import {
  skeletonChartCss,
  skeletonSvgBackgroundCss,
  skeletonSvgCss,
  skeletonSvgGradientDarkColorCss,
  skeletonSvgGradientLightColorCss,
  skeletonSvgMaskCss,
  skeletonWrapperCss,
} from './styles';

const animationDuration = '2s';

export const ChartSkeleton: FC = () => {
  useMotifStyles(MotifComponent.CHART_SKELETON);
  return (
    <article className={cx(MotifComponent.CHART_SKELETON, skeletonWrapperCss)}>
      <div className={skeletonChartCss}>
        <svg
          viewBox="0 0 664 328"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={skeletonSvgCss}
        >
          <defs>
            {/* Define the shimmer gradient */}
            <linearGradient id="shimmerGradient" x1="0" y1="0" x2="1" y2="0">
              <stop className={skeletonSvgGradientDarkColorCss} offset="0%" strokeOpacity={1} />
              <stop className={skeletonSvgGradientLightColorCss} offset="50%" strokeOpacity={1} />
              <stop className={skeletonSvgGradientDarkColorCss} offset="100%" strokeOpacity={1} />
              <animate
                attributeName="x1"
                values="-100%;100%"
                dur={animationDuration}
                repeatCount="indefinite"
              />
              <animate
                attributeName="x2"
                values="0%;200%"
                dur={animationDuration}
                repeatCount="indefinite"
              />
            </linearGradient>
            {/* Define the shimmer mask */}
            <mask id="shimmerMask">
              <rect
                className={skeletonSvgMaskCss}
                height="11.0865"
                rx="5.54325"
                width="31.4118"
                x="36.955"
                y="60.0518"
              />
              <rect
                className={skeletonSvgMaskCss}
                height="11.0865"
                rx="5.54325"
                width="31.4118"
                x="36.955"
                y="113.944"
              />
              <rect
                className={skeletonSvgMaskCss}
                height="11.0865"
                rx="5.54325"
                width="31.4118"
                x="36.955"
                y="167.838"
              />
              <rect
                className={skeletonSvgMaskCss}
                height="11.0865"
                rx="5.54325"
                width="31.4118"
                x="36.955"
                y="221.73"
              />
              <path
                className={skeletonSvgMaskCss}
                clipRule="evenodd"
                d="M609.376 62.2274C613.179 65.7331 613.232 71.466 609.493 75.0322L484.305 194.434C479.871 198.663
                472.912 199.467 467.516 196.374L472.552 188.649L467.516 196.374L347.249 127.445L229.404 229.841C225.443
                233.282 219.686 234.2 214.734 232.18L218.588 223.877L214.734 232.18L98.1939 184.629C93.3034 182.634
                91.0641 177.299 93.1923 172.714C95.3205 168.128 101.01 166.029 105.901 168.024L219.015 214.177L337.294
                111.404C341.761 107.523 348.434 106.899 353.63 109.877L348.593 117.603L353.63 109.877L473.715
                178.703L595.719 62.3369C599.458 58.7707 605.573 58.7217 609.376 62.2274ZM470.541 181.73C470.538
                181.733 470.535 181.736 470.532 181.739L474.608 185.495L470.532 181.739L470.541 181.73Z"
                fillRule="evenodd"
              />
              <rect
                className={skeletonSvgMaskCss}
                height="11.0865"
                rx="5.54325"
                width="36.955"
                x="77.6056"
                y="265.152"
              />
              <rect
                className={skeletonSvgMaskCss}
                height="11.0865"
                rx="5.54325"
                width="36.955"
                x="205.727"
                y="265.152"
              />
              <rect
                className={skeletonSvgMaskCss}
                height="11.0865"
                rx="5.54325"
                width="36.955"
                x="333.848"
                y="265.152"
              />
              <rect
                className={skeletonSvgMaskCss}
                height="11.0865"
                rx="5.54325"
                width="36.955"
                x="461.969"
                y="265.152"
              />
              <rect
                className={skeletonSvgMaskCss}
                height="11.0865"
                rx="5.54325"
                width="36.955"
                x="590.09"
                y="265.152"
              />
            </mask>
          </defs>

          <rect x="0" y="0" width="100%" height="100%" className={skeletonSvgBackgroundCss} />
        </svg>
      </div>
    </article>
  );
};
