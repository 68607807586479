import { css } from '@emotion/css';

import { mobileMediaQuery, ZIndex } from '../../constants';
import { m } from '../../motif/m';

const breakHeightCss = css`
  height: ${m('--break-total-desktop-height')};
  ${mobileMediaQuery} {
    height: ${m('--break-total-mobile-height')};
  }
`;

const breakHalfHeightCss = css`
  height: ${m('--break-half-desktop-height')};
  ${mobileMediaQuery} {
    height: ${m('--break-half-mobile-height')};
  }
`;

export const breakCss = css`
  pointer-events: none;
  position: relative;
  z-index: ${ZIndex.BREAK};
  ${breakHeightCss}
`;

/**
 * Spreads the pattern to fill the break.
 *
 * Accounts for the issue where rendering SVGs has floating point rounding that sometimes doesn't
 * fully cover the container, so we expand the image.
 *
 * Feels like there are always weird edgecases with weird zoom levels, so the more overlap the
 * better the outcome looks.
 */
export const patternCss = css`
  width: 100%;
  height: calc(100% + 6px); /* Adds 3x of play below and above. */
  position: relative;
  top: -3px; /* Spreads the extra pixels up and down. */
`;

export const topOverlaidCss = css`
  margin-top: calc(0px - ${m('--break-total-desktop-height')});

  ${mobileMediaQuery} {
    margin-top: calc(0px - ${m('--break-total-mobile-height')});
  }
`;

export const bottomOverlaidCss = css`
  margin-bottom: calc(0px - ${m('--break-total-desktop-height')});

  ${mobileMediaQuery} {
    margin-bottom: calc(0px - ${m('--break-total-mobile-height')});
  }
`;

export const topHalfOverlaidCss = css`
  margin-top: calc(0px - ${m('--break-half-desktop-height')});

  ${mobileMediaQuery} {
    margin-top: calc(0px - ${m('--break-half-mobile-height')});
  }
`;

export const bottomHalfOverlaidCss = css`
  margin-bottom: calc(0px - ${m('--break-half-desktop-height')});

  ${mobileMediaQuery} {
    margin-bottom: calc(0px - ${m('--break-half-mobile-height')});
  }
`;

export const spacerWrapperCss = css`
  pointer-events: none;
  width: 100%;
`;

export const spacerHeightFullCss = breakHeightCss;

export const spacerHeightHalfCss = breakHalfHeightCss;
