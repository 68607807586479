import { defaultLocale } from '../constants';
import { cleanAndJoinText } from './cleanText';
import { extractBlockText } from './extractBlockText';
import { extractEntryText } from './extractEntryText';
import type {
  BlockHeroType,
  BlockType,
  ContentfulClient,
  Locales,
  MetasType,
  PageType,
} from './types';

/** Function to extract text from a Page for all locales. Returns a map of locales to page text. */
export async function extractPageTextForLocales(
  pageId: string,
  locales: Locales[],
  contentfulClient: ContentfulClient
): Promise<Record<Locales, string>> {
  const pageData = await contentfulClient.getEntries({ 'sys.id[in]': [pageId], include: 2 });
  const page = pageData.items[0] as PageType;

  const metas = page.fields.metas?.[defaultLocale];

  // Get meta for all locales
  const metasText = extractEntryText<MetasType['fields']>(
    metas?.fields as MetasType['fields'],
    locales,
    ['description']
  );

  const blocks = page.fields.blocks?.[defaultLocale] ?? [];

  // Get block text for all locales
  const blockPromises = blocks.map(async block => {
    if (block?.sys.contentType.sys.id === 'blockHero') {
      return extractEntryText<BlockHeroType['fields']>((block as BlockHeroType).fields, locales, [
        'header',
        'eyebrow',
        'title',
        'body',
      ]);
    }

    if (block?.sys.contentType.sys.id === 'block') {
      return await extractBlockText(block as BlockType, locales, contentfulClient);
    }

    // We don't index content for any other content types
    return {} as Record<Locales, string>;
  });

  const blockChunks = await Promise.all(blockPromises);

  // Combine meta and block text for each locale and store it in a map
  const localesToPageText: Record<Locales, string> = {};

  for (const locale of locales) {
    const pageText = [metasText[locale], ...blockChunks.map(block => block[locale])];
    localesToPageText[locale] = cleanAndJoinText(pageText);
  }

  return localesToPageText;
}
