import type { FC, ReactNode } from 'react';

import { authorBylineContainerCss } from './styles';

export interface AuthorBylineProps {
  author?: string | ReactNode;
  date?: string | ReactNode;
}

export const AuthorByline: FC<AuthorBylineProps> = ({ author, date }) => {
  if (!author && !date) return null;

  return (
    <div className={authorBylineContainerCss}>
      {author}
      {author && date && <br />}
      {date}
    </div>
  );
};
