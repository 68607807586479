import {
  AutoComplete as AutoCompleteSDS,
  MessageContext,
} from '@snapchat/snap-design-system-marketing';
import noop from 'lodash-es/noop';
import type { ComponentProps, FC } from 'react';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { AppContext } from '../../AppContext';
import type { SearchResult } from '../Search';

type GlobalHeaderSearchProps = {
  setSearchOpen: (open: boolean) => void;
  autocompleteProps?: Pick<
    ComponentProps<typeof AutoCompleteSDS>,
    'collapsible' | 'onSeeResults' | 'initialText' | 'hideSuggestions'
  >;
};

export const GlobalHeaderSearch: FC<GlobalHeaderSearchProps> = ({
  setSearchOpen,
  autocompleteProps,
}) => {
  const { formatMessage } = useContext(MessageContext);

  const { pathname } = useLocation();
  const { onRedirect } = useContext(AppContext);

  const onSeeResults = (term: string) => {
    onRedirect?.(`/search?q=${encodeURIComponent(term)}`);
  };

  return (
    <AutoCompleteSDS<SearchResult>
      autocompleteResults={[]}
      cancelMessage={formatMessage({
        id: 'globalHeaderSearchCancel',
        defaultMessage: 'Cancel',
      })}
      collapsible
      disabled={pathname === '/search'}
      hideSuggestions
      loadingAutocompleteTerms={false}
      onChange={noop}
      onCollapseChange={open => setSearchOpen(open)}
      onSeeResults={onSeeResults}
      onSelect={noop}
      placeholder={formatMessage({
        id: 'placeholderSearchPageInput',
        defaultMessage: 'Search...',
      })}
      shortBar={pathname !== '/search'}
      {...autocompleteProps}
    />
  );
};
