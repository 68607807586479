import type { FC } from 'react';
import { useCallback, useRef, useState } from 'react';

import { MotifComponent, useMotifStyles } from '../../motif';
import { TabItems } from './TabItems';
import { TabsContext } from './TabsContext';
import { TabsPanels } from './TabsPanels';
import type { TabsV2NavigationProps } from './types';

export const TabsV2: FC<TabsV2NavigationProps> = ({
  items,
  defaultSelectedTab = 0,
  onSelectTab,
}) => {
  const [selectedTab, selectTab] = useState(defaultSelectedTab);
  const eventListenersRef = useRef([] as (() => void)[]);

  useMotifStyles(MotifComponent.TABS);

  const handleSelectTab = useCallback(
    (index: number) => {
      selectTab(index);
      onSelectTab?.(index);
      // call all event listners registered
      eventListenersRef.current.forEach(listener => listener?.());
    },
    [selectTab, onSelectTab]
  );

  if (!items || items.length < 2) {
    return null;
  }

  return (
    <TabsContext.Provider
      value={{
        addOnTabChangeListener(listener) {
          eventListenersRef.current.push(listener);
        },
        removeOnTabChangeListener(listener) {
          eventListenersRef.current = eventListenersRef.current.filter(l => l !== listener);
        },
      }}
    >
      <div className={MotifComponent.TABS} data-test-id="sdsm-tabs">
        <TabItems items={items} selectTab={handleSelectTab} selectedTab={selectedTab} />
        <TabsPanels items={items} selectedTab={selectedTab} />
      </div>
    </TabsContext.Provider>
  );
};
