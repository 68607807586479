export type Animation = {
  name: string;
  duration: string;
  delay?: string;
  direction?: 'normal' | 'reverse' | 'alternate' | 'alternate-reverse';
  timingFunction?: string;
  iterationCount?: number | 'infinite';
  transformOrigin?: string;
  target?: string;
};

export type GlobalAnimation = {
  name: string;
  keyframes: string;
};
