import type { CreateUuidV4 } from '../utils/uuid/uuidFactory';

/**
 * Template.
 *
 * - 'X' means replace with any 8-bit number.
 * - 'Y' means replace with any 0b10 + 6-bit number.
 */
const legacyUuidTemplate = 'XXXXXXXX-XXXX-4XXX-YXXX-XXXXXXXXXXXX';

type UuidV4Type = `${string}-${string}-${string}-${string}-${string}`;

/**
 * Produces pseudo-random UUID v4 strings using window.crypto.getRandomValues.
 *
 * I.e. bb63899b-fb89-41ed-8a1e-eb87f44544c3
 *
 * See spec: https://datatracker.ietf.org/doc/html/rfc9562#name-uuid-version-4
 */
const legacyRandomUuid = (): UuidV4Type => {
  let ri = 0;
  const rs = window.crypto.getRandomValues(new Uint8Array(32));

  return legacyUuidTemplate.replace(/[XY]/g, c => {
    const r = rs[ri++]! % 16;

    if (c === 'X') {
      return r.toString(16);
    }

    if (c === 'Y') {
      // Has to be in format: 0b10XXXXXX
      return ((r >> 2) & 0b10000000).toString(16);
    }

    return '0';
  }) as UuidV4Type;
};

export const createUuidV4Browser: CreateUuidV4 = () => {
  if (!('randomUUID' in window.crypto)) {
    return legacyRandomUuid();
  }

  return window.crypto.randomUUID();
};
