import type { BackgroundColor } from '@snapchat/snap-design-system-marketing';
import {
  GlobalHeaderContext,
  GlobalNavScreen as GlobalNavScreenSDS,
} from '@snapchat/snap-design-system-marketing';
import type { FC, ReactNode } from 'react';
import { useContext } from 'react';

import type { GlobalNavAllType } from '../../generated/contentful-types';
import { GlobalNavGroupCollection } from '../GlobalNavGroupCollection/GlobalNavGroupCollection';
import { GlobalNavHighlightCollection } from '../GlobalNavHighlightCollection/GlobalNavHighlightCollection';

interface GlobalNavGroupScreenProps {
  backgroundColor: BackgroundColor;
  localNavMobile: ReactNode;
  localNavMobileFooter?: ReactNode;
  navGroups: readonly GlobalNavAllType[];
  globalNavHeading: string;
  showMobileGlobalLinks?: boolean;
}

export const GlobalNavGroupScreen: FC<GlobalNavGroupScreenProps> = ({
  backgroundColor,
  localNavMobile,
  localNavMobileFooter,
  navGroups,
  globalNavHeading,
  showMobileGlobalLinks,
}) => {
  const { toggleExpanded } = useContext(GlobalHeaderContext);
  const onNavClose = toggleExpanded ?? (() => null);
  return (
    <GlobalNavScreenSDS
      localNavMobile={localNavMobile}
      localNavMobileFooter={localNavMobileFooter}
      onNavClose={onNavClose}
      backgroundColor={backgroundColor}
      globalNavHeading={globalNavHeading}
      highlight={<GlobalNavHighlightCollection navGroups={navGroups} />}
      globalNav={<GlobalNavGroupCollection navGroups={navGroups} />}
      showMobileGlobalLinks={showMobileGlobalLinks}
    />
  );
};
GlobalNavGroupScreen.displayName = 'GlobalNavGroupScreen';
