import { css } from '@emotion/css';
import type {
  SubNavigation as SubNavigationType,
  SubNavigationItem as SubNavigationItemType,
} from '@snapchat/mw-contentful-schema';
import {
  SubNavigation as SubNavigationSDS,
  SubNavigationItem as SubNavigationItemSDS,
} from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';

import { getContentfulInspectorProps } from '../../utils/contentful/getContentfulInspectorProps';
import { totalHeaderHeightCssVar } from '../Header/headerSizeUtils';
import { pageStickyHeightCssVar } from '../Page';
import type { SubNavigationProps } from './types';

enum ScrollDir {
  DOWN = 'DOWN',
  UP = 'UP',
}

const isElementHorizontallyVisible = (element: HTMLElement, parent: HTMLElement) => {
  const elementRect = element.getBoundingClientRect();
  const parentRect = parent.getBoundingClientRect();

  return elementRect.left >= parentRect.left && elementRect.right <= parentRect.right;
};

const SubNavStickyCss = css`
  top: calc(var(${pageStickyHeightCssVar}) + var(${totalHeaderHeightCssVar}));
`;

export const SubNavigation: FC<SubNavigationProps> = ({ backgroundColor, sys, items = [] }) => {
  const subnavRef = useRef<HTMLDivElement>(null);
  const [activeItemId, setActiveItemId] = useState(items[0]?.anchorId);

  // we use -1 as a flag to indicate that the scroll position has not been set yet
  const lastScrollPos = useRef(typeof window !== 'undefined' ? -1 : 0);
  const scrollDir = useRef<ScrollDir>();

  useEffect(() => {
    const handleScroll = () => {
      const scrollPos = window.scrollY;

      if (lastScrollPos.current === -1) {
        lastScrollPos.current = scrollPos;
      }

      if (scrollPos > lastScrollPos.current) {
        scrollDir.current = ScrollDir.DOWN;
      } else if (scrollPos < lastScrollPos.current) {
        scrollDir.current = ScrollDir.UP;
      } // Else horizontal scroll

      lastScrollPos.current = scrollPos <= 0 ? 0 : scrollPos; // For mobile or negative scrolling
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleIntersectionChange = (entries: IntersectionObserverEntry[]) => {
      const intersectingEntries = entries.filter(entry => entry.isIntersecting);
      const lastIntersectingEntry =
        scrollDir.current === ScrollDir.DOWN
          ? intersectingEntries[intersectingEntries.length - 1]
          : intersectingEntries[0];

      if (lastIntersectingEntry) {
        const id = lastIntersectingEntry.target.id;
        setActiveItemId(id);

        const parent = subnavRef?.current;
        const element = parent?.querySelector(`a[href='#${id}']`) as HTMLElement;

        if (parent && element && !isElementHorizontallyVisible(element, parent))
          // 64 = left parent padding + 32 margin
          parent?.scroll({ left: element?.offsetLeft - 64, behavior: 'smooth' });
      }
    };

    const observer = new IntersectionObserver(handleIntersectionChange, {
      rootMargin: '-30% 0px -70% 0px',
    });

    items
      ?.map(item => item.anchorId)
      .filter((id): id is string => !!id)
      .map(id => document.getElementById(id))
      .filter((el): el is HTMLElement => !!el)
      .forEach(el => observer.observe(el));

    return () => {
      observer.disconnect();
    };
  }, [items]);

  const handleItemClick = (id?: string) => (event: React.MouseEvent) => {
    event.preventDefault();
    if (!id) return;

    const itemElement = document.getElementById(id);
    if (!itemElement) return;

    const totalHeaderHeight = Number(
      getComputedStyle(document.documentElement)
        .getPropertyValue('--total-header-height')
        .replace('px', '')
    );

    window?.scrollTo({
      behavior: 'smooth',
      top:
        itemElement.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        totalHeaderHeight -
        56, // SubNavigation height
    });
  };

  const { contentfulDescriptionDataset } = getContentfulInspectorProps<SubNavigationType>({
    entryId: sys.id,
    fieldIds: ['contentfulDescription'],
  });

  return (
    <SubNavigationSDS
      backgroundColor={backgroundColor}
      className={SubNavStickyCss}
      horizontalScrollRef={subnavRef}
      dataset={contentfulDescriptionDataset}
    >
      {items?.map(item => {
        const id = item.anchorId;
        const { textDataset } = getContentfulInspectorProps<SubNavigationItemType>({
          entryId: item.sys.id,
          fieldIds: ['text'],
        });

        return (
          <SubNavigationItemSDS
            key={id}
            href={`#${id}`}
            onLinkClick={handleItemClick(id)}
            isActive={id === activeItemId}
            dataset={textDataset}
          >
            {item.text}
          </SubNavigationItemSDS>
        );
      })}
    </SubNavigationSDS>
  );
};
