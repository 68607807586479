import { cx } from '@emotion/css';
import type { FC } from 'react';

import { MotifComponent } from '../../motif/motifConstants';
import { useMotifStyles } from '../../motif/motifReactUtils';
import { getBackgroundClassName } from '../../utils';
import { backgroundImageCss } from '../Page/Page.styled';
import {
  childPanelCss,
  curtainCss,
  panelColorsCss,
  showCurtainTextColorCss,
  splitPanelContainerCss,
  swapPanelsCss,
} from './styles';
import type { BlockSplitPanelProps } from './types';

export const BlockSplitPanel: FC<BlockSplitPanelProps> = ({
  leftBackgroundProps,
  rightBackgroundProps,
  contentLeft,
  contentRight,
  swapPanels,
  ...props
}) => {
  useMotifStyles(MotifComponent.BLOCK_SPLIT_PANEL);
  const leftPanelClasses = cx(
    panelColorsCss,
    childPanelCss,
    {
      [showCurtainTextColorCss]: !!(
        leftBackgroundProps?.showCurtain && leftBackgroundProps?.backgroundImageUrl
      ),
      [curtainCss]: !!(leftBackgroundProps?.showCurtain && leftBackgroundProps?.backgroundImageUrl),
      [swapPanelsCss]: swapPanels,
    },
    leftBackgroundProps?.backgroundImageUrl &&
      backgroundImageCss(leftBackgroundProps.backgroundImageUrl),
    getBackgroundClassName(leftBackgroundProps?.backgroundColor)
  );

  const rightPanelClasses = cx(
    panelColorsCss,
    childPanelCss,
    {
      [showCurtainTextColorCss]: !!(
        rightBackgroundProps?.showCurtain && rightBackgroundProps?.backgroundImageUrl
      ),
      [curtainCss]: !!(
        rightBackgroundProps?.showCurtain && rightBackgroundProps?.backgroundImageUrl
      ),
    },
    rightBackgroundProps?.backgroundImageUrl &&
      backgroundImageCss(rightBackgroundProps.backgroundImageUrl),
    getBackgroundClassName(rightBackgroundProps?.backgroundColor)
  );

  return (
    <section {...props} className={cx(MotifComponent.BLOCK_SPLIT_PANEL, splitPanelContainerCss)}>
      <article className={leftPanelClasses}>{contentLeft}</article>
      <article className={rightPanelClasses}>{contentRight}</article>
    </section>
  );
};

BlockSplitPanel.displayName = 'BlockSplitPanel';
