import { css } from '@emotion/css';

import { Spacing } from '../../constants';

export const authorBylineContainerCss = css`
  line-height: ${Spacing.XL}px;
  margin: ${Spacing.XS}px 0;
`;

export const authorBylineAuthorCss = css`
  display: block;
`;
