import { Plain, Yellow } from '../../constants/colors';
import type { MotifComponent, MotifComponentProps } from '../../motif';
import { m } from '../../motif/m';

export const buttonFontVars = {
  '--button-desktop-font-size': m('--action-desktop-font-size'),
  '--button-desktop-font-letter-spacing': m('--action-desktop-font-letter-spacing'),
  '--button-desktop-font-line-height': m('--action-desktop-font-line-height'),
  '--button-desktop-font-weight': m('--action-desktop-font-weight'),
  '--button-mobile-font-size': m('--action-mobile-font-size'),
  '--button-mobile-font-letter-spacing': m('--action-mobile-font-letter-spacing'),
  '--button-mobile-font-line-height': m('--action-mobile-font-line-height'),
  '--button-mobile-font-weight': m('--action-mobile-font-weight'),
} as const;

export const buttonStandardVars = {
  // = `calc(${m('--spacing-s')} - 1px) calc(${m('--spacing-xl')} - 1px)`. Concrete for Figma tokens
  '--button-regular-padding': '11px 31px',
  // = `calc(${m('--spacing-xs')} - 1px) calc(${m('--spacing-m')} - 1px)`. Concrete for Figma tokens
  '--button-compact-padding': '7px 15px',
  '--button-border-width': '1px',
  '--button-border-radius': '64px',
  '--button-hover-shadow': m('--box-shadow-xl'),
  '--button-active-shadow': m('--box-shadow-m'),
};

export const contrastBlackButtonVars: MotifComponentProps<MotifComponent.BUTTON> = {
  '--button-primary-bg-color': Yellow.V100,
  '--button-primary-hover-bg-color': Yellow.V100,
  '--button-primary-border-color': Yellow.V100,
  '--button-primary-hover-border-color': Yellow.V100,
  '--button-primary-fg-color': Plain.BLACK,
  '--button-secondary-bg-color': Plain.WHITE,
  '--button-secondary-hover-bg-color': Plain.WHITE,
  '--button-secondary-border-color': Plain.WHITE,
  '--button-secondary-hover-border-color': Plain.WHITE,
  '--button-secondary-fg-color': Plain.BLACK,
  '--button-flat-fg-color': Plain.WHITE,
  ...buttonFontVars,
  ...buttonStandardVars,
} as const;

export const contrastYellowButtonVars: MotifComponentProps<MotifComponent.BUTTON> = {
  '--button-primary-bg-color': Plain.BLACK,
  '--button-primary-hover-bg-color': Plain.BLACK,
  '--button-primary-border-color': Plain.BLACK,
  '--button-primary-hover-border-color': Plain.BLACK,
  '--button-primary-fg-color': Plain.WHITE,
  '--button-secondary-bg-color': Plain.WHITE,
  '--button-secondary-hover-bg-color': Plain.WHITE,
  '--button-secondary-border-color': Plain.WHITE,
  '--button-secondary-hover-border-color': Plain.WHITE,
  '--button-secondary-fg-color': Plain.BLACK,
  '--button-flat-fg-color': Plain.BLACK,
  ...buttonFontVars,
  ...buttonStandardVars,
} as const;

export const contrastWhiteButtonVars: MotifComponentProps<MotifComponent.BUTTON> = {
  '--button-primary-bg-color': Yellow.V100,
  '--button-primary-hover-bg-color': Yellow.V100,
  '--button-primary-border-color': Yellow.V100,
  '--button-primary-hover-border-color': Yellow.V100,
  '--button-primary-fg-color': Plain.BLACK,
  '--button-secondary-bg-color': Plain.BLACK,
  '--button-secondary-hover-bg-color': Plain.BLACK,
  '--button-secondary-border-color': Plain.BLACK,
  '--button-secondary-hover-border-color': Plain.BLACK,
  '--button-secondary-fg-color': Plain.WHITE,
  '--button-flat-fg-color': Plain.BLACK,
  ...buttonFontVars,
  ...buttonStandardVars,
} as const;

export const contrastGrayButtonVars: MotifComponentProps<MotifComponent.BUTTON> = {
  '--button-primary-bg-color': Yellow.V100,
  '--button-primary-hover-bg-color': Yellow.V100,
  '--button-primary-border-color': Yellow.V100,
  '--button-primary-hover-border-color': Yellow.V100,
  '--button-primary-fg-color': Plain.BLACK,
  '--button-secondary-bg-color': Plain.WHITE,
  '--button-secondary-hover-bg-color': Plain.WHITE,
  '--button-secondary-border-color': Plain.WHITE,
  '--button-secondary-hover-border-color': Plain.WHITE,
  '--button-secondary-fg-color': Plain.BLACK,
  '--button-flat-fg-color': Plain.BLACK,
  ...buttonFontVars,
  ...buttonStandardVars,
} as const;
