import type { ValueOf } from '../../types';

export const Template = {
  Straight: 'Straight',
  Head: 'Head',
  HeadFlipped: 'HeadFlipped',
  Skirt: 'Skirt',
  Skirt2: 'Skirt2',
} as const;

export type Template = ValueOf<typeof Template>;

export enum BreakOverlayType {
  BOTTOM = 'bottom',
  BOTTOM_HALF = 'bottomHalf',
  NONE = 'none',
  TOP = 'top',
  TOP_HALF = 'topHalf',
  TOP_HALF_AND_BOTTOM_HALF = 'topAndBottomHalfs',
}
