import type { DownloadData } from './persistDownloadId';

export const makePostSubmitUrlWithDownloadId = ({
  downloadId,
  version,
  platform,
}: DownloadData): string => {
  const url = new URL(window.location.href);
  url.searchParams.set('downloadId', downloadId);
  url.searchParams.set('version', version);
  url.searchParams.set('platform', platform);
  return url.href;
};
