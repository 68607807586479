import { gql } from '@apollo/client';

import type { Items } from '../../types/Items';
import type { SlugShallowData } from './slugQuery';
import { slugShallowFragment } from './slugQuery';

export const slugCollectionQuery = gql`
  query SlugCollectionQuery($preview: Boolean!, $locale: String!, $slug: String!) {
    slugCollection(preview: $preview, locale: $locale, where: { slug: $slug }, limit: 1) {
      total
      items {
        ...Slug
      }
    }
  }
  ${slugShallowFragment}
`;

export interface SlugCollectionData {
  slugCollection: Items<SlugShallowData>;
}
