import { gql } from '@apollo/client';

import { contentfulSysIdFragment } from '../../../utils/contentful/contentfulQuery';
import { chartDataFragment } from '../query';

export const fragments = {
  all: gql`
    fragment TableVisualizationAll on TableVisualization {
      ...ContentfulSysId
      chartTitle
      chartData {
        ...ChartDataAll
      }
      tableColumns
      enableSearch
      initialRowCount
    }
    ${contentfulSysIdFragment}
    ${chartDataFragment}
  `,
  withTitle: gql`
    fragment TableVisualizationWithTitle on TableVisualization {
      ...ContentfulSysId
      chartTitle
    }
    ${contentfulSysIdFragment}
  `,
};

export const queries = {
  shallow: gql`
    query tableVisualizationEntryQuery($preview: Boolean!, $locale: String!, $id: String!) {
      tableVisualization(preview: $preview, locale: $locale, id: $id) {
        ...ContentfulSysId
      }
    }
    ${contentfulSysIdFragment}
  `,
  all: gql`
    query tableVisualizationEntryQuery($preview: Boolean!, $locale: String!, $id: String!) {
      tableVisualization(preview: $preview, locale: $locale, id: $id) {
        ...TableVisualizationAll
      }
    }
    ${fragments.all}
  `,
};
