import { cx } from '@emotion/css';
import { type FC, useEffect, useRef, useState } from 'react';

import { Alignment } from '../../../constants';
import { Media } from '../../Media';
import { AnimatedAccordionControls } from './AnimatedAccordionControls';
import {
  accordionItemAlignCenterCss,
  accordionItemAlignEndCss,
  accordionItemAlignStartCss,
  accordionItemContainerCss,
  contentBodyCss,
  contentContainerCss,
  contentTitleCss,
  fadeInAnimationCss,
  hiddenAccordionItemCss,
  imageCss,
  mediaContainerCss,
  minHeightVariable,
} from './styles';
import type { AnimatedAccordionMobileProps } from './types';

export const AnimatedAccordionMobile: FC<AnimatedAccordionMobileProps> = props => {
  const { items, textAlignment = 'Start' } = props;
  const allowAnimation = items.length > 1;
  const itemsRefs = useRef<HTMLDivElement[]>([]);

  const [activeItem, setActiveItem] = useState<number>(-1);
  const [isPaused, setIsPaused] = useState<boolean>(true);
  const [touchStartX, setTouchStartX] = useState<number | null>(0);
  const [isSwipping, setIsSwipping] = useState<boolean>(false);
  const [swipeOffset, setSwipeOffset] = useState<number>(0);
  const [maxHeight, setMaxHeight] = useState<number>(0);
  const isMaxHeightSet = maxHeight > 0;

  // Animation handling
  const handleOnAnimationComplete = () => {
    setActiveItem(prevActiveItem => {
      if (prevActiveItem >= items.length - 1) {
        return 0;
      }

      return prevActiveItem + 1;
    });
  };

  useEffect(() => {
    if (allowAnimation) {
      setIsPaused(false);
    }

    setActiveItem(0);
  }, [allowAnimation]);

  // Item swipe handling; users can move back and forward through accordion items
  const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    setTouchStartX(event.touches[0]?.clientX as number);
    setIsSwipping(true);
  };

  const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
    if (touchStartX === null || !isSwipping) return;

    const touchEndx = event.touches[0]?.clientX as number;
    const deltaX = touchEndx - touchStartX;

    setSwipeOffset(deltaX);
  };

  const handleTouchEnd = () => {
    if (touchStartX === null) return;

    const threshold = 50;

    if (Math.abs(swipeOffset) > threshold) {
      if (swipeOffset > 0) {
        previousAccordionItem();
      } else {
        nextAccordionItem();
      }
    }

    setIsSwipping(false);
    setSwipeOffset(0);
    setTouchStartX(null);
  };

  const nextAccordionItem = () => {
    setActiveItem(prevActiveItem => (prevActiveItem + 1) % items.length);
  };

  const previousAccordionItem = () => {
    setActiveItem(prevActiveItem => (prevActiveItem === 0 ? items.length - 1 : prevActiveItem - 1));
  };

  // Item's height handling; all accordion items must have the same height to prevent layout shifts due to content length differences
  useEffect(() => {
    if (!isMaxHeightSet && allowAnimation) {
      const heights = itemsRefs.current.map(item => item.clientHeight);
      setMaxHeight(Math.max(...heights));
    }
  }, [allowAnimation, isMaxHeightSet, maxHeight]);

  // Set the tallest item height as a CSS variable to allow for a dynamic height property
  const maxHeightVariableStyles = {
    [minHeightVariable]: `${maxHeight}px`,
  } as React.CSSProperties;

  return (
    <div
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      style={maxHeightVariableStyles}
    >
      {items?.map((item, itemIndex) => (
        <div
          key={itemIndex}
          className={cx(accordionItemContainerCss, {
            [accordionItemAlignStartCss]: textAlignment === Alignment.Start,
            [accordionItemAlignCenterCss]: textAlignment === Alignment.Center,
            [accordionItemAlignEndCss]: textAlignment === Alignment.End,
            [hiddenAccordionItemCss]: allowAnimation && itemIndex !== activeItem && isMaxHeightSet,
          })}
          ref={element => {
            itemsRefs.current[itemIndex] = element as HTMLDivElement;
          }}
          // Allow play/pause animation by clicking anywhere besides the play button
          onClick={() => setIsPaused(!isPaused)}
        >
          <div className={cx(mediaContainerCss, fadeInAnimationCss)}>
            <Media className={imageCss} imgSrcs={item.imgSrcs} altText={item.imageAltText} />
          </div>
          {allowAnimation && (
            <AnimatedAccordionControls
              currentItemIndex={itemIndex}
              isActive={itemIndex === activeItem && swipeOffset === 0}
              isPaused={isPaused}
              itemsCount={items.length}
              onPause={() => setIsPaused(!isPaused)}
              onAnimationComplete={handleOnAnimationComplete}
            />
          )}
          <div className={cx(contentContainerCss, fadeInAnimationCss)}>
            <h6 className={contentTitleCss}>{item.title}</h6>
            <section className={contentBodyCss}>{item.body}</section>
          </div>
        </div>
      ))}
    </div>
  );
};
