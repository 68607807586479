/* eslint-disable no-console */
import noop from 'lodash-es/noop';
import { useCallback, useEffect, useState } from 'react';

/**
 * Enum for different states for a toggle attribute.
 *
 * Useful for state management like collapse/expand toggles where the transition state matters.
 */
export enum ToggleState {
  OFF = 'off',
  ON = 'on',
  TURNING_OFF = 'turning-off',
  TURNING_ON = 'turning-on',
}

/** Enum for target state that may be passed into the 'toggle' function. */
export enum ToggleTarget {
  OFF = 'off',
  ON = 'on',
  TOGGLE = 'toggle',
}

export interface UseToggleStateInit {
  initialValue?: ToggleState;

  /** Callback for when the state changes. */
  onToggle?: (newState: ToggleState, previousState: ToggleState) => void;

  /** Delay from TURNING_ON -> ON and TURNING_OFF -> OFF */
  transitionDurationMs?: number;
}

const defaultUseToggleStateInit: Required<UseToggleStateInit> = {
  initialValue: ToggleState.OFF,
  onToggle: noop,
  transitionDurationMs: 250,
};

export interface UseToggleStateExport {
  state: ToggleState;
  toggle: (target?: ToggleTarget) => void;
}

/**
 * Hook for being able to set a value to ON/OFF and get notified while transitions happen.
 *
 * Sample usage:
 *
 * ```typescript
 * const { state: myState, toggle: toggleMyState } = useToggleState({
 *   initialValue: myIntialBooleanValue ? ToggleState.ON : ToggleState.OFF,
 *   onToggle: (newState, _oldState) => {
 *     switch (newState) {
 *       case ToggleState.ON:
 *         break;
 *       case ToggleState.TURNING_ON:
 *         break;
 *       case ToggleState.OFF:
 *         break;
 *       case ToggleState.TURNING_OFF:
 *         break;
 *       default:
 *         break;
 *     }
 *   },
 * });
 * ```
 */
export const useToggleState = (init: UseToggleStateInit): UseToggleStateExport => {
  const { initialValue, onToggle, transitionDurationMs } = {
    ...defaultUseToggleStateInit,
    ...init,
  };

  const [state, setState] = useState<ToggleState>(initialValue);

  /** Helper function for updating internal state and notifying listeners that state updated. */
  const setStateAndNotify = useCallback(
    (newState: ToggleState, oldState: ToggleState) => {
      if (newState === oldState) {
        return;
      }

      setState(newState);
      onToggle(newState, oldState);
    },
    [onToggle, setState]
  );

  /** Effect that schedules changes from TURNING_OFF -> OFF and TURNING_ON -> ON. */
  useEffect(() => {
    let transitionTimeout: ReturnType<typeof setTimeout>;

    if (state === ToggleState.TURNING_OFF) {
      transitionTimeout = setTimeout(
        setStateAndNotify.bind(this, ToggleState.OFF, ToggleState.TURNING_OFF),
        transitionDurationMs
      );
    }

    if (state === ToggleState.TURNING_ON) {
      transitionTimeout = setTimeout(
        setStateAndNotify.bind(this, ToggleState.ON, ToggleState.TURNING_ON),
        transitionDurationMs
      );
    }

    return () => {
      transitionTimeout && clearTimeout(transitionTimeout);
    };
  }, [state, transitionDurationMs, setStateAndNotify]);

  const turnOn = () => {
    if (state !== ToggleState.OFF) {
      return;
    }

    setStateAndNotify(ToggleState.TURNING_ON, state);
  };

  const turnOff = () => {
    if (state !== ToggleState.ON) {
      return;
    }

    setStateAndNotify(ToggleState.TURNING_OFF, state);
  };

  const toggle = (target = ToggleTarget.TOGGLE) => {
    if (target === ToggleTarget.ON) {
      turnOn();
    } else if (target === ToggleTarget.OFF) {
      turnOff();
    } else {
      const isOff = state === ToggleState.TURNING_OFF || state === ToggleState.OFF;
      isOff ? turnOn() : turnOff();
    }
  };

  return { state, toggle };
};
