import { gql } from '@apollo/client';

import type { ContentfulTypedSysProps } from '../../types/contentful';
import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';

// =================================================================================================
// Organization Schema Fragment
// =================================================================================================

export const organizationSchemaFragment = gql`
  fragment OrganizationSchemaAll on OrganizationSchema {
    ...ContentfulSysId
    name
    description
    url
    logo {
      url
    }
    sameAs
  }
  ${contentfulSysIdFragment}
`;

export const organizationSchemaCollectionQuery = gql`
  query OrganizationSchemaCollectionQuery($preview: Boolean!, $locale: String!) {
    organizationSchemaCollection(preview: $preview, locale: $locale, limit: 1) {
      items {
        ...OrganizationSchemaAll
      }
    }
  }
  ${organizationSchemaFragment}
`;

export interface OrganizationSchemaData extends ContentfulTypedSysProps<'OrganizationSchema'> {
  name: string;
  description?: string;
  url?: string;
  logo?: { url?: string };
  sameAs?: string[];
}

export interface OrganizationSchemaCollectionData
  extends ContentfulTypedSysProps<'OrganizationSchemaCollection'> {
  organizationSchemaCollection: {
    items: OrganizationSchemaData[];
  };
}
