import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import { Config } from '../../config';
import { createContentfulClient } from './ContentfulProvider';

/**
 * This is the interval at which we reset apollo clients.
 *
 * On the server this happens with no state to restore, so the cache gets wiped allowing us to
 * garbage collect previously stored quries.
 *
 * Mote that this delay is how long it can take to see changes in prod.
 */
const cacheExpirationTimeMs = 30_000;

/**
 * Map from locale to apollo cache. We need to have a separate map per locale.
 *
 * The Apollo Client Team recommends creating a new client per SSR request:
 * https://github.com/apollographql/apollo-client/issues/7942#issuecomment-812540838 We won't go
 * that far yet, but we will recycle the Apollo client and cache periodically to address the memory
 * leak issues.
 *
 * The map values include both active client and factory method used for replacing the client.
 */
const apolloClientMap = new Map<
  string,
  [ApolloClient<NormalizedCacheObject>, () => ApolloClient<NormalizedCacheObject>]
>();

// We don't want the cache to persist forever, so we clear the cache every now and then by replacing the Apollo Client.
// Currently set to every 30 seconds on the server-side.
if (!Config.isClient && !Config.isTest) {
  setInterval(() => {
    for (const [locale, [_, clientFactory]] of Array.from(apolloClientMap.entries())) {
      const newClient = clientFactory();
      apolloClientMap.set(locale, [newClient, clientFactory]);
    }
  }, cacheExpirationTimeMs);
}

/** Returns a new apollo client for options or returns one from cache. */
export const getOrCreateApolloClient = (
  locale: string,
  options: Parameters<typeof createContentfulClient>
): ApolloClient<NormalizedCacheObject> => {
  // No caches on dev/staging servers.
  if (Config.isPreview) {
    return createContentfulClient(...options);
  }

  const spaceId = options[0].spaceId;
  const apolloClientMapKey = `${spaceId}/${locale}`;

  // If previous version exists, return it.
  if (apolloClientMap.has(apolloClientMapKey)) {
    const [client] = apolloClientMap.get(apolloClientMapKey)!;
    return client;
  }

  // Else populate the cache with a new client and a factory to reset it.
  const client = createContentfulClient(...options);
  apolloClientMap.set(apolloClientMapKey, [client, () => createContentfulClient(...options)]);
  return client;
};
