/* eslint-disable max-len */
import type { FC } from 'react';

import type { GenericSvgIconProps } from '../CustomIcon';

export const FollowIcon: FC<GenericSvgIconProps> = props => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.73 0.59C10.36 0.58 8.04 1.30 6.06 2.65C4.09 3.99
        2.55 5.91 1.64 8.15C0.73 10.39 0.49 12.85 0.95 15.23C1.41
        17.61 2.56 19.80 4.23 21.51C5.91 23.23 8.05 24.40 10.37
        24.87C12.70 25.34 15.11 25.10 17.30 24.17C19.50 23.25 21.37
        21.68 22.69 19.66C24.01 17.64 24.71 15.27 24.71 12.85C24.71
        9.60 23.45 6.48 21.20 4.18C18.96 1.88 15.91 0.59 12.73
        0.59ZM6.46 16.68C5.72 16.68 4.99 16.46 4.38 16.04C3.76
        15.61 3.28 15.01 2.99 14.31C2.71 13.61 2.64 12.84 2.78 12.10C2.93 11.35 3.29 10.67 3.81 10.13C4.34
        9.60 5.00 9.23 5.73 9.08C6.46 8.93 7.22 9.01 7.90 9.30C8.59 9.59 9.17 10.08 9.59 10.71C10.00 11.35
        10.22 12.09 10.22 12.85C10.22 13.35 10.12 13.85 9.93 14.32C9.74 14.78 9.47 15.21 9.12 15.56C8.77
        15.92 8.35 16.20 7.90 16.39C7.44 16.59 6.95 16.68 6.46 16.68ZM19.44 16.66L18.16 15.36L19.72
        13.77H12.86V11.92H19.67L18.16 10.39L19.43 9.09L23.14 12.87L19.44 16.66Z"
      />
    </svg>
  );
};
