import { cx } from '@emotion/css';
import type { FC, ReactNode } from 'react';

import { MotifComponent, useMotifStyles } from '../../motif';
import { authorCss, bigQuoteCss, endQuoteCss, quoteCss, startQuoteCss } from './Quote.styled';
interface Props {
  author?: ReactNode;
  children?: ReactNode;
}

export const Quote: FC<Props> = ({ author, children }) => {
  useMotifStyles(MotifComponent.QUOTE);

  return (
    <div className={cx(MotifComponent.QUOTE, quoteCss)}>
      <aside className={cx(startQuoteCss, bigQuoteCss)}>“</aside>
      {children}
      <aside className={cx(endQuoteCss, bigQuoteCss)}>”</aside>
      {author && <div className={authorCss}>— {author}</div>}
    </div>
  );
};

Quote.displayName = 'Quote';
