import { cx } from '@emotion/css';
import type { FC, PropsWithChildren } from 'react';

import { h6Css } from '../../motif';
import { dataSetToAttributes } from '../../utils';
import type { FormFieldComponentProps } from './Form.types';
import { errorMessageCss, inputContainerCss, inputHelpTextCss, inputLabelCss } from './styles';

export type FieldProps = Pick<
  FormFieldComponentProps,
  | 'dir' // Unused.
  | 'error'
  | 'helpText'
  | 'label'
  | 'name'
  | 'dataset'
  | 'helpTextDataset'
  | 'errorDataset'
  | 'labelDataset'
> & {
  type:
    | 'Text'
    | 'Number'
    | 'Date'
    | 'Textarea'
    | 'Checkbox'
    | 'Hidden'
    | 'Select'
    | 'MultiSelect'
    | 'Radio';
  hasError?: boolean;
};

export const Field: FC<PropsWithChildren<FieldProps>> = ({
  type,
  name,
  label,
  helpText,
  error,
  hasError,
  children,
  dataset,
  labelDataset,
  helpTextDataset,
  errorDataset,
}) => {
  if (type === 'Hidden') {
    return <>{children}</>;
  }

  return (
    <div className={cx('sdsm-form-field', cx(inputContainerCss))} {...dataSetToAttributes(dataset)}>
      {label && type !== 'Checkbox' && (
        <label
          htmlFor={name}
          className={cx(h6Css, inputLabelCss)}
          {...dataSetToAttributes(labelDataset)}
        >
          {label}
        </label>
      )}
      {children}
      {((helpText && !hasError) || (helpText && hasError && !error)) && (
        <p className={inputHelpTextCss} {...dataSetToAttributes(helpTextDataset)}>
          {helpText}
        </p>
      )}
      {hasError && error && (
        <div className={errorMessageCss} {...dataSetToAttributes(errorDataset)}>
          {error}
        </div>
      )}
    </div>
  );
};
