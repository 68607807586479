import type { FC } from 'react';
import { useEffect, useRef } from 'react';

import { getTracer } from '../../utils/tracing';

/**
 * Component for automation testing to track outgoing requests. Since its using the tracer, it also
 * includes anything that is being actively traced, but a majority of it is outgoing requests.
 */
export const ActiveEventCountTracker: FC = () => {
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    return getTracer().addEventListener(
      (_eventId, _eventName, _lifeCycle, activeEventCount: number) => {
        divRef.current && (divRef.current.dataset.events = `${activeEventCount}`);
      }
    );
  }, []);

  return <div data-testid="mwp-active-traced-events" ref={divRef} data-events="0" hidden />;
};
