import clone from 'lodash-es/clone';
import mapValues from 'lodash-es/mapValues';

/**
 * Converts null values in an object into undefined values. Goes deep. Clones all.
 *
 * This is for the use in contentful client because apollo doesn't support this as a feature. See:
 * https://github.com/apollographql/apollo-client/issues/2412
 */
export function convertNullsToUndefined<T>(object: T): T {
  if (object === null || object === undefined) return undefined as unknown as T;
  if (typeof object !== 'object') return object;
  const cloned = clone(object as T);
  const mapped = Array.isArray(object)
    ? (object.map(convertNullsToUndefined) as unknown as T)
    : (mapValues(cloned as unknown as object, convertNullsToUndefined) as unknown as T);

  return Object.freeze(mapped);
}
