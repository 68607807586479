import { cx } from '@emotion/css';
import { Alignment, Heading } from '@snapchat/snap-design-system-marketing';
import type { FC, ReactNode } from 'react';
import React from 'react';

import {
  alignCenterCss,
  alignRightCss,
  headerCss,
  headingCss,
  textAlignCenterCss,
  textAlignRightCss,
} from './ArDownloadFormBlock.styles';
import type { SubtitleStyle, TitleStyle } from './types';

const htmlHeadingElementMap: Record<keyof typeof Heading, string> = {
  H100: 'h1',
  H200: 'h2',
  H300: 'h3',
  H400: 'h4',
  H500: 'h5',
  H600: 'h6',
  H700: 'h7',
};

interface ArDownloadFormHeaderProps {
  title?: ReactNode;
  subtitle: ReactNode;
  alignment?: Alignment;
  titleStyle?: TitleStyle;
  subtitleStyle?: SubtitleStyle;
}

export const ArDownloadFormHeader: FC<ArDownloadFormHeaderProps> = ({
  title,
  subtitle,
  titleStyle = 'H300',
  subtitleStyle = 'H400',
  alignment,
}) => {
  const titleCss = Heading[titleStyle];
  const subtitleCss = Heading[subtitleStyle];

  const textAlignStyles = {
    [textAlignRightCss]: alignment === Alignment.Right,
    [textAlignCenterCss]: alignment === Alignment.Center,
  };

  return (
    <header
      className={cx(headerCss, 'ar-dowload-form-header', {
        [alignRightCss]: alignment === Alignment.Right,
        [alignCenterCss]: alignment === Alignment.Center,
      })}
    >
      {title &&
        React.createElement(
          htmlHeadingElementMap[titleStyle],
          {
            className: cx(titleCss, headingCss, textAlignStyles),
          },
          title
        )}
      {subtitle &&
        React.createElement(
          htmlHeadingElementMap[subtitleStyle],
          {
            className: cx(subtitleCss, headingCss, textAlignStyles),
          },
          subtitle
        )}
    </header>
  );
};
