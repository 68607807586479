import { css } from '@emotion/css';
import { Black, Gray, Spacing, White } from '@snapchat/snap-design-system-marketing';

import { mediaQuery } from '../../styles/mediaQueries';

export const disabledButtonCss = css`
  color: ${Gray.V300};
  border: 1px solid ${Gray.V300};
  background-color: ${Gray.V200};
`;

export const addToCartButtonCss = css`
  position: relative;
  margin-top: ${Spacing.MD}px;
`;

export const addToCartTooltipCss = css`
  background: ${Black.V200};
  border-radius: 6px;
  box-shadow: 0px 6px 12px 4px rgba(0, 0, 0, 0.1);
  color: ${White};
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  bottom: 125%;
  left: 0;
  padding: 6px 7px;
  text-align: center;
  width: 100%;

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${Black.V200} transparent transparent transparent;
  }

  ${mediaQuery.over768_desktop_small} {
    padding: 6px ${Spacing.XS}px;
  }
`;
