import { css } from '@emotion/css';

import { mobileMediaQuery, nonMobileMediaQuery } from '../../constants';
import { m } from '../../motif/m';

export const bigQuoteCss = css`
  font-size: 40px;
  line-height: 40px;
  margin-bottom: -20px;
  margin-top: -8px;
`;

export const startQuoteCss = css`
  margin-inline-start: -16px;
  text-align: start;
`;

export const endQuoteCss = css`
  margin-inline-end: -20px;
  text-align: end;
`;

export const quoteCss = css`
  padding: ${m('--spacing-xl')};
  border-radius: ${m('--border-radius-l')};

  background-color: ${m('--quote-bg-color')};
  color: ${m('--quote-fg-color')};
`;

export const authorCss = css`
  text-align: end;
  margin-top: ${m('--spacing-s')};

  ${nonMobileMediaQuery} {
    font-size: ${m('--quote-author-desktop-font-size')};
    font-weight: ${m('--quote-author-desktop-font-weight')};
  }

  ${mobileMediaQuery} {
    font-size: ${m('--quote-author-mobile-font-size')};
    font-weight: ${m('--quote-author-mobile-font-weight')};
  }
`;
