import { cx } from '@emotion/css';
import type { FC } from 'react';

import { MotifComponent, useMotifStyles } from '../../motif';
import type { IconName } from '../Icon';
import { Icon } from '../Icon';
import { Tooltip } from '../Tooltip';
import {
  activeToggleCss,
  activeToggleWithTextCss,
  baseToggleButtonCss,
  svgActiveCss,
  svgCss,
  svgTextCss,
  toggleButtonTextCss,
  toggleButtonWithTextCss,
  tooltipCss,
} from './styles';

export interface ToggleButtonProps {
  iconSize?: number;
  icon: IconName;
  onClick: (index: number) => void;
  selected: boolean;
  tooltip?: string;
  text?: string;
  id: string;
  index: number;
}

export const ToggleButton: FC<ToggleButtonProps> = ({
  text,
  icon,
  iconSize = 25,
  selected,
  tooltip,
  id,
  index,
  onClick,
}: ToggleButtonProps) => {
  const toggleButtonClass = text ? toggleButtonWithTextCss : baseToggleButtonCss;
  const activeToggleClass = text ? activeToggleWithTextCss : activeToggleCss;
  const svgIconClass = selected ? svgActiveCss : svgCss;
  useMotifStyles(MotifComponent.TOGGLE_BUTTON);
  const handleClick = () => onClick(index);

  const buttonContent = (
    <div
      id={id}
      className={cx(MotifComponent.TOGGLE_BUTTON, toggleButtonClass, {
        [activeToggleClass]: selected,
      })}
      onClick={handleClick}
    >
      <Icon name={icon} size={iconSize} className={text ? svgTextCss : svgIconClass} />
      <p className={toggleButtonTextCss}>{text}</p>
    </div>
  );

  if (tooltip) {
    return (
      <Tooltip
        content={<div className={tooltipCss}>{tooltip}</div>}
        trigger="onHover"
        uniqueId={id}
      >
        {buttonContent}
      </Tooltip>
    );
  }

  return buttonContent;
};

ToggleButton.displayName = 'ToggleButton';
