import { cx } from '@emotion/css';
import type { ForwardedRef } from 'react';
import { forwardRef, useCallback, useContext } from 'react';

import { m, MotifComponent, useMotifStyles } from '../../motif';
import type { BaseInteractiveComponentProps } from '../../types';
import type { OnActivateHandler } from '../../types/activationEvents';
import type { IconName } from '../Icon/Icon';
import { Icon } from '../Icon/Icon';
import { PrimitivesContext } from '../Primitives';
import { containerCss } from './IconButton.style';
import { IconButtonSize } from './IconButton.types';

interface IconButtonProps
  extends BaseInteractiveComponentProps<HTMLButtonElement | HTMLAnchorElement> {
  iconName: IconName;
  url?: string;
  onClick?: OnActivateHandler;
  iconClassName?: string;
  disabled?: boolean;
  size?: IconButtonSize;
}

/** Componen that shows a round button with an icon inside. */
export const IconButton = forwardRef<HTMLButtonElement | HTMLAnchorElement, IconButtonProps>(
  (props, ref) => {
    const {
      url,
      iconName,
      className,
      iconClassName,
      onClick,
      disabled,
      size = IconButtonSize.MEDIUM,
      ...passThrough
    } = props;

    useMotifStyles(MotifComponent.ICON_BUTTON);
    const { Anchor } = useContext(PrimitivesContext);

    const containerClassName = cx(
      MotifComponent.ICON_BUTTON,
      containerCss,
      size,
      { disabled },
      className
    );
    const icon = (
      <Icon
        name={iconName}
        size={24}
        fill={m('--icon-color')}
        className={cx('icon', iconClassName)}
      />
    );

    const onActivate = useCallback(() => {
      if (disabled) return;
      onClick?.();
    }, [disabled, onClick]);

    return url ? (
      <Anchor
        href={url}
        ref={ref as ForwardedRef<HTMLAnchorElement>}
        className={containerClassName}
        onClick={onActivate}
        {...passThrough}
      >
        {icon}
      </Anchor>
    ) : (
      <button
        ref={ref as ForwardedRef<HTMLButtonElement>}
        disabled={disabled}
        className={containerClassName}
        onClick={onActivate}
        {...passThrough}
      >
        {icon}
      </button>
    );
  }
);
