import { Black, Gray, White } from '../../constants';
import type { MotifComponent, MotifComponentProps } from '../../motif';

export const toggleButtonBlackVars: MotifComponentProps<MotifComponent.TOGGLE_BUTTON> = {
  '--toggle-button-active-color': Black.V100,
  '--toggle-button-color': Gray.V250,
  '--toggle-button-hover-color': Gray.V150,
};

export const toggleButtonYellowVars: MotifComponentProps<MotifComponent.TOGGLE_BUTTON> = {
  '--toggle-button-active-color': Black.V100,
  '--toggle-button-color': Gray.V150,
  '--toggle-button-hover-color': Gray.V250,
};

export const toggleButtonGrayVars: MotifComponentProps<MotifComponent.TOGGLE_BUTTON> = {
  '--toggle-button-active-color': Black.V100,
  '--toggle-button-color': White,
  '--toggle-button-hover-color': Gray.V250,
};
