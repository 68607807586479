import { gql } from '@apollo/client';

import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';

export const fragments = {
  questionnaireAll: gql`
    fragment QuestionnaireBlockAll on QuestionnaireBlock {
      ...ContentfulSysId
      questionnaireTitle: title
      body {
        json
      }
      image {
        url
      }
      questionsCollection(limit: 20) {
        items {
          ...QuestionnaireQuestionAll
        }
      }
      startLabel
      submitLabel
      nextLabel
      backLabel
      skipLabel
      trackingIdLabel
    }
  `,
  questionnaireQuestionAll: gql`
    fragment QuestionnaireQuestionAll on QuestionnaireQuestion {
      ...ContentfulSysId
      questionType
      question
      backgroundImage {
        url
      }
      body {
        json
      }
      answersCollection {
        items {
          ... on QuestionnaireAnswer {
            ...ContentfulSysId
            title
            description {
              json
            }
            image {
              url
            }
          }
        }
      }
      required
    }
    ${contentfulSysIdFragment}
  `,
};

export const query = gql`
  query QuestionnaireEntryQuery($preview: Boolean!, $locale: String!, $id: String!) {
    questionnaireBlock(preview: $preview, locale: $locale, id: $id) {
      ...QuestionnaireBlockAll
    }
  }
  ${fragments.questionnaireAll}
  ${fragments.questionnaireQuestionAll}
`;
