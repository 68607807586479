import type { Event, EventHint } from '@sentry/browser';
import type { EventListener, LoggedEvent } from '@snapchat/logging';
import { LoggingEventType } from '@snapchat/logging';
import {
  BlizzardClientEventListener,
  ConsoleClientEventListener,
  GrapheneClientEventListener,
  SentryClientEventListener,
} from '@snapchat/logging-browser';
import omit from 'lodash-es/omit';

import {
  formatForBusinessEvents,
  formatMarketingWebEvents,
} from '../helpers/logging/blizzard/eventFormats';
import {
  BlizzardEventFormat,
  LoggingVendor,
  SubscribedEventType,
} from '../helpers/logging/eventListenerTypes';
import { GoogleEventListener } from '../helpers/logging/google/GoogleEventListener';
import { logger } from '../helpers/logging/loggingInstance';
import type {
  ExperimentContext,
  LoggingConfig,
  LoggingContext,
  LoggingCustomEvents,
  LoggingPermissions,
} from '../helpers/logging/loggingTypes';
import { EventOrigin } from '../helpers/logging/loggingTypes';
import { getBeforeSend } from '../helpers/logging/sentry';
import { ClientExportEventListener } from './ClientExportEventListener';

/** Configuration parameter for SentryClientEventListener class constructor */
type SentryProps = ConstructorParameters<
  typeof SentryClientEventListener<LoggingContext, LoggingCustomEvents, LoggingPermissions>
>[0];

/** For logger where flush interval is controlled, this is the value we pass in. */
const logFlushIntervalMs = 5e3;

/** Returns the value of a cookie. 1-liner to avoid importing a cookie library. */
function getCookie(cookieName: string): string | undefined {
  return new RegExp(`${cookieName}=([^;]+);`).exec(document.cookie)?.[1];
}

/** Provides the Snap Pixel cookie id if set. */
function getPixelContext(): { pixelId?: string } {
  return {
    pixelId: getCookie('_scid'),
  };
}

/** Captor for the latest values of the experiment impression. */
class ExperimentContextListener
  implements EventListener<LoggingContext, LoggingCustomEvents, LoggingPermissions>
{
  private readonly experimentContext: ExperimentContext = {};

  /** @override */
  public logEvent(
    event: LoggedEvent<LoggingCustomEvents>,
    _context: Partial<LoggingContext>
  ): void {
    if (
      event.type === LoggingEventType.CUSTOM &&
      event.subscribedEventType === SubscribedEventType.EXPERIMENT_IMPRESSION
    ) {
      if (!event.experimentId) {
        this.experimentContext.experimentId = undefined;
        this.experimentContext.variantId = undefined;
      } else {
        this.experimentContext.experimentId = event.experimentId;
        this.experimentContext.variantId = event.variantId;
      }
    }
  }

  public getExperimentContext = (): ExperimentContext => this.experimentContext;

  // TODO: Consider moving these overrides to SyncEventListener..

  /** @override */
  allow = () => Promise.resolve();
  /** @override */
  deny = () => Promise.resolve();
  /** @override */
  flush = () => Promise.resolve();
}

export const initializeClientLogging = (config: LoggingConfig): void => {
  // Do not log inside tests.
  if (config.isTest || !config.isClient) return;

  logger.addContextProvider(() => ({
    region: config.region,
    buildNumber: config.buildNumber,
    eventOrigin: EventOrigin.CLIENT,
  }));

  // Adds the experiment context provider.
  const experimentContextListener = new ExperimentContextListener();
  logger.addEventListeners(experimentContextListener);
  logger.addContextProvider(() => experimentContextListener.getExperimentContext());

  // Adds the snap pixel context provider.
  logger.addContextProvider(getPixelContext);

  // Add web client id if sc-wcid cookie is set.
  logger.addContextProvider(() => ({
    webClientId: getCookie('sc-wcid'),
  }));

  for (const eventListenerConfig of config.trackingSettings.eventListeners) {
    switch (eventListenerConfig.vendor) {
      case LoggingVendor.GRAPHENE: {
        if (!config.isCompilationModeProd || !config.isDeploymentTypeProd || !config.buildNumber) {
          break;
        }

        logger.addEventListeners(
          new GrapheneClientEventListener<LoggingContext, LoggingCustomEvents, LoggingPermissions>({
            useAsync: true,
            flavor: config.isDeploymentTypeProd ? 'production' : 'development',
            partitionName: eventListenerConfig.partitionName,
            getAdditionalDimensions: context => ({
              // only for csp violations, will not be populated for others.
              // TODO refactor to combine getMetricName and getAdditionalDimensions
              blockedUri: context.blockedUri,
              sourceFile: context.sourceFile,
              violatedDirective: context.violatedDirective,

              // only for contentful errors, may not be populated for others.
              errorCode: context.errorCode,
              errorName: context.errorName,

              // All others
              region: context.region, // Useful for graphene charting
              path: context.path, // Useful for debugging
              locale: context.locale, // Useful for debugging
              uaBrand: context.uaBrand,
              uaPlatform: context.uaPlatform,
            }),
            logTimeInterval: logFlushIntervalMs,
            // These are overrides from the default ones for legacy reasons.
            // TODO: Consolidate with the one initializeServerLogging.
            getMetricName(event, _context) {
              switch (event.type) {
                case LoggingEventType.TIMING: {
                  return 'app_measure_event';
                }

                case LoggingEventType.VALUE: {
                  return 'app_measure_event';
                }

                case LoggingEventType.USER_ACTION: {
                  return 'user_event';
                }

                case LoggingEventType.ERROR: {
                  if (event.component === 'Csp') {
                    return 'csp_error';
                  }

                  if (event.component === 'Contentful') {
                    return 'contentful_error';
                  }
                }
              }

              // Fallback to defaults for other events.
              return null;
            },
            variant: config.site,
          })
        );
        break;
      }

      case LoggingVendor.MWP_HERMES: {
        if (!config.isCompilationModeProd || !config.isDeploymentTypeProd || !config.buildNumber) {
          break;
        }

        logger.addEventListeners(
          new ClientExportEventListener({
            useAsync: true,
            logTimeIntervalMs: logFlushIntervalMs,
            onError: error => console.error(error),
          })
        );
        break;
      }

      case LoggingVendor.GOOGLE_TAG_MANAGER: {
        // TODO: Pass in nonce a a prop.
        const nonce = document.querySelector<HTMLScriptElement>('script[nonce]')?.nonce;
        const gtmEventListener = new GoogleEventListener({
          useAsync: true,
          gtmId: eventListenerConfig.googleTagManagerId,
          nonce,
        });

        logger.addEventListeners(gtmEventListener);

        // GTM needs to be allowed to run by confirming that the client isn't in
        // California. This is because of CCPA restrictions.
        gtmEventListener.checkRegion();
        break;
      }

      case LoggingVendor.BLIZZARD: {
        const formatter =
          eventListenerConfig.eventFormat === BlizzardEventFormat.FOR_BUSINESS
            ? formatForBusinessEvents
            : formatMarketingWebEvents;

        logger.addEventListeners(
          new BlizzardClientEventListener<LoggingContext, LoggingCustomEvents, LoggingPermissions>({
            useAsync: true,
            isProd: config.isDeploymentTypeProd,
            cookieDomain: config.trackingSettings.cookieDomain,
            eventFormatter: formatter,
          })
        );
        break;
      }

      case LoggingVendor.SENTRY: {
        // TODO: Re-enable sentry on staging. Disabled to avoid regression tests
        // using up all of sentry quota.
        if (!config.isDeploymentTypeProd) {
          break;
        }

        // Sentry Listener configuration
        const sentryProps: SentryProps = {
          dsn: eventListenerConfig.dsn,
          tracesSampleRate: 1.0,
          useAsync: true,
          getFlatContext: context => ({
            region: context.region,
          }),
          environment: config.deploymentType,
          release: `${eventListenerConfig.projectName}@${config.buildNumber}`,
          filter(event, _context) {
            // Skip CSP errors because they are very spammy and already logged in Graphene
            if (event.type === LoggingEventType.ERROR && event.component === 'Csp') {
              return false;
            }

            // Else log other events.
            return true;
          },
          // We can adjust grouping rules before sending an event
          beforeSend: getBeforeSend<Event, EventHint>(),
        };

        // Only specify `allowUrls` property if defined in config, otherwise fallback to default
        if (eventListenerConfig.allowUrls) {
          sentryProps.allowUrls = eventListenerConfig.allowUrls;
        }

        logger.addEventListeners(
          new SentryClientEventListener<LoggingContext, LoggingCustomEvents, LoggingPermissions>(
            sentryProps
          )
        );
        break;
      }

      case LoggingVendor.CONSOLE: {
        logger.addEventListeners(
          new ConsoleClientEventListener<LoggingContext, LoggingCustomEvents, LoggingPermissions>({
            filter(event, _context) {
              // Skip Contentful: requestDuration=XXXms because it's very spammy.
              if (
                event.type === LoggingEventType.TIMING &&
                event.component === 'Contentful' &&
                event.variable === 'request_duration' &&
                event.valueMs < 1e3
              ) {
                return false;
              }

              // Else log other events.
              return true;
            },
            customEventFormatter(event, _context) {
              if (event.subscribedEventType === SubscribedEventType.EXPERIMENT_IMPRESSION) {
                if (!event.experimentId || !event.variantId) return null;
                return `Experiment: Impression on ${event.experimentId}.${event.variantId}`;
              }

              // Dump other custom events.
              return [omit(event, 'type')];
            },
          })
        );
        break;
      }
    }
  }
};
