import { Plain } from '../../constants';

export const logoDarkBgVars = {
  '--logo-stroke-color': Plain.WHITE,
  '--logo-fill-color': Plain.BLACK,
};

export const logoLightBgVars = {
  '--logo-stroke-color': Plain.BLACK,
  '--logo-fill-color': Plain.WHITE,
};
