import { cx } from '@emotion/css';
import { type FC, useContext } from 'react';

import { MessageContext } from '../../contexts';
import { MotifComponent, useMotifStyles } from '../../motif';
import { PaginationItem } from './PaginationItem';
import { paginationCss } from './styles';
import type { PaginationProps } from './types';
import { getPaginationItems } from './utils';

/**
 * Component to render the pagination
 *
 * @param className Extra classnames for the pagination wrapper
 * @param totalPages Number of total pages of the pagination
 * @param currentPage Current page of the pagination
 * @param onChange Called whenever there's a valid new page to go to.
 */
export const Pagination: FC<PaginationProps> = ({
  ariaLabel,
  className,
  totalPages,
  currentPage,
  onChange,
}) => {
  useMotifStyles(MotifComponent.PAGINATION);

  const { formatMessage } = useContext(MessageContext);
  const previousPageMessage = formatMessage({
    id: 'pagination-go-to-previous-page',
    defaultMessage: 'Go to previous page',
  });
  const nextPageMessage = formatMessage({
    id: 'pagination-go-to-next-page',
    defaultMessage: 'Go to next page',
  });

  const goToPageMessage = (pageNumber: string) =>
    formatMessage(
      {
        id: 'pagination-go-to-page',
        defaultMessage: 'Go to page {page}',
      },
      { page: pageNumber }
    );

  if (!totalPages || !currentPage || totalPages === 1) return null;

  const handlePageChange = (newPage: number) => () => {
    if (newPage >= 1 && newPage <= totalPages && newPage !== currentPage) {
      onChange(newPage);
    }
  };

  const paginationItems = getPaginationItems(totalPages, currentPage);

  return (
    <nav aria-label={ariaLabel} className={cx(MotifComponent.PAGINATION, className)}>
      <ul className={paginationCss}>
        <PaginationItem
          disabled={currentPage === 1}
          handlePageChange={handlePageChange(currentPage - 1)}
          type="previous"
          ariaLabel={previousPageMessage}
        />
        {paginationItems.map((item, index) => {
          if (Array.isArray(item)) {
            if (!item.length) return null;

            return (
              <li key={index}>
                <span>...</span>
              </li>
            );
          }

          return (
            <PaginationItem
              ariaLabel={item === currentPage ? `${item}` : `${goToPageMessage(String(item))}`}
              handlePageChange={handlePageChange(item)}
              isActive={item === currentPage}
              disabled={item === currentPage}
              key={index}
            >
              {item}
            </PaginationItem>
          );
        })}
        <PaginationItem
          disabled={currentPage === totalPages}
          handlePageChange={handlePageChange(currentPage + 1)}
          type="next"
          ariaLabel={nextPageMessage}
        />
      </ul>
    </nav>
  );
};

Pagination.displayName = 'Pagination';
