import { parseError } from '@snapchat/core';
import { useImperativeEffect } from '@snapchat/core-browser';
import type { FC } from 'react';

/** Our standard location for font files. */
const fontLinkToCheck = 'https://web-platform.snap.com/fonts/font.graphik.css';

/** This location reads the same file, but without CDN, so is slower. */
const fontLinkAlternative =
  'https://snap-design-system.storage.googleapis.com/fonts/font.graphik.css';

/**
 * Returns whether we're able to load a file from the CDN.
 *
 * If this fails, we assume that it's blocked by an adblock (i.e. uBlock Origin).
 */
const canLoadFonts = () =>
  fetch(fontLinkToCheck)
    .then(() => true)
    .catch(error => {
      const message = parseError(error).message;

      if (message === 'Failed to fetch') {
        console.warn(
          `Your browser is blocking "${
            new URL(fontLinkToCheck).hostname
          }" which hosts our fonts. Allow this domain through your content blocker to see this website the way it was meant to be seen.`
        );
      } else {
        console.error('Failed to fetch fonts', error);
      }

      return false;
    });

/**
 * Checks that we are able to load a file from the CDN where we serve our fonts from. If that fails,
 * we try to add a font link to the alternative location (a slower one).
 *
 * TODO: Convert to a hook.
 */
export const FontChecker: FC = () => {
  useImperativeEffect(() => {
    void canLoadFonts().then(canLoad => {
      if (canLoad) return;

      const newLink = document.createElement('link');
      newLink.type = 'text/css';
      newLink.rel = 'stylesheet';
      newLink.href = fontLinkAlternative;

      document.head.appendChild(newLink);
    });
  }, []);

  return null;
};
