import { cx } from '@emotion/css';
import type { FC } from 'react';
import { useMemo, useState } from 'react';

import { MotifComponent, useMotifStyles } from '../../motif';
import { dataSetToAttributes } from '../../utils';
import type { FilterDropdownMenuProps } from '../FilterDropdown/FilterDropdownMenu';
import { allValue, FilterDropdownMenu } from '../FilterDropdown/FilterDropdownMenu';
import { Icon } from '../Icon';
import {
  buttonDefaultStylesResetCss,
  clearFiltersButtonCss,
  clearFiltersWrapperCss,
  desktopWrapperCss,
  filterBarCss,
  filtersIconActiveCss,
  filtersIconButtonCss,
  filtersIconCss,
  mobileWrapperCss,
  searchWrapperCss,
  toggleDropdownsBaseTextCss,
  toggleDropdownsMainTextCss,
  toggleDropdownsSecondaryTextCss,
} from './galleryFiltersV2Styles';

type AbridgedGalleryDropdownMenuProps = Omit<FilterDropdownMenuProps, 'value' | 'onChange'>;

export interface GalleryFiltersV2Props {
  searchMenus: AbridgedGalleryDropdownMenuProps[];
  selectedFilters: {
    [key: string]: string;
  };
  onChangeFilter: (filterKey: string, filterValue?: string) => void;
  onClearFilters: () => void;
  clearButtonLabel?: string;
  mobileFiltersToggleLabel?: string;
  mobileFiltersWrapperTextDataset?: DOMStringMap;
  clearFiltersCtaCopyDataset?: DOMStringMap;
}

export const GalleryFiltersV2: FC<GalleryFiltersV2Props> = ({
  searchMenus = [],
  selectedFilters,
  onChangeFilter,
  mobileFiltersToggleLabel,
  onClearFilters,
  clearButtonLabel,
  mobileFiltersWrapperTextDataset,
  clearFiltersCtaCopyDataset,
}) => {
  useMotifStyles(MotifComponent.GALLERY);

  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const toggleIsMobileOpen = () => setIsMobileOpen(!isMobileOpen);

  const selectedFiltersCount = useMemo(
    () => Object.keys(selectedFilters).length,
    [selectedFilters]
  );

  if (searchMenus.length === 0) {
    return null;
  }

  const searchMenusWithState: FilterDropdownMenuProps[] = searchMenus.map(
    ({ title, id, allItemTitle, items, dataset }) => ({
      title,
      id,
      allItemTitle,
      items,
      dataset,
      value: selectedFilters[id] ? selectedFilters[id] : allValue,
      onChange: (setValue?: string) => onChangeFilter(id, setValue),
    })
  );

  const children = searchMenusWithState.map(props => (
    <FilterDropdownMenu key={props.id} {...props} isStandalone={searchMenus.length === 1} />
  ));

  const filterText = mobileFiltersToggleLabel ?? (searchMenus.length > 1 ? 'Filters' : 'Filter');

  return (
    <section className={MotifComponent.GALLERY}>
      <aside className={desktopWrapperCss}>
        <div className={searchWrapperCss}>
          {children}
          {!!selectedFiltersCount && (
            <button
              data-testid="clear-btn-desktop"
              type="button"
              className={cx(buttonDefaultStylesResetCss, clearFiltersButtonCss)}
              onClick={onClearFilters}
              {...dataSetToAttributes(clearFiltersCtaCopyDataset)}
            >
              {clearButtonLabel ?? 'Clear'}
            </button>
          )}
        </div>
      </aside>
      <aside className={mobileWrapperCss}>
        {searchMenus.length > 1 && (
          <div className={filterBarCss} data-testid="dropdowns-mobile-wrapper">
            <div>
              <button
                type="button"
                className={cx(
                  buttonDefaultStylesResetCss,
                  toggleDropdownsBaseTextCss,
                  toggleDropdownsMainTextCss
                )}
                onClick={toggleIsMobileOpen}
                {...dataSetToAttributes(mobileFiltersWrapperTextDataset)}
              >
                {filterText}
              </button>
              {!!selectedFiltersCount && (
                <span
                  data-testid="selected-filters-counter-mobile"
                  className={cx(toggleDropdownsBaseTextCss, toggleDropdownsSecondaryTextCss)}
                >{` (${selectedFiltersCount})`}</span>
              )}
            </div>
            <div className={clearFiltersWrapperCss}>
              {!!selectedFiltersCount && (
                <button
                  data-testid="clear-btn-mobile"
                  type="button"
                  className={cx(buttonDefaultStylesResetCss, clearFiltersButtonCss)}
                  onClick={onClearFilters}
                  {...dataSetToAttributes(clearFiltersCtaCopyDataset)}
                >
                  {clearButtonLabel ?? 'Clear'}
                </button>
              )}
              <button
                type="button"
                className={cx(buttonDefaultStylesResetCss, filtersIconButtonCss)}
                onClick={toggleIsMobileOpen}
              >
                <Icon
                  name="bars-triangle"
                  className={cx(filtersIconCss, { [filtersIconActiveCss]: isMobileOpen })}
                />
              </button>
            </div>
          </div>
        )}
        {(isMobileOpen || searchMenus.length === 1) && (
          <div className={searchWrapperCss}>{children}</div>
        )}
      </aside>
    </section>
  );
};

GalleryFiltersV2.displayName = 'GalleryFiltersV2';
