/* eslint-disable import/no-unused-modules */
import type { ApolloLink } from '@apollo/client';
import { globalComponentsApolloLink as importedLink } from '@snapchat/mw-global-components-schema';

// Export the Apollo Link for consumers to use
export * from './components';
export * from './context';

// We need to import and export this because if we don't
// it will reference global-components-schema. By casting it
// here, we can force the type to be ApolloLink (which it is).
export const globalComponentsApolloLink = importedLink as ApolloLink;
