import { css, cx } from '@emotion/css';
import type { FC, PropsWithChildren } from 'react';

import type { WrapperProps } from './types';

export const LaptopWrapper: FC<PropsWithChildren<WrapperProps>> = ({
  children,
  className,
  maxHeight,
}) => {
  const styleCss = css`
    ${maxHeight ? `max-height: ${maxHeight}px;` : ''}
    width: 100%;
    & > svg {
      /* Scaling the SVG */
      width: 100%;
    }
  `;

  return (
    <div className={cx(styleCss, className)}>
      <svg
        viewBox="0 0 654 366"
        width="654"
        height="366"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="laptop-wrapper"
      >
        <g filter="url(#J)" fillRule="evenodd">
          <path
            d="M95.626 1.127L104.459 1h444.227l8.834.127c9.637.157 17.141 7.735 
          17.096 17.253.045-.115.285 5.943.285 8.833v292.992c0 1.781-.092 4.779-.172 
          6.782-.05 1.237-.152 3.513-.238 4.224-1.023 8.479-8.201 14.964-16.971 
          14.923.086.045-5.974.285-8.834.285H104.459c-2.861 0-8.92-.24-8.833-.285
          -8.359.039-15.272-5.851-16.788-13.745-.208-1.083-.314-2.203-.309-3.351
          -.045.114-.285-5.944-.285-8.833V27.214c0-2.876.24-8.948.285-8.833-.045-9.518 
          7.591-17.299 17.097-17.253z"
            fill="#303030"
          />
        </g>
        <g filter="url(#L)" fillRule="evenodd">
          <path
            d="M572.337 27.499l-.285-8.833c.045-7.935-6.31-14.449-14.247-14.404
          -1.411-.051-6.09-.127-8.834-.127H104.459c-2.752 0-6.258.083-8.833.127
          -7.937-.045-14.292 6.469-14.247 14.404-.057.246-.285 6.117-.285 8.833V322.12c0 
          .889.037 2.314.088 3.582.059 1.457.223 3.895.304 4.478.768 5.534 5.499 9.421 
          11.29 9.321-.039.112 4.331.285 6.269.285h454.77c2.043 0 6.421-.173 6.554-.285 
          6.229.11 11.335-4.713 11.398-11.112-.06.036.112-4.348 0-6.269l.57-294.621z"
            fill="#272727"
          />
        </g>
        <g fillRule="evenodd">
          <path
            d="M574.988 216.885v103.069l-.144 6.217-.393 5.834c-1.491 7.885-8.427 
          13.848-16.758 13.848l-8.884.24H104.133c-2.82 0-8.879-.24-8.879-.24-8.499 0
          -15.286-6.207-16.53-14.328-.135-.879-.445-9.574-.445-11.552v-103.05l496.709
          -.038z"
            fill="url(#O)"
            fillOpacity=".03"
          />
          <path
            d="M93.346 24.365h466.453c.355 0 .57.215.57.569v291.203a.52.52 0 0 1
          -.57.569H93.346a.52.52 0 0 1-.57-.569V24.934c0-.355.215-.569.57-.569z"
            fill="#111112"
          />
          <path
            d="M272.029 350.802H100.508l-37.081-.284c-33.91.284-43.031-3.773-43.026
          -4.748v-1.425h252.054l107.667.096h252.054v1.424c.004.976-9.117 5.033-43.027 
          4.749-.622.019-37.081.284-37.081.284H380.546l-108.517-.096z"
            fill="#1c1b1b"
          />
        </g>
        <path
          d="M21.255 336.082H631.32c.532 0 .855.313.855.57v9.118c0 .256-.323.57
        -.855.57H21.255c-.532 0-.855-.314-.855-.57v-9.118c0-.257.322-.57.855-.57z"
          fill="#1d1c1c"
          fillRule="evenodd"
        />
        <foreignObject
          x="92"
          y="24"
          width="468"
          height="293"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        >
          {children}
        </foreignObject>
        <defs>
          <filter
            id="J"
            x="77.244"
            y="0"
            width="498.657"
            height="347.42"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feGaussianBlur stdDeviation=".5" />
            <feBlend in="SourceGraphic" result="C" />
            <feColorMatrix
              in="SourceAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="D"
            />
            <feGaussianBlur stdDeviation="1.5" />
            <feComposite in2="D" operator="arithmetic" k2="-1" k3="1" />
            <feBlend in2="C" />
          </filter>
          <filter
            id="L"
            x="79.093"
            y="2.134"
            width="495.243"
            height="339.652"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feBlend in="SourceGraphic" result="C" />
            <feColorMatrix
              in="SourceAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="D"
            />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="D" operator="arithmetic" k2="-1" k3="1" />
            <feBlend in2="C" result="E" />
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
            <feBlend in2="E" />
          </filter>
          <linearGradient
            id="O"
            x1="111.017"
            y1="227.897"
            x2="111.017"
            y2="340.072"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopOpacity=".01" />
            <stop offset="1" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

LaptopWrapper.displayName = 'LaptopWrapper';
