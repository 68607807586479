import { gql } from '@apollo/client';

import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';

export const snapchatEmbedFragment = gql`
  fragment SnapchatEmbedAll on SnapchatEmbed {
    ...ContentfulSysId
    url
  }
  ${contentfulSysIdFragment}
`;

export const query = gql`
  query SnapchatEmbedQuery($preview: Boolean!, $locale: String!, $id: String!) {
    snapchatEmbed(preview: $preview, locale: $locale, id: $id) {
      ...SnapchatEmbedAll
    }
  }
  ${snapchatEmbedFragment}
`;
