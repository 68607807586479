import { cx } from '@emotion/css';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';

import { useOnScreen } from '../../../hooks';
import { Media } from '../../Media';
import { AnimatedAccordionMediaDirection } from '../AnimatedAccordion';
import { AccordionItem } from './AccordionItem';
import {
  accordionContainerCss,
  imageActiveCss,
  imageAspectRatioWrapperCss,
  imageCss,
  imagesContainerCss,
  layoutCss,
  layoutReverseCss,
} from './styles';
import type { AccordionProps } from './types';

export const Accordion: FC<AccordionProps> = ({ items = [], mediaDirection, autoPlaySpeedMs }) => {
  const allowAnimation = items.length > 1; // No need to animate if only 1 item
  const accordionRef = useRef<HTMLDivElement>(null);
  const isOnScreen = useOnScreen(accordionRef);

  // Start 'activeItem' at -1 so that all items appear closed initially.
  // This is to prevent an initial flash of the first item appearing open without
  // visibility due to functionality of the underlying 'DetailSummary' component
  // from SDS-M which is used inside the 'AccordionItem' component.
  const [activeItem, setActiveItem] = useState(-1);
  const [isPaused, setIsPaused] = useState(true);

  const handleOnAnimationComplete = () => {
    setActiveItem(prevActiveItem => {
      if (prevActiveItem >= items.length - 1) {
        return 0;
      }

      return prevActiveItem + 1;
    });
  };

  // When component first enters the screen, activate/open the first item
  useEffect(() => {
    if (allowAnimation) {
      setIsPaused(false);
    }

    setActiveItem(0);
  }, [allowAnimation, setIsPaused, setActiveItem]);

  // Pause/resume auto-rotation when component leaves/enters screen
  useEffect(() => {
    if (!allowAnimation) {
      return;
    }

    if (isOnScreen) {
      setIsPaused(false);
    } else {
      setIsPaused(true);
    }
  }, [isOnScreen, allowAnimation, setIsPaused]);

  return (
    <div
      className={cx(layoutCss, {
        [layoutReverseCss]: mediaDirection === AnimatedAccordionMediaDirection.Start,
      })}
    >
      <div ref={accordionRef} className={accordionContainerCss}>
        {items.map((item, i) => (
          <AccordionItem
            key={`${item.title}${i}`}
            isOpen={i === activeItem}
            isPaused={isPaused || i !== activeItem}
            progressIndicatorDurationMs={autoPlaySpeedMs}
            title={item.title}
            body={item.body}
            onToggle={() => {
              if (i === activeItem || !allowAnimation) return;
              setIsPaused(false);
              setActiveItem(i);
            }}
            onMouseOver={() => {
              if (i !== activeItem || !allowAnimation) return;
              setIsPaused(true);
            }}
            onMouseLeave={() => {
              if (i !== activeItem || !allowAnimation) return;
              setIsPaused(false);
            }}
            onAnimationComplete={handleOnAnimationComplete}
          />
        ))}
      </div>
      <div className={imagesContainerCss}>
        <div className={imageAspectRatioWrapperCss}>
          {items.map((item, i) => {
            return (
              <Media
                key={`${item.title}${i}`}
                className={cx(imageCss, {
                  [imageActiveCss]: i === activeItem,
                })}
                imgSrcs={item.imgSrcs}
                altText={item.imageAltText}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
