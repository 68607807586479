import { gql } from '@apollo/client';

import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';
import { fragments as imageFragments } from '../Image';
import { fragments as videoFragments } from '../Video';

export const carouselItemFragments = {
  text: gql`
    fragment CarouselTextAll on CarouselText {
      ...ContentfulSysId
      description {
        json
      }
      subtext {
        json
      }
      slugReference {
        ...ContentfulSysId
        slug
      }
      url
    }
    ${contentfulSysIdFragment}
  `,
  card: gql`
    fragment CarouselCardAll on CarouselCard {
      ...ContentfulSysId
      title
      subtitle
      body
      media {
        __typename
        ... on Video {
          ...VideoAll
        }
        ... on Image {
          ...ImageAll
        }
      }
      slugReference {
        ...ContentfulSysId
        slug
      }
      url
      showShadow
    }
    ${imageFragments.all}
    ${videoFragments.all}
    ${contentfulSysIdFragment}
  `,
};

export const fragments = {
  all: gql`
    fragment CarouselV3All on CarouselV3 {
      ...ContentfulSysId
      layout
      mediaAspectRatio
      contentsCollection {
        items {
          ... on CarouselText {
            ...CarouselTextAll
          }
          ... on CarouselCard {
            ...CarouselCardAll
          }
        }
      }
    }
    ${carouselItemFragments.text}
    ${carouselItemFragments.card}
  `,
};

export const carouselV3Query = gql`
  query CarouselV3Query($preview: Boolean!, $locale: String!, $id: String!) {
    carouselV3(preview: $preview, locale: $locale, id: $id) {
      ...CarouselV3All
    }
  }
  ${fragments.all}
`;
