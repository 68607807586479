import { Graticule, Mercator } from '@visx/geo';
import type { MouseEvent as ReactMouseEvent, MouseEventHandler } from 'react';
import { memo, useMemo } from 'react';
import { feature } from 'topojson-client';

import { White } from '../../../constants/colors';
import type { FieldMetadata } from '../types';
import type { CountryProperties, GeoMapDatum, GeoTooltipData, WorldData } from './geoMapTypes';

export type GeoMercatorProps = {
  width: number;
  height: number;
  onMouseEnter: (event: ReactMouseEvent<SVGPathElement, MouseEvent>, data: GeoTooltipData) => void;
  onMouseLeave: MouseEventHandler<SVGPathElement> | undefined;
  fieldMetadata: FieldMetadata;
  countryFieldKey: string;
  data: GeoMapDatum[];
  stroke?: string;
  colorRange: string[];
  emptyColor: string;
  topoData: WorldData;
  dataScaler: (num: number) => string;
};

function Map({
  width,
  height,
  onMouseEnter,
  onMouseLeave,
  fieldMetadata,
  countryFieldKey,
  data,
  stroke,
  emptyColor,
  topoData,
  dataScaler,
}: GeoMercatorProps): JSX.Element | null {
  const centerX = width / 2;
  const centerY = height / 2;
  const scale = (width / 630) * 100;

  const world = feature(topoData, topoData.objects.units);

  const color = (amount?: number) => {
    if (amount) {
      return dataScaler(amount);
    }

    return emptyColor;
  };
  // convert array to object for faster lookup, memo-ify for larger data sets
  const countries = useMemo(() => {
    return data.reduce((map, datum) => {
      map[datum[countryFieldKey]!] = datum;
      return map;
    }, {} as Record<string, GeoMapDatum>);
  }, [data, countryFieldKey]);

  return (
    <Mercator data={world.features} scale={scale} translate={[centerX, centerY + 50]}>
      {mercator => (
        <g>
          <Graticule graticule={g => mercator.path(g) || ''} stroke={White} />
          {mercator.features.map(({ feature, path }, i) => {
            const datum = countries[feature.id! as string] ?? ({} as GeoMapDatum);

            // TODO: Figure out what's wrong w/ the types.
            // It tends to think that properties = { properties: CountryProperties }
            // which is wrong...
            // I (Alex) spent an hour on this.. and kept hitting dead ends.
            // So for now it's a cast.
            const properties = feature.properties as unknown as CountryProperties;

            return (
              <path
                key={`map-feature-${i}`}
                id={properties.name}
                data-testid={properties.name}
                d={path || ''}
                fill={color(Number(datum[fieldMetadata.key]))}
                stroke={stroke || White}
                strokeWidth={0.5}
                onMouseEnter={e =>
                  onMouseEnter(e, {
                    id: feature.id as string,
                    name: properties.name,
                    data: datum[fieldMetadata.key] ?? 'No data',
                    field: fieldMetadata.title,
                  })
                }
                onMouseLeave={onMouseLeave}
              />
            );
          })}
        </g>
      )}
    </Mercator>
  );
}

const typedMemo: <T>(c: T) => T = memo;
export const MemoMap = typedMemo(Map);
