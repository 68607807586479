export const flags = [
  {
    id: 4,
    name: 'Afghanistan',
    alpha2: 'af',
    alpha3: 'afg',
    emoji: '🇦🇫',
  },
  {
    id: 8,
    name: 'Albania',
    alpha2: 'al',
    alpha3: 'alb',
    emoji: '🇦🇱',
  },
  {
    id: 12,
    name: 'Algeria',
    alpha2: 'dz',
    alpha3: 'dza',
    emoji: '🇩🇿',
  },
  {
    id: 20,
    name: 'Andorra',
    alpha2: 'ad',
    alpha3: 'and',
    emoji: '🇦🇩',
  },
  {
    id: 24,
    name: 'Angola',
    alpha2: 'ao',
    alpha3: 'ago',
    emoji: '🇦🇴',
  },
  {
    id: 28,
    name: 'Antigua and Barbuda',
    alpha2: 'ag',
    alpha3: 'atg',
    emoji: '🇦🇬',
  },
  {
    id: 32,
    name: 'Argentina',
    alpha2: 'ar',
    alpha3: 'arg',
    emoji: '🇦🇷',
  },
  {
    id: 51,
    name: 'Armenia',
    alpha2: 'am',
    alpha3: 'arm',
    emoji: '🇦🇲',
  },
  {
    id: 36,
    name: 'Australia',
    alpha2: 'au',
    alpha3: 'aus',
    emoji: '🇦🇺',
  },
  {
    id: 40,
    name: 'Austria',
    alpha2: 'at',
    alpha3: 'aut',
    emoji: '🇦🇹',
  },
  {
    id: 31,
    name: 'Azerbaijan',
    alpha2: 'az',
    alpha3: 'aze',
    emoji: '🇦🇿',
  },
  {
    id: 44,
    name: 'Bahamas',
    alpha2: 'bs',
    alpha3: 'bhs',
    emoji: '🇧🇸',
  },
  {
    id: 48,
    name: 'Bahrain',
    alpha2: 'bh',
    alpha3: 'bhr',
    emoji: '🇧🇭',
  },
  {
    id: 50,
    name: 'Bangladesh',
    alpha2: 'bd',
    alpha3: 'bgd',
    emoji: '🇧🇩',
  },
  {
    id: 52,
    name: 'Barbados',
    alpha2: 'bb',
    alpha3: 'brb',
    emoji: '🇧🇧',
  },
  {
    id: 112,
    name: 'Belarus',
    alpha2: 'by',
    alpha3: 'blr',
    emoji: '🇧🇾',
  },
  {
    id: 56,
    name: 'Belgium',
    alpha2: 'be',
    alpha3: 'bel',
    emoji: '🇧🇪',
  },
  {
    id: 84,
    name: 'Belize',
    alpha2: 'bz',
    alpha3: 'blz',
    emoji: '🇧🇿',
  },
  {
    id: 204,
    name: 'Benin',
    alpha2: 'bj',
    alpha3: 'ben',
    emoji: '🇧🇯',
  },
  {
    id: 64,
    name: 'Bhutan',
    alpha2: 'bt',
    alpha3: 'btn',
    emoji: '🇧🇹',
  },
  {
    id: 68,
    name: 'Bolivia (Plurinational State of)',
    alpha2: 'bo',
    alpha3: 'bol',
    emoji: '🇧🇴',
  },
  {
    id: 70,
    name: 'Bosnia and Herzegovina',
    alpha2: 'ba',
    alpha3: 'bih',
    emoji: '🇧🇦',
  },
  {
    id: 72,
    name: 'Botswana',
    alpha2: 'bw',
    alpha3: 'bwa',
    emoji: '🇧🇼',
  },
  {
    id: 76,
    name: 'Brazil',
    alpha2: 'br',
    alpha3: 'bra',
    emoji: '🇧🇷',
  },
  {
    id: 96,
    name: 'Brunei Darussalam',
    alpha2: 'bn',
    alpha3: 'brn',
    emoji: '🇧🇳',
  },
  {
    id: 100,
    name: 'Bulgaria',
    alpha2: 'bg',
    alpha3: 'bgr',
    emoji: '🇧🇬',
  },
  {
    id: 854,
    name: 'Burkina Faso',
    alpha2: 'bf',
    alpha3: 'bfa',
    emoji: '🇧🇫',
  },
  {
    id: 108,
    name: 'Burundi',
    alpha2: 'bi',
    alpha3: 'bdi',
    emoji: '🇧🇮',
  },
  {
    id: 132,
    name: 'Cabo Verde',
    alpha2: 'cv',
    alpha3: 'cpv',
    emoji: '🇨🇻',
  },
  {
    id: 116,
    name: 'Cambodia',
    alpha2: 'kh',
    alpha3: 'khm',
    emoji: '🇰🇭',
  },
  {
    id: 120,
    name: 'Cameroon',
    alpha2: 'cm',
    alpha3: 'cmr',
    emoji: '🇨🇲',
  },
  {
    id: 124,
    name: 'Canada',
    alpha2: 'ca',
    alpha3: 'can',
    emoji: '🇨🇦',
  },
  {
    id: 140,
    name: 'Central African Republic',
    alpha2: 'cf',
    alpha3: 'caf',
    emoji: '🇨🇫',
  },
  {
    id: 148,
    name: 'Chad',
    alpha2: 'td',
    alpha3: 'tcd',
    emoji: '🇹🇩',
  },
  {
    id: 152,
    name: 'Chile',
    alpha2: 'cl',
    alpha3: 'chl',
    emoji: '🇨🇱',
  },
  {
    id: 156,
    name: 'China',
    alpha2: 'cn',
    alpha3: 'chn',
    emoji: '🇨🇳',
  },
  {
    id: 170,
    name: 'Colombia',
    alpha2: 'co',
    alpha3: 'col',
    emoji: '🇨🇴',
  },
  {
    id: 174,
    name: 'Comoros',
    alpha2: 'km',
    alpha3: 'com',
    emoji: '🇰🇲',
  },
  {
    id: 178,
    name: 'Congo',
    alpha2: 'cg',
    alpha3: 'cog',
    emoji: '🇨🇬',
  },
  {
    id: 180,
    name: 'Congo, Democratic Republic of the',
    alpha2: 'cd',
    alpha3: 'cod',
    emoji: '🇨🇩',
  },
  {
    id: 188,
    name: 'Costa Rica',
    alpha2: 'cr',
    alpha3: 'cri',
    emoji: '🇨🇷',
  },
  {
    id: 384,
    name: "Côte d'Ivoire",
    alpha2: 'ci',
    alpha3: 'civ',
    emoji: '🇨🇮',
  },
  {
    id: 191,
    name: 'Croatia',
    alpha2: 'hr',
    alpha3: 'hrv',
    emoji: '🇭🇷',
  },
  {
    id: 192,
    name: 'Cuba',
    alpha2: 'cu',
    alpha3: 'cub',
    emoji: '🇨🇺',
  },
  {
    id: 196,
    name: 'Cyprus',
    alpha2: 'cy',
    alpha3: 'cyp',
    emoji: '🇨🇾',
  },
  {
    id: 203,
    name: 'Czechia',
    alpha2: 'cz',
    alpha3: 'cze',
    emoji: '🇨🇿',
  },
  {
    id: 208,
    name: 'Denmark',
    alpha2: 'dk',
    alpha3: 'dnk',
    emoji: '🇩🇰',
  },
  {
    id: 262,
    name: 'Djibouti',
    alpha2: 'dj',
    alpha3: 'dji',
    emoji: '🇩🇯',
  },
  {
    id: 212,
    name: 'Dominica',
    alpha2: 'dm',
    alpha3: 'dma',
    emoji: '🇩🇲',
  },
  {
    id: 214,
    name: 'Dominican Republic',
    alpha2: 'do',
    alpha3: 'dom',
    emoji: '🇩🇴',
  },
  {
    id: 218,
    name: 'Ecuador',
    alpha2: 'ec',
    alpha3: 'ecu',
    emoji: '🇪🇨',
  },
  {
    id: 818,
    name: 'Egypt',
    alpha2: 'eg',
    alpha3: 'egy',
    emoji: '🇪🇬',
  },
  {
    id: 222,
    name: 'El Salvador',
    alpha2: 'sv',
    alpha3: 'slv',
    emoji: '🇸🇻',
  },
  {
    id: 226,
    name: 'Equatorial Guinea',
    alpha2: 'gq',
    alpha3: 'gnq',
    emoji: '🇬🇶',
  },
  {
    id: 232,
    name: 'Eritrea',
    alpha2: 'er',
    alpha3: 'eri',
    emoji: '🇪🇷',
  },
  {
    id: 233,
    name: 'Estonia',
    alpha2: 'ee',
    alpha3: 'est',
    emoji: '🇪🇪',
  },
  {
    id: 748,
    name: 'Eswatini',
    alpha2: 'sz',
    alpha3: 'swz',
    emoji: '🇸🇿',
  },
  {
    id: 231,
    name: 'Ethiopia',
    alpha2: 'et',
    alpha3: 'eth',
    emoji: '🇪🇹',
  },
  {
    id: 242,
    name: 'Fiji',
    alpha2: 'fj',
    alpha3: 'fji',
    emoji: '🇫🇯',
  },
  {
    id: 246,
    name: 'Finland',
    alpha2: 'fi',
    alpha3: 'fin',
    emoji: '🇫🇮',
  },
  {
    id: 250,
    name: 'France',
    alpha2: 'fr',
    alpha3: 'fra',
    emoji: '🇫🇷',
  },
  {
    id: 266,
    name: 'Gabon',
    alpha2: 'ga',
    alpha3: 'gab',
    emoji: '🇬🇦',
  },
  {
    id: 270,
    name: 'Gambia',
    alpha2: 'gm',
    alpha3: 'gmb',
    emoji: '🇬🇲',
  },
  {
    id: 268,
    name: 'Georgia',
    alpha2: 'ge',
    alpha3: 'geo',
    emoji: '🇬🇪',
  },
  {
    id: 276,
    name: 'Germany',
    alpha2: 'de',
    alpha3: 'deu',
    emoji: '🇩🇪',
  },
  {
    id: 288,
    name: 'Ghana',
    alpha2: 'gh',
    alpha3: 'gha',
    emoji: '🇬🇭',
  },
  {
    id: 300,
    name: 'Greece',
    alpha2: 'gr',
    alpha3: 'grc',
    emoji: '🇬🇷',
  },
  {
    id: 308,
    name: 'Grenada',
    alpha2: 'gd',
    alpha3: 'grd',
    emoji: '🇬🇩',
  },
  {
    id: 320,
    name: 'Guatemala',
    alpha2: 'gt',
    alpha3: 'gtm',
    emoji: '🇬🇹',
  },
  {
    id: 324,
    name: 'Guinea',
    alpha2: 'gn',
    alpha3: 'gin',
    emoji: '🇬🇳',
  },
  {
    id: 624,
    name: 'Guinea-Bissau',
    alpha2: 'gw',
    alpha3: 'gnb',
    emoji: '🇬🇼',
  },
  {
    id: 328,
    name: 'Guyana',
    alpha2: 'gy',
    alpha3: 'guy',
    emoji: '🇬🇾',
  },
  {
    id: 332,
    name: 'Haiti',
    alpha2: 'ht',
    alpha3: 'hti',
    emoji: '🇭🇹',
  },
  {
    id: 340,
    name: 'Honduras',
    alpha2: 'hn',
    alpha3: 'hnd',
    emoji: '🇭🇳',
  },
  {
    id: 348,
    name: 'Hungary',
    alpha2: 'hu',
    alpha3: 'hun',
    emoji: '🇭🇺',
  },
  {
    id: 352,
    name: 'Iceland',
    alpha2: 'is',
    alpha3: 'isl',
    emoji: '🇮🇸',
  },
  {
    id: 356,
    name: 'India',
    alpha2: 'in',
    alpha3: 'ind',
    emoji: '🇮🇳',
  },
  {
    id: 360,
    name: 'Indonesia',
    alpha2: 'id',
    alpha3: 'idn',
    emoji: '🇮🇩',
  },
  {
    id: 364,
    name: 'Iran (Islamic Republic of)',
    alpha2: 'ir',
    alpha3: 'irn',
    emoji: '🇮🇷',
  },
  {
    id: 368,
    name: 'Iraq',
    alpha2: 'iq',
    alpha3: 'irq',
    emoji: '🇮🇶',
  },
  {
    id: 372,
    name: 'Ireland',
    alpha2: 'ie',
    alpha3: 'irl',
    emoji: '🇮🇪',
  },
  {
    id: 376,
    name: 'Israel',
    alpha2: 'il',
    alpha3: 'isr',
    emoji: '🇮🇱',
  },
  {
    id: 380,
    name: 'Italy',
    alpha2: 'it',
    alpha3: 'ita',
    emoji: '🇮🇹',
  },
  {
    id: 388,
    name: 'Jamaica',
    alpha2: 'jm',
    alpha3: 'jam',
    emoji: '🇯🇲',
  },
  {
    id: 392,
    name: 'Japan',
    alpha2: 'jp',
    alpha3: 'jpn',
    emoji: '🇯🇵',
  },
  {
    id: 400,
    name: 'Jordan',
    alpha2: 'jo',
    alpha3: 'jor',
    emoji: '🇯🇴',
  },
  {
    id: 398,
    name: 'Kazakhstan',
    alpha2: 'kz',
    alpha3: 'kaz',
    emoji: '🇰🇿',
  },
  {
    id: 404,
    name: 'Kenya',
    alpha2: 'ke',
    alpha3: 'ken',
    emoji: '🇰🇪',
  },
  {
    id: 296,
    name: 'Kiribati',
    alpha2: 'ki',
    alpha3: 'kir',
    emoji: '🇰🇮',
  },
  {
    id: 408,
    name: "Korea (Democratic People's Republic of)",
    alpha2: 'kp',
    alpha3: 'prk',
    emoji: '🇰🇵',
  },
  {
    id: 410,
    name: 'Korea, Republic of',
    alpha2: 'kr',
    alpha3: 'kor',
    emoji: '🇰🇷',
  },
  {
    id: 414,
    name: 'Kuwait',
    alpha2: 'kw',
    alpha3: 'kwt',
    emoji: '🇰🇼',
  },
  {
    id: 417,
    name: 'Kyrgyzstan',
    alpha2: 'kg',
    alpha3: 'kgz',
    emoji: '🇰🇬',
  },
  {
    id: 418,
    name: "Lao People's Democratic Republic",
    alpha2: 'la',
    alpha3: 'lao',
    emoji: '🇱🇦',
  },
  {
    id: 428,
    name: 'Latvia',
    alpha2: 'lv',
    alpha3: 'lva',
    emoji: '🇱🇻',
  },
  {
    id: 422,
    name: 'Lebanon',
    alpha2: 'lb',
    alpha3: 'lbn',
    emoji: '🇱🇧',
  },
  {
    id: 426,
    name: 'Lesotho',
    alpha2: 'ls',
    alpha3: 'lso',
    emoji: '🇱🇸',
  },
  {
    id: 430,
    name: 'Liberia',
    alpha2: 'lr',
    alpha3: 'lbr',
    emoji: '🇱🇷',
  },
  {
    id: 434,
    name: 'Libya',
    alpha2: 'ly',
    alpha3: 'lby',
    emoji: '🇱🇾',
  },
  {
    id: 438,
    name: 'Liechtenstein',
    alpha2: 'li',
    alpha3: 'lie',
    emoji: '🇱🇮',
  },
  {
    id: 440,
    name: 'Lithuania',
    alpha2: 'lt',
    alpha3: 'ltu',
    emoji: '🇱🇹',
  },
  {
    id: 442,
    name: 'Luxembourg',
    alpha2: 'lu',
    alpha3: 'lux',
    emoji: '🇱🇺',
  },
  {
    id: 450,
    name: 'Madagascar',
    alpha2: 'mg',
    alpha3: 'mdg',
    emoji: '🇲🇬',
  },
  {
    id: 454,
    name: 'Malawi',
    alpha2: 'mw',
    alpha3: 'mwi',
    emoji: '🇲🇼',
  },
  {
    id: 458,
    name: 'Malaysia',
    alpha2: 'my',
    alpha3: 'mys',
    emoji: '🇲🇾',
  },
  {
    id: 462,
    name: 'Maldives',
    alpha2: 'mv',
    alpha3: 'mdv',
    emoji: '🇲🇻',
  },
  {
    id: 466,
    name: 'Mali',
    alpha2: 'ml',
    alpha3: 'mli',
    emoji: '🇲🇱',
  },
  {
    id: 470,
    name: 'Malta',
    alpha2: 'mt',
    alpha3: 'mlt',
    emoji: '🇲🇹',
  },
  {
    id: 584,
    name: 'Marshall Islands',
    alpha2: 'mh',
    alpha3: 'mhl',
    emoji: '🇲🇭',
  },
  {
    id: 478,
    name: 'Mauritania',
    alpha2: 'mr',
    alpha3: 'mrt',
    emoji: '🇲🇷',
  },
  {
    id: 480,
    name: 'Mauritius',
    alpha2: 'mu',
    alpha3: 'mus',
    emoji: '🇲🇺',
  },
  {
    id: 484,
    name: 'Mexico',
    alpha2: 'mx',
    alpha3: 'mex',
    emoji: '🇲🇽',
  },
  {
    id: 583,
    name: 'Micronesia (Federated States of)',
    alpha2: 'fm',
    alpha3: 'fsm',
    emoji: '🇫🇲',
  },
  {
    id: 498,
    name: 'Moldova, Republic of',
    alpha2: 'md',
    alpha3: 'mda',
    emoji: '🇲🇩',
  },
  {
    id: 492,
    name: 'Monaco',
    alpha2: 'mc',
    alpha3: 'mco',
    emoji: '🇲🇨',
  },
  {
    id: 496,
    name: 'Mongolia',
    alpha2: 'mn',
    alpha3: 'mng',
    emoji: '🇲🇳',
  },
  {
    id: 499,
    name: 'Montenegro',
    alpha2: 'me',
    alpha3: 'mne',
    emoji: '🇲🇪',
  },
  {
    id: 504,
    name: 'Morocco',
    alpha2: 'ma',
    alpha3: 'mar',
    emoji: '🇲🇦',
  },
  {
    id: 508,
    name: 'Mozambique',
    alpha2: 'mz',
    alpha3: 'moz',
    emoji: '🇲🇿',
  },
  {
    id: 104,
    name: 'Myanmar',
    alpha2: 'mm',
    alpha3: 'mmr',
    emoji: '🇲🇲',
  },
  {
    id: 516,
    name: 'Namibia',
    alpha2: 'na',
    alpha3: 'nam',
    emoji: '🇳🇦',
  },
  {
    id: 520,
    name: 'Nauru',
    alpha2: 'nr',
    alpha3: 'nru',
    emoji: '🇳🇷',
  },
  {
    id: 524,
    name: 'Nepal',
    alpha2: 'np',
    alpha3: 'npl',
    emoji: '🇳🇵',
  },
  {
    id: 528,
    name: 'Netherlands',
    alpha2: 'nl',
    alpha3: 'nld',
    emoji: '🇳🇱',
  },
  {
    id: 554,
    name: 'New Zealand',
    alpha2: 'nz',
    alpha3: 'nzl',
    emoji: '🇳🇿',
  },
  {
    id: 558,
    name: 'Nicaragua',
    alpha2: 'ni',
    alpha3: 'nic',
    emoji: '🇳🇮',
  },
  {
    id: 562,
    name: 'Niger',
    alpha2: 'ne',
    alpha3: 'ner',
    emoji: '🇳🇪',
  },
  {
    id: 566,
    name: 'Nigeria',
    alpha2: 'ng',
    alpha3: 'nga',
    emoji: '🇳🇬',
  },
  {
    id: 807,
    name: 'North Macedonia',
    alpha2: 'mk',
    alpha3: 'mkd',
    emoji: '🇲🇰',
  },
  {
    id: 578,
    name: 'Norway',
    alpha2: 'no',
    alpha3: 'nor',
    emoji: '🇳🇴',
  },
  {
    id: 512,
    name: 'Oman',
    alpha2: 'om',
    alpha3: 'omn',
    emoji: '🇴🇲',
  },
  {
    id: 586,
    name: 'Pakistan',
    alpha2: 'pk',
    alpha3: 'pak',
    emoji: '🇵🇰',
  },
  {
    id: 585,
    name: 'Palau',
    alpha2: 'pw',
    alpha3: 'plw',
    emoji: '🇵🇼',
  },
  {
    id: 591,
    name: 'Panama',
    alpha2: 'pa',
    alpha3: 'pan',
    emoji: '🇵🇦',
  },
  {
    id: 598,
    name: 'Papua New Guinea',
    alpha2: 'pg',
    alpha3: 'png',
    emoji: '🇵🇬',
  },
  {
    id: 600,
    name: 'Paraguay',
    alpha2: 'py',
    alpha3: 'pry',
    emoji: '🇵🇾',
  },
  {
    id: 604,
    name: 'Peru',
    alpha2: 'pe',
    alpha3: 'per',
    emoji: '🇵🇪',
  },
  {
    id: 608,
    name: 'Philippines',
    alpha2: 'ph',
    alpha3: 'phl',
    emoji: '🇵🇭',
  },
  {
    id: 616,
    name: 'Poland',
    alpha2: 'pl',
    alpha3: 'pol',
    emoji: '🇵🇱',
  },
  {
    id: 620,
    name: 'Portugal',
    alpha2: 'pt',
    alpha3: 'prt',
    emoji: '🇵🇹',
  },
  {
    id: 634,
    name: 'Qatar',
    alpha2: 'qa',
    alpha3: 'qat',
    emoji: '🇶🇦',
  },
  {
    id: 642,
    name: 'Romania',
    alpha2: 'ro',
    alpha3: 'rou',
    emoji: '🇷🇴',
  },
  {
    id: 643,
    name: 'Russian Federation',
    alpha2: 'ru',
    alpha3: 'rus',
    emoji: '🇷🇺',
  },
  {
    id: 646,
    name: 'Rwanda',
    alpha2: 'rw',
    alpha3: 'rwa',
    emoji: '🇷🇼',
  },
  {
    id: 659,
    name: 'Saint Kitts and Nevis',
    alpha2: 'kn',
    alpha3: 'kna',
    emoji: '🇰🇳',
  },
  {
    id: 662,
    name: 'Saint Lucia',
    alpha2: 'lc',
    alpha3: 'lca',
    emoji: '🇱🇨',
  },
  {
    id: 670,
    name: 'Saint Vincent and the Grenadines',
    alpha2: 'vc',
    alpha3: 'vct',
    emoji: '🇻🇨',
  },
  {
    id: 882,
    name: 'Samoa',
    alpha2: 'ws',
    alpha3: 'wsm',
    emoji: '🇼🇸',
  },
  {
    id: 674,
    name: 'San Marino',
    alpha2: 'sm',
    alpha3: 'smr',
    emoji: '🇸🇲',
  },
  {
    id: 678,
    name: 'Sao Tome and Principe',
    alpha2: 'st',
    alpha3: 'stp',
    emoji: '🇸🇹',
  },
  {
    id: 682,
    name: 'Saudi Arabia',
    alpha2: 'sa',
    alpha3: 'sau',
    emoji: '🇸🇦',
  },
  {
    id: 686,
    name: 'Senegal',
    alpha2: 'sn',
    alpha3: 'sen',
    emoji: '🇸🇳',
  },
  {
    id: 688,
    name: 'Serbia',
    alpha2: 'rs',
    alpha3: 'srb',
    emoji: '🇷🇸',
  },
  {
    id: 690,
    name: 'Seychelles',
    alpha2: 'sc',
    alpha3: 'syc',
    emoji: '🇸🇨',
  },
  {
    id: 694,
    name: 'Sierra Leone',
    alpha2: 'sl',
    alpha3: 'sle',
    emoji: '🇸🇱',
  },
  {
    id: 702,
    name: 'Singapore',
    alpha2: 'sg',
    alpha3: 'sgp',
    emoji: '🇸🇬',
  },
  {
    id: 703,
    name: 'Slovakia',
    alpha2: 'sk',
    alpha3: 'svk',
    emoji: '🇸🇰',
  },
  {
    id: 705,
    name: 'Slovenia',
    alpha2: 'si',
    alpha3: 'svn',
    emoji: '🇸🇮',
  },
  {
    id: 90,
    name: 'Solomon Islands',
    alpha2: 'sb',
    alpha3: 'slb',
    emoji: '🇸🇧',
  },
  {
    id: 706,
    name: 'Somalia',
    alpha2: 'so',
    alpha3: 'som',
    emoji: '🇸🇴',
  },
  {
    id: 710,
    name: 'South Africa',
    alpha2: 'za',
    alpha3: 'zaf',
    emoji: '🇿🇦',
  },
  {
    id: 728,
    name: 'South Sudan',
    alpha2: 'ss',
    alpha3: 'ssd',
    emoji: '🇸🇸',
  },
  {
    id: 724,
    name: 'Spain',
    alpha2: 'es',
    alpha3: 'esp',
    emoji: '🇪🇸',
  },
  {
    id: 144,
    name: 'Sri Lanka',
    alpha2: 'lk',
    alpha3: 'lka',
    emoji: '🇱🇰',
  },
  {
    id: 729,
    name: 'Sudan',
    alpha2: 'sd',
    alpha3: 'sdn',
    emoji: '🇸🇩',
  },
  {
    id: 740,
    name: 'Suriname',
    alpha2: 'sr',
    alpha3: 'sur',
    emoji: '🇸🇷',
  },
  {
    id: 752,
    name: 'Sweden',
    alpha2: 'se',
    alpha3: 'swe',
    emoji: '🇸🇪',
  },
  {
    id: 756,
    name: 'Switzerland',
    alpha2: 'ch',
    alpha3: 'che',
    emoji: '🇨🇭',
  },
  {
    id: 760,
    name: 'Syrian Arab Republic',
    alpha2: 'sy',
    alpha3: 'syr',
    emoji: '🇸🇾',
  },
  {
    id: 762,
    name: 'Tajikistan',
    alpha2: 'tj',
    alpha3: 'tjk',
    emoji: '🇹🇯',
  },
  {
    id: 834,
    name: 'Tanzania, United Republic of',
    alpha2: 'tz',
    alpha3: 'tza',
    emoji: '🇹🇿',
  },
  {
    id: 764,
    name: 'Thailand',
    alpha2: 'th',
    alpha3: 'tha',
    emoji: '🇹🇭',
  },
  {
    id: 626,
    name: 'Timor-Leste',
    alpha2: 'tl',
    alpha3: 'tls',
    emoji: '🇹🇱',
  },
  {
    id: 768,
    name: 'Togo',
    alpha2: 'tg',
    alpha3: 'tgo',
    emoji: '🇹🇬',
  },
  {
    id: 776,
    name: 'Tonga',
    alpha2: 'to',
    alpha3: 'ton',
    emoji: '🇹🇴',
  },
  {
    id: 780,
    name: 'Trinidad and Tobago',
    alpha2: 'tt',
    alpha3: 'tto',
    emoji: '🇹🇹',
  },
  {
    id: 788,
    name: 'Tunisia',
    alpha2: 'tn',
    alpha3: 'tun',
    emoji: '🇹🇳',
  },
  {
    id: 792,
    name: 'Turkey',
    alpha2: 'tr',
    alpha3: 'tur',
    emoji: '🇹🇷',
  },
  {
    id: 795,
    name: 'Turkmenistan',
    alpha2: 'tm',
    alpha3: 'tkm',
    emoji: '🇹🇲',
  },
  {
    id: 798,
    name: 'Tuvalu',
    alpha2: 'tv',
    alpha3: 'tuv',
    emoji: '🇹🇻',
  },
  {
    id: 800,
    name: 'Uganda',
    alpha2: 'ug',
    alpha3: 'uga',
    emoji: '🇺🇬',
  },
  {
    id: 804,
    name: 'Ukraine',
    alpha2: 'ua',
    alpha3: 'ukr',
    emoji: '🇺🇦',
  },
  {
    id: 784,
    name: 'United Arab Emirates',
    alpha2: 'ae',
    alpha3: 'are',
    emoji: '🇦🇪',
  },
  {
    id: 826,
    name: 'United Kingdom of Great Britain and Northern Ireland',
    alpha2: 'gb',
    alpha3: 'gbr',
    emoji: '🇬🇧',
  },
  {
    id: 840,
    name: 'United States of America',
    alpha2: 'us',
    alpha3: 'usa',
    emoji: '🇺🇸',
  },
  {
    id: 858,
    name: 'Uruguay',
    alpha2: 'uy',
    alpha3: 'ury',
    emoji: '🇺🇾',
  },
  {
    id: 860,
    name: 'Uzbekistan',
    alpha2: 'uz',
    alpha3: 'uzb',
    emoji: '🇺🇿',
  },
  {
    id: 548,
    name: 'Vanuatu',
    alpha2: 'vu',
    alpha3: 'vut',
    emoji: '🇻🇺',
  },
  {
    id: 862,
    name: 'Venezuela (Bolivarian Republic of)',
    alpha2: 've',
    alpha3: 'ven',
    emoji: '🇻🇪',
  },
  {
    id: 704,
    name: 'Viet Nam',
    alpha2: 'vn',
    alpha3: 'vnm',
    emoji: '🇻🇳',
  },
  {
    id: 887,
    name: 'Yemen',
    alpha2: 'ye',
    alpha3: 'yem',
    emoji: '🇾🇪',
  },
  {
    id: 894,
    name: 'Zambia',
    alpha2: 'zm',
    alpha3: 'zmb',
    emoji: '🇿🇲',
  },
  {
    id: 716,
    name: 'Zimbabwe',
    alpha2: 'zw',
    alpha3: 'zwe',
    emoji: '🇿🇼',
  },
];
