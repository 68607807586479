import type { FC } from 'react';

import { ArArkoseProtectedFormBlock } from '../sites/ar/components/ArArkoseProtectedFormBlock';
import { ArDownloadFormBlock } from '../sites/ar/components/ArDownloadForm';
import { ArDownloadIdCaptureBlock } from '../sites/ar/components/ArDownloadIdCapture';
import { AvalonDeepLinkBlock } from '../sites/avalon/components/AvalonDeepLinkBlock/AvalonDeepLinkBlock';
import { AvalonFormBlock } from '../sites/avalon/components/AvalonFormBlock/AvalonFormBlock';
import { AvalonHomeHero } from '../sites/avalon/components/AvalonHomeHero/AvalonHomeHero';
import { AvalonProductHero } from '../sites/avalon/components/AvalonProductHero/AvalonProductHero';
import { AvalonScrollSection } from '../sites/avalon/components/AvalonScrollSection/AvalonScrollSection';
import { FixedPositionBlock } from '../sites/avalon/components/FixedPositionBlock/FixedPositionBlock';
import { MosaicGridBlock } from '../sites/avalon/components/MosaicGridBlock/MosaicGridBlock';
import { ValuePropsBlock } from '../sites/avalon/components/ValuePropsBlock/ValuePropsBlock';
import { JobApplyNowBlock } from '../sites/careers/components/JobApplyNowBlock/JobApplyNowBlock';
import { JobDescriptionBlock } from '../sites/careers/components/JobDescriptionBlock/JobDescriptionBlock';
import { JobHero } from '../sites/careers/components/JobHero/JobHero';
import { JobMobileCallToAction } from '../sites/careers/components/JobMobileCallToAction/JobMobileCallToAction';
import { JobPostingsShallow } from '../sites/careers/components/JobsPostings/JobPostingsShallow';
import { CheeriosBlock } from '../sites/cheerios/components/CheeriosBlock';
import { CheeriosStaticBlock } from '../sites/cheerios/components/CheeriosStaticBlock';
import { CventAuthenticator } from '../sites/experience/components/CventAuthenticator/CventAuthenticator';
import { EventRoot } from '../sites/experience/components/LiveEvent/EventRoot';
import { RegistrationFormBlock } from '../sites/experience/components/RegistrationFormBlock/RegistrationFormBlock';
// eslint-disable-next-line max-len
import { Sps2024ConfirmationShallow as ExperienceSps2024ConfirmationShallow } from '../sites/experience/components/Sps2024Confirmation/Sps2024ConfirmationShallow';
// eslint-disable-next-line max-len
import { Sps2024RegistrationShallow as ExperienceSpsRegistrationShallow } from '../sites/experience/components/Sps2024Registration/Sps2024RegistrationShallow';
import { ChiliPiperFormBlockShallow } from '../sites/for-business/components/ChiliPiperForm/ChiliPiperFormShallow';
import { LeadGenFormBlock } from '../sites/for-business/components/LeadGenForm/LeadGenFormBlock';
import { PersistentCallToActionShallow } from '../sites/for-business/components/PersistentCallToAction/PersistentCallToActionShallow';
import { LatestGalleryCardHeroShallow } from '../sites/newsroom/components/LatestGalleryCardHero/LatestGalleryCardHeroShallow';
import { CookieTestBlock } from '../sites/sandbox/components/CookieTestBlock/CookieTestBlock';
import { SnapHome } from '../sites/snap/pages/SnapHome';
// Temporary will have a follow up PR to make standard component.
import { QuestionnaireBlock } from './QuestionnaireBlock';

/**
 * Returns a map of all supported custom components.
 *
 * This is in a separate file and not Site Config because adding it there introduces a circular
 * dependency because usually you can't instantiate custom components without backreferencing the
 * Config in some way.
 */
export function getCustomComponents(): Record<string, FC<{ id: string }>> {
  return {
    ArDownloadFormBlock,
    ArDownloadIdCaptureBlock,
    AvalonHomeHero,
    AvalonDeepLinkBlock,
    AvalonProductHero,
    AvalonFormBlock,
    AvalonScrollSection,
    FixedPositionBlock,
    ValuePropsBlock,
    CheeriosBlock,
    CheeriosStaticBlock,
    ChiliPiperFormBlock: ChiliPiperFormBlockShallow,
    JobApplyNowBlock,
    JobDescriptionBlock,
    JobHero,
    JobPostings: JobPostingsShallow,
    JobMobileCallToAction,
    MobileCta: PersistentCallToActionShallow,
    QuestionnaireBlock,
    SandboxCookieTest: CookieTestBlock,
    LatestGalleryCardHero: LatestGalleryCardHeroShallow,
    RegistrationFormBlock,
    ArArkoseProtectedFormBlock,
    LeadGenFormBlock,
    ExperienceSps2024Registration: ExperienceSpsRegistrationShallow,
    ExperienceSps2024Confirmation: ExperienceSps2024ConfirmationShallow,
    CventAuthenticator,
    MosaicGridBlock,
    LiveEvent: EventRoot,
    SnapHome,
  };
}
