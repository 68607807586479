import { Black, Gray } from '../../constants/colors';
import type { MotifComponent, MotifComponentProps } from '../../motif';
import { m } from '../../motif/m';

const defaultItemActiveColorVars = {
  '--tabs-item-color': m('--action-default-color'),
  '--tabs-item-hover-color': m('--action-hover-color'),
  '--tabs-item-active-color': m('--action-active-color'),
};

export const lightTabVars: MotifComponentProps<MotifComponent.TABS> = {
  '--tabs-underline-color': Gray.V200,
  ...defaultItemActiveColorVars,
};

export const darkTabVars: MotifComponentProps<MotifComponent.TABS> = {
  '--tabs-underline-color': Black.V100,
  ...defaultItemActiveColorVars,
};
