import { useContentfulImages } from '@snapchat/mw-contentful-client';
import { Logo as LogoSDS } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useContext } from 'react';

import { SitewideConfigurationContext } from '../../context/SitewideConfiguration/SitewideConfigurationContext';
import { combineImageSources } from '../../utils/combineImageSources';

export const GlobalHeaderLogo: FC = () => {
  const { sitewideValues } = useContext(SitewideConfigurationContext);

  const localNavProps = sitewideValues?.navigationBar;
  const { getImageSources } = useContentfulImages();

  if (!localNavProps) {
    return null;
  }

  const { url, logoV2: logo } = localNavProps;

  const imgSrcs = combineImageSources({
    mobile: getImageSources(logo?.mobileMedia?.url),
    desktop: getImageSources(logo?.media?.url),
  });

  const logoElement = <LogoSDS imgSrcs={imgSrcs} url={url} logoType={'Ghost'} />;

  return <>{logoElement}</>;
};
