import { DeploymentType, Site } from '@snapchat/mw-common';
import { isBrowser } from '@snapchat/mw-common/client';
import {
  ContentfulAlias,
  contentfulSpaceIds,
  productionContentTokens,
} from '@snapchat/mw-contentful-schema';
import {
  globalComponentsAccessToken,
  globalComponentsEnvName,
  globalComponentsSpaceId,
} from '@snapchat/mw-global-components-schema';
import { Template } from '@snapchat/snap-design-system-marketing';

import { clientEnv } from './clientEnv';
import type {
  ContentfulConfiguration,
  ElasticConfiguration,
  MarketingWebConfiguration,
  RequiredTrackingSettings,
  SiteConfiguration,
} from './configTypes';
import { getCookieDomain } from './helpers/trackingCookies';
import { elasticCloudId, elasticNode } from './server/endpoints/gallery/galleryConfig';
import { config as snap523Config } from './sites/523/523Config';
import { config as arConfig } from './sites/ar/arConfig';
import { config as arcadiaConfig } from './sites/arcadia/arcadiaConfig';
import { config as avalonConfig } from './sites/avalon/avalonConfig';
import { config as careersConfig } from './sites/careers/careersConfig';
import { config as cheeriosConfig } from './sites/cheerios/cheeriosConfig';
import { config as citizenConfig } from './sites/citizen/citizenConfig';
import { config as creatorsConfig } from './sites/creators/creatorsConfig';
import { config as diversityConfig } from './sites/diversity/diversityConfig';
import { config as educatorsConfig } from './sites/educators/educatorsConfig';
import { config as engBlogConfig } from './sites/eng-blog/engBlogConfig';
import { config as experienceConfig } from './sites/experience/experienceConfig';
import { config as forBusinessConfig } from './sites/for-business/forBusinessConfig';
import { config as newsroomConfig } from './sites/newsroom/newsroomConfig';
import { config as originalsConfig } from './sites/originals/originalsConfig';
import { config as parentsConfig } from './sites/parents/parentsConfig';
import { config as sandboxConfig } from './sites/sandbox/sandboxConfig';
import { config as snapConfig } from './sites/snap/snapConfig';
import { config as trustConfig } from './sites/trust/trustConfig';

const environmentNames: Record<DeploymentType, string> = {
  [DeploymentType.PRODUCTION]: ContentfulAlias.PRODUCTION,
  [DeploymentType.STAGING]: ContentfulAlias.MASTER,
  [DeploymentType.LATEST_DEV]: ContentfulAlias.LATEST_DEV,
};

const transformTrackingSettings = (site: SiteConfiguration): RequiredTrackingSettings => {
  return {
    ...site.trackingSettings,
    cookieDomain: getCookieDomain(site.domainName, site.trackingSettings.cookieDomain),
  };
};

export const getSiteConfig = (site: Site): SiteConfiguration => {
  switch (site) {
    case Site.A523:
      return snap523Config;
    case Site.ARCADIA:
      return arcadiaConfig;
    case Site.AR:
      return arConfig;
    case Site.AVALON:
      return avalonConfig;
    case Site.CAREERS:
      return careersConfig;
    case Site.CHEERIOS:
      return cheeriosConfig;
    case Site.CITIZEN:
      return citizenConfig;
    case Site.CREATORS:
      return creatorsConfig;
    case Site.DIVERSITY:
      return diversityConfig;
    case Site.EDUCATORS:
      return educatorsConfig;
    case Site.ENG_BLOG:
      return engBlogConfig;
    case Site.EXPERIENCE:
      return experienceConfig;
    case Site.FOR_BUSINESS:
      return forBusinessConfig;
    case Site.NEWSROOM:
      return newsroomConfig;
    case Site.ORIGINALS:
      return originalsConfig;
    case Site.PARENTS:
      return parentsConfig;
    case Site.SANDBOX:
      return sandboxConfig;
    case Site.TRUST:
      return trustConfig;
    case Site.SNAP:
      return snapConfig;
    default:
      break;
  }
  throw new Error(
    `Cannot find config for 'src/sites/${clientEnv.SITE_CONFIG}'. Is it specified correctly in env/\${SITE}.env?`
  );
};

/** Function that merges the site configuration with globally available environment variables. */
const createConfig = (): MarketingWebConfiguration => {
  const requiredEnvVars: (keyof typeof clientEnv)[] = ['NODE_ENV', 'PREVIEW', 'SITE_CONFIG'];

  for (const envVar of requiredEnvVars) {
    if (!clientEnv[envVar]) {
      throw Error(
        `Missing required variable '${envVar}'.\nOn server, make sure it's in the environment during esbuild execution.\n`
      );
    }
  }

  const site = clientEnv.SITE_CONFIG as Site;
  const siteConfig = getSiteConfig(site);

  const isPreview = clientEnv.PREVIEW! === 'true';

  if (isPreview && !clientEnv.CONTENTFUL_PREVIEW_ACCESS_TOKEN) {
    throw new Error(`Preview access token not set. Do you have 'secrets.env'?`);
  }

  const isClient = isBrowser();
  const buildNumber = clientEnv.CI_PIPELINE_ID ?? 'local';
  const commit = clientEnv.CI_COMMIT ?? 'unknown_commit';
  // NOTE: This would probably be better checking GAE_VERSION and GAE_SERVICE;
  // However there's an argument against compiling those into the world readable bundle for
  // security reasons.
  const isLocal = buildNumber === 'local';
  const isTest = !!clientEnv.JEST_WORKER_ID;

  const compilationMode = clientEnv.NODE_ENV === 'production' ? 'production' : 'development';
  const deploymentType = clientEnv.DEPLOYMENT_TYPE as DeploymentType;
  const isDeploymentTypeProd = deploymentType === DeploymentType.PRODUCTION;
  const isCompilationModeProd = compilationMode === 'production';
  const isSsr = clientEnv.SSR !== 'false'; // Defaults to 'true' so worded as a check for falsehood.
  const region = clientEnv.GOOGLE_CLOUD_REGION;
  const googleCloudProjectName = clientEnv.GOOGLE_CLOUD_PROJECT ?? 'entapps-web-dev';

  const contentfulEnvNameOverride =
    clientEnv.CONTENTFUL_ENVIRONMENT_NAME === 'undefined'
      ? undefined
      : clientEnv.CONTENTFUL_ENVIRONMENT_NAME;
  const contentful: ContentfulConfiguration = {
    spaceId: contentfulSpaceIds[site],
    environmentName: contentfulEnvNameOverride ?? environmentNames[deploymentType],
    accessToken: productionContentTokens[site],
    devAccessToken: clientEnv.CONTENTFUL_DEV_ACCESS_TOKEN,
    previewAccessToken: clientEnv.CONTENTFUL_PREVIEW_ACCESS_TOKEN,
  };

  const contentfulGlobal: ContentfulConfiguration = {
    spaceId: globalComponentsSpaceId,
    environmentName: globalComponentsEnvName,
    accessToken: globalComponentsAccessToken,
    devAccessToken: clientEnv.GLOBAL_COMPONENTS_CONTENTFUL_DEV_ACCESS_TOKEN,
    previewAccessToken: clientEnv.GLOBAL_COMPONENTS_CONTENTFUL_PREVIEW_ACCESS_TOKEN,
  };

  const useLocalElastic = clientEnv.USE_LOCAL_ELASTIC === 'true';

  const elasticConfig: ElasticConfiguration = {
    elasticNode: useLocalElastic ? 'http://localhost:9200' : elasticNode,
    elasticCloudId: useLocalElastic ? undefined : elasticCloudId,
    elasticApiKeyOverride: useLocalElastic ? undefined : clientEnv.ELASTIC_SEARCH_READER_API_KEY,
    isApiKeyRequired: !useLocalElastic,
  };

  const config: MarketingWebConfiguration = {
    site,
    ...siteConfig,
    theme: {
      // These are the default breaks.
      breakTemplates: [
        Template.Straight,
        Template.Skirt,
        Template.Straight,
        Template.HeadFlipped,
        Template.Straight,
        Template.Head,
      ],
      ...siteConfig.theme,
    },
    contentful,
    contentfulGlobal,
    elastic: elasticConfig,
    globalNavProps: {
      trackingSiteName: siteConfig.domainName,
      ...siteConfig.globalNavProps,
    },
    trackingSettings: transformTrackingSettings(siteConfig),
    compilationMode,
    isCompilationModeProd,
    isSSR: isSsr,
    isPreview,
    isClient,
    isLocal,
    isTest,
    localeFilter: isPreview ? undefined : siteConfig.localeFilter, // Enable all locales when in Preview mode.
    homeUrl: `https://${siteConfig.domainName}`,
    deploymentType,
    isDeploymentTypeProd,
    buildNumber,
    commit,
    region,
    googleCloudProjectName,
  };

  if (siteConfig.init) {
    siteConfig.init();
  }

  return config;
};

export const Config = createConfig();
