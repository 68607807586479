import { cx } from '@emotion/css';
import { CrossIcon } from '@snapchat/snap-design-system-icons';
import type { FC, PropsWithChildren } from 'react';
import { useState } from 'react';

import type { BackgroundColor } from '../../constants';
import { MotifComponent, useMotifStyles } from '../../motif';
import { dataSetToAttributes, getBackgroundClassName } from '../../utils';
import {
  globalHeaderBannerButtonDivCss,
  globalHeaderBannerButtonStylesCss,
  globalHeaderBannerContentContainerCss,
  globalHeaderBannerCss,
} from './Banner.styles';

interface BannerProps extends PropsWithChildren {
  className?: string;
  contentClassName?: string;
  backgroundColor?: BackgroundColor;
  dataset?: DOMStringMap;
}

export const Banner: FC<BannerProps> = ({
  backgroundColor = 'Yellow',
  className,
  children,
  contentClassName,
  dataset,
}) => {
  useMotifStyles(MotifComponent.BANNER);
  const [show, setShow] = useState(true);

  if (!show) {
    return null;
  }

  return (
    <div
      className={cx(
        MotifComponent.BANNER,
        globalHeaderBannerCss,
        className,
        getBackgroundClassName(backgroundColor)
      )}
      {...dataSetToAttributes(dataset)}
    >
      <div className={cx(globalHeaderBannerContentContainerCss, contentClassName)}>{children}</div>
      <div className={globalHeaderBannerButtonDivCss}>
        <button className={globalHeaderBannerButtonStylesCss} onClick={() => setShow(false)}>
          <CrossIcon size={24} />
        </button>
      </div>
    </div>
  );
};
