import { cx } from '@emotion/css';
import { Icon } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useContext } from 'react';

import { ShopifyContext } from '../ShopifyProvider';
import { Price } from './Price';
import {
  atpCss,
  cartItemImg,
  cartItemWrapperCss,
  priceCss,
  quantityCss,
  quantityModificationCss,
  quantityModificationDisabledCss,
  quantityWrapperCss,
  titleCss,
} from './styles';
import type { ShopifyProduct } from './types';

export const CartItem: FC<ShopifyProduct> = ({ image, atpMessage, price, handle, maxQuantity }) => {
  const { cart, products, currencyCode = '' } = useContext(ShopifyContext);

  const contentfulProduct = products?.contentful[handle] ?? {};

  const buttonDisabled = maxQuantity === cart.lineItems[handle];
  return (
    <div className={cartItemWrapperCss}>
      <div className={cartItemImg(image)} />
      <div className={titleCss}>{contentfulProduct.title}</div>
      <Price className={priceCss} productPrice={price} />
      <div className={quantityWrapperCss}>
        <button
          onClick={() =>
            cart.removeOne({
              handle,
              contentfulProduct,
              shopifyProducts: products.shopify,
              currencyCode,
            })
          }
          className={quantityModificationCss}
        >
          <Icon name="remove" />
        </button>
        <span className={quantityCss}>{cart.lineItems[handle]}</span>
        <button
          disabled={buttonDisabled}
          onClick={() =>
            cart.addOne({
              handle,
              contentfulProduct,
              shopifyProducts: products.shopify,
              currencyCode,
            })
          }
          className={cx(quantityModificationCss, {
            [quantityModificationDisabledCss]: buttonDisabled,
          })}
        >
          <Icon name="add" />
        </button>
      </div>
      {atpMessage && <div className={atpCss}>{atpMessage}</div>}
    </div>
  );
};
