import { gql } from '@apollo/client';

import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';

export const contentfulProductsQuery = gql`
  query ShopifyProductsContentful($preview: Boolean!, $locale: String!) {
    shopifyProductCollection(preview: $preview, locale: $locale) {
      items {
        ...ContentfulSysId
        title
        productHandle
        checkoutHandle
        description {
          json
        }
        relatedItemsCollection(limit: 10) {
          items {
            ...ContentfulSysId
            productHandle
          }
        }
        accessoriesCollection(limit: 10) {
          items {
            ...ShopifyProduct
          }
        }
        bundleItemsCollection(limit: 10) {
          items {
            ...ShopifyProduct
          }
        }
      }
    }
  }

  fragment ShopifyProduct on ShopifyProduct {
    ...ContentfulSysId
    title
    productHandle
    checkoutHandle
    description {
      json
    }
  }
  ${contentfulSysIdFragment}
`;
