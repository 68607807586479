import { css } from '@emotion/css';
import type { FC } from 'react';

import type { ImageSources } from '../../types';
import { Picture } from '../Picture';

export const iconCss = css`
  height: 125px;
  width: 125px;
  display: block;
`;

type IconProps = {
  src?: string;
  imgSrcs?: ImageSources;
  imgAltText?: string;
};

export const MediaIcon: FC<IconProps> = ({ imgAltText, imgSrcs, src }) => {
  return <Picture altText={imgAltText} imgSrcs={imgSrcs} imgClassName={iconCss} defaultSrc={src} />;
};
