import { gql } from '@apollo/client';
import type { Criteria, CustomFooterSocialCollection } from '@snapchat/mw-contentful-schema';
import type { BackgroundColor } from '@snapchat/snap-design-system-marketing';
import type { ContentfulSysProps, ContentfulTypedSysProps } from 'src/types/contentful';
import type { Items } from 'src/types/Items';

import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';
import { criteriaFragment } from '../Experiment/experimentQuery';
import { fragments as imageFragments } from '../Image';
import type { ImageDataProps } from '../Image/types';
import { imageButtonFragment } from '../ImageButton/imageButtonQuery';

// =================================================================================================
// Footer Item Fragment
// =================================================================================================
export const footerItemFragment = gql`
  fragment FooterItemAll on FooterItem {
    ...ContentfulSysId
    title
    url
    analytics {
      ...ContentfulSysId
      label
    }
    criteria {
      ...CriteriaAll
    }
  }
  ${contentfulSysIdFragment}
  ${criteriaFragment}
`;

export type CustomFooterItemProps = ContentfulTypedSysProps<'FooterItem'> & {
  title: string;
  url: string;
  analytics?: { label: string; sys: { id: string } };
  criteria?: Criteria;
};

// =================================================================================================
// Footer Local Dropdown Fragment
// =================================================================================================
export const footerLocaleDropdownFragment = gql`
  fragment FooterLocaleDropdownAll on FooterLocaleDropdown {
    ...ContentfulSysId
    title
    criteria {
      ...CriteriaAll
    }
  }
  ${contentfulSysIdFragment}
  ${criteriaFragment}
`;

export type CustomFooterLocaleDropdownProps = ContentfulSysProps & {
  __typename: 'FooterLocaleDropdown';
  title: string;
  criteria?: Criteria;
};

// =================================================================================================
// Footer Cookie Settings Link Fragment
// =================================================================================================
export const footerCookiesSettingsLinkFragment = gql`
  fragment FooterCookiesSettingsLinkAll on FooterCookiesSettingsLink {
    ...ContentfulSysId
    title
    criteria {
      ...CriteriaAll
    }
  }
  ${contentfulSysIdFragment}
  ${criteriaFragment}
`;

export type CustomFooterCookiesSettingsLinkProps = ContentfulSysProps & {
  __typename: 'FooterCookiesSettingsLink';
  title: string;
  criteria?: Criteria;
};

// =================================================================================================
// Footer Group Link Fragment
// =================================================================================================
export const footerGroupFragment = gql`
  fragment FooterGroupAll on FooterGroup {
    ...ContentfulSysId
    __typename
    title
    groupKey
    itemsCollection(limit: 10) {
      items {
        __typename
        ... on FooterItem {
          ...FooterItemAll
        }

        ... on FooterLocaleDropdown {
          ...FooterLocaleDropdownAll
        }

        ... on FooterCookiesSettingsLink {
          ...FooterCookiesSettingsLinkAll
        }
      }
    }
    analytics {
      ...ContentfulSysId
      label
    }
    criteria {
      ...CriteriaAll
    }
  }
  ${contentfulSysIdFragment}
  ${footerItemFragment}
  ${footerLocaleDropdownFragment}
  ${footerCookiesSettingsLinkFragment}
  ${criteriaFragment}
`;

type CustomFooterGroupItemsCollectionProps = {
  items: Array<
    CustomFooterItemProps | CustomFooterLocaleDropdownProps | CustomFooterCookiesSettingsLinkProps
  >;
};

export type CustomFooterGroupProps = {
  sys: { id: string };
  title: string;
  groupKey: string;
  itemsCollection: CustomFooterGroupItemsCollectionProps;
  analytics?: { label: string };
  criteria?: Criteria;
};

// =================================================================================================
// Custom FooterFragment
// =================================================================================================
export const customFooterFragment = gql`
  fragment CustomFooterAll on CustomFooter {
    ...ContentfulSysId
    columnsCollection(limit: 10) {
      items {
        ... on FooterGroup {
          ...FooterGroupAll
        }
      }
    }
    barCollection(limit: 10) {
      items {
        ... on FooterGroup {
          ...FooterGroupAll
        }
      }
    }
    backgroundColor
    logo {
      ...ImageAll
    }
    url
    socialLabel
    socialCollection(limit: 10) {
      items {
        ... on ImageButton {
          ...ImageButtonAll
        }
      }
    }
  }
  ${contentfulSysIdFragment}
  ${imageButtonFragment}
  ${imageFragments.all}
  ${footerGroupFragment}
`;

// =================================================================================================
// Footer Query
// =================================================================================================
export const customFooterQuery = gql`
  query FooterQuery($preview: Boolean!, $locale: String!) {
    customFooterCollection(preview: $preview, locale: $locale, limit: 1) {
      items {
        ...CustomFooterAll
      }
    }
  }

  ${customFooterFragment}
`;

export type FooterLogoItemProps = ImageDataProps & {
  url?: string;
};

export type FooterQueryResponse = {
  customFooterCollection: ContentfulSysProps & {
    items: Array<{
      columnsCollection: Items<CustomFooterGroupProps>;
      barCollection: Items<CustomFooterGroupProps>;
      socialCollection: CustomFooterSocialCollection;
      backgroundColor: BackgroundColor;
      logo: FooterLogoItemProps | null;
      url: string | null;
      socialLabel: string | null | undefined;
    }>;
  };
};
