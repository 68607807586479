import { gql } from '@apollo/client';
import type { Criteria, Experiment } from '@snapchat/mw-contentful-schema';

import type { ContentfulTypedSysProps } from '../../types/contentful';
import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';
import { createExperimentFragmentNoAb } from '../Experiment/experimentQuery';
import { pageNavigationFragment } from '../Page/pageQuery';
import type { PageNavigationShallowDataProps } from '../Page/types';

export const pageSideNavbarQuery = gql`
  query AllPageSideNavQuery($preview: Boolean!, $locale: String!, $limit: Int, $skip: Int) {
    slugCollection(
      limit: $limit
      skip: $skip
      preview: $preview
      locale: $locale
      where: { page_exists: true }
    ) {
      total
      items {
        ...ContentfulSysId
        slug
        page {
          ...ExperimentOnPageLoadingPageNavigationShallow
        }
        unlistedCriteria {
          ...CriteriaAll
        }
      }
    }
  }
  ${contentfulSysIdFragment}
  ${createExperimentFragmentNoAb('Page', 'PageNavigationShallow', pageNavigationFragment)}
`;

export type PageNavigationData = {
  slugCollection: {
    total: number;
    items: Array<
      ContentfulTypedSysProps<'Slug'> & {
        slug: string;
        // NOTE: This is not a true experiment, but only the ExperimentDefaultReference.
        // TODO: Fix the types. Currently we don't support experimentation on the side nav-level.
        page: PageNavigationShallowDataProps | Experiment;
        unlistedCriteria?: Criteria;
      }
    >;
  };
};
