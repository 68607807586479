import { cx } from '@emotion/css';
import type { FC } from 'react';
import { useCallback, useContext, useEffect, useRef } from 'react';

import { ToggleTarget } from '../../../hooks/useToggleState';
import { m } from '../../../motif';
import { DetailsSummary } from '../../DetailsSummary';
import { AccordionContext } from '../AccordionContext';
import { accordionItemCss, itemChevronCss, summaryCss } from './styles';
import type { AccordionItemProps } from './types';

// TODO: Move these into constants
const duration = 400;
const easing = 'ease-out';

export const AccordionItem: FC<AccordionItemProps> = ({
  id,
  title,
  children,
  titleDataset,
  className,
  summaryClassName,
}) => {
  const { multipleOpen, openIds, setOpenIds } = useContext(AccordionContext);

  const detailsRef = useRef<HTMLDetailsElement | null>(null);
  const summaryRef = useRef<HTMLElement | null>(null);

  const animation = useRef<Animation | null>(null);
  const isClosing = useRef(false);
  const isExpanding = useRef(false);

  const isOpen = openIds.includes(id);

  // syncs the intial state of the two components per DetailsSummary comment on line 81
  useEffect(() => {
    if (!detailsRef.current) {
      return;
    }

    if (isOpen !== detailsRef.current.open) {
      detailsRef.current.open = isOpen;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shrink = useCallback(() => {
    isClosing.current = true;

    if (!detailsRef?.current || !summaryRef?.current) {
      return;
    }

    detailsRef.current.style.overflow = 'hidden';

    const startHeight = `${detailsRef.current.offsetHeight}px`;
    const endHeight = `${summaryRef.current.offsetHeight}px`;

    if (animation.current) {
      animation.current.cancel();
    }

    animation.current = detailsRef.current.animate?.(
      { height: [startHeight, endHeight] },
      { duration, easing }
    );

    animation.current?.finished
      .then(() => onAnimationFinish(false))
      .catch(() => {
        isClosing.current = false;
      });
  }, []);

  // syncs visual state with actual state if item is closed by another source (e.g. multiple open not allowed, anotehr item opened)
  useEffect(() => {
    if (isOpen) {
      return;
    }

    if (detailsRef.current?.open) {
      shrink();
    }
  }, [isOpen, shrink]);

  const expand = useCallback(() => {
    isExpanding.current = true;

    if (!detailsRef.current || !summaryRef.current) {
      return;
    }

    detailsRef.current.style.overflow = 'hidden';

    const startHeight = `${detailsRef.current.offsetHeight}px`;
    const endHeight = `${
      summaryRef.current.offsetHeight +
      detailsRef.current.scrollHeight -
      summaryRef.current.offsetHeight
    }px`;

    if (animation.current) {
      animation.current.cancel();
    }

    animation.current = detailsRef.current.animate?.(
      { height: [startHeight, endHeight] },
      { duration, easing }
    );

    animation.current?.finished
      .then(() => onAnimationFinish(true))
      .catch(() => {
        isExpanding.current = false;
      });
  }, []);

  const open = useCallback(() => {
    if (!detailsRef.current) {
      return;
    }

    detailsRef.current.style.height = `${detailsRef.current.offsetHeight}px`;
    detailsRef.current.open = true;
    window.requestAnimationFrame(expand);
  }, [expand]);

  const onToggle = useCallback(
    (toggle: ToggleTarget) => {
      if (multipleOpen) {
        setOpenIds(toggle === ToggleTarget.OFF ? openIds.filter(o => o !== id) : [...openIds, id]);
      } else {
        setOpenIds(toggle === ToggleTarget.OFF ? [] : [id]);
      }

      if (!detailsRef.current) {
        return;
      }

      if (toggle === ToggleTarget.ON) {
        open();
      } else {
        shrink();
      }
    },
    [multipleOpen, setOpenIds, openIds, id, open, shrink]
  );

  const onAnimationFinish = (open: boolean) => {
    if (!detailsRef.current) {
      return;
    }

    detailsRef.current.open = open;
    detailsRef.current.style.height = '';
    detailsRef.current.style.overflow = '';
    isExpanding.current = false;
    isClosing.current = false;
    animation.current = null;
  };

  return (
    <DetailsSummary
      detailsRef={detailsRef}
      summaryRef={summaryRef}
      summary={title}
      chevronProps={{ className: itemChevronCss, fill: m('--icon-color') }}
      className={cx(accordionItemCss, className)}
      onToggle={onToggle}
      summaryProps={{
        dataset: titleDataset,
        className: cx(summaryCss, summaryClassName),
      }}
      transitionDurationMs={duration}
      open={isOpen}
    >
      {children}
    </DetailsSummary>
  );
};
