import { css } from '@emotion/css';
import {
  BackgroundColor,
  Black,
  getBackgroundClassName,
  Gray,
  h5Css,
  m,
  mobileMediaQuery,
  p1Css,
  smallMobileMediaQuery,
} from '@snapchat/snap-design-system-marketing';

/* Styles copied from: marketing-web/packages/web/src/index.ejs */
const normalizeCss = css`
  font-family: 'Graphik', Helvetica, sans-serif;

  * {
    box-sizing: border-box;
    margin: 0;
  }
`;

/* Standardize UI when rendered outside of MWP */
export const globalCss = css`
  ${normalizeCss}

  /* override site settings that override header behaviors */
  h1,h2,h3,h4,h5,h6 {
    font-family: 'Graphik', Helvetica, sans-serif;
    text-transform: initial;
    line-height: inherit;
  }

  /* Ensure consistent rendering of lists */
  ul {
    list-style: disc outside;
  }
`;

const cookieModalHeaderCss = css`
  .modal-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: ${m('--spacing-xs')};

    .logo-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }
`;

const cookieModalRichTextCss = css`
  .contentful-rich-text {
    font-weight: 400;
    line-height: 26px;

    h3 {
      font-size: 1.17em;
    }

    a {
      color: inherit;
      font-weight: 500;
    }
    > ul {
      padding-left: ${m('--spacing-l')};
      margin-bottom: ${m('--spacing-s')};
    }
    > ul > li {
      margin-bottom: ${m('--spacing-xxs')};
    }
    > p {
      margin: 0 0 ${m('--spacing-xxs')};
    }
    *[dir='rtl'] & {
      > ul {
        padding-left: unset;
        padding-right: ${m('--spacing-l')};
      }
    }
  }
`;

/**
 * Dark mode settings are loaded separately so they can access the classnames from parent SDS-M
 * Content components
 */
const darkModeCss = css`
  .${getBackgroundClassName(BackgroundColor.Black)} .category-border.category-border {
    /* Hardcoding Enum colors because we don't have a relevant CSS variable to use. */
    border: 1px solid ${Black.V50};
  }
`;

const cookieModalSettingsScreenCss = css`
  .category-border {
    /* Hardcoding Enum colors because we don't have a relevant CSS variable to use. */
    border: 1px solid ${Gray.V50};
    border-radius: ${m('--border-radius-m')};
    padding: ${m('--spacing-s')};
    margin-bottom: ${m('--spacing-s')};
  }

  .category-title-container {
    display: flex;
    align-items: center;
    margin-bottom: ${m('--spacing-xs')};
  }
  .category-title {
    flex: 1;

    ${p1Css}

    *[dir='rtl'] & {
      text-align: right;
    }
  }
  .category-status {
    font-size: 14px;
    margin-right: ${m('--spacing-xs')};
    *[dir='rtl'] & {
      margin-right: unset;
      margin-left: ${m('--spacing-xs')};
    }
  }
  .category-toggle {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
`;

const cookieModalFooterCss = css`
  .modal-footer {
    padding-top: ${m('--spacing-s')}; /* Padding set by design */
    text-align: center;
    display: flex;
    /* Reverse direction so that when we wrap on smaller screens, the break happens on 1st, rather than last element.
       NOTE: requires that content be rendered in reverse order */
    flex-direction: row-reverse;
    gap: ${m('--spacing-s')};
    align-self: center;
    justify-content: center;

    /* Button spacing managed via gap instead of margins */
    .sdsm-button {
      margin: 0;
    }

    ${mobileMediaQuery} {
      flex-wrap: wrap;
      width: 100%;
    }

    ${smallMobileMediaQuery} {
      /* See note above, accounts for content rendered in reverse order. */
      flex-direction: column-reverse;
      align-items: center;
    }
  }
`;

const settingsPageFooterCss = css`
  .settings-page-footer {
    padding-top: ${m('--spacing-xl')};
    display: flex;
    flex-direction: row;
    gap: ${m('--spacing-s')};
    align-self: center;
    justify-content: center;

    /* Button spacing managed via gap instead of margins */
    .sdsm-button {
      margin: 0;
    }

    ${smallMobileMediaQuery} {
      padding-top: unset;
      flex-direction: column;
    }
  }
`;

export const contentCss = css`
  /* card style. */
  background-color: ${m('--background-color')};
  color: ${m('--foreground-color')};
  border-radius: ${m('--border-radius-m')};
  padding: ${m('--spacing-m')};
  border: 1px #0003 solid;
  box-shadow: ${m('--box-shadow-l')};
`;

export const cookieModalCss = css`
  --padding-horizontal: ${m('--spacing-xl')};
  --padding-vertical: ${m('--spacing-l')};

  ${mobileMediaQuery} {
    --padding-horizontal: ${m('--spacing-s')};
    --padding-vertical: ${m('--spacing-xs')};
  }
  --max-height: calc(100vh - var(--padding-vertical));
  /* stylelint-disable-next-line declaration-block-no-duplicate-custom-properties */
  --max-height: calc(100dvh - var(--padding-vertical));

  .sdsm-modal-content {
    ${contentCss}
    padding: var(--padding-vertical) var(--padding-horizontal);
    width: max-content;

    /* stop-gap solution to prevent any part from being hidden */
    overflow: auto;

    /* prevents overflow of this container. */
    max-height: 100%;
    max-width: 100%;
  }

  .cookie-landing-screen,
  .cookie-settings-screen {
    max-height: calc(var(--max-height) - calc(2 * calc(1px + var(--padding-vertical))));

    display: flex;
    flex-direction: column;

    /* Header Styles */
    ${cookieModalHeaderCss}

    /* Body Styles */
    .modal-body {
      flex: 1;
      overflow-y: auto;
      min-height: 5em;
      margin-bottom: ${m('--spacing-xs')};
      padding-right: ${m('--spacing-xs')};

      *[dir='rtl'] & {
        padding-right: unset;
        padding-left: ${m('--spacing-xs')};
      }

      .cookie-title {
        ${h5Css}

        margin-bottom: ${m('--spacing-m')};
      }

      h3,
      b {
        font-weight: 500;
      }

      ${cookieModalSettingsScreenCss}

      ${cookieModalRichTextCss}
    }

    /* Footer Styles */
    ${cookieModalFooterCss}
  }

  /* Dark Mode Styles */
  ${darkModeCss}
`;

export const cookieSettingsPageCss = css`
  /* Body Styles */
  .settings-page-body {
    text-align: left;

    .cookie-title {
      ${h5Css}

      margin-bottom: ${m('--spacing-m')};
    }

    ${cookieModalSettingsScreenCss}

    ${cookieModalRichTextCss}
  }

  /* Footer Styles */
  ${settingsPageFooterCss}

  /* Dark Mode Styles */
  ${darkModeCss}
`;
