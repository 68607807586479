import { cx } from '@emotion/css';
import { ParentSize } from '@visx/responsive';
import type { ReactElement } from 'react';

import { MotifComponent, useMotifStyles } from '../../../motif';
import type { BarGroupProps } from './BarGroup';
import { BarGroup } from './BarGroup';
import type { StackedBarGroupProps } from './StackedBarGroup';
import { StackedBarGroup } from './StackedBarGroup';
import { titleCss, wrapperCss } from './styles';
import {
  chartWrapperPadding,
  defaultMargin,
  minimumDesktopChartSizePx,
  minimumMobileChartSizePx,
  toLowercaseAndTrim,
} from './utils/helpers';
import type { BarChartProps, BarData, BarItem } from './utils/types';

type Filter = {
  key: string;
  value: string;
};

export const BarChart = (props: BarChartProps): ReactElement => {
  const { stackKey, chartTitle, data, filters } = props;

  useMotifStyles(MotifComponent.BAR_CHART);

  return (
    <ParentSize parentSizeStyles={{ width: '' }} className={cx(MotifComponent.BAR_CHART)}>
      {parent => {
        // To prevent layout shift, don't render anything until parent width is established
        // (i.e. parent width is non-zero)
        if (parent.width <= 0) return;

        const width = getChartWidth(parent.width, props.width);
        const leftPosition =
          props.hideYAxisLabels && props.hideXAxisLabels
            ? defaultMargin.left
            : defaultMargin.leftWhenNoYAxisLabels;
        const xMax = width - leftPosition - defaultMargin.right;
        const yMax = props.height - defaultMargin.top - defaultMargin.bottom;
        const xScaleTicks = width < minimumDesktopChartSizePx ? 3 : 5;

        const updatedProps = {
          ...props,
          data: filterData(data, filters),
          width,
          height: props.height,
          xMax,
          yMax,
          leftPosition,
          xScaleTicks,
        };

        return (
          <div className={wrapperCss}>
            {chartTitle && <h2 className={titleCss}>{chartTitle}</h2>}
            {stackKey ? (
              <StackedBarGroup {...(updatedProps as StackedBarGroupProps)} />
            ) : (
              <BarGroup {...(updatedProps as BarGroupProps)} />
            )}
          </div>
        );
      }}
    </ParentSize>
  );
};

function filterData(data: BarData, filtersInput: string[] = []) {
  if (!filtersInput?.length) {
    return data;
  }

  const filters = filtersInput.map(createFilter).filter(removeInvalidFilters) as Filter[];

  const filtersGroupedByKey: Record<string, string[]> = filters.reduce((result, filter) => {
    const isFilterKeyInResultData = !!result[filter.key];
    const newFilterValue = toLowercaseAndTrim(filter.value);

    if (isFilterKeyInResultData) {
      result[filter.key]!.push(newFilterValue);
    } else {
      result[filter.key] = [newFilterValue];
    }

    return result;
  }, {} as Record<string, string[]>);

  const itemMatchFilters = (item: BarItem) => {
    const itemMatchAllFilters = filters.every(filter => {
      const itemValue = toLowercaseAndTrim(item[filter.key]);
      const match = filtersGroupedByKey[filter.key]!.includes(itemValue);
      return match;
    });

    return itemMatchAllFilters;
  };

  const filteredData = data.filter(itemMatchFilters);

  return filteredData;
}

function createFilter(filterStr = ''): Filter | undefined {
  const [key, value] = filterStr.split('=');

  if (!key || !value) {
    return;
  }

  const newFilter: Filter = { key, value };

  return newFilter;
}

function removeInvalidFilters(filter?: Filter): boolean {
  return !!filter;
}

function getChartWidth(parentWidth: number, inputWidth?: number) {
  if (inputWidth && inputWidth < parentWidth) {
    return inputWidth;
  }

  if (parentWidth > minimumMobileChartSizePx) {
    const viewport = parentWidth < minimumDesktopChartSizePx ? 'mobile' : 'desktop';
    return parentWidth - chartWrapperPadding[viewport];
  }

  return minimumMobileChartSizePx;
}
