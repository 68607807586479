import type { FC } from 'react';

import type { GenericSvgIconProps } from '../CustomIcon';

export const HoverIcon: FC<GenericSvgIconProps> = props => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M12.80 0.59C10.43 0.58 8.11 1.30 6.13 2.65C4.16 3.99 2.62 5.91 1.71 8.15C0.80 10.39 0.56 12.85 1.02 15.23C1.49 17.61 2.63 19.80 4.30 21.51C5.98 23.23 8.12 24.40 10.45 24.87C12.77 25.34 15.18 25.10 17.38 24.17C19.57 23.25 21.44 21.68 22.76 19.66C24.08 17.64 24.78 15.27 24.78 12.85C24.78 9.60 23.52 6.48 21.27 4.18C19.03 1.88 15.98 0.59 12.80 0.59ZM12.72 4.22C13.46 4.22 14.19 4.45 14.80 4.87C15.42 5.29 15.90 5.89 16.19 6.59C16.47 7.29 16.55 8.07 16.40 8.81C16.26 9.56 15.90 10.24 15.38 10.78C14.85 11.32 14.18 11.68 13.45 11.83C12.72 11.98 11.97 11.90 11.28 11.61C10.59 11.32 10.01 10.83 9.59 10.20C9.18 9.57 8.96 8.82 8.96 8.06C8.96 7.05 9.36 6.07 10.06 5.35C10.76 4.63 11.72 4.22 12.72 4.22ZM12.76 18.97C8.15 18.97 4.41 18.54 4.41 18.02C4.41 17.49 8.15 17.07 12.76 17.07C17.38 17.07 21.11 17.49 21.11 18.02C21.11 18.55 17.38 18.97 12.76 18.97Z"
      />
    </svg>
  );
};
