import { css, keyframes } from '@emotion/css';

// TODO: Optimization: Inject the keyframe into global once.

export interface AnimationProps {
  duration?: string;
  curve?: string;
  delay?: string;
  iterationCount?: number;
  direction?: 'normal' | 'reverse' | 'alternate' | 'alternate-reverse';
  fillMode?: 'forwards' | 'backwards' | 'both';
  playState?: 'paused' | 'running';
}

/** Keyframes for fading in an element. */
export const fadeInKeyframes = keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
`;

/** Keyframes for fading out an element. */
export const fadeOutKeyframes = keyframes`
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
`;

const animationPropsToCssProp = (props: AnimationProps): string => {
  const keysInOrder: Array<keyof AnimationProps> = [
    'duration',
    'curve',
    'delay',
    'iterationCount',
    'direction',
    'fillMode',
    'playState',
  ];
  const valuesInOrder = keysInOrder.map(key => props[key]).filter(value => !!value);
  return valuesInOrder.join(' ');
};

export const opacityFadeInCss = (
  animationProps: AnimationProps = { duration: '250ms', curve: 'ease-out' }
): string => css`
  animation: ${fadeInKeyframes} ${animationPropsToCssProp(animationProps)};
`;

export const opacityFadeOutCss = (
  animationProps: AnimationProps = { duration: '250ms', curve: 'ease-out' }
): string => css`
  animation: ${fadeOutKeyframes} ${animationPropsToCssProp(animationProps)};
`;

export const rotateCss = (
  startDegrees = 0,
  endDegrees = 360,
  animationProps: AnimationProps = { duration: '250ms', curve: 'linear' }
): string => css`
  @keyframes rotate {
    0% {
      transform: rotate(${startDegrees}deg);
    }
    100% {
      transform: rotate(${endDegrees}deg);
    }
  }

  animation: rotate ${animationPropsToCssProp(animationProps)};
`;

/** Rip of android's FastOutSlowInInterpolator curve. See https://cubic-bezier.com/#.4,0,.2,1 */
export const androidFastOutSlowInCurve = 'cubic-bezier(0.4, 0.0, 0.2, 1)';
/** Rip of android's LinearOutSlowInInterpolator curve. See https://cubic-bezier.com/#0,0,.2,1 */
export const androidLinearOutSlowIn = 'cubic-bezier(0.0, 0.0, 0.2, 1)';

export const slideUpCss = (
  animationProps: AnimationProps = { duration: '250ms', curve: androidFastOutSlowInCurve }
): string => css`
  @keyframes slideUp {
    0% {
      transform: translateY(100vh);
    }
    100% {
      transform: translateY(0);
    }
  }

  animation: slideUp ${animationPropsToCssProp(animationProps)};
`;

export const slideDownCss = (
  animationProps: AnimationProps = {
    duration: '250ms',
    curve: androidLinearOutSlowIn,
  }
): string => css`
  @keyframes slideDown {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(100vh);
    }
  }

  animation: slideDown ${animationPropsToCssProp(animationProps)};
`;
