import { css } from '@emotion/css';

import { nonMobileMediaQuery, White } from '../../constants';
import { ZIndex } from '../../constants/zIndex';
import { m } from '../../motif/m';

export const panelColorsCss = css`
  background-color: ${m('--background-color')};
  color: ${m('--foreground-color')};
`;

export const swapPanelsCss = css`
  order: 1;
`;

export const splitPanelContainerCss = css`
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: row;
  min-height: 100%;
  position: relative;
  width: 100%;
  height: 100%;

  article {
    overflow: auto;
  }

  ${nonMobileMediaQuery} {
    grid-auto-flow: column;
  }
`;

export const showCurtainTextColorCss = css`
  color: ${White};
`;

export const curtainCss = css`
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: black;
    opacity: 0.7;
  }
`;

export const childPanelCss = css`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 32px 46px;
  position: relative;
  width: 100%;
  min-height: 275px;

  > * {
    z-index: ${ZIndex.SPLIT_PANEL_CURTAIN};
    width: 100%;
  }

  ${nonMobileMediaQuery} {
    padding: 64px;
  }
`;
