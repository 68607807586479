import Cookies from 'js-cookie';

import type { CookieCategoryType } from '../generated/contentful-types';

export const getCookie = (name: string): string | undefined => {
  return Cookies.get(name);
};

/**
 * Function that retrieves Category Cookie values (e.g. Marketing, Performance, etc. cookies).
 * Converts values to boolean to represent whether user opted in for that category.
 *
 * @param categories Array of Cookie Categories
 * @returns Record where key is the cookie category name, value is the boolean of whether a user has
 *   opted in.
 */
export const getAllCookies = (
  categories: readonly CookieCategoryType[]
): Record<string, boolean> => {
  const output: Record<string, boolean> = {};

  for (const category of categories) {
    const { categoryCookieName } = category;

    // Only set value if cookie exists
    const cookieValue = getCookie(categoryCookieName);

    if (cookieValue) {
      output[categoryCookieName] = cookieValue === 'true';
    }
  }

  return output;
};
