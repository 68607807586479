import { Black, Gray, Plain } from '../../constants';
import type { MotifComponent, MotifComponentProps } from '../../motif';

export const sideOverlayYellowBgVars: MotifComponentProps<MotifComponent.SIDE_OVERLAY> = {
  '--side-overlay-bg-color': Gray.V50,
  '--side-overlay-border-color': Gray.V250,
  '--side-overlay-fg-color': Plain.BLACK,
} as const;

export const sideOverlayBlackBgVars: MotifComponentProps<MotifComponent.SIDE_OVERLAY> = {
  '--side-overlay-bg-color': Black.V125,
  '--side-overlay-border-color': Black.V100,
  '--side-overlay-fg-color': Plain.WHITE,
} as const;

export const sideOverlayWhiteBgVars: MotifComponentProps<MotifComponent.SIDE_OVERLAY> = {
  '--side-overlay-bg-color': Gray.V50,
  '--side-overlay-border-color': Gray.V150,
  '--side-overlay-fg-color': Plain.BLACK,
} as const;

export const sideOverlayGrayBgVars: MotifComponentProps<MotifComponent.SIDE_OVERLAY> = {
  '--side-overlay-bg-color': Gray.V50,
  '--side-overlay-border-color': Gray.V250,
  '--side-overlay-fg-color': Plain.BLACK,
} as const;
