import { onCLS, onFID, onINP, onLCP } from 'web-vitals';

import { logTiming, logValue } from '../helpers/logging';

/**
 * Init gathering of Web Vitals, specifically LCP (Largest Contentful Paint), CLS (Cumulative Layout
 * Shift), and FID (First Input Delay).
 */
export const initWebVitalsLogging = (): void => {
  const category = 'WebVitals';

  onCLS(metric =>
    logValue({
      eventValue: metric.value,
      eventLabel: window.location.pathname,
      eventVariable: 'cumulative_layout_shift',
      eventCategory: category,
    })
  );

  onFID(metric =>
    logTiming({
      eventValue: metric.value,
      eventLabel: window.location.pathname,
      eventVariable: 'first_input_delay',
      eventCategory: category,
    })
  );

  onLCP(metric =>
    logTiming({
      eventValue: metric.value,
      eventLabel: window.location.pathname,
      eventVariable: 'largest_contentful_paint',
      eventCategory: category,
    })
  );

  onINP(metric => {
    logTiming({
      eventValue: metric.value,
      eventLabel: window.location.pathname,
      eventVariable: 'interaction_to_next_paint',
      eventCategory: category,
    });
  });
};
