import { cx } from '@emotion/css';
import type { FC, ReactElement } from 'react';

import { MotifComponent, useMotifStyles } from '../../motif';
import type { TileProps } from '../Tile';
import { galleryV2Css } from './styles';

export interface GalleryGridV2Props {
  children?: ReactElement<TileProps> | Array<ReactElement<TileProps>>;
}

export const GalleryGridV2: FC<GalleryGridV2Props> = ({ children }) => {
  useMotifStyles(MotifComponent.GALLERY);

  return (
    <div data-test-id="sdsm-gallery-grid" className={cx(MotifComponent.GALLERY, galleryV2Css)}>
      {children}
    </div>
  );
};

GalleryGridV2.displayName = 'GalleryGridV2';
