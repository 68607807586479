import { css } from '@emotion/css';

import { m } from '../../../motif/m';

export const navigatorItemLinkCss = css`
  background-color: transparent;
  border-radius: ${m('--border-radius-m')};
  color: ${m('--global-header-menu-item-fg-color')};
  cursor: pointer;
  display: flex;
  gap: 8px;
  padding: 16px;
  text-decoration: none;
  transition: background-color 0.2s linear;
  width: 100%;

  :hover {
    background-color: ${m('--global-header-menu-item-bg-hover-color')};
  }
`;

export const navigatorItemLinkIconCss = css`
  && {
    margin: 1px 0 0 0;
    min-width: 24px;
  }
`;

export const navigatorItemLinkTextCss = css`
  && {
    display: flex;
    flex-direction: column;
    gap: ${m('--spacing-xs')};
    margin: 0;

    span {
      margin: 0;
    }
  }
`;

export const navigatorItemLinkTextTitleCss = css`
  && {
    font-size: ${m('--p2-desktop-font-size')};
    font-weight: 500;
    line-height: ${m('--p2-desktop-font-line-height')};
    margin: 0;
  }
`;

export const navigatorItemLinkTextDescriptionCss = css`
  && {
    font-size: ${m('--p3-desktop-font-size')};
    font-weight: 400;
    line-height: ${m('--p3-desktop-font-line-height')};
    margin: 0;
  }
`;

export const navigatorItemLinkSpacerCss = css`
  && {
    font-size: ${m('--p3-desktop-font-size')};
    font-weight: 400;
    line-height: ${m('--p3-desktop-font-line-height')};
    margin: 0 0 ${m('--spacing-xs')} 0;
  }
`;
