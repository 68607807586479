import type { FC, PropsWithChildren } from 'react';
import { memo, useContext } from 'react';
import { createPortal } from 'react-dom';

import { AppContext } from '../../AppContext';

// eslint-disable-next-line import/no-unused-modules
export const pageBottomStickyPortalId = 'mwp-page-bottom-sticky-portal';

/**
 * Portal for rendering content at the bottom of the page in a persistent/sticky manner.
 *
 * Use as return React.createPortal(<element>, pageBottomStickyPortalId)
 */
export const PageBottomStickyPortal: FC<{ className?: string }> = memo(props => {
  const { pageBottomStickyPortalRef } = useContext(AppContext);

  return (
    <aside
      className={props.className}
      data-testid={pageBottomStickyPortalId}
      ref={pageBottomStickyPortalRef}
    />
  );
});

/**
 * Easy insertion point into the bottom of the page (but sticks as the user scrolls).
 *
 * Use as <IntoPageBottomStickyPortal>{.. your stuff ..}</IntoPageBottomStickyPortal>
 *
 * NOTE: The contently only gets inserted on the client. Nothing displays on the server.
 */
export const IntoPageBottomStickyPortal: FC<PropsWithChildren> = ({ children }) => {
  const { pageBottomStickyPortalRef } = useContext(AppContext);

  return pageBottomStickyPortalRef?.current
    ? createPortal(children, pageBottomStickyPortalRef!.current)
    : null;
};
