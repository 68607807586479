import type { BackgroundColor } from '../../constants';
import type { BaseComponentProps } from '../../types';

export interface SideNavigationProps extends BaseComponentProps {
  items: Array<SideNavigationSectionProps>;
  isUrlCurrent?: (url: string) => boolean;
  backgroundColor?: BackgroundColor;
  mobileBackgroundColor?: BackgroundColor;
}

export interface SideNavigationSectionProps extends BaseComponentProps {
  title: string;
  links: Array<SideNavigationItemProps>;
}

export interface SideNavigationItemProps extends BaseComponentProps {
  title: string;
  url: string;
  inNewTab?: boolean;
}

export interface SideNavigationLinksProps extends BaseComponentProps {
  links: Array<SideNavigationItemProps>;
  isUrlCurrent?: (url: string) => boolean;
  focusSection: VoidFunction;
  isRTL: () => boolean;
}
