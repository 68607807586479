import type { MotifComponent, MotifComponentProps } from '../../motif';
import { m } from '../../motif/m';

export const contentVars: MotifComponentProps<MotifComponent.CONTENT> = {
  '--content-desktop-grid-gap': m('--spacing-l'),
  '--content-mobile-grid-gap': m('--spacing-m'),
  '--content-subtitle-color': m('--foreground-color'),
  '--content-title-color': m('--foreground-color'),
  '--content-backdrop-filter': 'none',
  '--content-border-width': '0',
  '--content-border-color': 'unset',
  '--content-box-shadow': 'none',
  '--content-mobile-no-bg-padding': '0',
  '--content-desktop-no-bg-padding': `${m('--spacing-m')} ${m('--spacing-xl')}`,
};
