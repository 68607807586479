import type { FC } from 'react';

import { Icon } from '../Icon';
import { arrowCss } from './styles';
import type { TabDirection } from './types';

export interface TabArrowsProps {
  direction: TabDirection;
  onClick?: () => void;
  isDisabled: boolean;
}

/**
 * Returns the tab arrow components.
 *
 * @param direction - Direction that the arrow is pointing to
 * @param onClick - Handles arrow clicks
 * @param isDisabled - Checks if the arrows are enabled or disabled
 */
export const TabArrows: FC<TabArrowsProps> = ({ direction, onClick, isDisabled }) => {
  const iconName = direction === 'Left' ? 'chevron-left' : 'chevron-right';
  return (
    <button disabled={isDisabled} className={arrowCss} onClick={onClick}>
      <Icon name={iconName} size={22} />
    </button>
  );
};
