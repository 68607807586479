import { Gray, Plain } from '../../constants';
import type { MotifComponent, MotifComponentProps } from '../../motif';

export const quoteGrayBgVars: MotifComponentProps<MotifComponent.QUOTE> = {
  '--quote-bg-color': Gray.V100,
  '--quote-fg-color': Plain.BLACK,
  '--quote-author-desktop-font-size': '16px',
  '--quote-author-desktop-font-weight': '500',
  '--quote-author-mobile-font-size': '16px',
  '--quote-author-mobile-font-weight': '500',
};

export const quoteWhiteBgVars: MotifComponentProps<MotifComponent.QUOTE> = {
  '--quote-bg-color': Plain.WHITE,
  '--quote-fg-color': Plain.BLACK,
  '--quote-author-desktop-font-size': '16px',
  '--quote-author-desktop-font-weight': '500',
  '--quote-author-mobile-font-size': '16px',
  '--quote-author-mobile-font-weight': '500',
};
