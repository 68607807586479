import {
  dataSetToAttributes,
  GalleryGridV2 as GalleryGridV2SDS,
  SkeletonSummaryCard,
} from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import { useContentfulQuery } from '../../hooks/useContentfulQuery';
import { SummaryCard } from '../SummaryCard';
import { defaultTilesPerPage } from './constants';
import type { TileQueryResponse } from './query';
import { tileCollectionQuery } from './query';
import { galleryPlaceholderCss } from './styles';
import type { LazyTilesProps } from './types';

export const LazyTiles: FC<LazyTilesProps> = ({
  emptyPlaceholder,
  tiles,
  emptyPlaceholderDataset,
  showDates,
  showDescriptions,
  showMedia,
  isGalleryLoading,
  defaultTileImage,
  numberOfTiles,
}) => {
  const tileIds = tiles?.map(tile => tile.tileSysId) ?? [];

  const { data, loading } = useContentfulQuery<TileQueryResponse, { ids: string[] }>(
    tileCollectionQuery,
    {
      variables: { ids: tileIds },
    }
  );

  const sortedItems = data?.tileCollection?.items
    .slice()
    .sort((a, b) => tileIds.indexOf(a.sys.id) - tileIds.indexOf(b.sys.id));

  if (isGalleryLoading || loading) {
    return (
      <GalleryGridV2SDS>
        {Array.from({ length: numberOfTiles ?? defaultTilesPerPage }).map((_, index) => (
          <SkeletonSummaryCard key={`skeleton-summary-card-${index}`} />
        ))}
      </GalleryGridV2SDS>
    );
  }

  return (
    <>
      {sortedItems?.length ? (
        <GalleryGridV2SDS>
          {sortedItems.map(tile => {
            return (
              <SummaryCard
                key={tile.sys.id}
                showDates={showDates}
                showDescriptions={showDescriptions}
                showMedia={showMedia}
                {...tile}
                media={tile.media ?? defaultTileImage}
              />
            );
          })}
        </GalleryGridV2SDS>
      ) : (
        <div data-test-id="mwp-gallery-placeholder" className={galleryPlaceholderCss}>
          <p {...dataSetToAttributes(emptyPlaceholderDataset)}>{emptyPlaceholder}</p>
        </div>
      )}
    </>
  );
};

LazyTiles.displayName = 'LazyTiles';
