/**
 * Utility function that checks if a video element is playing. Logic grabbed from this thread:
 * https://stackoverflow.com/questions/6877403/how-to-tell-if-a-video-element-is-currently-playing/6877530#6877530
 */
export const isVideoPlaying = (video?: HTMLVideoElement): boolean => {
  if (!video) {
    return false;
  }

  return !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);
};
