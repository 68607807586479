import { css } from '@emotion/css';

import { Spacing } from '../../../constants';
import { Black, Gray, White, Yellow } from '../../../constants/colors';

export const cardCss = css`
  padding: 25px;
  background: ${Gray.V50};
  border-radius: 8px;
  width: 350px;
  height: 100px;
  margin: 25px 32px 0 0;
  *[dir='rtl'] & {
    margin: 25px 0 0 32px;
  }
`;

export const cardAmountCss = css`
  font-size: 48px;
  margin: 10px 0;
  font-weight: 600;
`;

export const cardTitleCss = css`
  color: ${Gray.V300};
`;

export const rowCss = css`
  display: flex;
  flex-wrap: wrap;
`;

export const headerRowCss = css`
  ${rowCss}
  justify-content: space-between;
`;

export const mainControlsCss = css`
  ${rowCss}
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-top: 10px;

  .sdsm-button:not(:first-child) {
    margin-left: 5px;
  }
`;

export const partitionSelectorCss = css`
  border: none;
  align-items: center;
  background-color: ${Gray.V50};
  border-radius: ${Spacing['2XS']}px;
  color: ${Gray.V300}px;
  cursor: pointer;
  font-size: 14px;
  padding: ${Spacing.MD}px;
  display: flex;
  justify-content: space-between;
  min-width: 160px;
`;

export const dateSelectorButtonCss = css`
  background-color: ${White};
  border: 1px solid ${Gray.V200};
  border-radius: 15px;
  height: 30px;
  width: 30px;
  cursor: pointer;
`;

export const vizTitleCss = css`
  font-weight: 500;
`;

export const vizFilterContainerCss = css`
  ${rowCss};
  align-self: flex-end;
`;

export const vizFilterButtonCss = (active: boolean): ReturnType<typeof css> => css`
  ${dateSelectorButtonCss};
  border-radius: 0;
  border: 1px solid ${active ? Yellow.V200 : Gray.V200};
  fill: ${active ? Black.V200 : Gray.V200};
`;
