import { GlobalComponentsContext } from '@snapchat/mw-global-components';
import { useGlobalComponentsContentfulQuery } from '@snapchat/mw-global-components-schema';
import type { MessageFormatter } from '@snapchat/snap-design-system-marketing';
import { defaultFormatMessage, MessageContext } from '@snapchat/snap-design-system-marketing';
import type { FC, PropsWithChildren } from 'react';
import { useContext } from 'react';

import { Config } from '../../config';
import { useContentfulQuery } from '../../hooks/useContentfulQuery';
import type { Items } from '../../types/Items';
import { useContentfulContext } from '../useContentfulContext';
import { intlProviderQuery } from './query';

interface Message {
  key: string;
  value: string;
}

interface MessageResponse {
  messageCollection: Items<Message>;
}

export const IntlProvider: FC<PropsWithChildren> = ({ children }) => {
  const context = useContentfulContext();
  const { globalApolloClient } = useContext(GlobalComponentsContext);

  const { data: localData } = useContentfulQuery<MessageResponse>(intlProviderQuery);

  const { data: globalData } = useGlobalComponentsContentfulQuery<MessageResponse>(
    intlProviderQuery,
    { currentLocale: context.locale, isPreview: context.preview, isSSR: Config.isSSR },
    { client: globalApolloClient }
  );

  const globalMessages = !globalData
    ? null
    : Object.fromEntries(globalData.messageCollection.items.map(m => [m.key, m.value]));

  const localMessages = !localData
    ? null
    : Object.fromEntries(localData.messageCollection.items.map(m => [m.key, m.value]));

  // Early bail until both of the message packs have been loaded.
  // This is done to avoid an extra full-app re-render when only one of them is loaded.
  if (!globalMessages || !localMessages) {
    return (
      <MessageContext.Provider value={{ formatMessage: defaultFormatMessage }}>
        {children}
      </MessageContext.Provider>
    );
  }

  const messages = {
    ...localMessages,
    ...globalMessages, // Global message keys will override any duplicate local message key
  };

  const formatMessage: MessageFormatter = (message, vars): string => {
    let formattedValue = messages[message.id] ?? message.defaultMessage;

    if (!vars) return formattedValue;

    for (const [key, value] of Object.entries(vars)) {
      formattedValue = formattedValue.replace(`{${key}}`, value);
    }

    return formattedValue;
  };

  return <MessageContext.Provider value={{ formatMessage }}>{children}</MessageContext.Provider>;
};
