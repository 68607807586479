import { css } from '@emotion/css';

import { Black, White } from '../../../constants/colors';

export const fullTooltipCss = css`
  padding: 0px 1rem;
`;

export const tooltipCss = css`
  padding: 0;
`;

export const tooltipTopCss = css`
  background-color: ${Black.V200};
  padding: 5px 10px;
  color: ${White};
  border-radius: 4px 4px 0px 0px;
`;

export const tooltipBottomCss = css`
  background-color: ${Black.V100};
  padding: 5px 10px;
  color: ${White};
  border-radius: 0px 0px 4px 4px;
  p {
    margin: 0;
  }
`;

export const containerCss = css`
  display: flex;
  align-items: center;
`;

export const dataKeyCss = css`
  font-size: 10px;
`;

export const dataCss = css`
  font-size: 12px;
  && {
    margin-left: 11px;
  }
`;

export const dotCss = css`
  width: 6px;
  height: 6px;
  border-radius: 3px;
  margin: 0 5px 0 0;
  *[dir='rtl'] & {
    margin: 0 0 0 5px;
  }
`;

export const leftAxisCss = css`
  transform: translate(50px, 0px);
  *[dir='rtl'] & {
    transform: translate(35px, 0px);
  }
`;

export const sectionCss = css`
  position: relative;
`;

export const legendCss = css`
  display: flex;
  position: absolute;
`;
