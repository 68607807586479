import type { FC } from 'react';
import { useContext } from 'react';

import { DesignSystemContext } from '../../context/DesignSystemContext';

export interface DesignAgnosticButtonProps {
  testId?: string;
  text: string;
  isPrimary?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
}

export const DesignAgnosticButton: FC<DesignAgnosticButtonProps> = props => {
  const { buttonComponent } = useContext(DesignSystemContext);
  return buttonComponent(props);
};
