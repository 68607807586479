import type { SrcSetSizes } from '@snapchat/mw-contentful-client';
import {
  GlobalNavHighlight as GlobalNavHighlightSDS,
  mobileMaxWidth,
} from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useState } from 'react';

import type { GlobalNavHighlightAllType } from '../../generated/contentful-types';
import { CallToAction } from '../CallToAction/CallToAction';
import { useActivation } from '../CallToAction/useActivation';
import { Media } from '../Media/Media';

/**
 * Different sizes of images to serve.
 *
 * Somewhat arbitrarily chosen based off of the most popular resolutions from GA. Unfortunately GA
 * doesnt have DPR logged (i think)
 */
const hightlightWidths = [480, 960, 1440, 1920, 2560];

/**
 * This is the amount of padding in the global nav highlight. we use this to help the browser more
 * accurately know the final img size so it can pick the optimal image.
 *
 * If the value changes in the future, it's not a huge deal to be exactly the same, as the only
 * difference would be slightly lower or higher resolution images.
 */
const hightlightPadding = 452;

export const globalNavHighlightSizes = `(max-width: ${mobileMaxWidth}px) 100vw, calc(100vw - ${hightlightPadding}px)`;

export const hightlightSizedSrcSets: SrcSetSizes = {
  sizeToUrl: hightlightWidths.map(width => ({
    size: `${width}w`,
    settings: { quality: 70, width },
  })),
  sizes: globalNavHighlightSizes,
};

export const GlobalNavHighlight: FC<GlobalNavHighlightAllType> = props => {
  const [triggered, setTriggered] = useState<number>(0);
  useActivation({ triggered, ctaProps: props?.cta });

  const { cta, background, cardTitleV2, cardBodyV2 } = props;

  const onActivate = () => {
    // We increment the triggered here to change the useEffect hash
    // and force another trigger. We do this since the activation can
    // be an external link the user may want to open multiple times.
    setTriggered(triggered + 1);
  };

  return (
    <GlobalNavHighlightSDS
      cardTitleV2={cardTitleV2}
      cardBodyV2={cardBodyV2}
      background={<Media {...background} sizedSrcSets={hightlightSizedSrcSets} />}
      callToAction={<CallToAction {...cta} />}
      onActivate={onActivate}
    />
  );
};

GlobalNavHighlight.displayName = 'GlobalNavHighlight';
