import { cx } from '@emotion/css';
import omit from 'lodash/omit.js';
import type { ChangeEvent, FC } from 'react';
import { memo, useCallback, useContext, useEffect } from 'react';

import { dataSetToAttributes } from '../../utils';
import { Field } from './Field';
import type { FormFieldComponentProps, ValidationKey } from './Form.types';
import { FormEventType } from './Form.types';
import { FormContext } from './FormContext';
import { getBooleanValue, getStringValue } from './formUtils';
import {
  checkboxCss,
  checkboxWrapperCss,
  inputCss,
  inputErrorCss,
  placeholderCss,
  textAreaCss,
} from './styles';

export type InputType = 'Text' | 'Number' | 'Date' | 'Textarea' | 'Checkbox' | 'Hidden';

export interface InputProps extends FormFieldComponentProps {
  type?: InputType;
  validation?: ValidationKey;
  maxLength?: number;
  /**
   * Only used for Input types `Number` and `Date`. If specified, the input value must be greater
   * than or equal to the Min Value.
   */
  minValue?: string;
  /**
   * Only used for Input types `Number` and `Date`. If specified, the input value must be less than
   * or equal to the Max Value.
   */
  maxValue?: string;
}

export const Input: FC<InputProps> = memo(props => {
  const {
    name,
    validation,
    required = false,
    placeholder = '',
    initialValue,
    type = 'Text',
    label,
    richLabel,
    labelDataset,
    richLabelDataset,
    readOnly,
    shouldResetToInitial,
    maxLength,
    minValue,
    maxValue,
    ...restProps
  } = props;
  const { state, dispatch } = useContext(FormContext);

  const fieldMeta = state.fields[name];
  const value = state.formBody[name];

  const inputProps = omit(
    restProps,
    'dataset',
    'helpText',
    'helpTextDataset',
    'errorDataset',
    'contentfulDescriptionDataset'
  );

  useEffect(() => {
    dispatch({
      type: FormEventType.REGISTER_FIELD,
      name,
      field: {
        initialValue,
        validation,
        required,
        shouldResetToInitial: type === 'Hidden' || shouldResetToInitial,
      },
    });
  }, [dispatch, initialValue, name, required, validation, type, shouldResetToInitial]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const target = e.target as HTMLInputElement;
      const isCheckbox = target.type === 'checkbox';
      const value = isCheckbox ? target.checked : target.value;

      dispatch({
        type: FormEventType.CHANGE_FIELD_VALUE,
        name,
        value,
      });
    },
    [dispatch, name]
  );

  const handleInvalid = useCallback(() => {
    dispatch({ type: FormEventType.INVALIDATE_FIELD, name });
  }, [dispatch, name]);

  const getInputElement = () => {
    if (type === 'Textarea') {
      return (
        <textarea
          name={name}
          placeholder={placeholder}
          value={getStringValue(value)}
          className={cx(textAreaCss, placeholderCss, { [inputErrorCss]: fieldMeta?.hasError })}
          required={required}
          onInvalid={handleInvalid}
          readOnly={readOnly}
          onChange={handleChange}
          onBlur={handleChange}
          maxLength={maxLength ?? 600}
          {...inputProps}
        />
      );
    }

    if (type === 'Checkbox') {
      return (
        <div className={checkboxWrapperCss}>
          <input
            type="checkbox"
            name={name}
            id={name}
            onInvalid={handleInvalid}
            placeholder={placeholder}
            checked={getBooleanValue(value)}
            className={cx(checkboxCss, placeholderCss, { [inputErrorCss]: fieldMeta?.hasError })}
            required={required}
            onChange={handleChange}
            onBlur={handleChange}
            readOnly={readOnly}
            {...inputProps}
          />
          <label
            htmlFor={name}
            {...dataSetToAttributes(richLabel ? richLabelDataset : labelDataset)}
          >
            {richLabel ?? label}
          </label>
        </div>
      );
    }

    if (type === 'Number' || type === 'Date') {
      return (
        <input
          type={type}
          name={name}
          placeholder={placeholder}
          value={getStringValue(value)}
          className={cx(inputCss, placeholderCss, { [inputErrorCss]: fieldMeta?.hasError })}
          required={required}
          readOnly={readOnly}
          onChange={handleChange}
          onInvalid={handleInvalid}
          onBlur={handleChange}
          min={minValue}
          max={maxValue}
          {...inputProps}
        />
      );
    }

    return (
      <input
        type={type === 'Hidden' ? 'hidden' : 'text'}
        name={name}
        placeholder={placeholder}
        value={getStringValue(value)}
        className={cx(inputCss, placeholderCss, { [inputErrorCss]: fieldMeta?.hasError })}
        required={required}
        readOnly={readOnly}
        onChange={handleChange}
        onInvalid={handleInvalid}
        onBlur={handleChange}
        maxLength={maxLength}
        {...inputProps}
      />
    );
  };

  return (
    <Field
      {...props}
      label={richLabel ?? label}
      hasError={fieldMeta?.hasError ?? false}
      type={type ?? 'Hidden'}
    >
      {getInputElement()}
    </Field>
  );
});

Input.displayName = 'Input';
