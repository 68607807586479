import type { ValueOf } from '../../types';

export const TileImageSize = {
  Small: 'Small',
  Medium: 'Medium',
  Large: 'Large',
} as const;

export type TileImageSize = ValueOf<typeof TileImageSize>;

export const TileImageFit = {
  Contain: 'Contain',
  Cover: 'Cover',
} as const;

export type TileImageFit = ValueOf<typeof TileImageFit>;
