import { Black, Gray, Plain } from '../../constants';
import type { MotifComponent, MotifComponentProps } from '../../motif';
import { m } from '../../motif/m';

export const accordionStandardVars = {
  '--accordion-header-padding': m('--spacing-m'),
  '--accordion-header-desktop-font-size': m('--h6-desktop-font-size'),
  '--accordion-header-mobile-font-size': m('--h6-mobile-font-size'),
  '--accordion-header-desktop-font-line-height': m('--h6-desktop-font-line-height'),
  '--accordion-header-mobile-font-line-height': m('--h6-mobile-font-line-height'),
  '--accordion-header-desktop-font-weight': m('--h6-desktop-font-weight'),
  '--accordion-header-mobile-font-weight': m('--h6-mobile-font-weight'),
} as const;

export const accordionBlackBgVars: MotifComponentProps<MotifComponent.ACCORDION> = {
  '--accordion-divider-border-color': Gray.V250,
  '--accordion-header-color': Plain.WHITE,
  ...accordionStandardVars,
};

export const accordionYellowBgVars: MotifComponentProps<MotifComponent.ACCORDION> = {
  '--accordion-divider-border-color': Black.V50,
  '--accordion-header-color': Plain.BLACK,
  ...accordionStandardVars,
};

export const accordionWhiteBgVars: MotifComponentProps<MotifComponent.ACCORDION> = {
  '--accordion-divider-border-color': Gray.V200,
  '--accordion-header-color': Plain.BLACK,
  ...accordionStandardVars,
};

export const accordionGrayBgVars: MotifComponentProps<MotifComponent.ACCORDION> = {
  '--accordion-divider-border-color': Gray.V250,
  '--accordion-header-color': Plain.BLACK,
  ...accordionStandardVars,
};
