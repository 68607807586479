import { gql } from '@apollo/client';

import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';
import { fragments as mediaFragments } from '../Media';

export const fragments = {
  all: gql`
    fragment FaviconAll on Favicon {
      ...ContentfulSysId
      asset {
        ...AssetAll
      }
      size
    }
    ${mediaFragments.all}
    ${contentfulSysIdFragment}
  `,
};

export const query = gql`
  query FaviconCollectionQuery($preview: Boolean!, $locale: String!) {
    faviconCollection(preview: $preview, locale: $locale, order: sys_publishedAt_ASC) {
      items {
        ...FaviconAll
      }
    }
  }
  ${fragments.all}
`;
