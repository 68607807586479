import type { FC } from 'react';

import { Alignment } from '../../constants';
import { AuthorByline } from '../AuthorByline';
import { Block } from '../Block';
import { Category } from '../Category';
import { Content } from '../Content';
import { latestPostItemTitleCss, latestPostItemWrapperCss } from './styles';
import type { LatestPostsProps } from './types';

export const LatestPosts: FC<LatestPostsProps> = ({
  anchorId,
  title,
  items,
  cta,
  backgroundColor,
  scrollSnap,
  className,
  preChildren,
  postChildren,
}) => {
  const latestPostsBody = (
    <>
      {items.map((item, index) => (
        <article data-test-id="latest-post-item" className={latestPostItemWrapperCss} key={index}>
          {item.category && <Category title={item.category} />}
          <div className={latestPostItemTitleCss}>{item.title}</div>
          {(item.author || item.date) && <AuthorByline author={item.author} date={item.date} />}
          <hr />
        </article>
      ))}
    </>
  );

  return (
    <Block
      title={title}
      titleAlignment={Alignment.Center}
      anchorId={anchorId}
      className={className}
      fullHeight={scrollSnap}
      backgroundColor={backgroundColor}
      maxColumns={1}
      preChildren={preChildren}
      postChildren={postChildren}
    >
      <Content body={latestPostsBody} callsToAction={cta ? [cta] : undefined} />
    </Block>
  );
};
