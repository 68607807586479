import cloneDeep from 'lodash-es/cloneDeep';

import { allMotifSchemes, MotifComponent } from './motifConstants';
import type { Motif, MotifComponentProps, MotifComponentVar, PartialMotif } from './motifTypes';

/**
 * Return the clone of `minuend` with values from `subtrahend` subtracted.
 *
 * The indeded usecase is to make a diff from a custom motif and default one.
 */
export function motifDiff(minuend: Motif, subtrahend: Motif): PartialMotif {
  const motif: PartialMotif = cloneDeep(minuend);

  for (const scheme of allMotifSchemes) {
    if (!minuend[scheme] || !subtrahend[scheme]) continue;

    for (const component of Object.values(MotifComponent)) {
      if (!minuend[scheme]![component] || !subtrahend[scheme]![component]) continue;

      for (const cssVar of Object.keys(motif[scheme]![component]!)) {
        const variable = cssVar as MotifComponentVar<typeof component>;
        const minuendValue = (
          minuend[scheme]![component]! as MotifComponentProps<typeof component>
        )[variable];
        const subtrahendValue = (
          subtrahend[scheme]![component]! as MotifComponentProps<typeof component>
        )[variable];
        if (minuendValue !== subtrahendValue) continue;
        delete (motif[scheme]![component] as MotifComponentProps<typeof component>)[variable];
      }
    }
  }

  return motif;
}
