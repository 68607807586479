import type { BackgroundColor } from '@snapchat/snap-design-system-marketing';
import type { FC, PropsWithChildren } from 'react';
import { useState } from 'react';

import type { PageLayoutContextProps } from './PageLayoutContext';
import { FooterView, HeaderView, PageLayoutContext } from './PageLayoutContext';

export class SessionPageLayoutContextProps implements PageLayoutContextProps {
  public footerView: FooterView = FooterView.FULL_FOOTER;
  public headerView: HeaderView = HeaderView.FULL_HEADER;
  public headerBackgroundColorOverride?: BackgroundColor;

  /** @override */
  setFooterView = (footerView: FooterView): void => {
    this.footerView = footerView;
  };

  /** @override */
  setHeaderView = (headerView: HeaderView): void => {
    this.headerView = headerView;
  };

  /** @override */
  setHeaderBackgroundColorOverride = (color?: BackgroundColor): void => {
    this.headerBackgroundColorOverride = color;
  };
}

/**
 * Provider for the context that holds Layout settings that are stored in the `Page` object.
 *
 * This setup where a page (a sibling of header and footer) needs to affect header and footer is why
 * we have to bend through a context.
 *
 * What complicates matters, is that you cannot affect a sibling component when rendering, so it has
 * to be done via other means, like doing an update inside of a `useEffect`. However this doesn't
 * work on the server-side. The quick and dirty solution is to pass the `sessionProps` from a
 * previous render to the provider, so that it can be used to set the initial state.
 */
export const PageLayoutProvider: FC<
  { sessionValue: PageLayoutContextProps } & PropsWithChildren
> = ({ sessionValue, children }) => {
  const [footerView, setFooterView] = useState(sessionValue.footerView ?? FooterView.FULL_FOOTER);
  const [headerView, setHeaderView] = useState(sessionValue.headerView ?? HeaderView.FULL_HEADER);
  const [headerBackgroundColorOverride, setHeaderBackgroundColorOverride] = useState<
    BackgroundColor | undefined
  >(sessionValue.headerBackgroundColorOverride);

  const pageContext: PageLayoutContextProps = {
    footerView,
    headerView,
    headerBackgroundColorOverride,
    setFooterView: (footerView: FooterView) => {
      setFooterView(footerView);
      sessionValue.setFooterView?.(footerView);
    },
    setHeaderView: (headerView: HeaderView) => {
      setHeaderView(headerView);
      sessionValue.setHeaderView?.(headerView);
    },
    setHeaderBackgroundColorOverride: (bgColor?: BackgroundColor) => {
      setHeaderBackgroundColorOverride(bgColor);
      sessionValue.setHeaderBackgroundColorOverride?.(bgColor);
    },
  };

  return <PageLayoutContext.Provider value={pageContext}>{children}</PageLayoutContext.Provider>;
};
