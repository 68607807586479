import type { ContentfulWebhookProps } from './contentfulWebhookTypes';

/**
 * Parses the X-Contentful-Topic header passed along with contentful webhooks.
 *
 * These have a format of `[API].[Type].[Action]`. See:
 * https://www.contentful.com/developers/docs/concepts/webhooks/ Though that documentation isn't
 * complete.
 */
export function parseContentfulTopic(topicName: string): ContentfulWebhookProps {
  const [api, entity, action] = topicName.split('.');
  return { action, entity, api } as ContentfulWebhookProps;
}
