import type { ErrorInfo, ReactNode } from 'react';
import type React from 'react';
import { Component } from 'react';

import { logError } from './helpers/logging';
import { Error500 } from './pages/Error500/Error500';
import { customFetch } from './utils/fetch/customFetch';
import type { Tracer } from './utils/tracing';

interface ErrorProps {
  tracer?: Tracer;
  children?: ReactNode;
}

interface ErrorState {
  hasError: boolean;
}

export class ErrorBoundary extends Component<ErrorProps, ErrorState> {
  constructor(props: ErrorProps) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({
      hasError: true,
    });

    // This returns a 599 response which lets us see volume of uncaught errors as 599 responses.
    const trace = this.props.tracer?.startSpan('fetchError');
    void customFetch('/error').then(_ => trace?.endSpan());

    // This logs to sentry, graphene, console etc.
    logError({
      component: 'UncaughtError',
      error: error ?? undefined,
      context: {
        componentStack: errorInfo?.componentStack,
      },
    });
  }

  render(): React.ReactNode {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return <Error500 />;
    }

    return children;
  }
}
