import type { BackgroundColor } from '@snapchat/snap-design-system-marketing';
import { gql } from 'graphql-tag';

import type { ContentfulTypedSysProps } from '../../types/contentful';
import type { Items } from '../../types/Items';
import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';
import type { SubNavigationItemProps } from './types';

export const subNavigationFragment = gql`
  fragment SubNavigationAll on SubNavigation {
    ...ContentfulSysId
    backgroundColor
    subNavigationItemsCollection {
      items {
        ...ContentfulSysId
        text
        anchorId
      }
    }
  }
  ${contentfulSysIdFragment}
`;

export interface SubNavigationDataProps extends ContentfulTypedSysProps<'SubNavigation'> {
  backgroundColor: BackgroundColor;
  subNavigationItemsCollection: Items<SubNavigationItemProps>;
}

export const subNavigationQuery = gql`
  query SubNavigationQuery($preview: Boolean!, $locale: String!, $id: String!) {
    subNavigation(preview: $preview, locale: $locale, id: $id) {
      ...SubNavigationAll
    }
  }
  ${subNavigationFragment}
`;

export interface SubNavigationData {
  subNavigation?: SubNavigationDataProps;
}
