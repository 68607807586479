import { Site } from '@snapchat/mw-common';
import { type Motif, defaultMotif } from '@snapchat/snap-design-system-marketing';

import { Config } from '../config';
import { arcadiaMotif } from '../sites/arcadia/arcadiaMotif';
import { avalonMotif } from '../sites/avalon/avalonMotif';
import { cheeriosMotif } from '../sites/cheerios/cheeriosMotif';
import { experienceMotif } from '../sites/experience/experienceMotif';

export function getSiteMotif(): Motif | undefined {
  switch (Config.site) {
    case Site.ARCADIA:
      return arcadiaMotif;
    case Site.AVALON:
      return avalonMotif;
    case Site.CHEERIOS:
      return cheeriosMotif;
    case Site.EXPERIENCE:
      return experienceMotif;
    default:
      return defaultMotif;
  }
}
