import { gql } from '@apollo/client';
import type { ContentfulMetadata, Criteria } from '@snapchat/mw-contentful-schema';
import type { TileImageFit, TileImageSize } from '@snapchat/snap-design-system-marketing';
import type { ContentfulSysProps } from 'src/types/contentful';
import type { Items } from 'src/types/Items';
import type { RichText } from 'src/types/RichText';

import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';
import { fragments as analyticsFragments } from '../Analytics/query';
import type { AnalyticsDataProps } from '../Analytics/types';
import { criteriaFragment } from '../Experiment';
import { fragments as imageFragments } from '../Image';
import type { ImageDataProps } from '../Image/types';
import { fragments as videoFragments } from '../Video';

export const fragments = {
  all: gql`
    fragment TileAll on Tile {
      ...ContentfulSysId
      title {
        json
      }
      label {
        json
      }
      media {
        __typename
        ... on Video {
          ...VideoAll
        }
        ... on Image {
          ...ImageAll
        }
      }
      date
      secondaryMedia {
        ...ImageAll
      }
      imageSize
      imageFit
      url
      contentfulMetadata {
        tags {
          id
        }
      }
      analytics {
        ...AnalyticsAll
      }
      slugReference {
        ...ContentfulSysId
        slug
        unlistedCriteria {
          ...CriteriaAll
        }
      }
    }
    ${analyticsFragments.all}
    ${imageFragments.all}
    ${videoFragments.all}
    ${criteriaFragment}
    ${contentfulSysIdFragment}
  `,
};

export type GalleryTileProps = ContentfulSysProps & {
  contentfulMetadata: ContentfulMetadata;
  title?: RichText;
  label?: RichText;
  url?: string;
  media?: ImageDataProps | null;
  date?: string;
  secondaryMedia?: ImageDataProps | null;
  imageSize?: TileImageSize;
  imageFit?: TileImageFit;
  analytics?: AnalyticsDataProps;
  slugReference?: {
    slug: string;
    unlistedCriteria?: Criteria;
  } & ContentfulSysProps;
  linkedFrom?: {
    galleryCollection: Items<ContentfulSysProps>;
  };
};

export const queries = {
  shallow: gql`
    query TileQuery($preview: Boolean!, $locale: String!, $id: String!) {
      tile(preview: $preview, locale: $locale, id: $id) {
        ...ContentfulSysId
      }
    }
    ${contentfulSysIdFragment}
  `,
  all: gql`
    query TileQuery($preview: Boolean!, $locale: String!, $id: String!) {
      tile(preview: $preview, locale: $locale, id: $id) {
        ...TileAll
      }
    }
    ${fragments.all}
  `,
};
