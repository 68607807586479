import type { ReactNode } from 'react';

import type { BaseComponentProps } from '../../../types';

export enum Orientation {
  Horizontal = 'Horizontal',
  Vertical = 'Vertical',
}

export interface FooterGroupProps extends BaseComponentProps {
  id: string;
  onClick?: () => void;
  title?: ReactNode;
  children?: ReactNode;
  orientation?: Orientation;
  isFooterBar?: boolean;
}
