export interface ImageSource {
  url: string;
  type: string;
  sizes?: string;
  media?: string;
}

export interface ImageSources {
  sources?: ImageSource[];
  default: string;
  defaultSrcSet?: string;
  defaultSizes?: string;
}
