import { useGlobalAnimations } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import { useContentfulQuery } from '../../hooks/useContentfulQuery';
import { query } from './query';
import type { AnimationKeyframesCollectionDataHandlerProps } from './types';

export const GlobalAnimations: FC = () => {
  const { data } = useContentfulQuery<AnimationKeyframesCollectionDataHandlerProps>(query);

  useGlobalAnimations(
    data?.animationKeyframesCollection?.items?.map(item => ({
      name: item.sys.id,
      keyframes: item.keyframes,
    }))
  );

  return null;
};

GlobalAnimations.displayName = 'GlobalAnimations';
