import { css } from '@emotion/css';

import { mobileMediaQuery, nonMobileMediaQuery } from '../constants';

export const hideWhenMobileCss = css`
  ${mobileMediaQuery} {
    display: none;
  }
`;

export const hideWhenDesktopCss = css`
  ${nonMobileMediaQuery} {
    display: none;
  }
`;
