import type { ReactNode } from 'react';

import { labelHeight, textLineHeight, titleHeightForImageSize } from './constants';
import type { TileImageSize } from './types';

export interface TileTitleProps {
  imageSize: TileImageSize;
  label?: ReactNode;
}

export const tileTitleHeight = ({ imageSize, label }: TileTitleProps): number => {
  const baseHeight = titleHeightForImageSize[imageSize];

  if (label) {
    return baseHeight;
  }

  return baseHeight + labelHeight;
};

/** @deprecated Just use line-clamp which is available now. TODO: Update. */
export const tileTitleLines = ({ imageSize, label }: TileTitleProps): number => {
  const h = tileTitleHeight({ imageSize, label });
  return Math.floor(h / textLineHeight);
};
