export const combineRefs = <T extends HTMLElement>(
  innerRef: React.MutableRefObject<T | null>,
  fwdRef: React.ForwardedRef<T>
) => {
  // TODO no idea what the return type is for this.
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return (element: T | null) => {
    [innerRef, fwdRef].forEach(ref => {
      if (ref) {
        if (typeof ref === 'function') {
          ref(element);
        } else {
          ref.current = element;
        }
      }
    });
  };
};
