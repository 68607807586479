import { StaticBrowserFeature } from '@snapchat/client-hints';
import { createContext } from 'react';

import type { BrowserFeatures } from './types';

export const BrowserFeaturesContext = createContext<BrowserFeatures>(
  new StaticBrowserFeature({
    lowEntropyHints: {
      isMobile: true, // Mobile-first FTW
      saveData: false,
      browsers: [],
      platform: 'Unknown',
    },
  })
);

export const BrowserFeaturesContextProvider = BrowserFeaturesContext.Provider;
