import { Quote as QuoteSDS } from '@snapchat/snap-design-system-marketing';
import isNull from 'lodash-es/isNull';
import type { FC } from 'react';

import type { ContentfulIdVariable } from '../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../hooks/useContentfulQuery';
import { isContentfulShallow } from '../../utils/contentful';
import { renderRichTextMultiLineWithMarkings } from '../../utils/renderText/renderRichText';
import type { CustomerQuoteDataProps, CustomerQuoteProps } from './query';
import { query } from './query';

export const Quote: FC<CustomerQuoteProps> = props => {
  const needsLoading = isContentfulShallow(props);

  const { data } = useContentfulQuery<CustomerQuoteDataProps, ContentfulIdVariable>(query, {
    variables: { id: props.sys.id },
    skip: !needsLoading,
  });

  if (needsLoading && !data) return null;

  const { authorName, body } = data?.customerQuote ?? props;
  return (
    <QuoteSDS author={isNull(authorName) ? undefined : authorName}>
      {renderRichTextMultiLineWithMarkings(body)}
    </QuoteSDS>
  );
};

Quote.displayName = 'Quote';
