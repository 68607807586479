import { Black, Gray, Plain } from '../../constants';
import type { MotifComponent, MotifComponentProps } from '../../motif';
import { m } from '../../motif/m';

export const summaryCardVars = {
  '--summary-card-bg-color': Plain.WHITE,
  '--summary-card-border-radius': m('--border-radius-s'),
  '--summary-card-description-color': Plain.BLACK,
  '--summary-card-fg-color': Black.V100,
  '--summary-card-title-color': Plain.BLACK,
};

export const contrastBlackSummaryCardVars: MotifComponentProps<MotifComponent.SUMMARY_CARD> = {
  '--summary-card-bg-color': Black.V125,
  '--summary-card-border-radius': m('--border-radius-s'),
  '--summary-card-description-color': Gray.V100,
  '--summary-card-fg-color': Plain.WHITE,
  '--summary-card-title-color': Plain.WHITE,
} as const;
