import { css } from '@emotion/css';

import { m } from '../../../motif/m';

export const globalNavItemCss = css`
  display: flex;
  justify-content: space-between;

  & > .icon-external-link {
    color: ${m('--global-header-item-color')};
    font-size: 16px;
    vertical-align: middle;
    align-self: center;
  }

  & > a {
    position: relative;
  }

  & a.selected {
    color: ${m('--global-header-item-active-color')};
  }

  padding: 0;
`;

export const globalNavItemWithChildrenCss = css`
  summary.selected {
    color: ${m('--global-header-item-active-color')};
  }
`;

export const externalIconCss = css`
  &.icon-external-link {
    color: ${m('--global-header-item-color')};
    font-size: 14px;
    margin: 0 9px;
  }
`;

export const globalNavItemChevronCss = css`
  width: 20px;
  height: 20px;
  fill: ${m('--global-header-item-color')};

  summary.selected > & {
    fill: ${m('--global-header-item-active-color')};
  }
`;
