import { useContext } from 'react';

import type { ConsumerContextProps } from './ConsumerContextProvider';
import { ConsumerContext } from './ConsumerContextProvider';

export const useConsumerContext = (): ConsumerContextProps => {
  const context = useContext(ConsumerContext);

  if (context === undefined) {
    throw new Error('ConsumerContext can only be used in combination with ConsumerProvider');
  }

  return context;
};
