import type {
  ButtonSize,
  ButtonType as ButtonTypeSDS,
  IconName,
} from '@snapchat/snap-design-system-marketing';
import { Button as ButtonSDS, PrimitivesProvider } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useContext } from 'react';

import { Action } from '../../analytics/Action';
import { GlobalComponentsContext } from '../../context/GlobalComponentsContext';
import type { ButtonAllType } from '../../generated/contentful-types';
import { PlainRichText } from '../Contentful/PlainRichText';

type Props = ButtonAllType & {
  target?: string;
  analyticsLabel?: string;
};

export const Button: FC<Props> = props => {
  const { Anchor, onEvent } = useContext(GlobalComponentsContext);

  const { url, target, buttonType, theme, title, size, image, iconName } = props;

  const onClick = (): void => {
    onEvent?.({
      action: Action.Click,
      component: 'Button',
      url,
      label: props.analyticsLabel,
    });
  };

  const type = buttonType ?? theme;

  return (
    <PrimitivesProvider value={{ Anchor }}>
      <ButtonSDS
        link={url}
        target={target}
        type={type as ButtonTypeSDS}
        onClick={onClick}
        size={size as ButtonSize}
        image={
          image?.media
            ? {
                ...image?.media,
                // TODO: These need to be coming from somewhere...
                width: 24,
                height: 24,
              }
            : undefined
        }
        iconName={iconName as IconName}
      >
        {title?.json && <PlainRichText document={title.json} />}
      </ButtonSDS>
    </PrimitivesProvider>
  );
};

Button.displayName = 'Button';
