import { DeploymentType } from './deploymentTypes';
import type { SupportedRegion } from './regions';

// Docs: https://cloud.google.com/memorystore/docs/redis/connect-redis-instance-cloud-run#node.js

// For Redis info see: https://console.cloud.google.com/memorystore/redis/instances?referrer=search&project=marketing-web-platform
export const redisPort = 6379;

/**
 * Mapping from region to the IP of the redis server.
 *
 * Add new ones by running:
 *
 *       export REGION=""
 *       gcloud redis instances create "redis-${REGION}" --size="1" --region="${REGION}" --project="marketing-web-platform"
 *
 * Then you can find the IP here:
 * https://console.cloud.google.com/memorystore/redis/instances?project=marketing-web-platform
 */
const productionRedisIps: Record<SupportedRegion, string> = {
  'asia-northeast1': '10.165.105.235',
  'asia-south2': '10.163.141.131',
  'asia-southeast1': '10.150.104.115',
  'europe-north1': '10.108.174.147',
  'europe-west1': '10.234.14.171',
  'europe-west2': '10.104.84.83',
  'europe-west6': '10.28.188.83',
  'me-central2': '10.13.87.3',
  'me-west1': '10.231.229.83',
  'northamerica-northeast1': '10.251.168.19',
  'southamerica-east1': '10.134.96.19',
  // TODO: Migrate to 10.164.16.3 for us-central1 as it's a newer instance (v6 vs v4).
  // Keeping the old one while we migrate to cloud run.
  // Should only move after we move spectacles.com and snap.com to MWP.
  'us-central1': '10.48.40.187',
  'us-east1': '10.176.23.243',
  'us-east4': '10.61.144.123',
  'us-south1': '10.108.169.219',
  'us-west2': '10.239.139.59',
};

/**
 * IP Address of the staging/dev redis instance.
 *
 * See: https://console.cloud.google.com/memorystore/redis/instances?project=entapps-web-dev
 */
const stagingAndDevRedisIp = '10.205.109.11';

/** Returns the IP address of the Redis/Memorystore instance for a particular region. */
export function getRedisIp(region: SupportedRegion, deploymentType: DeploymentType): string {
  if (deploymentType !== DeploymentType.PRODUCTION) {
    return stagingAndDevRedisIp;
  }

  return productionRedisIps[region];
}
