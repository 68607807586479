import { cx } from '@emotion/css';
import {
  AddIcon,
  AlignBottomIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  BarsIcon,
  BarsTriangleIcon,
  CameraIcon,
  CartIcon,
  ChatOutlineIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  CrossIcon,
  ExternalLinkIcon,
  GlobeIcon,
  HeartOutlineIcon,
  HideIcon,
  ListIcon,
  PlayFilledIcon,
  RemoveIcon,
  RocketIcon,
  SearchIcon,
  ShowIcon,
  SortTrianglesIcon,
  TransitionCurveUpIcon,
  TrophyIcon,
} from '@snapchat/snap-design-system-icons';
import type { ColorPalette } from '@snapchat/snap-design-system-icons/lib/constants/colors';
import type { FC } from 'react';

import { MotifComponent, useMotifStyles } from '../../motif';
import type { allCustomIcons, CustomIconName } from './CustomIcon/CustomIcon';
import { allCustomIconNames, CustomIcon } from './CustomIcon/CustomIcon';

// NOTE: We CANNOT import ALL SDS-Icons because they add 400kb to the client-side
// bundle. So we have to only add the ones that are currently in use.
// Customers have to request allow-listing of new icons from SDS-I if they want
// to use them.
// Developers have to add them here when trying to use them as well.
//
// It is possible to audit what icons are being used.
// 1. Search the code for `iconName=` which is how it is set on Icon, Button, Content
// 2. Search Contentful for Button#iconName and Content#iconName. Note that we have
//    a script for it (find-icon-names.ts)

/** Map of allowed SDS icons. See comment above. Exported for storybook. */
export const allowedSdsIcons = {
  'arrow-left': ArrowLeftIcon,
  'arrow-right': ArrowRightIcon,
  'align-bottom': AlignBottomIcon,
  'bars-triangle': BarsTriangleIcon,
  'chat-outline': ChatOutlineIcon,
  'chevron-down': ChevronDownIcon,
  'chevron-left': ChevronLeftIcon,
  'chevron-right': ChevronRightIcon,
  'chevron-up': ChevronUpIcon,
  'external-link': ExternalLinkIcon,
  'heart-outline': HeartOutlineIcon,
  list: ListIcon,
  'play-filled': PlayFilledIcon,
  'sort-triangles': SortTrianglesIcon,
  'transition-curve-up': TransitionCurveUpIcon,
  add: AddIcon,
  bars: BarsIcon,
  camera: CameraIcon,
  cart: CartIcon,
  check: CheckIcon,
  cross: CrossIcon,
  globe: GlobeIcon,
  remove: RemoveIcon,
  rocket: RocketIcon,
  search: SearchIcon,
  trophy: TrophyIcon,
  show: ShowIcon,
  hide: HideIcon,
};

// these are from contentful so we can't update all at once
export const mappingOldFontToSdsIcons = {
  chevron: ChevronDownIcon,
  'chevron-inverted': ChevronUpIcon,
  close: CrossIcon,
  chat: ChatOutlineIcon,
  'camera-rounded': CameraIcon,
};

export const sdsIconNames = Object.keys(allowedSdsIcons);
export const oldIconToSdsIconNames = Object.keys(mappingOldFontToSdsIcons);

type SdsIconName = keyof typeof allowedSdsIcons;
type OldIconToSdsIconName = keyof typeof mappingOldFontToSdsIcons;
type CustomIcons = keyof typeof allCustomIcons;

// All available icons
export type IconName = SdsIconName | OldIconToSdsIconName | CustomIcons;

export interface IconProps {
  className?: string;
  name: IconName | string;
  fill?: string;
  size?: number;
}

export const Icon: FC<IconProps> = ({ name: iconName, className, fill, size = 16 }) => {
  useMotifStyles(MotifComponent.ICON);

  // Use SDS-Icons version first before trying others.
  if (iconName in allowedSdsIcons) {
    const SdsSvgIcon = allowedSdsIcons[iconName as keyof typeof allowedSdsIcons];
    return (
      <SdsSvgIcon
        size={size}
        fill={fill as ColorPalette}
        className={cx(MotifComponent.ICON, className)}
      />
    );
  }

  // Try to map to sds Icons if the name is outdated/old.
  if (iconName in mappingOldFontToSdsIcons) {
    const SdsSvgIcon = mappingOldFontToSdsIcons[iconName as keyof typeof mappingOldFontToSdsIcons];
    return (
      <SdsSvgIcon
        size={size}
        fill={fill as ColorPalette}
        className={cx(MotifComponent.ICON, className)}
      />
    );
  }

  // If not an sds-icon, check if its custom.
  if (allCustomIconNames.includes(iconName)) {
    return (
      <CustomIcon
        width={size}
        height={size}
        fill={fill}
        iconName={iconName as CustomIconName}
        className={cx(MotifComponent.ICON, className)}
      />
    );
  }

  // Render nothing if its an invalid icon name
  return null;
};
