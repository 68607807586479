import { MediaMode } from '../constants';
import { useMediaMode } from './useMediaMode';

/**
 * Simple hook that returns whether the browser is being displayed on a screen that's less than
 * mobile/desktop threshold.
 */
export function useIsMobile(): boolean {
  const mode = useMediaMode();
  return mode === MediaMode.Mobile;
}
