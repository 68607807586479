import type { ValueOf } from '../types';

/** @deprecated. Use MotifScheme instead. */
export const BackgroundColor = {
  Black: 'Black',
  White: 'White',
  Yellow: 'Yellow',
  Gray: 'Gray',
} as const;

export type BackgroundColor = ValueOf<typeof BackgroundColor>;

export type KeyedOnBackgroundColor<T> = { [key in BackgroundColor]: T };

export const allBackgroundColors: BackgroundColor[] = [
  BackgroundColor.Black,
  BackgroundColor.White,
  BackgroundColor.Yellow,
  BackgroundColor.Gray,
];
