import type { FC } from 'react';

import type { ContentfulIdVariable } from '../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../hooks/useContentfulQuery';
import type { ContentfulSysProps } from '../../types/contentful';
import { Content } from './Content';
import { queries } from './queries';
import type { ContentDataHandlerProps } from './types';

export const ContentShallow: FC<ContentfulSysProps> = props => {
  const { data } = useContentfulQuery<ContentDataHandlerProps, ContentfulIdVariable>(queries.all, {
    variables: { id: props.sys.id },
  });

  if (!data?.content) return null;
  return <Content {...data.content} />;
};

ContentShallow.displayName = 'ContentShallow';
