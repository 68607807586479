import { DeploymentType } from './deploymentTypes';
import type { SupportedRegion } from './regions';

// NOTE: We use VPCs for Redis connectivity.
// See: https://cloud.google.com/vpc/docs/configure-serverless-vpc-access#gcloud
// See: https://cloud.google.com/vpc/docs/serverless-vpc-access

/**
 * Map of the production Virtual Private Cloud connectors per region.
 *
 * All of these are named 'vpc-connector' to make CLI commands for them easier to write.
 *
 * The full list is here:
 * https://console.cloud.google.com/networking/connectors/list?project=marketing-web-platform
 *
 * If you're adding a new entry here, you have to create a new VPC connector by running a command:
 * NOTE: Pick a region index not already taken. See the list of existing connectors.
 *
 *     export REGION=""
 *     export REGION_INDEX=""
 *     gcloud compute networks vpc-access connectors create "vpc-connector" \
 *         --region="${REGION}" \
 *         --network="default" \
 *         --range="10.8.${REGION_INDEX}.0/28" \
 *         --min-instances=2 \
 *         --max-instances=10 \
 *         --machine-type="e2-micro"
 */
const productionVpcIpAddresses: Record<SupportedRegion, string> = {
  'asia-northeast1': '10.8.2.0',
  'asia-south2': '	10.8.15.0',
  'asia-southeast1': '10.8.17.0',
  'europe-north1': '10.8.4.0',
  'europe-west1': '10.8.5.0',
  'europe-west2': '10.8.6.0',
  'europe-west6': '	10.8.7.0',
  'me-west1': '10.8.16.0',
  'me-central2': '10.8.18.0',
  'northamerica-northeast1': '10.8.8.0',
  'southamerica-east1': '10.8.9.0',
  'us-central1': '10.8.0.0', // This one has a legacy name 'vpc-connector-central'.
  'us-east1': '	10.8.11.0',
  'us-east4': '10.8.12.0',
  'us-south1': '10.8.13.0',
  'us-west2': '10.8.14.0',
};

// See https://console.cloud.google.com/networking/connectors/list?project=entapps-web-dev
const stagingAndDevVpcIpAddress = '10.4.0.0';

/** Returns the Virtual Private Cloud cluster IP address. */
export function getVpcIpAddress(region: SupportedRegion, deploymentType: DeploymentType): string {
  return deploymentType === DeploymentType.PRODUCTION
    ? productionVpcIpAddresses[region]
    : stagingAndDevVpcIpAddress;
}

/**
 * Returns the Virtual Private Cloud connector name.
 *
 * See prod:
 * https://console.cloud.google.com/networking/connectors/list?project=marketing-web-platform See
 * staging/dev: https://console.cloud.google.com/networking/connectors/list?project=entapps-web-dev
 */
export function getVpcConnectorName(
  region: SupportedRegion,
  deploymentType: DeploymentType
): string {
  // For us-central1, in production, we use a legacy VPC connector name of 'vpc-connector-central'.
  if (deploymentType === DeploymentType.PRODUCTION && region === 'us-central1') {
    return 'vpc-connector-central';
  }

  // For all other production vpc accesses, we use a standard name = 'vpc-connector'.
  if (deploymentType === DeploymentType.PRODUCTION) {
    return 'vpc-connector';
  }

  // For LATEST_DEV and STAGING we use the 'vpc-connector-west'.
  return 'vpc-connector-west';
}
