import { css, keyframes } from '@emotion/css';

import { ZIndex } from '../../constants';
import { m } from '../../motif';

const spinCss = keyframes`
  0% {
    transform: translateY(-50%) rotate(0deg);
  }

  100% {
    transform: translateY(-50%) rotate(360deg);
  }
`;

export const spinnerCssSizeVar = '--spinner-size';

export const spinnerCss = css`
  display: inline-block;
  position: relative;
  vertical-align: middle;
  z-index: ${ZIndex.SPINNER};
  width: var(${spinnerCssSizeVar});
  height: var(${spinnerCssSizeVar});

  &::before {
    width: calc(var(${spinnerCssSizeVar}) / 2);
    height: var(${spinnerCssSizeVar});
    border-radius: 0 var(${spinnerCssSizeVar}) var(${spinnerCssSizeVar}) 0;
  }

  &::before,
  &::after {
    border-width: calc(var(${spinnerCssSizeVar}) / 8);
    border-style: solid;
    border-color: ${m('--spinner-fg-color')};
    border-left: none;
    box-sizing: border-box;
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: ${ZIndex.SPINNER};
    transform: translateY(-50%);
    transform-origin: 0% 50%;
    animation: ${spinCss} 1s linear 0s infinite;
  }

  &::after {
    width: calc(var(${spinnerCssSizeVar}) / 4);
    height: calc(var(${spinnerCssSizeVar}) / 2);
    border-radius: 0 calc(var(${spinnerCssSizeVar}) / 2) calc(var(${spinnerCssSizeVar}) / 2) 0;
    animation-direction: reverse;
  }
`;
