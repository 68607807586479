import type { NormalizedCacheObject } from '@apollo/client';
import createCache from '@emotion/cache';
import { CacheProvider as EmotionCacheProvider } from '@emotion/react';
import { AsyncDataController } from '@snapchat/async-data';
import { ClientBrowserFeature } from '@snapchat/client-hints-browser';
import { printWarning } from '@snapchat/self-xss-warning';
import type { History } from 'history';
import { createRoot, hydrateRoot } from 'react-dom/client';
import type { HelmetData } from 'react-helmet-async';

import type { AppProps } from './App';
import { App } from './App';
import type { RedirectOptions, UserInfo } from './AppContext';
import { defaultContext } from './AppContext';
import { BrowserCookieManager } from './clientonly/BrowserCookieManager';
import { createUuidV4Browser } from './clientonly/browserCreateUuidV4';
import { initializeClientLogging } from './clientonly/loggingInitClient';
import { startClientLogging } from './clientonly/loggingStartClient';
import { Config } from './config';
import { FooterView, HeaderView } from './context/PageLayoutContext';
import { defaultLocale } from './helpers/locale';
import type { SerializedAppProps } from './index.template';
import { getOrCreateApolloClient } from './utils/contentful/ContentfulClientCache';
import { applyUrlOverrides } from './utils/contentful/contentfulUrlOverrides';
import { generateFragmentTypes } from './utils/contentful/generateFragmentTypes';
import { setTracer } from './utils/tracing';
import { BrowserTracer } from './utils/tracing/browserTracer';
import { ensureWebClientId } from './utils/webClientId/webClientIdUtils';

interface EsBuildDevError {
  errors: {
    location: {
      file: string;
      line: string;
      column: string;
    };
    text: string;
  }[];
  // TODO: Add warnings here if they're useful.
}

const defaultState: SerializedAppProps = {
  currentLocale: defaultContext.currentLocale,
  supportedLocales: defaultContext.supportedLocales,
  userLocation: defaultContext.userLocation,
  pageLayoutContext: {
    footerView: FooterView.FULL_FOOTER,
    headerView: HeaderView.FULL_HEADER,
  },
};

// TODO: Move this into src/browser
async function main() {
  // ===========================================================================
  // Initialize logging and tracing.
  // ===========================================================================
  initializeClientLogging(Config);
  startClientLogging(Config);

  setTracer(new BrowserTracer());

  // ===========================================================================
  // Read APP_STATE
  // ===========================================================================
  // Note: APP_STATE is exported when server uses renderHtml.tsx.
  const state: SerializedAppProps = window.APP_STATE ?? defaultState;

  // print xss warning in console
  printWarning(state.currentLocale);

  // ===========================================================================
  // Locale prefix redirect.
  // ===========================================================================

  // We should be redirecting on the server, but we change the url here for redundancy.
  if (window.location.pathname.startsWith(`/${state.currentLocale}`)) {
    const url = new URL(window.location.href);
    url.pathname = url.pathname.replace(`/${state.currentLocale}`, '');

    if (state.currentLocale !== defaultLocale) {
      url.searchParams.set('lang', state.currentLocale);
    }
    window.history.pushState({}, '', url);
  }

  // ===========================================================================
  // Initialize Apollo Clients
  // ===========================================================================

  // Note: APOLLO_STATE and GLOBAL_APOLLO_STATE is exported when server uses renderHtml.tsx.
  const apolloCache: NormalizedCacheObject = window.APOLLO_STATE; // OK to be undefined.
  const globalApolloCache: NormalizedCacheObject = window.GLOBAL_APOLLO_STATE; // OK to be undefined.
  const requestUrl = new URLSearchParams(window.location.search);
  const contentfulConfigWithOverrides = applyUrlOverrides(Config.contentful, requestUrl);

  // Note: APOLLO_FRAGMENTS is exported when server uses renderHtml.tsx.
  const localApolloFragments =
    window.APOLLO_FRAGMENTS && Object.keys(window.APOLLO_FRAGMENTS).length > 0
      ? window.APOLLO_FRAGMENTS
      : await generateFragmentTypes(contentfulConfigWithOverrides);

  const globalApolloFragments =
    window.GLOBAL_APOLLO_FRAGMENTS && Object.keys(window.GLOBAL_APOLLO_FRAGMENTS).length > 0
      ? window.GLOBAL_APOLLO_FRAGMENTS
      : await generateFragmentTypes(Config.contentfulGlobal);

  const apolloClient = getOrCreateApolloClient(state.currentLocale, [
    contentfulConfigWithOverrides,
    localApolloFragments,
    apolloCache,
  ]);

  const globalApolloClient = getOrCreateApolloClient(state.currentLocale, [
    Config.contentfulGlobal,
    globalApolloFragments,
    globalApolloCache,
  ]);

  // ===========================================================================
  // Initialize Async data controller
  // ===========================================================================

  let asyncDataControllerCache = new Map();

  if (window.ASYNC_DATA_CONTROLLER_CACHE) {
    asyncDataControllerCache = new Map(Object.entries(window.ASYNC_DATA_CONTROLLER_CACHE ?? {}));
  }

  const asyncDataController = new AsyncDataController({
    cache: asyncDataControllerCache,
    // 1 hour 30 second default expiry time so that cached results returned from CDN
    // do not expire immediately.
    defaultExpiryTimeMs: 3600e3 + 30e3,
    clock: Date.now,
  });

  // ===========================================================================
  // Initialize Web Client ID
  // ===========================================================================

  const cookieManager = new BrowserCookieManager();
  const createUuidV4 = createUuidV4Browser;

  // Getting the web-client-id will set it if it doesn't exist.
  // We can't do this in the App because we don't want the setting to happen
  // on the server-side (blocks contents from being cached in CDN).
  const webClientId = ensureWebClientId({
    cookieManager,
    createUuidV4,
    currentUrl: new URL(window.location.href),
    userLocation: state.userLocation,
  });

  /**
   * Implementation of UserInfo provider that may short-circuit getting the cookie from the server
   * if it's already available on the client.
   *
   * TODO: If the complexity of the UserInfo increases, we may want to break out the experiment
   * bucket source into a separate provider.
   */
  const getUserInfoClient = (): Promise<UserInfo> => {
    if (webClientId) {
      return Promise.resolve({ experimentBucket: { id: webClientId } });
    }

    // If the sc-wcid cookie is not available, we need to fetch the experiment
    // bucket source (ip address) from the server.
    return fetch('/api/userinfo')
      .then(response => response.json())
      .catch(() => ({ experimentBucket: { id: 'default' } }));
  };

  // ===========================================================================
  // Initialize redirect logic
  // ===========================================================================

  const onRedirectClient = (location: string, options?: RedirectOptions) => {
    // Short circuit full urls.
    const newTab = options?.newTab;

    if (location.startsWith('http')) {
      window.open(location, newTab ? '_blank' : '_self');
      return;
    }

    // If we have access to React history object, we can update the state without
    // a full page reload.
    if (history && !newTab) {
      // For internal links, we can push the state to the react history object.
      history.push(location);
      // But we do also want to add an item to the browser history.
      window.history.pushState(null, '', new URL(location, window.location.href));
      return;
    }
    // no history so need to do full reload.
    window.open(location, newTab ? '_blank' : '_self');
  };

  // ===========================================================================
  // Initialize client-side App props
  // ===========================================================================

  const browserFeatures = new ClientBrowserFeature();

  let history: History<unknown> | undefined;

  const clientAppProps: AppProps = {
    ...state,
    getCurrentUrl: () => window.location.href,
    routerContext: {},
    helmetContext: {} as HelmetData,
    apolloClient,
    globalApolloClient,
    onRedirect: onRedirectClient,
    onHistory: capturedHistory => {
      history = capturedHistory;
    },
    browserFeatures,
    asyncDataController,
    globalPrivacyControl: navigator.globalPrivacyControl,
    cookieManager,
    createUuidV4,
    getUserInfo: getUserInfoClient,
    singleCallbackCache: new Map(),
  };

  // ===========================================================================
  // Render App
  // ===========================================================================

  // Reads the stylesheet from `<style data-emotion="marketing-web-emotion ...">...</style>` */
  const cache = createCache({ key: 'marketing-web-emotion' });

  // Server should pre-render the initial body using SSR, so we can call hydrate instead of render
  const jsx = (
    <EmotionCacheProvider value={cache}>
      <App {...clientAppProps} />
    </EmotionCacheProvider>
  );

  // Note: <main> element is specified in index.ejs.
  const rootElement = document.querySelector('main');

  if (!rootElement) {
    console.error('HTML must contain a <main> element.');
  } else if (rootElement.hasChildNodes() ?? false) {
    hydrateRoot(rootElement, jsx);
  } else {
    // If SSR failed, we render fully. Without this SSR failures result in blank pages.
    createRoot(rootElement).render(jsx);
  }

  // ==========================================================================
  // Client Recompile Dev Reload
  // ==========================================================================

  let errorCount = 0;

  if (Config.isLocal && Config.compilationMode === 'development') {
    const serverStatus = new EventSource('/esbuild', {});

    serverStatus.addEventListener('error', () => {
      console.error('/esbuild server disconnected.');
      errorCount++;

      if (errorCount > 1e3) {
        serverStatus.close();

        console.info(
          'Esbuild could not connnect after a 1000 attempts. Closing connection. Please reload.'
        );
      }
      // TODO: Figure out if we want to close this. I.e. calling serverStatus.close();
      // But that breaks the live reload if you restart dev server which isn't desirable in all
      // circumstances.
    });

    serverStatus.addEventListener('change', event => {
      const data = JSON.parse(event.data) as EsBuildDevError;

      if (data.errors?.length) {
        data.errors.forEach(error => {
          const location = error.location;
          const path = `${location.file}:${location.line}:${location.column}`;

          console.error(` 'Build failed. Not reloading.', ${error.text} (${path})`);
        });
      } else {
        console.info('Served content changed. Reloading');
        location.reload();
      }
    });
  }
}

main().catch(error => console.error(error));
