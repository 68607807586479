import clone from 'lodash-es/clone';

import type { FooterGroupAllType } from '../../../generated/contentful-types';
import type { FooterGroupItem, FooterGroupProps } from '../../FooterV3/FooterV3Types';
import type { CustomFooterGroupProps } from './FooterCollectionV3';

/** Merges default global columns from contentful, with the custom ones a consumer can pass in */
export const mergeFooterData = (
  globalGroups: Array<FooterGroupAllType>,
  customGroups: Array<CustomFooterGroupProps>,
  cookieSettingsUrl?: string
): FooterGroupProps[] => {
  // Ce clone this because we might splice later and it breaks things across renders
  const clonedCustomGroups = clone(customGroups);

  const computed = globalGroups.reduce((result, curGroup) => {
    // Check if custom group key is set to override one of the defaults
    const spaceItemIndex = clonedCustomGroups.findIndex(
      group => group.groupKey.trim() === curGroup.groupKey.trim()
    );

    const genericItems = contentfulToGenericGroup(
      curGroup.itemsCollection.items,
      cookieSettingsUrl
    );

    if (!genericItems.length) {
      return result;
    }

    // If found, replace the default with the custom
    if (spaceItemIndex >= 0) {
      const spaceGroupArr = clonedCustomGroups.splice(spaceItemIndex, 1);
      const spaceGroup = spaceGroupArr[0]!;

      const mergedGroup: FooterGroupProps = {
        id: spaceGroup.id ?? curGroup.sys.id,
        title: spaceGroup.title ?? curGroup.title,
        items: spaceGroup.items.concat(genericItems),
        analyticsLabel: spaceGroup.analyticsLabel ?? curGroup.analytics?.label,
      };

      result.push(mergedGroup);
      // if not overridden, add to list per usual
    } else {
      const group: FooterGroupProps = {
        id: curGroup.sys.id,
        title: curGroup.title,
        items: genericItems,
        analyticsLabel: curGroup.analytics?.label,
      };

      result.push(group);
    }

    return result;
  }, [] as FooterGroupProps[]);

  const restMapped = clonedCustomGroups.map(
    group =>
      ({
        id: group.id,
        title: group.title,
        items: group.items,
        analyticsLabel: group.analyticsLabel,
      } as FooterGroupProps)
  );

  return [...computed, ...restMapped];
};

/** Filters out columns and items that are restricted on the passed in domain. Use this BEFORE MERGE. */
export const filterFooterDomains = (
  collection: readonly FooterGroupAllType[],
  domain: string
): FooterGroupAllType[] => {
  const filteredGroups = collection.filter(
    group => !group.hideOnDomains || !group.hideOnDomains.includes(domain)
  );

  return filteredGroups.map(group => ({
    ...group,
    itemsCollection: {
      ...group.itemsCollection,
      items: group.itemsCollection.items.filter(
        item => !item.hideOnDomains || !item.hideOnDomains.includes(domain)
      ),
    },
  }));
};

const contentfulToGenericGroup = (
  items: FooterGroupAllType['itemsCollection']['items'],
  cookieSettingsUrl?: string
): FooterGroupItem[] => {
  const result = items
    .map(item => {
      if (item.__typename === 'FooterCookiesSettingsLink') {
        if (!cookieSettingsUrl) return undefined;

        return {
          id: item.sys.id,
          title: item.title,
          url: cookieSettingsUrl,
        };
      }

      if (item.__typename === 'FooterLocaleDropdown') {
        return undefined;
      }

      return {
        analyticsLabel: item?.analytics?.label,
        id: item.sys.id,
        title: item.title,
        url: item.url,
      };
    })
    .filter(item => !!item) as FooterGroupItem[];

  return result;
};
