import { Footnote as FootnoteSDS } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import { renderRichTextWithElements } from '../../utils/renderText/renderRichText';
import type { FootnoteProps } from './types';

export const Footnote: FC<FootnoteProps> = props => {
  const { number, fullText } = props;

  if (!fullText) return null;

  return <FootnoteSDS index={String(number)}>{renderRichTextWithElements(fullText)}</FootnoteSDS>;
};

Footnote.displayName = 'Footnote';
