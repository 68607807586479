import { Hyperlink as HyperlinkSDS } from '@snapchat/snap-design-system-marketing';
import type { FC, PropsWithChildren } from 'react';
import { useContext } from 'react';

import { shouldFlushLogs } from '../../helpers/getLocalPath';
import { logger } from '../../helpers/logging';
import { UserAction } from '../../types/events';
import type { ConsumerContextProps } from '../ConsumerContextProvider';
import { ConsumerContext } from '../ConsumerContextProvider';
import type { HyperlinkProps } from './types';

export const Hyperlink: FC<PropsWithChildren<HyperlinkProps>> = ({ uri, children }) => {
  const consumerContext: ConsumerContextProps = useContext(ConsumerContext);
  const { logEvent } = consumerContext;

  const onClick = (): void => {
    logEvent?.({
      component: 'Hyperlink',
      type: UserAction.Click,
      label: `Hyperlink: ${uri}`,
      url: uri,
    });

    if (shouldFlushLogs(uri)) {
      void logger.flush();
    }
  };

  return (
    <HyperlinkSDS link={uri} onClick={onClick}>
      {children}
    </HyperlinkSDS>
  );
};

Hyperlink.displayName = 'Hyperlink';
