import type { FC, PropsWithChildren, ReactElement } from 'react';
import { Suspense, useEffect, useState } from 'react';

export const SuspenseWrapper: FC<PropsWithChildren<{ fallbackElement?: ReactElement }>> = ({
  children,
  fallbackElement = null,
}) => {
  const [isServer, setIsServer] = useState(true);

  useEffect(() => {
    setIsServer(false);
  }, []);

  if (isServer) {
    return fallbackElement;
  }

  return <Suspense fallback={fallbackElement}>{children}</Suspense>;
};
