/* eslint-disable max-len */
import type { FC } from 'react';

import type { GenericSvgIconProps } from '../CustomIcon';

export const RewardsIcon: FC<GenericSvgIconProps> = props => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
      <path d="M7.20 16H8.33V13.87C10.81 13.70 12.28 12.13 12.28 10.16C12.28 7.88 10.83 7.03 8.33 6.63V3.17C9.35 3.34 9.88 3.93 10.06 4.95H12.06C11.86 2.86 10.50 1.73 8.33 1.53V0H7.20V1.51C4.90 1.67 3.40 3.07 3.40 4.94C3.40 7.08 4.70 7.99 7.20 8.42V12.21C5.59 12.01 5.22 11.08 5.09 9.99H3C3.16 12.03 4.31 13.67 7.20 13.87V16V16ZM5.39 4.75C5.39 3.91 6.03 3.25 7.19 3.12V6.44C5.71 6.14 5.39 5.63 5.39 4.75ZM10.20 10.36C10.20 11.35 9.46 12.06 8.33 12.21V8.60C9.80 8.92 10.20 9.39 10.20 10.36V10.36Z" />
    </svg>
  );
};
