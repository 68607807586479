import { cx } from '@emotion/css';
import type { FC } from 'react';

import type { BaseComponentProps } from '../../types';
import {
  summaryCardContainerCss,
  summaryCardCss,
  summaryCardDashCss,
  summaryCardImageCss,
  summaryCardRowCss,
  summaryCardSkeletonAnimationCss,
  summaryCardSkeletonContainerCss,
  summaryCardSkeletonCss,
  summaryCardSkeletonGapCss,
} from './SummaryCard.styled';

// https://www.figma.com/file/ZGx8KtLKunDcyUlhT6wtCE/SDS-M-Production?type=design&node-id=7887%3A103257&mode=design&t=y4zjz5uyXzB85w9K-1
/**
 * Skeleton hardcoded structure to render some animated lines and their items
 *
 * @param height Height in pixels for the gray line (and its items).
 * @param items List of items to render within that line - each number represents a width
 *   percentage.
 */

const skeletonLines = [
  {
    height: 8,
    items: [20],
  },
  {
    height: 12,
    items: [40, 17, 25],
  },
  {
    height: 12,
    items: [25, 55],
  },
  {
    height: 8,
    items: [36, 19, 27],
  },
  {
    height: 8,
    items: [15, 46, 25],
  },
  {
    height: 8,
    items: [22, 39],
  },
];

export const SkeletonSummaryCard: FC<BaseComponentProps> = ({ className }) => {
  const summaryCardClassName = cx(summaryCardCss, className);
  const summaryCardContainerClassName = cx(
    summaryCardContainerCss,
    summaryCardSkeletonContainerCss
  );
  const summaryCardImageClassName = cx(summaryCardImageCss, summaryCardSkeletonCss);
  const summaryCardDashClassName = cx(
    summaryCardDashCss,
    summaryCardSkeletonCss,
    summaryCardSkeletonAnimationCss
  );
  const summaryCardRowClassName = cx(summaryCardRowCss, summaryCardSkeletonGapCss);

  return (
    <div className={summaryCardClassName} data-testid="skeleton-summary-card-container">
      <div className={summaryCardContainerClassName}>
        {skeletonLines.map(({ height, items }, lineIndex) => (
          <div
            key={`skeleton-line-${lineIndex}`}
            style={{ height }}
            className={summaryCardRowClassName}
          >
            {items.map((widthPercentage, itemIndex) => (
              <div
                key={`skeleton-line-${lineIndex}-${itemIndex}`}
                className={summaryCardDashClassName}
                style={{ height, width: `${widthPercentage}%` }}
              />
            ))}
          </div>
        ))}
      </div>
      <div className={cx(summaryCardImageClassName, summaryCardSkeletonAnimationCss)} />
    </div>
  );
};

SkeletonSummaryCard.displayName = 'SkeletonSummaryCard';
