import type { FC } from 'react';
import { useContext } from 'react';

import { GlobalComponentsContext } from '../../context/GlobalComponentsContext';
import type { CallToActionAllType } from '../../generated/contentful-types';
import { Button } from '../Button/Button';

export const CallToAction: FC<CallToActionAllType> = props => {
  const { onError } = useContext(GlobalComponentsContext);

  const { presentation, analytics, url } = props;

  if (url) {
    onError?.(new Error('The "url" field on CallToAction is no longer supported'));
  }

  if (presentation.__typename === 'Button') {
    return <Button analyticsLabel={analytics?.label} {...presentation} />;
  }

  onError?.(new Error(`Unable to render CTA presentation "${presentation.__typename}"`));
  return null;
};

CallToAction.displayName = 'CallToAction';
