import { gql } from '@apollo/client';

import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';

// ================================================================================================
// Definition Fragment
// ================================================================================================

export const definitionFragment = gql`
  fragment DefinitionAll on Definition {
    ...ContentfulSysId
    displayText
    fullText {
      json
    }
    anchorId
  }
  ${contentfulSysIdFragment}
`;

export const query = gql`
  query DefinitionQuery($preview: Boolean!, $locale: String!, $id: String!) {
    Definition(preview: $preview, locale: $locale, id: $id) {
      ...DefinitionAll
    }
  }
  ${definitionFragment}
`;
