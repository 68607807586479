/**
 * These are the ISO-3166 Alpha-2 codes for the countries in the GDPR region.
 *
 * The 'unknown country' code of ZZ is also included to default to showing the popup when the exact
 * country cannot be determined.
 */
export const gdprCountries = new Set([
  'AT', // Austria
  'BE', // Belgium
  'BG', // Bulgaria
  'CY', // Cyprus
  'CZ', // Czechia
  'DE', // Germany
  'DK', // Denmark
  'EE', // Estonia
  'ES', // Spain
  'FI', // Finland
  'FR', // France
  'GB', // Great Britain
  'GR', // Greece
  'HR', // Croatia
  'HU', // Hungary
  'IE', // Ireland
  'IS', // Iceland
  'IT', // Italy
  'LI', // Liechtenstein
  'LT', // Lithuania
  'LU', // Luxembourg
  'LV', // Latvia
  'MT', // Malta
  'NL', // Netherlands
  'NO', // Norway
  'PL', // Poland
  'PT', // Portugal
  'RO', // Romania
  'SE', // Sweden
  'SI', // Slovenia
  'SK', // Slovakia
  'ZZ', // Unknown
]);
