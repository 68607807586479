import { Config } from '../../config';
import type { ContentfulConfiguration } from '../../configTypes';
import { UrlParameter } from '../../constants/urlParameters';

/**
 * Applies URL overrides to a contentful configuration.
 *
 * Currently only supports the 'contentfulEnvironmentName' param.
 */
export function applyUrlOverrides(
  config: ContentfulConfiguration,
  params: URLSearchParams
): ContentfulConfiguration {
  if (Config.isDeploymentTypeProd) {
    return config;
  }
  const newConfig = {
    ...config,
    environmentName: params.get(UrlParameter.CONTENTFUL_ENVIRONMENT_NAME) ?? config.environmentName,
  };

  return newConfig;
}
