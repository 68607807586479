import isNil from 'lodash/isNil.js';

export interface MediaQueryProps {
  min?: number;
  max?: number;
}

export const mediaQueryForRange = ({ min, max }: MediaQueryProps): string => {
  if (!isNil(min) && !isNil(max)) {
    if (max < min) {
      throw new Error(`max=${max} is less than min=${min}`);
    }

    return `@media screen and (min-width: ${min}px) and (max-width: ${max}px)`;
  }

  if (!isNil(min) && isNil(max)) {
    return `@media screen and (min-width: ${min}px)`;
  }

  if (isNil(min) && !isNil(max)) {
    return `@media screen and (max-width: ${max}px)`;
  }

  return mediaQueryForRange({ min: 0 });
};
