import { css } from '@emotion/css';
import { m, p2Css } from '@snapchat/snap-design-system-marketing';

export const noResultsFoundContainerCss = css`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-block: ${m('--spacing-xl')} 128px;
  width: 100%;
`;

export const noResultsFoundTitleCss = css`
  margin-block-end: ${m('--spacing-xs')};
`;

export const noResultsFoundSubtitleCss = css`
  ${p2Css}
  margin-block-end: ${m('--spacing-l')};
`;

export const noResultsFoundImageCss = css`
  width: 268px;
  max-width: 90%;
`;
