import { gql } from '@apollo/client';
import type { Experiment } from '@snapchat/mw-contentful-schema';

import { createExperimentFragmentWithAbReplacements } from '../../components/Experiment';
import type { PageShallowDataProps } from '../../components/Page';
import { pageFragmentShallow } from '../../components/Page';
import type { Items } from '../../types/Items';
import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';

const custom404Fragment = gql`
  fragment Custom404 on Slug {
    ...ContentfulSysId
    page {
      ...ExperimentOnPageLoadingPageShallow
    }
  }
  ${createExperimentFragmentWithAbReplacements('Page', 'PageShallow', pageFragmentShallow)}
  ${contentfulSysIdFragment}
`;

export const custom404CollectionQuery = gql`
  query Custom404CollectionQuery($preview: Boolean!, $locale: String!) {
    slugCollection(preview: $preview, locale: $locale, where: { slug: "not-found" }, limit: 1) {
      items {
        ...Custom404
      }
    }
  }
  ${custom404Fragment}
`;

interface Custom404Data {
  page: PageShallowDataProps | Experiment;
}

export interface Custom404CollectionData {
  slugCollection: Items<Custom404Data>;
}
