import { css } from '@emotion/css';
import { m, mobileMediaQuery } from '@snapchat/snap-design-system-marketing';

export const localeDropdownContainerCss = css`
  ${mobileMediaQuery} {
    width: 100%;
    .sdsm-dropdown,
    .sdsm-dropdown-button {
      width: 100%;
    }
  }
`;

export const dropdownCss = css`
  display: flex;
  flex-direction: column;
  gap: ${m('--spacing-s')};
`;

export const horizontalDropdownCss = css`
  flex-direction: row;
  align-items: center;

  ${mobileMediaQuery} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const titleCss = css`
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  color: ${m('--foreground-color')};
`;
