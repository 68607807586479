import { gql } from '@apollo/client';
import type { Alignment, BackgroundColor } from '@snapchat/snap-design-system-marketing';

import type { ShallowContent } from '../../../../components/Block';
import { ctaFragment } from '../../../../components/CallToAction';
import type { CallToActionDataProps } from '../../../../components/CallToAction/types';
import type { FormDataProps } from '../../../../components/Form';
import { formFragments } from '../../../../components/Form';
import type { MediaDataProps } from '../../../../components/Media';
import { fragments as assetsFragments } from '../../../../components/Media';
import type { ContentfulSysProps } from '../../../../types/contentful';
import type { Items } from '../../../../types/Items';
import type { RichText } from '../../../../types/RichText';
import { contentfulSysIdFragment } from '../../../../utils/contentful/contentfulQuery';

export interface ArArkoseProtectedFormBlockQuery {
  arArkoseProtectedFormBlock: ArArkoseProtectedFormBlockDataProps;
}

export interface ArArkoseProtectedFormBlockDataProps extends ContentfulSysProps {
  name: string;
  arkosePublicDevKey: string;
  arkosePublicProdKey: string;
  protectedForm: FormDataProps;
  eyebrow?: string;
  title?: RichText | string;
  titleFromEmail?: RichText | string;
  subtitle?: RichText | string;
  subtitleFromEmail?: RichText | string;
  titleAlignment?: Alignment;
  titleAlignmentMobile?: Alignment;
  maxColumns?: 1 | 2 | 3;
  backgroundColor: BackgroundColor;
  backgroundMedia?: MediaDataProps;
  showCurtain?: boolean;
  callsToActionCollection: Items<CallToActionDataProps>;
  contentsCollection?: Items<ShallowContent>;
  anchorId?: string;
  isNarrow?: boolean;
  widthStyle?: string;
}

export const ArArkoseProtectedFormBlockFragment = {
  all: gql`
    fragment ArArkoseProtectedFormBlockAll on ArArkoseProtectedFormBlock {
      ...ContentfulSysId
      arkosePublicDevKey
      arkosePublicProdKey
      eyebrow
      maxColumns
      isNarrow
      anchorId
      showCurtain
      backgroundColor
      backgroundMedia {
        ...AssetAll
      }
      widthStyle
      title {
        json
      }
      titleFromEmail {
        json
      }
      subtitle {
        json
      }
      subtitleFromEmail {
        json
      }
      titleAlignment
      titleAlignmentMobile
      maxColumns
      callsToActionCollection(limit: 9) {
        items {
          ... on CallToAction {
            ...CallToActionAll
          }
        }
      }
      protectedForm {
        ... on Form {
          ...FormAll
        }
      }
    }
    ${contentfulSysIdFragment}
    ${formFragments.all}
    ${ctaFragment}
    ${assetsFragments.all}
  `,
};

export const query = gql`
  query ArArkoseProtectedFormBlockQuery($id: String!, $locale: String!) {
    arArkoseProtectedFormBlock(id: $id, locale: $locale) {
      ...ArArkoseProtectedFormBlockAll
    }
  }
  ${ArArkoseProtectedFormBlockFragment.all}
`;
