import type { TrackingParameters } from '@snapchat/snap-design-system-marketing';
import {
  appendTrackingParameters,
  FooterItemV2 as FooterItemSDS,
} from '@snapchat/snap-design-system-marketing';
import snakeCase from 'lodash-es/snakeCase';
import type { FC } from 'react';
import { useContext } from 'react';

import { Action } from '../../../analytics/Action';
import { GlobalComponentsContext } from '../../../context/GlobalComponentsContext';
import type { FooterGroupItem } from '../FooterV3Types';

const defaultTrackingParameters: TrackingParameters = {
  utm_source: 'global_footer',
  utm_medium: 'referral',
  utm_campaign: 'universal_navigation',
  utm_content: 'footer_item_link',
};

export const FooterItemV3: FC<Omit<FooterGroupItem, 'type'>> = props => {
  const { id, url, title, analyticsLabel } = props;
  const { isUrlCurrent, onEvent, hostname } = useContext(GlobalComponentsContext);

  const trackingParameters = hostname
    ? { ...defaultTrackingParameters, utm_source: snakeCase(hostname) }
    : defaultTrackingParameters;

  const trackedUrl = appendTrackingParameters(url, trackingParameters);

  const onClick = () => {
    onEvent?.({
      action: Action.Click,
      component: 'FooterItem',
      label: analyticsLabel,
      url: trackedUrl,
    });
  };

  return (
    <FooterItemSDS
      id={id}
      key={id}
      isSelected={isUrlCurrent?.(url ?? '')}
      url={trackedUrl}
      title={title}
      onClick={onClick}
    />
  );
};
