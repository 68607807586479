import { css } from '@emotion/css';

import { mobileMediaQuery } from '../../constants';
import { ZIndex } from '../../constants/zIndex';
import { m } from '../../motif/m';
import { globalHeaderHeight } from '../GlobalHeader/GlobalHeader.constants';

export const filtersCss = css`
  display: flex;
  justify-content: center;
  padding: ${m('--spacing-m')} 0;
  margin-bottom: ${m('--spacing-xxxxl')};
`;

export const containerCss = (columns?: number, mobileColumns?: number): string => css`
  display: grid;
  grid-auto-flow: dense;
  transition: filter 0.2s;
  grid-template-columns: repeat(${Math.max(1, columns ?? 4)}, 1fr);

  ${mobileMediaQuery} {
    grid-template-columns: repeat(${Math.max(1, mobileColumns ?? columns ?? 2)}, 1fr);
    padding: 0 8px;
  }
`;

export const wrapperCss = css`
  position: relative;
  border-radius: ${m('--border-radius-xl')};
  overflow: hidden;
  z-index: 0;

  &:before {
    content: '';
    display: block;
    height: 0;
  }

  @media (hover: hover) {
    transition: box-shadow 0.2s;

    div[data-poster] {
      transition: opacity 0.2s;
    }

    &:hover {
      box-shadow: ${m('--box-shadow-xl')};

      div[data-poster] {
        opacity: 0;
      }
    }
  }
`;

export const coveredTileCss = css`
  min-height: 100%;
`;

export const overlayCss = css`
  position: absolute;
  inset: 0;
  z-index: 1;
  display: flex;
  align-content: center;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding: ${m('--spacing-xl')};
  font-size: 16px;

  [data-playing='true'] > & {
    opacity: 0;
  }

  [data-overlay='true'] > & {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 42.19%, rgba(0, 0, 0, 0.9) 100%);
  }

  [data-overlay='false'] > & {
    font-weight: 700;
    font-size: 24px;
    line-height: 38px;
  }

  ${mobileMediaQuery} {
    padding: ${m('--spacing-m')};
  }
`;

export const titleCss = css`
  flex: 1 1 auto;
  width: 100%;
  color: ${m('--mosaic-title-color')};
`;

export const highlightCss = css`
  display: inline-block;
  color: ${m('--mosaic-highlight-color')};
  white-space: nowrap;

  &:after {
    /* NOTE: Emotion has a rule that there has to be a space at the end here. */
    content: '\\00a0 ';
  }
`;

export const durationCss = css`
  margin-inline-start: auto;
  flex: 0 0 auto;
  color: ${m('--mosaic-duration-color')};
`;

export const videoCss = css`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const playButtonCss = css`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  [data-playing='true'] > & {
    opacity: 0;
  }
`;

export const tileCss = (
  rows?: number,
  columns?: number,
  mobileRows?: number,
  mobileColumns?: number
): string => css`
  cursor: pointer;
  position: relative;
  padding: calc(${m('--mosaic-grid-gap')} / 2);
  grid-row: span ${Math.max(1, rows ?? 1)};
  grid-column: span ${Math.max(1, columns ?? 1)};

  ${mobileMediaQuery} {
    padding: ${m('--spacing-xs')};
    grid-row: span ${Math.max(1, mobileRows ?? rows ?? 1)};
    grid-column: span ${Math.max(1, mobileColumns ?? columns ?? 1)};
  }
`;

export const posterCss = css`
  position: absolute;
  inset: 0;
`;

export const posterImageCss = css`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.2s;
`;

// TODO: This CSS is pretty hacky. But for some reason neither flex nor
// grid layout the <video> component without very specific boundaries.
// We should revisit when someoen has a week to dig into this.
export const lightBoxContainerCss = css`
  /* This is set such that the 40px close button fits in the middle. */
  --lightbox-horizontal-padding: 80px;
  --lightbox-vertical-padding: 80px;

  ${mobileMediaQuery} {
    --lightbox-horizontal-padding: 24px;
    --lightbox-vertical-padding: 80px;
  }

  width: calc(100vw - calc(2 * var(--lightbox-horizontal-padding)));
  width: calc(100dvw - calc(2 * var(--lightbox-horizontal-padding)));
  height: calc(100vh - calc(2 * var(--lightbox-vertical-padding)));
  height: calc(100dvh - calc(2 * var(--lightbox-vertical-padding)));
`;

export const lightBoxCss = css`
  height: calc(100% + var(--lightbox-vertical-padding));
  display: grid;
  grid-template-rows: calc(100% - 100px) auto;
  align-items: center;

  margin: 0;
  margin-bottom: calc(0px - var(--lightbox-vertical-padding));

  z-index: ${ZIndex.MOSAIC_LIGHTBOX};
  inset: ${globalHeaderHeight}px 0 0;
  transition: opacity 0.2s;

  &[data-active='false'] {
    opacity: 0;
    pointer-events: none;
  }
`;

export const lightBoxControlsCss = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const lightBoxCaptionCss = css`
  color: ${m('--foreground-color')};
  width: 100%;
  padding: ${m('--spacing-l')};
  text-align: center;
`;

export const lightBoxVideoCss = css`
  border-radius: ${m('--border-radius-l')};
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
`;

export const lightboxNavLeftIconCss = css`
  margin: 5px 0;
  transform: rotate(270deg);
  [dir='rtl'] & {
    transform: rotate(90deg);
  }
`;

export const lightboxNavRightIconCss = css`
  margin: 5px -1px 5px 1px;
  transform: rotate(270deg);
  [dir='rtl'] & {
    transform: rotate(90deg);
  }
`;
