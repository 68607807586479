import { cx } from '@emotion/css';
import {
  Button as ButtonSDS,
  FormattedMessage,
  MotifScheme,
} from '@snapchat/snap-design-system-marketing';
import type { FC, ReactNode, Ref } from 'react';
import { createRef, useContext, useEffect, useState } from 'react';

import { ShopifyContext } from '../ShopifyProvider';
import { addToCartButtonCss, addToCartTooltipCss, disabledButtonCss } from './styles';
import type { AddToCartProps } from './types';

const AddToCartButton: FC<AddToCartProps> = ({ productHandle = '', showTooltip }) => {
  const { cart, products, currencyCode = '' } = useContext(ShopifyContext);
  const quantityInCart = cart.lineItems[productHandle] ?? 0;
  const shopifyProduct = products.shopify[productHandle]!;
  const contentfulProduct = products.contentful[productHandle]!;
  const relatedItems = contentfulProduct?.relatedItemsCollection?.items ?? [];

  const renderTooltip = (): ReactNode => {
    if (quantityInCart === shopifyProduct.maxQuantity || relatedItemInCart) {
      return (
        <FormattedMessage
          id="maxCartQuantity"
          values={{ quantity: String(shopifyProduct.maxQuantity) }}
          defaultMessage="Limit {quantity} per customer."
        />
      );
    }

    return null;
  };

  if (!shopifyProduct) {
    return null;
  }

  const relatedItemInCart = relatedItems.some(
    ({ productHandle: relatedItemHandle }) =>
      Object.keys(cart.lineItems).indexOf(relatedItemHandle) !== -1
  );

  const disabled =
    quantityInCart === shopifyProduct.maxQuantity || !shopifyProduct.available || relatedItemInCart;

  return (
    <>
      <ButtonSDS
        type="Primary"
        className={disabled ? disabledButtonCss : undefined}
        onClick={() =>
          cart.addOne({
            handle: productHandle,
            contentfulProduct,
            shopifyProducts: products.shopify,
            currencyCode,
          })
        }
        disabled={disabled}
      >
        <FormattedMessage id="addToCart" defaultMessage="Add to Cart" />
      </ButtonSDS>
      {showTooltip && disabled && <span className={addToCartTooltipCss}>{renderTooltip()}</span>}
    </>
  );
};

export const AddToCart: FC<AddToCartProps> = ({ className, productHandle = '' }) => {
  const { store, products } = useContext(ShopifyContext);
  const isProductAvailableInCountry = !!store;
  const buttonRef: Ref<HTMLDivElement> = createRef();
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const shopifyProduct = products.shopify[productHandle];

  useEffect(() => {
    const button = buttonRef.current;

    if (!button) return;

    const onButtonMouseEnter = () => setShowTooltip(true);
    const onButtonMouseLeave = () => setShowTooltip(false);

    button.addEventListener('mouseenter', onButtonMouseEnter);
    button.addEventListener('mouseleave', onButtonMouseLeave);

    return () => {
      button.removeEventListener('mouseenter', onButtonMouseEnter);
      button.removeEventListener('mouseleave', onButtonMouseLeave);
    };
  }, [buttonRef]);

  return (
    <div ref={buttonRef} className={cx(className, MotifScheme.TERTIARY, addToCartButtonCss)}>
      {isProductAvailableInCountry && shopifyProduct?.available ? (
        <AddToCartButton productHandle={productHandle} showTooltip={showTooltip} />
      ) : (
        <>
          {/* TODO: Add this to localized messages when we have a shop again. */}
          Product not available in your country
        </>
      )}
    </div>
  );
};

AddToCart.displayName = 'AddToCart';
