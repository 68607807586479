import type { AxisScale } from '@visx/axis';
import { getStringWidth } from '@visx/text';
import type { XYChartTheme } from '@visx/xychart';
import {
  AnimatedAreaStack,
  AnimatedBarGroup,
  AnimatedBarStack,
  AreaStack,
  BarGroup,
  BarStack,
} from '@visx/xychart';
import type { ReactElement } from 'react';
import { Fragment } from 'react';

import type { RequiredChartProps } from './types';

export const tickFormatter = (value: string | number, locale = 'en-US'): string => {
  if (typeof value === 'number') {
    return numberFormatter(value, locale);
  }

  return value;
};

const numberFormatter = (value: number, locale?: string) => {
  return value.toLocaleString(locale, { notation: 'compact' });
};

export const dateFormatter = (date: Date, locale?: string): string => {
  const options = { month: 'long', year: 'numeric', day: 'numeric' } as const;
  return date.toLocaleDateString(locale, options);
};

type YAccessor<Datum, XScale extends AxisScale, YScale extends AxisScale> = RequiredChartProps<
  Datum,
  XScale,
  YScale
>['accessors']['yAccessor'];

/** Pass a series of data and an accessor to find out how wide the largest label will be on an axis */
export function getMaxLabelWidth<Datum, XScale extends AxisScale, YScale extends AxisScale>(
  data: Datum[],
  yAccessor: YAccessor<Datum, XScale, YScale>,
  locale?: string
): number {
  return data.reduce((currentMax: number, currentData) => {
    const currValue = getStringWidth(tickFormatter(yAccessor(currentData), locale)) ?? 0;
    return currentMax < currValue ? currValue : currentMax;
  }, 0);
}

/**
 * This function creates an object with data keys linked to theme colors to pass to tooltip This is
 * necessary because of the filter function changing the order of colors for groups and stacks
 */
export function getDataKeysToColors(
  element: ReactElement,
  theme: XYChartTheme
): Record<string, string> {
  const multipleElements =
    element.type === BarGroup ||
    element.type === AnimatedBarGroup ||
    element.type === BarStack ||
    element.type === AnimatedBarStack ||
    element.type === AreaStack ||
    element.type === AnimatedAreaStack ||
    element.type === Fragment;

  if (multipleElements) {
    return element.props.children.reduce(
      (colors: Record<string, string>, child: JSX.Element, index: number) => {
        colors[child.props.dataKey] = theme.colors[index]!;
        return colors;
      },
      {}
    );
  }

  return {
    [element.props.dataKey ?? 'default']: theme.colors[0]!,
  };
}
