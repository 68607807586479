import { gql } from '@apollo/client';

import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';
import { ctaFragment } from '../CallToAction/query';
import { criteriaFragment } from '../Experiment';

// Uses import { NavigationCallToAction } from '@snapchat/mw-contentful-schema';
export const navigationCtaFragment = gql`
  fragment NavigationCta on NavigationCallToAction {
    ...ContentfulSysId
    callToAction {
      ...CallToActionAll
    }
    displayModes
    hideCriteria {
      ...CriteriaAll
    }
  }
  ${criteriaFragment}
  ${contentfulSysIdFragment}
  ${ctaFragment}
`;

export const query = gql`
  query NavigationCtaQuery($preview: Boolean!, $locale: String!, $id: String!) {
    cavigationCta(preview: $preview, locale: $locale, id: $id) {
      ...NavigationCta
    }
  }
  ${navigationCtaFragment}
`;
