export function isDate<T>(date?: Date | T): date is Date {
  if (!date) {
    return false;
  }

  return typeof (date as Date).getFullYear === 'function';
}

export const dateDesktopString = (date: Date): string => {
  return date.toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

export const dateMobileString = (date: Date): string => {
  return date.toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'numeric',
    day: '2-digit',
  });
};
