import { cx } from '@emotion/css';
import type { FC } from 'react';

import { useMediaMode } from '../../hooks/useMediaMode';
import { MotifComponent, useMotifStyles } from '../../motif';
import { getBackgroundClassName } from '../../utils/themeUtils';
import { FooterContext } from './FooterContext';
import type { FooterV2Props } from './types';

export const FooterV2: FC<FooterV2Props> = ({ children, backgroundColor }) => {
  const mode = useMediaMode();
  useMotifStyles(MotifComponent.FOOTER);

  return (
    <FooterContext.Provider
      value={{
        mode,
      }}
    >
      <footer
        data-testid="footer-v2"
        className={cx(MotifComponent.FOOTER, getBackgroundClassName(backgroundColor))}
      >
        {children}
      </footer>
    </FooterContext.Provider>
  );
};
