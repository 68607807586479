import { css } from '@emotion/css';

export const videoCss = css`
  width: 100%;
  height: 100%;
  display: block;
`;

const objectFitCoverCss = css`
  object-fit: cover;
`;

const objectFitContainCss = css`
  object-fit: contain;
`;

export const getObjectFit = (isBackgroundVideo?: boolean): string => {
  return isBackgroundVideo ? objectFitCoverCss : objectFitContainCss;
};
