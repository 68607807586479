import { lazyComponent } from '@snapchat/mw-common/client';
import type { MotifRootProps } from '@snapchat/snap-design-system-marketing';
import { MotifRoot } from '@snapchat/snap-design-system-marketing';
import { type FC, type PropsWithChildren, useContext } from 'react';

import { AppContext } from '../../AppContext';
import { Config } from '../../config';
import { UrlParameter } from '../../constants/urlParameters';
import { customBlueMotif } from '../../styles/customBlueMotif';
import { customGreenMotif } from '../../styles/customGreenMotif';
import { SuspenseWrapper } from '../SuspenseWrapper';

const LazyCustomMotifHandler = lazyComponent(async () =>
  import('./LazyCustomMotifHandler').then(module => ({
    default: module.LazyCustomMotifHandler,
  }))
);

/**
 * Wrapper for motif root that lets us run feature flags for motifs. Currently no experiments being
 * run, but keeping the wrapper for future use.
 */
export const MotifRootWrapper: FC<PropsWithChildren<MotifRootProps>> = ({
  children,
  motif,
  ...passThroughProps
}) => {
  const { getCurrentUrl } = useContext(AppContext);

  let finalMotif = motif;

  const url = new URL(getCurrentUrl());
  const useAlternateMotif = url.searchParams.get(UrlParameter.USE_ALTERNATE_MOTIF);

  if (Config.isDeploymentTypeProd) {
    // Do nothing. The theme is cached, so we can't turn on the easter egg.
  } else if (useAlternateMotif === 'true' || useAlternateMotif === 'green') {
    finalMotif = customGreenMotif;
  } else if (useAlternateMotif === 'blue') {
    finalMotif = customBlueMotif;
  } else if (useAlternateMotif === 'figma-upload') {
    return (
      <SuspenseWrapper fallbackElement={<p>...Loading</p>}>
        <LazyCustomMotifHandler motif={motif} {...passThroughProps}>
          {children}
        </LazyCustomMotifHandler>
      </SuspenseWrapper>
    );
  }

  return (
    <MotifRoot motif={finalMotif} {...passThroughProps}>
      {children}
    </MotifRoot>
  );
};
