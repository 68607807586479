import { gql } from '@apollo/client';
import type {
  Maybe,
  Scalars,
  ScrollAnimatedSectionAnimatedContentCollection,
  ScrollAnimatedSectionMobileContentCollection,
} from '@snapchat/mw-contentful-schema';
import type { EntitySys } from 'contentful';

import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';
import { blockAllFragment } from '../Block';
import { assetFragment } from '../Media';

export const scrollAnimatedVideoFragment = gql`
  fragment ScrollAnimatedVideoAll on ScrollAnimatedVideo {
    ...ContentfulSysId
    mp4Source {
      ...AssetAll
    }
    webmSource {
      ...AssetAll
    }
    scrubStart
    scrubEnd
    videoStart
    videoEnd
  }
  ${contentfulSysIdFragment}
  ${assetFragment}
`;

export const scrollAnimatedSectionFragment = gql`
  fragment ScrollAnimatedSectionAll on ScrollAnimatedSection {
    ...ContentfulSysId
    scrollHeight
    animatedContentCollection {
      items {
        ...ScrollAnimatedVideoAll
      }
    }
    mobileContentCollection {
      items {
        ...BlockAll
      }
    }
  }
  ${scrollAnimatedVideoFragment}
  ${contentfulSysIdFragment}
  ${assetFragment}
  ${blockAllFragment}
`;

export const query = gql`
  query ScrollAnimatedSectionQuery($preview: Boolean!, $locale: String!, $id: String!) {
    scrollAnimatedSection(preview: $preview, locale: $locale, id: $id) {
      ...ScrollAnimatedSectionAll
    }
  }
  ${scrollAnimatedSectionFragment}
`;

export interface ScrollAnimatedSectionDataProps {
  __typename: 'ScrollAnimatedSection';
  animatedContentCollection?: ScrollAnimatedSectionAnimatedContentCollection;
  mobileContentCollection?: ScrollAnimatedSectionMobileContentCollection;
  scrollHeight?: Maybe<Scalars['Float']>;
  sys: EntitySys;
}
