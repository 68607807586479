import { css, cx } from '@emotion/css';
import type { FC, PropsWithChildren } from 'react';

import { maxWidth, narrowWidth, nonMobileMediaQuery, reducedWidth } from '../../constants';
import { MotifComponent } from '../../motif/motifConstants';
import { useMotifStyles } from '../../motif/motifReactUtils';
import type { BaseComponentProps } from '../../types';

export type WidthType = 'reduced' | 'narrow' | 'full';

const styleCss = css`
  margin: auto;
  max-width: ${maxWidth}px;
`;

// TODO: update styles to match button styling css.
const styleReducedCss = css`
  ${nonMobileMediaQuery} {
    max-width: ${reducedWidth}px;
    width: 80%;
  }
`;

const styleNarrowCss = css`
  max-width: ${narrowWidth}px;
`;

interface Props extends BaseComponentProps {
  /** @deprecated Use widthStyle instead. */
  isNarrow?: boolean;
  widthStyle?: WidthType;
}

/** Container for block elements that ensures that they don't get too wide. */
export const BlockBoundary: FC<PropsWithChildren<Props>> = ({
  children,
  className,
  isNarrow,
  widthStyle,
  ...passThroughProps
}) => {
  useMotifStyles(MotifComponent.BLOCK_BOUNDARY);

  // TODO: Keep is isNarrow field for now to keep forBiz in tact until we fully
  // migrate it to marketing-web
  className = cx(
    MotifComponent.BLOCK_BOUNDARY,
    styleCss, // Has to come first so that later classnames can override
    {
      [styleReducedCss]: widthStyle === 'reduced',
      [styleNarrowCss]: isNarrow || widthStyle === 'narrow',
    },
    className
  );

  return (
    <div {...passThroughProps} className={className}>
      {children}
    </div>
  );
};

BlockBoundary.displayName = 'BlockBoundary';
