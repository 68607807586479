import type { FC } from 'react';

import { useFormatDate } from '../../hooks/useFormatDate';
import type { RichText } from '../../types/RichText';
import { renderString } from '../../utils/renderString';
import { renderRichTextMarkingsOnly } from '../../utils/renderText/renderRichText';

interface HeroHeaderProps {
  header?: RichText | string;
  isExpectingDate?: boolean;
  fallbackDate?: Date;
}

const dateFormatOptions: Intl.DateTimeFormatOptions = {
  month: 'long',
  day: '2-digit',
  year: 'numeric',
  // NOTE: Always render these dates assuming UTC timezone to ensure consistent rendering between server and client.
  //       Otherwise we would need to determine the client's timezone whenever we process the initial render on the server.
  //       By using UTC for all cases we ensure consistent behavior, though we may be off by 1 day depending on the user's
  //       location.
  timeZone: 'UTC',
};

/**
 * Helper function that parses the props for rendering a the Hero Header and returns a React
 * Component or null. Returning null here enables us to conditionally render downstream components
 * by checking whether the output component exists.
 */
export const getHeroHeaderComponent = ({
  fallbackDate,
  header,
  isExpectingDate,
}: HeroHeaderProps): FC | null => {
  // Normal case: render specified header text
  if (!isExpectingDate && header) {
    return () => <>{renderRichTextMarkingsOnly(header)}</>;
  }

  const headerText = typeof header === 'string' ? header : renderString(header) ?? '';
  const parsedDate: Date | undefined = new Date(headerText);
  const isParsedDateValid = !Number.isNaN(parsedDate.getTime());

  // Normal case: User specified date
  if (isExpectingDate && isParsedDateValid) {
    return () => {
      const { formatDate } = useFormatDate();
      return <>{formatDate(parsedDate, dateFormatOptions)}</>;
    };
  }

  // Normal case: User wants date to render using system generated date
  // Edge case: User specified a malformed date string
  if (isExpectingDate && fallbackDate) {
    return () => {
      const { formatDate } = useFormatDate();
      return <>{formatDate(fallbackDate, dateFormatOptions)}</>;
    };
  }

  // Normal case: No header text or date specified, don't render
  return null;
};
