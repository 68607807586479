import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';

import { useContentfulQuery } from '../../hooks/useContentfulQuery';
import { query } from './query';
import type { FaviconDataHandlerProps } from './types';

export const Favicon: FC = () => {
  const { data } = useContentfulQuery<FaviconDataHandlerProps>(query, {});

  if (!data) return null;

  const publishedFavicon = data?.faviconCollection?.items?.some(faviconEntry =>
    Boolean(faviconEntry.asset)
  );

  if (!publishedFavicon) {
    return (
      <Helmet>
        <link rel="icon" type="image/png" href="/images/favicon.png" />
      </Helmet>
    );
  }

  return (
    <Helmet>
      {data.faviconCollection.items
        .filter(faviconEntry => Boolean(faviconEntry.asset))
        .map(faviconEntry => {
          const { sys, asset, size } = faviconEntry;
          return (
            <link
              key={sys.id}
              rel="icon"
              type="image/png"
              href={`${asset.url}?fm=png`}
              sizes={size ? `${size}x${size}` : undefined}
            />
          );
        })}
    </Helmet>
  );
};
