import Cookies from 'js-cookie';

import type { CookieOptions } from '../utils/cookies/CookieManager';
import { CookieManager } from '../utils/cookies/CookieManager';

export class BrowserCookieManager extends CookieManager {
  /** @override */
  public getCookie = (name: string): string | undefined => {
    return Cookies.get(name);
  };

  /** @override */
  public setCookie = (name: string, value: string, options: CookieOptions): void => {
    Cookies.set(name, value, options);
  };

  /** @override */
  public deleteCookie = (name: string): void => {
    return Cookies.remove(name);
  };
}
