import throttle from 'lodash/throttle.js';
import { useEffect } from 'react';

function handleResize() {
  // documentElement size isn't always updated upon removal of nav bars on mobile
  // clients, so we supplement it with the window inner size.
  const height = Math.max(document.documentElement.clientHeight, window.innerHeight);
  const width = Math.max(document.documentElement.clientWidth, window.innerWidth);
  document.documentElement.style.setProperty('--client-height', `${height}px`);
  document.documentElement.style.setProperty('--client-width', `${width}px`);
}

/**
 * Sets css variables on document to client height and width on mount and screen resize. Css
 * varibale names are --client-height, --client-width
 */
export function useSetClientSize(): void {
  useEffect(() => {
    // NOTE: We throttle and fire after the window for any trailing executions to make
    // sure that we pick up the latest size.
    const throttleResize = throttle(handleResize, /* singleFireWindowMs */ 50, { trailing: true });
    window.addEventListener('resize', throttleResize);

    // Sets the initial variable values.
    handleResize();

    return () => window.removeEventListener('resize', throttleResize);
  }, []);
}
