import { createHttpLink } from '@apollo/client';
import { fetch } from 'cross-fetch';

import {
  globalComponentsAccessToken,
  globalComponentsEnvName,
  globalComponentsSpaceId,
} from './contentfulConstants';

/**
 * Default Link for the global components apollo.
 *
 * Use this when you don't already have an apollo client installed.
 *
 * Usage:
 *
 * <ApolloProvider client={new ApolloClient({ link: globalComponentsApolloLink, cache: new
 * InMemoryCache() })}> ... </ApolloProvider>
 */
export const globalComponentsApolloLink = createHttpLink({
  uri: `https://graphql.contentful.com/content/v1/spaces/${globalComponentsSpaceId}/environments/${globalComponentsEnvName}`,
  credentials: 'same-origin',
  headers: {
    Authorization: `Bearer ${globalComponentsAccessToken}`,
  },
  fetch,
});
