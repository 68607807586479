import { css, cx } from '@emotion/css';
import type { FC } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

import { mobileMediaQuery } from '../../constants';
import { Icon } from '../Icon';
import { Media } from '../Media';
import { isVideoPlaying } from '../Video/utils';
import {
  coveredTileCss,
  durationCss,
  highlightCss,
  overlayCss,
  playButtonCss,
  posterCss,
  posterImageCss,
  titleCss,
  videoCss,
  wrapperCss,
} from './styles';
import type { MosaicTileProps } from './types';

export const MosaicTile: FC<MosaicTileProps> = ({
  columns = 1,
  rows = 1,
  mobileColumns = columns,
  mobileRows = rows,
  cover = true,
  poster,
  preview,
  showOverlay,
  highlight,
  title,
  duration,
  onEnter,
  onLeave,
}) => {
  //TODO: use a callback instead of a ref as in https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
  const containerRef = useRef<HTMLDivElement>(null);
  const [playing, setPlaying] = useState(false);

  const ratio = rows / columns;
  const mobileRatio = mobileRows / mobileColumns;

  const ratioCss = css`
    &:before {
      padding-bottom: ${ratio * 100}%;
    }
    ${mobileMediaQuery} {
      &:before {
        padding-bottom: ${mobileRatio * 100}%;
      }
    }
  `;

  const onTileEnter = useCallback(async () => {
    onEnter?.();
    //TODO: use a forwardRef here: Video component should forward the video tag ref
    const video = containerRef.current?.querySelector('video');

    if (!video) {
      return;
    }
    await video.play();
    setPlaying(true);
  }, [containerRef, onEnter]);
  const onTileLeave = useCallback(() => {
    onLeave?.();
    //TODO: ditto `onTileEnter`
    const video = containerRef.current?.querySelector('video');

    if (!video) {
      return;
    }

    if (isVideoPlaying(video)) {
      video.pause();
    }
    video.currentTime = 0;
    setPlaying(false);
  }, [containerRef, onLeave]);

  useEffect(() => {
    const container = containerRef.current;
    container?.addEventListener('mouseenter', onTileEnter);
    container?.addEventListener('mouseleave', onTileLeave);

    return () => {
      container?.removeEventListener('mouseenter', onTileEnter);
      container?.removeEventListener('mouseleave', onTileLeave);
    };
  }, [containerRef, onTileEnter, onTileLeave]);

  return (
    <div
      ref={containerRef}
      className={cx(ratioCss, wrapperCss, cover ? coveredTileCss : undefined)}
      data-playing={playing}
      data-overlay={!!showOverlay}
    >
      {preview && (
        <Media className={videoCss} isBackgroundVideo={true} autoPlay={false} {...preview} />
      )}
      {poster && (
        <div className={posterCss} data-poster>
          <img
            alt={title ?? highlight ?? ''}
            src={poster.src}
            srcSet={poster.srcSet}
            className={posterImageCss}
          />
        </div>
      )}
      <div className={overlayCss}>
        {(title || highlight) && (
          <div className={titleCss}>
            {highlight && <div className={highlightCss}>{highlight}</div>}
            {title}
          </div>
        )}
        {duration && <div className={durationCss}>{duration}</div>}
        <div className={playButtonCss}>
          <Icon name="play-filled" />
        </div>
      </div>
    </div>
  );
};
