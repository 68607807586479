import type { BrowserInfo } from '@snapchat/client-hints';

import { Config } from '../../config';
import type { UserAgentHints } from '../../types/userAgentHints';

export function userAgentHintsFromBowser(browserFeatures: BrowserInfo): UserAgentHints {
  const hints = browserFeatures.getLowEntropyHints();
  const browserVersion = hints.browsers[0]?.majorVersion;
  return {
    isMobile: hints.isMobile,
    brand: hints.browsers[0]?.brand, // "Edge".
    brandVersion: browserVersion ? String(browserVersion) : undefined, // "75.2"
    platform: hints.platform, // "Windows", "macOS" etc.
  };
}

/**
 * Starting from iOS 13, iPad uses MacOS for its UA. As of April 2023 there's no touch screen Mac,
 * so we can hack around this by checking for maxTouchPoints. We want to limit this workaround as
 * much as possible. Note we cannot detect Firefox via UA on iPad reliably, so we can only limit it
 * to iPad.
 *
 * NOTE: Relies on client features (navigator), always returns false for SSR
 */
export const isIpad = (browserFeatures: BrowserInfo): boolean => {
  // Subsequent check relies on client features, always return false for SSR
  if (!Config.isClient) return false;

  // Normal case: Check for Mac OS with touchpoints
  const hints = browserFeatures.getLowEntropyHints();
  return hints.platform === 'macOS' && !!navigator?.maxTouchPoints && navigator?.maxTouchPoints > 1;
};
