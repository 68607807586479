/* eslint-disable max-len */
import type { FC } from 'react';

import type { GenericSvgIconProps } from '../CustomIcon';

export const FlashlightIcon: FC<GenericSvgIconProps> = props => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.15 0.20C13.70 0.43 14.27 0.88 14.77 1.47L14.47 1.77L14.77 1.47C15.28 2.07 15.63 2.73 15.79 3.34C15.94 3.92 15.93 4.59 15.49 5.01L15.22 4.68L15.49 5.01L8.62 11.58C8.69 11.84 8.74 12.09 8.75 12.33C8.77 12.66 8.74 12.99 8.57 13.28L8.55 13.31L8.52 13.35L6.53 15.59L6.52 15.61L6.50 15.62C6.24 15.87 5.91 15.96 5.58 15.95C5.24 15.93 4.89 15.83 4.53 15.66C3.81 15.32 3.03 14.68 2.31 13.84C1.62 13.01 0.94 12.04 0.52 11.18C0.31 10.76 0.15 10.34 0.09 9.96C0.03 9.59 0.06 9.15 0.38 8.85L0.39 8.83L0.41 8.82L3.03 6.80L3.06 6.78L3.09 6.76C3.37 6.62 3.69 6.62 3.99 6.68C4.21 6.72 4.45 6.80 4.68 6.90L11.57 0.32L11.57 0.31L11.58 0.31C12.02 -0.08 12.64 -0.01 13.15 0.20ZM12.11 0.97L5.13 7.64C5.67 7.99 6.24 8.50 6.76 9.13C7.29 9.76 7.70 10.42 7.98 11.02L14.95 4.36C15.03 4.28 15.12 4.05 15.00 3.57C14.88 3.12 14.60 2.57 14.16 2.06C13.73 1.54 13.25 1.18 12.85 1.01C12.43 0.83 12.20 0.89 12.11 0.97ZM0.92 9.51L1.32 9.19C2.10 9.05 3.44 9.85 4.58 11.21C5.67 12.51 6.27 13.93 6.14 14.76L5.95 14.98C5.88 15.03 5.78 15.08 5.60 15.07C5.41 15.07 5.16 15.00 4.86 14.86C4.28 14.58 3.58 14.03 2.92 13.25C2.25 12.46 1.62 11.55 1.24 10.78C1.05 10.39 0.94 10.06 0.90 9.82C0.87 9.61 0.90 9.53 0.92 9.51ZM7.04 6.35L9.37 4.12C9.53 3.97 9.78 3.98 9.92 4.16C10.07 4.33 10.05 4.59 9.89 4.74L7.57 6.96C7.40 7.12 7.16 7.10 7.02 6.93C6.87 6.76 6.88 6.50 7.04 6.35Z"
      />
    </svg>
  );
};
