import type { NavigatorItemMobileProps } from './NavigatorItemMobile/types';

/**
 * Utility to get Header menu's column dividers. Dividers are shown if header menu has two or more
 * columns.
 *
 * @param {string} props.col1Label - The label for the first column.
 * @param {NavigatorItemProps[]} props.col1Items - The items in the first column.
 * @param {string} props.col2Label - The label for the second column.
 * @param {NavigatorItemProps[]} props.col2Items - The items in the second column.
 * @param {string} props.col3Label - The label for the third column.
 * @param {NavigatorItemProps[]} props.col3Items - The items in the third column.
 * @returns {number[]} An array of column IDs that should have a divider.
 */
export const getColsWithDivider = ({
  col1Label,
  col1Items = [],
  col2Label,
  col2Items = [],
  col3Label,
  col3Items = [],
}: NavigatorItemMobileProps) => {
  // Dividers should be shown either side of a column with a label (excluding the first and last column edges)
  // This can be achieved by adding a divider to the left side (right side for RTL languages) of columns if they
  // have a label or the next column has a label
  const colsWithDivider = [
    { id: 1, hasItems: !!col1Items?.length, hasLabel: !!col1Label },
    { id: 2, hasItems: !!col2Items?.length, hasLabel: !!col2Label },
    { id: 3, hasItems: !!col3Items?.length, hasLabel: !!col3Label },
  ]
    // Remove columns that do not have items as those columns will not be rendered
    .filter(({ hasItems }) => hasItems)
    // Remove items that don't have a label or are next to a column that has a label
    // This is because each columns divider also acts as a divider for the previous column
    .filter((col, i, arr) => {
      const prevCol = arr[i - 1];
      const isFirstCol = i === 0;
      const hasDivider = col.hasLabel || prevCol?.hasLabel;

      // Remove the first item as we never want it to have a left divider as the menu's edge
      return !isFirstCol && hasDivider;
    })
    // Map to just the column id for ease of use
    .map(({ id }) => id);

  return colsWithDivider;
};
