import type { LocaleOption } from '@snapchat/mw-global-components';
import type { MapOf } from '@snapchat/snap-design-system-marketing';
import { createContext } from 'react';

import type { UserAction } from '../../types/events';
export interface EventLog {
  // NOTE: Some of these map to GA actions. See this doc: https://support.google.com/analytics/answer/1033068
  type: UserAction; // Action being performed // TODO: Rename this to "action".
  url?: string; // URL if this is for a link
  component?: string; // Roughly maps to GA category like "Video", "Hero"
  label?: string; // Label of the item that the event is for
}

export type IsUrlCurrent = (
  url: string,
  options?: {
    /**
     * Strips the specified string from the start of the path for the current location when
     * comparing to the specified url
     *
     * NOTE: noop if `ignoredPathPrefix` is not specified
     */
    ignoredPathPrefix?: string;
  }
) => boolean;

export interface ConsumerContextProps {
  logEvent?: (event: EventLog) => void;
  isUrlCurrent?: IsUrlCurrent;
  setUrlParams?: (params: MapOf<string | undefined>) => void;
  getUrlParams?: () => MapOf<string>;
  currentLocale: string;
  supportedLocales: Record<string, LocaleOption>;
}

export const ConsumerContext = createContext<ConsumerContextProps>({
  currentLocale: 'en-US',
  supportedLocales: {},
});
