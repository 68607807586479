import { gql } from '@apollo/client';
import type { Alignment, BackgroundColor } from '@snapchat/snap-design-system-marketing';

import type { ContentfulSysProps, ContentfulTypedSysProps } from '../../types/contentful';
import type { Items } from '../../types/Items';
import type { RichText } from '../../types/RichText';
import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';
import { ctaFragment } from '../CallToAction';
import type { CallToActionDataProps } from '../CallToAction/types';
import type { CarouselV3DataProps } from '../CarouselV3';
import type { ContentDataProps } from '../Content';
import { fragments as contentFragments } from '../Content';
import type { FormDataProps } from '../Form';
import type { LazyGalleryProps } from '../Gallery';
import type { ImageDataProps } from '../Image';
import { fragments as imageFragments } from '../Image';
import { fragments as assetsFragments } from '../Media';
import type { MosaicProps } from '../Mosaic';
import type { TabsDataProps } from '../Tabs';
import { fragments as videoFragments } from '../Video';
import type { VideoDataProps } from '../Video/types';
import type { BarChartDataProps } from '../visualizations/BarChart';
import type { GeoMapDataProps } from '../visualizations/GeoMap';
import type { LineChartDataProps } from '../visualizations/LineChart';
import type { MultiVisualizationDataProps } from '../visualizations/MultiVisualization';
import type { TableVisualizationDataProps } from '../visualizations/Table';

// =================================================================================================
// Block All Fragment
// =================================================================================================

export const blockAllFragment = gql`
  fragment BlockAll on Block {
    ...ContentfulSysId
    anchorId
    theme
    backgroundColor
    backgroundMediaV2 {
      __typename
      ... on Video {
        ...VideoAll
      }
      ... on Image {
        ...ImageAll
      }
    }
    showCurtain
    eyebrow
    title {
      json
    }
    subtitle {
      json
    }
    titleAlignment
    titleAlignmentMobile
    maxColumns
    isNarrow
    widthStyle
    fullHeight
    contentsCollection(limit: 15) {
      items {
        ...ContentfulSysId
      }
    }
    callsToActionCollection(limit: 9) {
      items {
        ... on CallToAction {
          ...CallToActionAll
        }
      }
    }
  }
  ${contentfulSysIdFragment}
  ${ctaFragment}
  ${assetsFragments.all}
  ${videoFragments.all}
  ${imageFragments.all}
`;

// TODO: Convert all of these to shallow.
export type BlockContentType =
  | ContentDataProps
  | LazyGalleryProps
  | CarouselV3DataProps
  | FormDataProps
  | TabsDataProps
  | BarChartDataProps
  | LineChartDataProps
  | GeoMapDataProps
  | MosaicProps
  | MultiVisualizationDataProps
  | TableVisualizationDataProps;

export type ShallowContent = ContentfulSysProps;

export interface BlockDataProps extends ContentfulTypedSysProps<'Block'> {
  eyebrow?: string;
  title?: RichText | string; // TODO: This should be just richText
  subtitle?: RichText | string; // TODO: This should be just richText
  titleAlignment?: Alignment;
  titleAlignmentMobile?: Alignment;
  maxColumns?: 1 | 2 | 3;
  theme?: BackgroundColor;
  backgroundColor: BackgroundColor;
  backgroundMediaV2?: VideoDataProps | ImageDataProps;
  showCurtain?: boolean;
  callsToActionCollection: Items<CallToActionDataProps>;
  contentsCollection?: Items<ShallowContent>;
  anchorId?: string;
  isNarrow?: boolean;
  widthStyle?: string;
  fullHeight?: boolean;
  isPreviousSameBackgroundColor?: boolean;
  isNextSameBackgroundColor?: boolean;
  className?: string;
  // TODO expose in Contentful
  skipBreakTemplate?: boolean;
}

// =================================================================================================
// Block Preview Fragment
// =================================================================================================

export const blockPreviewFragment = gql`
  fragment BlockPreview on Block {
    ...ContentfulSysId
    title {
      json
    }
    contentsCollection(limit: 5) {
      items {
        ... on Content {
          ...ContentMedia
        }
      }
    }
  }
  ${contentfulSysIdFragment}
  ${contentFragments.media}
`;

// =================================================================================================
// Block Shallow Fragment
// =================================================================================================

/** This shallow fragment does load ids of it's children so that we can preload them. */
export const blockFragmentShallow = gql`
  fragment BlockShallow on Block {
    ...ContentfulSysId
    contentsCollection(limit: 15) {
      items {
        ...ContentfulSysId
      }
    }
  }
  ${contentfulSysIdFragment}
`;

export interface BlockShallowWithContentIds extends ContentfulTypedSysProps<'Block'> {
  contentsCollection: Items<ContentfulSysProps>;
}

export type BlockShallowDataProps = ContentfulSysProps | BlockShallowWithContentIds;

// =================================================================================================
// Block All Query
// =================================================================================================

export const query = gql`
  query BlockQuery($preview: Boolean!, $locale: String!, $id: String!) {
    block(preview: $preview, locale: $locale, id: $id) {
      ...BlockAll
    }
  }
  ${blockAllFragment}
`;

export interface BlockDataHandlerProps {
  block: BlockDataProps;
}
