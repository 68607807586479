import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import type {
  NavigatorItem as NavigatorItemType,
  SideNavigation as SideNavigationType,
} from '@snapchat/mw-contentful-schema';
import type {
  SideNavigationItemProps,
  SideNavigationSectionProps,
} from '@snapchat/snap-design-system-marketing';
import { SideNavigation as SideNavigationSDS } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import type { ContentfulIdVariable } from '../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../hooks/useContentfulQuery';
import { isContentfulSysProps } from '../../utils/contentful';
import { getContentfulInspectorProps } from '../../utils/contentful/getContentfulInspectorProps';
import { useExperiment } from '../Experiment';
import type { NavigatorItemWithChildrenData } from '../Header/headerQuery';
import { query } from './query';
import { stickToHeaderCss } from './styles';
import type {
  SideNavigationDataHandlerProps,
  SideNavigationDataProps,
  SideNavigationProps,
} from './types';

export const SideNavigation: FC<SideNavigationDataProps> = ({
  navigatorItemsCollection,
  backgroundColor,
  mobileBackgroundColor,
  sys,
}) => {
  const { pathname } = useLocation();
  const { checkCriteria } = useExperiment();

  const isUrlCurrent = (url: string) => {
    const match = matchPath(url, { path: pathname, exact: true });
    return match ? match.isExact : false;
  };

  const isVisible = (item: NavigatorItemWithChildrenData) => checkCriteria(item.hideCriteria);

  const items: SideNavigationSectionProps[] = navigatorItemsCollection.items
    .filter(isVisible)
    .map(section => {
      const { titleDataset } = getContentfulInspectorProps<NavigatorItemType>({
        entryId: section.sys.id,
        fieldIds: ['title'],
      });

      return {
        title: section.title ? documentToPlainTextString(section.title.json) : '',
        links: section.navigatorItemsCollection.items.filter(isVisible).map(link => {
          const { titleDataset: nestedTitleDataset } =
            getContentfulInspectorProps<NavigatorItemType>({
              entryId: link.sys.id,
              fieldIds: ['title'],
            });

          const item: SideNavigationItemProps = {
            title: link.title ? documentToPlainTextString(link.title.json) : '',
            url: link.url ?? ''!,
            dataset: nestedTitleDataset,
          };

          return item;
        }),
        dataset: titleDataset,
      };
    });

  const { contentfulDescriptionDataset } = getContentfulInspectorProps<SideNavigationType>({
    entryId: sys.id,
    fieldIds: ['contentfulDescription'],
  });

  return (
    <SideNavigationSDS
      className={stickToHeaderCss}
      items={items}
      isUrlCurrent={isUrlCurrent}
      dataset={contentfulDescriptionDataset}
      backgroundColor={backgroundColor}
      mobileBackgroundColor={mobileBackgroundColor}
    />
  );
};

export const SideNavigationShallow: FC<SideNavigationProps> = props => {
  const id = isContentfulSysProps(props) ? props.sys.id : undefined;
  const { data } = useContentfulQuery<SideNavigationDataHandlerProps, ContentfulIdVariable>(query, {
    skip: !id,
    variables: { id },
  });

  if (!data) return null;
  return <SideNavigation {...data.sideNavigation} />;
};

SideNavigationShallow.displayName = 'SideNavigation';
