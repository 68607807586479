import { Black, White, Yellow } from '../../constants';

export const blockNavigationBlackBgVars = {
  '--block-navigation-buttons-color': Black.V50,
  '--block-navigation-buttons-active-color': Black.V200,
  '--block-navigation-buttons-bg-color': White,
  '--block-navigation-buttons-border-color': Black.V50,
  '--block-navigation-buttons-active-border-color': Yellow.V100,
  '--block-navigation-buttons-active-bg-color': Yellow.V100,
};

export const blockNavigationYellowBgVars = {
  '--block-navigation-buttons-bg-color': White,
  '--block-navigation-buttons-color': Black.V50,
  '--block-navigation-buttons-border-color': Black.V50,
  '--block-navigation-buttons-active-color': White,
  '--block-navigation-buttons-active-border-color': Black.V200,
  '--block-navigation-buttons-active-bg-color': Black.V200,
};

export const blockNavigationWhiteBgVars = {
  '--block-navigation-buttons-bg-color': White,
  '--block-navigation-buttons-color': Black.V50,
  '--block-navigation-buttons-border-color': Black.V50,
  '--block-navigation-buttons-active-color': Black.V200,
  '--block-navigation-buttons-active-border-color': Yellow.V100,
  '--block-navigation-buttons-active-bg-color': Yellow.V100,
};

export const blockNavigationGrayBgVars = {
  '--block-navigation-buttons-bg-color': White,
  '--block-navigation-buttons-color': Black.V50,
  '--block-navigation-buttons-border-color': Black.V50,
  '--block-navigation-buttons-active-color': White,
  '--block-navigation-buttons-active-border-color': Black.V200,
  '--block-navigation-buttons-active-bg-color': Black.V200,
};
