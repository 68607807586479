// ============================================================================
// Timeouts
// ============================================================================

/** Total server side response timeout in milliseconds */
export const connectionTimeoutMs = 30e3;

/** Total sever side response timeout in seconds. */
export const connectionTimeoutS = Math.ceil(connectionTimeoutMs / 1e3);

/**
 * Number of retries that are allowed for contentful.
 *
 * NOTE: 2023-07-07 We're changing this from 2 to 4 so that we can decrease the contentful request
 * timeout from ~11 seconds to ~6 seconds. This allows us to have green SLAs in a scenario where the
 * first request is slow and subsequent requests are fast. This way a 6 second + timeout, followed
 * by a <1s working query + a few more queries can return in under 10 seconds, which is our cutoff
 * for the uptime check to be considered a failure.
 */
export const contentfulRetryCount = 4;

/**
 * Web SSR request to contentful timeout. This is set to fit the number of contentfulRetryCount
 * attempts in a single server side request. There's a little headroom added for other requests.
 * I.e. this only works if there's a single slow contentful request that can can be retried and even
 * if it fails contentfulRetryCount times SSR fails but we return a 200 code to let the client side
 * render the page.
 */
export const contentfulRequestTimeoutMs = Math.round(
  (connectionTimeoutMs / contentfulRetryCount) * 0.75
);
