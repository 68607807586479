import { cx } from '@emotion/css';
import type { CSSProperties, FC } from 'react';

import { MotifComponent, useMotifStyles } from '../../motif';
import type { BaseComponentProps } from '../../types';
import { spinnerCss, spinnerCssSizeVar } from './styles';

interface SpinnerProps extends BaseComponentProps {
  size?: number;
}

/** Displays a spinning icon. */
export const Spinner: FC<SpinnerProps> = ({ size = 16, className }) => {
  useMotifStyles(MotifComponent.SPINNER);
  return (
    <div
      style={{ [spinnerCssSizeVar]: `${size}px` } as CSSProperties}
      className={cx(MotifComponent.SPINNER, spinnerCss, className)}
    />
  );
};
