import { css } from '@emotion/css';

import { mobileMediaQuery, VideoPanelAlignment } from '../../constants';

export const slidePanelCss: Record<VideoPanelAlignment, string> = {
  [VideoPanelAlignment.TopLeft]: css`
    left: 2%;
    top: 4%;
  `,
  [VideoPanelAlignment.TopRight]: css`
    right: 2%;
    top: 4%;
  `,
  [VideoPanelAlignment.TopCenter]: css`
    left: 50%;
    top: 4%;
    transform: translateX(-50%);
  `,
  [VideoPanelAlignment.BottomLeft]: css`
    left: 2%;
    bottom: 4%;
  `,
  [VideoPanelAlignment.BottomRight]: css`
    right: 2%;
    bottom: 4%;
  `,
  [VideoPanelAlignment.BottomCenter]: css`
    left: 50%;
    bottom: 4%;
    transform: translateX(-50%);
  `,
};

export const slidePanelMobileCss = css`
  position: absolute;

  ${mobileMediaQuery} {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }
`;

export const multiVideoBlockCss = css`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
`;

export const backgroundImageCss = css`
  width: 100%;
`;

export const togglePanelWithBreakSpacerWrapCss = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const togglePanelWrapCss = css`
  flex: 1;
  position: relative;
`;
