import { useImperativeEffect } from '@snapchat/core-browser';
import { ensureSingleScript } from '@snapchat/mw-common/client';
import isNil from 'lodash-es/isNil';
import noop from 'lodash-es/noop';
import type { FC } from 'react';

import { logInfo } from '../../helpers/logging';

/**
 * Component used to detect and report AdBlocker Usage.
 *
 * Imports a javascript resource that matches a naming convention that will be flagged by Ad
 * Blockers that reference the popular [EasyList
 * repository](https://github.com/easylist/easylist/tree/master), such as uBlock Origin, AdBlock,
 * AdGuard, etc.
 */
export const LogAdBlockUsage: FC = () => {
  useImperativeEffect(() => {
    // Note: cannot rely on the `onLoaded` callback as it will not fire if an AdBlocker exists
    ensureSingleScript('detect-ad-blocker', '/openad.js', noop);

    // checks whether the script was successfully imported and logs the result
    setTimeout(() => {
      const hasAdBlocker = isNil(window.snapMarketingWeb?.hasAdBlocker);

      logInfo({
        eventAction: 'Exists',
        eventCategory: 'AdBlocker',
        eventLabel: `${hasAdBlocker}`,
      });
    }, 500);
  }, []);

  return null;
};
