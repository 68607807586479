import { css } from '@emotion/css';

import { mobileMediaQuery } from '../../constants';
import { m } from '../../motif';
import { bannerMinHeight } from './Banner.constants';

export const globalHeaderBannerCss = css`
  padding: 16px 60px;
  font-size: ${m('--banner-font-size')};
  background-color: ${m('--banner-bg-color')};
  color: ${m('--banner-fg-color')};
  min-height: ${bannerMinHeight}px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  line-height: ${m('--banner-font-line-height')};
  box-sizing: border-box;

  ${mobileMediaQuery} {
    padding: 16px 32px 16px 20px;
  }
`;

export const globalHeaderBannerContentContainerCss = css`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const globalHeaderBannerButtonDivCss = css`
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: absolute;
  right: 0;

  ${mobileMediaQuery} {
    width: 44px;
  }
`;

export const globalHeaderBannerButtonStylesCss = css`
  background: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* 44 x 44 for a11y */
  width: 44px;
  height: 44px;

  /* search icon */
  .sds-icon-cross {
    fill: ${m('--banner-fg-color')};
  }
`;
