import type { ReactNode } from 'react';

import type { BackgroundColor } from '../../constants';
import type { BaseComponentProps } from '../../types';

export interface FooterV2Props extends BaseComponentProps {
  backgroundColor?: BackgroundColor;
  children?: ReactNode;
  hideFooterLinks?: boolean;
}
