import { cx } from '@emotion/css';
import type { FC } from 'react';

import { ChartToggleButton } from './ChartToggleButton';
import { buttonsCss, buttonsWrapperClassName, buttonsWrapperCss } from './styles';
import type { ChartToggleItemProps } from './types';

export interface ChartToggleButtonsProps {
  items: Array<ChartToggleItemProps>;
  selectedChart: number;
  selectChart: (index: number) => void;
}

export const ChartToggleButtons: FC<ChartToggleButtonsProps> = ({
  items,
  selectedChart,
  selectChart,
}) => {
  const handleOnClick = (index: number) => {
    if (selectedChart !== index) {
      selectChart(index);
    }
  };

  const wrapperClassName = cx(buttonsWrapperCss, buttonsWrapperClassName);

  return (
    <div className={wrapperClassName}>
      <nav className={buttonsCss} role="tablist">
        {items.map(({ title }, index) => (
          <ChartToggleButton
            key={`chart-button-${index}`}
            active={selectedChart === index}
            onClick={() => handleOnClick(index)}
          >
            {title}
          </ChartToggleButton>
        ))}
      </nav>
    </div>
  );
};
