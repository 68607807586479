import { cx } from '@emotion/css';

import type { flags } from './country-flags';
import { rowCss, tooltipAmountCss, tooltipCss, tooltipFlagCss, tooltipLabelCss } from './styles';

type TooltipData = {
  id: string;
  name: string;
  data?: string | number | undefined;
  field: string;
};

type DefaultTooltipProps = {
  flags: typeof flags;
  tooltipData?: TooltipData;
  className?: string;
  locale?: string;
};

export function DefaultTooltip({
  flags,
  tooltipData,
  className,
  locale,
}: DefaultTooltipProps): JSX.Element {
  return (
    <article className={cx(tooltipCss, className)}>
      <div className={rowCss}>
        <span className={tooltipFlagCss}>
          {flags.find(flag => flag.alpha3 === tooltipData?.id.toLowerCase())?.emoji}
        </span>
        <span className={tooltipAmountCss}>{tooltipData?.data?.toLocaleString(locale)}</span>
      </div>
      {/** TODO replace with localized messages ex from United States */}
      <span className={tooltipLabelCss}>{`${tooltipData?.field} from ${tooltipData?.name}`}</span>
    </article>
  );
}
