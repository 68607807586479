import { css } from '@emotion/css';

import { m } from '../../motif/m';

export const definitionCss = css`
  background-color: ${m('--definition-bg-color')};
  border-radius: ${m('--border-radius-s')};
  border-bottom: 2px solid ${m('--definition-border-color')};
  cursor: pointer;
  margin: 0;
  padding: 0px 4px 2px 4px;
  /* Ensure element is scrolled to the center of the screen when scrolled into view here:
   * packages/web/src/Routes.tsx
   */
  scroll-margin-top: 50vh;
  transition: background-color 150ms ease-in-out, border-color 150ms ease-in-out;

  &:hover {
    background-color: ${m('--definition-hover-bg-color')};
    border-bottom: 2px solid ${m('--definition-hover-border-color')};
  }
`;

export const definitionActiveCss = css`
  background-color: ${m('--definition-active-bg-color')};
  border-bottom: 2px solid ${m('--definition-hover-border-color')};

  &:hover {
    background-color: ${m('--definition-active-bg-color')};
    border-bottom: 2px solid ${m('--definition-hover-border-color')};
  }
`;
