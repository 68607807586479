import type { FC } from 'react';

import type { FontFamily } from './fontConstants';
import { fontDefinitionFiles, fontLinkIdFn } from './fontConstants';

interface Props {
  fontFamily: FontFamily;
}

/**
 * Component that inserts the font stylesheet for a given font-family.
 *
 * Use this for websites that use `Helmet` or construct the `<head>` element in JSX.
 */
export const FontStylesheet: FC<Props> = ({ fontFamily }) => {
  return (
    <link rel="stylesheet" id={fontLinkIdFn(fontFamily)} href={fontDefinitionFiles[fontFamily]} />
  );
};
