import { lazyComponent } from '@snapchat/mw-common/client';
import type { AdjustedChartData, AdjustedSeries } from '@snapchat/mw-contentful-schema';
import type {
  ChartMetadata,
  FieldMetadata,
  VisualizationMeta,
} from '@snapchat/snap-design-system-marketing';
import { VisualizationKey } from '@snapchat/snap-design-system-marketing';
import type { FC, ReactNode } from 'react';
import { useContext, useState } from 'react';

import type { ContentfulIdVariable } from '../../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../../hooks/useContentfulQuery';
import { isContentfulSysProps } from '../../../utils/contentful';
import { ContentfulContext } from '../../../utils/contentful/ContentfulContext';
import { SuspenseWrapper } from '../../SuspenseWrapper';
import { BarChartShallow } from '../BarChart';
import { GeoMapShallow } from '../GeoMap';
import { LineChartShallow } from '../LineChart';
import { TableShallow } from '../Table';
import { queries } from './query';
import type {
  MultiVisualizationDataHandlerProps,
  MultiVisualizationProps,
  MultiVisualizationRenderProps,
  ShallowVisualization,
} from './types';

const LazyMultiVisualization = lazyComponent(() =>
  import('./LazyMultiviz').then(module => ({ default: module.MultiVisualization }))
);

const generateChartMeta = (chartData: AdjustedChartData) => ({
  id: chartData.sys.id,
  label: chartData.label ?? '',
});

const generateVisualizationMeta = (visualization: ShallowVisualization): VisualizationMeta => ({
  id: visualization.sys.id,
  title: visualization.chartTitle,
  key: visualization.__typename as VisualizationKey,
});

const getProcessedChartData = (
  chartDataItems: AdjustedChartData[],
  series: AdjustedSeries
): AdjustedChartData[] => {
  const filteredChartData = chartDataItems.filter(
    chartData => chartData.seriesName.sys.id === series.sys.id
  );

  let unnamedChartDataCount = 0;
  return filteredChartData.map((chartData: AdjustedChartData) => {
    if (!chartData.label) {
      unnamedChartDataCount++;
      return { ...chartData, label: `Unnamed Data ${unnamedChartDataCount}` };
    }

    return { ...chartData, label: chartData.label };
  });
};

const MultiVisualization: FC<MultiVisualizationRenderProps> = ({
  chartTitle,
  seriesName,
  chartDataCollection,
  visualizationsCollection,
  selectableFields,
}) => {
  const { locale } = useContext(ContentfulContext);

  // getProcessedChartData will filter out any chart data that does not share series given to the Multi Visualization.
  // It will also add placeholder labels to chart data entries that don't have them (as those labels)
  // are needed to populate the data selector)
  const chartDataItems = getProcessedChartData(chartDataCollection.items, seriesName);

  const visualizationMetadataItems: VisualizationMeta[] =
    visualizationsCollection.items.map(generateVisualizationMeta);

  const chartMetadataItems: ChartMetadata[] = chartDataItems.map(generateChartMeta);

  const fieldMetadataItems: FieldMetadata[] | undefined = selectableFields?.map(field => ({
    key: field,
    // Fallback on key as title in case somehow headerNames was not populated in the Series
    title: seriesName?.headerNames?.[field] ?? field,
  }));

  // State

  const [selectedChartData, setSelectedChartData] = useState<AdjustedChartData | undefined>(
    chartDataItems[0]
  );

  const [selectedVisualization, setSelectedVisualization] = useState<
    ShallowVisualization | undefined
  >(visualizationsCollection.items[0]);

  const [selectedField, setSelectedField] = useState<string | undefined>(
    selectableFields?.[0] ?? undefined
  );

  // Callbacks

  const onChartDataSelected = (item: ChartMetadata) =>
    setSelectedChartData(chartDataItems.find(chartData => chartData.sys.id === item.id));

  const onVisualizationSelected = (item: VisualizationMeta) =>
    setSelectedVisualization(visualizationsCollection.items.find(viz => viz.sys.id === item.id));

  const renderVisualization = (visualization: VisualizationMeta): ReactNode => {
    if (!selectedVisualization || !selectedChartData) return null;

    // Renderer will return a visualization with a few adjustments...
    //
    // 1) Override chart data (normally chart data is attached to Visualization itself)
    // 2) Override the "visualized field" if a field has been selected in Mutli Visualization (i.e. for Bar Chart, we override xAxes)

    switch (visualization.key) {
      case VisualizationKey.TABLE:
        return <TableShallow {...selectedVisualization} chartDataOverride={selectedChartData} />;
      case VisualizationKey.BAR_CHART:
        return (
          <BarChartShallow
            {...selectedVisualization}
            chartDataOverride={selectedChartData}
            xAxesOverride={selectedField ? [selectedField] : undefined}
          />
        );
      case VisualizationKey.LINE_CHART:
        return (
          <LineChartShallow
            {...selectedVisualization}
            chartDataOverride={selectedChartData}
            yKeysOverride={selectedField ? [selectedField] : undefined}
          />
        );
      case VisualizationKey.GEO_MAP:
        return (
          <GeoMapShallow
            {...selectedVisualization}
            chartDataOverride={selectedChartData}
            valueKeyOverride={selectedField}
          />
        );
      default:
        return null;
    }
  };

  // Validation should prevent a scenario where there is no selected visualization or chart data,
  // but just in case, we'll gracefully fail here if we lack one or the other
  if (!selectedChartData || !selectedVisualization) return null;

  return (
    <SuspenseWrapper>
      <LazyMultiVisualization
        chartMetadataItems={chartMetadataItems}
        selectedChartMetadata={generateChartMeta(selectedChartData)}
        visualizationMetadataItems={visualizationMetadataItems}
        selectedVisualizationMetadata={generateVisualizationMeta(selectedVisualization)}
        visualizationTitle={chartTitle}
        fieldMetadataItems={fieldMetadataItems}
        locale={locale}
        onDataSelected={onChartDataSelected}
        onVisualizationSelected={onVisualizationSelected}
        onFieldSelected={(field: FieldMetadata) => setSelectedField(field.key)}
        renderVisualization={renderVisualization}
      />
    </SuspenseWrapper>
  );
};

export const MultiVisualizationShallow: FC<MultiVisualizationProps> = props => {
  const id = isContentfulSysProps(props) ? props.sys.id : undefined;
  const { data } = useContentfulQuery<MultiVisualizationDataHandlerProps, ContentfulIdVariable>(
    queries.all,
    {
      skip: !id,
      variables: { id },
    }
  );

  if (!data) return null;

  return <MultiVisualization {...data.visualizationSelector} />;
};
