import { lazyComponent } from '@snapchat/mw-common/client';
import type { ChartDataRow } from '@snapchat/mw-contentful-schema';
import { getRawData } from '@snapchat/mw-contentful-schema';
import {
  type LineChartAccessors,
  type ValueOf,
  ChartSkeleton,
} from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import { SuspenseWrapper } from '../../SuspenseWrapper';
import type { LineChartRenderProps } from './types';

const LazyLineChart = lazyComponent(() =>
  import('./LazyLineChart').then(module => ({ default: module.LineChart }))
);

// Accessors will tell the chart how to draw the line given y axis key, x axis key, and row
const getAccessors = (yAxisKeys: string[], xAxisKey: string) => {
  const accessors: LineChartAccessors<
    ChartDataRow,
    ValueOf<ChartDataRow>,
    ValueOf<ChartDataRow>
  > = { x: {}, y: {} };

  for (const yKey of yAxisKeys) {
    // X accessor will always look at the same specified column no matter what the y value is
    accessors.x[yKey] = row => row[xAxisKey]!;
    // Y accessor will look for the value of the column which matches the given y value
    accessors.y[yKey] = row => row[yKey]!;
  }

  return accessors;
};

export const LineChart: FC<LineChartRenderProps> = props => {
  const { chartData, curve, xKey, yKeys, chartTitle } = props;

  if (!chartData) return null;

  const rawData = getRawData(chartData);

  return (
    <SuspenseWrapper fallbackElement={<ChartSkeleton />}>
      <LazyLineChart
        data={rawData}
        accessors={getAccessors(yKeys, xKey)}
        chartTitle={chartTitle}
        curve={curve}
        yKeys={yKeys}
      />
    </SuspenseWrapper>
  );
};
