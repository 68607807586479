import { gql } from '@apollo/client';

import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';

export const fragments = {
  contentAll: gql`
    fragment ContentAnimationAll on ContentAnimation {
      keyframes {
        ...ContentfulSysId
        delay
        duration
        direction
        iterationCount
        timingFunction
        transformOrigin
      }
      target
    }
    ${contentfulSysIdFragment}
  `,
};

export const query = gql`
  query AnimationCollectionQuery($preview: Boolean!, $locale: String!) {
    animationKeyframesCollection(
      preview: $preview
      locale: $locale
      limit: 20
      order: sys_publishedAt_ASC
    ) {
      items {
        ...ContentfulSysId
        delay
        duration
        direction
        iterationCount
        timingFunction
        transformOrigin
        keyframes
      }
    }
  }
  ${contentfulSysIdFragment}
`;
