import { css } from '@emotion/css';
import { mobileMediaQuery } from '@snapchat/snap-design-system-marketing';

export const hasSideNavCss = css`
  display: flex;
  overflow: clip;

  ${mobileMediaQuery} {
    display: block;
  }
`;

export const minHeightCss = css`
  min-height: calc(100vh - var(--total-header-height));
  min-height: calc(100dvh - var(--total-header-height));
`;
