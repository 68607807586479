import type { BrowserFeatures } from './types';

export const isEmptyBrowserFeatures = (browserFeatures: BrowserFeatures): boolean => {
  return browserFeatures.getLowEntropyHints().browsers.length === 0;
};

export const isMobileOs = (browserFeatures: BrowserFeatures): boolean => {
  return browserFeatures.getLowEntropyHints().isMobile;
};

export const isDesktopOs = (browserFeatures: BrowserFeatures): boolean => {
  return !browserFeatures.getLowEntropyHints().isMobile;
};

export const isWindows = (browserFeatures: BrowserFeatures): boolean => {
  return browserFeatures.getLowEntropyHints().platform === 'Windows';
};

export const isMac = (browserFeatures: BrowserFeatures): boolean => {
  return browserFeatures.getLowEntropyHints().platform === 'macOS';
};
