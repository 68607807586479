import { logValue } from '../../helpers/logging';
import type { ContentfulSysProps } from '../../types/contentful';
import { isElementInViewport } from '../../utils/isElementInViewport';
import type { VideoDataProps } from './types';

export const isVideoDataProps = (props: ContentfulSysProps): props is VideoDataProps => {
  return (props as VideoDataProps).__typename === 'Video';
};

interface GetVideoWatchLoggerParams {
  eventLabel: string;
  getPreviousWatchTime: () => number;
  setPreviousWatchTime: (value: number) => void;
  /** Defaults to true if not specified */
  autoPlay?: boolean | null;
}

/** Helper function that generates an `onTimeUpdate` listener to log video watch times. */
export const getVideoWatchLogger: (
  params: GetVideoWatchLoggerParams
) => React.ReactEventHandler<HTMLVideoElement> = ({
  autoPlay = true, //default to true if not specified
  eventLabel,
  getPreviousWatchTime,
  setPreviousWatchTime,
}) => {
  const onTimeUpdate: React.ReactEventHandler<HTMLVideoElement> = event => {
    // don't log watch heartbeats for auto-played videos
    if (autoPlay) return;

    const video = event.target as HTMLVideoElement;

    //https://docs.w3cub.com/dom/htmlmediaelement/readystate
    const sufficientDataToPlay = video.readyState > 2;
    const isVideoPlaying =
      video.currentTime > 0 && !video.paused && !video.ended && sufficientDataToPlay;

    const watchEventTimeDelta = Math.abs(getPreviousWatchTime() - video.currentTime);
    const isVideoInViewport = isElementInViewport(video);

    if (isVideoPlaying && isVideoInViewport && !document.hidden && watchEventTimeDelta >= 0.9) {
      logValue({
        eventCategory: 'Video',
        eventLabel,
        eventVariable: 'video_watch',
        eventValue: Math.floor(video.currentTime),
      });
      setPreviousWatchTime(video.currentTime);
    }
  };

  return onTimeUpdate;
};
