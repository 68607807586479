/**
 * Keys in the local storage that we set on a browser.
 *
 * These have to be CRAFTED because the data that's stored in them HAS NO EXPIRATION TIME. I.e. it's
 * important to not be too generic with words like "key" or "array", or get very specific with
 * applications like "sps-2023-foo" to make sure the same values aren't reused in 2024.
 */
const localStorageKeys = [
  'mwp-shopify-cart', // Items in the shopify cart for websites with ecommerce
  'mwp-snapkit-user-id', // SnapKit user id for SPS bitmoji reactions
  'mwp-snapkit-access-token', // Snapkit access token for SPS bitmoji reactions
  'sps-2023-firstName', // Stored first name of a person who's registered for SPS 2023
  'sps-2023-registrationStatus', // Registration status for someone who's registered for SPS 2023
  'sps-2023-interests', // Recorded interests for someone who's registeredf or SPS 2023
  'sps-2024-firstName', // Stored first name of a person who's registered for SPS 2024
  'sps-2024-registrationStatus', // Registration status for someone who's registered for SPS 2024
  'sps-2024-interests', // Recorded interests for someone who's registered for SPS 2024
  'experience-registration', // Registration data for experience site
  'mwp-form-data', // Form data for MWP forms
  'mwp-custom-motif', // When using custom debug mode to upload a motif from figma tokens
] as const;

type LocalStorageKey = (typeof localStorageKeys)[number];

/** Sets the local storage value for a given key. Type safe. */
export function setLocalStorageItem(key: LocalStorageKey, value: string): void {
  // eslint-disable-next-line no-restricted-syntax
  localStorage?.setItem(key, value);
}

/** Retrieves the local storage value for a given key. Type safe. */
export function getLocalStorageItem(key: LocalStorageKey): string | null {
  // eslint-disable-next-line no-restricted-syntax
  return localStorage?.getItem(key) ?? null;
}

/** Removes an item from local storage for a given key. Type safe. */
export function removeLocalStorageItem(key: LocalStorageKey): void {
  // eslint-disable-next-line no-restricted-syntax
  localStorage?.removeItem(key);
}
