import { css } from '@emotion/css';

import { Black, Blue, Gray } from '../../constants';
import { m } from '../../motif';

export const searchWrapperCss = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${m('--spacing-xxl')} calc((100% - (500px * 3)) / 2);
  margin: 0 ${m('--spacing-xxl')};
`;

export const searchTitleWrapperCss = css`
  margin-bottom: calc(${m('--spacing-xxl')} * 2);
  width: 100%;
`;

export const searchSubtitleCss = css`
  color: ${Gray.V300};
  font-size: 14px;
  margin-top: ${m('--spacing-s')};
`;

export const searchItemCss = css`
  box-sizing: border-box;
  border-radius: ${m('--border-radius-l')};
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: ${m('--spacing-l')} 0;
  text-decoration: none;
  width: 80%;
`;

// TODO: Refactor fonts in this component when we touch it again.
// https://jira.sc-corp.net/browse/ENTWEB-8779
export const searchItemPageTitleCss = css`
  color: ${Blue.V150};
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
`;

export const searchItemTitleCss = css`
  color: ${Black.V50};
  font-size: 16px;
`;

export const searchItemSubtitleCss = css`
  color: ${Black.V50};
  font-size: 16px;
  line-height: ${m('--spacing-l')};
`;

export const searchItemDescriptionCss = css`
  color: ${Black.V100};
  line-height: ${m('--spacing-l')};
  margin-top: ${m('--spacing-l')};
`;

export const noResultsFoundContainerCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${m('--search-no-results-bg-color')};
  color: ${m('--search-no-results-fg-color')};
  padding: calc(${m('--spacing-xxxl')} * 2) 0;
  border-radius: ${m('--border-radius-l')};
`;

export const noResultsFoundTitleCss = css`
  margin: ${m('--spacing-xs')} 0;
`;

export const noResultsFoundSubtitleCss = css`
  /* needed to override the p1css */
  && {
    font-weight: 400;
    margin-bottom: ${m('--spacing-xl')};
  }
`;

export const noResultsFoundButtonContainerCss = css``;

export const loadMoreResultsButtonCss = css`
  margin-top: ${m('--spacing-xxxl')};
  text-align: center;
`;
