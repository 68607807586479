import type { FC } from 'react';

import { Alignment, MediaMode } from '../../constants';
import { useMediaMode } from '../../hooks';
import { Accordion } from './/Accordion/Accordion';
import type { AnimatedAccordionItem } from './AnimatedAccordionCarousel/types';
import { AnimatedAccordionMobile } from './AnimatedAccordionMobile/AnimatedAccordionMobile';

/** Props for the AnimatedAccordion component. */
interface AnimatedAccordionProps {
  /** The items to be displayed in the accordion. */
  items?: AnimatedAccordionItem[];

  /** The direction of the media in the accordion. */
  mediaDirection?: AnimatedAccordionMediaDirection;

  /** The speed in seconds at which the accordion automatically plays. */
  autoPlaySpeed?: number;

  /** The alignment of the text on mobile. */
  textAlignmentMobile?: Alignment;
}

export enum AnimatedAccordionMediaDirection {
  End = 'End',
  Start = 'Start',
}

export const AnimatedAccordion: FC<AnimatedAccordionProps> = props => {
  const mode = useMediaMode();
  const isMobile = mode === MediaMode.Mobile;

  const {
    mediaDirection = AnimatedAccordionMediaDirection.Start,
    autoPlaySpeed = 10,
    textAlignmentMobile = Alignment.Start,
    items = [],
  } = props;

  if (isMobile) {
    return <AnimatedAccordionMobile items={items} textAlignment={textAlignmentMobile} />;
  }

  return (
    <Accordion
      items={items}
      mediaDirection={mediaDirection}
      autoPlaySpeedMs={autoPlaySpeed * 1000}
    />
  );
};
