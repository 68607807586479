import type { CSSProperties, FC } from 'react';
import { useContext, useEffect, useRef } from 'react';

import { BrowserFeaturesContext } from '../../BrowserFeatures/BrowserFeaturesContextProvider';
import type { BaseComponentProps } from '../../types';
import { dataSetToAttributes } from '../../utils';

interface ScrollAnimatedVideoProps extends BaseComponentProps {
  className?: string;
  /** Mp4 video source, used for safari and chrome */
  videoMp4Src: string;
  /** Webm video source, used for firefox */
  videoWebmSrc: string;
  /** Start time of video to use as start as animation. defaults to start of video */
  videoStart?: number;
  /** End time of video to use as end of animation. defaults to end of video */
  videoEnd?: number;
  /** What percent (0-100) the scrub animation should start at, defaults to 0 */
  scrubStart?: number;
  /** What percent (0-100) the scrub animation should end at, defaults to 100 */
  scrubEnd?: number;
  /**
   * Function that returns the scroll progress for the scrub video. Needs to return a value between
   * 0 and 1. Memoize this function to prevent attaching and unattaching scroll listeners on every
   * render
   */
  calculateScrollProgress: () => number;
  style?: CSSProperties;
}

/**
 * Component that will scrub through a video based on the scrollProgress calculated by the
 * calculateScrollProgress function on scroll and resize
 */
export const ScrollAnimatedVideo: FC<ScrollAnimatedVideoProps> = ({
  calculateScrollProgress,
  className,
  videoMp4Src,
  videoWebmSrc,
  videoStart = 0,
  videoEnd,
  scrubStart = 0,
  scrubEnd = 100,
  style,
  dataset,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const browserFeatures = useContext(BrowserFeaturesContext);
  const hints = browserFeatures.getLowEntropyHints();
  const isFirefox = hints.browsers.some(browser => browser.brand === 'Firefox');

  const finalVideoSrc = isFirefox ? videoWebmSrc : videoMp4Src;

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = videoStart || 0;
    }

    const scrollCallback = () => {
      if (videoRef.current) {
        const scrollPercent = calculateScrollProgress();

        const scrubRange = (scrubEnd - scrubStart) / 100;
        // scale the scroll percent to the scrub range
        const animationPercent = (scrollPercent - scrubStart / 100) * (1 / scrubRange);

        if (animationPercent > 0 && animationPercent <= 1) {
          const finalVideoEnd = videoEnd
            ? Math.min(videoEnd, videoRef.current.duration)
            : videoRef.current.duration;
          const animationDuration = finalVideoEnd - videoStart;
          const newTime = videoStart + animationDuration * animationPercent;

          videoRef.current.currentTime = Number.parseFloat(newTime.toFixed(3));
        }
      }
    };

    // NOTE: if performance proves to be an issue, we can look into using
    // blobs to load a whole video, or requestAnimationFrame, or adding an intersection observer
    // to attach/remove the scroll listeners
    window.addEventListener('scroll', scrollCallback);
    window.addEventListener('resize', scrollCallback);

    return () => {
      window.removeEventListener('scroll', scrollCallback);
      window.removeEventListener('resize', scrollCallback);
    };
  }, [calculateScrollProgress, scrubEnd, scrubStart, videoEnd, videoStart]);

  return (
    <video
      ref={videoRef}
      src={finalVideoSrc}
      className={className}
      style={style}
      {...dataSetToAttributes(dataset)}
    />
  );
};
