import type { PossibleTypesMap } from '@apollo/client';
import memoizeAsync from 'promise-memoize';

import type { ContentfulConfiguration } from '../../configTypes';
import { customFetch } from '../fetch/customFetch';
import { getTracer } from '../tracing';
import { getContentfulHttpOptions } from './ContentfulProvider';

/**
 * Returns the fragment map (i.e. what types Entry interface maps to etc).
 *
 * This is based on this code:
 * https://www.apollographql.com/docs/react/data/fragments/#generating-possibletypes-automatically
 */
export const generateFragmentTypes = memoizeAsync(
  async (config: ContentfulConfiguration): Promise<PossibleTypesMap> => {
    const httpOptions = getContentfulHttpOptions(config);
    const trace = getTracer().startSpan('generateFragmentTypes');
    const possibleTypesResponse = await customFetch(httpOptions.uri as string, {
      method: 'POST',
      headers: {
        Authorization: httpOptions.headers?.Authorization ?? '',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        variables: {},
        // Minified sincewe don't have a way to minify this otherwise.
        query: '{__schema{types{kind name possibleTypes{name}}}}',
      }),
    });

    const possibleTypesData = await possibleTypesResponse.json();

    if (!possibleTypesData.data) {
      throw Error(`Schema request failed: ${JSON.stringify(possibleTypesData)}`);
    }

    const parsedFragmentTypes = parseFragmentTypeData(possibleTypesData.data);

    trace.endSpan();
    return parsedFragmentTypes;
  }
);

type FragmentTypeSchema = {
  __schema: {
    types: {
      kind: string;
      name: string;
      possibleTypes?: { name: string }[] | null;
    }[];
  };
};

export function parseFragmentTypeData(data: FragmentTypeSchema): PossibleTypesMap {
  const possibleTypeMap: PossibleTypesMap = {};

  data.__schema.types.forEach(supertype => {
    if (supertype.possibleTypes) {
      possibleTypeMap[supertype.name] = supertype.possibleTypes.map(
        (subtype: { name: string }) => subtype.name
      );
    }
  });

  return possibleTypeMap;
}
