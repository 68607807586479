import { cx } from '@emotion/css';
import type { FC } from 'react';

import { BackgroundColor } from '../../constants';
import { MotifComponent, useMotifStyles } from '../../motif';
import { getBackgroundClassName } from '../../utils/themeUtils';
import { NavigationButton } from './NavigationButton';
import { NavigationButtonLeft } from './NavigationButtonLeft';
import { NavigationButtonRight } from './NavigationButtonRight';
import { buttonsContainerCss, navigationPanelsContainerCss, textBoldCss } from './styles';
import type { BlockNavigationProps } from './types';

export const BlockNavigation: FC<BlockNavigationProps> = ({
  step,
  backgroundColor = BackgroundColor.White,
  ...props
}) => {
  useMotifStyles(MotifComponent.BLOCK_NAVIGATION);
  return (
    <div
      {...props}
      className={cx(
        MotifComponent.BLOCK_NAVIGATION,
        getBackgroundClassName(backgroundColor),
        navigationPanelsContainerCss
      )}
    >
      {step.previousButton && (
        <NavigationButtonLeft
          showArrow={step.previousButton.showArrow}
          onClick={step.previousButton.onClick}
          isDisabled={step.previousButton.isDisabled}
          label={step.previousButton.label}
          className={textBoldCss}
        />
      )}
      <div className={buttonsContainerCss}>
        {step.skipButton?.label && (
          <NavigationButton
            showArrow={false}
            onClick={step.skipButton.onClick}
            isDisabled={step.skipButton.isDisabled}
            label={step.skipButton.label}
          />
        )}
        {step.nextButton && (
          <NavigationButtonRight
            showArrow={step.nextButton.showArrow}
            onClick={step.nextButton.onClick}
            isDisabled={step.nextButton.isDisabled}
            label={step.nextButton.label}
            className={textBoldCss}
          />
        )}
      </div>
    </div>
  );
};

BlockNavigation.displayName = 'BlockNavigation';
