import type { FC, PropsWithChildren, ReactNode, RefObject } from 'react';
import { createContext, useState } from 'react';

interface DefinitionContent {
  title: ReactNode;
  body: ReactNode;
}

interface DefinitionContextProps {
  isOpen: boolean;
  open: ({ title, body }: DefinitionContent, ref: RefObject<HTMLElement>) => void;
  close: () => void;
  content?: DefinitionContent;
  triggerRef?: RefObject<HTMLElement>;
}

const defaultContext: DefinitionContextProps = {
  isOpen: false,
  open: () => {},
  close: () => {},
};

export const DefinitionContext = createContext(defaultContext);

export const DefinitionProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState<DefinitionContent | undefined>();
  const [triggerRef, setTriggerRef] = useState<RefObject<HTMLElement> | undefined>();

  const open = ({ title, body }: DefinitionContent, ref: RefObject<HTMLElement>) => {
    setContent({ title, body });
    setTriggerRef(ref);
    setIsOpen(true);
  };

  const close = () => {
    setContent(undefined);
    setTriggerRef(undefined);
    setIsOpen(false);
  };

  return (
    <DefinitionContext.Provider value={{ isOpen, open, close, content, triggerRef }}>
      {children}
    </DefinitionContext.Provider>
  );
};
