import head from 'lodash-es/head';
import type { FC } from 'react';
import { useContext } from 'react';
import { Helmet } from 'react-helmet-async';

import { AppContext } from '../../AppContext';
import { useContentfulQuery } from '../../hooks/useContentfulQuery';
import { SlugContext } from '../Slug/SlugContext';
import type { OrganizationSchemaCollectionData } from '.';
import { generateWebSchema, organizationSchemaCollectionQuery } from '.';
import type { SchemasProps } from './WebSchema.types';

export const WebSchemas: FC<SchemasProps> = props => {
  const { schemas } = props;

  const { getCurrentUrl } = useContext(AppContext);
  const { publishedAt, postedDate } = useContext(SlugContext);

  const currentUrl = getCurrentUrl();

  const { data: organizationSchemaData } = useContentfulQuery<OrganizationSchemaCollectionData>(
    organizationSchemaCollectionQuery
  );

  const transformedSchemas = schemas.map(schema =>
    generateWebSchema(
      currentUrl,
      schema,
      postedDate,
      publishedAt,
      head(organizationSchemaData?.organizationSchemaCollection?.items || [])
    )
  );

  return (
    <Helmet>
      {transformedSchemas.map(
        (schema, idx) =>
          schema && (
            <script type="application/ld+json" key={`schema-${idx}`}>
              {JSON.stringify(schema)}
            </script>
          )
      )}
    </Helmet>
  );
};

WebSchemas.displayName = 'WebSchemas';
