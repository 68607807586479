import { css, cx } from '@emotion/css';
import type { FC, ReactNode } from 'react';
import { useContext } from 'react';

import { mobileMediaQuery } from '../../constants';
import { actionCss } from '../../motif';
import { m } from '../../motif/m';
import type { ImageSources } from '../../types';
import type { OnActivateHandler } from '../../types/activationEvents';
import { globalHeaderHeight } from '../GlobalHeader/GlobalHeader.constants';
import { Picture } from '../Picture';
import { PrimitivesContext } from '../Primitives';
import { GhostSvg } from './Logo.Ghost';
import { SnapIncLogoSvg } from './Logo.SnapInc';

const rootCss = css`
  text-decoration: none;
  display: flex;
  padding-top: 0;
  padding-bottom: 0;
  cursor: pointer;
`;

const customLogoCss = css`
  max-width: 100%;
  /* Prevents large logo images from exceeding header height */
  max-height: calc(${globalHeaderHeight}px - ${m('--spacing-m')});

  ${mobileMediaQuery} {
    max-width: 100%;
  }
  display: block;
`;

const labelCss = css`
  margin-left: ${m('--spacing-m')};

  *[dir='rtl'] & {
    margin-right: ${m('--spacing-m')};
    margin-left: 0;
  }

  ${actionCss}
`;

type LogoType = 'Ghost' | 'SnapInc';

export interface LogoProps {
  label?: ReactNode;
  /** @deprecated Use imgSrcs in favor of imageSource to utilize next gen image formats */
  imageSource?: string;
  /** @deprecated Background color is no longer used. */
  backgroundColor?: string;
  url?: string;
  onClick?: OnActivateHandler;
  isSelected?: boolean;
  className?: string;
  innerLogoClassName?: string;
  imgSrcs?: ImageSources;
  logoType?: LogoType;
  openInNewTab?: boolean;
}

export const Logo: FC<LogoProps> = ({
  imgSrcs,
  imageSource,
  label,
  url = '/',
  onClick,
  innerLogoClassName,
  className,
  openInNewTab,
  logoType = 'Ghost',
}) => {
  const { Anchor } = useContext(PrimitivesContext);

  let logo: ReactNode;

  if (imgSrcs) {
    logo = <Picture altText="logo" imgClassName={customLogoCss} imgSrcs={imgSrcs} />;
  } else if (imageSource) {
    logo = <Picture altText="logo" imgClassName={customLogoCss} defaultSrc={imageSource} />;
  } else if (logoType === 'SnapInc') {
    logo = <SnapIncLogoSvg className={innerLogoClassName} />;
  } else {
    logo = <GhostSvg className={innerLogoClassName} width={32} height={32} />;
  }

  return (
    <Anchor
      href={url}
      onClick={onClick}
      className={cx(className, rootCss)}
      {...(openInNewTab ? { target: '_blank', rel: 'noopener' } : {})}
    >
      {logo}
      {label && <span className={labelCss}>{label}</span>}
    </Anchor>
  );
};

Logo.displayName = 'Logo';
