import type { CookieManager } from '../../../utils/cookies/CookieManager';
import { Platform } from '../components/ArDownloadForm';
import type { PlatformString } from '../server';

const mapPlaformValues = (platform: string): PlatformString | '' => {
  const platformStripped = platform.toLowerCase().replace(/[_-\W\s]/gm, '');

  if (platformStripped.includes('osx') || platformStripped.includes('macos')) {
    return Platform.MAC_OS;
  }

  if (platformStripped.includes('windows')) {
    return Platform.WINDOWS;
  }

  return '';
};

export interface DownloadData {
  version: string;
  platform: PlatformString;
  downloadId: string;
}

export const downloadsCookieKey = 'sc-lsdownloads';

export type LsDownloadsMap = Record<string, string>;

export const serializeVersion = (version: string): string => {
  const [part1 = 0, part2 = 0, part3 = 0, part4 = 0] = version
    .split(/[._-]/gm)
    .map(part => Number.parseInt(part))
    .filter(Number.isInteger);

  return [part1, part2, part3, part4].join('_');
};

export const serializeVersionAndPlatform = (version: string, platform: string): string => {
  const versionSerialized = serializeVersion(version);
  return `${mapPlaformValues(platform).toLowerCase()}_${versionSerialized}`;
};

export const makeUpdatedLsDownloadsMap = (
  lsDownloadsMap: LsDownloadsMap,
  { version, platform, downloadId }: DownloadData
): LsDownloadsMap => {
  if (!version || !platform || !downloadId) {
    // || operator instead of ?? to account for the edge case when map serialized to ""
    return lsDownloadsMap || {};
  }

  return {
    ...lsDownloadsMap,
    [serializeVersionAndPlatform(version, platform)]: downloadId,
  };
};

export const persistDownloadId = (
  downloadData: DownloadData,
  cookieManager: CookieManager
): void => {
  const lsDownloadCookie = cookieManager.getCookieJson(downloadsCookieKey) as LsDownloadsMap;
  const lsDownloadsMap = makeUpdatedLsDownloadsMap(lsDownloadCookie, downloadData);

  const expires = new Date();
  // Privacy review: https://jira.sc-corp.net/browse/STUDIO-40099
  expires.setDate(expires.getDate() + 180); // Today +180 days.

  cookieManager.setCookieJson(downloadsCookieKey, lsDownloadsMap, {
    secure: true,
    expires,
    sameSite: 'strict',
  });
};
