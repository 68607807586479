import { cx } from '@emotion/css';
import type { FC } from 'react';
import { useContext, useMemo } from 'react';

import { MediaMode } from '../../../constants';
import { DetailsSummary } from '../../DetailsSummary';
import { globalNavGroupAnimationDurationMs } from '../../GlobalHeader/GlobalHeader.constants';
import { FooterContext } from '../FooterContext';
import {
  footerColumnListCss,
  footerGroupCss,
  footerHeaderCss,
  footerHeaderSpacingCss,
  footerHorizontalLayoutCss,
} from './styles';
import type { FooterGroupProps } from './types';
import { Orientation } from './types';

export const FooterGroup: FC<FooterGroupProps> = ({
  children,
  title,
  orientation = Orientation.Vertical,
  isFooterBar,
}) => {
  const { mode } = useContext(FooterContext);

  const containerClassName = cx(footerGroupCss, {
    [footerHorizontalLayoutCss]: orientation === Orientation.Horizontal,
  });

  const footerHeaderClassName = cx(footerHeaderCss, {
    [footerHeaderSpacingCss]: orientation === Orientation.Vertical,
  });

  const header = useMemo(() => {
    if (!title) {
      return null;
    }

    if (typeof title !== 'string') {
      return title;
    }

    return (
      <div className={footerHeaderClassName}>
        <span>{title}</span>
      </div>
    );
  }, [title, footerHeaderClassName]);

  const childrenWrapper = useMemo(() => {
    if (!children) {
      return null;
    }

    return (
      <ul
        className={cx(footerColumnListCss, {
          [footerHorizontalLayoutCss]: orientation === Orientation.Horizontal,
        })}
      >
        {children}
      </ul>
    );
  }, [children, orientation]);

  // Adding this here because using an open detail summary
  // for the footer bar items causes automatic scroll to the footer (bug)
  // follow up: https://jira.sc-corp.net/browse/ENTWEB-7631
  if (isFooterBar && mode === MediaMode.Mobile) {
    return (
      <ul
        className={cx(footerColumnListCss, {
          [footerHorizontalLayoutCss]: orientation === Orientation.Horizontal,
        })}
      >
        {children}
      </ul>
    );
  }

  return mode === MediaMode.Mobile ? (
    <DetailsSummary
      showChevron={!!title}
      summary={title}
      summaryProps={{ className: footerHeaderClassName }}
      className={containerClassName}
      fadeInAnimation
      transitionDurationMs={globalNavGroupAnimationDurationMs}
      disableScrollToOnOpen={isFooterBar}
    >
      {childrenWrapper}
    </DetailsSummary>
  ) : (
    <div data-testid="footer-group" className={containerClassName}>
      {header}
      {childrenWrapper}
    </div>
  );
};
