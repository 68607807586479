import { BreakOverlayType, Template } from './types';

/**
 * This util function is used to determine the amount that a 'Break' component should overlay the
 * previous or next block
 */
export const getBreakOverlayType = ({
  template,
  isTopTransparent,
  isBottomTransparent,
  isOverlaid,
}: {
  template: Template;
  isTopTransparent: boolean;
  isBottomTransparent: boolean;
  isOverlaid?: boolean;
}): BreakOverlayType => {
  if (!template) return BreakOverlayType.NONE;

  if (isTopTransparent && isBottomTransparent) {
    return BreakOverlayType.NONE;
  }

  const isTransparentTopOnly = isTopTransparent && !isBottomTransparent;
  const isTransparentBottomOnly = !isTopTransparent && isBottomTransparent;

  if (isOverlaid) {
    if (template === Template.Straight) {
      return BreakOverlayType.TOP_HALF_AND_BOTTOM_HALF;
    }

    if (isTransparentTopOnly) {
      return BreakOverlayType.TOP;
    }

    if (isTransparentBottomOnly) {
      return BreakOverlayType.BOTTOM;
    }

    return BreakOverlayType.TOP_HALF_AND_BOTTOM_HALF;
  }

  if (isTransparentTopOnly) {
    return template === Template.Straight ? BreakOverlayType.TOP_HALF : BreakOverlayType.TOP;
  }

  if (isTransparentBottomOnly) {
    return template === Template.Straight ? BreakOverlayType.BOTTOM_HALF : BreakOverlayType.BOTTOM;
  }

  return BreakOverlayType.NONE;
};
