import { css } from '@emotion/css';

import {
  mobileMediaQuery,
  nonMobileMediaQuery,
  twoColumnCollapseMediaQuery,
  White,
} from '../../constants';
import { h1Css, m, p1Css, p2Css, p3Css } from '../../motif';
import { globalHeaderHeight } from '../GlobalHeader';
import { primitiveElementClassNames } from '../Primitives';

/** Minimum height of the Split block */
// TODO: Make sure this is the expected min height in the next Snap meeting
const minHeightPx = 400;

/** Minimum height of mobile media */
const mobileMediaMinHeightPx = 375;

/** Max width for Content wrapper from designs */
const contentMaxWidthPx = 592;

/** Max width for Media caption from designs */
const mediaCaptionMaxWidthPx = 440;

export const splitBlockWrapperCss = css`
  align-items: stretch;
  background-color: ${m('--background-color')};
  display: flex;
  gap: 0;
  justify-content: center;
  min-height: ${minHeightPx}px;
  width: 100%;

  ${mobileMediaQuery} {
    flex-direction: column;
    height: 100%;
  }
`;

export const fitWindowCss = css`
  height: calc(100vh - ${globalHeaderHeight}px);
`;

export const reverseSplitCss = css`
  flex-direction: row-reverse;
`;

export const textWrapperCss = css`
  display: flex;
  flex-shrink: 0;
  justify-content: flex-end;
  padding: ${m('--spacing-xxxxl')};
  width: 50%;

  ${twoColumnCollapseMediaQuery} {
    width: 60%;
  }

  ${mobileMediaQuery} {
    padding: 0;
    width: 100%;
  }
`;

export const contentWrapperCss = css`
  color: ${m('--foreground-color')};
  display: flex;
  flex-direction: column;
  gap: ${m('--spacing-m')};
  padding: ${m('--spacing-xl')};
  width: 100%;

  ${nonMobileMediaQuery} {
    max-width: ${contentMaxWidthPx}px;
  }
`;

export const titleCss = css`
  ${h1Css}
  word-break: break-word;
`;

export const subtitleCss = css`
  ${p1Css}
  word-break: break-word;
`;

export const bodyCss = css`
  ${p2Css}
  margin-bottom: 0;
  word-break: break-word;

  & .${primitiveElementClassNames.p}:last-child {
    margin-bottom: 0;
  }
`;

export const ctaWrapperCss = css`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: ${m('--spacing-m')};
  margin-top: ${m('--spacing-m')};
`;

export const mediaWrapperCss = css`
  display: flex;
  justify-content: center;
  position: relative;
  width: 50%;

  ${twoColumnCollapseMediaQuery} {
    width: 40%;
  }

  ${mobileMediaQuery} {
    height: ${mobileMediaMinHeightPx}px;
    width: 100%;
  }
`;

export const backgroundCss = css`
  height: 100%;
  inset: 0;
  object-fit: cover;
  position: absolute;
  width: 100%;
`;

export const mediaCaptionCss = css`
  background: rgba(0, 0, 0, 0.8);
  border-radius: ${m('--border-radius-s')};
  bottom: ${m('--spacing-m')};
  color: ${White};
  max-width: ${mediaCaptionMaxWidthPx}px;
  padding: ${m('--spacing-xxs')} ${m('--spacing-xs')};
  position: absolute;
  ${p3Css}

  ${mobileMediaQuery} {
    margin-inline: ${m('--spacing-xl')};
    max-width: 100%;
  }
`;
