import type { FontFamily } from '../components';
import type { BackgroundColor } from '../constants';
import type {
  MotifComponent,
  MotifScheme,
  motifVariables,
  nonDefaultMotifSchemes,
} from './motifConstants';

/** A type of a CSS variable supported by the system. */
export type MotifVar = (typeof motifVariables)[MotifComponent][number];

/** A type of a CSS variable available at the root (and therefore usable outside) */
export type MotifRootVar = (typeof motifVariables)[MotifComponent.ROOT][number];

export type MotifComponentVar<C extends MotifComponent> = (typeof motifVariables)[C][number];

/** Properties that can be set per a componet (loosely typed). */
export type MotifComponentProps<C extends MotifComponent> = Readonly<
  Record<MotifComponentVar<C>, string>
>;

/** Motif CSS variables per component. */
export type MotifStyles = { [C in MotifComponent]: MotifComponentProps<C> } & {
  name: string;
  legacyName: BackgroundColor;
};

/** Partial styles, meant for overrides over default. */
export type PartialMotifStyles = Partial<{
  [C in MotifComponent]: Partial<MotifComponentProps<C>>;
}> & {
  name: string;
  legacyName?: BackgroundColor;
};

/** A motif (theme) for a website. There can be only one per website. */
export type Motif = {
  name: string;
  fontFamily: FontFamily;
  [MotifScheme.DEFAULT]: MotifStyles;
} & {
  [K in (typeof nonDefaultMotifSchemes)[number]]?: PartialMotifStyles;
};

/** A partial motif (theme) definition for merging with the default. */
export type PartialMotif = Omit<Partial<Motif>, MotifScheme.DEFAULT> & {
  name: string;
  [MotifScheme.DEFAULT]?: PartialMotifStyles;
};
