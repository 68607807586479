import { gql } from '@apollo/client';

import { contentfulSysIdFragment } from '../../../utils/contentful/contentfulQuery';
import { chartDataFragment } from '../query';

export const fragments = {
  all: gql`
    fragment GeoMapAll on GeoVisualization {
      ...ContentfulSysId
      chartTitle
      chartData {
        ...ChartDataAll
      }
      dataScalingMethod
      countryCodeKey
      valueKey
    }
    ${contentfulSysIdFragment}
    ${chartDataFragment}
  `,
  subset: gql`
    fragment GeoMapSubset on GeoVisualization {
      ...ContentfulSysId
      countrySelectorFields
    }
    ${contentfulSysIdFragment}
  `,
  withTitle: gql`
    fragment GeoMapWithTitle on GeoVisualization {
      ...ContentfulSysId
      chartTitle
    }
    ${contentfulSysIdFragment}
  `,
};

export const queries = {
  shallow: gql`
    query geoMapEntryQuery($preview: Boolean!, $locale: String!, $id: String!) {
      geoVisualization(preview: $preview, locale: $locale, id: $id) {
        ...ContentfulSysId
      }
    }
    ${contentfulSysIdFragment}
  `,
  all: gql`
    query geoMapEntryQuery($preview: Boolean!, $locale: String!, $id: String!) {
      geoVisualization(preview: $preview, locale: $locale, id: $id) {
        ...GeoMapAll
      }
    }
    ${fragments.all}
  `,
  subset: gql`
    query geoMapEntryQuery($preview: Boolean!, $id: String!) {
      geoVisualization(preview: $preview, id: $id) {
        ...GeoMapSubset
      }
    }
    ${fragments.subset}
  `,
};
