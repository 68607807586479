import type { FC, ReactElement } from 'react';
import { useRef } from 'react';

import { MediaMode } from '../../constants';
import { useMediaMode } from '../../hooks';
import type { CarouselV3Props } from './CarouselV3';
import { CarouselV3 } from './CarouselV3';

/**
 * Type guard function to check if the given element is a CarouselV3.
 *
 * @param elem - The element to be checked.
 * @returns True if the element is a React element with CarouselV3Props, false otherwise.
 */
export const isCarouselV3Element = (elem: unknown): elem is ReactElement<CarouselV3Props> => {
  const asCarouselV3 = elem as ReactElement<CarouselV3Props>;

  if (asCarouselV3.type === CarouselV3) {
    return true;
  }
  // evil displayName check
  const typeAsFC = (elem as ReactElement).type as FC;
  return typeAsFC.displayName === 'CarouselV3';
};

/**
 * Retrieves the value from a given direction based on the RTL (Right-to-Left) setting.
 *
 * @param originalValue - The original numerical value.
 * @param isRTL - A boolean determining if the text direction is Right-to-Left (RTL).
 * @returns The value adjusted according to the direction; the original value if RTL, otherwise the
 *   negation of the original value.
 */
export const getValueFromDirection = (originalValue: number, isRTL: boolean): number => {
  if (isRTL) {
    return originalValue;
  }

  return originalValue * -1;
};

/**
 * Computes the modulo operation for a given number x with respect to another number m. Correctly
 * handles negative values
 *
 * @param x - The dividend number.
 * @param m - The divisor number.
 * @returns The result of the modulo operation.
 */
export const modulo = (x: number, m: number): number => {
  return ((x % m) + m) % m;
};

/**
 * Calculates the shortest path between two indices in a circular list.
 *
 * @param currentIndex - The current index in the circular list.
 * @param targetIndex - The target index in the circular list.
 * @param count - The total count of items in the circular list.
 * @returns The shortest path as the number of steps to reach the target index from the current
 *   index.
 */
export const shortestPath = (currentIndex: number, targetIndex: number, count: number): number => {
  // Calculating the direct distance
  const direct = Math.abs(targetIndex - currentIndex);
  // Calculating the wrapping distance
  const wrapping = count - direct;
  // Determining the direction of movement based on which index is first
  const direction = currentIndex < targetIndex ? 1 : -1;
  // Determining the smaller scalar distance
  const diff = Math.abs(direct) > Math.abs(wrapping) ? -wrapping : direct;
  // Determining the final distance by multiplying the scalar distance by the direction
  return diff * direction;
};

/**
 * Custom hook for handling drag interactions.
 *
 * @param dragStart - Function to be called when the drag starts.
 * @param dragMove - Function to be called when the drag is in progress.
 * @param dragEnd - Function to be called when the drag ends.
 */
export const useDrag = ({
  dragStart,
  dragMove,
  dragEnd,
}: {
  dragStart?: (event?: React.TouchEvent | React.MouseEvent) => void;
  dragMove?: (delta: number, event?: React.TouchEvent | React.MouseEvent) => void;
  dragEnd?: (delta: number, event?: React.TouchEvent | React.MouseEvent) => void;
}): {
  resetDrag: () => void;
  handleDragStart: (event: React.TouchEvent | React.MouseEvent) => void;
  handleDragMove: (event: React.TouchEvent | React.MouseEvent) => void;
  handleDragEnd: (event?: React.TouchEvent | React.MouseEvent) => void;
} => {
  const mode = useMediaMode();
  const isMobile = mode === MediaMode.Mobile;

  const initialTouchPosition = useRef<number | null>(null);
  const touchPosition = useRef<number | null>(null);

  const handleDragStart = (event: React.TouchEvent | React.MouseEvent) => {
    const clientX =
      (event as React.TouchEvent)?.touches?.[0]?.clientX ||
      (event as React.MouseEvent)?.clientX ||
      0;
    initialTouchPosition.current = clientX;
    touchPosition.current = clientX;
    dragStart?.(event);
  };

  const handleDragMove = (event: React.TouchEvent | React.MouseEvent) => {
    event.preventDefault();

    if (!initialTouchPosition.current) {
      return;
    }

    const clientX =
      (event as React.TouchEvent)?.touches?.[0]?.clientX ||
      (event as React.MouseEvent)?.clientX ||
      0;

    touchPosition.current = clientX;
    const diff = initialTouchPosition.current - clientX;

    dragMove?.(diff, event);
  };

  const handleDragEnd = (event?: React.TouchEvent | React.MouseEvent) => {
    // This prevents links from working in mobile
    if (!isMobile) {
      event?.preventDefault();
      event?.stopPropagation();
    }

    const initial = initialTouchPosition.current || 0;
    const current = touchPosition.current || 0;
    const diff = initial - current;

    initialTouchPosition.current = null;
    touchPosition.current = null;
    dragEnd?.(diff, event);
  };

  const resetDrag = () => {
    initialTouchPosition.current = null;
    touchPosition.current = null;
  };

  return { handleDragStart, handleDragMove, handleDragEnd, resetDrag };
};
