import { isAbsoluteUrl } from './tracking';

export const isExternal = (link?: string): boolean => {
  if (!link) {
    return false;
  }

  if (!isAbsoluteUrl.test(link)) {
    return false;
  }

  try {
    const parsedUrl = new URL(link);
    return parsedUrl.hostname !== window?.location.hostname;
  } catch {
    // eslint-disable-next-line no-console
    console.warn(`Unable to parse URL '${link}'. It is invalid.`);
    return false;
  }
};
