import { Black, Gray, Plain } from '../../constants';
import type { MotifComponent, MotifComponentProps } from '../../motif';

export const footnoteVars: MotifComponentProps<MotifComponent.FOOTNOTE> = {
  '--footnote-fg-color': Black.V50,
  '--footnote-bg-color': Plain.WHITE,
  '--footnote-border-color': Gray.V250,
  '--footnote-hover-icon-bg-color': Gray.V150,
  '--footnote-title-color': Plain.BLACK,
};
