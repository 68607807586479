/* eslint-disable typescript-sort-keys/string-enum */

export enum Blue {
  V50 = '#61C9FF',
  V100 = '#0EADFF',
  V150 = '#049EEE',
  V200 = '#0096E5',
}

export enum Purple {
  V50 = '#C195DE',
  V100 = '#A05DCD',
  V150 = '#9043BE',
  V200 = '#8936B6',
}

export enum Green {
  V50 = '#59D0B6',
  V100 = '#02B790',
  V150 = '#00A881',
  V200 = '#00A179',
}

export enum Red {
  V50 = '#F77F91',
  V100 = '#F23C57',
  V150 = '#E1143D',
  V200 = '#C50A33',
}

export enum Orange {
  V50 = '#FFB257',
  V100 = '#FF8A00',
  V150 = '#EE7A00',
  V200 = '#E57200',
}

export enum Yellow {
  V50 = '#FEFF85',
  V100 = '#FFFC00',
  V150 = '#FCF000',
  V200 = '#FFD301',
}

export enum Gray {
  V50 = '#F7F8F9',
  V100 = '#F0F1F2',
  V150 = '#E9EAEB',
  V200 = '#D4D5D6',
  V250 = '#C7C7CC',
  V300 = '#858D94',
}

export enum Black {
  V50 = '#53575B',
  V100 = '#3A3E41',
  V125 = '#222425',
  V150 = '#121314',
  V200 = '#000000',
}

/**
 * Plain colors in the palette for those who're CSS challenged.
 *
 * I.e. it's perfectly fine to use `white`, `black` and `transparent` where appropriate.
 */
export enum Plain {
  BLACK = '#000',
  TRANSPARENT = 'transparent',
  WHITE = '#FFF',
}

export type ColorPalette =
  | typeof Plain
  | typeof Black
  | typeof Blue
  | typeof Green
  | typeof Gray
  | typeof Orange
  | typeof Purple
  | typeof Red
  | typeof Yellow;

export const White = Plain.WHITE;
export const Transparent = Plain.TRANSPARENT;

// Brand Palettes
// TODO: Move these into customArcadiaMotif when old theming is deleted.
// Tracking: https://jira.sc-corp.net/browse/ENTWEB-8215
export enum ArcadiaBlack {
  V200 = '#111',
}

// TODO: Move these into customArcadiaMotif when old theming is deleted.
// Tracking: https://jira.sc-corp.net/browse/ENTWEB-8215
export enum ArcadiaGray {
  V300 = '#8D8C87',
}
