import { css } from '@emotion/css';

import { mobileMediaQuery, nonMobileMediaQuery } from '../../constants';
import { m } from '../../motif/m';

export const accordionCss = css`
  padding-bottom: ${m('--spacing-l')};
`;

export const titleCss = css`
  border-bottom: 1px solid ${m('--accordion-divider-border-color')};
  color: ${m('--accordion-header-color')};
  margin-block: 0;
  padding: ${m('--accordion-header-padding')} 0;

  ${nonMobileMediaQuery} {
    font-size: ${m('--accordion-header-desktop-font-size')};
    font-weight: ${m('--accordion-header-desktop-font-weight')};
    line-height: ${m('--accordion-header-desktop-font-line-height')};
  }

  ${mobileMediaQuery} {
    font-size: ${m('--accordion-header-mobile-font-size')};
    font-weight: ${m('--accordion-header-mobile-font-weight')};
    line-height: ${m('--accordion-header-mobile-font-line-height')};
  }
`;
