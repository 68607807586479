import { cx, injectGlobal } from '@emotion/css';
import type { FC, HTMLAttributes, PropsWithChildren } from 'react';
import { createElement, useContext } from 'react';

import { FontStylesheet } from '../components/Font/FontStylesheet';
import { defaultMotif } from './defaultMotif';
import { MotifComponent } from './motifConstants';
import { MotifContext, useMotifStyles } from './motifReactUtils';
import { globalMotifCss } from './motifStyles';
import type { Motif } from './motifTypes';
import { getMotifName, setMotif } from './motifUtils';

injectGlobal(globalMotifCss);

export interface MotifRootProps extends PropsWithChildren, HTMLAttributes<unknown> {
  motif?: Motif;
  tag?: 'main' | 'section';
}

/**
 * Wrapper that sets the Motif and servers as motif root.
 *
 * This is a convenience component and the few lines in it can safely be used in other fashions. I.e
 * if you want to append some styles to the `body` and don't want to introduce extra nesting.
 */
export const MotifRoot: FC<MotifRootProps> = ({
  motif = defaultMotif,
  tag = 'main',
  children,
  className,
  ...passThroughProps
}) => {
  const { injectedStyles } = useContext(MotifContext);

  // Has to be the first thing.
  if (getMotifName() !== motif.name) {
    setMotif(motif);
    injectedStyles.clear();
  }

  // Inject root styles.
  useMotifStyles(MotifComponent.ROOT);

  return (
    <>
      <FontStylesheet fontFamily={motif.fontFamily} />
      {createElement(
        tag,
        {
          className: cx(MotifComponent.ROOT, className),
          'data-test-id': 'sdsm-motif-root',
          ...passThroughProps,
        },
        children
      )}
    </>
  );
};
