import reject from 'lodash-es/reject';
import type { FC } from 'react';
import { useContext } from 'react';

import { GlobalComponentsContext } from '../../context/GlobalComponentsContext';
import type { GlobalNavAllType } from '../../generated/contentful-types';
import { GlobalNavGroup } from '../GlobalNavGroup/GlobalNavGroup';

/** Returns a sorted list of nav groups. */
export function sortNavGroups(
  navGroups: readonly GlobalNavAllType[],
  isPromoted: (group: GlobalNavAllType) => boolean
): GlobalNavAllType[] {
  return [...navGroups.filter(isPromoted), ...reject(navGroups, isPromoted)];
}

/** Returns a filtered list of nav groups that should remain visible */
const filterNavGroups = (
  navGroups: readonly GlobalNavAllType[],
  hostname: string
): GlobalNavAllType[] =>
  navGroups.filter(
    navGroup => !navGroup.hideHostnameRegex || new RegExp(navGroup.hideHostnameRegex).test(hostname)
  );

interface Props {
  navGroups: ReadonlyArray<GlobalNavAllType>;
}

export const GlobalNavGroupCollection: FC<Props> = ({ navGroups }) => {
  const { hostname } = useContext(GlobalComponentsContext);
  const isPromoted = (group: GlobalNavAllType) =>
    !!group.primaryHostnameRegex && new RegExp(group.primaryHostnameRegex).test(hostname);
  const filteredNavGroups = filterNavGroups(navGroups, hostname);
  const sortedNavGroups = sortNavGroups(filteredNavGroups, isPromoted);
  return (
    <>
      {sortedNavGroups.map(props => (
        <GlobalNavGroup key={props.groupKey} {...props} />
      ))}
    </>
  );
};
GlobalNavGroupCollection.displayName = 'GlobalNavGroupCollection';
