/* eslint-disable import/no-duplicates */
import { cx } from '@emotion/css';
import type { DesignSystemProps } from '@snapchat/mw-cookie-components';
import { Button, ButtonType, getBackgroundClassName } from '@snapchat/snap-design-system-marketing';
// TODO: this caused issues due to esm vs csj default exports, check if this is still a problem for consumers.
// see https://github.sc-corp.net/Snapchat/marketing-web/pull/1770
import { ToggleSlider } from '@snapchat/snap-design-system-marketing';

import { LocaleDropdown } from '../LocaleDropdown/LocaleDropdown';
import { SdsmCookieModal } from './SdsmCookieModal';
import { contentCss } from './SdsmCookieModal.styles';

export const SdsmComponents: DesignSystemProps = {
  buttonComponent: ({ text, isDisabled, isPrimary, onClick }) => {
    const type = isPrimary ? ButtonType.Primary : undefined;
    return (
      <Button disabled={isDisabled} onClick={onClick} size="Compact" type={type}>
        {text}
      </Button>
    );
  },

  localeDropdownComponent: ({
    currentLocale,
    supportedLocales,
    containerProvider,
    onLocaleChange,
  }) => {
    // TODO: Use SDS-M Locale Dropdown.
    return (
      <LocaleDropdown
        currentLocale={currentLocale.code}
        supportedLocales={supportedLocales}
        containerProvider={containerProvider}
        onLocaleChange={onLocaleChange}
      />
    );
  },

  modalComponent: ({ backgroundType, children, isDisplayed, portalRoot }) => {
    return (
      <SdsmCookieModal
        backgroundType={backgroundType}
        isDisplayed={isDisplayed}
        portalRoot={portalRoot}
      >
        {children}
      </SdsmCookieModal>
    );
  },

  sectionComponent: ({ backgroundType, children }) => {
    return (
      <section className={cx(contentCss, getBackgroundClassName(backgroundType))}>
        {children}
      </section>
    );
  },

  toggleComponent: ({ id, isChecked, onToggle }) => {
    const noop = () => undefined;
    return <ToggleSlider id={id} isChecked={isChecked} onToggle={onToggle ?? noop} />;
  },
};
