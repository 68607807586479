import type { FC } from 'react';

import type { GenericSvgIconProps } from '../CustomIcon';

export const WaffleIcon: FC<GenericSvgIconProps> = props => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.98 6C3.98 4.88 4.88 3.98 5.99 3.98C7.11 3.98 8.01
        4.88 8.01 6C8.01 7.11 7.11 8.01 5.99 8.01C4.88 8.01 3.98
        7.11 3.98 6ZM3.98 12C3.98 10.88 4.88 9.98 5.99 9.98C7.11
        9.98 8.01 10.88 8.01 12C8.01 13.11 7.11 14.01 5.99
        14.01C4.88 14.01 3.98 13.11 3.98 12ZM5.99 15.98C4.88
        15.98 3.98 16.88 3.98 18C3.98 19.11 4.88 20.01 5.99 20.01C7.11
         20.01 8.01 19.11 8.01 18C8.01 16.88 7.11 15.98 5.99 15.98ZM9.98
         6C9.98 4.88 10.88 3.98 11.99 3.98C13.11 3.98 14.01 4.88 14.01
         6C14.01 7.11 13.11 8.01 11.99 8.01C10.88 8.01 9.98 7.11 9.98
         6ZM11.99 9.98C10.88 9.98 9.98 10.88 9.98 12C9.98 13.11 10.88
         14.01 11.99 14.01C13.11 14.01 14.01 13.11 14.01 12C14.01
         10.88 13.11 9.98 11.99 9.98ZM9.98 18C9.98 16.88 10.88 15.98
         11.99 15.98C13.11 15.98 14.01 16.88 14.01 18C14.01 19.11 13.11
         20.01 11.99 20.01C10.88 20.01 9.98 19.11 9.98 18ZM18.00 3.98C16.88
         3.98 15.98 4.88 15.98 6C15.98 7.11 16.88 8.01 18.00 8.01C19.11 8.01
         20.01 7.11 20.01 6C20.01 4.88 19.11 3.98 18.00 3.98ZM15.98 12C15.98
         10.88 16.88 9.98 18.00 9.98C19.11 9.98 20.01 10.88 20.01 12C20.01
         13.11 19.11 14.01 18.00 14.01C16.88 14.01 15.98 13.11 15.98
         12ZM18.00 15.98C16.88 15.98 15.98 16.88 15.98 18C15.98 19.11
         16.88 20.01 18.00 20.01C19.11 20.01 20.01 19.11 20.01 18C20.01
         16.88 19.11 15.98 18.00 15.98Z"
      />
    </svg>
  );
};
