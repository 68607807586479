import { BackgroundType, CookieModal as CookiePopupGlobal } from '@snapchat/mw-global-components';
import { BackgroundColor } from '@snapchat/snap-design-system-marketing';
import type React from 'react';

import { Config } from '../../config';
import {
  logError,
  onMarketingTrackingAccepted,
  onPerformanceTrackingAccepted,
} from '../../helpers/logging';

export interface CookiePopupProps {
  isClient: boolean;
  backgroundColor?: BackgroundColor;
  globalPrivacyControl?: boolean;
}

const defaultBackgroundColor = BackgroundColor.White;

// TODO: Grab isClient from Config, grab globalPrivacyControl from AppContext,
// grab backgroundColor from Config.theme.
export const CookiePopup: React.FC<CookiePopupProps> = ({
  isClient,
  backgroundColor = defaultBackgroundColor,
  globalPrivacyControl,
}) => {
  // Return nothing for SSR
  if (!isClient) return null;

  const backgroundType =
    backgroundColor === BackgroundColor.Black ? BackgroundType.DarkMode : BackgroundType.LightMode;

  // For non-production environments (localhost, staging sites) - specify empty string so that cookies will can be set.
  const cookieDomain =
    !Config.isDeploymentTypeProd || !Config.isCompilationModeProd ? '' : undefined;

  return (
    <CookiePopupGlobal
      cookieDomain={cookieDomain}
      backgroundType={backgroundType}
      onComplete={event => {
        if (event.cookieAcceptance.Marketing) {
          onMarketingTrackingAccepted().catch(error =>
            logError({ component: 'CookiePopup', error })
          );
        }

        if (event.cookieAcceptance.Performance) {
          onPerformanceTrackingAccepted().catch(error =>
            logError({ component: 'CookiePopup', error })
          );
        }
      }}
      globalPrivacyControl={globalPrivacyControl}
    />
  );
};
