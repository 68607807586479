import { ChartToggleWrapper as ChartToggleWrapperSDS } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import type { ContentfulSysProps } from '../../../types/contentful';
import { Feature, useFeatureFlags } from '../../FeatureFlags';
import { ChartToggle } from './ChartToggle';

export const ChartToggleWrapper: FC<ContentfulSysProps> = props => {
  const featureFlags = useFeatureFlags();
  const useDropdowns = featureFlags[Feature.USE_CHART_DROPDOWN_TOGGLE] === 'true';

  if (useDropdowns) {
    return (
      <ChartToggleWrapperSDS>
        <ChartToggle {...props} />
      </ChartToggleWrapperSDS>
    );
  }

  return <ChartToggle {...props} />;
};

ChartToggleWrapper.displayName = 'ChartToggleWrapper';
