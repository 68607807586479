import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from '@snapchat/mw-common';
import type { Motif, MotifRootProps } from '@snapchat/snap-design-system-marketing';
import {
  Button,
  defaultMotif,
  mergeMotifs,
  MotifRoot,
  Size,
} from '@snapchat/snap-design-system-marketing';
import {
  type ChangeEventHandler,
  type FC,
  type PropsWithChildren,
  useCallback,
  useLayoutEffect,
  useMemo,
} from 'react';

// TODO: Figure out a better way to import this properly without exporting.
import { figmaTokensToMotif } from '../../../../snap-design-system-marketing/src/devtools/figmaTokens/figmaTokensToMotif';
import type { FigmaTokenSingleFileExport } from '../../../../snap-design-system-marketing/src/devtools/figmaTokens/figmaTokenTypes';
import { motifDiff } from '../../../../snap-design-system-marketing/src/motif/motifDiff';
import { Config } from '../../config';
import { logger } from '../../helpers/logging';
import { IntoDebugPortal } from '../DebugPortal/DebugPortal';

const component = 'CustomMotifHandler';

/** Wrapper for motif to allow uploading a custom motif from a figma token export. */
export const LazyCustomMotifHandler: FC<PropsWithChildren<MotifRootProps>> = ({
  children,
  motif,
  ...passThroughProps
}) => {
  // If this is being used, we know its not production and the url param is set to figma-upload

  // TODO: We are using local storage to load the custom motif due to limitations on dynamically
  // changing the motif client side. This is temporary until we have a better solution.
  const uploadedMotif = useMemo(() => {
    if (!Config.isClient || Config.isDeploymentTypeProd) return null;
    const storedMotif = getLocalStorageItem('mwp-custom-motif');
    if (!storedMotif) return null;

    const partialMotif = JSON.parse(storedMotif);
    logger.logDebug({ component, message: 'Applying custom motif from local storage.' });
    return mergeMotifs(defaultMotif, partialMotif);
  }, []);

  useLayoutEffect(() => {
    // dont want to clear styles unless there is an uploaded motif to use
    if (!uploadedMotif) return;
    // This does not work when doing mwp local (dev:client) since emotion isnt putting this meta data
    // into its style elements (seems to be SSR only).
    // biome-ignore lint/complexity/noForEach: <explanation>
    window.document
      .querySelectorAll(`style[data-emotion~="marketing-web-emotion"`)
      .forEach(el => el.remove());
  }, [uploadedMotif]);

  // handler for file upload of custom motif. It will process it and save to local storage and reload.
  const handleCustomMotifUpload: ChangeEventHandler<HTMLInputElement> = useCallback(event => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (uploadEvent: ProgressEvent<FileReader>) => {
      if (typeof uploadEvent.target?.result !== 'string') return;

      let message = '';

      const tokens = JSON.parse(uploadEvent.target?.result) as FigmaTokenSingleFileExport;
      const errors: Error[] = [];
      const onError = (error: Error) => {
        errors.push(error);
        logger.logWarning({ component, message: `Non-critical error parsing motif: ${error}` });
      };

      let motif: Motif | null = null;
      try {
        motif = figmaTokensToMotif('uploaded-custom', tokens, onError);
        message += 'Successfully parsed motif. Applying to the current site after reload.';
        logger.logDebug({ component, message: `${message} - ${motif}` });
      } catch (error) {
        logger.logError({ component, message: 'Error parsing motif', error });
        message += 'Critical error parsing motif. Could not apply. See the following information. ';
        message += `\n ${error}`;
      }
      if (errors.length > 0) {
        message += '\n\nNon Critical Errors:\n';
        message += errors.map((error, index) => `${index + 1}. ${error.message}`).join('\n');
      }
      message && alert(message);
      if (motif) {
        const partialMotif = motifDiff(motif, defaultMotif);
        setLocalStorageItem('mwp-custom-motif', JSON.stringify(partialMotif));
        window.location.reload();
      }
    };

    reader.readAsText(file); // Read the file as text
  }, []);

  // This should not occur since you should not use this component in prod but
  // putting in safe guard just in case.
  if (Config.isDeploymentTypeProd) {
    return null;
  }

  let finalMotif = motif;

  if (uploadedMotif) {
    finalMotif = uploadedMotif;
  }

  return (
    <MotifRoot motif={finalMotif} {...passThroughProps}>
      {children}
      {/**
       * Again we are only using this component in non prod + custom choice of motif in url, so this is
       * safe to just allow
       */}
      <IntoDebugPortal name="custom-motif-debug">
        {!uploadedMotif ? (
          <section>
            <p>Upload a figma token motif json file to apply it to the site.</p>
            <input type="file" onChange={handleCustomMotifUpload} />
          </section>
        ) : (
          <Button
            size={Size.Compact}
            onClick={() => {
              removeLocalStorageItem('mwp-custom-motif');
              window.location.reload();
            }}
          >
            Remove Uploaded Motif
          </Button>
        )}
      </IntoDebugPortal>
    </MotifRoot>
  );
};
