import type { FC } from 'react';

import { Config } from '../../config';
import { AvalonChatWidget } from '../../sites/avalon/components/AvalonChatWidget/AvalonChatWidget';

/**
 * Global components hard-coded to a specific site.
 *
 * This was build for the omnipresent chat widget for the avalon site.
 *
 * Only add similar use-cases here.
 */
export const HardcodedComponents: FC = () => {
  switch (Config.site) {
    case 'avalon': {
      return <AvalonChatWidget />;
    }
    default:
      return null;
  }
};
