import type { DocumentNode } from '@apollo/client';
import { gql } from '@apollo/client';

import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';
import { blockFragmentShallow } from '../Block/query';
import type { PageSitemapData } from '../Page/pageQuery';

// =================================================================================================
// Experiment Filter Fragment
// =================================================================================================

export type ExperimentFilterAction = 'Allow' | 'Deny';

export const criteriaFragment = gql`
  fragment CriteriaAll on Criteria {
    ...ContentfulSysId
    trafficRangeStart
    trafficRangeEnd
    countryAction
    countries
    languageAction
    languages
    deviceAction
    devices
    platformAction
    platforms
  }
  ${contentfulSysIdFragment}
`;

// =================================================================================================
// Experiment Sitemap Fragment
// =================================================================================================

/**
 * Experiment Query optimized for generating site map data.
 *
 * NOTE: we do not apply any expiriment logic when generating site maps. Intead we only reference
 * the default page content.
 */
export const experimentSitemapFragment = gql`
  fragment SiteMapExperiment on Experiment {
    sys {
      id
    }
    defaultReference {
      ...SiteMapPage
    }
  }
`;

/** Experiment content model optimized for generating site map data. */
export type ExperimentSitemapData = {
  __typename?: 'Experiment';
  sys: {
    id: string;
  };
  defaultReference?: PageSitemapData;
};

// =================================================================================================
// Create Experiment Fragment function.
// =================================================================================================

/**
 * I will probably regret this one day, but this helper function generates a fragment for getting
 * experiment data for a given fragment on a particular data type.
 *
 * Sample usage:
 *
 *     const fooQuery = gql`
 *         fragment FooShallow on Foo {
 *           bar {
 *             ...ExperimentOn[Bar]Loading[BarFragment]
 *           }
 *         }
 *         ${createExperimentFragment('Bar', 'BarFragment', someBarFragment)}
 *       `;
 */
export function createExperimentFragmentWithAbReplacements(
  entryType: string,
  fragmentName: string,
  fragment: DocumentNode,
  armLimit = 10
): DocumentNode {
  return gql`
    fragment ExperimentOn${entryType}Loading${fragmentName} on Entry {
      ... on ${entryType} {
        ...${fragmentName}
      }
      ... on Experiment {
        ...ContentfulSysId
        defaultReference {
          ...${fragmentName}
        }
        experimentArmsCollection(limit: ${armLimit}) {
          items {
            ...ContentfulSysId
            reference {
              ...${fragmentName}
            }
            criteria {
              ...CriteriaAll
            }
            abExperimentsCollection(limit: ${armLimit}) {
              items {
                ...ContentfulSysId
                analyticsId
                seed
                reference {
                  ...ContentfulSysId
                }
                abVariantsCollection(limit: 5) {
                  items {
                    ...ContentfulSysId
                    replacement {
                      ...ContentfulSysId
                      ... on Page {
                        blocksCollection(limit: 10) {
                          items {
                            ...ContentfulSysId
                            ... on Block {
                              ...BlockShallow
                            }
                          }
                        }
                      }
                      ... on Block {
                        ...ContentfulSysId
                        contentsCollection(limit: 10) {
                          items {
                            ...ContentfulSysId
                          }
                        }
                      }
                    }
                    trafficStartRange
                    trafficEndRange
                    analyticsId
                  }
                }
              }
            }
            analyticsId
          }
        }
        analyticsId
      }
    }
    ${blockFragmentShallow}
    ${contentfulSysIdFragment}
    ${criteriaFragment}
    ${fragment}
  `;
}

export function createExperimentFragmentNoAb(
  entryType: string,
  fragmentName: string,
  fragment: DocumentNode,
  armLimit = 10
): DocumentNode {
  return gql`
    fragment ExperimentOn${entryType}Loading${fragmentName} on Entry {
      ... on ${entryType} {
        ...${fragmentName}
      }
      ... on Experiment {
        ...ContentfulSysId
        defaultReference {
          ...${fragmentName}
        }
        experimentArmsCollection(limit: ${armLimit}) {
          items {
            ...ContentfulSysId
            reference {
              ...${fragmentName}
            }
            criteria {
              ...CriteriaAll
            }
            analyticsId
          }
        }
        analyticsId
      }
    }
    ${contentfulSysIdFragment}
    ${criteriaFragment}
    ${fragment}
  `;
}
