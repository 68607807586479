import { useContext, useEffect } from 'react';

import { Action } from '../../analytics/Action';
import { GlobalComponentsContext } from '../../context/GlobalComponentsContext';
import type { CallToActionAllType } from '../../generated/contentful-types';

interface UseActivationProps {
  /**
   * Signal to push the trigger. Anything that evaluates to true will trigger the action. Note that
   * this can be a number to allow for multi-trigger scenarios (like opening a link in a new
   * window).
   */
  triggered: boolean | number;
  ctaProps?: CallToActionAllType;
}

/**
 * Hook for activating the action on a CTA.
 *
 * This is useful to manually trigger the CTA for example onClick by another element.
 *
 * Usage:
 *
 * `useActivation({triggered, ...ctaProps});`
 */
export const useActivation = ({ triggered, ctaProps }: UseActivationProps): void => {
  const context = useContext(GlobalComponentsContext);
  const { onEvent } = context;

  const { presentation, url, analytics } = ctaProps ?? {};

  const usesButton = presentation?.__typename === 'Button';

  useEffect(() => {
    if (!triggered || !usesButton) {
      return;
    }

    const resolvedUrl = url ?? presentation?.url;

    if (onEvent) {
      onEvent({
        action: Action.Click,
        component: 'VirtualCallToAction',
        label: analytics?.label,
        url: resolvedUrl,
      });
    }

    if (window) {
      window.location.href = resolvedUrl;
    }
  }, [triggered, url, analytics?.label, onEvent, usesButton, presentation?.url]);
};
