import { lazyComponent } from '@snapchat/mw-common/client';
import type { CodeProps } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import { SuspenseWrapper } from '../SuspenseWrapper';

const LazyCode = lazyComponent(() =>
  import('./LazyCode').then(module => ({ default: module.Code }))
);

export const Code: FC<CodeProps> = props => (
  <SuspenseWrapper>
    <LazyCode {...props} />
  </SuspenseWrapper>
);

Code.displayName = 'Code';
