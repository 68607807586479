import { css } from '@emotion/css';

import { Alignment, mobileMediaQuery } from '../constants';

export const getTextAlignValue = (alignment: Alignment): 'center' | 'start' | 'end' => {
  switch (alignment) {
    case Alignment.Center:
      return 'center';
    case Alignment.Start:
    case Alignment.Left:
      return 'start';
    case Alignment.End:
    case Alignment.Right:
      return 'end';
    default:
      throw new Error(`specified alignment: ${alignment} is not accounted for!`);
  }
};

export const getFlexAlignValue = (alignment: Alignment): 'center' | 'flex-start' | 'flex-end' => {
  switch (alignment) {
    case Alignment.Center:
      return 'center';
    case Alignment.Start:
    case Alignment.Left:
      return 'flex-start';
    case Alignment.End:
    case Alignment.Right:
      return 'flex-end';
    default:
      throw new Error(`specified alignment: ${alignment} is not accounted for!`);
  }
};

const allAlignments: Alignment[] = Object.values(Alignment);

/** Precomputed lookup for the text alignment css. */
export const textAlignmentCss = Object.fromEntries(
  allAlignments.map(alignment => [
    alignment,
    css`
      text-align: ${getTextAlignValue(alignment)};
    `,
  ])
) as Record<Alignment, string>;

/** Precomputed lookup for the flex alignment css. */
export const alignItemsCss = Object.fromEntries(
  allAlignments.map(alignment => [
    alignment,
    css`
      align-items: ${getFlexAlignValue(alignment)};
    `,
  ])
) as Record<Alignment, string>;

/** Precomputed lookup for the grid alignment css. */
export const justifyItemsCss = Object.fromEntries(
  allAlignments.map(alignment => [
    alignment,
    css`
      justify-items: ${getFlexAlignValue(alignment)};
    `,
  ])
) as Record<Alignment, string>;

/** Precomputed lookup for the flex-column alignment. */
export const justifyContentCss = Object.fromEntries(
  allAlignments.map(alignment => [
    alignment,
    css`
      justify-content: ${getFlexAlignValue(alignment)};
    `,
  ])
) as Record<Alignment, string>;

/** Precomputed lookup for the flex-column alignment on mobile. */
export const justifyContentMobileCss = Object.fromEntries(
  allAlignments.map(alignment => [
    alignment,
    css`
      ${mobileMediaQuery} {
        justify-content: ${getFlexAlignValue(alignment)};
      }
    `,
  ])
) as Record<Alignment, string>;

/** Precomputed lookup for the text and flex alignment css. */
export const alignmentCss = Object.fromEntries(
  allAlignments.map(alignment => [
    alignment,
    css`
      text-align: ${getTextAlignValue(alignment)};
      align-items: ${getFlexAlignValue(alignment)};
      justify-items: ${getFlexAlignValue(alignment)};
      justify-content: ${getFlexAlignValue(alignment)};
    `,
  ])
) as Record<Alignment, string>;

/** Precomputed lookup for the mobile text and flex alignment css. */
export const mobileAlignmentCss = Object.fromEntries(
  allAlignments.map(alignment => [
    alignment,
    css`
      ${mobileMediaQuery} {
        text-align: ${getTextAlignValue(alignment)};
        align-items: ${getFlexAlignValue(alignment)};
        justify-items: ${getFlexAlignValue(alignment)};
        justify-content: ${getFlexAlignValue(alignment)};
      }
    `,
  ])
) as Record<Alignment, string>;
