import { gql } from '@apollo/client';

import { contentfulSysIdFragment } from '../../../utils/contentful/contentfulQuery';
import { chartDataFragment } from '../query';

export const fragments = {
  all: gql`
    fragment BarChartAll on BarChart {
      ...ContentfulSysId
      chartTitle
      chartData {
        ...ChartDataAll
      }
      yAxis
      xAxes
      stackKey
      filters
      width
      height
      hideXAxisLabels
      hideYAxisLabels
      hideXPercentLabels
      showAsPercent
      numberOfDecimalPlaces
      hideLegend
      dataColors
    }
    ${contentfulSysIdFragment}
    ${chartDataFragment}
  `,
  withTitle: gql`
    fragment BarChartWithTitle on BarChart {
      ...ContentfulSysId
      chartTitle
    }
    ${contentfulSysIdFragment}
  `,
};

export const queries = {
  shallow: gql`
    query barChartEntryQuery($preview: Boolean!, $locale: String!, $id: String!) {
      barChart(preview: $preview, locale: $locale, id: $id) {
        ...ContentfulSysId
      }
    }
    ${contentfulSysIdFragment}
  `,
  all: gql`
    query barChartEntryQuery($preview: Boolean!, $locale: String!, $id: String!) {
      barChart(preview: $preview, locale: $locale, id: $id) {
        ...BarChartAll
      }
    }
    ${fragments.all}
  `,
};
