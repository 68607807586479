import type { ValueOf } from '../types';

export const ImageFormat = {
  jpg: 'jpg',
  png: 'png',
  webp: 'webp',
  avif: 'avif',
} as const;

export type ImageFormat = ValueOf<typeof ImageFormat>;
