import { requestIdleCallback } from '@snapchat/polyfills';
import { LoadingBar as LoadingBarSDS } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { memo, useEffect, useState } from 'react';

import { endFetchEventListeners, startFetchEventListeners } from '../../utils/fetch/customFetch';

/** Loading Bar component that listens for fetch requests and handles rendering in the client */
export const LoadingBar: FC = memo(() => {
  const [loadingCount, setLoadingCount] = useState(0);

  useEffect(() => {
    const startCallBack = () => {
      setTimeout(() => {
        setLoadingCount(loadingCount => {
          return loadingCount + 1;
        });
        // Defer executing this after the current loop so we don't trigger
        // a state change during another component's render.
        // This fixes a react warning.
      }, 0);
    };

    const endCallBack = () => {
      requestIdleCallback(
        () =>
          setLoadingCount(loadingCount => {
            return Math.max(0, loadingCount - 1);
          }),
        // Call the callback eventually.
        { timeout: 5e3 }
      );
    };
    startFetchEventListeners.add(startCallBack);
    endFetchEventListeners.add(endCallBack);

    return () => {
      startFetchEventListeners.delete(startCallBack);
      endFetchEventListeners.delete(endCallBack);
    };
  }, []);

  if (loadingCount === 0) {
    return null;
  }

  return <LoadingBarSDS className="react-loading-bar" />;
});

LoadingBar.displayName = 'LoadingBar';
