import type { AdjustedChartData, AdjustedSeries, ChartDataRow, ChartSeries } from './types';

/**
 * Given the corresponding "Series", merge a single "Data" row with a "Localized Data" row TODO:
 * Remove once V2 visualization migration is complete
 */
const mergeRowWithLocalized = (
  series: ChartSeries,
  row: ChartDataRow,
  localizedRow: ChartDataRow
) => {
  const mergedRow: ChartDataRow = {};
  const keys = Object.keys(series.schema.items.properties!);

  for (const key of keys) {
    // Use "Localizable Properties" to determine if we should pull
    // from localized partition or regular partition.
    // If we have to, fallback on whatever partition has data for given key.
    if (series.localizableProperties?.includes(key)) {
      mergedRow[key] = localizedRow[key] ?? row[key]!;
    } else {
      mergedRow[key] = row[key] ?? localizedRow[key]!;
    }
  }

  return mergedRow;
};

/**
 * Given the corresponding series, merges the "Data" rows with the "Localized Data" rows from a
 * "Chart Data" TODO: Remove once V2 visualization migration is complete
 */
export function mergeDataWithLocalized(
  series: ChartSeries,
  rows?: ChartDataRow[],
  localizedRows?: ChartDataRow[]
): ChartDataRow[] {
  // Data really should never be null or undefined, but if it gets into a bad state
  // then we'll act as if the data is an empty list to prevent MWP from crashing.
  if (!rows) {
    return [];
  }

  // Localized data was not provided, assume all data is non-localizable
  if (!localizedRows) {
    return rows;
  }

  // Localizable data entries should exactly match the number of regular date entries
  if (rows.length !== localizedRows.length) {
    //@ts-ignore allow console.warn for this case https://jira.sc-corp.net/browse/WEBP-10119 to update to log
    console?.warn('Data size mismatch between localized and non-localized data.');
  }

  return rows.map((row, i) => mergeRowWithLocalized(series, row, localizedRows[i] as ChartDataRow));
}

/** Given a Chart Data, returns the full underlying raw data as a list of rows */
export function getRawData(data: AdjustedChartData): ChartDataRow[] {
  const localizedRows = data.localizedData ?? [];
  const nonLocalizedRows = data.data ?? [];

  // Localized data was not provided, assume all data is non-localizable
  if (!localizedRows) {
    return nonLocalizedRows;
  }

  // Localizable data entries should exactly match the number of regular data entries
  if (nonLocalizedRows.length !== localizedRows.length) {
    //@ts-ignore allow console.warn for this case https://jira.sc-corp.net/browse/WEBP-10119 to update to log
    console?.warn('Data size mismatch between localized and non-localized data.');
  }

  return nonLocalizedRows.map((row, i) =>
    mergeLocalizedAndNonLocalizedRows(row, localizedRows[i] as ChartDataRow, data.seriesName!)
  );
}

const mergeLocalizedAndNonLocalizedRows = (
  nonLocalizedRow: ChartDataRow,
  localizedRow: ChartDataRow,
  series: AdjustedSeries
) => {
  const mergedRow: ChartDataRow = {};
  const keys = Object.keys(series.schema.items.properties!);

  for (const key of keys) {
    // Use "Localizable Properties" to determine if we should pull
    // from localized partition or regular partition.
    // If we have to, fallback on whatever partition has data for given key.
    if (series.localizableProperties?.includes(key)) {
      mergedRow[key] = localizedRow[key] ?? nonLocalizedRow[key] ?? '';
    } else {
      mergedRow[key] = nonLocalizedRow[key] ?? localizedRow[key] ?? '';
    }
  }

  return mergedRow;
};
