import { GlobalNavGroup as GlobalNavGroupSDS } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import type { GlobalNavAllType } from '../../generated/contentful-types';
import { GlobalNavHighlight } from '../GlobalNavHighlight/GlobalNavHighlight';
import { GlobalNavItem } from '../GlobalNavItem/GlobalNavItem';

export const GlobalNavGroup: FC<GlobalNavAllType> = ({
  title,
  groupKey,
  itemsCollection,
  highlight,
}) => {
  // TODO: This wasn't done here before. Might screw things up because of how bad the highlights
  // look on mobile. Should ask design before going live with this.
  const mobileNavHighlight = <GlobalNavHighlight {...highlight} />;
  return (
    <GlobalNavGroupSDS
      navGroupKey={groupKey}
      title={title}
      mobileHighlight={mobileNavHighlight}
      isExpandable
    >
      {itemsCollection?.items.map((itemProps, i) => (
        <GlobalNavItem key={`nav-item-${i}`} {...itemProps} />
      ))}
    </GlobalNavGroupSDS>
  );
};

GlobalNavGroup.displayName = 'GlobalNavGroup';
