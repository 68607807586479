import { cx } from '@emotion/css';
import type { FC } from 'react';

import { MotifComponent, useMotifStyles } from '../../motif';
import type { BaseComponentProps } from '../../types';
import { progressBarCss } from './styles';

interface ProgressBarProps extends BaseComponentProps {
  currentStep: number;
  totalSteps: number;
  className?: string;
}

export const ProgressBar: FC<ProgressBarProps> = ({
  currentStep,
  totalSteps,
  className,
  ...props
}) => {
  useMotifStyles(MotifComponent.PROGRESS_BAR);

  return (
    <progress
      className={cx(MotifComponent.PROGRESS_BAR, progressBarCss, className)}
      {...props}
      max={totalSteps}
      value={currentStep}
    />
  );
};

ProgressBar.displayName = 'ProgressBar';
