import { css } from '@emotion/css';
import { useContentfulImages } from '@snapchat/mw-contentful-client';
import {
  Alignment,
  AnimatedAccordion as AnimatedAccordionSDS,
  AnimatedAccordionMediaDirection,
  nonMobileMediaQuery,
  Spacing,
} from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import type { ContentfulIdVariable } from '../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../hooks/useContentfulQuery';
import type { ContentfulSysProps } from '../../types/contentful';
import { parseMedia } from '../../utils/media';
import {
  renderRichTextMarkingsOnly,
  renderRichTextWithElements,
} from '../../utils/renderText/renderRichText';
import type { AnimatedAccordionContentData, AnimatedAccordionData } from './query';
import { query } from './query';

export const verticalPaddingCss = css`
  ${nonMobileMediaQuery} {
    padding-top: ${Spacing.XL}px;
  }
`;

export const AnimatedAccordion: FC<ContentfulSysProps> = props => {
  const { data } = useContentfulQuery<AnimatedAccordionData, ContentfulIdVariable>(query, {
    variables: { id: props.sys.id },
  });

  const {
    accordionContentsCollection,
    mediaDirection = AnimatedAccordionMediaDirection.End,
    autoPlaySpeed: autoPlaySpeedSecs = 10,
    textAlignmentMobile = Alignment.Start,
  } = data?.animatedAccordion ?? {};

  const { getImageSources } = useContentfulImages();

  const items = (accordionContentsCollection?.items ?? []).map(
    (item: AnimatedAccordionContentData) => {
      const { imageSource, imageAltText } = parseMedia(item.media);
      return {
        title: renderRichTextMarkingsOnly(item.title),
        body: renderRichTextWithElements(item.body),
        imgSrcs: getImageSources(imageSource),
        imageAltText,
      };
    }
  );

  return (
    <AnimatedAccordionSDS
      items={items}
      mediaDirection={mediaDirection}
      autoPlaySpeed={autoPlaySpeedSecs}
      textAlignmentMobile={textAlignmentMobile}
    />
  );
};
