import { LegendOrdinal } from '@visx/legend';
import type { ScaleOrdinal } from 'd3-scale';
import type { ReactElement } from 'react';

import { chartLegendContainerCss } from '../styles';
import { defaultMargin } from '../utils/helpers';

export type ChartLegendProps = {
  colorScale: ScaleOrdinal<string, string>;
  keys: string[];
};

export const ChartLegend = (props: ChartLegendProps): ReactElement => {
  const { colorScale, keys } = props;

  return (
    <legend className={chartLegendContainerCss} style={{ top: defaultMargin.top / 2 - 10 }}>
      <LegendOrdinal scale={colorScale} domain={keys} direction="row" labelMargin="0 15px 0 0" />
    </legend>
  );
};
