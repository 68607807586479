import { css } from '@emotion/css';
import { nonMobileMediaQuery } from '@snapchat/snap-design-system-marketing';

const mobileSummaryCardHeight = 180;

const summaryCardHeroHeight = 280;

export const summaryCardHeroCss = css`
  height: ${mobileSummaryCardHeight}px;

  ${nonMobileMediaQuery} {
    height: ${summaryCardHeroHeight}px;
  }
`;
