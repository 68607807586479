import { cx } from '@emotion/css';
import type { FC, PropsWithChildren, ReactNode } from 'react';

import { Alignment } from '../../constants';
import type { ImageSources } from '../../types';
import { dataSetToAttributes, mobileAlignmentCss } from '../../utils';
import { Picture } from '../Picture';
import { headerDesktopCss, headerImageCss, headerMobileCss } from './HeroHeader.styles';
import { dateDesktopString, dateMobileString, isDate } from './utils';

interface Props {
  content?: ReactNode | Date;
  /** @deprecated Use iconSrcs instead */
  iconSource?: string;
  /** @deprecated */
  desktopCutoff?: number;
  iconSrcs?: ImageSources;
  iconAltText?: string;
  textAlignMobile?: Alignment;
  headerDataset?: DOMStringMap;
  className?: string;
}

export const HeroHeader: FC<PropsWithChildren<Props>> = ({
  content,
  iconSrcs,
  iconAltText,
  textAlignMobile,
  headerDataset,
  className,
}) => {
  if (!content && !iconSrcs) {
    return null;
  }

  const headerIsDate = isDate(content);
  const headerMobile = headerIsDate ? dateMobileString(content as Date) : content;
  const headerDesktop = headerIsDate ? dateDesktopString(content as Date) : content;

  const icon = iconSrcs && (
    <div className={headerImageCss}>
      <Picture imgSrcs={iconSrcs} imgClassName={headerImageCss} altText={iconAltText} />
    </div>
  );

  return (
    <>
      <div
        data-test-id="sdsm-hero-header"
        className={cx(headerDesktopCss, className)}
        {...dataSetToAttributes(headerDataset)}
      >
        {icon}
        {headerDesktop}
      </div>
      <div
        className={cx(
          mobileAlignmentCss[textAlignMobile ?? Alignment.Start],
          headerMobileCss,
          className
        )}
        {...dataSetToAttributes(headerDataset)}
      >
        {icon}
        {headerMobile}
      </div>
    </>
  );
};

HeroHeader.displayName = 'HeroHeader';
