import type React from 'react';

import { DesignAgnosticButton } from '../designAgnostic';
import { CookieRichText } from '../shared/CookieRichText';
import type { BackgroundType, LocaleOption } from '../types';
import { CookieModalFooter } from './CookieModalFooter';
import { CookieModalHeader } from './CookieModalHeader';

interface Props {
  backgroundType: BackgroundType;
  supportedLocales: Record<string, LocaleOption>;
  title: string;
  content: Record<string, unknown>;
  acceptAllButtonText: string;
  essentialOnlyButtonText: string;
  settingsButtonText: string;
  onAcceptAllButtonClick: () => void;
  onAcceptEssentialButtonClick: () => void;
  onSettingsbuttonClick: () => void;
  onLocaleChange?: (locale: string) => void;
}

export const CookieLandingScreen: React.FC<Props> = ({
  backgroundType,
  supportedLocales,
  title,
  content,
  settingsButtonText,
  acceptAllButtonText,
  essentialOnlyButtonText,
  onSettingsbuttonClick,
  onAcceptAllButtonClick,
  onAcceptEssentialButtonClick,
  onLocaleChange,
}) => (
  <div data-testid="mwp-cookie-landing-screen" className="cookie-landing-screen">
    <CookieModalHeader
      backgroundType={backgroundType}
      supportedLocales={supportedLocales}
      onLocaleChange={onLocaleChange}
    />
    <div data-testid="mwp-cookie-modal-body" className="modal-body">
      <h2 className="cookie-title">{title}</h2>
      <CookieRichText document={content} className="landing-content" />
    </div>
    <CookieModalFooter>
      <DesignAgnosticButton
        isPrimary={true}
        text={essentialOnlyButtonText}
        onClick={onAcceptEssentialButtonClick}
      />
      <DesignAgnosticButton
        isPrimary={true}
        text={acceptAllButtonText}
        onClick={onAcceptAllButtonClick}
      />
      <DesignAgnosticButton
        isPrimary={false}
        text={settingsButtonText}
        onClick={onSettingsbuttonClick}
      />
    </CookieModalFooter>
  </div>
);
