import { useContext } from 'react';

import { AppContext } from '../../AppContext';
import { ShopifyContext } from '../ShopifyProvider';

export const usePrice = (price: number): string => {
  const { userLocation } = useContext(AppContext);
  const { currencyCode } = useContext(ShopifyContext);

  if (price === 0) {
    return '';
  }

  return new Intl.NumberFormat(userLocation.country, {
    style: 'currency',
    currency: currencyCode ?? 'USD',
  }).format(price);
};
