import type { Site } from './sites';

/** Returns elastic search index for the SEARCH functionality. */
export const getElasticSearchAliasName = (
  site: Site,
  environmentId: string,
  isPreview: boolean,
  prefix?: string
): string => {
  const suffix = isPreview ? 'staging' : 'production';

  const baseName = `search_${site}_${environmentId}_${suffix}`;

  if (prefix) {
    return `${prefix}_${baseName}`;
  }

  return baseName;
};

/** Elastic Alias for the JOBS functionality */
export const elasticJobsAliasName = 'jobs_production';
/** Elastic index prefix for the jobs indexes. */
export const elasticJobsIndexPrefix = 'jobs_';

/** Returns elastic search index for the GALLERY functionality */
export const getElasticGalleryAliasName = (
  site: Site,
  environmentId: string,
  isPreview: boolean
): string => {
  const stagingOrProduction = isPreview ? 'staging' : 'production';
  return `gallery_${site}_${environmentId}_${stagingOrProduction}`;
};
