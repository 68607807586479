import { css } from '@emotion/css';

import { m } from '../../motif';

export const codeCss = css`
  code[class*='language-'],
  pre[class*='language-'] {
    border-radius: ${m('--border-radius-l')};
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  }

  pre[class*='language-'] {
    padding: 1em;
    margin: 0.5em 0;
    overflow: auto;
    max-width: 100%;
  }
`;
