import { getTopLevelDomain } from '@snapchat/parse-domain';

export const cookieSettingsPath = '/cookie-settings';

export function getCookieDomain(domain: string, cookieDomain?: string | undefined): string {
  // Use input value if specified
  if (cookieDomain) return cookieDomain;

  // Use top level domain (ar.snap.com => snap.com)
  const topLevelDomain = getTopLevelDomain(domain);
  // Return top level domain or empty string
  return topLevelDomain;
}

export const getCookieSettingsUrl = (domain: string, cookieDomain?: string | undefined): string => {
  cookieDomain = getCookieDomain(domain, cookieDomain);

  const isSameDomain = domain === cookieDomain;
  return isSameDomain ? cookieSettingsPath : `https://${cookieDomain}${cookieSettingsPath}`;
};
