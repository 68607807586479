import { cx } from '@emotion/css';
import type { FC } from 'react';

import { Icon } from '../Icon';
import { skeletonWrapperClassName } from '../visualizations/ChartToggle/styles';
import {
  skeletonDropdownIconCss,
  skeletonIconWrapperCss,
  skeletonLabelCss,
  skeletonLineAnimationCss,
  skeletonLineCss,
  skeletonLinesWrapperCss,
  skeletonWrapperCss,
} from './DropdownSkeleton.styles';

/**
 * `SkeletonChartToggleDropdown` is a functional component that renders a skeleton screen
 * placeholder for a chart toggle dropdown. This component is typically used to display a loading
 * state while the actual chart toggle dropdown is being loaded. Usage:
 *
 * ```jsx
 * <SkeletonChartToggleDropdown />;
 * ```
 *
 * @returns A React element representing the skeleton state of a chart toggle dropdown.
 */
export const DropdownSkeleton: FC = () => (
  <aside className={cx('sdsm-dropdown-skeleton', skeletonWrapperClassName, skeletonWrapperCss)}>
    <div className={cx(skeletonLabelCss, skeletonLineAnimationCss)} />
    <div className={cx(skeletonLinesWrapperCss)}>
      <div className={cx(skeletonLineCss, skeletonLineAnimationCss)} />
      <div className={cx(skeletonLineCss, skeletonLineAnimationCss)} />
      <div className={skeletonIconWrapperCss}>
        <Icon size={20} className={skeletonDropdownIconCss} name="chevron-down" />
      </div>
    </div>
  </aside>
);
