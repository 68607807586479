// Logic is based on:
// https://github.sc-corp.net/Snapchat/web-self-serve-ads/blob/master/client/src/apps/shared/scripts/l10n/utils/bhc.ts
//
// That site is built atop Salesforce and requires special handling.
// - uses `language` instead of `lang` for tracking locale
// - uses Salesforce language codes instead of web locale
// - only supports 2 languages currently

export const businessHelpHostname = 'businesshelp.snapchat.com';

/**
 * Maps web locale codes to language equivalents for businesshelp.snapchat.com
 *
 * See this page for list of Salesforce supported languages:
 * https://help.salesforce.com/s/articleView?id=sf.faq_getstart_what_languages_does.htm&type=5 Not
 * all of these are supported by businesshelp.snapchat.com, but the site will roll over to en_US for
 * unsupported languages.
 */
const localeLanguageMap: Record<string, string | undefined> = {
  ar: 'ar',
  'da-DK': 'da',
  'de-DE': 'de',
  'el-GR': 'el',
  'en-GB': 'en_GB',
  'en-US': 'en_US',
  'es-ES': 'es',
  'es-MX': 'es_MX',
  'fi-FI': 'fi',
  'fr-CA': 'fr',
  'fr-FR': 'fr',
  'id-ID': 'in',
  'it-IT': 'it',
  'ja-JP': 'ja',
  'ko-KR': 'ko',
  'nb-NO': 'no',
  'nl-NL': 'nl_NL',
  'pl-PL': 'pl',
  'pt-BR': 'pt_BR',
  'pt-PT': 'pt_PT',
  'ru-RU': 'ru',
  'sv-SE': 'sv',
  'th-TH': 'th',
  'tr-TR': 'tr',
  'vi-VN': 'vi',
  'zh-Hans': 'zh_CN',
  'zh-Hant': 'zh_TW',
};

/**
 * Appends the specified url with the `language` query parameter.
 *
 * Includes special logic for the businesshelp.snapchat.com website.
 */
export const appendLocaleToBusinessHelpUrl = (url: URL, locale: string): void => {
  const newLocale = localeLanguageMap[locale];

  if (newLocale && !url.searchParams.has('language')) {
    url.searchParams.set('language', newLocale);
  }
};
