import type { VideoItem } from '@snapchat/snap-design-system-marketing';
import { MultiVideoBlock as MultiVideoBlockSDS } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import type { ContentfulIdVariable } from '../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../hooks/useContentfulQuery';
import type { VideoDataProps } from '../Video/types';
import { query } from './query';
import type { MultiVideoBlockProps, MultiVideoDataHandlerProps } from './types';

const updateCollection = (collection: Array<VideoDataProps>) => {
  if (!collection || !collection.length) return [];
  return collection.map(item => {
    return { ...item, id: item.sys.id } as VideoItem;
  });
};

export const MultiVideoBlock: FC<MultiVideoBlockProps> = ({
  children,
  id,
  togglePanelClassName,
  togglePanelLabel,
  preChildren,
  postChildren,
  ...otherProps
}) => {
  const { data } = useContentfulQuery<MultiVideoDataHandlerProps, ContentfulIdVariable>(query, {
    variables: { id },
  });

  if (data) {
    const {
      anchorId,
      panelPosition,
      primaryVideosCollection,
      secondaryVideosCollection,
      mobilePrimaryVideosCollection,
      mobileSecondaryVideosCollection,
    } = data.multiVideoBlock;

    return (
      <MultiVideoBlockSDS
        anchorId={anchorId}
        id={id}
        toggleButtonIcons={['hover', 'reveal', 'follow', 'orbit']}
        panelPosition={panelPosition}
        primaryVideos={updateCollection(primaryVideosCollection?.items)}
        secondaryVideos={updateCollection(secondaryVideosCollection?.items)}
        mobilePrimaryVideos={updateCollection(mobilePrimaryVideosCollection?.items)}
        mobileSecondaryVideos={updateCollection(mobileSecondaryVideosCollection?.items)}
        togglePanelLabel={togglePanelLabel}
        togglePanelClassName={togglePanelClassName}
        preChildren={preChildren}
        postChildren={postChildren}
        {...otherProps}
      >
        {children}
      </MultiVideoBlockSDS>
    );
  }

  return null;
};

MultiVideoBlock.displayName = 'MultiVideoBlock';
