import { Black, Gray, White, Yellow } from '../../constants';
import type { MotifComponent, MotifComponentProps } from '../../motif';

export const toggleSliderBlackVars: MotifComponentProps<MotifComponent.TOGGLE_SLIDER> = {
  '--toggle-slider-background-color': Gray.V100,
  '--toggle-slider-active-color': Yellow.V100,
  '--toggle-slider-switch-color': White,
};

export const toggleSliderDarkVars: MotifComponentProps<MotifComponent.TOGGLE_SLIDER> = {
  '--toggle-slider-background-color': Black.V200,
  '--toggle-slider-active-color': Yellow.V100,
  '--toggle-slider-switch-color': White,
};

export const toggleSliderYellowVars: MotifComponentProps<MotifComponent.TOGGLE_SLIDER> = {
  '--toggle-slider-background-color': Gray.V200,
  '--toggle-slider-active-color': Black.V200,
  '--toggle-slider-switch-color': White,
};

export const toggleSliderWhiteVars: MotifComponentProps<MotifComponent.TOGGLE_SLIDER> = {
  '--toggle-slider-background-color': Black.V50,
  '--toggle-slider-active-color': Yellow.V100,
  '--toggle-slider-switch-color': White,
};

export const toggleSliderLightVars: MotifComponentProps<MotifComponent.TOGGLE_SLIDER> = {
  '--toggle-slider-background-color': White,
  '--toggle-slider-active-color': Yellow.V100,
  '--toggle-slider-switch-color': Black.V50,
};
