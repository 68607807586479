import type { FC } from 'react';

import type { ChartToggleItemProps } from './types';

export interface ChartTogglePanelsV2Props {
  items: Array<ChartToggleItemProps>;
  selectedChart: string;
}

export const ChartTogglePanelsV2: FC<ChartTogglePanelsV2Props> = ({ items, selectedChart }) => {
  // We need to have this component as a direct child on the ChartToggleWrapper component.
  return <>{items.map(({ content }, index) => selectedChart === index.toString() && content)}</>;
};
