import type { FC } from 'react';

import { useContentfulQuery } from '../../hooks/useContentfulQuery';
import { Gallery } from './Gallery';
import type { LazyGalleryQueryResponse } from './query';
import { lazyGalleryQuery } from './query';
import type { LazyGalleryProps } from './types';

export const LazyGallery: FC<LazyGalleryProps> = props => {
  const galleryId = props.sys.id;

  const { data: galleryData } = useContentfulQuery<
    LazyGalleryQueryResponse,
    {
      id: string;
    }
  >(lazyGalleryQuery, {
    variables: { id: galleryId },
  });

  const gallery = galleryData?.gallery;

  if (!gallery) {
    return null;
  }

  return <Gallery gallery={gallery} />;
};

LazyGallery.displayName = 'LazyGallery';
