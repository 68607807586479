import { css } from '@emotion/css';
import { DeploymentType } from '@snapchat/mw-common';
import {
  BackgroundType,
  CookieSettings as CookieSettingsGlobal,
} from '@snapchat/mw-global-components';
import { BackgroundColor, Block } from '@snapchat/snap-design-system-marketing';
import type React from 'react';

import { NoMatchDefault } from '../NoMatch/NoMatchDefault';

export type CookieSettingsProps = {
  cookieDomain: string;
  deploymentType: DeploymentType;
  domainName: string;
  isClient: boolean;
  backgroundColor?: BackgroundColor;
};

const defaultBackgroundColor = BackgroundColor.White;

const noPaddingTopCss = css`
  padding-top: 0;
`;

export const CookieSettings: React.FC<CookieSettingsProps> = ({
  cookieDomain,
  deploymentType,
  domainName,
  isClient,
  backgroundColor = defaultBackgroundColor,
}) => {
  // Use current domain for local development and in Dev/Staging environments
  if (deploymentType !== DeploymentType.PRODUCTION) {
    domainName = '';
    cookieDomain = '';
  }

  const isSameDomain = domainName === cookieDomain;
  const backgroundType =
    backgroundColor === BackgroundColor.Black ? BackgroundType.DarkMode : BackgroundType.LightMode;

  // Return 404 if using cookies from a different domain.
  if (!isSameDomain) return <NoMatchDefault />;

  // Return nothing for SSR
  if (!isClient) return null;

  return (
    <Block backgroundColor={backgroundColor} className={noPaddingTopCss}>
      <CookieSettingsGlobal cookieDomain={cookieDomain} backgroundType={backgroundType} />
    </Block>
  );
};
