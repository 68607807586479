import { css } from '@emotion/css';

import { mobileMediaQuery } from '../../../constants';
import { m } from '../../../motif';

export const heroMediaCss = css`
  /* Setting height so that we reserve the space for the video/carousel. */
  max-height: 650px;

  /* Limiting the width to at least allow for 1 tile. */
  min-width: 274px;
  max-width: 100%;

  /* Matches the padding on the Hero Text container. */
  padding-inline: ${m('--spacing-xxl')};

  /* TODO: Fix this for the case where VIDEOS overflow */
  overflow: visible;

  ${mobileMediaQuery} {
    height: unset;
  }

  /* Limits the height of the video wrapper. Without this max-height is ignored */
  * {
    max-height: 650px;
  }
`;

export const heroWideMediaCss = css`
  padding-inline: 0;
`;

export const compactHeroMediaCss = css`
  max-height: 350px;

  /* Limits the height of the video wrapper. Without this max-height is ignored */
  * {
    max-height: 350px;
  }
`;
