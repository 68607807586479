/* eslint-disable max-len */
import type { FC } from 'react';

import type { GenericSvgIconProps } from '../CustomIcon';

export const RevealIcon: FC<GenericSvgIconProps> = props => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.76 0.59C10.39 0.58 8.07 1.30 6.10 2.65C4.12 3.99 2.59 5.91 1.68 8.15C0.77 10.39 0.53 12.85 0.99 15.23C1.45 17.61 2.59 19.80 4.27 21.51C5.95 23.23 8.08 24.40 10.41 24.87C12.74 25.34 15.15 25.10 17.34 24.17C19.53 23.25 21.41 21.68 22.73 19.66C24.04 17.64 24.75 15.27 24.75 12.85C24.75 9.60 23.49 6.48 21.24 4.18C18.99 1.88 15.94 0.59 12.76 0.59ZM9.80 4.58C10.30 4.58 10.79 4.74 11.21 5.02C11.63 5.31 11.95 5.71 12.14 6.19C12.34 6.66 12.39 7.18 12.29 7.68C12.19 8.19 11.95 8.65 11.59 9.01C11.24 9.38 10.79 9.62 10.29 9.72C9.80 9.82 9.29 9.77 8.83 9.58C8.36 9.38 7.97 9.05 7.69 8.62C7.41 8.19 7.26 7.69 7.26 7.18C7.26 6.49 7.53 5.83 8.00 5.34C8.48 4.86 9.12 4.58 9.80 4.58ZM3.87 17.95L7.78 11.86L10.71 16.38L15.60 8.02L21.74 17.95H3.87Z"
      />
    </svg>
  );
};
