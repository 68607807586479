import { gql } from '@apollo/client';

import type { ContentfulTypedSysProps } from '../../types/contentful';
import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';

// =================================================================================================
// Blog Schema Fragment
// =================================================================================================

export const blogSchemaFragment = gql`
  fragment BlogSchemaAll on BlogSchema {
    ...ContentfulSysId
    headline
    description
    imagesCollection(limit: 10) {
      items {
        url
      }
    }
  }
  ${contentfulSysIdFragment}
`;

export interface BlogSchemaData extends ContentfulTypedSysProps<'BlogSchema'> {
  headline: string;
  description?: string;
  imagesCollection?: { items?: { url?: string }[] };
}
