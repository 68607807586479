import { gql } from '@apollo/client';
import type { Alignment, BackgroundColor } from '@snapchat/snap-design-system-marketing';

import type { ImageDataProps } from '../../components/Image';
import { fragments as imageFragments } from '../../components/Image';
import type { VideoDataProps } from '../../components/Video';
import { fragments as videoFragments } from '../../components/Video';
import type { ContentfulTypedSysProps } from '../../types/contentful';
import type { Items } from '../../types/Items';
import type { RichText } from '../../types/RichText';
import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';
import { ctaFragment } from '../CallToAction';
import type { CallToActionDataProps } from '../CallToAction/types';

/**
 * The fields `title`, `subtitle`, and `body` have been renamed to `splitBlockTitle`,
 * `splitBlockSubtitle`, and `splitBlockBody` respectively. This prevents naming collisions with the
 * existing `Block` content type, which uses the same field names in its GraphQL query.
 */
export const splitBlockFragment = gql`
  fragment SplitBlockAll on SplitBlock {
    ...ContentfulSysId
    callsToActionCollection(limit: 2) {
      items {
        ... on CallToAction {
          ...CallToActionAll
        }
      }
    }
    splitBlockTitle: title
    splitBlockSubtitle: subtitle
    splitBlockBody: body {
      json
    }
    textAlignment
    textAlignmentMobile
    verticalTextAlignment
    media {
      __typename
      ... on Video {
        ...VideoAll
      }
      ... on Image {
        ...ImageAll
      }
    }
    mediaCaption
    mediaDirection
    backgroundColor
    fitWindow
  }
  ${contentfulSysIdFragment}
  ${ctaFragment}
  ${imageFragments.all}
  ${videoFragments.all}
`;

export const splitBlockQuery = gql`
  query SplitBlockQuery($preview: Boolean!, $locale: String!, $id: String!) {
    splitBlock(preview: $preview, locale: $locale, id: $id) {
      ...SplitBlockAll
    }
  }
  ${splitBlockFragment}
`;

export interface SplitBlockDataProps extends ContentfulTypedSysProps<'SplitBlock'> {
  backgroundColor: BackgroundColor;
  callsToActionCollection?: Items<CallToActionDataProps>;
  fitWindow?: boolean;
  media: ImageDataProps | VideoDataProps;
  mediaCaption?: string;
  mediaDirection: Alignment;
  splitBlockBody?: RichText | string;
  splitBlockSubtitle?: string;
  splitBlockTitle: string;
  textAlignment: Alignment;
  textAlignmentMobile: Alignment;
  verticalTextAlignment: Alignment;
}

export interface SplitBlockDataHandlerProps {
  splitBlock: SplitBlockDataProps;
}
