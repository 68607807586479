import { css } from '@emotion/css';

import { Gray, mobileMediaQuery, ZIndex } from '../../../constants';
import { m } from '../../../motif/m';
import { globalCss } from '../../../utils';
import { globalHeaderHeight } from '../../GlobalHeader/GlobalHeader.constants';

export const itemClassName = 'navigator-item';
export const clickableNavigatorItemClassName = 'navigator-item-click';

export const navItemSelectedCss = css`
  font-weight: 700;
  color: ${m('--global-header-navigator-item-active-color')};

  /* Taken from reactMenuCss - note the menu itself is not themed. The background is always white.*/
  .szh-menu__item--active,
  &.szh-menu__item--active {
    color: #fff;
    background-color: ${Gray.V150};
  }
`;

export const menuItemCss = css`
  && {
    margin: unset;
    padding: unset;
  }

  /* TODO: Font-weight should use motif vars, but first we need to change the active page indicator. https://jira.sc-corp.net/browse/ENTWEB-8894 */
  font-weight: 400;
  font-size: 13px;
  color: ${m('--global-header-navigator-item-color')};
`;

const sharedCss = css`
  color: ${m('--global-header-navigator-item-color')};
  display: flex;
  font-size: ${m('--action-desktop-font-size')};
  font-weight: 500;
  text-decoration: none;
  white-space: nowrap;

  ${mobileMediaQuery} {
    font-size: ${m('--action-mobile-font-size')};
  }
`;

export const itemCss = css`
  ${sharedCss}
  align-items: center;
  line-height: ${globalHeaderHeight}px;
  margin-left: ${m('--spacing-m')};
  margin-right: ${m('--spacing-m')};
  padding-bottom: 0;
  padding-top: 0;

  &.${clickableNavigatorItemClassName} {
    cursor: pointer;

    &:hover {
      color: ${m('--global-header-navigator-item-hover-color')};
    }

    & span {
      position: relative; /* For the underline */
      overflow-y: hidden;
    }

    /** Custom underline */
    & span::after {
      /* TODO: consolidate the rounded underline css? */
      background-color: ${m('--global-header-navigator-item-hover-color')};
      bottom: -8px;
      content: '';
      height: 8px;
      left: 0;
      position: absolute;
      border-radius: ${m('--border-radius-s')};
      transform: translateY(0);
      transition: all 0.15s ease;
      width: 100%;
    }

    &:hover span::after {
      opacity: 0.6; /* TODO: convert to motif variable instead */
      transform: translateY(-4px);
    }

    & span.selected {
      color: ${m('--global-header-navigator-item-active-color')};
    }

    & span.selected::after {
      opacity: 1;
      transform: translateY(-4px);
    }
  }
`;

// moved from the old DropdownMenuV2, may need refactoring
export const reactMenuCss = globalCss`
  &.szh-menu-container,
  .szh-menu-container {
    position: relative;
    width: 0px;
    height: 0px;
  }

  .szh-menu {
    margin: 0;
    padding: 0;
    list-style: none;
    box-sizing: border-box;
    width: max-content;
    position: absolute;
    z-index: ${ZIndex.DROPDOWN};
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  .szh-menu:focus {
    outline: none;
  }
  .szh-menu--state-closed {
    display: none;
  }
  .szh-menu__arrow {
    box-sizing: border-box;
    width: 0.75rem;
    height: 0.75rem;
    background-color: #fff;
    border: 1px solid transparent;
    border-left-color: rgba(0, 0, 0, 0.1);
    border-top-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    z-index: -1;
  }
  .szh-menu__arrow--dir-left {
    right: -0.375rem;
    transform: translateY(-50%) rotate(135deg);
  }
  .szh-menu__arrow--dir-right {
    left: -0.375rem;
    transform: translateY(-50%) rotate(-45deg);
  }
  .szh-menu__arrow--dir-top {
    bottom: -0.375rem;
    transform: translateX(-50%) rotate(-135deg);
  }
  .szh-menu__arrow--dir-bottom {
    top: -0.375rem;
    transform: translateX(-50%) rotate(45deg);
  }
  .szh-menu__item {
    cursor: unset;
  }
  .szh-menu__item:focus {
    outline: none;
  }
  .szh-menu__item--hover {
    background-color: unset;
  }
  .szh-menu__item--focusable {
    cursor: default;
    background-color: inherit;
  }
  .szh-menu__item--disabled {
    cursor: default;
    color: #aaa;
  }
  .szh-menu__submenu {
    position: relative;
  }
  .szh-menu__group {
    box-sizing: border-box;
  }
  .szh-menu__radio-group {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .szh-menu__divider {
    height: 1px;
    margin: 0.5rem 0;
    background-color: rgba(0, 0, 0, 0.12);
  }

  .szh-menu-button {
    box-sizing: border-box;
  }

  .szh-menu {
    user-select: none;
    color: #212529;
    border: none;
    border-radius: 0.25rem;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.133), 0 0.6px 2px rgba(0, 0, 0, 0.1);
    min-width: 10rem;
    padding: 0.5rem 0;
  }
  .szh-menu__item {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0.375rem 1.5rem;
  }
  .szh-menu-container--itemTransition .szh-menu__item {
    transition-property: background-color, color;
    transition-duration: 0.15s;
    transition-timing-function: ease-in-out;
  }

  .szh-menu__item--type-radio {
    padding-left: 2.2rem;
  }
  .szh-menu__item--type-radio::before {
    content: '\\25CB';
    position: absolute;
    left: 0.8rem;
    top: 0.55rem;
    font-size: 0.8rem;
  }
  .szh-menu__item--type-radio.szh-menu__item--checked::before {
    content: '\\25CF';
  }
  .szh-menu__item--type-checkbox {
    padding-left: 2.2rem;
  }
  .szh-menu__item--type-checkbox::before {
    position: absolute;
    left: 0.8rem;
  }
  .szh-menu__item--type-checkbox.szh-menu__item--checked::before {
    content: '\\2714';
  }
  .szh-menu__submenu > .szh-menu__item {
    padding-right: 2.5rem;
  }
  .szh-menu__submenu > .szh-menu__item::after {
    content: '\\276F';
    position: absolute;
    right: 1rem;
  }
  .szh-menu__header {
    color: #888;
    font-size: 0.8em;
    padding: 0.2rem 1.5rem;
    text-transform: uppercase;
  }

  *[dir='rtl'] .szh-menu--dir-right {
    left: -10.3rem !important;
  }

  *[dir='rtl'] .szh-menu--dir-left {
    left: -12rem !important;
  }
`;

export const selectedCss = css`
  text-decoration: underline;
`;

export const iconCss = css`
  /**
   * use negative margin to make icon take up space same as previous
   */
  margin: calc(${m('--spacing-xxs')} * -1);
  fill: ${m('--global-header-navigator-item-color')};

  margin-left: calc(${m('--spacing-xs')} - 4px);
  *[dir='rtl'] & {
    margin-right: calc(${m('--spacing-xs')} - 4px);
  }
`;

export const anchorCss = css`
  color: ${m('--global-header-navigator-item-color')};
  text-decoration: none;
  display: inline-block;

  :hover,
  :focus {
    background-color: ${Gray.V150};
    border-radius: ${m('--spacing-xxs')};
  }

  margin: ${m('--spacing-xxs')};
  padding: 0.375rem 1.5rem;
  width: 100%;
  height: 100%;
`;
