import type { FC, ReactNode } from 'react';
import { useContext, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

import { BrowserFeaturesContext, isMobileOs } from '../../BrowserFeatures';
import { MotifComponent, useMotifStyles } from '../../motif';
import { TooltipTrigger } from './types';

export type TooltipPlace = 'top' | 'right' | 'bottom' | 'left';

export interface TooltipProps {
  children?: ReactNode;
  content: ReactNode;
  trigger?: TooltipTrigger;
  delayHide?: number;
  place?: TooltipPlace;
  globalEventOff?: string;
  tooltipClassName?: string;
  uniqueId: string;
}

/**
 * Wraps the children in a click listener that shows a tooltip.
 *
 * TODO: Rewrite this using our own code and not use external packages.
 */
export const Tooltip: FC<TooltipProps> = ({
  children,
  content,
  trigger,
  delayHide,
  place,
  globalEventOff,
  tooltipClassName,
  uniqueId,
}: TooltipProps) => {
  useMotifStyles(MotifComponent.TOOLTIP);
  const browserFeatures = useContext(BrowserFeaturesContext);

  // for tablets and phones, tooltip should also be set on click
  const isForMobileClicks = trigger === TooltipTrigger.OnClick || isMobileOs(browserFeatures);
  const defaultGlobalEventOff = isForMobileClicks ? 'click' : undefined;

  // We have to rebuild the tooltips when trigger change for the new behavior to take effect
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [trigger]);

  return (
    <>
      <span
        className={MotifComponent.TOOLTIP}
        data-tip
        data-for={uniqueId}
        data-event={isForMobileClicks ? 'click focus' : undefined}
      >
        {children}
      </span>
      <ReactTooltip
        uuid={uniqueId}
        id={uniqueId}
        place={place ?? 'top'}
        effect="solid"
        delayHide={delayHide ?? 500}
        globalEventOff={globalEventOff ?? defaultGlobalEventOff}
        className={tooltipClassName}
      >
        {content}
      </ReactTooltip>
    </>
  );
};

Tooltip.displayName = 'Tooltip';
