import type { FC } from 'react';
import { useEffect } from 'react';

import { Config } from '../../config';
import { logError } from '../../helpers/logging';
import { sanitizeCspResource } from '../../utils/url/sanitizeCspResource';

const ignoredBlockedUriBySourcePrefix: Record<string, Set<string>> = {
  // Per discussion w/ Alex, we will not log these specific violations as they are known issues w/ gtm
  'https://www.googletagmanager.com/gtm.js': new Set(['eval']),
  // Per team discussion, we will not log these as they are noops
  [`https://${Config.domainName}/mwp-chunks/`]: new Set(['eval', 'properties']),
  // Per team discussion, we will not log these as they are noops
  Unknown: new Set(['eval', 'inline']),
};

/** Component used to report CSP Security Violations. */
export const LogCspViolation: FC = () => {
  useEffect(() => {
    // The `securitypolicyviolation` event fires multiple times for a single violation,
    // this way we don't log the violation more than once per app load.
    const reported = new Set<string>();

    const listener = (e: SecurityPolicyViolationEvent) => {
      // NOTE: blockedURI and sourceFile may contain URLs with query parameters
      const { blockedURI, violatedDirective, sourceFile } = e;
      const key = `${blockedURI}|${violatedDirective}|${sourceFile}`;

      if (reported.has(key)) return; // already reported, noop

      for (const sourceFilePrefix in ignoredBlockedUriBySourcePrefix) {
        const blockedUriValues = ignoredBlockedUriBySourcePrefix[sourceFilePrefix];

        if (blockedUriValues?.has(blockedURI) && sourceFile.startsWith(sourceFilePrefix)) {
          reported.add(key); // Add to set to bypass this check on subsequent fires
          return; // known scenario that we don't want to log, noop
        }
      }

      logError({
        component: 'Csp',
        error: 'CspViolationError',
        message: `Blocked ${blockedURI}, ${sourceFile}, ${violatedDirective}`,
        context: {
          blockedUri: sanitizeCspResource(blockedURI),
          sourceFile,
          violatedDirective: sanitizeCspResource(violatedDirective),
        },
      });

      reported.add(key);
    };

    document.addEventListener('securitypolicyviolation', listener);

    return () => {
      document.removeEventListener('securitypolicyviolation', listener);
    };
  }, []);

  return null;
};
