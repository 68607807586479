import type { MotifComponent, MotifComponentProps } from '../../motif';
import { m } from '../../motif/m';

const defaultHyperlinkActiveColorVars = {
  '--hyperlink-color': m('--action-default-color'),
  '--hyperlink-hover-color': m('--action-hover-color'),

  '--hyperlink-desktop-font-size': m('--action-desktop-font-size'),
  '--hyperlink-desktop-font-line-height': m('--action-desktop-font-line-height'),
  '--hyperlink-desktop-font-weight': m('--action-desktop-font-weight'),
  '--hyperlink-desktop-font-text-decoration': m('--action-desktop-font-text-decoration'),
  '--hyperlink-desktop-font-letter-spacing': m('--action-desktop-font-letter-spacing'),
  '--hyperlink-mobile-font-size': m('--action-mobile-font-size'),
  '--hyperlink-mobile-font-line-height': m('--action-mobile-font-line-height'),
  '--hyperlink-mobile-font-weight': m('--action-mobile-font-weight'),
  '--hyperlink-mobile-font-text-decoration': m('--action-mobile-font-text-decoration'),
  '--hyperlink-mobile-font-letter-spacing': m('--action-mobile-font-letter-spacing'),
};

export const hyperLinkVarsBg: MotifComponentProps<MotifComponent.HYPERLINK> = {
  ...defaultHyperlinkActiveColorVars,
  // No other vars here for now.
};
