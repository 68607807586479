import { Site } from '@snapchat/mw-common';

/** These tokens have published content read permission from the /production/ alias. */
export const productionContentTokens: Record<Site, string> = {
  [Site.A523]: 'TEeowd5MSMxWWV4qRGfuSzP6jDyCw7bGPBnSc7FgeCA',
  [Site.ARCADIA]: 'ZylclnWJF6xeq5chuHTvEpmhNm0LD6Ea0oWEQcNJXxs',
  [Site.AR]: 'iAOsVMXtR32scQ6d0zbJ_-sYO9nYKG07W_ABFJDUn88',
  [Site.CAREERS]: 'ry27SzE0GBgWgQTTHH6g7WVu3w6vGTRej-vWTRxBtRE',
  [Site.CHEERIOS]: '1bn1NIbQCGGTxkSdVtqXidIaQmeiTuWG64URI3Y2K84',
  [Site.CITIZEN]: 'UIeH-b7nqyTFwt2V3q9j4RiOwHZUI-6-2XVYX3O372A',
  [Site.CREATORS]: 'vQAHEP8QOjgzxHNAEPOm1uwmb6IblHEsgj7k4oGpJ-E',
  [Site.DIVERSITY]: 'AYCNZfq0m4oXfzlA29mV7TWnZ-hpJJ1AtaWJ6lVlG4k',
  [Site.ENG_BLOG]: 'z_FW9MI2WUz8WVdgsv8RNE7VqR2_lWnb1J7zWUTWc8U',
  [Site.EXPERIENCE]: '-8HQWmpfZSv3H0o_nVdED5WyMgNGQ3cwY-UGJRjpBCc',
  [Site.FOR_BUSINESS]: 'KdZZ9fEF015H8Vt4QKYMLEer6WRF8Cp7qYhSXtUn1V4',
  [Site.NEWSROOM]: '5hC-nHrt38Uq1VpDdMnuYHD8aPSdX2yFaIHItIsz9AA',
  [Site.ORIGINALS]: 'HY9lbLiTKCd9f0PsNttx17O-AdkcWy9JseLYGSamWr4',
  [Site.PARENTS]: 'xz7uwXl9fWX24J9thrLu9QutVk9fJjKetAZPSkFELJc',
  [Site.SANDBOX]: 'cj8SMzzCy4jmm1NZknJo1pYFmi0a_7OXvI_Jix-ScaM',
  [Site.TRUST]: 'ur-lfHTOBaqzBf0I_8-ooUzS3KbsIyIEc9HGiMfV0HE',
  [Site.AVALON]: 'Z0Ld8g2AgpMqOpLIV4JU5ymaPUlqGpUTsDvGw5VEih8',
  [Site.EDUCATORS]: 'PBrC1AS3d_KhPlR7UdEwebaW0B4nyvYhu5oY3N4hJxk',
  [Site.SNAP]: 's9X24xBFL18NQDxQmQXjdz542eu17QiYDX2OSs80e0A',
};
