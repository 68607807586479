import { mediaQueryForRange } from '../utils/layout';

// TODO: Append the "px" suffix to these.
export const maxWidth = 1440;
export const reducedWidth = 1140;
export const narrowWidth = 850;
export const mobileMaxWidth = 768;
export const largeDesktopWidth = 1240;
export const smallMobileMaxWidth = 480;
export const extraLargeDesktopWidth = 1920;

export const mobileMediaQuery = mediaQueryForRange({ max: mobileMaxWidth });
export const nonMobileMediaQuery = mediaQueryForRange({ min: mobileMaxWidth + 1 });
export const largeDesktopMediaQuery = mediaQueryForRange({ min: largeDesktopWidth + 1 });
export const extraLargeDesktopMediaQuery = mediaQueryForRange({
  min: extraLargeDesktopWidth + 1,
});

export const smallMobileMediaQuery = `@media screen and (max-width: ${smallMobileMaxWidth}px)`;

/** Screen width at which three-column layouts collapse to two-column */
export const twoColumnCollapseWidth = 1024;

export const twoColumnCollapseMediaQuery = `@media screen and (max-width: ${twoColumnCollapseWidth}px)`;
export const nonTwoColumnCollapseMediaQuery = `@media screen and (min-width: ${
  twoColumnCollapseWidth + 1
}px)`;
export const threeColumnCollapseMediaQuery = `@media screen and (max-width: ${largeDesktopWidth}px)`;
