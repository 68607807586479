/**
 * List of all the ZIndexes that we specify.
 *
 * This is meant to be a way to help avoid z-index conflicts.
 *
 * This should include z-indexes from the libraries that we use.
 *
 * Note that using z-indexes is considered an anti-pattern because render order can and should be
 * controlled via stacking contexts. See:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context
 *
 * NOTE: This can't be an enum because it's got duplicate values.
 */
export const ZIndex = {
  HERO_CURTAIN: 1, // See Hero. Required so that video background render behind content.
  BLOCK_CURTAIN: 1, // See Block. Required so that video background render behind content.
  QUESTIONNAIRE_FOOTER: 1, // See Questionnaire
  SPLIT_PANEL_CURTAIN: 1, // See Hero
  COLLAPSIBLE_CONTENT_MEDIA_WRAPPER: 1,
  CHART_TOOLTIP: 2, // See BarChartTooltip. Since blocks have a z-index, tooltips need to be higher.
  HERO_BOUNDARY: 2, // See Hero
  BLOCK_BOUNDARY: 2, //See Block
  HERO_TEXT: 2, // See Hero
  CAROUSEL_ARROW_BEFORE: 2, // See Carousel. This should match CAROUSEL_ARROW IMHO.
  NAVIGATOR_BUTTON: 2, // See NavigatorItem
  NAVIGATOR_BUTTON_FOCUSED: 3, // See NavigatorItem
  BUTTON: 50, // See Button
  CAROUSEL_SIDE_GRADIENT: 51, // See Carousel Side Gradient
  BREAK: 55, // See Break
  MOBILE_CTA: 58, // See PersistentCallToAction
  MOSAIC_LIGHTBOX: 60, // See Mosaic
  // See Carousel. Should display above its own content but below nav elements (NAVIGATOR,
  // NAVIGATOR_HEADER, NAVIGATOR_HIGHLIGHT, SUB_NAVIGATION)
  CAROUSEL_ARROW: 98,
  SIDENAV: 98,
  LOADING_BAR: 99,
  SPINNER: 99, // See Spinner.
  SUB_NAVIGATION: 99, // See SubNavigation. Allow sub navigation header to cover content it scrolls over.
  SIDE_OVERLAY: 99, // See SideOverlay
  NAVIGATOR: 100, // See GlobalNav. Exists solely so that buttons don't bleed through it.
  NAVIGATOR_HEADER: 101, // Makes sticky header cover content it scrolls over.
  NAVIGATOR_HIGHLIGHT: 102, // Highlight does need to cover the header.
  MODAL: 1999,
  DROPDOWN: 2020, // See DropdownMenu
} as const;
