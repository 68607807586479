import { Definition as DefinitionSDS } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import { renderRichTextWithElements } from '../../utils/renderText/renderRichText';
import { DefinitionContext } from './DefinitionContext';
import type { DefinitionProps } from './types';

export const Definition: FC<DefinitionProps> = ({
  displayText,
  fullText,
  anchorId,
  overlayTitle,
  overlayBody,
  className,
}) => {
  const { open, close, triggerRef } = useContext(DefinitionContext);
  const ref = useRef<HTMLButtonElement>(null);
  const isActive = ref === triggerRef;

  const content = useMemo(
    () => ({
      title: overlayTitle ?? displayText,
      body: overlayBody ?? renderRichTextWithElements(fullText),
    }),
    [displayText, fullText, overlayTitle, overlayBody]
  );

  useEffect(() => {
    if (anchorId && window.location.hash === `#${anchorId}`) {
      open(content, ref);
    }

    // We only want to fire this once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = useCallback(() => {
    if (isActive) {
      close();

      // Remove hash from url
      const url = new URL(window.location.href);
      url.hash = '';
      window.history.replaceState(null, '', url);
    } else {
      open(content, ref);

      // Add hash to url
      const url = new URL(window.location.href);
      url.hash = anchorId ? `#${anchorId}` : ''; // If no anchor id, remove hash
      window.history.replaceState(null, '', url);
    }
  }, [isActive, anchorId, content, ref, open, close]);

  // If no display text then do not render anything, as nothing to show
  if (!displayText) return null;

  // If no full text then just render the display text
  if (!fullText) return <>{displayText}</>;

  return (
    <DefinitionSDS
      ref={ref}
      anchorId={anchorId}
      isActive={isActive}
      onClick={handleClick}
      className={className}
    >
      {displayText}
    </DefinitionSDS>
  );
};

Definition.displayName = 'Definition';
