import { gql } from '@apollo/client';
import type { SitewideConfigurationCollection } from '@snapchat/mw-contentful-schema';

import { fragments as analyticsFragments } from '../../components/Analytics';
import { ctaFragment } from '../../components/CallToAction';
import { criteriaFragment } from '../../components/Experiment';
import {
  customFooterFragment,
  footerCookiesSettingsLinkFragment,
  footerGroupFragment,
  footerItemFragment,
  footerLocaleDropdownFragment,
} from '../../components/FooterWrapper/query';
import { navigatorFragment, navigatorItemFragment } from '../../components/Header/headerQuery';
import { navigationCtaFragment } from '../../components/NavigationCta';
import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';

// Custom nav item fragment since we need to alias title since its rich text :(
// S U P E R HACKY but it works for now because we need to alias title to richTitle
// but in the code we have to un-alias it back to title... so I'd rather only do it
// at one level as opposed to the nested levels.
export const navigatorItemRichTitleFragment = gql`
  fragment NavigatorItemsRichTitle on NavigatorItem {
    ...ContentfulSysId
    richTitle: title {
      json
    }
    url
    analytics {
      ...AnalyticsAll
    }
    hideCriteria {
      ...CriteriaAll
    }
  }
  ${analyticsFragments.all}
  ${contentfulSysIdFragment}
  ${criteriaFragment}
`;

const sitewideValuesFragment = gql`
  fragment SitewideValuesAll on SitewideValues {
    ...ContentfulSysId
    navigationBar {
      ...NavigatorLocal
    }
    customFooter {
      ...CustomFooterAll
    }
    featureFlags {
      ...ContentfulSysId
      featureFlags
    }
  }
  ${contentfulSysIdFragment}
`;

const sitewideConfigurationFragment = gql`
  fragment SitewideConfiguration on SitewideConfiguration {
    ...ContentfulSysId
    reference {
      ... on SitewideValues {
        ...SitewideValuesAll
      }
      ... on SitewideExperiment {
        ...ContentfulSysId
        default {
          ...SitewideValuesAll
        }
        seed
        variantsCollection(limit: 5) {
          items {
            ...ContentfulSysId
            trafficStartRange
            trafficEndRange
            analyticsId
            replacementsCollection(limit: 10) {
              items {
                ...ContentfulSysId
                replacementTarget {
                  ...ContentfulSysId
                }
                replacement {
                  ... on Navigator {
                    ...ContentfulSysId
                  }
                  # copy depth from headerQuery.ts
                  ... on NavigatorItem {
                    ...NavigatorItemsRichTitle
                    navigatorItemsCollection(limit: 15) {
                      items {
                        ...NavigatorItemFieldsOnly
                        navigatorItemsCollection(limit: 15) {
                          items {
                            # Note: this is 3x nested to support the 3x nested nav for forbusiness.snapchat.com
                            ...NavigatorItemFieldsOnly
                          }
                          total
                        }
                      }
                      total
                    }
                  }
                  ... on NavigationCallToAction {
                    ...NavigationCta
                  }
                  ... on CallToAction {
                    ...CallToActionAll
                  }
                  ... on FeatureFlags {
                    ...ContentfulSysId
                    featureFlags
                  }
                  ... on CustomFooter {
                    ...CustomFooterAll
                  }
                  ... on FooterCookiesSettingsLink {
                    ...FooterCookiesSettingsLinkAll
                  }
                  ... on FooterGroup {
                    ...FooterGroupAll
                  }
                  ... on FooterItem {
                    ...FooterItemAll
                  }
                  ... on FooterLocaleDropdown {
                    ...FooterLocaleDropdownAll
                  }
                }
              }
            }
          }
        }
        analyticsId
      }
    }
  }
  ${contentfulSysIdFragment}
  ${navigatorFragment}
  ${customFooterFragment}
  ${sitewideValuesFragment}
  ${navigationCtaFragment}
  ${navigatorItemRichTitleFragment}
  ${navigatorItemFragment}
  ${ctaFragment}
  ${footerGroupFragment}
  ${footerCookiesSettingsLinkFragment}
  ${footerLocaleDropdownFragment}
  ${footerItemFragment}
  ${customFooterFragment}
`;

export const sitewideConfigurationQuery = gql`
  query SitewideConfigurationQuery($preview: Boolean!, $locale: String!) {
    sitewideConfigurationCollection(preview: $preview, locale: $locale, limit: 1) {
      items {
        ...SitewideConfiguration
      }
    }
  }
  ${sitewideConfigurationFragment}
`;

export interface SitewideConfigurationData {
  sitewideConfigurationCollection: SitewideConfigurationCollection;
}
