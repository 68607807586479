import { css } from '@emotion/css';

import { mobileMediaQuery } from '../../constants';
import { Gray, White } from '../../constants/colors';

export const tableStylesCss = css`
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  text-indent: initial;
  border-spacing: 0px;
  width: 100%;
  background-color: ${White};
`;

// Needed due to causing expansion of parent width on mobile for larger tables.
// We want to cause an overflow so we can scroll larger tables on mobile,
// but it seems to be due to the setup under a block that prevents overflow from
// working with width=100%. Very hacky and needs a better implementation.
export const tableWrapperStylesCss = css`
  margin-top: 15px;

  &&& {
    overflow-x: auto;
    ${mobileMediaQuery} {
      max-width: 75vw;
      margin: 0;
    }
  }
`;

export const tableControlsCss = css`
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  /* search icon */
  .sds-icon-search {
    margin-right: 5px;
  }

  /* search bar */
  input {
    font-size: 16px;
    height: 40px;
    max-width: 200px;
    border: 1px solid transparent;
    padding-left: 10px;
  }
`;

export const viewAllButtonCss = css`
  margin-left: auto;
`;

export const tGroupStylesCss = css`
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
`;

export const tRowStylesCss = css`
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
`;

export const tCellHeadCss = css`
  background-color: ${Gray.V150};
  text-align: left;
  *[dir='rtl'] & {
    text-align: right;
  }
`;

export const tCellCss = css`
  border-bottom: 1px solid #e5ebed;
  color: #536171;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.75rem 1rem;
  vertical-align: top;
`;

export const rowWithSubRowCss = css`
  border: none;
`;

export const subRowCellCss = css`
  padding-top: 0;
`;

export const filterIconCss = css`
  margin-left: 5px;
`;
