interface RetrievedCountryRegion {
  country: string;
  region: string;
}

// TODO: Switch to 'https://web-platform.snap.com/cookies/user_location'
const cookiesApiUserLocationEndpoint = 'https://www.snapchat.com/cookies/api/user_location';

/**
 * Helper function that calls relevant API endpoint to retrieve the client device location. Used to
 * determine whether in GPC region and as a dimension for logging.
 *
 * @returns String, {Two Character Country Code (upper case)}-{region (lower case)} example: `US-ca`
 */
export const fetchUserLocation = async (): Promise<string> => {
  const data = await fetch(cookiesApiUserLocationEndpoint);

  if (data.status !== 200) {
    const { status, statusText } = data;
    throw new Error(`${status} : ${statusText}`);
  }
  const json: RetrievedCountryRegion = await data.json();
  const country = json.country;
  const region = json.region;
  return `${country}-${region}`;
};
