import { useAsyncData } from '@snapchat/async-data-browser';
import { useMemo, useRef } from 'react';

import type { SearchResultResponse } from '../components/Search';
import {
  searchLocaleParam,
  searchOffsetParam,
  searchPerPageParam,
  searchQueryParam,
} from '../components/Search';
import { Config } from '../config';
import { logger } from '../helpers/logging';

type SearchResultsData = {
  data: SearchResultResponse;
  isErrored: boolean;
  isLoading: boolean;
};

/**
 * Custom hook for fetching search results based on the provided parameters.
 *
 * @param {string} searchTerm - The term to search for
 * @param {string} locale - The locale for which search results are requested.
 * @param {number} page - The page number to use in the query
 * @param {number} pageSize - The number of search results per page
 * @returns An object containing search results data and loading state.
 */
export function useSearchResults(
  searchTerm: string,
  locale: string,
  page = 1,
  pageSize = 20
): SearchResultsData {
  const previousResults = useRef([]);
  const previousTotal = useRef(0);
  const emptyResults: SearchResultResponse = { results: [], total: 0 };
  let searchResults = emptyResults;

  const params = useMemo(() => {
    const params = new URLSearchParams();
    const searchOffsetValue = Math.max(0, (page - 1) * pageSize);

    params.append(searchQueryParam, searchTerm ?? '');
    params.append(searchLocaleParam, locale);
    params.append(searchOffsetParam, `${searchOffsetValue}`);
    params.append(searchPerPageParam, `${pageSize}`);

    return params;
  }, [searchTerm, locale, page, pageSize]);

  const host = Config.isClient ? '' : `http://localhost:${process.env.PORT ?? 3000}`;
  const url = `${host}/api/search?${params}`;

  const { data, isLoading, error } = useAsyncData({
    dataId: `/api/search?${params}`,
    dataAsync: async () => {
      // edge case: don't send to server if there is no search input
      if (!searchTerm) return emptyResults;

      const response = await fetch(url);

      if (!response.ok) {
        const error = await response.text();
        throw new Error(error);
      }

      const data = await response.json();
      return data;
    },
  });

  if (error) {
    logger.logError({
      component: 'useSearchResults',
      message: 'Error loading search results',
      error,
    });
  }

  if (data) {
    previousResults.current = data.results;
    previousTotal.current = data.total;
    searchResults = data as SearchResultResponse;
  }

  return {
    data: isLoading
      ? { results: previousResults.current, total: previousTotal.current }
      : searchResults,
    isLoading,
    isErrored: !!error,
  };
}
