import { css } from '@emotion/css';

export const containerCss = css`
  position: relative;
  display: flex;
  justify-content: center;
`;

export const innerContainerCss = css`
  position: sticky;
  top: 0;
  height: 100vh;
  width: 100%;
`;
