import type Cookies from 'js-cookie';

import type { CookieCategoryType, TrackingCookieType } from '../generated/contentful-types';

/** Settings for a categorized cookie (set by Privacy team in Contentful) */
export interface CategorizedCookie extends Cookies.CookieAttributes {
  name: string;
  regex?: RegExp;
}

/**
 * Helper function to parse Contentful entries for relevant attributes for a given cookie. Note that
 * we only want to set attributes that have values so that JS Cookie can merge these settings with
 * the default configuration.
 */
const convertToCategorizedCookie = (
  hostName: string,
  input: TrackingCookieType
): CategorizedCookie => {
  const { sys, name, pattern, subDomains, path, secure, sameSite } = input;

  const output: CategorizedCookie = {
    id: sys.id,
    name,
  };

  // regex pattern for the cookie (e.g. for the `_ga_{container}` cookie)
  if (pattern) output.regex = new RegExp(pattern);

  // Cookies may be set on the top level domain or the sub domain.
  // If the latter, privacy will list the subdomain in the `subDomains` array.
  // If a value matching the current domain exists, set the value to an empty string.
  // This triggers configures the cookie deletion on the current domain rather than the cookie domain
  if (subDomains?.includes(hostName)) output.domain = '';

  if (path) output.path = path;

  if (secure) output.secure = true;

  // @ts-ignore: Contentful types do not reflect validations, users are restricted to valid values
  if (sameSite) output.sameSite = sameSite;

  return output;
};

/**
 * Helper function that converts an input array of Contentful `CookieCategory` elements to a Map
 * data structure
 *
 * @param categories Array of `CookieCategory` elements from Contentful
 * @returns A map where the key is the cookie category and the value is a set of all registered
 *   tracking cookies for that category.
 */
export const createCookieMapping = (
  categories: readonly CookieCategoryType[]
): Map<string, Set<CategorizedCookie>> => {
  const cookieMapping = new Map<string, Set<CategorizedCookie>>();
  const hostName = (window?.location?.hostname ?? '').toLowerCase();

  for (const category of categories) {
    const cookieSet = new Set<CategorizedCookie>();
    const cookieName = category.categoryCookieName;

    for (const trackingCookie of category.cookiesCollection.items) {
      const categorizedCookie = convertToCategorizedCookie(hostName, trackingCookie);
      cookieSet.add(categorizedCookie);
    }
    cookieMapping.set(cookieName, cookieSet);
  }

  return cookieMapping;
};
