import { cx } from '@emotion/css';
import type React from 'react';
import { useEffect, useRef } from 'react';

import { useDisableScrollOutside } from '../../../hooks/useDisableBodyScroll';
import { ToggleState } from '../../../hooks/useToggleState';
import { MotifComponent } from '../../../motif/motifConstants';
import { dataSetToAttributes, getBackgroundClassName } from '../../../utils';
import { IconButton } from '../../IconButton';
import { IconButtonSize } from '../../IconButton/IconButton.types';
import { globalHeaderDesktopNavButtonCss } from '../GlobalHeader.styles';
import { testIds } from './GlobalNavScreen.constants';
import {
  globalNavDesktopCss,
  globalNavHighlightClassName,
  globalNavLeftNavClassName,
} from './GlobalNavScreen.styles';
import type { GlobalNavScreenDesktopProps } from './types';

export const GlobalNavScreenDesktop: React.FC<GlobalNavScreenDesktopProps> = ({
  className,
  onNavClose,
  highlight,
  globalNav,
  backgroundColor,
  screenState,
  dataset,
}) => {
  const leftNavRef = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (screenState === ToggleState.ON) {
      ref.current?.focus();
    }
  }, [screenState]);

  if (!dataset?.testid) {
    dataset = { ...dataset, testid: testIds.navTopLevel } as DOMStringMap;
  }

  // Only enable scroll inside the left nav.
  useDisableScrollOutside(leftNavRef.current);

  return (
    <section
      className={cx(
        MotifComponent.HEADER,
        globalNavDesktopCss,
        getBackgroundClassName(backgroundColor),
        className
      )}
      {...dataSetToAttributes(dataset)}
    >
      <div ref={leftNavRef} className={globalNavLeftNavClassName}>
        <header className="sticky">
          <IconButton
            size={IconButtonSize.LARGE}
            onClick={onNavClose}
            className={globalHeaderDesktopNavButtonCss}
            iconName="close"
            ref={ref}
            data-testid={testIds.closeButton}
          />
          {/* TODO: Put search field here */}
        </header>
        <nav className="menu" data-testid={testIds.navItems}>
          {globalNav}
        </nav>
      </div>
      <aside className={globalNavHighlightClassName} data-testid={testIds.navHighlights}>
        {highlight}
      </aside>
    </section>
  );
};

GlobalNavScreenDesktop.displayName = 'GlobalNavScreenDesktop';
