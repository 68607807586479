import { Plain, Yellow } from '../../constants';
import { m } from '../../motif/m';
import type { MotifComponent } from '../../motif/motifConstants';
import type { MotifComponentProps } from '../../motif/motifTypes';

export const mosaicLightBgVars: MotifComponentProps<MotifComponent.MOSAIC> = {
  '--mosaic-border-radius': m('--border-radius-m'),
  '--mosaic-grid-gap': m('--spacing-l'),
  '--mosaic-title-color': Plain.WHITE,
  '--mosaic-highlight-color': Yellow.V100,
  '--mosaic-duration-color': Plain.WHITE,
};

export const mosaicDarkBgVars: MotifComponentProps<MotifComponent.MOSAIC> = {
  '--mosaic-border-radius': m('--border-radius-m'),
  '--mosaic-grid-gap': m('--spacing-l'),
  '--mosaic-title-color': Plain.WHITE,
  '--mosaic-highlight-color': Yellow.V100,
  '--mosaic-duration-color': Plain.WHITE,
};
