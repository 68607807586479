import type { BaseMarketingWebEvent } from '@snapchat/blizzard-schema';
import type { LoggedEvent } from '@snapchat/logging';
import { LoggingEventType } from '@snapchat/logging';
import type { BlizzardBaseEvent } from '@snapchat/logging-browser';
import kebabCase from 'lodash-es/kebabCase';

import { SubscribedEventType } from '../../eventListenerTypes';
import type { LoggingContext, LoggingCustomEvents } from '../../loggingTypes';

/**
 * Event Formatting function for primary MarketingWeb Blizzard Table
 *
 * Table: `sc-analytics.prod_analytics_marketing_web`
 *
 * This is the primary MarketingWeb Blizzard table. Events logged here may trigger events in
 * secondary tables - refer to `formatSnapPixelEvent()` and `formatWebClientIdEvent()` functions for
 * details.
 */
const formatMarketingWebEvent = (
  event: LoggedEvent<LoggingCustomEvents>,
  context: Partial<LoggingContext>
): BlizzardBaseEvent | undefined => {
  const url = context.url ?? new URL(window.location.href);
  const data: BaseMarketingWebEvent = {
    host: url.host,
    path: url.pathname,
    query: JSON.stringify(Object.fromEntries(url.searchParams.entries())),
    // TODO: Blizzard appears to overwrite this with whatever it can find in the sc-language cookie so we probably don't
    // need to set it here. Remove once we're sure page_locale is being set and sent correctly.
    // https://jira.sc-corp.net/browse/WEBP-11203
    locale: context.locale,
    page_locale: context.locale,
  };

  // if experiment data exists, we add all three fields
  if (context.variantId) {
    data.experiment_category = 'Page'; // Always page.
    data.experiment_group = context.variantId;
    data.experiment_id = context.experimentId ?? 'None';
  }

  switch (event.type) {
    case LoggingEventType.CUSTOM: {
      switch (event.subscribedEventType) {
        case SubscribedEventType.PAGE_LOAD:

        // falls through
        case SubscribedEventType.FIRST_PAGE_LOAD: {
          return {
            event_name: 'MARKETING_WEB_PAGE_VIEW',
            ...data,
            is_landing_page: event.subscribedEventType === SubscribedEventType.FIRST_PAGE_LOAD,
          };
        }

        case SubscribedEventType.EXPERIMENT_IMPRESSION: {
          // We add this case so typescript is happy, but theoretically this should never fire.
          // If it DOES somehow fire, it will fire the exact same log as SubscribedEventType.INTERNAL and
          // how the fields are set up in useExperiment
          return {
            event_name: 'MARKETING_WEB_INTERNAL_EVENT',
            ...data,
            type: 'useExperiment',
            category: 'Experiment',
            label: `${event.experimentId?.replace(/[ _]/, '-')}:${kebabCase(event.variantId)}`,
          };
        }
      }
      break;
    }

    case LoggingEventType.USER_ACTION: {
      return {
        event_name: 'MARKETING_WEB_USER_INTERACTION',
        ...data,
        type: event.action,
        category: event.component,
        label: event.label,
      };
    }

    case LoggingEventType.INFO: {
      return {
        event_name: 'MARKETING_WEB_INTERNAL_EVENT',
        ...data,
        type: event.action,
        category: event.component,
        label: event.label,
      };
    }

    case LoggingEventType.VALUE: {
      return {
        event_name: 'MARKETING_WEB_VALUE_EVENT',
        ...data,
        metric: event.value,
        type: event.variable,
        category: event.component,
        label: event.label,
      };
    }

    case LoggingEventType.TIMING: {
      return {
        event_name: 'MARKETING_WEB_TIMING_EVENT',
        ...data,
        duration: event.valueMs,
        type: event.variable,
        category: event.component,
        label: event.label,
      };
    }
  }

  return undefined;
};

/**
 * Formatting for Snap Pixel Blizzard events (table:
 * `sc-analytics.prod_analytics_marketing_web_pixel_id`)
 *
 * Returns undefined if it is not relevant for the triggering event. Table is used to correlate
 * entries in the `sc-analytics.prod_analytics_marketing_web` to Snap pixel identifiers. This is
 * separated to its own table as snap pixel data requires a shorter data retention policy than the
 * primary table.
 *
 * @param triggeringEvent Event generated by `formatMarketingWebEvent` function
 * @param context Logging context, used to access pixelId value
 */
const formatSnapPixelEvent = (
  triggeringEvent: BlizzardBaseEvent,
  context: Partial<LoggingContext>
): BlizzardBaseEvent | undefined => {
  // Do not create event if pixelId is not defined
  if (!context.pixelId) return;

  return {
    event_name: 'MARKETING_WEB_SNAP_PIXEL_EVENT',
    pixel_id: context.pixelId,
    triggering_event_name: triggeringEvent.event_name,
  };
};

/**
 * Formatting for sc-wcid Blizzard events (table: `sc-analytics:prod_analytics_marketing_web_wcid`)
 *
 * Returns undefined if it is not relevant for the triggering event. Table is used to correlate
 * entries in the `sc-analytics.prod_analytics_marketing_web` to sc-wcid identifiers. This is
 * separated to its own table as the `sc-wcid` cookie values require a shorter data retention policy
 * than the primary table.
 *
 * @param triggeringEvent Event generated by `formatMarketingWebEvent` function
 * @param context Logging context, used to access webClientId value
 */
const formatWebClientIdEvent = (
  triggeringEvent: BlizzardBaseEvent,
  context: Partial<LoggingContext>
): BlizzardBaseEvent | undefined => {
  // Do not create event if webClientId is not defined
  if (!context.webClientId) return;

  return {
    event_name: 'MARKETING_WEB_CLIENT_ID_EVENT',
    web_client_id: context.webClientId,
    triggering_event_name: triggeringEvent.event_name,
  };
};

/**
 * Wrapper function around individual MarketingWebEvent formatting functions.
 *
 * Returns an array containing formatted events.
 */
export const formatMarketingWebEvents = (
  event: LoggedEvent<LoggingCustomEvents>,
  context: Partial<LoggingContext>
): BlizzardBaseEvent[] => {
  const output: BlizzardBaseEvent[] = [];

  const primaryEvent = formatMarketingWebEvent(event, context);

  // If not a valid input event for logging to MWP tables, return empty array
  if (!primaryEvent) return output;

  output.push(primaryEvent);

  // Generate related event if appropriate and add to output array
  const pixelEvent = formatSnapPixelEvent(primaryEvent, context);
  pixelEvent && output.push(pixelEvent);

  // Generate related event if appropriate and add to output array
  const webClientIdEvent = formatWebClientIdEvent(primaryEvent, context);
  webClientIdEvent && output.push(webClientIdEvent);

  return output;
};
