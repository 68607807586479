import { ScreenSize } from './screenSizes';

const mediaQueryCss = (width: number) => `@media (min-width: ${width}px)`;

/**
 * Standard breakpoints for screen resolutions.
 *
 * Use like css` font-size: 50%; ${mediaQuery.over768_desktop_small} { font-size: 75%; }
 * ${mediaQuery.over1024_desktop_medium} { font-size: 100%; }`.
 *
 * Note that if you are using multiple they have to be specified in ASC order so that the last one
 * that's true takes precedence.
 */
export const mediaQuery = {
  over320_mobile_small: mediaQueryCss(ScreenSize.width_320_mobile_small),
  over375_mobile_medium: mediaQueryCss(ScreenSize.width_375_mobile_medium),
  over425_mobile_large: mediaQueryCss(ScreenSize.width_425_mobile_large),
  over768_desktop_small: mediaQueryCss(ScreenSize.width_768_desktop_small), // Matches mobile/desktop breakpoint at SDS-M
  over1024_desktop_medium: mediaQueryCss(ScreenSize.width_1024_desktop_medium),
  over1440_desktop_large: mediaQueryCss(ScreenSize.width_1440_desktop_large),
  over2560_desktop_larger: mediaQueryCss(ScreenSize.width_2560_desktop_larger),
};
