/* eslint-disable max-len */
import type { FC } from 'react';

import type { GenericSvgIconProps } from '../CustomIcon';

export const ChartIcon: FC<GenericSvgIconProps> = props => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.57768 6.37882L12.6856 2.89522L11.9394 2.22949L9.35981 5.12089L6.25449 3.42708L3.11646 7.17908L3.88353 7.82063L6.4955 4.69763L9.57768 6.37882ZM11.4375 5.15611H13.1875V13.9999H11.4375V5.15611ZM5.6875 7.01548H7.4375V13.9999H5.6875V7.01548ZM4.5625 9.31236H2.8125V13.9999H4.5625V9.31236ZM8.5625 8.09361H10.3125V13.9999H8.5625V8.09361Z"
      />
    </svg>
  );
};
