import type { MotifVar } from './motifTypes';

/**
 * Reference to a CSS variable exported by SDS-M Motif.
 *
 * Is a type-safe to references css variables.
 */
export function m(variable: MotifVar): string {
  return `var(${variable})`;
}

/**
 * This is a TYPECHECK. DO NOT DELETE. If the type for the 'm' function breaks this will throw a
 * type error. In order to fix this make sure that the motifVariables are comprised entirely of
 * readonly arrays (usually by specifing "as const")
 */
// @ts-expect-error The 'm' function accepted a random string. This is a bug.
const _neverResolved = m('not-a-variable');
