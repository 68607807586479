import { gql } from '@apollo/client';

import { contentfulSysIdFragment } from '../../../utils/contentful/contentfulQuery';
import { fragments as barChartFragments } from '../BarChart/query';
import { fragments as geoMapFragments } from '../GeoMap/query';
import { fragments as lineChartFragments } from '../LineChart/query';
import { chartDataFragment, seriesFragment } from '../query';
import { fragments as tableFragments } from '../Table/query';

export const fragments = {
  all: gql`
    fragment VisualizationSelectorAll on VisualizationSelector {
      ...ContentfulSysId
      chartTitle
      visualizationsCollection {
        items {
          ... on BarChart {
            ...BarChartWithTitle
          }
          ... on LineChart {
            ...LineChartWithTitle
          }
          ... on GeoVisualization {
            ...GeoMapWithTitle
          }
          ... on TableVisualization {
            ...TableVisualizationWithTitle
          }
        }
      }
      seriesName {
        ...SeriesAll
      }
      chartDataCollection {
        items {
          ...ChartDataAll
        }
      }
      selectableFields
    }
    ${barChartFragments.withTitle}
    ${lineChartFragments.withTitle}
    ${geoMapFragments.withTitle}
    ${tableFragments.withTitle}
    ${seriesFragment}
    ${chartDataFragment}
    ${contentfulSysIdFragment}
  `,
};

export const queries = {
  shallow: gql`
    query visualizationSelectorEntryQuery($preview: Boolean!, $locale: String!, $id: String!) {
      visualizationSelector(preview: $preview, locale: $locale, id: $id) {
        ...ContentfulSysId
      }
    }
    ${contentfulSysIdFragment}
  `,
  all: gql`
    query visualizationSelectorEntryQuery($preview: Boolean!, $locale: String!, $id: String!) {
      visualizationSelector(preview: $preview, locale: $locale, id: $id) {
        ...VisualizationSelectorAll
      }
    }
    ${fragments.all}
  `,
};
