import { cx } from '@emotion/css';
import type { FC, ReactNode } from 'react';

import type { BaseComponentProps } from '../../../types';
import type { OnActivateHandler } from '../../../types/activationEvents';
import { buttonActiveClassName, buttonCss } from './styles';

export interface Props extends BaseComponentProps {
  children?: ReactNode;
  active?: boolean;
  onClick?: OnActivateHandler;
}

export const ChartToggleButton: FC<Props> = ({ children, active, onClick }) => {
  const className = cx(buttonCss, active && buttonActiveClassName);

  return (
    <button role="tab" onClick={onClick} className={className}>
      {children}
    </button>
  );
};
