import { DeploymentType } from './deploymentTypes';

/**
 * All of the regions we support for deployment
 *
 * IMPORTANT: If you add more regions here, we need to create new VPCs / Memorystore for them.
 */
export const supportedRegions = [
  'asia-northeast1',
  'asia-south2',
  'asia-southeast1',
  'europe-north1',
  'europe-west1',
  'europe-west2',
  'europe-west6',
  'me-west1',
  'me-central2',
  'northamerica-northeast1',
  'southamerica-east1',
  'us-central1',
  'us-east1',
  'us-east4',
  'us-south1',
  'us-west2',
] as const;

export type SupportedRegion = (typeof supportedRegions)[number];

export function isSupportedRegion(input: string): input is SupportedRegion {
  return supportedRegions.includes(input as unknown as SupportedRegion);
}

/**
 * Returns the appengine region for marketing-web-platform project for PRODUCTION and
 * entapps-web-dev otherwise.
 *
 * There's only 1 region because the AppEngine is limited to 1 region per project.
 */
export function getAppEngineRegion(deploymentType: DeploymentType): SupportedRegion {
  return deploymentType === DeploymentType.PRODUCTION ? 'us-central1' : 'us-west2';
}
