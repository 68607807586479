import { cx } from '@emotion/css';
import type { AnchorHTMLAttributes, FC, ReactElement } from 'react';

import {
  getMotifSchemeForLegacyBackgroundColor,
  MotifComponent,
  MotifScheme,
  p1Css,
  useMotifStyles,
} from '../../motif';
import type { OnActivateHandler } from '../../types/activationEvents';
import { Button } from '../Button/Button';
import {
  loadMoreResultsButtonCss,
  noResultsFoundButtonContainerCss,
  noResultsFoundContainerCss,
  noResultsFoundSubtitleCss,
  noResultsFoundTitleCss,
  searchItemCss,
  searchItemDescriptionCss,
  searchItemPageTitleCss,
  searchItemSubtitleCss,
  searchItemTitleCss,
  searchSubtitleCss,
  searchTitleWrapperCss,
  searchWrapperCss,
} from './Search.styles';

export interface SearchItemProps {
  title?: string | ReactElement;
  subtitle?: string | ReactElement;
  description?: string | ReactElement;
  LinkElement: FC<AnchorHTMLAttributes<unknown>>;
  pageTitle: string;
}

export const SearchItem: FC<SearchItemProps> = ({
  title,
  subtitle,
  description,
  LinkElement,
  pageTitle,
}) => (
  <LinkElement className={searchItemCss}>
    <div className={searchItemPageTitleCss}>{pageTitle}</div>
    {title && title !== pageTitle && <div className={searchItemTitleCss}>{title}</div>}
    {subtitle && <div className={searchItemSubtitleCss}>{subtitle}</div>}
    {description && <div className={searchItemDescriptionCss}>{description}</div>}
  </LinkElement>
);

SearchItem.displayName = 'SearchItem';

export interface NoResultsFoundProps {
  title: string | ReactElement;
  subtitle?: string | ReactElement;
  buttonText: string | ReactElement;
  buttonAction: OnActivateHandler;
}

export const NoResultsFound: FC<NoResultsFoundProps> = ({
  title,
  subtitle,
  buttonText,
  buttonAction,
}) => (
  <div className={noResultsFoundContainerCss}>
    <h4 className={noResultsFoundTitleCss}>{title}</h4>
    {subtitle && <div className={cx(p1Css, noResultsFoundSubtitleCss)}>{subtitle}</div>}
    <div className={noResultsFoundButtonContainerCss}>
      <Button
        type="Primary"
        className={getMotifSchemeForLegacyBackgroundColor(MotifScheme.DEFAULT)}
        onClick={buttonAction}
      >
        {buttonText}
      </Button>
    </div>
  </div>
);

NoResultsFound.displayName = 'NoResultsFound';

type SearchChildren =
  | ReactElement<typeof SearchItem>
  | ReactElement<typeof SearchItem>[]
  | ReactElement<typeof NoResultsFound>;

interface SearchProps {
  title?: string | ReactElement;
  subtitle?: string | ReactElement;
  children: SearchChildren;
  loadMoreResultsButton?: {
    title: string;
    buttonAction: OnActivateHandler;
  };
}

export const Search: FC<SearchProps> = ({ title, subtitle, children, loadMoreResultsButton }) => {
  // TODO: component should be search results
  useMotifStyles(MotifComponent.SEARCH);

  return (
    <div className={cx(MotifComponent.SEARCH, searchWrapperCss)}>
      {(title || subtitle) && (
        <div className={searchTitleWrapperCss}>
          {title && title}
          {subtitle && <div className={searchSubtitleCss}>{subtitle}</div>}
        </div>
      )}
      {children}
      {loadMoreResultsButton && (
        <div className={loadMoreResultsButtonCss}>
          <Button
            type="Primary"
            className={getMotifSchemeForLegacyBackgroundColor(MotifScheme.DEFAULT)}
            onClick={loadMoreResultsButton.buttonAction}
          >
            {loadMoreResultsButton.title}
          </Button>
        </div>
      )}
    </div>
  );
};

Search.displayName = 'Search';
