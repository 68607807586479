import kebabCase from 'lodash-es/kebabCase';

/**
 * Font families supported by SDS.
 *
 * The values in this enum must match the font family name exactly as it's defined in
 * /snap-design-system/fonts/
 */
export enum FontFamily {
  AVENIR_NEXT = 'Avenir Next',
  BUENOS_AIRES = 'Buenos Aires',
  GRAPHIK = 'Graphik',
}

export const fontLinkIdFn = (fontFamily: FontFamily): string =>
  `sdsm-font-${kebabCase(fontFamily)}`;

// Note: the location the files are stored in is
// https://console.cloud.google.com/storage/browser/snap-design-system/fonts;tab=objects?project=snap-design-system
// But it is hosted through a proxy with CDN enabled on the marketing web load balancer
export const fontDefinitionFiles: Record<FontFamily, string> = {
  [FontFamily.GRAPHIK]: 'https://web-platform.snap.com/fonts/font.graphik.css',
  [FontFamily.AVENIR_NEXT]: 'https://web-platform.snap.com/fonts/font.avenir-next.css',
  [FontFamily.BUENOS_AIRES]: 'https://web-platform.snap.com/fonts/font.buenos-aires.css',
};
