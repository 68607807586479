import type { FC } from 'react';
import { useContext } from 'react';

import { DesignSystemContext } from '../../context/DesignSystemContext';
import type { LocaleOption } from '../types';

export interface DesignAgnosticLocaleDropdownProps {
  currentLocale: LocaleOption;
  supportedLocales: Record<string, LocaleOption>;
  onLocaleChange?: (locale: string) => void;
  /**
   * If specified, renders the dropdown's popup in the specified element rather than the default
   * placement.
   */
  containerProvider?: (node: HTMLElement) => HTMLElement;
}

export const DesignAgnosticLocaleDropdown: FC<DesignAgnosticLocaleDropdownProps> = props => {
  const { localeDropdownComponent } = useContext(DesignSystemContext);
  return localeDropdownComponent(props);
};
