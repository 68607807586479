import { css } from '@emotion/css';

import {
  maxWidth,
  nonTwoColumnCollapseMediaQuery,
  twoColumnCollapseMediaQuery,
} from '../../../constants';
import { m } from '../../../motif/m';

export const wrapperCss = css`
  border-top-color: ${m('--footer-border-color')};
  border-top-style: solid;
  border-top-width: 1px;
  color: ${m('--footer-fg-color')};
  background-color: ${m('--footer-bg-color')};
  display: flex;
  justify-content: center;
`;

export const footerColumnsDesktopCss = css`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  gap: ${m('--spacing-m')};
  max-width: ${maxWidth}px;
  padding-block: ${m('--spacing-xl')};
  padding-inline: ${m('--spacing-l')};
  width: 100%;

  ${twoColumnCollapseMediaQuery} {
    padding-inline: ${m('--spacing-xxxxl')};
  }

  ${nonTwoColumnCollapseMediaQuery} {
    padding-inline: ${m('--spacing-xxxxl')};
  }
`;

export const footerColumnsCenteredCss = css`
  justify-content: space-between;
`;

export const footerColumnMobileItemsCss = css`
  box-sizing: border-box;
`;

export const footerColumnMobileCss = css`
  align-items: center;
  border-top: 1px solid ${m('--footer-divider-border-color')};
  color: ${m('--footer-fg-color')};
  background-color: ${m('--footer-bg-color')};
  display: block;
  padding-block: ${m('--spacing-xl')} ${m('--spacing-xl')};
  padding-inline: ${m('--spacing-xl')};
`;

export const oneColumnWidthCss = css`
  > div {
    width: 100%;
  }
`;

// formula: (100% - {min gap width} * {number of columns per row - 1}) / {number of columns per row}
export const twoColumnWidthCss = css`
  > div {
    width: calc((100% - ${m('--spacing-m')}) / 2);
  }
`;
export const threeColumnWidthCss = css`
  > div {
    width: calc((100% - (${m('--spacing-m')} * 2)) / 3);
  }
`;
export const fourColumnWidthCss = css`
  > div {
    width: calc((100% - (${m('--spacing-m')} * 3)) / 4);
  }
`;
export const fiveOrMoreColumnWidthCss = css`
  > div {
    width: calc((100% - (${m('--spacing-m')} * 4)) / 5);
  }

  ${nonTwoColumnCollapseMediaQuery} {
    > div {
      width: calc((100% - (${m('--spacing-m')} * 5)) / 6);
    }
  }
`;
