import type { BackgroundColor } from '../../constants';
import type { BaseComponentProps } from '../../types';

export interface SubNavigationProps extends BaseComponentProps {
  backgroundColor: BackgroundColor;
  className?: string;
  horizontalScrollRef?: React.RefObject<HTMLDivElement>;
}

export interface SubNavigationItemProps extends BaseComponentProps {
  href: string;
  isActive: boolean;
  onLinkClick?: (event: React.MouseEvent) => void;
  className?: string;
}
