import { cx } from '@emotion/css';
import type { FC, MouseEventHandler, ReactEventHandler } from 'react';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import type { BaseComponentProps, ImageSources } from '../../../types';
import type { OnNavigateHandler } from '../../../types/activationEvents';
import { Icon } from '../../Icon';
import { Media } from '../../Media';
import { PrimitivesContext } from '../../Primitives';
import { isVideoPlaying } from '../../Video';
import {
  arrowCss,
  bodyCss,
  bodyOnlyClampCss,
  cardCss,
  cardLandscapeWidthCss,
  cardPortraitWidthCss,
  image11Css,
  image32Css,
  image169Css,
  imageCss,
  imageLoadingCss,
  infoElementCss,
  linkCss,
  mediaContainerCss,
  singleViewLandscapeWidthCss,
  subtitleCss,
  subtitleOnlyClampCss,
  textCss,
  textOverlapCss,
  textPlayerControlsCss,
  textWrapperCss,
  textWrapperOverlapCss,
  titleCss,
  titleOnlyClampCss,
  videoCss,
  videoPlayerControlsCss,
} from './CarouselCardItem.styles';
import type { CarouselV3AspectRatios } from './types';

export interface CarouselCardItemProps extends BaseComponentProps {
  aspectRatio?: CarouselV3AspectRatios;
  title?: string;
  subtitle?: string;
  body?: string;
  // click settings
  url?: string;
  openNewTab?: boolean;
  onClick?: OnNavigateHandler;
  // image settings
  imgSrcs?: ImageSources;
  imgAltText?: string;
  // video setings
  videoSource?: string;
  mobileVideoSource?: string;
  posterSource?: string;
  captionsSource?: string;
  onTimeUpdate?: ReactEventHandler<HTMLVideoElement>;
  onPlay?: ReactEventHandler<HTMLVideoElement>;
  // carousel controls
  shouldLoad?: boolean;
  isVisible?: boolean;
  isSingleView?: boolean;
  autoplay?: boolean;
  showVideoControls?: boolean;
}

export const CarouselCardItem: FC<CarouselCardItemProps> = ({
  aspectRatio,
  autoplay,
  body,
  captionsSource,
  imgAltText,
  imgSrcs,
  shouldLoad,
  onPlay,
  onTimeUpdate,
  openNewTab,
  posterSource,
  isVisible,
  showVideoControls,
  subtitle,
  title,
  url,
  videoSource,
  mobileVideoSource,
  onClick,
  isSingleView = false,
  className,
}) => {
  const [media, setMedia] = useState<HTMLElement>();
  const mediaCallback = useCallback((elt: HTMLElement) => setMedia(elt), []);
  const didLoad = useRef(shouldLoad);
  const { Anchor } = useContext(PrimitivesContext);
  const isTextOverlap = !videoSource || !showVideoControls;

  const onClickWrapped = useCallback<MouseEventHandler>(
    event => {
      url && onClick && onClick(url, event);
    },
    [url, onClick]
  );

  // isVisible indicates if any part of the item is on screen
  // It allows us to lazy load images and pause videos when they leave the screen
  useEffect(() => {
    if (shouldLoad) {
      didLoad.current = true;
    }
  }, [shouldLoad]);

  useEffect(() => {
    if (media && media.tagName === 'VIDEO') {
      const video = media as HTMLVideoElement;

      if (!isVisible) {
        if (isVideoPlaying(video)) {
          video.pause();
        }
        video.tabIndex = -1;

        if (!showVideoControls) {
          video.currentTime = 0;
        }
      } else {
        if (!showVideoControls) {
          try {
            void video.play();
          } catch (_error) {
            // the video may be outside the document at this point
          }
        }
        video.tabIndex = 0;
      }
    }
  }, [isVisible, showVideoControls, media]);

  const hasBodyContent = !!(title || subtitle || body);

  const mediaElement = useMemo(() => {
    const mediaCss = cx(
      videoSource ? videoCss : imageCss,
      showVideoControls && hasBodyContent ? videoPlayerControlsCss : null
    );

    return (
      <Media
        showVideoControls={showVideoControls}
        videoSource={videoSource}
        mobileVideoSource={mobileVideoSource}
        posterSource={posterSource}
        onPlay={onPlay}
        captionsSource={captionsSource}
        isBackgroundVideo={autoplay}
        onTimeUpdate={onTimeUpdate}
        altText={imgAltText}
        imgSrcs={imgSrcs}
        className={mediaCss}
        ref={mediaCallback}
        isDraggable={false}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    videoSource,
    showVideoControls,
    posterSource,
    onPlay,
    captionsSource,
    autoplay,
    onTimeUpdate,
    mediaCallback,
  ]);

  const mediaContent = (
    <div
      className={cx(
        mediaContainerCss,
        { [image32Css]: aspectRatio === '3:2' },
        { [image11Css]: aspectRatio === '1:1' },
        { [image169Css]: aspectRatio === '16:9' },
        { [imageLoadingCss]: !isVisible && !didLoad.current }
      )}
    >
      {(isVisible || didLoad.current) && mediaElement}
    </div>
  );

  const bodyContent = (
    <div className={cx(textWrapperCss, { [textWrapperOverlapCss]: isTextOverlap })}>
      <div
        className={cx(textCss, {
          [textOverlapCss]: isTextOverlap,
          [textPlayerControlsCss]: showVideoControls && hasBodyContent,
        })}
      >
        {!!url && <Icon className={arrowCss} name="arrow-right" />}
        {title && (
          <h5
            className={cx(infoElementCss, titleCss, { [titleOnlyClampCss]: !subtitle && !body })}
            title={title}
          >
            {title}
          </h5>
        )}
        {subtitle && (
          <h6
            className={cx(infoElementCss, subtitleCss, { [subtitleOnlyClampCss]: !title && !body })}
            title={subtitle}
          >
            {subtitle}
          </h6>
        )}
        {body && (
          <p
            className={cx(infoElementCss, bodyCss, { [bodyOnlyClampCss]: !title && !subtitle })}
            title={body}
          >
            {body}
          </p>
        )}
      </div>
    </div>
  );

  const commonCss = {
    [cardLandscapeWidthCss]: aspectRatio !== '9:16' && !isSingleView,
    [singleViewLandscapeWidthCss]: aspectRatio !== '9:16' && isSingleView,
    [cardPortraitWidthCss]: aspectRatio === '9:16',
  };

  if (url) {
    return (
      <Anchor
        href={url}
        {...(openNewTab ? { target: '_blank', rel: 'noopener' } : {})}
        className={cx(cardCss, linkCss, commonCss, className)}
        tabIndex={isVisible ? undefined : -1}
        onClick={onClickWrapped}
        draggable="false"
        data-test-id="sdsm-carousel-card"
      >
        {mediaContent}
        {hasBodyContent && bodyContent}
      </Anchor>
    );
  }

  return (
    <div className={cx(cardCss, commonCss, className)} data-test-id="sdsm-carousel-card">
      {mediaContent}
      {hasBodyContent && bodyContent}
    </div>
  );
};

CarouselCardItem.displayName = 'CarouselCardItem';
