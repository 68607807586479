import { css } from '@emotion/css';

import {
  mobileMediaQuery,
  noHorizontalScrollbarsCss,
  nonMobileMediaQuery,
  ZIndex,
} from '../../constants';
import { m, MotifComponent } from '../../motif';

export const tabsPanelsCss = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  & > div {
    margin: auto;
  }

  ${mobileMediaQuery} {
    margin-top: ${m('--spacing-m')};
  }
`;

export const tabsButtonsCss = css`
  display: flex;
  justify-content: center;
  padding-top: ${m('--spacing-xxxs')};
  padding-bottom: ${m('--spacing-xxxl')};
  flex-wrap: wrap;
  & > .${MotifComponent.BUTTON} {
    margin-bottom: ${m('--spacing-m')};
  }
  & > .${MotifComponent.BUTTON}:not(:last-child) {
    margin-right: ${m('--spacing-m')};
  }
  ${mobileMediaQuery} {
    justify-content: start;
    margin-right: -${m('--spacing-xl')};
    margin-left: -${m('--spacing-xl')};
    overflow-x: auto;
    flex-wrap: nowrap;
    ${noHorizontalScrollbarsCss}
  }
`;

export const arrowButtonContainerCss = css`
  display: flex;
  z-index: ${ZIndex.HERO_CURTAIN};
  background-color: ${m('--background-color')};
  min-width: 80px;
  justify-content: center;
  align-items: center;
  padding: 0 0 0 0;
  margin: 0 0 0 0;
  flex-direction: row;
  [dir='rtl'] & {
    flex-direction: row-reverse;
  }
  ${mobileMediaQuery} {
    display: none;
  }
`;

export const arrowCss = css`
  cursor: pointer;
  z-index: ${ZIndex.CAROUSEL_ARROW};
  background-color: ${m('--background-color')};
  border: none;

  :disabled {
    opacity: 50%;
  }
  ${mobileMediaQuery} {
    display: none;
  }
`;

export const leftGradientCss = css`
  display: block;
  width: 50px;
  height: ${m('--spacing-xxxl')};
  z-index: ${ZIndex.CAROUSEL_SIDE_GRADIENT};
  position: absolute;
  content: '';
  background: linear-gradient(to left, transparent 10%, ${m('--background-color')} 100%);
  left: ${m('--spacing-xxxl')};
  [dir='rtl'] & {
    left: calc(${m('--spacing-xxxxl')} * 2);
  }
  ${mobileMediaQuery} {
    left: ${m('--spacing-xs')};
    [dir='rtl'] & {
      left: ${m('--spacing-xs')};
    }
  }
`;
export const rightGradientCss = css`
  display: block;
  width: 50px;
  height: ${m('--spacing-xxxl')};
  z-index: ${ZIndex.CAROUSEL_SIDE_GRADIENT};
  position: absolute;
  content: '';
  background: linear-gradient(to right, transparent 10%, ${m('--background-color')} 100%);
  right: calc(${m('--spacing-xxxxl')} * 2);
  [dir='rtl'] & {
    right: ${m('--spacing-xxxl')};
  }
  ${mobileMediaQuery} {
    right: ${m('--spacing-xs')};
    [dir='rtl'] & {
      right: ${m('--spacing-xs')};
    }
  }
`;

export const tabsContainerCss = css`
  display: flex;
  max-width: 100%;
  margin: 0 ${m('--spacing-xxxl')} 0 ${m('--spacing-xxxl')};
  padding-top: ${m('--spacing-xl')};
  border-bottom: 1px ${m('--tabs-underline-color')} solid;
  ${mobileMediaQuery} {
    padding: 0;
    margin: 0 ${m('--spacing-xs')} 0 ${m('--spacing-xs')};

    *:last-child {
      [dir='rtl'] & {
        margin-left: ${m('--spacing-m')};
        margin-right: 0;
      }

      margin-right: ${m('--spacing-m')};
    }
  }
`;

export const tabItemContainterCss = css`
  align-items: center;
  display: flex;
  gap: ${m('--spacing-xl')};
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  z-index: ${ZIndex.BUTTON};
  width: 100%;
  scroll-behavior: smooth;
  position: relative;

  ${mobileMediaQuery} {
    justify-content: start;
    padding-inline-start: 0px;
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const tabItemCenterContainterCss = css`
  justify-content: center;
`;

export const tabItemStartContainterCss = css`
  justify-content: flex-start;
`;

const itemLinkUnderlineCss = css`
  &::after {
    bottom: 0px;
    content: '';
    position: absolute;
    transition: all 0.15s ease;
    visibility: hidden;
    width: 100%;
    text-align: left;
    margin-left: 0;
    height: 0px;
    border-top: 5px solid;
    border-radius: ${m('--border-radius-s')};
    margin-bottom: -5px;
    color: ${m('--tabs-item-color')};
  }

  &.active::after {
    visibility: visible;
    color: ${m('--tabs-item-active-color')};
  }

  &:hover::after {
    transform: translateY(-4px);
    visibility: visible;
    color: ${m('--tabs-item-hover-color')};
  }
`;

const itemLinkUnderlineActiveCss = css`
  &::after {
    transform: translateY(-4px);
    visibility: visible;
    color: ${m('--tabs-item-active-color')};
  }
  &:hover::after {
    color: ${m('--tabs-item-hover-color')};
  }
`;

export const itemLinkCss = css`
  align-items: center;
  cursor: pointer;
  color: ${m('--tabs-item-color')};
  display: flex;

  :hover {
    color: ${m('--tabs-item-hover-color')};
  }

  position: relative;
  text-decoration: none;
  justify-content: center;
  white-space: nowrap;
  user-select: none;

  ${mobileMediaQuery} {
    font-size: ${m('--action-mobile-font-size')};
    font-weight: ${m('--action-mobile-font-weight')};
  }

  ${nonMobileMediaQuery} {
    font-size: ${m('--action-desktop-font-size')};
    font-weight: ${m('--action-desktop-font-weight')};
  }

  line-height: ${m('--spacing-xxxxl')};

  ${itemLinkUnderlineCss}
`;

export const itemLinkActiveCss = css`
  color: ${m('--tabs-item-active-color')};
  ${itemLinkUnderlineActiveCss}
`;

export const tabBreakCss = css`
  z-index: ${ZIndex.NAVIGATOR_BUTTON};
  width: 100%;
  ${mobileMediaQuery} {
    width: calc(100% - ${m('--spacing-xl')}px);
  }
  height: 16px;
  position: relative;
  margin-top: -${m('--spacing-xs')};
  background-color: ${m('--background-color')};
`;
