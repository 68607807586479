import { css } from '@emotion/css';

import { mobileMediaQuery } from '../../constants';
import { m } from '../../motif';

export const sliderCss = css`
  display: inline-flex;

  & input {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  & label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 50px;
    height: 29px;
    background: ${m('--toggle-slider-background-color')};
    border-radius: 100px;
    position: relative;
  }

  & span {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 25px;
    height: 25px;
    border-radius: 45px;
    transition: 0.2s;
    background: ${m('--toggle-slider-switch-color')};
    box-shadow: 0px 0px 5px 1px rgb(10 10 10 / 29%);
  }

  ${mobileMediaQuery} {
    & span {
      width: 12px;
      height: 12px;
    }

    & label {
      width: 28px;
      height: 16px;
    }
  }
`;

export const checkedSliderCss = css`
  display: inline-flex;

  & label span {
    transform: translateX(calc(100% - 4px));
  }

  & label {
    background-color: ${m('--toggle-slider-active-color')};
  }

  ${mobileMediaQuery} {
    & label span {
      transform: translateX(calc(100% - 0.5px));
    }
  }
`;
