import { css } from '@emotion/css';

export const iframeContainerCss = css`
  width: 100%;
  min-width: 300px;
  max-width: 416px;
  margin: 0 auto;

  > iframe {
    aspect-ratio: 3 / 5;
    width: 100%;
    height: 100%;
  }
`;

export const blockQuoteCss = css`
  background: #c4c4c4;
  border: 0;
  border-radius: 40px;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.5), 0 1px 10px 0 rgba(0, 0, 0, 0.15);
  margin: 1px;
  min-width: 300px;
  max-width: 416px;
  padding: 0;
  width: 99.375%;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 650px;
`;

export const frameContainerCss = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const linkCss = css`
  background-color: #f4f4f4;
  border-radius: 50%;
  flex-grow: 0;
  height: 40px;
  margin: 16px 14px 16px 16px;
  width: 40px;
  cursor: pointer;
`;

export const frameCss = css`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
`;

export const spacerCss = css`
  flex: 1;
`;

export const footerCss = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-end-end-radius: 40px;
  border-end-start-radius: 40px;
`;

export const footerLinkCss = css`
  background-color: yellow;
  width: 100%;
  padding: 10px 20px;
  border: none;
  border-radius: inherit;
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
  color: black;
  align-items: center;
`;

// Using non-motif values because these should match with external embed styles, not sdsm
export const footerTextCss = css`
  margin-left: 4px;
  padding-bottom: 1px;
  font-size: 14px;
`;
