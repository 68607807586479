import { cx } from '@emotion/css';
import type { FC, KeyboardEvent, MouseEvent, ReactNode } from 'react';
import { useEffect, useRef } from 'react';

import { m, MotifComponent, useMotifStyles } from '../../motif';
import type { BaseComponentProps } from '../../types';
import { Icon } from '../Icon';
import {
  sideOverlayActiveCss,
  sideOverlayBackdropActiveCss,
  sideOverlayBackdropCss,
  sideOverlayCloseButtonCss,
  sideOverlayCss,
  sideOverlayDefinitionCss,
  sideOverlayHeaderCss,
  sideOverlayListCss,
  sideOverlayTermCss,
} from './SideOverlay.styles';

interface SideOverlayProps extends BaseComponentProps {
  isOpen: boolean;
  title?: ReactNode;
  body?: ReactNode;
  onClose?: (e: MouseEvent | KeyboardEvent) => void;
}

export const SideOverlay: FC<SideOverlayProps> = ({ isOpen, title, body, onClose, className }) => {
  useMotifStyles(MotifComponent.SIDE_OVERLAY);

  const closeButtonRef = useRef<HTMLButtonElement>(null);

  // Focus the close button when the overlay opens
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        closeButtonRef?.current?.focus();
      }, 250); // Wait for overlay to animate in and become visible
    }
  }, [isOpen]);

  return (
    <>
      <div
        className={cx(sideOverlayBackdropCss, {
          [sideOverlayBackdropActiveCss]: isOpen,
        })}
        onClick={onClose}
      />
      <aside
        className={cx(
          MotifComponent.SIDE_OVERLAY,
          sideOverlayCss,
          {
            [sideOverlayActiveCss]: isOpen,
          },
          className
        )}
      >
        <div className={sideOverlayHeaderCss}>
          <button
            className={sideOverlayCloseButtonCss}
            type="button"
            ref={closeButtonRef}
            onClick={onClose}
            onKeyDown={onClose}
          >
            <Icon name="cross" size={24} fill={m('--side-overlay-fg-color')} />
          </button>
        </div>
        <dl className={sideOverlayListCss}>
          <dt className={sideOverlayTermCss}>{title}</dt>
          <dd className={sideOverlayDefinitionCss}>{body}</dd>
        </dl>
      </aside>
    </>
  );
};
