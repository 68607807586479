import type { NavigationItem } from './types';
/**
 * Checks if the destination URL matches with the current url as in window.location. This is the
 * default function for checking if a navigation item should be highlighted in the breadcrumb.
 */

export function defaultIsUrlCurrent(location?: Location): (url: string) => boolean {
  return (destinationUrl: string): boolean => {
    if (!location) {
      return false;
    }

    try {
      const base = `${location.protocol}//${location.hostname}`;
      const destUrl = new URL(destinationUrl, base);

      const currentUrlHost = location.hostname;
      const currentUrlPath = location.pathname;

      return currentUrlHost === destUrl.hostname && currentUrlPath === destUrl.pathname;
    } catch (_error) {
      return false;
    }
  };
}

export function getNavigationBreadcrumbs(
  navigationTree: NavigationItem[],
  isUrlCurrent: (url: string) => boolean = defaultIsUrlCurrent(
    typeof window === 'undefined' ? undefined : window?.location
  )
): Set<string> {
  const results = new Set<string>();

  navigationTree.map(node => traverseTree(results, node, isUrlCurrent));

  return results;
}

/** Helper function to traverse navigation tree and add active nodes & ancestors to output */
const traverseTree = (
  output: Set<string>,
  item: NavigationItem,
  isUrlCurrent?: (url: string) => boolean,
  parents: NavigationItem[] = []
): void => {
  // handle leaf node
  if (!item.subItems?.length) {
    const isSelected = item.url && isUrlCurrent?.(item.url);

    // add node and ancestors to output
    if (isSelected) {
      output.add(item.id);
      parents.map(parent => output.add(parent.id));
    }

    return;
  }

  // handle trunk node
  for (const child of item.subItems) {
    const ancestors = [...parents, item];
    traverseTree(output, child, isUrlCurrent, ancestors);
  }
};
