// THIS FILE EXISTES SOLELY TO STUB REMOVED EXPORTED NAMES TO AVOID BREAKING CHANGES.

import type { FC } from 'react';

export const ThemeStylesheet: FC<unknown> = () => null;
export const createThemeStyleshet = (_: unknown): string => '';
export const cssVar = (_: unknown): string => '';
export type ThemeVariable = never;
export const getThemeClassName = (_: unknown): string => '';
export const SnapchatTheme = undefined;
export const commonPalette = undefined;
export type CustomMotifName = never;
export const Shadow = { BASE: '' };
export type ItemColorProps = never;
export type SliderColorProps = never;
export type ItemColorPalette = never;
export type ComponentWithItemsPalette = never;
export type ToggleButtonPallete = never;
export type ButtonColorProps = never;
export type BlockColorProps = never;
export type ButtonPalette = never;
export type ComponentWithItemsColorProps = never;
export type FontProps = never;
export type ColorProps = never;
export type SubNavigationThemeProps = never;
export type GlobalHeaderColorProps = never;
export const nonButtonCss = undefined;
export const clickableBaseCss = undefined;
export type ThemeName = never;
export const allThemedComponents = [];
export type ColorVar = never;
export type ColorVarsMap = never;
export type Theme = never;
export const setupPage = (): void => {};
export type ChartToggleThemeProps = never;
export type ChartToggleButtonColorProps = never;
export type ColorPropsWithBorder = never;
export type NavProps = never;
