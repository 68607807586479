import type { Document } from '@contentful/rich-text-types';
import type { KeyValueMap } from 'contentful-management/dist/typings/export-types';

import { defaultLocale } from '../constants';
import { cleanAndJoinText } from './cleanText';
import { getPlainText } from './getPlainText';
import type { Locales } from './types';

/**
 * Helper function to extract text from an entry. The fields to extract text from must be either a
 * string or rich text node.
 */
export function extractEntryText<T extends KeyValueMap>(
  entryFields: T,
  locales: Locales[],
  fields: (keyof T)[]
): Record<Locales, string> {
  if (!entryFields) {
    return {};
  }

  const localeToTextMap: Record<Locales, string> = {};

  for (const locale of locales) {
    const results = [];

    for (const field of fields) {
      const fieldText = (entryFields?.[field]?.[locale] ??
        entryFields?.[field]?.[defaultLocale]) as Document | string | undefined;

      const fieldPlainText = getPlainText(fieldText);
      results.push(fieldPlainText);
    }

    const cleanedStr = cleanAndJoinText(results);
    localeToTextMap[locale] = cleanedStr;
  }

  return localeToTextMap;
}
