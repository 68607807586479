import { css } from '@emotion/css';

import { mobileMediaQuery, nonMobileMediaQuery } from '../../constants';
import { m, p2Css } from '../../motif';

const iconSize = 84; // width and height, taken from figma

// mobile or a full date on desktop.
export const headerMobileCss = css`
  ${p2Css}
  ${mobileMediaQuery} {
    margin-block-end: ${m('--spacing-l')};
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  ${nonMobileMediaQuery} {
    display: none;
  }
`;

export const headerDesktopCss = css`
  ${p2Css}
  ${mobileMediaQuery} {
    display: none;
  }

  ${nonMobileMediaQuery} {
    margin-block-end: ${m('--spacing-xl')};
    display: flex;
    align-items: center;
  }
`;

export const headerImageCss = css`
  border-radius: ${m('--spacing-xs')};
  width: ${iconSize}px;
  height: ${iconSize}px;
  object-fit: contain;

  ${mobileMediaQuery} {
    margin-bottom: ${m('--spacing-m')};
  }

  ${nonMobileMediaQuery} {
    margin-inline: 0 ${m('--spacing-m')};

    *[dir='rtl'] & {
      margin-inline: ${m('--spacing-m')} 0;
    }
  }
`;
