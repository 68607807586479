import type { Document } from '@contentful/rich-text-types';
import { BLOCKS } from '@contentful/rich-text-types';
import merge from 'lodash/merge.js';

/** Merges the text in 2 rich text documents into a single document. */
// eslint-disable-next-line import/no-unused-modules
export function mergeRichText(
  doc1: Document | undefined,
  doc2: Document | undefined
): Document | undefined {
  if (!doc1) return doc2;
  if (!doc2) return doc1;

  return {
    data: merge(doc1.data, doc2.data),
    content: [...doc1.content, ...doc2.content],
    nodeType: BLOCKS.DOCUMENT,
  };
}
