import type { ReactElement } from 'react';

import { formatBarLabel } from '../utils/helpers';

const barPercentLegendFontSize = 14;

type BarPercentLabelProps = {
  barX: number;
  barY: number;
  barWidth: number;
  barHeight: number;
  value: string | number;
  showAsPercent?: boolean;
  numberOfDecimalPlaces?: number;
  isRTL?: boolean;
};

export const BarPercentLabel = (props: BarPercentLabelProps): ReactElement => {
  const { barX, barY, barHeight, barWidth, value, showAsPercent, numberOfDecimalPlaces } = props;
  return (
    <text
      x={barX + barWidth}
      y={barY + (barHeight + barPercentLegendFontSize) / 2}
      fill="black"
      fontSize={barPercentLegendFontSize}
      fontWeight="bold"
      dx=".8em"
      textAnchor={props.isRTL ? 'end' : 'start'}
    >
      {formatBarLabel(value, showAsPercent, numberOfDecimalPlaces)}
    </text>
  );
};
