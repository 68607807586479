import { gql } from 'graphql-tag';

import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';
import { criteriaFragment } from '../Experiment';

// TODO: get url from first level items so it allows first level links or sections
// (not implemented in SDS)

export const fragments = {
  all: gql`
    fragment SideNavigationAll on SideNavigation {
      ...ContentfulSysId
      backgroundColor
      mobileBackgroundColor
      navigatorItemsCollection(limit: 14) {
        items {
          ...ContentfulSysId
          title {
            json
          }
          hideCriteria {
            ...CriteriaAll
          }
          navigatorItemsCollection(limit: 15) {
            items {
              ...ContentfulSysId
              title {
                json
              }
              url
              hideCriteria {
                ...CriteriaAll
              }
            }
          }
        }
      }
    }
    ${criteriaFragment}
    ${contentfulSysIdFragment}
  `,
};

export const query = gql`
  query SideNavigationQuery($preview: Boolean!, $locale: String!, $id: String!) {
    sideNavigation(preview: $preview, locale: $locale, id: $id) {
      ...SideNavigationAll
    }
  }
  ${fragments.all}
`;
