/**
 * A list of sites that we support on MWP.
 *
 * Shared to avoid other packages from maintaining separate lists.
 *
 * NOTE: IF you update this, also update the ALL_SPACE_NAMES variable in all_spaces.sh.
 */
export enum Site {
  A523 = '523',
  AR = 'ar',
  ARCADIA = 'arcadia',
  AVALON = 'avalon',
  CAREERS = 'careers',
  CHEERIOS = 'cheerios',
  CITIZEN = 'citizen',
  CREATORS = 'creators',
  DIVERSITY = 'diversity',
  EDUCATORS = 'educators',
  ENG_BLOG = 'eng-blog',
  EXPERIENCE = 'experience',
  FOR_BUSINESS = 'for-business',
  NEWSROOM = 'newsroom',
  ORIGINALS = 'originals',
  PARENTS = 'parents',
  SANDBOX = 'sandbox',
  SNAP = 'snap',
  TRUST = 'trust',
}

export const allSites = [
  Site.A523,
  Site.AR,
  Site.ARCADIA,
  Site.CAREERS,
  Site.CHEERIOS,
  Site.CITIZEN,
  Site.CREATORS,
  Site.DIVERSITY,
  Site.EDUCATORS,
  Site.ENG_BLOG,
  Site.EXPERIENCE,
  Site.FOR_BUSINESS,
  Site.NEWSROOM,
  Site.ORIGINALS,
  Site.PARENTS,
  Site.SANDBOX,
  Site.TRUST,
  Site.AVALON,
  Site.SNAP,
] as const;
