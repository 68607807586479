import { cx } from '@emotion/css';
import kebabCase from 'lodash-es/kebabCase';
import type { FC } from 'react';
import type React from 'react';

import { m } from '../../../motif';
import type { BaseComponentProps } from '../../../types';
import { ChartIcon } from './icons/ChartIcon';
import { FlashlightIcon } from './icons/FlashlightIcon';
import { FollowIcon } from './icons/FollowIcon';
import { HoverIcon } from './icons/HoverIcon';
import { OrbitIcon } from './icons/OrbitIcon';
import { RevealIcon } from './icons/RevealIcon';
import { RewardsIcon } from './icons/RewardsIcon';
import { WaffleIcon } from './icons/WaffleIcon';

export type GenericSvgIconProps = BaseComponentProps & React.SVGProps<SVGSVGElement>;

export const allCustomIcons = {
  chart: ChartIcon,
  flashlight: FlashlightIcon,
  follow: FollowIcon,
  hover: HoverIcon,
  orbit: OrbitIcon,
  reveal: RevealIcon,
  rewards: RewardsIcon,
  waffle: WaffleIcon,
};

export const allCustomIconNames = Object.keys(allCustomIcons);

export type CustomIconName = keyof typeof allCustomIcons;

type CustomIconProps = GenericSvgIconProps & {
  iconName: CustomIconName;
};

/**
 * Legacy component that used to hold our own custom icons. Keeping separate for now as to not have
 * any regressions on how we style icons. Do NOT use this anywhere, favor using Icon instead.
 *
 * TODO: just move this into the Icon component, we don't really it separated.
 */
export const CustomIcon: FC<CustomIconProps> = ({
  iconName,
  className,
  fill,
  ...otherSvgIconProps
}) => {
  const Icon = allCustomIcons[iconName];

  if (!Icon) {
    return null;
  }

  return (
    <Icon
      className={cx(`icon-${kebabCase(iconName)}`, className)}
      fill={fill ?? m('--icon-color')}
      {...otherSvgIconProps}
    />
  );
};
