import { cx } from '@emotion/css';
import { ensureSingleScript } from '@snapchat/mw-common/client';
import type { FC } from 'react';
import { useContext, useEffect, useRef } from 'react';

import { MotifComponent, useMotifStyles } from '../../motif';
import type { BaseComponentProps } from '../../types';
import { dataSetToAttributes } from '../../utils';
import { PrimitivesContext } from '../Primitives';
import {
  blockQuoteCss,
  footerCss,
  footerLinkCss,
  footerTextCss,
  frameContainerCss,
  frameCss,
  iframeContainerCss,
  linkCss,
  spacerCss,
} from './SnapchatEmbed.styles';

export interface SnapchatEmbedProps extends BaseComponentProps {
  url: string;
}

/** (BETA) Renders an embedded Lens, Spotlight, Publish Profile, or Story. */
export const SnapchatEmbed: FC<SnapchatEmbedProps> = props => {
  const { url, dataset, className } = props;
  const ref = useRef<HTMLDivElement>(null);
  useMotifStyles(MotifComponent.SNAPCHAT_EMBED);
  const { Anchor } = useContext(PrimitivesContext);

  useEffect(() => {
    ensureSingleScript('snapchat-embed', 'https://www.snapchat.com/embed.js', () => {
      // See https://github.sc-corp.net/Snapchat/web/pull/4201
      const event = new Event('loadEmbed', { bubbles: true });
      ref.current?.dispatchEvent(event);
    });
  }, [url]);

  // Remove params and trailing slash
  const newUrl = new URL(url);
  const urlWithoutParams = `${newUrl.origin}${newUrl.pathname}`.replace(/\/$/, '');

  // Note: These elements and styles comes directly from the embed code
  return (
    <div
      className={cx(MotifComponent.SNAPCHAT_EMBED, iframeContainerCss, className)}
      ref={ref}
      {...dataSetToAttributes(dataset)}
    >
      <blockquote
        className={cx(blockQuoteCss, 'snapchat-embed')}
        data-snapchat-embed-width="416"
        data-snapchat-embed-height="692"
        data-snapchat-embed-url={`${urlWithoutParams}/embed`}
        data-snapchat-embed-style="border-radius: 40px;"
      >
        <div className={frameContainerCss}>
          <Anchor href={urlWithoutParams} className={linkCss} />

          <div className={frameCss} />
        </div>
        <div className={spacerCss} />
        <div className={footerCss}>
          <Anchor href={urlWithoutParams} className={footerLinkCss}>
            <div className={footerTextCss}>View more on Snapchat</div>
          </Anchor>
        </div>
      </blockquote>
    </div>
  );
};
