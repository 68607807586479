import { css } from '@emotion/css';
import type { FC } from 'react';

const error500Css = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  overflow-x: hidden;
  position: relative;
  width: 100%;
  height: 100vh;
  text-align: center;
  background-color: #fffc00;

  h1 {
    font-size: 7em;
    font-weight: 600;
    margin-bottom: 40px;
  }

  p {
    margin-top: 0.5em;
  }

  a {
    text-decoration: none;
  }
`;

export const Error500: FC = () => {
  return (
    <div className={error500Css}>
      <h1>500</h1>
      <h2>Oops! Something went wrong</h2>

      <p>
        <a href="/">Go to home</a>
      </p>
    </div>
  );
};
