import type React from 'react';
import { useContext, useRef } from 'react';

import { ContentfulContext } from '../../context';
import { DesignAgnosticLocaleDropdown } from '../designAgnostic';
import { GhostLogo } from '../Logo/Ghost';
import type { BackgroundType, LocaleOption } from '../types';

interface Props {
  backgroundType: BackgroundType;
  supportedLocales: Record<string, LocaleOption>;
  onLocaleChange?: (locale: string) => void;
}

export const CookieModalHeader: React.FC<Props> = ({
  backgroundType,
  supportedLocales,
  onLocaleChange,
}) => {
  const { currentLocale } = useContext(ContentfulContext);

  const headerRef = useRef<HTMLDivElement>(null);
  // NOTE: headerRef.current is guaranteed to exist prior to when containerProvider is called.
  const containerProvider = () => headerRef.current!;
  return (
    <div data-testid="mwp-cookie-modal-header" className="modal-header" ref={headerRef}>
      <div className="logo-container">
        <GhostLogo width={24} height={24} backgroundType={backgroundType} />
      </div>
      <div className="locale-container">
        <DesignAgnosticLocaleDropdown
          supportedLocales={supportedLocales}
          currentLocale={supportedLocales[currentLocale] as LocaleOption}
          onLocaleChange={onLocaleChange}
          // Specify container to ensure dropdown popup sticks to modal
          containerProvider={containerProvider}
        />
      </div>
    </div>
  );
};
