import { gql } from '@apollo/client';

import type { ContentfulTypedSysProps } from '../../types/contentful';
import type { RichText } from '../../types/RichText';
import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';

export const fragments = {
  all: gql`
    fragment CustomerQuoteAll on CustomerQuote {
      ...ContentfulSysId
      authorName
      body {
        json
      }
    }
    ${contentfulSysIdFragment}
  `,
};

export interface CustomerQuoteProps extends ContentfulTypedSysProps<'CustomerQuote'> {
  authorName: string;
  body: RichText;
}

export const query = gql`
  query CustomerQuotesQuery($preview: Boolean!, $locale: String!, $id: String!) {
    customerQuote(preview: $preview, locale: $locale, id: $id) {
      ...CustomerQuoteAll
    }
  }
  ${fragments.all}
`;

export interface CustomerQuoteDataProps extends ContentfulTypedSysProps<'CustomerQuote'> {
  customerQuote: CustomerQuoteProps;
}
