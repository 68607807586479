import { useContext } from 'react';

import type { ContentfulContextType } from '../utils/contentful/ContentfulContext';
import { ContentfulContext } from '../utils/contentful/ContentfulContext';

export const useContentfulContext = (): ContentfulContextType => {
  const context = useContext(ContentfulContext);

  if (context === undefined) {
    throw new Error(
      'The useContentfulQuery hook can only be used in combination with ContentProvider'
    );
  }

  return context;
};
