import { createContext, useCallback, useContext } from 'react';

import type { SingleCallbackArgs, SingleCallbackCache } from '../utils/singleCallback';
import { singleCallback } from '../utils/singleCallback';

export const SingleCallbackCacheContext = createContext<SingleCallbackCache>(new Map());

export type SingleCallbackFn = (args: Omit<SingleCallbackArgs, 'cache'>) => void;

/** A hook wrapper around `singleCallback` that allows scoped calling history. */
export function useSingleCallback(): SingleCallbackFn {
  const cache = useContext(SingleCallbackCacheContext);
  const singleCallbackInternal = useCallback<SingleCallbackFn>(
    args => singleCallback({ ...args, cache }),
    [cache]
  );

  return singleCallbackInternal;
}
