import { SideOverlay as SideOverlaySDS } from '@snapchat/snap-design-system-marketing';
import type { FC, KeyboardEvent, MouseEvent } from 'react';
import { useContext } from 'react';

import { DefinitionContext } from './DefinitionContext';
import type { DefinitionOverlayProps } from './types';

export const DefinitionOverlay: FC<DefinitionOverlayProps> = ({ className }) => {
  const { isOpen, close, content, triggerRef } = useContext(DefinitionContext);

  const handleClose = (e: MouseEvent | KeyboardEvent) => {
    // Return focus to the trigger element only if using keyboard navigation
    // Do before before calling 'close()', which removes the trigger element from state
    if (['Enter', ' '].includes((e as KeyboardEvent)?.key)) {
      e.preventDefault(); // Prevent browser page-down action on spacebar press
      triggerRef?.current?.focus();
    }

    close();

    // Note: using `useHistory` causes a re-render so the element loses focus
    // so using `window.history` directly instead to remove hash from url
    const url = new URL(window.location.href);
    url.hash = '';
    window.history.replaceState(null, '', url);
  };

  return (
    <SideOverlaySDS
      isOpen={isOpen}
      title={content?.title}
      body={content?.body}
      onClose={handleClose}
      className={className}
    />
  );
};

DefinitionOverlay.displayName = 'DefinitionOverlay';
