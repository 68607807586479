import type { CSSObject } from '@emotion/css';
import { cache, injectGlobal } from '@emotion/css';
import clone from 'lodash-es/clone';
import cloneDeep from 'lodash-es/cloneDeep';
import merge from 'lodash-es/merge';

import type { BackgroundColor } from '../constants/backgroundColor';
import { defaultMotif } from './defaultMotif';
import {
  allMotifSchemes,
  MotifComponent,
  MotifScheme,
  nonDefaultMotifSchemes,
} from './motifConstants';
import type { Motif, MotifRootVar, PartialMotif } from './motifTypes';

// TODO: Should be in /common. Use the same definition in web etc.
const isClient = typeof window !== 'undefined' && typeof window.document !== 'undefined';

let globalMotif: Motif = defaultMotif;

/**
 * Global function for setting the Motif for the components.
 *
 * NOTE: This has to be called before any of the components are intantiated or else wrong styles
 * will be injected.
 */
export function setMotif(motif: Motif): void {
  if (motif.name === globalMotif.name) return;
  globalMotif = motif;

  clearEmotionInjectedGlobals();
}

/** Exposes the name of the motif that was set. Useful for defining hooks for say Storybook. */
export function getMotifName(): string {
  return globalMotif.name;
}

/**
 * Returns the scheme for the legacy background color.
 *
 * Useful during the transition period.
 */
export function getMotifSchemeForLegacyBackgroundColor(
  color?: BackgroundColor | MotifScheme
): MotifScheme {
  if (!color) return MotifScheme.DEFAULT;
  if (allMotifSchemes.includes(color as MotifScheme)) return color as MotifScheme;
  const scheme = allMotifSchemes.find(scheme => globalMotif[scheme]?.legacyName === color);
  return scheme ?? MotifScheme.DEFAULT;
}

/**
 * Global function that should be used from within components to inject styles.
 *
 * Calling this multiple times with the same component is a no-op.
 *
 * NOTE: We do not use emotion's injectGlobal here because it always _appends_ styles which isn't
 * the exact behavior we want every time and we can't delete old styles when using storybook and
 * such.
 */
export function injectStyles(component: MotifComponent): void {
  /**
   * Map from styles (i.e. `foo: bar;`) to their selectors. This is an optimization to dedupe
   * duplicate styles when server-side rendering the stylesheet.
   */
  const stylesAndSelectors = new Map<CSSObject, Set<string>>();

  for (const scheme of allMotifSchemes) {
    const variables = globalMotif[scheme]?.[component];
    if (!variables) continue;

    if (!stylesAndSelectors.has(variables)) {
      stylesAndSelectors.set(variables, new Set());
    }

    const selectors = stylesAndSelectors.get(variables)!;

    // For the default scheme, we want to include it w/ no guards so that other schemes can
    // inherit styles from it.
    if (scheme === MotifScheme.DEFAULT) {
      selectors.add(`.${MotifComponent.ROOT}`);
    }

    selectors.add(`.${scheme}`);
  }

  const styles: Record<string, CSSObject> = {};

  for (const [cssValues, selectorSet] of stylesAndSelectors.entries()) {
    styles[[...selectorSet].join(',\n')] = cssValues;
  }

  emotionInjectGlobal(styles);
}

const emotionInjectedGlobals = new Set<string>();

/** Injects global styles to emotion, recording the hash of the inserted object. */
function emotionInjectGlobal(styles: Record<string, CSSObject>) {
  const beforeKeys = Object.keys(cache.inserted);

  injectGlobal(styles);

  for (const key in cache.inserted) {
    if (beforeKeys.includes(key)) continue;
    emotionInjectedGlobals.add(key);
  }
}

/** Clears the keys in emotion default cache with the current motif styles. */
function clearEmotionInjectedGlobals() {
  for (const globalEmotionHash of emotionInjectedGlobals) {
    // This deletes the items from the default emotion cache. This has the effect
    // of these styles being forgotten and not rendered on the server-side.
    // This is important, because without this step, every SSR invocation will
    // wite out all globally injected styles and keep an infinite history.
    delete cache.inserted[globalEmotionHash];

    if (isClient) {
      // In browser, emotion adds styles in shape <style data-emotion="<some-id> <hash1> ..."
      // And generally, global styles are added into individual style elements, which makes it
      // possible to delete them without side-effects.
      window.document
        .querySelectorAll(`style[data-emotion~="${globalEmotionHash}"]`)
        .forEach(el => el.remove());

      // TODO: The above does not work in our current MWP setup except in storybook. Emotion isn't putting
      // the ids in the element fields. We need another way to capture and delete the added styles.
      // This makes dynamically changing the motif in the client impossible.
    }
  }

  emotionInjectedGlobals.clear();
}

/**
 * Returns a deep clone of the motif.
 *
 * This decouples any of the same variable sets that are in the motif so this should be used instead
 * of a `cloneDeep` or `structuredClone`.
 */
export function cloneMotif(originalMotif: Motif): Motif {
  // IMPORTANT: Can't use "cloneDeep" or "structuredClone" here because it
  // dedupes already duplicate
  // objects, which isn't what we want here. I.e. if multiple schemes reuse
  // the variables (i.e. it's the same object), it's going to be the same object
  // after the clone! This isn't desired here.
  const motif = clone(originalMotif);

  motif[MotifScheme.DEFAULT] = cloneDeep(motif[MotifScheme.DEFAULT]);

  for (const scheme of nonDefaultMotifSchemes) {
    if (!motif[scheme]) continue;
    // NOTE: Every scheme needs to be cloned separately.
    motif[scheme] = cloneDeep(motif[scheme]!);
  }

  return motif;
}

/** Merges changes onto a motif. */
export function mergeMotifs(originalMotif: Motif, changes: PartialMotif): Motif {
  const motif = cloneMotif(originalMotif);
  return merge(motif, changes);
}

/** Returns the value of root variable for the current motif. */
export function getMotifVariableValue(
  scheme: MotifScheme,
  variable: MotifRootVar
): string | undefined {
  return globalMotif[scheme]?.[MotifComponent.ROOT]?.[variable];
}
