import type { Button as ButtonType } from '@snapchat/mw-contentful-schema';
import type { FC } from 'react';
import { useContext } from 'react';

import type { ContentfulIdVariable } from '../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../hooks/useContentfulQuery';
import { UserAction } from '../../types/events';
import { isContentfulSysNotShallow } from '../../utils/contentful';
import { getContentfulInspectorProps } from '../../utils/contentful/getContentfulInspectorProps';
import type { ButtonData } from '../Button';
import { Button } from '../Button';
import { ConsumerContext } from '../ConsumerContextProvider';
import { query } from './query';
import type {
  CallToActionDataHandlerProps,
  CallToActionDataProps,
  CallToActionProps,
} from './types';

export const CallToAction: FC<CallToActionProps> = props => {
  const consumerContext = useContext(ConsumerContext);
  const { data: rawData } = useContentfulQuery<CallToActionDataHandlerProps, ContentfulIdVariable>(
    query,
    {
      skip: isContentfulSysNotShallow(props),
      variables: { id: props.sys.id },
    }
  );

  const data = rawData ? rawData?.callToAction : (props as CallToActionDataProps);

  // Sanity check for when content is archived or isn't published.
  if (!data || !data.sys || !data.presentation) {
    return null;
  }

  const { presentation, analytics, url, className } = data;
  const { logEvent } = consumerContext;
  const label = analytics?.label;

  const onClick = (): void => {
    // NOTE: This is legacy record; the `url` is null in all new CTAs so the logEvent doesn't
    // have enough information to log anything. Loggin happens inside the presentation component.
    logEvent?.({ type: UserAction.Click, component: 'CTA', label, url });
  };

  if (presentation.__typename === 'Button') {
    // TODO: Remove this cast. Requires making MediaProps work w/ Asset.
    const buttonData = presentation as ButtonData;

    const { titleDataset } = getContentfulInspectorProps<ButtonType>({
      entryId: buttonData.sys.id,
      fieldIds: ['title'],
    });

    return (
      <Button
        onClick={onClick}
        {...buttonData}
        className={className}
        buttonTextDataset={titleDataset}
      />
    );
  }

  // TODO: Throw an error here.
  return null;
};

CallToAction.displayName = 'CallToAction';
