import { css } from '@emotion/css';

import { MobileHeading } from '../../../constants';
import { m } from '../../../motif';

export const accordionItemCss = css`
  border-bottom: 1px solid ${m('--accordion-divider-border-color')};
  scroll-margin: 40px;
  overflow-y: hidden;
  overflow-x: auto;

  section {
    margin-bottom: 0;
    padding: ${m('--spacing-xs')} ${m('--spacing-xxxl')} ${m('--spacing-l')} ${m('--spacing-m')};
  }
`;

export const summaryCss = css`
  ${MobileHeading.M500}
  align-items: center;
  cursor: pointer;
  display: flex;
  font-weight: 500;
  justify-content: space-between;
  margin: 0;
  padding-block: ${m('--spacing-m')};
  padding-inline: ${m('--spacing-m')} 52px;
  user-select: none;

  /* Prevents keyboard selection of focusable elements that the panel is collapsed. */
  &[data-state='off'] + * {
    visibility: hidden;
  }

  /* In webkit the arrow is rendered as a list icon; so we hide that, too. */
  /* We add our own arrow so we need to hid the default one. */
  ::-webkit-details-marker {
    display: none;
  }
  list-style-type: none;

  > svg {
    right: ${m('--spacing-m')};
  }

  *[dir='rtl'] & svg {
    left: ${m('--spacing-m')};
    right: unset;
  }
`;

export const itemChevronCss = css`
  width: 20px;
  height: 20px;
  /* we override top here since its already centered by flex */
  top: unset;
`;
