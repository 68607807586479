import { cx } from '@emotion/css';
import { Icon, MessageContext } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useContext } from 'react';

import { ShopifyContext } from '../ShopifyProvider';
import { usePrice } from './hooks';
import {
  accessoryNameAndPriceCss,
  accessoryQuantityCss,
  accessoryQuantityModificationCss,
  accessoryQuantityModificationDisabledCss,
  accessoryTitleCss,
  accessoryWrapperCss,
  quantityWrapperCss,
} from './styles';
import type { ShopifyProduct } from './types';

export const CartAccessory: FC<ShopifyProduct> = ({
  title,
  price,
  handle,
  maxQuantity,
  available,
}) => {
  const { cart, products, currencyCode = '' } = useContext(ShopifyContext);
  const formattedPrice = usePrice(price);
  const { formatMessage } = useContext(MessageContext);
  const quantity = cart.lineItems[handle] ?? 0;
  const accessoryPrice = formatMessage(
    {
      id: 'cartAccessoryPrice',
      defaultMessage: '{price} Each',
    },
    { price: formattedPrice }
  );
  const contentfulProduct = products?.contentful[handle] ?? {};
  const addButtonDisabled = maxQuantity === quantity || !available;
  const removeButtonDisabled = quantity === 0 || !available;
  return (
    <div className={accessoryWrapperCss}>
      <div className={accessoryNameAndPriceCss}>
        <div className={accessoryTitleCss}>{title}</div>
        <div>{accessoryPrice}</div>
      </div>
      <div className={quantityWrapperCss}>
        <button
          disabled={removeButtonDisabled}
          onClick={() =>
            cart.removeOne({
              handle,
              contentfulProduct,
              shopifyProducts: products.shopify,
              currencyCode,
            })
          }
          className={cx(accessoryQuantityModificationCss, {
            [accessoryQuantityModificationDisabledCss]: removeButtonDisabled,
          })}
        >
          <Icon name="remove" />
        </button>
        <span
          className={cx(accessoryQuantityCss, { [accessoryQuantityModificationCss]: !available })}
        >
          {quantity}
        </span>
        <button
          disabled={addButtonDisabled}
          onClick={() =>
            cart.addOne({
              handle,
              contentfulProduct,
              shopifyProducts: products.shopify,
              currencyCode,
            })
          }
          className={cx(accessoryQuantityModificationCss, {
            [accessoryQuantityModificationDisabledCss]: addButtonDisabled,
          })}
        >
          <Icon name="add" />
        </button>
      </div>
    </div>
  );
};
