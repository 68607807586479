import Cookies from 'js-cookie';

const setCookie = (name: string, value: string, domain = ''): void => {
  Cookies.set(name, value, { expires: 365, secure: true, domain });
};

/**
 * Helper function that saves the user's Opt in state for each Cookie Category as cookies on the
 * specified `cookieDomain`.
 *
 * @param cookieDomain String, domain attribute used when setting each cookie
 * @param cookieStatus Record, represents the users opt in state for each cookie category
 */
export const setOptInCookies = (
  cookieDomain: string,
  cookieStatus: Record<string, boolean>
): void => {
  for (const key in cookieStatus) {
    setCookie(key, (cookieStatus[key] as boolean).toString(), cookieDomain);
  }
};
