import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';

import type { RenderData } from '../types/RichText';

export const renderString = (data: RenderData): string | undefined => {
  if (!data) {
    return undefined;
  }

  if (typeof data === 'string') {
    return data;
  }

  return documentToPlainTextString(data.json);
};
