import type { ReactNode } from 'react';

import type { BackgroundColor } from '../../../constants';
import type { ToggleState } from '../../../hooks/useToggleState';
import type { BaseComponentProps } from '../../../types';

export interface GlobalNavScreenProps extends BaseComponentProps {
  onNavClose?: () => void;
  localNavMobile?: ReactNode;
  localNavMobileFooter?: ReactNode;
  globalNav: ReactNode;
  globalNavHeading: ReactNode;
  highlight: ReactNode;
  backgroundColor?: BackgroundColor;
  showMobileGlobalLinks?: boolean;
  screenState?: ToggleState;
}

export type GlobalNavScreenMobileProps = GlobalNavScreenProps;
export type GlobalNavScreenDesktopProps = Omit<
  GlobalNavScreenProps,
  'globalNavHeading' | 'localNavMobile' | 'localNavMobileFooter'
>;
