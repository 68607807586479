import {
  defaultGlobalNavMobileGroupKey,
  GlobalHeaderContext,
} from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useContext, useEffect } from 'react';

import type { NavigatorGroupProps } from '../Header/headerNavGroupUtils';
import { LocalNavItemMobile } from './LocalNavItemMobile';

interface LocalNavMobileProps {
  items?: NavigatorGroupProps[];
}

/**
 * Local nav links styled to look like the global nav, but only for mobile.
 *
 * It is only on mobile that we style the local nav in the same fashion as global nav.
 */
export const LocalNavMobile: FC<LocalNavMobileProps> = ({ items }) => {
  const { setGroupKey } = useContext(GlobalHeaderContext);

  useEffect(() => {
    setGroupKey?.(defaultGlobalNavMobileGroupKey);
  }, [setGroupKey]);

  if (!items || !items.length) {
    return null;
  }

  return (
    <>
      {items.map((props, i) => {
        return <LocalNavItemMobile key={`${props.sys.id}-${i}`} {...props} />;
      })}
    </>
  );
};

LocalNavItemMobile.displayName = 'LocalNavMobile';
