import { getTopLevelDomain } from '@snapchat/parse-domain';

/**
 * Function retrieves the the top level domain for the input value (if specified) or based on the
 * current window location. Otherwise returns the input value.
 *
 * NOTE: the browser default is not inheritable by subdomains.
 *
 * @param inputCookieDomain [Optional] if specified, uses this rather than the window location to
 *   determine top level domain.
 * @returns String, the parsed top level domain for the window location or input parameter. Example:
 *   ar.snap.com => snap.com
 */
export const getCookieDomain = (inputCookieDomain?: string): string => {
  // if input value is an empty string, use window location instead
  const hostName = inputCookieDomain || (window?.location?.hostname ?? '').toLowerCase();
  // Return top level domain (ar.snap.com => snap.com) or empty string
  return getTopLevelDomain(hostName);
};
