export interface SearchResult {
  slug: string;
  pageTitle: string;
  highlights: string[];
}

export interface SearchResultResponse {
  results: SearchResult[];
  total: number;
}

export const searchQueryParam = 'query';
export const searchOffsetParam = 'offset';
export const searchPerPageParam = 'page';
export const searchLocaleParam = 'locale';
