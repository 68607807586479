import { Black, Gray, White, Yellow } from '../../constants/colors';
import type { MotifComponent, MotifComponentProps } from '../../motif';
import { m } from '../../motif/m';

const defaultVars = {
  '--banner-fg-color': Black.V200,
  '--banner-font-size': m('--action-desktop-font-size'),
  '--banner-font-line-height': m('--action-desktop-font-line-height'),
};

export const bannerYellowBgVars: MotifComponentProps<MotifComponent.BANNER> = {
  '--banner-bg-color': Yellow.V100,
  ...defaultVars,
};

export const bannerBlackBgVars: MotifComponentProps<MotifComponent.BANNER> = {
  ...defaultVars,
  '--banner-bg-color': Black.V125,
  '--banner-fg-color': White,
};

export const bannerWhiteBgVars: MotifComponentProps<MotifComponent.BANNER> = {
  '--banner-bg-color': White,
  ...defaultVars,
};

export const bannerGrayBgVars: MotifComponentProps<MotifComponent.BANNER> = {
  '--banner-bg-color': Gray.V100,
  ...defaultVars,
};
