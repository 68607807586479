import { cx } from '@emotion/css';
import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { createPortal } from 'react-dom';

import { VerticalAlignment } from '../../constants';
import { useIsMobile } from '../../hooks';
import { useDisableScrollOutside } from '../../hooks/useDisableBodyScroll';
import { MotifComponent, useMotifStyles } from '../../motif';
import { dataSetToAttributes } from '../../utils';
import { IconButton } from '../IconButton';
import { IconButtonSize } from '../IconButton/IconButton.types';
import {
  alignmentCss,
  closeButtonCss,
  isBlockingClassName,
  modalBackdropCss,
  modalContentCss,
} from './styles';
import type { ModalProps } from './types';

/**
 * Component that displays an element over an overlay.
 *
 * There is a close button that renders for blocking modals.
 *
 * Note that there's no border UI or anything like that. That's up to the children prop.
 */
export const Modal: FC<ModalProps> = ({
  className,
  contentClassName,
  isBlocking = false,
  verticalAlignment = VerticalAlignment.Middle,
  onClose,
  isDisplayed = false,
  portalRoot,
  style,
  dataset,
  children,
  disableBackgroundScroll,
  shouldAllowScrollEvent,
}) => {
  useMotifStyles(MotifComponent.MODAL);

  // Using useState and useCallback to call `useDisableScrollOutside` after initial load
  const [modalRef, setModalRef] = useState<HTMLElement | null>(null);
  const isMobile = useIsMobile();
  const captureModalRef = useCallback(
    (node: HTMLElement | null) => setModalRef(node),
    [setModalRef]
  );

  useDisableScrollOutside(
    isBlocking || disableBackgroundScroll ? modalRef : null,
    undefined,
    shouldAllowScrollEvent
  );

  const modal = (
    <section
      className={cx(
        MotifComponent.MODAL,
        modalBackdropCss,
        {
          [isBlockingClassName]: isBlocking,
        },
        className
      )}
      style={style}
      {...dataSetToAttributes(dataset)}
    >
      <div
        className={cx(
          modalContentCss,
          'sdsm-modal-content',
          alignmentCss[verticalAlignment],
          contentClassName
        )}
        ref={captureModalRef}
      >
        {children}
        {!isBlocking && (
          <IconButton
            size={isMobile ? IconButtonSize.MEDIUM : IconButtonSize.LARGE}
            className={closeButtonCss}
            iconName="cross"
            onClick={onClose}
          />
        )}
      </div>
    </section>
  );

  if (!portalRoot) {
    return isDisplayed ? modal : null;
  }

  return isDisplayed ? createPortal(modal, portalRoot) : null;
};
