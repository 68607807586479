import { Spinner } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useContext, useEffect } from 'react';

import { AppContext } from '../../../../AppContext';
import type { LsDownloadsMap } from '../../helpers';
import { downloadsCookieKey, serializeVersionAndPlatform } from '../../helpers';
import { areQueryParamsValid } from '../../helpers/areQueryParamsValid';
import type { PlatformString } from '../../server';
import { spinnerContainerCss } from './ArDownloadIdCaptureBlock.styles';

const lensStudioUrl = 'http://127.0.0.1';
const lensStudioPath = '/auth';

export const ArDownloadIdCaptureBlock: FC = () => {
  const { cookieManager } = useContext(AppContext);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const version = searchParams.get('version');
    const platform = searchParams.get('platform') as PlatformString | null;
    const lensStudioPort = searchParams.get('ls_port');

    if (version && platform && areQueryParamsValid(version, platform, lensStudioPort)) {
      // || operator instead of ?? to account for when map serialized to ""
      const lsDownloadsMap = (cookieManager.getCookieJson(downloadsCookieKey) ||
        {}) as LsDownloadsMap;
      const downloadId = lsDownloadsMap[serializeVersionAndPlatform(version, platform)] ?? '';

      window.location.assign(
        `${lensStudioUrl}:${lensStudioPort}${lensStudioPath}?download_id=${downloadId}`
      );
    } else if (Number.isInteger(Number.parseInt(lensStudioPort ?? ''))) {
      window.location.assign(`${lensStudioUrl}:${lensStudioPort}${lensStudioPath}`);
    }
  }, [cookieManager]);

  return (
    <div data-test-id="lens-studio-redirect" className={spinnerContainerCss}>
      <Spinner />
    </div>
  );
};
