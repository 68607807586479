import type { SrcSetSizes } from '@snapchat/mw-contentful-client';
import { useContentfulImages } from '@snapchat/mw-contentful-client';
import { Media as MediaSDS } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useContext } from 'react';

import { GlobalComponentsContext } from '../../context/GlobalComponentsContext';
import type { AssetAllType } from '../../generated/contentful-types';
import { isImageUrl, isVideoUrl } from './mediaUtils';

type MediaWithSizes = AssetAllType & { sizedSrcSets?: SrcSetSizes };

export const Media: FC<MediaWithSizes> = props => {
  const { onError } = useContext(GlobalComponentsContext);
  const { contentType, url, description, sizedSrcSets: sizeSettings } = props;
  const { getImageSources } = useContentfulImages();

  if (isImageUrl(contentType)) {
    //TODO: eventaully move to using a PictureSDS and VideoSDS ENTWEB-6350
    let imgSrcs = getImageSources(url);

    if (sizeSettings) {
      imgSrcs = getImageSources(url, { size: sizeSettings });
    }

    return <MediaSDS sourceType={contentType} imgSrcs={imgSrcs} altText={description} />;
  }

  if (isVideoUrl(contentType)) {
    return (
      <MediaSDS
        sourceType={contentType}
        videoSource={url}
        showVideoControls={true}
        maxHeight={600}
        altText={description}
      />
    );
  }
  onError?.(`Unsupported Mdea type ${contentType}`);
  return null;
};
