import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import type { Document } from '@contentful/rich-text-types';
import type { FC } from 'react';

/**
 * Returns just the text from the RichText. This is useful if you want to get the text value without
 * any wrapping elements like <p>s or <span>s.
 *
 * If you do want rendered HTML, use <HtmlRichText document={field.json} />.
 */
export const PlainRichText: FC<{ document: string | Record<string, unknown> | Document }> = ({
  document,
}) => {
  if (typeof document === 'string') {
    return <>{document}</>;
  }

  return <>{documentToPlainTextString(document as unknown as Document)}</>;
};

PlainRichText.displayName = 'PlainRichText';
