import type { FC } from 'react';
import { useCallback, useState } from 'react';

import { Button } from '../../Button';
import type { DropdownItem, DropdownMenuButtonProps } from '../../DropdownMenu';
import { DropdownMenu } from '../../DropdownMenu';
import type { IconName } from '../../Icon';
import { Icon } from '../../Icon';
import type { TogglePanelItem } from '../../TogglePanel';
import { TogglePanel } from '../../TogglePanel';
import type { FieldMetadata } from '../types';
import {
  headerRowCss,
  mainControlsCss,
  partitionSelectorCss,
  vizFilterContainerCss,
  vizTitleCss,
} from './styles';
import type { ChartMetadata, VisualizationMeta } from './types';
import { VisualizationKey } from './types';

const getButtonIconsFromMetas = (visualizations: VisualizationMeta[]): IconName[] =>
  visualizations.map(visualization => {
    switch (visualization.key) {
      case VisualizationKey.TABLE:
        return 'list';
      case VisualizationKey.LINE_CHART:
        return 'transition-curve-up';
      case VisualizationKey.BAR_CHART:
        return 'align-bottom';
      case VisualizationKey.GEO_MAP:
        return 'globe';
      default:
        return 'chart';
    }
  });

const getPanelItemsFromMetas = (visualizations: VisualizationMeta[]): TogglePanelItem[] => {
  return visualizations.map(viz => {
    // Only add tooltip if there are multiple viz of the same type to avoid confusion
    const areMultipleOfThisType = visualizations.filter(v => v.key === viz.key).length > 1;
    return {
      tooltip: areMultipleOfThisType ? viz.title : undefined,
      id: viz.id,
    };
  });
};

type MultiVisualizationProps = {
  chartMetadataItems: ChartMetadata[];
  selectedChartMetadata: ChartMetadata;
  visualizationMetadataItems: VisualizationMeta[];
  selectedVisualizationMetadata: VisualizationMeta;
  fieldMetadataItems?: FieldMetadata[];
  visualizationTitle?: string;
  onDataSelected: (chartData: ChartMetadata) => void;
  onVisualizationSelected: (visualization: VisualizationMeta) => void;
  onFieldSelected: (field: FieldMetadata) => void;
  renderVisualization: (visualization: VisualizationMeta) => void;
};

export const MultiVisualization: FC<MultiVisualizationProps> = ({
  chartMetadataItems,
  selectedChartMetadata,
  visualizationMetadataItems,
  selectedVisualizationMetadata,
  fieldMetadataItems,
  visualizationTitle,
  onDataSelected,
  onVisualizationSelected,
  onFieldSelected,
  renderVisualization,
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

  const onFieldTabSelected = useCallback(
    (index: number) => {
      if (!fieldMetadataItems) return;
      setSelectedTabIndex(index);
      onFieldSelected(fieldMetadataItems[index]!);
    },
    [fieldMetadataItems, onFieldSelected]
  );

  const dropdownItems: DropdownItem[] = chartMetadataItems.map((data, index) => ({
    id: `chart-data-item-${index}`,
    title: data.label,
    onClick: () => onDataSelected(data),
  }));

  // TODO: This component should just render props.children instead of
  // using the selected chart label.
  const PartitionSelectionButton: FC<DropdownMenuButtonProps> = props => {
    return (
      <button onClick={props.onClick} className={partitionSelectorCss}>
        {selectedChartMetadata.label}
        <Icon name={props.isExpanded ? 'chevron-up' : 'chevron-down'} />
      </button>
    );
  };

  return (
    <>
      <div className={headerRowCss}>
        <h3 className={vizTitleCss}>{visualizationTitle}</h3>
        {/* Dropdown should only be visible if there are multiple chart data to choose from */}
        {chartMetadataItems.length > 1 && (
          <DropdownMenu buttonComponent={PartitionSelectionButton} items={dropdownItems} />
        )}
      </div>
      <div className={mainControlsCss}>
        <div className={vizFilterContainerCss}>
          {/* Chart selector should only be visible if there are multiple charts given */}
          {visualizationMetadataItems.length > 1 && (
            <TogglePanel
              id="multi-visualization-toggle-panel"
              buttonIcons={getButtonIconsFromMetas(visualizationMetadataItems)}
              items={getPanelItemsFromMetas(visualizationMetadataItems)}
              onButtonToggle={(index: number) =>
                onVisualizationSelected(visualizationMetadataItems[index]!)
              }
              selectedId={`${selectedVisualizationMetadata.id}`}
              hasToggleSlider={false}
              sliderChecked={false}
            />
          )}
        </div>
        {/* Fields selector is only visible if fields are explicity specified AND we are not visualizing a table (Table
        shows all fields regardless) */}
        {fieldMetadataItems && selectedVisualizationMetadata.key !== VisualizationKey.TABLE && (
          <div>
            {fieldMetadataItems.map((field, index) => (
              <Button
                key={`multi-viz-field-${index}`}
                size="Compact"
                type={selectedTabIndex !== index ? 'Primary' : 'Secondary'}
                onClick={() => onFieldTabSelected(index)}
              >
                {field.title}
              </Button>
            ))}
          </div>
        )}
      </div>

      {renderVisualization(selectedVisualizationMetadata)}
    </>
  );
};
