import { cx } from '@emotion/css';
import type { KeyboardEvent, PropsWithChildren } from 'react';
import { forwardRef } from 'react';

import { MotifComponent, useMotifStyles } from '../../motif';
import type { BaseComponentProps } from '../../types';
import { definitionActiveCss, definitionCss } from './Definition.styles';

interface DefinitionProps extends PropsWithChildren<BaseComponentProps> {
  isActive: boolean;
  anchorId?: string;
  onClick?: () => void;
}

export const Definition = forwardRef<HTMLButtonElement, DefinitionProps>(
  ({ anchorId, isActive, onClick, className, children }, ref) => {
    useMotifStyles(MotifComponent.DEFINITION);

    const handleKeyDown = (e: KeyboardEvent) => {
      if (['Enter', ' '].includes(e.key)) {
        e.preventDefault(); // Prevent browser page-down action on spacebar press
        onClick?.();
      }
    };

    // NOTE: We need to use <span> instead of <button> so that longer text wraps correctly as inline text
    return (
      <span
        role="button"
        tabIndex={0}
        id={anchorId}
        ref={ref}
        onClick={onClick}
        onKeyDown={handleKeyDown}
        className={cx(
          MotifComponent.DEFINITION,
          definitionCss,
          {
            [definitionActiveCss]: isActive,
          },
          className
        )}
      >
        {children}
      </span>
    );
  }
);
