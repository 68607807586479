import { cx } from '@emotion/css';
import type { FC } from 'react';

import { BackgroundColor } from '../../../constants/backgroundColor';
import { getBackgroundClassName } from '../../../utils';
import {
  highlightCardDesktopCss,
  highlightCtaClassName,
  highlightDesktopCss,
  highlightMediaDesktopClassName,
  highlightTextBodyClassName,
  highlightTextClassName,
  highlightTextTitleClassName,
} from './GlobalNavHighlight.styles';
import type { GlobalNavHighlightProps } from './types';

export const GlobalNavHighlightDesktop: FC<GlobalNavHighlightProps> = ({
  background,
  callToAction,
  cardTitleV2,
  cardBodyV2,
  cardTitle,
  cardBody,
}) => {
  return (
    <section className={highlightDesktopCss}>
      <figure className={highlightMediaDesktopClassName}>{background}</figure>
      <article
        className={cx(getBackgroundClassName(BackgroundColor.White), highlightCardDesktopCss)}
      >
        <figcaption className={highlightTextClassName}>
          <label className={highlightTextTitleClassName}>{cardTitleV2 ?? cardTitle}</label>
          <p className={highlightTextBodyClassName}>{cardBodyV2 ?? cardBody}</p>
        </figcaption>
        <div className={highlightCtaClassName}>{callToAction}</div>
      </article>
    </section>
  );
};
GlobalNavHighlightDesktop.displayName = 'GlobalNavHighlightDesktop';
