import { cx } from '@emotion/css';
import noop from 'lodash-es/noop';
import startCase from 'lodash-es/startCase';
import type { FC } from 'react';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';

import { MotifComponent, useMotifStyles } from '../../motif';
import type { BaseComponentProps } from '../../types';
import { DropdownButton as DefaultDropdownButton } from './DropdownButton';
import {
  dropdownContainerCss,
  dropdownMenuCss,
  getVerticalDirectionStyle,
  menuLeftCss,
  menuRightCss,
} from './DropdownMenu.styled';
import { DropdownMenuItem } from './DropdownMenuItem';
import type { DropdownItem, DropdownItemProps, DropdownMenuProps } from './types';
import { dropdownMenuClassName } from './utils';

interface DropdownOverlayProps extends BaseComponentProps {
  items: DropdownItemProps[];
  onItemClick: (item: DropdownItem) => void;
}

/** Overlay that contains the menu options. */
const DropdownOverlay = forwardRef<HTMLDivElement, DropdownOverlayProps>(
  ({ items, onItemClick, className }, ref) => {
    return (
      <div
        ref={ref}
        className={cx(dropdownMenuCss, dropdownMenuClassName, className)}
        role="listbox"
      >
        {items.map(({ id, title, onClick, isSelected }) => (
          <DropdownMenuItem
            key={id}
            id={id}
            isSelected={isSelected}
            title={title ?? id}
            onClick={clickEvent => {
              onClick?.(clickEvent);
              onItemClick({ id, title });
            }}
          />
        ))}
      </div>
    );
  }
);

/** Component that renders a button and when that button is clicked shows a menu. */
// TODO: Make this fully a11y by following https://www.w3.org/WAI/ARIA/apg/patterns/combobox/examples/combobox-select-only/
// TODO: Currently keyboard nav only kind of works. Arrow key functionality is missing.
export const DropdownMenu: FC<DropdownMenuProps> = ({
  items,
  selectedItemId,
  placeholderText = 'Select',
  onVisibleChange = noop,
  onItemSelect,
  className,
  buttonComponent,
  ariaLabel,
  ...passThrough
}) => {
  useMotifStyles(MotifComponent.DROPDOWN_MENU);
  const [selectedItem, setSelectedItem] = useState<DropdownItem | undefined>();

  const [isVisible, setIsVisible] = useState(false);
  /** We use this ref to fire the onVisibleChange in a useEffect */
  const prevVisible = useRef(isVisible);
  /** Ref for the container that the menu will be positioned absolutely to */
  const containerRef = useRef<HTMLDivElement>(null);
  /** Ref for the dropdown menu */
  const menuRef = useRef<HTMLDivElement>(null);
  /**
   * Ref to say whether or not we should render down or not. -1 means just render down, any other
   * value is the height of dropdown CTA, so it can render on top of it.
   */
  const renderDownRef = useRef<number>(-1);
  /**
   * Whether or not to render align left or right. default to render left, but on mobile sometimes
   * you'll have to render right (need logic for both though for RTL).
   */
  const renderRightRef = useRef<boolean>(false);

  // Use this to determine whether to align left or right.
  // We don't do this in the onClick because the menu isn't rendered yet.
  // There doesn't seem to be a flash of unstyled so... should be ok
  useEffect(() => {
    if (!isVisible || isVisible === prevVisible.current) {
      return;
    }

    if (menuRef.current) {
      const menuRect = menuRef.current.getBoundingClientRect();

      // These two if statements only fire when the current direction is incorrect
      // and causing the menu to render off the screen.

      // if its off the right side of the screen and rendering left, swap to render right
      if (!renderRightRef.current && menuRect.right > window.innerWidth) {
        renderRightRef.current = true;
        menuRef.current.style.right = '0';
        // We need to unset left here, so the inline style can override the style set
        // by the class.
        menuRef.current.style.left = 'unset';
      }

      // if its off the left side and rendering right, swap to render left.
      if (renderRightRef.current && menuRect.left < 0) {
        renderRightRef.current = false;
        // We technically don't need to unset here since left takes priority over right,
        // but doing it anyway for clarity
        menuRef.current.style.right = 'unset';
        menuRef.current.style.left = '0';
      }
    }
  }, [isVisible]);

  // Fire onVisibleChange whenever it changes.
  // Changed to do it in a useEffect because we were getting a warning
  // for calling a setState in another setState if it put it in the onClick
  useEffect(() => {
    if (prevVisible.current === isVisible) return;

    onVisibleChange(isVisible);
    prevVisible.current = isVisible;
  }, [isVisible, onVisibleChange]);

  // Adds body listener when visible to close on click on anything
  // that is not the container or menu itself.
  // It will also remove the listener on cleanup.
  useEffect(() => {
    if (!isVisible) return;

    const bodyClickListener = (ev: MouseEvent) => {
      if (!(ev.target instanceof Element)) return;

      if (ev.target && containerRef.current?.contains(ev.target)) {
        return;
      }

      setIsVisible(false);
    };

    document.body.addEventListener('click', bodyClickListener);

    return () => {
      document.body.removeEventListener('click', bodyClickListener);
    };
  }, [isVisible]);

  const onClickWithGetPosition = useCallback(() => {
    const containerRect = containerRef.current?.getBoundingClientRect();

    const totalScrollHeight = document.documentElement.scrollHeight;
    const windowScrollPosition = window.scrollY;

    if (
      containerRect?.bottom &&
      totalScrollHeight - windowScrollPosition - containerRect.bottom < window.innerHeight / 2
    ) {
      renderDownRef.current = containerRect.height;
    } else {
      renderDownRef.current = -1;
    }

    setIsVisible(prevState => {
      return !prevState;
    });
  }, [setIsVisible, containerRef]);

  const DropdownButton = buttonComponent ?? DefaultDropdownButton;

  // This one is inlined because we need the dynamic value of the containers height
  const verticalDirectionStyle = getVerticalDirectionStyle(renderDownRef.current);

  const onItemClick = useCallback(
    (item: DropdownItem) => {
      setSelectedItem(item);
      setIsVisible(false);
      onItemSelect?.(item);
    },
    [onItemSelect]
  );

  const activeSelectedItem = selectedItemId
    ? items.find(item => item.id === selectedItemId)
    : selectedItem;

  return (
    <div
      ref={containerRef}
      className={cx(MotifComponent.DROPDOWN_MENU, dropdownContainerCss, className)}
      {...passThrough}
    >
      <DropdownButton isExpanded={isVisible} onClick={onClickWithGetPosition} ariaLabel={ariaLabel}>
        {activeSelectedItem
          ? activeSelectedItem?.title ?? startCase(activeSelectedItem?.id)
          : placeholderText}
      </DropdownButton>
      {isVisible && (
        // TOD: MOve the overlay out as well. Should be use as separate component.
        <DropdownOverlay
          ref={menuRef}
          items={items.map(item => ({
            ...item,
            isSelected: activeSelectedItem?.id === item.id,
          }))}
          onItemClick={onItemClick}
          className={cx(
            renderRightRef.current ? menuRightCss : menuLeftCss,
            verticalDirectionStyle
          )}
        />
      )}
    </div>
  );
};
