const range = (start: number, end: number) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, index) => start + index);
};

/** Function to generate a list of pagination items e.g. itemList = [ 1, [2,3], 4, 5, 6, [7,8,9], 10] */
export const getPaginationItems = (
  totalPages: number,
  currentPage: number
): Array<number | number[]> => {
  // Number of always visible pages at the beginning and end
  const boundaryCount = 1;

  // Number of always visible pages before and after the current page
  const siblingCount = 0;

  // Start of the sibling range
  const siblingsStart = Math.max(
    Math.min(
      currentPage - siblingCount,
      // totalPages - page at beginning - sibilings for each side - current page
      totalPages - boundaryCount - siblingCount * 2 - 1
    ),
    // Greater than pages that should display at the beginning
    boundaryCount + 2
  );

  // End of the sibling range
  const siblingsEnd = Math.min(
    Math.max(
      currentPage + siblingCount,
      // upper boundary pages + sibilings for each side + current page + last page
      boundaryCount + siblingCount * 2 + 2
    ),
    // Less than pages that should display at the end
    totalPages - boundaryCount - 1
  );

  const hasStartEllipsis = siblingsStart > boundaryCount + 2;
  const hasEndEllipsis = siblingsEnd < totalPages - boundaryCount - 1;

  const pageBeforeEllipses =
    boundaryCount + 1 < totalPages - boundaryCount ? boundaryCount + 1 : [];
  const pageAfterEllipses =
    totalPages - boundaryCount > boundaryCount ? totalPages - boundaryCount : [];

  // range starts from first page + boundaryCount and stops before siblingsStart starts
  const leftEllipsisRange = range(1 + boundaryCount, siblingsStart - 1);
  // range starts after siblingsEnd ended and stops before last page - boundaryCount
  const rightEllipsisRange = range(siblingsEnd + 1, totalPages - boundaryCount);

  return [
    1,
    hasStartEllipsis ? leftEllipsisRange : pageBeforeEllipses,
    ...range(siblingsStart, siblingsEnd),
    hasEndEllipsis ? rightEllipsisRange : pageAfterEllipses,
    totalPages,
  ];
};
