import { cx } from '@emotion/css';
import type { FC } from 'react';

// TODO: This is wrong. Shouldn't be importing styles from the Block!!!
import {
  contentContainerCss,
  contentContainerStyle1ColumnCss,
  contentContainerStyle2ColumnCss,
  contentContainerStyle3ColumnCss,
} from '../Block/Block.styled';
import { tabsPanelsCss } from './styles';
import type { TabPanelsItemProps } from './types';

const contentContainerStyles = {
  1: contentContainerStyle1ColumnCss,
  2: contentContainerStyle2ColumnCss,
  3: contentContainerStyle3ColumnCss,
} as Record<number, string>;

export interface TabsPanelProps {
  items: Array<TabPanelsItemProps>;
  selectedTab: number;
}

export const TabsPanels: FC<TabsPanelProps> = ({ items, selectedTab }) => {
  return (
    <div className={tabsPanelsCss}>
      {items.map((item, index) => (
        <div
          key={`tabPanel-${index}`}
          role="tabpanel"
          className={cx(contentContainerCss, contentContainerStyles[item.maxColumns ?? 3])}
          style={selectedTab !== index ? { display: 'none' } : undefined}
        >
          {item.content.map(content => content)}
        </div>
      ))}
    </div>
  );
};
