import { BroadcastEventType } from './pubSub.constants';

export const getContentfulWebhookEventKey = (
  spaceId?: string,
  environmentId?: string,
  objectId?: string
): string | undefined => {
  if (!spaceId || !environmentId || !objectId) return undefined;
  return [BroadcastEventType.CONTENTFUL_WEBHOOK, spaceId, environmentId, objectId].join('_');
};

export const getBitmojiReactionEventKey = (videoId?: string): string | undefined => {
  if (!videoId) return undefined;
  return `${BroadcastEventType.BITMOJI_REACTION}_${videoId}`;
};
