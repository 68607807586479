import { createContext, useContext } from 'react';

import type { MotifComponent } from './motifConstants';
import { getMotifName, injectStyles } from './motifUtils';

export interface MotifContextProps {
  /**
   * Set of keys used when injecting motif styles into the environment.
   *
   * The idea behind keeping these in that it's easier than hashing stylesheets and more versatile
   * when it comes to resetting between renders (i.e. during SSR)
   */
  injectedStyles: Set<string>;
}

const defaultContext: MotifContextProps = {
  injectedStyles: new Set<string>(),
};

/**
 * Context for rendering motifs.
 *
 * Useful for isolating renders of the app in static environments or when there's overlap.
 *
 * I.e. This will re-inject necessary styles.
 *
 *     <MotifContext.Provider value={{ injectedStyles: new Set() }}>
 *       <App />
 *     </MotifContext.Provider>;
 */
export const MotifContext = createContext(defaultContext);

/** Helper hook for using motifs. */
export function useMotifStyles(component: MotifComponent): void {
  const motifName = getMotifName();
  const { injectedStyles } = useContext(MotifContext);
  const key = `${motifName}-${component}`;

  if (!injectedStyles.has(key)) {
    injectedStyles.add(key);
    injectStyles(component);
  }
}
