import { cx } from '@emotion/css';
import type { FC } from 'react';
import { useEffect } from 'react';

import { IconButton } from '../IconButton';
import { Media } from '../Media';
import { Modal } from '../Modal/Modal';
import {
  lightBoxCaptionCss,
  lightBoxContainerCss,
  lightBoxControlsCss,
  lightBoxCss,
  lightboxNavLeftIconCss,
  lightboxNavRightIconCss,
  lightBoxVideoCss,
} from './styles';
import type { MosaicLightBoxProps } from './types';

export const MosaicLightBox: FC<MosaicLightBoxProps> = ({
  tile,
  handleKeyPress,
  goNext,
  goPrevious,
  close,
  className,
}) => {
  useEffect(() => {
    if (tile?.onOpen) {
      tile.onOpen();
    }
  }, [tile]);

  if (!tile || !tile.video) return null;

  return (
    <Modal
      contentClassName={lightBoxContainerCss}
      isBlocking={false}
      isDisplayed={!!tile}
      disableBackgroundScroll={true}
      onClose={close}
    >
      <figure
        data-test-id="sdsm-mosaic-lightbox"
        className={cx(lightBoxCss, className)}
        key={`${tile.video.source}-${tile.title}-${tile.highlight}`}
      >
        <Media
          loop={true}
          className={lightBoxVideoCss}
          {...tile.video}
          showVideoControls={true}
          autoplay={true}
        />
        <div data-test-id="sdsm-mosaic-lightbox-controls" className={lightBoxControlsCss}>
          <IconButton
            iconName="chevron-up"
            iconClassName={lightboxNavLeftIconCss}
            onClick={goPrevious}
            onKeyDown={handleKeyPress}
          />

          <figcaption className={lightBoxCaptionCss}>{tile.title}</figcaption>

          <IconButton
            iconName="chevron-down"
            iconClassName={lightboxNavRightIconCss}
            onClick={goNext}
            onKeyDown={handleKeyPress}
          />
        </div>
      </figure>
    </Modal>
  );
};
