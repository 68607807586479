import { gql } from '@apollo/client';

import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';
import type { MediaDataProps } from '../Media';
import { fragments as mediaFragments } from '../Media';

export const imageButtonFragment = gql`
  fragment ImageButtonAll on ImageButton {
    ...ContentfulSysId
    url
    height
    width
    image {
      ...AssetAll
    }
  }

  ${contentfulSysIdFragment}
  ${mediaFragments.all}
`;

export const query = gql`
  query ImageButtonQuery($preview: Boolean!, $locale: String!, $id: String!) {
    imageButton(preview: $preview, locale: $locale, id: $id) {
      ...ImageButtonAll
    }
  }

  ${imageButtonFragment}
`;

export interface ImageButtonData {
  imageButton: {
    url: string;
    image: MediaDataProps;
    height: number;
    width: number;
  };
}
