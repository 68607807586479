import type { DocumentNode } from '@apollo/client/core';
import type { OperationDefinitionNode } from 'graphql';

/** Returns name of an apollo query for debugging. */
export function getQueryName(query: DocumentNode): string {
  const sourceName = query.loc?.source.name;
  const definitionName = query.definitions
    .filter(def => def.kind === 'OperationDefinition')
    .map(def => def as OperationDefinitionNode)
    .find(def => def.operation === 'query')?.name?.value;

  return definitionName ?? sourceName ?? 'Anonymous';
}
