import { css } from '@emotion/css';

export const debugPortalCss = css`
  position: fixed;
  right: 0;
  bottom: 0;
  border: 1px black solid;
  background-color: white;
  padding: 4px;
  font-family: monospace, sans-serif;
  font-size: 10px;
  z-index: 999; /* To render over other things */
`;
