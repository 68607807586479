import { useImperativeEffect } from '@snapchat/core-browser';
import pick from 'lodash-es/pick';
import type { FC } from 'react';

import { logEvent, logTiming, logValue, SubscribedEventType } from '../../helpers/logging';

/**
 * Logs internal timing events that have to do with the page navigation timing.
 *
 * See: https://developer.mozilla.org/en-US/docs/Web/API/PerformanceNavigationTiming
 */
function logTimingEvents() {
  const windowTimingEntries = performance.getEntriesByType(
    'navigation'
  ) as PerformanceNavigationTiming[];
  const [timing] = windowTimingEntries;
  // The field descriptions are in the spec: https://www.w3.org/TR/navigation-timing-2/
  const keysToLog: Array<keyof PerformanceNavigationTiming> = [
    'connectEnd', // DNS, proxy etc.
    'domComplete', // Dom is laid out.
    'domContentLoadedEventEnd', // Resources loaded.
    'domInteractive', // Scrolling is unlocked.
    'duration', // Total navigation duration.
    'loadEventEnd', // Time of the load event fire.
    'responseEnd', // Time of the server response end.
    'transferSize', // Total data transfered for load request.
  ];

  for (const [eventVariable, eventValue] of Object.entries(pick(timing, keysToLog))) {
    if (typeof eventValue !== 'number') continue;

    if (eventVariable === 'transferSize') {
      logValue({
        eventCategory: 'NavigationTiming',
        eventLabel: `${timing!.type} ${window.location.pathname}`,
        eventVariable, // TODO: Find out if we need to have a sperate metric due to histogram bucketing.

        eventValue,
      });
    } else {
      logTiming({
        eventCategory: 'NavigationTiming',
        eventLabel: `${timing!.type} ${window.location.pathname}`,
        // NOTE: We choose to not rename these fields, so that we conform
        // to the existing web standards.
        eventVariable,
        eventValue,
      });
    }
  }
}

/**
 * Component used to ensure consistent logging of Page Load events. This should be located as close
 * to the top level App and Router components as possible.
 *
 * This avoids issues where a change to a Context value causes a parent component to rerender and
 * fire the page load events multiple times for the same location.
 */
export const LogPageLoad: FC = () => {
  // LEGACY: Log first page load.
  // This is a very bad marker of when the page is loaded, because it only indicates that
  // React has been initialized and has begun rendering. DO NOT USE THIS for analysis.
  // This is only kept around to avoid breaking changes.
  useImperativeEffect(() => {
    logEvent({
      subscribedEventType: SubscribedEventType.FIRST_PAGE_LOAD,
    });
  }, []);

  /*
   * Logging of the web performance based on the Performance Timing API.
   * This is the main way in which we track page load speeds.
   */
  useImperativeEffect(() => {
    if (document.readyState === 'complete') {
      logTimingEvents();
      return;
    }

    const listener = () => {
      // Running the function below in a setTimeout to let 'loadEventEnd' value populate.
      setTimeout(logTimingEvents, 0);
    };

    window.addEventListener('load', listener);

    return () => {
      window.removeEventListener('load', listener);
    };
  }, []);

  return null;
};
