import { css } from '@emotion/css';

import { nonMobileMediaQuery } from '../../constants';
import { m } from '../../motif';

const defaultColor = 'rgb(232, 232, 235)';

export const skeletonWrapperCss = css`
  /** align with dropdown */
  margin-block-start: 17px;
`;

export const skeletonLabelCss = css`
  background-color: ${m('--dropdown-skeleton-animation-default-color')};
  border-radius: ${m('--border-radius-m')};
  height: 6px;
  margin-block-end: ${m('--spacing-xs')};
  width: 51px;
`;

export const skeletonLinesWrapperCss = css`
  align-items: center;
  background-color: ${m('--dropdown-menu-bg-color')};
  border-radius: ${m('--border-radius-m')};
  box-shadow: ${m('--box-shadow-s')};
  color: ${m('--foreground-color')};
  display: flex;
  gap: 6px;
  justify-content: space-between;
  min-width: 160px;
  padding: ${m('--spacing-s')} ${m('--spacing-m')};
`;

export const skeletonLineCss = css`
  background-color: ${m('--dropdown-skeleton-animation-default-color')};
  border-radius: ${m('--border-radius-m')};
  height: 12px;

  &:nth-child(1) {
    flex: 0 0 48px;
  }

  &:nth-child(2) {
    flex: 1 1 100%;
  }

  ${nonMobileMediaQuery} {
    &:nth-child(1) {
      flex: 0 0 28px;
    }
  }
`;

export const skeletonLineAnimationCss = css`
  overflow: hidden;
  position: relative;

  &::before {
    animation: dropdown-skeleton 2s infinite;
    background: linear-gradient(
      0.25turn,
      transparent,
      ${m('--dropdown-skeleton-animation-color')},
      transparent
    );
    content: '';
    height: 100%;
    left: 0;
    opacity: 0.8;
    pointer-events: none;
    position: absolute;
    top: 0;
    transform: translateX(-100%);
    width: 100%;
  }

  @keyframes dropdown-skeleton {
    0% {
      transform: translateX(-100%);
    }
    30% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;

export const skeletonIconWrapperCss = css`
  flex: 0 0 20px;
`;

export const skeletonDropdownIconCss = css`
  fill: ${defaultColor};
  position: relative;
  top: 1px;
  transform: rotate(0deg);
  transition: transform 250ms;
`;
