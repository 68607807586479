import { css, cx } from '@emotion/css';
import {
  h4Css,
  h5Css,
  p1Css,
  p2Css,
  p3Css,
  p4Css,
  primitiveElementClassNames,
} from '@snapchat/snap-design-system-marketing';
import type { FC, PropsWithChildren } from 'react';

/** Standard header sizing. Namely higher line height. */
const headerCss = css`
  && {
    line-height: 160%;
  }
`;

export const NoHeadingH1: FC<PropsWithChildren> = ({ children }) => (
  <div className={cx(primitiveElementClassNames.h1, h4Css, headerCss)}>{children}</div>
);
NoHeadingH1.displayName = 'H1';

export const NoHeadingH2: FC<PropsWithChildren> = ({ children }) => (
  <div className={cx(primitiveElementClassNames.h2, h5Css, headerCss)}>{children}</div>
);
NoHeadingH2.displayName = 'H2';

export const NoHeadingH3: FC<PropsWithChildren> = ({ children }) => (
  <div className={cx(primitiveElementClassNames.h3, p1Css, headerCss)}>{children}</div>
);
NoHeadingH3.displayName = 'H3';

export const NoHeadingH4: FC<PropsWithChildren> = ({ children }) => (
  <div className={cx(primitiveElementClassNames.h4, p2Css, headerCss)}>{children}</div>
);
NoHeadingH4.displayName = 'H4';

export const NoHeadingH5: FC<PropsWithChildren> = ({ children }) => (
  <div className={cx(primitiveElementClassNames.h5, p3Css, headerCss)}>{children}</div>
);
NoHeadingH5.displayName = 'H5';

export const NoHeadingH6: FC<PropsWithChildren> = ({ children }) => (
  <div className={cx(primitiveElementClassNames.h6, p4Css, headerCss)}>{children}</div>
);
NoHeadingH6.displayName = 'H6';
