import { useImperativeEffect } from '@snapchat/core-browser';
import throttle from 'lodash-es/throttle';
import { type FC, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { logValue } from '../../helpers/logging';

/** Scroll Depth breakpoints to log (% of page height) */
const logScrollDepthBreakPoints = [25, 50, 75, 90, 100];

/**
 * Component used to ensure consistent logging of Scroll Depth for a page. Purposely does not
 * distinguish between automated and user-driven scrolling.
 *
 * Only fires scroll depth events once per page - does not retrigger if the user scrolls up and
 * down.
 */
export const LogScrollDepth: FC = () => {
  const { pathname } = useLocation();
  const maxScrollDepth = useRef(0);
  const [currScrollDepth, setCurrScrollDepth] = useState(0);

  // Add throttled event listener to scroll event
  useEffect(() => {
    const listener = throttle(() => {
      const scrollY = window.scrollY;
      const scrollHeight = document.documentElement.scrollHeight - window.innerHeight;
      const scrollDepth = scrollY / scrollHeight;
      const roundedScrollDepth = Math.round(scrollDepth * 100);
      setCurrScrollDepth(roundedScrollDepth);
    }, 500);

    window.addEventListener('scroll', listener, { passive: true });

    return () => {
      window.removeEventListener('scroll', listener);
    };
  }, [setCurrScrollDepth]);

  // reset maxScrollDepth on location change
  useImperativeEffect(() => {
    maxScrollDepth.current = 0;
  }, [pathname]);

  // log scrollDepth
  useImperativeEffect(() => {
    if (currScrollDepth <= maxScrollDepth.current) return;

    for (let i = 0; i < logScrollDepthBreakPoints.length; i++) {
      const breakpoint = logScrollDepthBreakPoints[i]!;
      const nextBreakpoint = logScrollDepthBreakPoints[i + 1];
      const isLastBreakpoint = !nextBreakpoint;

      if (
        maxScrollDepth.current >= breakpoint || // already logged current breakpoint
        currScrollDepth < breakpoint || // does not satisfy current breakpoint
        (!isLastBreakpoint && currScrollDepth >= nextBreakpoint) // satisfies later breakpoint
      )
        continue;

      logValue({
        eventCategory: 'LogScrollDepth',
        eventLabel: `scroll breakpoint: ${breakpoint}%`,
        eventVariable: 'scroll_percentage',
        eventValue: currScrollDepth,
      });
      // early exit - no need to check for other logging scenarios
      break;
    }

    maxScrollDepth.current = currScrollDepth;
  }, [currScrollDepth]);

  // Component has no UI, return null
  return null;
};
