import { useImperativeEffect } from '@snapchat/core-browser';
import { Site } from '@snapchat/mw-common';
import { Yellow } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import { Config } from '../../config';
import { snapHeaderBackgroundVar } from '../../sites/snap/styleConstants';

export const StyleHacks: FC = () => {
  useImperativeEffect(() => {
    switch (Config.site) {
      case Site.SNAP:
        // HACK: We need to add a default override for the header background color for future snap.com
        // we modified the body on snap home this needs to be set on the body element
        // see snapConfig.ts for more details DONT TOUCH please :)
        document.body.parentElement?.style.setProperty(snapHeaderBackgroundVar, Yellow.V100);
    }
  }, []);

  return null;
};
