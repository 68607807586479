import { FooterGroup as FooterGroupSDS, Orientation } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import { FooterItemV3 } from '../FooterItemV3/FooterItemV3';
import type { FooterGroupProps } from '../FooterV3Types';

export const FooterGroup: FC<FooterGroupProps> = ({
  id,
  title,
  items,
  onClick,
  orientation = Orientation.Vertical,
  isFooterBar,
  beforeItems,
  afterItems,
}) => {
  if (!items?.length && !beforeItems && !afterItems) {
    return null;
  }

  const itemsToRender = items?.map((item, i) => {
    return (
      <FooterItemV3
        // ok to use index here because we should never be dynamically adding or removing footer items
        key={item.id || i}
        id={item.id}
        url={item.url}
        title={item.title}
        analyticsLabel={item.analyticsLabel}
      />
    );
  });

  return (
    <FooterGroupSDS
      isFooterBar={isFooterBar}
      id={id}
      title={title}
      onClick={onClick}
      orientation={orientation}
    >
      {beforeItems}
      {...itemsToRender}
      {afterItems}
    </FooterGroupSDS>
  );
};
