import { createContext } from 'react';

import type { ReplacementMap } from '../Experiment';

interface SlugContextProps {
  isShareable: boolean;
  publishedAt?: Date;
  postedDate?: Date;
  replacements?: ReplacementMap;
}

export const SlugContext = createContext<SlugContextProps>({
  isShareable: false,
});
