import type { Gallery as GalleryType } from '@snapchat/mw-contentful-schema';
import {
  GalleryFiltersV2 as GalleryFiltersV2SDS,
  Pagination,
} from '@snapchat/snap-design-system-marketing';
import cloneDeep from 'lodash-es/cloneDeep';
import omit from 'lodash-es/omit';
import pick from 'lodash-es/pick';
import type { FC } from 'react';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { AppContext } from '../../AppContext';
import { Config } from '../../config';
import { getContentfulInspectorProps } from '../../utils/contentful/getContentfulInspectorProps';
import { ConsumerContext } from '../ConsumerContextProvider';
import { totalHeaderHeightCssVar } from '../Header/headerSizeUtils';
import { defaultTilesPerPage, galleryTagsPrefix } from './constants';
import { LazyTiles } from './LazyTiles';
import type { GalleryDataProps } from './query';
import { galleryWrapperCss } from './styles';
import { useGalleryTiles } from './useGalleryTiles';
import { buildSearchMenus } from './utils';

type GalleryProps = {
  gallery: Omit<GalleryDataProps, 'tilesCollection'>;
};

const handleScroll = (gallery: HTMLDivElement | null) => {
  // Using requestAnimationFrame to avoid forced reflow  and solve inconsistencies with smooth scroll.
  window.requestAnimationFrame(() => {
    const totalHeaderHeight = Number.parseInt(
      getComputedStyle(document.documentElement).getPropertyValue(totalHeaderHeightCssVar),
      10
    );

    const galleryTop = gallery?.getBoundingClientRect().top ?? 0;
    const scrollY = window.scrollY;

    // Adding totalHeaderHeight to account the height of the header when sticky,
    // plus 20px to make some white space between the top of the element and the header.
    window.scrollTo({ top: galleryTop + scrollY - (totalHeaderHeight + 20), behavior: 'smooth' });
  });
};

export const Gallery: FC<GalleryProps> = props => {
  const { gallery } = props;
  const galleryId = gallery.sys.id;

  const { getUrlParams, setUrlParams } = useContext(ConsumerContext);
  const { onRedirect, getCurrentUrl } = useContext(AppContext);

  const query = getUrlParams?.() ?? {};
  const [page, setPage] = useState(gallery.hidePagination ? 1 : Number(query?.page ?? 1));

  const dropdownFilters = useMemo(() => {
    if (gallery?.dropdownsCollection) {
      return buildSearchMenus(gallery.dropdownsCollection.items, true);
    }

    return undefined;
  }, [gallery]);

  const dropdownFiltersIds = useMemo(() => {
    if (!dropdownFilters) {
      return [];
    }

    return dropdownFilters.map(dropdown => dropdown.id);
  }, [dropdownFilters]);

  const [tilesTags, setTilesTags] = useState(pick(query, dropdownFiltersIds));

  // Handle back button click
  useEffect(() => {
    const searchParams = getUrlParams?.() ?? {};
    const newFilters = pick(searchParams, dropdownFiltersIds);
    const newPage = Number(searchParams?.page ?? 1);
    setTilesTags(newFilters);
    setPage(newPage);
  }, [dropdownFiltersIds, getUrlParams]);

  const { paginationData, isLoading } = useGalleryTiles({
    galleryId,
    page,
    pageSize: gallery?.itemsPerPage,
    tags: Object.values(tilesTags),
    sortBy: gallery?.sortBy,
  });

  const galleryRef = useRef<HTMLDivElement>(null);

  const onChangeFilter = useCallback(
    (filterKey: string, filterValue: string | undefined) => {
      if (!setUrlParams) {
        return;
      }

      let newFilters = cloneDeep(tilesTags);
      const currentQuery = getUrlParams?.() ?? {};
      const otherParams = omit(currentQuery, dropdownFiltersIds);

      if (filterValue) {
        newFilters[filterKey] = filterValue;
      } else {
        newFilters = omit(newFilters, filterKey);
      }
      setTilesTags(newFilters);
      setPage(1);
      setUrlParams({ ...otherParams, ...newFilters, page: '1' });
    },
    [dropdownFiltersIds, getUrlParams, setUrlParams, tilesTags]
  );

  const onChangePage = useCallback(
    (newPage: number) => {
      if (!setUrlParams) {
        return;
      }

      const currentQuery = getUrlParams?.() ?? {};
      const newUrlParams = cloneDeep(currentQuery);
      newUrlParams.page = `${newPage}`;
      setPage(newPage);
      setUrlParams(newUrlParams);
      handleScroll(galleryRef.current);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getUrlParams, setUrlParams, galleryRef.current] // We need the rebuild onChangePage when galleryRef.current gets assigned
  );

  const clearFilters = useCallback(() => {
    if (!setUrlParams) {
      return;
    }

    const currentQuery = getUrlParams?.() ?? {};
    const otherParams = omit(currentQuery, dropdownFiltersIds);
    setPage(1);
    setTilesTags({});
    setUrlParams({ ...otherParams, page: '1' });
  }, [dropdownFiltersIds, getUrlParams, setUrlParams]);

  const { mobileFiltersWrapperTextDataset, clearFiltersCtaCopyDataset, emptyPlaceholderDataset } =
    getContentfulInspectorProps<GalleryType>({
      entryId: galleryId,
      fieldIds: [
        'emptyPlaceholder',
        'loadMoreCtaCopy',
        'mobileFiltersWrapperText',
        'clearFiltersCtaCopy',
      ],
    });

  if (onRedirect && Config?.redirects?.queryRedirects) {
    const queryRedirectsKeys = new Set(Object.keys(Config.redirects.queryRedirects));
    const queryKeys = Object.keys(query);
    const hasMatch = queryKeys.some(key => queryRedirectsKeys.has(key));

    if (hasMatch) {
      const requestUrl = new URL(getCurrentUrl());
      const url = new URL(`${requestUrl.protocol}//${requestUrl.host}${requestUrl.pathname}`);

      for (const [key, value] of Object.entries(query)) {
        if (queryRedirectsKeys.has(key)) {
          url.searchParams.set(`${galleryTagsPrefix}${key}`, String(value));
          continue;
        }

        url.searchParams.set(key, String(value));
      }

      // Copy the hash/fragment (thing after #)
      url.hash = requestUrl.hash;

      onRedirect(`${url.pathname}${url.search}${url.hash}`, { newTab: false });
      return null;
    }
  }

  return (
    <div data-test-id="mwp-lazy-gallery" ref={galleryRef} className={galleryWrapperCss}>
      {dropdownFilters && !gallery.hideFilters ? (
        <GalleryFiltersV2SDS
          searchMenus={dropdownFilters}
          selectedFilters={tilesTags}
          onChangeFilter={(filterKey, filterValue) => onChangeFilter(filterKey, filterValue)}
          onClearFilters={clearFilters}
          clearButtonLabel={gallery?.clearFiltersCtaCopy}
          mobileFiltersToggleLabel={gallery?.mobileFiltersWrapperText}
          mobileFiltersWrapperTextDataset={mobileFiltersWrapperTextDataset}
          clearFiltersCtaCopyDataset={clearFiltersCtaCopyDataset}
        />
      ) : null}
      <LazyTiles
        emptyPlaceholder={gallery.emptyPlaceholder}
        tiles={paginationData?.tiles}
        showDates={gallery.showDates}
        showDescriptions={gallery.showDescriptions}
        showMedia={gallery.showMedia}
        emptyPlaceholderDataset={emptyPlaceholderDataset}
        isGalleryLoading={isLoading}
        defaultTileImage={gallery.defaultImage ?? undefined}
        numberOfTiles={gallery?.itemsPerPage ?? defaultTilesPerPage}
      />
      {paginationData?.total && !gallery.hidePagination ? (
        <Pagination
          totalPages={
            Math.floor(
              (paginationData.total - 1) / (gallery?.itemsPerPage ?? defaultTilesPerPage)
            ) + 1
          }
          currentPage={page}
          onChange={onChangePage}
        />
      ) : null}
    </div>
  );
};

Gallery.displayName = 'Gallery';
