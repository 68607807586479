import type { BaseComponentProps } from '../../types';
import type { IconName } from '../Icon';
import type { VideoProps } from '../Video/Video';

export interface MediaSource {
  src: string;
  srcSet: string;
}

export interface MosaicTileProps {
  video?: VideoProps;
  preview?: VideoProps;
  poster: MediaSource;
  highlight?: string;
  title: string;
  duration?: string;
  columns?: number;
  mobileColumns?: number;
  rows?: number;
  mobileRows?: number;
  cover?: boolean;
  showOverlay?: boolean;
  filters?: string[];
  onEnter?: () => void;
  onLeave?: () => void;
  onOpen?: () => void;
  onClose?: () => void;
}

export interface MosaicFilterProps {
  icon: IconName;
  text?: string;
  id: string;
}

export interface MosaicProps extends BaseComponentProps {
  tiles: Array<MosaicTileProps>;
  /** @deprecated Filters should be applied on another level. */
  filters?: Array<MosaicFilterProps>;
  columns?: number;
  mobileColumns?: number;
  dense?: boolean;
  showOverlay?: boolean;
  lightboxClassName?: string;
}
export interface MosaicLightBoxProps {
  tile?: MosaicTileProps;
  handleKeyPress: (event: React.KeyboardEvent) => void;
  goNext: () => void;
  goPrevious: () => void;
  close: () => void;
  className?: string;
}

export interface MosaicFiltersProps {
  filters: MosaicFilterProps[];
  currentFilter: string;
  onSelectFilter: (id: string) => void;
}
