import { css } from '@emotion/css';

import { fadeInKeyframes, fadeOutKeyframes } from '../../constants/animation';

export const detailsSummaryAnimationDurationCssVar = '--animation-duration';

export const detailsCss = css`
  & > summary {
    /* Prevents selection when rapidly expanding and collapsing the content */
    user-select: none;

    cursor: pointer;
  }

  scroll-margin: 40px;

  /* Prevents keyboard selection of focusable elements that the panel is collapsed. */
  &[data-state='off'] > summary + * {
    visibility: hidden;
  }
`;

export const detailsAnimationCss = css`
  &[data-state='turning-on'] > summary + * {
    animation: ${fadeInKeyframes} var(${detailsSummaryAnimationDurationCssVar}) ease-out;
  }

  &[data-state='turning-off'] > summary + * {
    animation: ${fadeOutKeyframes} var(${detailsSummaryAnimationDurationCssVar}) ease-out;
  }
`;

export const chevronCss = css`
  justify-self: center;
  width: 12px;
  height: 12px;

  transform: rotate(0deg); /* 'up' */
  transition: transform 250ms;

  details[data-state='on'] > summary > &,
  details[data-state='turning-on'] > summary > & {
    transform: rotate(180deg); /* 'down', ccw */
  }

  *[dir='rtl'] details[data-state='on'] > summary > &,
  *[dir='rtl'] details[data-state='turning-on'] > summary > & {
    transform: rotate(-180deg); /* 'down', clockwise */
  }
  position: absolute;
  right: 0;

  /* stylelint-disable-next-line no-descending-specificity */
  *[dir='rtl'] & {
    right: unset;
    left: 0;
  }
`;

export const summaryCss = css`
  margin: 0;
  position: relative; /* To position the chveron absolutely. */

  /* In webkit the arrow is rendered as a list icon; so we hide that, too. */
  /* We add our own arrow so we need to hid the default one. */
  ::-webkit-details-marker {
    display: none;
  }
  list-style-type: none;

  display: flex;
  align-items: center;
`;
