import { Black, Gray, Plain, White } from '../../constants';
import type { MotifComponent, MotifComponentProps } from '../../motif';
import { m } from '../../motif/m';
const dropdownDefaultVars = {
  '--dropdown-menu-padding': m('--spacing-m'),
  '--dropdown-item-fg-color': m('--action-default-color'),
  '--dropdown-item-fg-hover-color': m('--action-hover-color'),
  '--dropdown-item-fg-active-color': m('--action-active-color'),
};

// Should eventually conver hover to underline
export const dropdownMenuLightVars: MotifComponentProps<MotifComponent.DROPDOWN_MENU> = {
  '--dropdown-menu-bg-color': White,
  '--dropdown-item-bg-hover-color': Gray.V100,
  '--dropdown-item-bg-active-color': Gray.V50,
  '--dropdown-menu-border-color': Gray.V150,

  '--dropdown-skeleton-animation-color': Gray.V150,
  '--dropdown-skeleton-animation-default-color': White,
  ...dropdownDefaultVars,
};

export const dropdownMenuDarkVars: MotifComponentProps<MotifComponent.DROPDOWN_MENU> = {
  '--dropdown-menu-bg-color': Black.V150,
  '--dropdown-item-bg-hover-color': Black.V50,
  '--dropdown-item-bg-active-color': Black.V100,
  '--dropdown-menu-border-color': Plain.BLACK,

  '--dropdown-skeleton-animation-color': Black.V50,
  '--dropdown-skeleton-animation-default-color': Gray.V300,
  ...dropdownDefaultVars,
};
