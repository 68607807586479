import { ChartSkeleton } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import type { ContentfulIdVariable } from '../../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../../hooks/useContentfulQuery';
import { isContentfulSysProps } from '../../../utils/contentful';
import { queries } from './query';
import { Table } from './Table';
import type { TableVisualizationDataHandlerProps, TableVisualizationProps } from './types';

export const TableShallow: FC<TableVisualizationProps> = props => {
  const id = isContentfulSysProps(props) ? props.sys.id : undefined;

  const { data, loading } = useContentfulQuery<
    TableVisualizationDataHandlerProps,
    ContentfulIdVariable
  >(queries.all, {
    skip: !id,
    variables: { id },
  });

  if (loading) return <ChartSkeleton />;
  if (!data) return null;

  // If chart data was passed directly to this chart, use that instead of whatever is on the
  // visualization (this will be the case if this is a child of a Multi Visualization)
  const chartData = props.chartDataOverride ?? data.tableVisualization.chartData;

  return <Table {...data.tableVisualization} chartData={chartData} id={id} />;
};
