import { useEffect } from 'react';

import type { FontFamily } from './fontConstants';
import { fontDefinitionFiles, fontLinkIdFn } from './fontConstants';

/**
 * Loads the SDS font faces definitions.
 *
 * @deprecated Use MotifRoot. This doesn't work in SSR
 */
export function useFontStylesheet(fontFamily: FontFamily): void {
  useEffect(() => {
    const fontStylesheetId = fontLinkIdFn(fontFamily);

    if (document.getElementById(fontStylesheetId)) {
      return;
    }
    const link = document.createElement('link');
    link.id = fontStylesheetId;
    link.rel = 'stylesheet';
    link.href = fontDefinitionFiles[fontFamily];
    document.head.appendChild(link);
  }, [fontFamily]);
}
