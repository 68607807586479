import { lazyComponent } from '@snapchat/mw-common/client';
import { getRawData } from '@snapchat/mw-contentful-schema';
import { ChartSkeleton } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useContext, useEffect, useState } from 'react';

import { AppContext } from '../../../AppContext';
import { logError } from '../../../helpers/logging';
import { customFetch } from '../../../utils/fetch/customFetch';
import { SuspenseWrapper } from '../../SuspenseWrapper';
import type { GeoMapRenderProps } from './types';

const LazyGeoMap = lazyComponent(async () =>
  import('./LazyGeoMap.js').then(module => ({ default: module.GeoMap }))
);

const topographyDataEndpoint =
  'https://storage.googleapis.com/snap-design-system-marketing/world-topo.json';

export const GeoMap: FC<GeoMapRenderProps> = props => {
  const { chartTitle, chartData, width, countryCodeKey, valueKey, dataScalingMethod } = props;

  const [topographyData, setTopographyData] = useState<unknown | undefined>();
  const { currentLocale } = useContext(AppContext);

  // Underlying library requires external topography data
  useEffect(() => {
    async function fetchTopographyData() {
      const res = await customFetch(topographyDataEndpoint);
      const data = await res.json();
      setTopographyData(data);
    }

    fetchTopographyData().catch(error => logError({ component: 'GeoVisualization', error }));
  }, []);

  if (!topographyData || !chartData || !valueKey) return null;

  const rawData = getRawData(chartData);

  return (
    <SuspenseWrapper fallbackElement={<ChartSkeleton />}>
      <LazyGeoMap
        width={width}
        data={rawData}
        fieldMetadata={{
          key: valueKey,
          title: chartData.seriesName.headerNames?.[valueKey] ?? valueKey,
        }}
        countryFieldKey={countryCodeKey}
        chartTitle={chartTitle}
        topoData={topographyData}
        locale={currentLocale}
        scaleMode={dataScalingMethod}
      />
    </SuspenseWrapper>
  );
};
