/* eslint-disable no-misleading-character-class */
// The above rule is disabled beacuse the RTL rangle is OK to match partial characters for our
// purpose of detecting RTL chars.

/**
 * Range for RTL characters. Copied from https://github.com/wooorm/direction.
 *
 * See https://www.ssec.wisc.edu/~tomw/java/unicode.html for explanation
 */
const rtlRange =
  '\u0591-\u07FF' + // Hebrew (0500-05FF), Arabic Letters (0600-06FF) and Syriac (0700-074F)
  '\uFB1D-\uFDFD' + // Hebrew and Arabic Letters and Ligatures, Farsi
  '\uFE70-\uFEFC'; // Arabic Presentation Form B

const rtl = new RegExp(`[${rtlRange}]`, 'g');

/** Detect direction of a string. */
export const direction = (source: string): 'rtl' | 'ltr' => {
  if (!source) {
    return 'ltr';
  }

  return rtl.test(source) ? 'rtl' : 'ltr';
};
