import type { GoogleConfig, GoogleLocation, GtmStartEvent } from '../../types/gtm';

export interface PluginObject {
  name: string;
  options: Record<string, unknown>;
}

const googleTagDataLayerSet = (document: Document, GTMID: string, nonce?: string): void => {
  // We need to configure datalayer with a custom record due to GTM requiring gtm.start
  // https://developers.google.com/tag-platform/tag-manager/web/datalayer
  configureDataLayer({ 'gtm.start': Date.now(), event: 'gtm.js' });
  const createdScriptElement = document.createElement('script');
  createdScriptElement.async = true;
  createdScriptElement.src = `https://www.googletagmanager.com/gtm.js?id=${GTMID}`;

  if (nonce) createdScriptElement.nonce = nonce;

  document.head.appendChild(createdScriptElement);
};

/** Toggle GTM and GA tracking. */
export const toggleGoogleTracking = (GAID: string, enabled: boolean): void => {
  if (GAID === '') return;

  const identifier = getGaidWithPrefix(GAID);

  // Uses as syntax due to dynamic key name
  (window as unknown as Record<string, unknown>)[`ga-disable-${identifier}`] = !enabled;
};

/**
 * Ensures the full GAID is populated. Necessary for setting the proper key to enable/disable GTM
 * and GA features.
 */
const getGaidWithPrefix = (GAID: string) => {
  if (GAID.includes('UA-') || GAID.includes('G-')) {
    return GAID;
  }

  return `UA-${GAID}`;
};

export const configureDataLayer = (input: GoogleConfig | GoogleLocation | GtmStartEvent): void => {
  window.dataLayer = window.dataLayer ?? [];
  window.dataLayer.push(input);
};

const enableGoogleTagManager = (GTMID: string, GAID: string, nonce?: string): void => {
  if (window) {
    toggleGoogleTracking(GAID, true);
    googleTagDataLayerSet(document, GTMID, nonce);
  }
};

const windowGaArgumentSetter = (args: Array<IArguments>) => {
  (window.ga.q = window.ga.q ?? []).push(...args);
};

const googleAnalyticsArgumentsSet = (nonce?: string): void => {
  window.GoogleAnalyticsObject = 'ga';
  window.ga = window.ga ?? windowGaArgumentSetter;
  window.ga.l = new Date().getTime;
  const createdScriptElement = document.createElement('script');
  createdScriptElement.async = true;
  createdScriptElement.src = 'https://www.google-analytics.com/analytics.js';

  if (nonce) createdScriptElement.nonce = nonce;

  document.head.appendChild(createdScriptElement);
};

const enableGoogleAnalytics = (
  GAID: string,
  gaPlugins: Array<PluginObject> = [],
  nonce?: string
): void => {
  if (window) {
    toggleGoogleTracking(GAID, true);

    if (GAID.includes('UA-')) {
      // GA 360 configuration
      googleAnalyticsArgumentsSet(nonce);
      ga('create', GAID, 'auto');

      gaPlugins.forEach(plugin => {
        ga('require', plugin.name, plugin.options);
      });
      ga('set', 'anonymizeIp', true);
      ga('send', 'pageview');
    } else {
      // GA 4 Configuration
      window.dataLayer = window.dataLayer ?? [];
      const GA4Script = document.createElement('script');
      GA4Script.async = true;
      GA4Script.src = `https://www.googletagmanager.com/gtag/js?id=${GAID}`;

      GA4Script.onload = () => {
        configureDataLayer({ js: new Date(), config: GAID });
      };

      if (nonce) GA4Script.nonce = nonce;

      document.head.appendChild(GA4Script);
    }
  }
};

export const enablePerformanceAnalytics = (
  GTMID: string,
  GAID: string,
  plugins?: Array<PluginObject>,
  /** Optional: nonce to apply to GTM/GA inline script element */
  nonce?: string
): void => {
  const gaIdentifier = getGaidWithPrefix(GAID);

  if (GTMID && GAID) {
    enableGoogleTagManager(GTMID, gaIdentifier, nonce);
  } else if (GAID) {
    enableGoogleAnalytics(gaIdentifier, plugins, nonce);
  }
};
