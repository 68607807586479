import type { ReactElement } from 'react';

import type { MediaProps } from './Media';
import { Media } from './Media';

export const isMediaElement = (elem: unknown): elem is ReactElement<MediaProps> => {
  const asMedia = elem as ReactElement<MediaProps>;

  if (asMedia.type === Media) {
    return true;
  }
  const { imageSource, videoSource } = asMedia.props;
  return !!imageSource || !!videoSource;
};
