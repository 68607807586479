import { cx } from '@emotion/css';
import type { FC } from 'react';
import { useState } from 'react';

import { MotifComponent, useMotifStyles } from '../../motif';
import { dataSetToAttributes } from '../../utils';
import { AccordionContext } from './AccordionContext';
import { accordionCss, titleCss } from './styles';
import type { AccordionProps } from './types';

export const Accordion: FC<AccordionProps> = ({
  children,
  multipleOpen = false,
  title,
  titleDataset,
}) => {
  useMotifStyles(MotifComponent.ACCORDION);

  const [openIds, setOpenIds] = useState<string[]>([]);

  return (
    <AccordionContext.Provider
      value={{
        multipleOpen,
        openIds,
        setOpenIds,
      }}
    >
      <div data-testid="accordion" className={cx(MotifComponent.ACCORDION, accordionCss)}>
        {title ? (
          <h4 className={titleCss} {...dataSetToAttributes(titleDataset)}>
            {title}
          </h4>
        ) : null}
        {children}
      </div>
    </AccordionContext.Provider>
  );
};
