import { cx } from '@emotion/css';
import type { FC } from 'react';

import { IconButton } from '../IconButton';
import { IconButtonSize } from '../IconButton/IconButton.types';
import {
  activePaginationItemCss,
  arrowButtonCss,
  nextButtonCss,
  paginationItemButtonCss,
  paginationItemCss,
  previousButtonCss,
} from './styles';
import type { PaginationItemProps } from './types';

export const PaginationItem: FC<PaginationItemProps> = ({
  ariaLabel,
  children,
  disabled,
  handlePageChange,
  isActive,
  type,
}) => {
  if (type === 'previous')
    return (
      <li>
        <IconButton
          size={IconButtonSize.SMALL}
          iconName="chevron-left"
          aria-label={ariaLabel}
          className={cx(arrowButtonCss, previousButtonCss)}
          disabled={disabled}
          onClick={handlePageChange}
          tabIndex={disabled ? -1 : 0}
          data-testid="pagination-item-previous"
        />
      </li>
    );

  if (type === 'next')
    return (
      <li>
        <IconButton
          size={IconButtonSize.SMALL}
          iconName="chevron-right"
          aria-label={ariaLabel}
          className={cx(arrowButtonCss, nextButtonCss)}
          disabled={disabled}
          onClick={handlePageChange}
          tabIndex={disabled ? -1 : 0}
          data-testid="pagination-item-next"
        />
      </li>
    );

  return (
    <li
      className={cx(paginationItemCss, {
        [activePaginationItemCss]: isActive,
      })}
    >
      <button
        aria-label={ariaLabel}
        aria-current={isActive}
        className={paginationItemButtonCss}
        disabled={disabled}
        onClick={handlePageChange}
        tabIndex={0}
        type="button"
      >
        {children?.toLocaleString()}
      </button>
    </li>
  );
};
