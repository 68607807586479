import type {
  CookieModalProps as CookieModalComponentProps,
  CookieProviderProps,
} from '@snapchat/mw-cookie-components';
import {
  CookieModal as CookieModalComponent,
  CookieProvider,
} from '@snapchat/mw-cookie-components';
import { PrimitivesProvider } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useContext, useEffect } from 'react';

import {
  type LocaleOption,
  defaultSupportedLocales,
  GlobalComponentsContext,
} from '../../context/GlobalComponentsContext';
import {
  type PluginObject,
  configureDataLayer,
  enablePerformanceAnalytics,
  toggleGoogleTracking,
} from './analytics';
import { type BackgroundType, CategoryOptInCookie } from './cookieModalTypes';
import { SdsmComponents } from './SdsmComponents';

export interface CookieModalProps {
  portalRoot?: Element;
  /** @deprecated Dropdown will get it from context */
  cookieDomain?: string;
  /** @deprecated Dropdown will get it from context */
  supportedLocales?: Record<string, LocaleOption>;
  /** @deprecated - Cookie modal shouldn't load GTM */
  GTMID?: string;
  /** @deprecated - Cookie modal shouldn't load GA */
  GAID?: string;
  /**
   * If specified, applies the nonce to the generated GTM or GA script
   *
   * @deprecated See above.
   */
  nonce?: string;
  /** @deprecated Dropdown will get it from context */
  onLocaleChange?: (locale: string) => void;
  /** @deprecated Use onComplete instead. */
  preferencesAcceptedCallback?: () => void;
  /** @deprecated Use onComplete instead. */
  performanceAnalyticsCallback?: () => void;
  /** @deprecated Use onComplete instead. */
  marketingAnalyticsCallback?: () => void;
  plugins?: Array<PluginObject>;
  onComplete?: CookieModalComponentProps['onComplete'];
  backgroundType?: BackgroundType;
  forceVisible?: boolean;
  /** Whether the user has enabled Global Privacy Control. undefined is treated same as false */
  globalPrivacyControl?: boolean;
  /** Fires whenever global privacy control is in place */
  onGlobalPrivacyControlSet?: () => void;
}

type LogEventParameters = Parameters<Required<CookieModalComponentProps>['onEvent']>;
type LogEventListener = (...params: LogEventParameters) => void;
type OnCompleteListener = Required<CookieModalComponentProps>['onComplete'];

export const CookieModal: FC<CookieModalProps> = ({
  portalRoot,
  cookieDomain,
  supportedLocales,
  GTMID = '',
  GAID = '',
  nonce,
  plugins,
  onLocaleChange,
  onComplete: userOnComplete,
  preferencesAcceptedCallback,
  performanceAnalyticsCallback,
  marketingAnalyticsCallback,
  backgroundType,
  forceVisible,
  globalPrivacyControl,
  onGlobalPrivacyControlSet,
}) => {
  // Disable GA/GTM until the performance tracking is enabled via `onComplete` event hook.
  useEffect(() => {
    toggleGoogleTracking(GAID, false);
  }, [GAID]);

  // Construct props for <CookieProvider />
  const context = useContext(GlobalComponentsContext);
  const { Anchor, globalApolloClient, currentLocale, isPreview, isSSR, onEvent, onError } = context;

  cookieDomain ??= context.hostname;
  supportedLocales ??= context.supportedLocales ?? defaultSupportedLocales;
  onLocaleChange ??= context.onLocaleChange;

  const providerProps: CookieProviderProps = {
    ...SdsmComponents,
    isSSR,
    isPreview,
    currentLocale,
    client: globalApolloClient,
  };

  // Construct props for <CookieModalComponent />
  const logEvent: LogEventListener = ({ label }) =>
    onEvent?.({ component: 'CookieModal', label, action: 'Click' });

  const onComplete: OnCompleteListener = props => {
    const { cookieAcceptance, userLocation } = props;

    // Add user location to data layer for GA/GTM to reference
    configureDataLayer({ userLocation });

    // Set up GA/GTM integration
    cookieAcceptance[CategoryOptInCookie.Performance] &&
      enablePerformanceAnalytics(GTMID, GAID, plugins, nonce);

    // Legacy callbacks trigger
    cookieAcceptance[CategoryOptInCookie.Preferences] && preferencesAcceptedCallback?.();
    cookieAcceptance[CategoryOptInCookie.Performance] && performanceAnalyticsCallback?.();
    cookieAcceptance[CategoryOptInCookie.Marketing] && marketingAnalyticsCallback?.();

    // Callback trigger.
    userOnComplete?.(props);
  };

  const modalProps: CookieModalComponentProps = {
    supportedLocales,
    cookieDomain,
    portalRoot: portalRoot as HTMLElement,
    backgroundType,
    onLocaleChange,
    onComplete,
    onEvent: logEvent,
    onError,
    forceVisible,
    globalPrivacyControl,
    onGlobalPrivacyControlSet,
  };

  return (
    <PrimitivesProvider value={{ Anchor }}>
      <CookieProvider {...providerProps}>
        <CookieModalComponent {...modalProps} />
      </CookieProvider>
    </PrimitivesProvider>
  );
};
