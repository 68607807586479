import type { FC, ReactElement } from 'react';

import { SkeletonSummaryCard } from './SkeletonSummaryCard';
import type { SummaryCardProps } from './SummaryCard';
import { SummaryCard } from './SummaryCard';

/**
 * Type guard function to check if the given element is a SummaryCard.
 *
 * @param elem - The element to be checked.
 * @returns True if the element is a React element with SummaryCardProps, false otherwise.
 */
export const isSummaryCardElement = (elem: unknown): elem is ReactElement<SummaryCardProps> => {
  const asSummaryCard = elem as ReactElement<SummaryCardProps>;

  if (asSummaryCard?.type === SummaryCard) {
    return true;
  }

  if (asSummaryCard?.type === SkeletonSummaryCard) {
    return true;
  }

  // evil displayName check
  const typeAsFC = (elem as ReactElement).type as FC;
  return typeAsFC.displayName === 'SummaryCard' || typeAsFC.displayName === 'SkeletonSummaryCard';
};
