import { logger } from '../helpers/logging/loggingInstance';
import type { LoggingConfig } from '../helpers/logging/loggingTypes';

/**
 * Attempts with different deferral values (on citizen.snap.com) and Lighthouse Perfromance (Mobile)
 * scores:
 *
 *     10 seconds: 74, 72
 *     9  seconds: Skip
 *     8  seconds: Skip
 *     7  seconds: 72, 72
 *     6  seconds: 72, 74, 69, 74, 78
 *     5  seconds: 79, 76, 73, 74, 73
 *     4  seconds: 72, 74, 73, 72, 75
 *     3  seconds: 61, 73, 67, 58, 72
 *     2  seconds: 71, 69, 70, 70, 69
 *     1  seconds: Skip
 *     0  seconds: 69, 70
 *
 * Based on the table above, we're targeting 5 seconds since we want to avoid never logging too many
 * bounced visits.
 */
const loggingInitTimeoutMs = 5e3;

/**
 * Gives the logger the logging permission.
 *
 * This triggers async downloads of the logging libs which has implications.
 */
export function startClientLogging(config: LoggingConfig): void {
  // logging client initialization occurs outside of React, so need to handle default behaviors here.
  const disableLazyLoadLogging =
    config.featureFlags?.disableLazyLoadLogging === 'true' || !config.isDeploymentTypeProd;

  const loggingScheduleStart = performance.now();

  const allowLogging = () => {
    void logger.allow('logging');
    const loggingAllowTrigger = performance.now();

    logger.logTiming({
      component: 'logInit',
      variable: 'allowLogging',
      valueMs: loggingAllowTrigger - loggingScheduleStart,
    });
  };

  if (disableLazyLoadLogging) {
    // This has an effect of greatly slowing down website rendering because logging
    // packages are very cumbersome, but this way there are less missed user sessions.
    setTimeout(allowLogging, 10);
  } else if (typeof globalThis.requestIdleCallback === 'function') {
    // If we can, we wait for the network to be idle before loading logging packages.
    // Though we only check after 1 second, to allow for the initial rendering to
    // finish (on fast machines anyway). Without the delay, idle callback fires too soon.
    setTimeout(() => {
      requestIdleCallback(allowLogging, { timeout: loggingInitTimeoutMs });
    }, 1e3);
  } else if (typeof globalThis.requestAnimationFrame === 'function') {
    // For legacy browsers, we wait for the end of the JS loop via setTimeout and then
    // wait for for the layout to finish allowing for one JS + render cycle to complete
    // before loading the logging packages.
    setTimeout(() => {
      requestAnimationFrame(allowLogging);
    }, 1e3);
  } else {
    // If all else fails, we have a long delay before initializing logging.
    setTimeout(allowLogging, loggingInitTimeoutMs);
  }
}
