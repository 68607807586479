import { css, cx } from '@emotion/css';
import type { FC, PropsWithChildren } from 'react';
interface Props {
  maxHeight?: number;
  className?: string;
}

export const phoneWrapperCss = css`
  display: flex;
  justify-content: center;
`;

export const phoneWrapperBgCss = css`
  background-image: url('https://images.ctfassets.net/kp51zybwznx4/5MBQ096OLy837zpHXtGsIa/0b35a1eadad459a767f935297483854c/iPhone.png');
  width: 250px;
  height: 530px;
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
  margin-bottom: 20px;
  padding: 14px;
`;

export const PhoneWrapper: FC<PropsWithChildren<Props>> = ({ children }) => (
  <div className={cx('phone-wrapper', phoneWrapperCss)} data-testid="phone-wrapper">
    <div className={phoneWrapperBgCss}>{children}</div>
  </div>
);

PhoneWrapper.displayName = 'PhoneWrapper';
