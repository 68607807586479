import { SnapchatEmbed as SnapchatEmbedSDS } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import type { ContentfulIdVariable } from '../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../hooks/useContentfulQuery';
import type { ContentfulSysProps } from '../../types/contentful';
import { isContentfulSysProps } from '../../utils/contentful';
import { query } from './SnapchatEmbed.query';

export interface SnapchatEmbedDataProps extends ContentfulSysProps {
  url: string;
}

export const SnapchatEmbedShallow: FC<ContentfulSysProps> = props => {
  const id = isContentfulSysProps(props) ? props.sys.id : undefined;
  const { data } = useContentfulQuery<
    { snapchatEmbed: SnapchatEmbedDataProps },
    ContentfulIdVariable
  >(query, {
    skip: !id,
    variables: { id },
  });

  if (!data) return null;

  return <SnapchatEmbedSDS {...data.snapchatEmbed} />;
};
