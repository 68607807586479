/**
 * CSP Errors may reference resources with query string parameters, etc. We'll reduce cardinality by
 * ommitting these when logging to graphene.
 */
export function sanitizeCspResource(resource?: string): string | undefined {
  if (!resource) return undefined;

  try {
    const url = new URL(resource);
    return `${url.origin}${url.pathname}`;
  } catch {
    return resource; // If not parseable as a URL (e.g. `eval`), return the original string
  }
}
