import { cx } from '@emotion/css';
import type { FC } from 'react';

import {
  fiveOrMoreColumnWidthCss,
  footerColumnsCenteredCss,
  footerColumnsDesktopCss,
  fourColumnWidthCss,
  oneColumnWidthCss,
  threeColumnWidthCss,
  twoColumnWidthCss,
  wrapperCss,
} from './styles';
import type { FooterColumnsProps } from './types';

export const FooterColumnsDesktop: FC<FooterColumnsProps> = ({ children }) => {
  let footerStyle = cx(footerColumnsDesktopCss);

  const childrenLength = Array.isArray(children) ? children.filter(child => !!child).length : 1;

  switch (childrenLength) {
    case 1:
      footerStyle = cx(footerColumnsDesktopCss, oneColumnWidthCss);
      break;
    case 2:
      footerStyle = cx(footerColumnsDesktopCss, twoColumnWidthCss);
      break;
    case 3:
      footerStyle = cx(footerColumnsDesktopCss, threeColumnWidthCss);
      break;
    case 4:
      footerStyle = cx(footerColumnsDesktopCss, fourColumnWidthCss);
      break;
    default:
      footerStyle = cx(footerColumnsDesktopCss, fiveOrMoreColumnWidthCss);
      break;
  }

  if (childrenLength <= 6) {
    footerStyle = cx(footerStyle, footerColumnsCenteredCss);
  }

  return (
    <article data-testid="footer-columns-desktop" className={wrapperCss}>
      <div className={footerStyle}>{children}</div>
    </article>
  );
};
