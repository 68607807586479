import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import type { BlogPosting, FAQPage, Organization, Question, WithContext } from 'schema-dts';

import type { BlogSchemaData } from './blogSchemaQuery';
import type { FaqPageSchemaData } from './faqPageSchemaQuery';
import type { OrganizationSchemaData } from './organizationSchemaQuery';
import type { SchemaOrgStructuredDataSchema, StructuredDataSchema } from './WebSchema.types';

const schemaOrgUrl = 'https://schema.org';

/** Given the schema data from contentful, generate the corresponding schema.org structured data */
export function generateWebSchema(
  url: string,
  schemaData: StructuredDataSchema,
  postedAt?: Date,
  lastModifiedAt?: Date,
  organizationSchemaData?: OrganizationSchemaData
): SchemaOrgStructuredDataSchema | undefined {
  switch (schemaData.__typename) {
    case 'BlogSchema':
      return generateBlogSchema(url, schemaData, postedAt, lastModifiedAt, organizationSchemaData);
    case 'OrganizationSchema':
      return generateOrganizationSchema(url, schemaData);
    case 'FaqPageSchema':
      return generateFaqPageSchema(schemaData);
    default:
      return;
  }
}

// This snippet will be re-used in other schemas to provide information about the organization
function generateOrganizationSnippet(organizationSchemaData: OrganizationSchemaData): Organization {
  return {
    '@type': 'Organization',
    name: organizationSchemaData.name,
    logo: organizationSchemaData.logo?.url && {
      '@type': 'ImageObject',
      url: organizationSchemaData.logo?.url,
    },
    url: organizationSchemaData.url,
  };
}

function generateBlogSchema(
  url: string,
  blogSchemaData: BlogSchemaData,
  postedAt?: Date,
  lastModifiedAt?: Date,
  organizationSchemaData?: OrganizationSchemaData
): WithContext<BlogPosting> {
  const organizationSnippet =
    organizationSchemaData && generateOrganizationSnippet(organizationSchemaData);

  const images = blogSchemaData.imagesCollection?.items
    ?.map(item => item.url)
    // this lets typescript know that the array contains only strings, otherwise there'd be an error
    .filter((item: string | undefined): item is string => item !== undefined);

  return {
    '@context': schemaOrgUrl,
    '@type': 'BlogPosting',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': url,
    },
    headline: blogSchemaData.headline,
    description: blogSchemaData.description,
    image: images,
    author: organizationSnippet,
    publisher: organizationSnippet,
    datePublished: postedAt?.toISOString(),
    dateModified: lastModifiedAt?.toISOString(),
  };
}

function generateOrganizationSchema(
  url: string,
  organizationSchemaData: OrganizationSchemaData
): WithContext<Organization> {
  const organizaitonSnippet = generateOrganizationSnippet(organizationSchemaData);

  return {
    '@context': schemaOrgUrl,
    '@type': 'Organization',
    brand: organizaitonSnippet,
    description: organizationSchemaData?.description,
    name: organizationSchemaData?.name,
    sameAs: organizationSchemaData?.sameAs,
    url,
  };
}

function generateFaqPageSchema(faqPageSchemaData: FaqPageSchemaData): WithContext<FAQPage> {
  const questions: Question[] =
    faqPageSchemaData.itemsCollection?.items?.map(item => ({
      '@type': 'Question',
      name: item.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: documentToHtmlString(item.answer.json),
      },
    })) ?? [];

  return {
    '@context': schemaOrgUrl,
    '@type': 'FAQPage',
    mainEntity: questions,
  };
}
