import type { ValueOf } from '../types';

/** TODO: Rename to `ButtonSize` and move under /components/Button */
export const Size = {
  Compact: 'Compact',
  Regular: 'Regular',
  Large: 'Large',
  /** @deprecated. This isn't a size. */
  Flat: 'Flat',
} as const;

export type Size = ValueOf<typeof Size>;

export type ButtonSize = Size;
