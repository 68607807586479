import type { FC } from 'react';

import type { BreakPatternProps } from './breakPatternTypes';

export const StraightBreak: FC<BreakPatternProps> = props => {
  const { topColor, bottomColor, ...rest } = props;
  return (
    <div
      {...rest}
      style={{
        background: `linear-gradient(
        to bottom,
        ${topColor} 0%,
        ${topColor} 50%,
        ${bottomColor} 50%,
        ${bottomColor} 100%
      )`,
      }}
    />
  );
};
