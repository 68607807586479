import { cx } from '@emotion/css';
import type { FC, ReactNode } from 'react';

import { MotifComponent } from '../../../motif';
import type { BaseComponentProps } from '../../../types';
import { chartToggleWrapperCss } from './styles';

export interface ChartToggleWrapperProps extends BaseComponentProps {
  children?: ReactNode;
}

export const ChartToggleWrapper: FC<ChartToggleWrapperProps> = ({ children }) => {
  return (
    <section className={cx(MotifComponent.CHART_TOGGLE, chartToggleWrapperCss)}>{children}</section>
  );
};
