import type { FC } from 'react';

import { type LogoProps, Logo } from './Logo';
import { type GhostProps, GhostSvg } from './Logo.Ghost';

export * from './Logo';
export * from './Logo.Ghost';
export * from './Logo.SnapInc';

// Exporting these to keep compatibility and minimize breaking changes
// TODO (breaking change): Remove these for the next major version bump
export const LogoLight: FC<GhostProps> = GhostSvg;
export const Ghost: FC<LogoProps> = Logo;
