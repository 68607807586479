import { cx } from '@emotion/css';
import type { FC, PropsWithChildren } from 'react';

import type { BaseComponentProps } from '../../types';
import { dataSetToAttributes } from '../../utils';
import { containerCss, innerContainerCss } from './styles';

export const scrollableSectionScrollProgressCssVar = '--scroll-progress';

interface ScrollAnimatedSectionProps extends BaseComponentProps {
  /** Used to pass a className to the inner sticky container. */
  innerContainerClassName?: string;
  /** Ref to the outer section container */
  containerRef: React.RefObject<HTMLDivElement>;
}

/**
 * Component that will create a scrollable section with a sticky inner container. Used for scroll
 * based animations
 */
export const ScrollAnimatedSection: FC<PropsWithChildren<ScrollAnimatedSectionProps>> = ({
  className,
  containerRef,
  innerContainerClassName,
  children,
  dataset,
  style,
}) => {
  return (
    <section
      ref={containerRef}
      className={cx(containerCss, className)}
      style={{ ...style }}
      {...dataSetToAttributes(dataset)}
    >
      <div className={cx(innerContainerCss, innerContainerClassName)}>{children}</div>
    </section>
  );
};
