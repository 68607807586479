import { AxisLeft } from '@visx/axis';
import { GridColumns } from '@visx/grid';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { Bar, BarGroupHorizontal } from '@visx/shape';
import type {
  AnyScaleBand,
  BarGroupHorizontal as BarGroupHorizontalType,
} from '@visx/shape/lib/types';
import type { ScaleOrdinal } from 'd3-scale';
import type { ReactElement } from 'react';
import { useCallback, useMemo, useState } from 'react';

import type { BarTooltipProps } from './components';
import {
  AxisBottomPercentLabels,
  AxisLeftGroupLabel,
  BarPercentLabel,
  BarTooltip,
  ChartLegend,
} from './components';
import { barChartContainerCss } from './styles';
import {
  defaultMargin,
  getLegendColors,
  getLegendLabels,
  mapStringToNumber,
} from './utils/helpers';
import type { BarChartProps, BarData, BarItem } from './utils/types';

export type BarGroupProps = Required<Omit<BarChartProps, 'stackKey'>>;
export type BarsProps = {
  barGroups: BarGroupHorizontalType<string>[];
  yScale: AnyScaleBand;
  colors: string[];
  legendLabels: string[];
  hideLabels?: boolean;
  colorScale: ScaleOrdinal<string, string>;
  showAsPercent?: boolean;
  numberOfDecimalPlaces?: number;
  isRTL?: boolean;
};

export const BarGroup = (props: BarGroupProps): ReactElement => {
  const {
    width,
    height,
    yAxis,
    xAxes,
    xMax,
    yMax,
    xScaleTicks,
    leftPosition,
    data,
    dataColors,
    hideXAxisLabels,
    hideYAxisLabels,
    hideXPercentLabels,
    showAsPercent,
    numberOfDecimalPlaces,
    hideLegend,
    isRTL,
  } = props;

  const mappedData = useMemo(
    () => removeUnusedPropertiesAndSumByYAxis(data, yAxis, xAxes),
    [data, yAxis, xAxes]
  );

  const getGroupValue = useCallback((data: BarItem) => data[yAxis] as string, [yAxis]);

  const legendLabels = useMemo(() => getLegendLabels(mappedData, yAxis), [mappedData, yAxis]);
  const barColors = useMemo(
    () => getLegendColors(legendLabels, dataColors),
    [legendLabels, dataColors]
  );

  const yScale = useMemo(
    () =>
      scaleBand<string>({
        range: [0, yMax],
        domain: mappedData.map(getGroupValue),
        padding: 0.2,
      }),
    [yMax, mappedData, getGroupValue]
  );

  const xScale = useMemo(
    () =>
      scaleLinear<number>({
        domain: [0, getBiggestBarItemValue(mappedData, yAxis)],
        range: [0, xMax],
      }),
    [mappedData, yAxis, xMax]
  );

  const xPercentScale = useMemo(
    () =>
      scaleBand<string>({
        range: [0, yScale.bandwidth()],
        domain: xAxes,
        padding: 0.1,
        paddingOuter: 1,
      }),
    [yScale, xAxes]
  );

  const colorScale = useMemo(
    () =>
      scaleOrdinal<string, string>({
        domain: legendLabels,
        range: barColors,
      }),
    [barColors, legendLabels]
  );

  return (
    <figure className={barChartContainerCss}>
      <svg width={width} height={height}>
        <Group top={defaultMargin.top} left={leftPosition}>
          <GridColumns scale={xScale} height={height - 60} numTicks={xScaleTicks} />
          <BarGroupHorizontal
            data={mappedData}
            keys={xAxes}
            width={xMax}
            y0={getGroupValue}
            y0Scale={yScale}
            y1Scale={xPercentScale}
            xScale={xScale}
            color={colorScale}
          >
            {barGroups => (
              <Bars
                barGroups={barGroups}
                colors={barColors}
                yScale={xPercentScale}
                legendLabels={legendLabels}
                hideLabels={hideXAxisLabels}
                colorScale={colorScale}
                showAsPercent={showAsPercent}
                numberOfDecimalPlaces={numberOfDecimalPlaces}
                isRTL={isRTL}
              />
            )}
          </BarGroupHorizontal>
          {!hideXPercentLabels && (
            <AxisBottomPercentLabels
              yMax={yMax}
              xScale={xScale}
              numTicks={xScaleTicks}
              hidePercentSignal={!showAsPercent}
            />
          )}
        </Group>
        {!hideYAxisLabels && <AxisLeftGroupLabel yScale={yScale} />}
      </svg>
      {!hideLegend && <ChartLegend colorScale={colorScale} keys={legendLabels} />}
    </figure>
  );
};

const Bars = (props: BarsProps) => {
  const {
    barGroups,
    yScale,
    colors,
    legendLabels,
    hideLabels,
    colorScale,
    showAsPercent,
    numberOfDecimalPlaces,
    isRTL,
  } = props;
  const [tooltipData, setTooltipData] = useState<BarTooltipProps | null>(null);
  let tooltipTimeout: ReturnType<typeof setTimeout>;

  const handleMouseLeave = () => {
    tooltipTimeout = setTimeout(() => {
      setTooltipData(null);
    }, 300);
  };

  const handleMouseMove = (
    top: number,
    left: number,
    barValue: number,
    barKey: string,
    barGroupIndex: number
  ) => {
    if (tooltipTimeout) {
      clearTimeout(tooltipTimeout);
    }
    const groupName = barKey;
    const subGroupName = '';
    const colorKey = legendLabels[barGroupIndex]!;

    setTooltipData({
      tooltipData: { value: barValue, colorKey, groupName, subGroupName },
      tooltipTop: top,
      tooltipLeft: left,
      colorScale,
      showAsPercent,
      numberOfDecimalPlaces,
    });
  };

  return (
    <>
      {barGroups.map(barGroup => (
        <Group key={`bar-group-horizontal-${barGroup.index}-${barGroup.y0}`} top={barGroup.y0}>
          {barGroup.bars.map(bar => (
            <Group key={`${barGroup.index}-${bar.index}-${bar.key}`}>
              <Bar
                x={bar.x}
                y={bar.y}
                width={bar.width}
                height={bar.height}
                fill={colors[barGroup.index]}
                rx={0}
                onMouseLeave={handleMouseLeave}
                onMouseMove={e =>
                  handleMouseMove(e.clientY, e.clientX, bar.value, bar.key, barGroup.index)
                }
              />
              <BarPercentLabel
                barX={bar.x}
                barY={bar.y}
                barWidth={bar.width}
                barHeight={bar.height}
                value={bar.value}
                showAsPercent={showAsPercent}
                numberOfDecimalPlaces={numberOfDecimalPlaces}
                isRTL={isRTL}
              />
            </Group>
          ))}
          {!hideLabels && (
            <AxisLeft
              hideTicks
              scale={yScale}
              hideAxisLine
              tickLabelProps={() => ({
                fill: 'black',
                fontSize: 11,
                // Bar labels are right aligned against their respective bar. The "end" is actually the "start"
                // of the text for RTL languages, so anchor should be 'start' for RTL and 'end' for LTR.
                textAnchor: isRTL ? 'start' : 'end',
                dy: '0.33em',
              })}
            />
          )}
        </Group>
      ))}
      {tooltipData && <BarTooltip {...tooltipData} />}
    </>
  );
};

function getBiggestBarItemValue(data: BarData, yAxis: string) {
  const keysToIgnore = [yAxis];

  let biggestBarItemValue = 0;

  data.forEach(item => {
    Object.keys(item)
      .filter(key => !keysToIgnore.includes(key))
      .forEach(dataKey => {
        const currentValue = mapStringToNumber(item[dataKey]);
        biggestBarItemValue = Math.max(biggestBarItemValue, currentValue);
      });
  });

  return biggestBarItemValue;
}

function removeUnusedPropertiesAndSumByYAxis(
  data: BarData,
  yAxis: string,
  xAxes: string[]
): BarItem[] {
  const groupDataByGroupKeyAndSumSubGroupKeys = () => {
    const grouppedData = data.reduce((result: Record<string, BarItem>, line) => {
      const yAxisValue = line[yAxis] as string;

      if (!result[yAxisValue]) {
        result[yAxisValue] = {};
      }

      xAxes.forEach(xAxis => {
        const xAxisValue = line[xAxis];

        if (!result[yAxisValue]![xAxis]) {
          result[yAxisValue]![xAxis] = mapStringToNumber(xAxisValue);
        } else {
          result[yAxisValue]![xAxis] =
            mapStringToNumber(result[yAxisValue]![xAxis]) + mapStringToNumber(xAxisValue);
        }
      });

      return result;
    }, {});

    return grouppedData;
  };

  const convertGrouppedDataToArray = (data: Record<string, BarItem>) => {
    const dataAsArray = Object.entries(data).map(([name, item]) => ({
      [yAxis]: name,
      ...item,
    }));

    return dataAsArray;
  };

  const grouppedData = groupDataByGroupKeyAndSumSubGroupKeys();
  const mappedData = convertGrouppedDataToArray(grouppedData);
  const sortedData = mappedData.sort((a, b) =>
    a[yAxis]!.toString().localeCompare(b[yAxis]!.toString())
  );

  return sortedData;
}
