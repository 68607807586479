import { Superscript } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import { renderRichTextWithElements } from '../../utils/renderText/renderRichText';
import { Definition } from '../Definition';
import {
  footnoteInlineNoDisplayTextCss,
  footnoteInlineSuperscriptCss,
  footnoteInlineSuperscriptOverlayCss,
} from './FootnoteInline.styles';
import type { FootnoteInlineProps } from './types';

export const FootnoteInline: FC<FootnoteInlineProps> = ({
  number,
  displayText,
  fullText,
  ...props
}) => {
  const footnoteDisplayText = (
    <>
      {displayText}
      {/* Note: The whitespace before "index" is important. It ensures that the browser never considers
      the footnote index as a part of the footnote content (i.e. "children"). This can happen if
      the footnote content ends with a roman numeral (i.e. 1, 2, 3 etc...) and causes problems
      in RTL locales */}
      <span className={footnoteInlineSuperscriptCss}>
        <Superscript> {number}</Superscript>
      </span>
    </>
  );

  const overlayBody = (
    <div>
      {/* Note: The whitespace after "index" is important. It ensures that the browser never considers
      the footnote index as a part of the footnote content (i.e. "children"). This can happen if
      the footnote content begins with a roman numeral (i.e. 1, 2, 3 etc...) and causes problems
      in RTL locales */}
      <span className={footnoteInlineSuperscriptOverlayCss}>
        <Superscript>{number} </Superscript>
      </span>
      {renderRichTextWithElements(fullText)}
    </div>
  );

  return (
    <Definition
      {...props}
      displayText={footnoteDisplayText}
      fullText={fullText}
      overlayTitle=""
      overlayBody={overlayBody}
      className={!displayText ? footnoteInlineNoDisplayTextCss : undefined}
    />
  );
};

FootnoteInline.displayName = 'FootnoteInline';
