/** Type of a dependency that shouldn't occupy too much room in RAM. */
type Dep = WeakRef<object> | string | number | boolean | undefined;

export type SingleCallbackCache = Map<string, Dep[][]>;

/**
 * A BAD polyfill for the WeakRef class that never lets go of it's references which causes a memory
 * leak, but it's better than crashing the page.
 */
class WeakRefPolyfill<T extends object> implements WeakRef<T> {
  private static readonly tag = 'WeakRef' as const;

  private readonly ref: T;

  constructor(ref: T) {
    this.ref = ref;
  }

  deref = (): T => this.ref;

  get [Symbol.toStringTag]() {
    return WeakRefPolyfill.tag;
  }
}

export interface SingleCallbackArgs {
  key: string;
  callback: () => void;
  dependencies: unknown[];
  cache: SingleCallbackCache;
}

/** How many items to store key invocation key. */
const maxHistory = 50;

function areEqual(deps: unknown[], previousDeps: Dep[]): boolean {
  if (deps.length !== previousDeps.length) {
    return false;
  }

  return deps.every((dep, i) => {
    const previousDep = previousDeps[i];

    if (typeof previousDep === 'object' && previousDep !== null && 'deref' in previousDep) {
      return dep === previousDep.deref();
    }

    return dep === previousDep;
  });
}

function mapDep(dep: unknown): Dep {
  if (typeof dep === 'object' && dep !== null) {
    return WeakRef ? new WeakRef(dep) : new WeakRefPolyfill(dep);
  }

  if (typeof dep === 'string' || typeof dep === 'number' || typeof dep === 'boolean') {
    return dep;
  }

  return String(dep);
}

/**
 * Runs a function only once for a given set of dependencies.
 *
 * This is similar to `useImperativeEffect` but for use in places where hooks are disallowed or are
 * not practical.
 */
export function singleCallback({ key, callback, dependencies, cache }: SingleCallbackArgs): void {
  if (!cache.has(key)) {
    cache.set(key, []);
  }

  const previousCalls = cache.get(key)!;

  if (previousCalls.some(previous => areEqual(dependencies, previous))) {
    return;
  }

  if (previousCalls.length >= maxHistory) {
    previousCalls.shift();
  }

  previousCalls.push(dependencies.map(mapDep));

  callback();
}
