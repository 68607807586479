import { css } from '@emotion/css';

import { mobileMediaQuery, nonMobileMediaQuery } from '../../constants';
import { m } from '../../motif';

export const hyperLinkCss = css`
  cursor: pointer;

  /** TODO: Troubleshoot this w/ designers. */
  text-decoration: underline;

  color: ${m('--hyperlink-color')};
  :hover {
    color: ${m('--hyperlink-hover-color')};
    border-bottom: 0;
  }

  ${mobileMediaQuery} {
    font-size: ${m('--hyperlink-mobile-font-size')};
    font-weight: ${m('--hyperlink-mobile-font-weight')};
    letter-spacing: ${m('--hyperlink-mobile-font-letter-spacing')};
    line-height: ${m('--hyperlink-mobile-font-line-height')};
  }

  ${nonMobileMediaQuery} {
    font-size: ${m('--hyperlink-desktop-font-size')};
    font-weight: ${m('--hyperlink-desktop-font-weight')};
    letter-spacing: ${m('--hyperlink-desktop-font-letter-spacing')};
    line-height: ${m('--hyperlink-desktop-font-line-height')};
  }
`;
