import { globalHeaderHeight } from '@snapchat/snap-design-system-marketing';

// Variable to keep track of the initial value we want to set the
// css variable to during SSR in index.ejs.
// ONLY to be used during SSR in the renderHtml file
let headerHeight = globalHeaderHeight;

/** Used only for SSR to set the initial value of the css variable */
export const setHeaderHeight = (height: number): void => {
  headerHeight = height;
};

/** Used only for SSR to set the initial value of the css variable */
export const getHeaderHeight = (): number => headerHeight;

/** Css variable that keeps track of total header height */
export const totalHeaderHeightCssVar = '--total-header-height';

/** Gets height of header from css variable. */
export const getHeaderHeightFromCssVar = (): number =>
  Number.parseInt(
    getComputedStyle(document.documentElement).getPropertyValue(totalHeaderHeightCssVar),
    10
  );
