import { cx } from '@emotion/css';
import type { FC } from 'react';

import { navigationButtonContainerCss } from './styles';
import type { NavigationButtonProps } from './types';

export const NavigationButton: FC<NavigationButtonProps> = ({
  onClick,
  isDisabled = false,
  label,
  className,
}) => {
  return (
    <button
      onClick={onClick}
      type="button"
      disabled={isDisabled}
      className={cx(navigationButtonContainerCss, className)}
    >
      {label}
    </button>
  );
};

NavigationButton.displayName = 'NavigationButton';
