import { css } from '@emotion/css';

import { mobileMediaQuery, nonMobileMediaQuery } from '../constants';
import { globalCss } from '../utils/globalCss';
import { m } from './m';
import { MotifComponent } from './motifConstants';

/** Default font */
const textStyles = globalCss`
  /* Required to be listed. I.e. body.sdsm { font-family: ... } */
  font-family: ${m('--font-family')};

  ${nonMobileMediaQuery} {
    font-size: ${m('--text-desktop-font-size')};
    line-height: ${m('--text-desktop-font-line-height')};
    font-weight: ${m('--text-desktop-font-weight')};
  }

  ${mobileMediaQuery} {
    font-size: ${m('--text-mobile-font-size')};
    line-height: ${m('--text-mobile-font-line-height')};
    font-weight: ${m('--text-mobile-font-weight')};
  }
`;

const h1Styles = globalCss`
  ${nonMobileMediaQuery} {
    font-size: ${m('--h1-desktop-font-size')};
    line-height: ${m('--h1-desktop-font-line-height')};
    font-weight: ${m('--h1-desktop-font-weight')};
    font-stretch: ${m('--h1-desktop-font-stretch')};
  }

  ${mobileMediaQuery} {
    font-size: ${m('--h1-mobile-font-size')};
    line-height: ${m('--h1-mobile-font-line-height')};
    font-weight: ${m('--h1-mobile-font-weight')};
    font-stretch: ${m('--h1-mobile-font-stretch')};
  }
`;

const h2Styles = globalCss`
  ${nonMobileMediaQuery} {
    font-size: ${m('--h2-desktop-font-size')};
    line-height: ${m('--h2-desktop-font-line-height')};
    font-weight: ${m('--h2-desktop-font-weight')};
    font-stretch: ${m('--h2-desktop-font-stretch')};
  }

  ${mobileMediaQuery} {
    font-size: ${m('--h2-mobile-font-size')};
    line-height: ${m('--h2-mobile-font-line-height')};
    font-weight: ${m('--h2-mobile-font-weight')};
    font-stretch: ${m('--h2-mobile-font-stretch')};
  }
`;

const h3Styles = globalCss`
  ${nonMobileMediaQuery} {
    font-size: ${m('--h3-desktop-font-size')};
    line-height: ${m('--h3-desktop-font-line-height')};
    font-weight: ${m('--h3-desktop-font-weight')};
    font-stretch: ${m('--h3-desktop-font-stretch')};
  }

  ${mobileMediaQuery} {
    font-size: ${m('--h3-mobile-font-size')};
    line-height: ${m('--h3-mobile-font-line-height')};
    font-weight: ${m('--h3-mobile-font-weight')};
    font-stretch: ${m('--h3-mobile-font-stretch')};
  }
`;

const h4Styles = globalCss`
  ${nonMobileMediaQuery} {
    font-size: ${m('--h4-desktop-font-size')};
    line-height: ${m('--h4-desktop-font-line-height')};
    font-weight: ${m('--h4-desktop-font-weight')};
    font-stretch: ${m('--h4-desktop-font-stretch')};
  }

  ${mobileMediaQuery} {
    font-size: ${m('--h4-mobile-font-size')};
    line-height: ${m('--h4-mobile-font-line-height')};
    font-weight: ${m('--h4-mobile-font-weight')};
    font-stretch: ${m('--h4-mobile-font-stretch')};
  }
`;

const h5Styles = globalCss`
  ${nonMobileMediaQuery} {
    font-size: ${m('--h5-desktop-font-size')};
    line-height: ${m('--h5-desktop-font-line-height')};
    font-weight: ${m('--h5-desktop-font-weight')};
    font-stretch: ${m('--h5-desktop-font-stretch')};
  }

  ${mobileMediaQuery} {
    font-size: ${m('--h5-mobile-font-size')};
    line-height: ${m('--h5-mobile-font-line-height')};
    font-weight: ${m('--h5-mobile-font-weight')};
    font-stretch: ${m('--h5-mobile-font-stretch')};
  }
`;

const h6Styles = globalCss`
  ${nonMobileMediaQuery} {
    font-size: ${m('--h6-desktop-font-size')};
    line-height: ${m('--h6-desktop-font-line-height')};
    font-weight: ${m('--h6-desktop-font-weight')};
    font-stretch: ${m('--h6-desktop-font-stretch')};
  }

  ${mobileMediaQuery} {
    font-size: ${m('--h6-mobile-font-size')};
    line-height: ${m('--h6-mobile-font-line-height')};
    font-weight: ${m('--h6-mobile-font-weight')};
    font-stretch: ${m('--h6-mobile-font-stretch')};
  }
`;

/**
 * Global CSS for styling raw tags in SDS-M components
 *
 * Note: this only applies to scope tagged with `.sdsm` so that it can be dropped onto other
 * websites without breaking CSS.
 *
 * TODO: It would be nice to just put in values here instead of using variables for all of the
 * values, however that would take away the ability to override things globally, like changing the
 * font-stretch for all headings in Arcadia. Though we could do this via other means, it would mean
 * a refactor.
 */
export const globalMotifCss = globalCss`
  .${MotifComponent.ROOT},
  .${MotifComponent.ROOT} * {
      box-sizing: border-box;
  }

  .${MotifComponent.ROOT} {
    ${textStyles}
    margin: 0;
    padding: 0;
  }

  .${MotifComponent.ROOT} h1, h1.${MotifComponent.ROOT} { ${h1Styles} }
  .${MotifComponent.ROOT} h2, h2.${MotifComponent.ROOT} { ${h2Styles} }
  .${MotifComponent.ROOT} h3, h3.${MotifComponent.ROOT} { ${h3Styles} }
  .${MotifComponent.ROOT} h4, h4.${MotifComponent.ROOT} { ${h4Styles} }
  .${MotifComponent.ROOT} h5, h5.${MotifComponent.ROOT} { ${h5Styles} }
  .${MotifComponent.ROOT} h6, h6.${MotifComponent.ROOT} { ${h6Styles} }
`;

export const textCss = css(textStyles);
export const h1Css = css(h1Styles);
export const h2Css = css(h2Styles);
export const h3Css = css(h3Styles);
export const h4Css = css(h4Styles);
export const h5Css = css(h5Styles);
export const h6Css = css(h6Styles);

export const p1Css = css`
  ${nonMobileMediaQuery} {
    font-size: ${m('--p1-desktop-font-size')};
    line-height: ${m('--p1-desktop-font-line-height')};
    font-weight: ${m('--p1-desktop-font-weight')};
    font-stretch: ${m('--p1-desktop-font-stretch')};
  }

  ${mobileMediaQuery} {
    font-size: ${m('--p1-mobile-font-size')};
    line-height: ${m('--p1-mobile-font-line-height')};
    font-weight: ${m('--p1-mobile-font-weight')};
    font-stretch: ${m('--p1-mobile-font-stretch')};
  }
`;

export const p2Css = css`
  ${nonMobileMediaQuery} {
    font-size: ${m('--p2-desktop-font-size')};
    line-height: ${m('--p2-desktop-font-line-height')};
    font-weight: ${m('--p2-desktop-font-weight')};
    font-stretch: ${m('--p2-desktop-font-stretch')};
  }

  ${mobileMediaQuery} {
    font-size: ${m('--p2-mobile-font-size')};
    line-height: ${m('--p2-mobile-font-line-height')};
    font-weight: ${m('--p2-mobile-font-weight')};
    font-stretch: ${m('--p2-mobile-font-stretch')};
  }
`;

export const p3Css = css`
  ${nonMobileMediaQuery} {
    font-size: ${m('--p3-desktop-font-size')};
    line-height: ${m('--p3-desktop-font-line-height')};
    font-weight: ${m('--p3-desktop-font-weight')};
    font-stretch: ${m('--p3-desktop-font-stretch')};
  }

  ${mobileMediaQuery} {
    font-size: ${m('--p3-mobile-font-size')};
    line-height: ${m('--p3-mobile-font-line-height')};
    font-weight: ${m('--p3-mobile-font-weight')};
    font-stretch: ${m('--p3-mobile-font-stretch')};
  }
`;

export const p4Css = css`
  ${nonMobileMediaQuery} {
    font-size: ${m('--p4-desktop-font-size')};
    line-height: ${m('--p4-desktop-font-line-height')};
    font-weight: ${m('--p4-desktop-font-weight')};
    font-stretch: ${m('--p4-desktop-font-stretch')};
  }

  ${mobileMediaQuery} {
    font-size: ${m('--p4-mobile-font-size')};
    line-height: ${m('--p4-mobile-font-line-height')};
    font-weight: ${m('--p4-mobile-font-weight')};
    font-stretch: ${m('--p4-mobile-font-stretch')};
  }
`;

/** A generic style for all clickable elements. */
export const actionCss = css`
  color: ${m('--action-default-color')};
  :hover {
    color: ${m('--action-hover-color')};
  }
  :active {
    color: ${m('--action-active-color')};
  }

  ${mobileMediaQuery} {
    font-size: ${m('--action-mobile-font-size')};
    line-height: ${m('--action-mobile-font-line-height')};
    font-weight: ${m('--action-mobile-font-weight')};
  }

  ${nonMobileMediaQuery} {
    font-size: ${m('--action-desktop-font-size')};
    line-height: ${m('--action-desktop-font-line-height')};
    font-weight: ${m('--action-desktop-font-weight')};
  }
`;
