import { gql } from '@apollo/client';

import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';

export const fragments = {
  all: gql`
    fragment AnalyticsAll on Analytics {
      ...ContentfulSysId
      label
    }
    ${contentfulSysIdFragment}
  `,
};

export const query = gql`
  query AnalyticsQuery($preview: Boolean!, $locale: String!, $id: String!) {
    analytics(preview: $preview, locale: $locale, id: $id) {
      ...AnalyticsAll
    }
  }
  ${fragments.all}
`;
