import { useCallback, useContext } from 'react';

import { AppContext } from '../AppContext';

const forcedArabicLocale = 'ar-EG-u-nu-latn';

type FormatDate = {
  formatDate: (date?: Date | string, dateFormatOptions?: Intl.DateTimeFormatOptions) => string;
};

/** Formats a date, forcing its locale to be ar-EG-u-nu-latn if active lang is Arabic. */
export function useFormatDate(): FormatDate {
  const { currentLocale } = useContext(AppContext);

  const formatDate = useCallback(
    (date: Date | string | undefined, dateFormatOptions?: Intl.DateTimeFormatOptions): string => {
      if (!date) return '';

      const effectiveLocale = currentLocale.startsWith('ar') ? forcedArabicLocale : currentLocale;

      const formatter = new Intl.DateTimeFormat(effectiveLocale, dateFormatOptions);
      return formatter.format(new Date(date));
    },
    [currentLocale]
  );

  return { formatDate };
}
