import { injectGlobal } from '@emotion/css';

import { globalCss } from '../../utils/globalCss';
import type { GlobalAnimation } from './types';

/**
 * Hook to load global animations using injectGlobal from emotion
 *
 * @param animations Animations array
 * @returns Null
 *
 *   Usage:
 *
 *       useAnimations([{ name: 'fadeIn', keyframes: 'from { opacity: 0; } to { opacity: 1; }' }]);
 */

export function useGlobalAnimations(animations: GlobalAnimation[] | undefined): null {
  if (!animations || animations.length === 0) {
    return null;
  }

  const globalAnimationStyles = animations
    .filter(item => item.keyframes)
    .map(
      item =>
        `@keyframes animation-${item.name} {
${item.keyframes}
}`
    )
    .join('\n');

  // Note: adding a global animation 'no-animation' to trigger the interception
  // observer if the initial animation style has a transform and puts the element
  // outside of the viewport
  injectGlobal(
    globalCss`${globalAnimationStyles} @keyframes no-animation { from { transform: none; } to {} }`
  );

  return null;
}
