import { css } from '@emotion/css';
import type { FC } from 'react';
import { useContext, useEffect } from 'react';

import { AppContext } from '../../AppContext';
import { Config } from '../../config';

const redirectContainerCss = css`
  padding: 20px;
`;

/**
 * Redirect component that will use the redirect function and therefore function for both server and
 * client, not just client.
 */
export const Redirect: FC<{ path: string; newTab?: boolean; isPermanent?: boolean }> = ({
  path,
  newTab,
  isPermanent,
}) => {
  const { onRedirect } = useContext(AppContext);

  // On the server, useEffect doesn't fire, so we need to immediately call this
  // function to record the new URL in the router.
  if (!Config.isClient) {
    onRedirect?.(path, { isPermanent, newTab });
  }

  useEffect(() => {
    onRedirect?.(path, { isPermanent, newTab });
  }, [onRedirect, path, newTab, isPermanent]);

  return (
    <section className={redirectContainerCss}>
      Redirecting to <a href={path}>{path}</a>.
    </section>
  );
};
