import { LoggingEventBus } from '@snapchat/logging';

import { SubscribedEventType } from './eventListenerTypes';
import type {
  ErrorEvent,
  InternalEvent,
  InternalTimingEvent,
  InternalValueEvent,
  LegacyAllEvents,
  LoggingContext,
  LoggingCustomEvents,
  LoggingPermissions,
  UserInteractionEvent,
  WarningEvent,
} from './loggingTypes';

// =================================================================================================
// Module Singleton
// =================================================================================================

export const logger = new LoggingEventBus<
  LoggingContext,
  LoggingCustomEvents,
  LoggingPermissions
>();

export const onMarketingTrackingAccepted = async (): Promise<void> => {
  logger && (await logger.allow('marketing'));
};

export const onPerformanceTrackingAccepted = async (): Promise<void> => {
  logger && (await logger.allow('performance'));
};

// =================================================================================================
// Helper Functions - provides type checking when logging different event types
// =================================================================================================

// TODO: We need to change the caller signatures to match the new interfaces.

export const logDebug = logger.logDebug;

export const logError = (event: Omit<ErrorEvent, 'subscribedEventType'>): void => {
  logger.logError(
    {
      error: event.error,
      component: event.component,
      message: event.message,
      action: event.action ?? 'Unknown',
    },
    event.context
  );
};

export const logWarning = (event: Omit<WarningEvent, 'subscribedEventType'>): void => {
  logger.logWarning(
    {
      component: event.component,
      message: event.message,
      action: 'Unknown',
    },
    event.context
  );
};

export const logInfo = (event: Omit<InternalEvent, 'subscribedEventType'>): void => {
  logger.logInfo(
    {
      component: event.eventCategory,
      action: event.eventAction,
      label: event.eventLabel ?? 'Unknown',
    },
    event.context
  );
};

// eslint-disable-next-line import/no-unused-modules
export const logValue = (event: Omit<InternalValueEvent, 'subscribedEventType'>): void => {
  logger.logValue(
    {
      component: event.eventCategory,
      label: event.eventLabel,
      value: event.eventValue,
      variable: event.eventVariable,
    },
    event.context
  );
};

// eslint-disable-next-line import/no-unused-modules
export const logTiming = (event: Omit<InternalTimingEvent, 'subscribedEventType'>): void => {
  logger.logTiming(
    {
      component: event.eventCategory,
      label: event.eventLabel,
      valueMs: event.eventValue,
      variable: event.eventVariable,
    },
    event.context
  );
};

export const logUserEvent = (event: Omit<UserInteractionEvent, 'subscribedEventType'>): void => {
  logger.logUserEvent({
    component: event.eventCategory,
    action: event.eventAction,
    label: event.eventLabel ?? 'Unknown',
  });
};

export const logEvent = (event: LegacyAllEvents): void => {
  switch (event.subscribedEventType) {
    case SubscribedEventType.USER_INTERACTION: {
      logger.logUserEvent({
        component: event.eventCategory,
        action: event.eventAction,
        label: event.eventLabel ?? 'Unknown',
      });
      break;
    }

    case SubscribedEventType.ERROR: {
      logger.logError(
        {
          error: event.error,
          component: event.component,
          message: event.message,
          action: 'Unknown', // TODO: Add this to the error type.
        },
        event.context
      );
      break;
    }

    case SubscribedEventType.INTERNAL: {
      logger.logInfo(
        {
          component: event.eventCategory,
          action: event.eventAction,
          label: event.eventLabel ?? 'Unknown',
        },
        event.context
      );
      break;
    }

    case SubscribedEventType.INTERNAL_TIMING: {
      logger.logTiming(
        {
          component: event.eventCategory,
          variable: event.eventVariable,
          valueMs: event.eventValue,
          label: event.eventLabel ?? 'Unknown',
        },
        event.context
      );
      break;
    }

    case SubscribedEventType.INTERNAL_VALUE: {
      logger.logValue(
        {
          component: event.eventCategory,
          variable: event.eventVariable,
          value: event.eventValue,
          label: event.eventLabel ?? 'Unknown',
        },
        event.context
      );
      break;
    }

    case SubscribedEventType.WARNING: {
      logger.logWarning(
        {
          component: event.component,
          message: event.message,
          action: 'Unknown',
          // NOTE: We're discarding context.
        },
        event.context
      );
      break;
    }

    case SubscribedEventType.ECOMMERCE:
    case SubscribedEventType.EXPERIMENT_IMPRESSION:
    case SubscribedEventType.PAGE_LOAD:
    case SubscribedEventType.FIRST_PAGE_LOAD:

    // fall through
    case SubscribedEventType.PHONE_NUMBER_EVENT: {
      logger.logCustom(event, event.context);
      break;
    }
  }
};

export const logCustom = logger.logCustom;
