import { css } from '@emotion/css';

import { m } from '../../../motif/m';

export const navigatorItemMobileCss = css`
  display: flex;
  flex-direction: column;
  gap: ${m('--spacing-s')};
  padding: ${m('--spacing-m')} 0;
  padding-inline-start: ${m('--spacing-m')};
`;

export const navigatorItemMobileColCss = css`
  display: flex;
  flex-direction: column;
  gap: ${m('--spacing-s')};
`;

export const navigatorItemMobileColWithDividerCss = css`
  border-top: 1px solid ${m('--global-header-menu-col-divider-color')};
  margin-top: ${m('--spacing-s')};
  padding-top: ${m('--spacing-l')};
`;

export const navigatorItemMobileTextCss = css`
  && {
    color: ${m('--global-header-menu-item-fg-color')};
    font-size: ${m('--p2-desktop-font-size')};
    font-weight: 400;
    line-height: ${m('--p2-desktop-font-line-height')};

    a {
      color: ${m('--global-header-menu-item-fg-color')};
    }
  }
`;

export const navigatorItemMobileColLabelCss = css`
  && {
    color: ${m('--global-header-menu-col-label-color')};
    font-size: ${m('--p3-desktop-font-size')};
    font-weight: 500;
    letter-spacing: -0.01em;
    line-height: ${m('--p3-desktop-font-line-height')};
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
  }
`;

export const navigatorItemMobileFeaturedItemCss = css`
  margin-top: ${m('--spacing-s')};
  margin-inline-start: calc(${m('--spacing-m')} * -1);

  :first-child {
    margin-top: 0;
  }
`;
