import { ApolloLink, Observable } from '@apollo/client';

import { logTiming } from '../../helpers/logging';

/* NOTE:
   There is a limitation with Apollo where you need to wrap operations in new Operation/Observer
   rather than append additional subcribers.  Appending causes the operation to be fired multiple times.
   Relevant GitHub Thread: https://github.com/apollographql/apollo-link/issues/298

   Implementation is based on this example from the Apollo GraphQL repository:
   https://github.com/apollographql/apollo-link/blob/638b78672a9b4adc5283e5710150da701e8e4bbd/packages/apollo-link-error/src/index.ts
 */

/**
 * Apollo link that records request latency.
 *
 * Tries to extract helpful headers from the contentful response.
 *
 * This is meant to be used as a debugging log tool for troubleshooting responses from contentful.
 */
export const requestLatencyLogLink = new ApolloLink((operation, forward) => {
  const queryName = operation.operationName;
  const locale = operation.variables.locale ?? 'en-US';
  const eventCategory = 'Contentful';
  const eventVariable = 'request_duration';
  const requestUrl = operation.getContext().currentUrl as string;
  // NOTE: We log the path now to be able to analyze slower queries on certain sites/pages.

  const requestPath = requestUrl ? new URL(requestUrl).pathname : '/';
  // TODO: Consider adding request complexity and response size here.
  const logContext = {
    queryName,
    locale,
    requestPath,
  };

  // This is a wrapper around the original operation that we can use to measure latency.
  return new Observable(latencyObserver => {
    // NOTE: Setting this here has some overhead, but it's typically <0.01ms.
    operation.setContext({ startMs: performance.now() });

    const subscription = forward(operation).subscribe({
      // Forward to next link in the chain.
      next: result => {
        latencyObserver.next(result);
      },

      // Fires on going back up the chain on success.
      complete: () => {
        const durationMs = performance.now() - operation.getContext().startMs;
        const response = operation.getContext().response as Response;
        const responseStatus = response?.status ?? 200;

        logTiming({
          eventCategory,
          eventVariable,
          eventValue: durationMs,
          context: {
            ...logContext,
            responseStatus,
            contentfulRequestStatus: 'complete',
            // NOTE: These headers aren't being logged on the client for some reason.
            // TODO: Figure out why. They're in the network tab but are absent from the headers here.
            // Works fine on the server.
            'x-contentful-region': response?.headers?.get('x-contentful-region'),
            'x-cache': response?.headers?.get('x-cache'),
            // When contentful support is contacted, they want these request ids provided to them.
            'x-contentful-request-id': response?.headers?.get('x-contentful-request-id'),
            'x-served-by': response?.headers?.get('x-served-by'),
          },
        });

        latencyObserver.complete();
      },

      // Fires on going back up the chain on error.
      error: error => {
        const durationMs = performance.now() - operation.getContext().startMs;
        const response = operation.getContext().response as Response;
        const responseStatus = response?.status ?? 500;

        logTiming({
          eventCategory,
          eventVariable,
          eventValue: durationMs,

          context: {
            ...logContext,
            responseStatus,
            // NOTE: The error is logged in a separate link and does not need to be replicated here.
            contentfulRequestStatus: 'error',
            // NOTE: These headers aren't being logged on the client for some reason.
            // TODO: Figure out why. They're in the network tab but are absent from the headers here.
            // Works fine on the server.
            'x-contentful-region': response?.headers?.get('x-contentful-region'),
            'x-cache': response?.headers?.get('x-cache'),
            // When contentful support is contacted, they want these request ids provided to them.
            'x-contentful-request-id': response?.headers?.get('x-contentful-request-id'),
            'x-served-by': response?.headers?.get('x-served-by'),
          },
        });

        latencyObserver.error(error);
      },
    });

    return () => {
      subscription.unsubscribe();
    };
  });
});
