import { gql } from '@apollo/client';

export const contentfulSysIdFragment = gql`
  fragment ContentfulSysId on Entry {
    sys {
      id
    }
    __typename
  }
`;

export type ContentfulSysId = {
  sys: {
    id: string;
  };
};
