import type { PlatformString } from '../server';

export const areQueryParamsValid = (
  version: string | null,
  platform: PlatformString | null,
  port: string | null
): boolean => {
  if (platform?.toLowerCase() !== 'mac_os' && platform?.toLowerCase() !== 'windows') {
    return false;
  }

  const versionToVerify = version?.replace(/[-_.]/gm, '_') ?? '';

  if (!/(\d+_){0,3}(\d+){1}/g.test(versionToVerify)) {
    return false;
  }

  return Number.isInteger(Number.parseInt(port ?? ''));
};
