import type { FC, PropsWithChildren } from 'react';
import { createContext, useState } from 'react';

type ContentfulLivePreviewContext = {
  hasLivePreview?: boolean;
  setHasLivePreview?: (value: boolean) => void;
};

export const ContentfulLivePreviewContext = createContext<ContentfulLivePreviewContext>({});

export const ContentfulLivePreviewContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [hasLivePreview, setHasLivePreview] = useState(false);

  return (
    <ContentfulLivePreviewContext.Provider
      value={{
        hasLivePreview,
        setHasLivePreview: (hasLivePreview: boolean) => setHasLivePreview(hasLivePreview),
      }}
    >
      {children}
    </ContentfulLivePreviewContext.Provider>
  );
};
