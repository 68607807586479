import { useEffect, useState } from 'react';

/**
 * Represents the window focus state.
 *
 * @property {boolean} isFocused - Indicates whether the window is currently focused (true) or
 *   blurred (false).
 * @interface WindowFocused
 */
export interface WindowFocused {
  isFocused: boolean;
}

/**
 * Hook to track the focus state of the window.
 *
 * @function useWindowFocused
 * @param {boolean} enabled - Indicates whether to enable focus tracking.
 * @returns {WindowFocused} An object containing the window focus state.
 */
export function useWindowFocused(enabled: boolean): WindowFocused {
  const [isFocused, setIsFocused] = useState(true);

  useEffect(() => {
    const handleBlur = () => setIsFocused(false);
    const handleFocus = () => setIsFocused(true);

    if (enabled) {
      window.addEventListener('blur', handleBlur);
      window.addEventListener('focus', handleFocus);
    } else {
      window.removeEventListener('blur', handleBlur);
      window.removeEventListener('focus', handleBlur);
    }

    return () => {
      window.removeEventListener('blur', handleBlur);
      window.removeEventListener('focus', handleBlur);
    };
  }, [enabled]);

  return {
    isFocused,
  };
}
