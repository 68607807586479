import { useAsyncData } from '@snapchat/async-data-browser';
import { BrowserFeaturesContext } from '@snapchat/snap-design-system-marketing';
import { useContext, useMemo, useRef } from 'react';

import { AppContext } from '../../AppContext';
import { Config } from '../../config';
import { logger } from '../../helpers/logging';
import { defaultTileSorting, defaultTilesPerPage } from './constants';
import type { GalleryTilesSortingType, PaginationDataProps } from './types';

type GalleryData = {
  paginationData: PaginationDataProps | undefined;
  isLoading: boolean;
};

type GalleryTilesProps = {
  galleryId: string;
  page: number;
  pageSize?: number;
  tags: string[];
  sortBy?: GalleryTilesSortingType;
};

/**
 * Returns the data needed to render the pagination and tiles components
 *
 * @param galleryId Id of the gallery we want to query
 * @param page Page number to use in the query
 * @param tags List of values from dropdown filters
 */
export function useGalleryTiles({
  galleryId,
  page,
  tags,
  pageSize = defaultTilesPerPage,
  sortBy = defaultTileSorting,
}: GalleryTilesProps): GalleryData {
  const appContext = useContext(AppContext);
  const browserFeatures = useContext(BrowserFeaturesContext);
  const previousTotal = useRef(0);

  const params = useMemo(() => {
    const params = new URLSearchParams();

    params.append('galleryId', galleryId);
    params.append('pageSize', `${pageSize}`);
    params.append('sortBy', sortBy);

    if (tags.length) {
      params.append('tags', tags.join(','));
    }

    params.append('page', `${page - 1}`);

    if (appContext.currentLocale) {
      params.append('locale', appContext.currentLocale);
    }

    if (appContext.userLocation.country) {
      params.append('country', appContext.userLocation.country);
    }

    const platform = browserFeatures.getLowEntropyHints().platform;

    if (platform) {
      params.append('platform', platform);
    }

    const device = browserFeatures.getLowEntropyHints().isMobile ? 'Mobile' : 'Non-Mobile';

    if (device) {
      params.append('device', device);
    }

    return params;
  }, [
    galleryId,
    tags,
    page,
    pageSize,
    appContext.currentLocale,
    appContext.userLocation.country,
    browserFeatures,
    sortBy,
  ]);

  const host = Config.isClient ? '' : `http://localhost:${process.env.PORT ?? 3000}`;
  const url = `${host}/api/gallery?${params}`;

  const { data, isLoading } = useAsyncData({
    dataId: `/api/gallery?${params}`,
    dataAsync: async () => {
      try {
        const response = await fetch(url);
        const data = await response.json();
        return data;
      } catch (error) {
        logger.logError({
          component: 'UseGalleryTiles',
          message: 'Error loading gallery tiles',
          error,
        });
      }
    },
  });

  if (data?.total) {
    previousTotal.current = data?.total;
  }

  return {
    paginationData: isLoading ? { tiles: [], total: previousTotal.current } : data,
    isLoading,
  };
}
