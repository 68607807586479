import kebabCase from 'lodash-es/kebabCase';

/**
 * Converts dataset property map to attributes that React can consume.
 *
 * Based on this gist: https://gist.github.com/t1ger-0527/f839a3f59bdcad3300e3e4865bb09027 React
 * still has no support: https://github.com/facebook/react/issues/1259 But browsers support this:
 * https://caniuse.com/dataset
 *
 * Note that dataset should be used for storing debug information for browser automation and manual
 * debugging, but not for storing state or styling. See this article:
 * https://reactjs.org/blog/2017/09/08/dom-attributes-in-react-16.html#should-i-keep-data-in-custom-attributes
 */
export const dataSetToAttributes = (dataset?: DOMStringMap): DOMStringMap | undefined => {
  if (!dataset) {
    return undefined;
  }
  const attributeEntries = Object.entries(dataset);
  const dataSetEntries = attributeEntries.map(([key, value]) => [`data-${kebabCase(key)}`, value]);
  return Object.fromEntries(dataSetEntries);
};
