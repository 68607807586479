import type { FC } from 'react';

import type { ContentfulIdVariable } from '../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../hooks/useContentfulQuery';
import type { ContentfulTypedSysProps } from '../../types/contentful';
import { Mosaic } from './Mosaic';
import { query } from './query';
import type { MosaicDataHandlerProps } from './types';

export const MosaicShallow: FC<ContentfulTypedSysProps<'Mosaic'>> = props => {
  const { data } = useContentfulQuery<MosaicDataHandlerProps, ContentfulIdVariable>(query.all, {
    variables: { id: props.sys.id },
  });

  if (!data?.mosaic) return null;
  return <Mosaic {...data.mosaic} __typename="Mosaic" />;
};
MosaicShallow.displayName = 'Mosaic';
