import type { ValueOf } from '../types/ValueOf';

/**
 * Actions that we track that the user can do.
 *
 * These have to be context-free. I.e. "Click" instead of "HeaderClick" or "Scroll" instead of
 * "FooScroll"
 */
export const Action = {
  Click: 'Click',
  Warning: 'Warning',
  LocaleSelect: 'LocaleSelect',
} as const;

export type Action = ValueOf<typeof Action>;
