/* eslint-disable react/jsx-key */

// Dependencies
import { cx } from '@emotion/css';
import type { KeyboardEventHandler, ThHTMLAttributes } from 'react';
import { Fragment, useContext } from 'react';
import type { Column, HeaderGroup, Row } from 'react-table';
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';

import { MessageContext } from '../../contexts';
import { useMotifStyles } from '../../motif';
import { MotifComponent } from '../../motif/motifConstants';
import type { OnActivateHandler } from '../../types/activationEvents';
import { Button } from '../Button';
import { Icon } from '../Icon';
// Components
import { SortIcon } from './SortIcon';
// Styles
import {
  rowWithSubRowCss,
  subRowCellCss,
  tableControlsCss,
  tableStylesCss,
  tableWrapperStylesCss,
  tCellCss,
  tCellHeadCss,
  tGroupStylesCss,
  tRowStylesCss,
  viewAllButtonCss,
} from './styles';

type TableProps<Data extends object> = {
  data: Data[];
  columns: Column<Data>[];
  className?: string;
  sortable?: boolean;
  searchable?: boolean;
  initialRowCount?: number;
  renderSortIcon?: (isSorted: boolean, isSortedDesc?: boolean) => JSX.Element;
  isLoading?: boolean;
  renderSubRow?: (data: Data) => JSX.Element | null;
};

export function Table<Data extends object>({
  data,
  columns,
  className,
  sortable,
  searchable,
  initialRowCount,
  renderSortIcon,
  renderSubRow,
}: TableProps<Data>): JSX.Element {
  const { formatMessage } = useContext(MessageContext);
  useMotifStyles(MotifComponent.TABLE);

  const searchMessage = formatMessage({
    id: 'table-search-placeholder',
    defaultMessage: 'Type to search...',
  });

  const viewAllMessage = formatMessage({
    id: 'table-view-all',
    defaultMessage: 'View All',
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    setPageSize,
    state,
  } = useTable<Data>(
    {
      columns,
      data,
      initialState: { pageSize: initialRowCount ?? data.length },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const onViewAll = () => setPageSize(data.length);

  const onKeyPress =
    (onClick: OnActivateHandler): KeyboardEventHandler<unknown> =>
    event => {
      if (event.key === 'Enter' || event.key === ' ') {
        onClick(event);
      }
    };

  const renderHeaderGroup = (headerGroup: HeaderGroup<Data>) => {
    const { key: headerGroupKey, ...headerGroupProps } = headerGroup.getHeaderGroupProps();

    return (
      // Apply the header row props
      <tr key={headerGroupKey} className={tRowStylesCss} {...headerGroupProps}>
        {
          // Loop over the headers in each row
          headerGroup.headers.map(column => {
            // Apply the header cell props
            const { key: headerKey, ...headerProps } = column.getHeaderProps(
              sortable ? column.getSortByToggleProps() : undefined
            ) as ThHTMLAttributes<HTMLTableHeaderCellElement> & { key: string };
            const ariaSort = !column.isSorted
              ? 'none'
              : column.isSortedDesc
              ? 'descending'
              : 'ascending';
            const accessibleProps = sortable
              ? ({
                  tabIndex: 0,
                  // @ts-ignore ActivateHandler not compatible with mouse handler, fix later
                  onKeyPress: onKeyPress(headerProps.onClick),
                  'aria-sort': ariaSort,
                  scope: 'col',
                  role: 'columnheader',
                } as ThHTMLAttributes<HTMLTableHeaderCellElement>)
              : undefined;

            return (
              <th
                key={headerKey}
                className={cx(tCellCss, tCellHeadCss)}
                {...headerProps}
                {...accessibleProps}
              >
                {
                  // Render the header
                  column.render('Header')
                }
                <SortIcon column={column} sortable={sortable} renderSortIcon={renderSortIcon} />
              </th>
            );
          })
        }
      </tr>
    );
  };

  const renderRow = (row: Row<Data>, index: number) => {
    // Prepare the row for display
    prepareRow(row);

    const rowData = data[index];
    const subRow = rowData ? renderSubRow?.(rowData) : null;

    const { key: rowKey, ...rowProps } = row.getRowProps();

    return (
      // Apply the row props
      <Fragment key={rowKey}>
        <tr className={tRowStylesCss} {...rowProps}>
          {
            // Loop over the rows cells
            row.cells.map(cell => {
              // Apply the cell props
              const { key: cellKey, ...cellProps } = cell.getCellProps();
              return (
                <td
                  key={cellKey}
                  className={cx(tCellCss, {
                    [rowWithSubRowCss]: !!subRow,
                  })}
                  {...cellProps}
                >
                  {
                    // Render the cell contents
                    cell.render('Cell')
                  }
                </td>
              );
            })
          }
        </tr>
        {subRow && (
          <tr key={`sub-${rowKey}`} className={tRowStylesCss}>
            <td colSpan={row.cells.length} className={cx(tCellCss, subRowCellCss)}>
              {subRow}
            </td>
          </tr>
        )}
      </Fragment>
    );
  };

  return (
    // apply the table props
    <div className={cx(MotifComponent.TABLE, tableWrapperStylesCss)}>
      <div className={tableControlsCss}>
        {searchable && (
          <>
            <Icon name="search" />
            <input
              value={state.globalFilter}
              placeholder={searchMessage}
              onChange={event => setGlobalFilter(event.target.value)}
            />
          </>
        )}
        {initialRowCount && (
          <Button className={viewAllButtonCss} type="Flat" onClick={onViewAll}>
            {viewAllMessage}
          </Button>
        )}
      </div>
      <table className={cx(tableStylesCss, className)} {...getTableProps()}>
        <thead className={tGroupStylesCss}>
          {
            // Loop over the header rows
            headerGroups.map(renderHeaderGroup)
          }
        </thead>
        {/* Apply the table body props */}
        <tbody className={tGroupStylesCss} {...getTableBodyProps()}>
          {page.map(renderRow)}
        </tbody>
      </table>
    </div>
  );
}
