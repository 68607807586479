// eslint-disable-next-line max-len
import { GlobalNavHighlightReel as GlobalNavHighlightReelSDS } from '@snapchat/snap-design-system-marketing';
import type { FC, ReactElement } from 'react';

import type { GlobalNavAllType } from '../../generated/contentful-types';
import { GlobalNavHighlight } from '../GlobalNavHighlight/GlobalNavHighlight';

interface Props {
  navGroups: ReadonlyArray<GlobalNavAllType>;
}

export const GlobalNavHighlightCollection: FC<Props> = props => {
  const highlights = new Map<string, ReactElement>();

  props.navGroups
    .filter(group => !!group.highlight)
    .forEach(navGroup => {
      highlights.set(navGroup.groupKey, <GlobalNavHighlight {...navGroup.highlight} />);
    });

  return <GlobalNavHighlightReelSDS highlights={highlights} />;
};
GlobalNavHighlightCollection.displayName = 'GlobalNavHighlightCollection';
