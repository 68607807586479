import { css, cx } from '@emotion/css';
import type { FC } from 'react';

import { useIsMobile } from '../../hooks';
import { MotifComponent, useMotifStyles } from '../../motif';
import type { IconName } from '../Icon';
import { ToggleButton } from '../ToggleButton';
import { ToggleSlider } from '../ToggleSlider';
import { labelCss, togglePanelCss, veritcalDividerCss, withSliderCss } from './styles';

export interface TogglePanelProps {
  buttonIcons: Array<IconName>;
  className?: string;
  hasToggleSlider: boolean;
  id: string;
  items: Array<TogglePanelItem>;
  label?: string;
  onButtonToggle?: (arg0: number) => void;
  onSliderUpdate?: (arg0: boolean) => void;
  selectedId: string;
  sliderChecked: boolean;
}
export interface TogglePanelItem {
  id: string;
  tooltip?: string;
}

export interface ToggleButtonData {
  text?: string;
  icon: string;
  selected: boolean;
  id: string;
}

const paddingLeftCss = css`
  padding-left: 16px;
`;

export const TogglePanel: FC<TogglePanelProps> = ({
  buttonIcons,
  className,
  hasToggleSlider,
  id,
  items,
  label,
  onSliderUpdate,
  onButtonToggle,
  selectedId,
  sliderChecked,
}) => {
  const sliderEnabled = hasToggleSlider;
  useMotifStyles(MotifComponent.TOGGLE_PANEL);
  const isMobile = useIsMobile();

  const handleToggleButtonClick = (index: number) => {
    onButtonToggle?.(index);
  };

  const handleToggleSliderClick = () => {
    onSliderUpdate?.(!sliderChecked);
  };

  const renderButtons = () => {
    return items.map((item, index) => {
      const { id: itemId } = item;
      return (
        <ToggleButton
          id={itemId}
          key={itemId}
          index={index}
          icon={buttonIcons[index] ?? 'waffle'}
          tooltip={item.tooltip}
          selected={itemId === selectedId}
          onClick={handleToggleButtonClick}
          iconSize={isMobile ? 20 : 26}
        />
      );
    });
  };

  const renderToggleSection = () => {
    return (
      <>
        <span className={veritcalDividerCss} />
        <span className={labelCss}>{label}</span>
        <ToggleSlider
          id={`${id}-slider`}
          onToggle={handleToggleSliderClick}
          isChecked={sliderChecked}
          className={paddingLeftCss}
        />
      </>
    );
  };

  return (
    <div
      className={cx(
        MotifComponent.TOGGLE_PANEL,
        togglePanelCss,
        sliderEnabled && withSliderCss,
        className
      )}
    >
      {renderButtons()}
      {hasToggleSlider && renderToggleSection()}
    </div>
  );
};
