import type { FC } from 'react';

import { contentContainerStyle1ColumnCss, panelsCss } from './styles';
import type { ChartToggleItemProps } from './types';
export interface Props {
  items: Array<ChartToggleItemProps>;
  selectedChart: number;
}

export const TabsPanels: FC<Props> = ({ items, selectedChart }) => {
  return (
    <div className={panelsCss} role="tabpanel">
      {items.map(
        ({ content }, index) =>
          selectedChart === index && (
            <div key={index} className={contentContainerStyle1ColumnCss}>
              {content}
            </div>
          )
      )}
    </div>
  );
};
