import { css } from '@emotion/css';

import { maxWidth, mobileMediaQuery } from '../../constants';
import { m } from '../../motif/m';

export const footnotesCss = css`
  background-color: ${m('--footnote-bg-color')};
  border-top: 1px solid ${m('--footnote-border-color')};
`;

export const footnotesContentCss = css`
  color: ${m('--footnote-fg-color')};
  margin: 0px auto;
  max-width: ${maxWidth}px;
  padding: 0 ${m('--spacing-xl')};

  ${mobileMediaQuery} {
    padding: 0 ${m('--spacing-m')};
  }
`;

export const footnotesPrintCss = css`
  display: none;

  @media print {
    display: block;
    padding-block: ${m('--spacing-l')};
  }
`;

export const footnoteDetailsCss = css`
  padding-bottom: 0;

  @media print {
    display: none;
  }
`;

export const footnoteAccordionItemSummaryCss = css`
  color: ${m('--footnote-title-color')};
  font-size: ${m('--h6-desktop-font-size')};
  font-weight: 500;
  height: 92px;
  line-height: 28px;
  margin: 0 auto;
  max-width: ${maxWidth}px;
  padding-inline-start: ${m('--spacing-xl')};

  ${mobileMediaQuery} {
    padding: 0 ${m('--spacing-m')};
  }

  & > svg {
    background-color: transparent;
    border-radius: 100%;
    height: 32px;
    padding: 6px;
    transition: background-color 0.2s linear;
    width: 32px;
  }

  &:hover {
    & > svg {
      background-color: ${m('--footnote-hover-icon-bg-color')};
    }
  }
`;

export const footnoteAccordionItemContentCss = css`
  padding-bottom: ${m('--spacing-m')};
`;
