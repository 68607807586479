import { css } from '@emotion/css';

import { mobileMediaQuery, nonMobileMediaQuery, Plain, ZIndex } from '../../constants';
import { m } from '../../motif/m';

// Value taken from designs. Jira ticket: https://jira.sc-corp.net/browse/WEBP-10648
const contentMaxWidthPx = 340;
const contentMaxWidthPxMobile = 640;

export const sideOverlayCss = css`
  background-color: ${m('--side-overlay-bg-color')};
  border: 1px solid ${m('--side-overlay-border-color')};
  box-shadow: 0 12px 20px 0 #0000001f;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: fixed;
  transition: transform 200ms ease-in-out, visibility 200ms ease-in-out;
  visibility: hidden;
  z-index: ${ZIndex.SIDE_OVERLAY};

  ${nonMobileMediaQuery} {
    border-radius: ${m('--border-radius-l')};
    height: calc(100vh - var(--total-header-height, 0px) - (2 * ${m('--spacing-l')}));
    max-width: ${contentMaxWidthPx}px;
    right: ${m('--spacing-l')};
    top: calc(var(--total-header-height, 0px) + ${m('--spacing-l')});
    transform: translateX(calc(100% + ${m('--spacing-l')}));
    width: ${contentMaxWidthPx}px;

    *[dir='rtl'] & {
      left: ${m('--spacing-l')};
      right: auto;
      transform: translateX(calc(-100% - ${m('--spacing-l')}));
    }
  }

  ${mobileMediaQuery} {
    border-radius: ${m('--border-radius-l')} ${m('--border-radius-l')} 0 0;
    bottom: 0;
    height: auto;
    left: 50%;
    max-height: calc(100vh - var(--total-header-height, 0px) - ${m('--spacing-m')});
    max-width: ${contentMaxWidthPxMobile}px;
    transform: translateX(-50%) translateY(100%);
    width: calc(100vw - (2 * ${m('--spacing-m')}));

    *[dir='rtl'] & {
      left: 50%;
      right: auto;
    }
  }
`;

export const sideOverlayActiveCss = css`
  visibility: visible;

  ${nonMobileMediaQuery} {
    transform: translateX(0);

    *[dir='rtl'] & {
      transform: translateX(0);
    }
  }

  ${mobileMediaQuery} {
    transform: translateX(-50%) translateY(0);
  }
`;

export const sideOverlayBackdropCss = css`
  background-color: ${Plain.BLACK};
  display: none;
  height: 100vh;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: opacity 200ms linear, visibility 200ms linear;
  visibility: hidden;
  width: 100vw;
  z-index: ${ZIndex.SIDE_OVERLAY};

  ${mobileMediaQuery} {
    display: block;
  }
`;

export const sideOverlayBackdropActiveCss = css`
  opacity: 0.3;
  visibility: visible;
`;

export const sideOverlayHeaderCss = css`
  display: flex;
  font-size: ${m('--h6-desktop-font-size')};
  font-weight: ${m('--h6-desktop-font-weight')};
  justify-content: end;
  line-height: ${m('--h6-desktop-font-line-height')};
  padding: ${m('--spacing-m')};
`;

export const sideOverlayCloseButtonCss = css`
  background: transparent;
  border: none;
  cursor: pointer;
  margin: calc(${m('--spacing-m')} * -1);
  padding: ${m('--spacing-m')};
`;

export const sideOverlayListCss = css`
  height: 100%;
  margin: 0;
  overflow-y: auto;
  padding: ${m('--spacing-xs')} ${m('--spacing-l')} ${m('--spacing-l')} ${m('--spacing-l')};

  ${mobileMediaQuery} {
    padding-bottom: ${m('--spacing-xxxxl')};
  }
`;

export const sideOverlayTermCss = css`
  color: ${m('--side-overlay-fg-color')};
  font-size: ${m('--h6-desktop-font-size')};
  font-weight: ${m('--h6-desktop-font-weight')};
  line-height: ${m('--h6-desktop-font-line-height')};
  margin: 0 0 ${m('--spacing-s')} 0;
  padding: 0;
`;

export const sideOverlayDefinitionCss = css`
  color: ${m('--side-overlay-fg-color')};
  font-size: ${m('--p2-desktop-font-size')};
  font-weight: ${m('--p2-desktop-font-weight')};
  line-height: ${m('--p2-desktop-font-line-height')};
  margin: 0;
  padding: 0;
`;
