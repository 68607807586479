import { cx } from '@emotion/css';

import {
  dotActiveCss,
  dotCss,
  dotsContainerCss,
  dotsInnerContainerCss,
  dotWrapperCss,
} from './CarouselV3.styles';

/**
 * Props for a component that represents a set of dots used for carousel navigation.
 *
 * @property {number} currentIndex - The index of the currently active slide.
 * @property {number} length - The total number of slides.
 * @property {(index: number) => void} onDotClick - A callback function to handle dot click events.
 *   It receives the index of the clicked dot.
 * @property {boolean} [isInfinite] - (Optional) Specifies whether the carousel is infinite.
 * @property {() => void} [onAnimationComplete] - (Optional) A callback function invoked when the
 *   slide transition animation is complete.
 * @interface DotsProps
 */
export interface DotsProps {
  currentIndex: number;
  length: number;
  onDotClick: (index: number) => void;
  isInfinite?: boolean;
  onAnimationComplete?: () => void;
}

export const Dots = ({ currentIndex, length, onDotClick }: DotsProps): JSX.Element => {
  return (
    <nav className={dotsContainerCss}>
      <ul className={dotsInnerContainerCss}>
        {Array(length)
          .fill(undefined)
          .map((_, index) => (
            <li key={index} data-index={index}>
              <button onClick={() => onDotClick(index)} className={dotWrapperCss}>
                <span
                  className={cx(dotCss, {
                    [dotActiveCss]: currentIndex === index,
                  })}
                />
              </button>
            </li>
          ))}
      </ul>
    </nav>
  );
};

Dots.displayName = 'Dots';
