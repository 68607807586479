export enum Layout {
  DENSE = 'Dense',
  NORMAL = 'Normal',
}

export enum MediaDirection {
  BOTTOM = 'Bottom',
  LEFT = 'Left',
  RIGHT = 'Right',
  TOP = 'Top',
}

export enum DisplayMode {
  ALL = 'All',
  DESKTOP_ONLY = 'DesktopOnly',
  MOBILE_ONLY = 'MobileOnly',
  NEITHER = 'Neither', // whoa
}
