import { cx } from '@emotion/css';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import type { ScaleOrdinal } from 'd3-scale';
import type { FC } from 'react';
import { useEffect } from 'react';

import {
  tooltipColoredDotCss,
  tooltipDescriptionCss,
  tooltipHeadingCss,
  tooltipPortalCss,
  tooltipWrapperCss,
} from '../styles';
import { formatBarLabel } from '../utils/helpers';
import type { BarItemValue } from '../utils/types';

export type BarTooltipProps = {
  tooltipData: TooltipData;
  tooltipTop?: number;
  tooltipLeft?: number;
  colorScale?: ScaleOrdinal<string, string>;
  showAsPercent?: boolean;
  numberOfDecimalPlaces?: number;
};

type TooltipData = {
  value: BarItemValue;
  groupName: string;
  subGroupName: string;
  colorKey: string;
};

export const BarTooltip: FC<BarTooltipProps> = props => {
  const { tooltipData, tooltipLeft, tooltipTop, colorScale, showAsPercent, numberOfDecimalPlaces } =
    props;

  const { tooltipOpen, hideTooltip, showTooltip } = useTooltip<TooltipData>();
  const { TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  useEffect(() => {
    showTooltip({
      tooltipData,
      tooltipTop,
      tooltipLeft,
    });

    return () => {
      hideTooltip();
    };
  }, [hideTooltip, showTooltip, tooltipData, tooltipLeft, tooltipTop]);

  if (!tooltipOpen || !tooltipData) return null;

  // NOTE: This is always using primary motif and therefore cannot be themed.

  return (
    <TooltipInPortal
      top={tooltipTop}
      left={tooltipLeft}
      className={cx('sdsm-bar-tooltip', 'sdsm', tooltipPortalCss)}
      unstyled
    >
      <div className={tooltipWrapperCss}>
        <article className={tooltipHeadingCss}>
          <div>{formatBarLabel(tooltipData.value, showAsPercent, numberOfDecimalPlaces)}</div>
        </article>
        <aside className={tooltipDescriptionCss}>
          <span
            className={tooltipColoredDotCss}
            style={{ backgroundColor: colorScale?.(tooltipData.colorKey) }}
          />
          <section>
            {tooltipData.groupName} {tooltipData.colorKey} {tooltipData.subGroupName}
          </section>
        </aside>
      </div>
    </TooltipInPortal>
  );
};
