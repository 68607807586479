import type { Block as RichTextBlock } from '@contentful/rich-text-types';
import type { ContentfulClientApi, Entry, LocaleCode } from 'contentful';

import type {
  TypeAccordion,
  TypeBarChart,
  TypeBarChartGroup,
  TypeBarChartStack,
  TypeBlock,
  TypeBlockHero,
  TypeContent,
  TypeCriteria,
  TypeExperiment,
  TypeGeoVisualization,
  TypeLineChart,
  TypeMetas,
  TypePage,
  TypePageAsset,
  TypeSlug,
  TypeTabItem,
  TypeTableVisualization,
  TypeTabs,
  TypeVisualizationSelector,
} from '../generated';

export type ContentfulClient = ContentfulClientApi<
  'WITH_ALL_LOCALES' | 'WITHOUT_UNRESOLVABLE_LINKS'
>;

export type Locales = LocaleCode | 'en-US';

export type FieldsWithLocales<FieldType> = {
  [Field in keyof FieldType]: {
    [key in Locales]: FieldType[Field];
  };
};

export type BarChartType = Entry &
  TypeBarChart<'WITH_ALL_LOCALES' | 'WITHOUT_UNRESOLVABLE_LINKS', Locales>;

export type BarChartGroupType = Entry &
  TypeBarChartGroup<'WITH_ALL_LOCALES' | 'WITHOUT_UNRESOLVABLE_LINKS', Locales>;

export type BarChartStackType = Entry &
  TypeBarChartStack<'WITH_ALL_LOCALES' | 'WITHOUT_UNRESOLVABLE_LINKS', Locales>;

export type LineChartType = Entry &
  TypeLineChart<'WITH_ALL_LOCALES' | 'WITHOUT_UNRESOLVABLE_LINKS', Locales>;

export type GeoVisualizationType = Entry &
  TypeGeoVisualization<'WITH_ALL_LOCALES' | 'WITHOUT_UNRESOLVABLE_LINKS', Locales>;

export type MultiVisualizationType = Entry &
  TypeVisualizationSelector<'WITH_ALL_LOCALES' | 'WITHOUT_UNRESOLVABLE_LINKS', Locales>;

export type TableVisualizationType = Entry &
  TypeTableVisualization<'WITH_ALL_LOCALES' | 'WITHOUT_UNRESOLVABLE_LINKS', Locales>;

export type BlockHeroType = Entry &
  TypeBlockHero<'WITH_ALL_LOCALES' | 'WITHOUT_UNRESOLVABLE_LINKS', Locales>;

export type ExperimentType = Entry &
  TypeExperiment<'WITH_ALL_LOCALES' | 'WITHOUT_UNRESOLVABLE_LINKS', Locales>;

export type PageAssetType = Entry &
  TypePageAsset<'WITH_ALL_LOCALES' | 'WITHOUT_UNRESOLVABLE_LINKS', Locales>;

export type CriteriaType = Entry &
  TypeCriteria<'WITH_ALL_LOCALES' | 'WITHOUT_UNRESOLVABLE_LINKS', Locales>;

export type BlockContentsType =
  | ContentType
  | TabsType
  | AccordionType
  | BarChartType
  | BarChartGroupType
  | BarChartStackType
  | LineChartType
  | GeoVisualizationType
  | MultiVisualizationType
  | TableVisualizationType;

// We have to do this because the types resolve to EntryLinks, instead of the nested entry types.
type EntryOmitFields = Omit<Entry, 'fields'>;

export type SlugType = EntryOmitFields &
  Omit<TypeSlug<'WITH_ALL_LOCALES' | 'WITHOUT_UNRESOLVABLE_LINKS', Locales>, 'fields'> & {
    fields: {
      page?: { [LocaleName in Locales]: PageType | ExperimentType | PageAssetType };
      slug?: { [LocaleName in Locales]: string };
      unlisted?: { [LocaleName in Locales]: boolean };
      unlistedCriteria?: { [LocaleName in Locales]: CriteriaType };
    };
  };

export type PageType = EntryOmitFields &
  Omit<TypePage<'WITH_ALL_LOCALES' | 'WITHOUT_UNRESOLVABLE_LINKS', Locales>, 'fields'> & {
    fields: {
      metas?: { [LocaleName in Locales]: MetasType };
      blocks?: { [LocaleName in Locales]: (BlockType | BlockHeroType)[] };
      localizedIn?: { [LocaleName in Locales]: string[] };
      title?: { [LocaleName in Locales]: string };
    };
  };

export type MetasType = EntryOmitFields &
  Omit<TypeMetas<'WITH_ALL_LOCALES' | 'WITHOUT_UNRESOLVABLE_LINKS', Locales>, 'fields'> & {
    fields: {
      description?: { [LocaleName in Locales]: string };
    };
  };

export type ContentType = EntryOmitFields &
  Omit<TypeContent<'WITH_ALL_LOCALES' | 'WITHOUT_UNRESOLVABLE_LINKS', Locales>, 'fields'> & {
    fields: {
      title?: { [LocaleName in Locales]: RichTextBlock };
      subtitle?: { [LocaleName in Locales]: RichTextBlock };
      body?: { [LocaleName in Locales]: RichTextBlock };
    };
  };

export type TabsType = EntryOmitFields &
  Omit<TypeTabs<'WITH_ALL_LOCALES' | 'WITHOUT_UNRESOLVABLE_LINKS', Locales>, 'fields'> & {
    fields: {
      items?: { [LocaleName in Locales]: TabItemType[] };
    };
  };

export type AccordionType = EntryOmitFields &
  Omit<TypeAccordion<'WITH_ALL_LOCALES' | 'WITHOUT_UNRESOLVABLE_LINKS', Locales>, 'fields'> & {
    fields: {
      title?: { [LocaleName in Locales]: string };
      items?: { [LocaleName in Locales]: ContentType[] };
    };
  };

export type TabItemType = EntryOmitFields &
  Omit<TypeTabItem<'WITH_ALL_LOCALES' | 'WITHOUT_UNRESOLVABLE_LINKS', Locales>, 'fields'> & {
    fields: {
      contents?: { [LocaleName in Locales]: ContentType[] };
    };
  };

export type BlockType = EntryOmitFields &
  Omit<TypeBlock<'WITH_ALL_LOCALES' | 'WITHOUT_UNRESOLVABLE_LINKS', Locales>, 'fields'> & {
    fields: {
      contents?: { [LocaleName in Locales]: BlockContentsType[] };
      eyebrow?: { [LocaleName in Locales]: string };
      title?: { [LocaleName in Locales]: RichTextBlock };
      subtitle?: { [LocaleName in Locales]: RichTextBlock };
    };
  };
