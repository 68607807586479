import { gql } from '@apollo/client';

import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';
import {
  blogSchemaFragment,
  faqPageSchemaFragment,
  organizationSchemaFragment,
} from '../WebSchema';

export const fragments = {
  all: gql`
    fragment MetaAll on Metas {
      ...ContentfulSysId
      description
      ogImage {
        url
      }
      ogVideo {
        url
      }
      noIndex
      noFollow
      customMetas
      schemasCollection(limit: 5) {
        items {
          ...ContentfulSysId
          ...BlogSchemaAll
          ...OrganizationSchemaAll
          ...FaqPageSchemaAll
        }
      }
    }
    ${blogSchemaFragment}
    ${organizationSchemaFragment}
    ${faqPageSchemaFragment}
    ${contentfulSysIdFragment}
  `,
};

export const query = gql`
  query MetasQuery($preview: Boolean!, $locale: String!, $id: String!) {
    metas(preview: $preview, locale: $locale, id: $id) {
      ...MetaAll
    }
  }
  ${fragments.all}
`;
