import { css } from '@emotion/css';
import {
  globalHeaderHeight,
  m,
  mobileMediaQuery,
  p3Css,
} from '@snapchat/snap-design-system-marketing';

export const searchContainerCss = css`
  padding-block: ${m('--spacing-xl')};
  margin-inline: auto;
  max-width: ${m('--search-container-max-width')};
  padding-inline: ${m('--spacing-xl')};
  width: 100%;
  min-height: calc(100vh - ${globalHeaderHeight}px);
  min-height: calc(100dvh - ${globalHeaderHeight}px);

  ${mobileMediaQuery} {
    margin-block: ${m('--spacing-xxxl')};
  }
`;

export const searchAutocompleteCss = css`
  margin-block-end: ${m('--spacing-xxxl')};

  ${mobileMediaQuery} {
    margin-block-end: ${m('--spacing-xl')};
  }
`;

export const searchSubtitleCss = css`
  ${p3Css};
  color: ${m('--search-subtitle-color')};
  margin-block-start: ${m('--spacing-s')};
`;

export const searchResultsContainerCss = css`
  margin-block-end: ${m('--spacing-xxxl')};

  ${mobileMediaQuery} {
    margin-block-end: ${m('--spacing-xl')};
  }
`;

export const searchResultsListCss = css`
  display: flex;
  flex-wrap: wrap;
  gap: ${m('--search-results-list-gap')};
  list-style: none;
  > li {
    width: 100%;
  }
`;

export const spinnerContainerCss = css`
  display: flex;
  justify-content: center;
`;
