import type { ValueOf } from '../types';

/**
 * @deprecated This type is no longer used since curtain opacity is now set using a number: 0-100
 *
 *   TODO: Delete this.
 */
export const CurtainOpacity = {
  Zero: '0%',
  TwentyFive: '25%',
  Fifty: '50%',
  SeventyFive: '75%',
} as const;

export type CurtainOpacity = ValueOf<typeof CurtainOpacity>;
