import type { CSSProperties, KeyboardEventHandler, MouseEventHandler, ReactElement } from 'react';

import type { ButtonProps } from '../components/Button';

export type Buttons = ReactElement<ButtonProps> | Array<ReactElement<ButtonProps>>;

/**
 * Common component properties.
 *
 * These are properties that can be passed into the root element of the component and still
 * function. These are based on SDS-E BaseComponentProps. See
 * https://github.sc-corp.net/Snapchat/snap-design-system/blob/master/packages/snap-design-system/src/shared/types.ts
 */
export type BaseComponentProps = {
  className?: string;
  style?: CSSProperties;

  /**
   * Used to set data-* attributes on elements. Useful for passing debug information and other data.
   *
   * Note that this isn't `dataSet` because React hasn't added official support for the property
   * yet. See: https://github.com/facebook/react/issues/1259
   *
   * So to use it for now your component needs to spreadk it. I.e. `<div
   * {...dataSetToAttributes(dataset)} ...>...</div>`
   */
  dataset?: DOMStringMap;

  // TODO: Deprecate this in favor of using dataset. (<Foo dataset={{testId: ...}} />)
  'data-testid'?: string;
};

/** Common component properties for components that handle native events. */
export type BaseInteractiveComponentProps<T extends HTMLElement> = BaseComponentProps & {
  tabIndex?: number;
  onClick?: MouseEventHandler<T>;
  onMouseEnter?: MouseEventHandler<T>;
  onMouseLeave?: MouseEventHandler<T>;
  onKeyDown?: KeyboardEventHandler<T>;
  onKeyUp?: KeyboardEventHandler<T>;
  onKeyPress?: KeyboardEventHandler<T>;
  // TODO: Add gesture handlers.
};
