import type { Dimensions, Partition } from '@snapchat/graphene';
import { FetchNetworkHandler, getWebConfig, Graphene } from '@snapchat/graphene';
import { getTopLevelDomain } from '@snapchat/parse-domain';

export const DevelopmentDomains = new Set<string>([
  'localhost', // local dev
  'sc-corp.net', // hosted dev / staging
  'appspot.com', // preproduction
]);

/**
 * Wrapper around the @snapchat/graphene client used for Cookie Components. Formats logs and errors
 * for delivery to specified Graphene partition. This occurs outside the normal Event and Error
 * logging workflow so we have visibility into how these components function across all websites.
 *
 * Major change vs `global-components` implementation: Dropped support for server side logging. This
 * was causing build issues and since we don't expect to log anything server side it wasn't
 * necessary.
 */
export class BrowserGrapheneClient {
  private readonly client: Graphene;

  constructor(partition: Partition, host: string) {
    this.client = this.initializeClient(partition, host);
  }
  /** Initializes Graphene Client */
  private initializeClient = (partition: Partition, host: string) => {
    const client = new Graphene();
    const flavor = this.getGrapheneFlavor(host);
    const variant = this.getGrapheneFriendlyString(host);
    const config = getWebConfig({
      partitionName: partition,
      flavor,
      variant,
    });

    client.initialize({
      networkHandler: new FetchNetworkHandler(config),
      debugMode: false,
      logTimeInterval: 5e3,
    });

    return client;
  };

  private getGrapheneFlavor = (host: string): 'development' | 'production' => {
    const topLevelDomain = getTopLevelDomain(host);
    return DevelopmentDomains.has(topLevelDomain) ? 'development' : 'production';
  };

  /**
   * Graphene doesn't support empty strings, so replace with 'Unknown'. Also replace any whitespace
   * or periods in the input string with underscores, this avoids issues finding the logs later due
   * to the partitions not matching expectations. Example: convert '523.snap.com' to '523_snap_com'
   * since periods represent partitions in Graphene.
   */
  private getGrapheneFriendlyString = (input: string): string => {
    if (!input) return 'Unknown';

    const pattern = /[\s.]+/g;
    return input.replace(pattern, '_');
  };

  public logMetric = (metric: string, dimensions: Dimensions): void => {
    this.client.increment({ metricsName: metric, dimensions });
  };

  public logError = (component: string, description: string, error: Error): void => {
    const dimensions: Dimensions = {
      description: this.getGrapheneFriendlyString(description),
      error: this.getGrapheneFriendlyString(error.name),
    };
    this.client.increment({ metricsName: `error_${component}`, dimensions });
  };
}
