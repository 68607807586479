import type { FC, PropsWithChildren } from 'react';

import { tableCellCss, tableHeaderCss, tableRowCss } from './PrimitiveTable.styles';

// =============================================================================
// PrimitiveTableCell
// =============================================================================

export const PrimitiveTableCell: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div role="cell" className={tableCellCss}>
      {children}
    </div>
  );
};

// =============================================================================
// PrimitiveTableHeader
// =============================================================================

export const PrimitiveTableHeader: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div role="columnheader" className={tableHeaderCss}>
      {children}
    </div>
  );
};

// =============================================================================
// PrimitiveTableRow
// =============================================================================

export const PrimitiveTableRow: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div role="row" className={tableRowCss}>
      {children}
    </div>
  );
};
