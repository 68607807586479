const imageExtensions = ['jpg', 'jpeg', 'png', 'svg', 'bmp'];

const videoExtensions = ['mp4', 'mov', 'ogg'];

export const isImageUrl = (url: string): boolean => {
  const split = url.split('.');
  const extension = split[split.length - 1]!;
  return imageExtensions.includes(extension.toLowerCase());
};

export const isVideoUrl = (url: string): boolean => {
  const split = url.split('.');
  const extension = split[split.length - 1]!;
  return videoExtensions.includes(extension.toLowerCase());
};
