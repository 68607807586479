import { css } from '@emotion/css';
import { mobileMediaQuery, nonMobileMediaQuery } from '@snapchat/snap-design-system-marketing';

export const mediaStylesCss = css`
  max-width: 100%;
  max-height: 400px;

  /* only do non mobile for now as we break rendering images that are very wide */
  ${nonMobileMediaQuery} {
    &.strict-height {
      height: var(--mediaHeight);
    }
  }
`;

export const mediaOnlyCss = css`
  margin-bottom: inherit;
  max-height: 600px;

  ${nonMobileMediaQuery} {
    &.strict-height {
      height: var(--mediaHeight);
    }
  }

  ${mobileMediaQuery} {
    max-width: 100%;
    height: inherit;
  }
`;
