import { cx } from '@emotion/css';
import type { FC } from 'react';
import { useState } from 'react';

import { MediaMode } from '../../../constants';
import { getBackgroundClassName } from '../../../utils';
import { FooterContext } from '../FooterContext';
import type { FooterV2Props } from '../types';
import { footerColumnMobileCss, footerColumnMobileItemsCss } from './styles';

export const FooterColumnsMobile: FC<FooterV2Props> = ({ children, backgroundColor }) => {
  const [expandedItem, setExpandedItem] = useState<string | undefined>();

  return (
    <FooterContext.Provider
      value={{
        mode: MediaMode.Mobile,
        expandedItem,
        setExpandedItem,
      }}
    >
      <div
        data-testid="footer-columns-mobile"
        className={cx(
          getBackgroundClassName(backgroundColor),
          footerColumnMobileCss,
          footerColumnMobileItemsCss
        )}
      >
        {children}
      </div>
    </FooterContext.Provider>
  );
};
