import { TabsV2 as TabsV2SDS } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import { logUserEvent } from '../../helpers/logging';
import type { ContentfulIdVariable } from '../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../hooks/useContentfulQuery';
import type { ContentfulSysProps } from '../../types/contentful';
import { UserAction } from '../../types/events';
import { renderRichTextMarkingsOnly } from '../../utils/renderText/renderRichText';
import { ContentShallow } from '../Content/ContentShallow';
import { FormShallow } from '../Form';
import { queries } from './queries';
import type { TabsDataHandlerProps } from './types';

export const Tabs: FC<ContentfulSysProps> = props => {
  const { data } = useContentfulQuery<TabsDataHandlerProps, ContentfulIdVariable>(queries.all, {
    variables: { id: props.sys.id },
  });

  if (!data || !data.tabs) return null;

  const { itemsCollection } = data.tabs;

  const onSelectTab = (index: number) => {
    logUserEvent({
      eventAction: UserAction.Click,
      eventCategory: 'Tab',
      eventLabel: `tab:${index}`,
    });
  };

  const items = itemsCollection.items.map(item => {
    const content = item.contentsCollection
      ? item.contentsCollection.items.map(contentDataProps => {
          const key = contentDataProps.sys.id;

          if (contentDataProps.__typename === 'Form') {
            return <FormShallow key={key} {...contentDataProps} />;
          }

          return <ContentShallow key={key} {...contentDataProps} />;
        })
      : [];

    return {
      button: {
        size: item.button.size,
        type: item.button.buttonType,
        title: renderRichTextMarkingsOnly(item.button.title),
        image: item.button.image?.media,
        iconName: item.button.iconName,
      },
      text: item.title,
      content,
      maxColumns: item.maxColumns,
    };
  });

  return <TabsV2SDS items={items} onSelectTab={onSelectTab} />;
};

Tabs.displayName = 'Tabs';
