import { AxisBottom } from '@visx/axis';
import type { PositionScale } from '@visx/shape/lib/types';
import type { ReactElement } from 'react';

type AxisBottomPercentLabelsProps = {
  xScale: PositionScale;
  hidePercentSignal?: boolean;
  yMax: number;
  numTicks: number;
};

export const AxisBottomPercentLabels = (props: AxisBottomPercentLabelsProps): ReactElement => {
  const { yMax, xScale, numTicks, hidePercentSignal } = props;

  const formatValue = (text: string) => {
    return hidePercentSignal ? text : `${text}%`;
  };

  return (
    <AxisBottom
      hideTicks
      top={yMax - 20}
      scale={xScale}
      stroke={'black'}
      tickStroke={'black'}
      hideAxisLine
      numTicks={numTicks}
      tickLabelProps={() => ({
        fill: 'black',
        fontSize: 14,
        textAnchor: 'middle',
      })}
      tickFormat={formatValue}
    />
  );
};
