import { cx } from '@emotion/css';
import type { FC, PropsWithChildren } from 'react';
import { Children, useCallback, useRef, useState } from 'react';

import { m } from '../../motif/m';
import type { BaseComponentProps } from '../../types';
import { itemChevronCss } from '../Accordion/AccordionItem/styles';
import { DetailsSummary } from '../DetailsSummary';
import {
  footnoteAccordionItemContentCss,
  footnoteAccordionItemSummaryCss,
  footnoteDetailsCss,
  footnotesContentCss,
  footnotesCss,
  footnotesPrintCss,
} from './FootnoteBlock.styles';

export const footnoteBlockBackgroundColor = 'White';

interface FootnoteBlockProps extends PropsWithChildren<BaseComponentProps> {
  title: string;
  isOpenInitially: boolean;
}

const animationDuration = 300;

const chevronProps = { className: itemChevronCss, fill: m('--icon-color') };
const summaryProps = {
  className: footnoteAccordionItemSummaryCss,
};

export const FootnoteBlock: FC<FootnoteBlockProps> = ({
  title,
  isOpenInitially,
  children,
  className,
}) => {
  const [open, setOpen] = useState(isOpenInitially);
  const detailsRef = useRef<HTMLDetailsElement | null>(null);

  const onToggle = useCallback(() => {
    setOpen(open => !open);
  }, []);

  if (Children.count(children) === 0) {
    return null;
  }

  return (
    <footer data-test-id="sdsm-footnotes" className={cx(footnotesCss, className)}>
      <div className={footnotesContentCss}>
        {/* Show for print, hide for screen */}
        <div className={footnotesPrintCss}>
          <h2 className={footnoteAccordionItemSummaryCss}>{title}</h2>
          {children}
        </div>
        <DetailsSummary
          summary={title}
          detailsRef={detailsRef}
          chevronProps={chevronProps}
          className={footnoteDetailsCss}
          summaryProps={summaryProps}
          onToggle={onToggle}
          fadeInAnimation
          transitionDurationMs={animationDuration}
          open={open}
        >
          <div className={footnoteAccordionItemContentCss}>{children}</div>
        </DetailsSummary>
      </div>
    </footer>
  );
};

FootnoteBlock.displayName = 'FootnoteBlock';
