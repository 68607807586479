// Dependencies
import type { HeaderGroup } from 'react-table';

// Components
import type { IconName } from '../Icon';
import { Icon } from '../Icon';
// Styles
import { filterIconCss } from './styles';

type SortIcon<Data extends object> = {
  column: HeaderGroup<Data>;
  sortable?: boolean;
  renderSortIcon?: (isSorted: boolean, isSortedDesc?: boolean) => JSX.Element;
};

export function SortIcon<Data extends object>({
  column,
  sortable,
  renderSortIcon,
}: SortIcon<Data>): JSX.Element | null {
  if (sortable && !renderSortIcon) {
    const iconName: IconName = column.isSorted
      ? column.isSortedDesc
        ? 'chevron-down'
        : 'chevron-up'
      : 'sort-triangles';

    return <Icon name={iconName} className={filterIconCss} />;
  }

  if (sortable && renderSortIcon) {
    return renderSortIcon(column.isSorted, column.isSortedDesc);
  }

  return null;
}
