export enum VideoTimeFormat {
  MILISECONDS = 'miliseconds',
  SECONDS = 'seconds',
  UTC_MILISECONDS = 'utcMiliseconds',
  UTC_SECONDS = 'utcSeconds',
}

/** Client Broadcast websocket message for bitmoji reactions. */
export type ReactionNotification = {
  bitmojiId: string;
  bitmojiReactionType: string;
  videoId: string;
  /** Video Time in form of seconds or miliseconds */
  videoTime: number;
  /** Format of the video time */
  videoTimeFormat: VideoTimeFormat;
  /** ISO formatted Date() */
  clientTimestamp?: string;
  /** ISO formatted Date() */
  receivedAt: string;
};

/**
 * Clint Broadcast websocket message for the contentful webhook entry body.
 *
 * If we need to add new fields here, we need to change what we publish
 */
export interface ContentfulNotification {
  sys: {
    type: 'Entry';
    id: string;
    contentType: {
      sys: {
        type: 'Link';
        linkType: 'ContentType';
        id: string;
      };
    };
    updatedAt: string;
    version: number;
  };
  fields: Record<string, { 'en-US': unknown; [locale: string]: unknown }>;
}

export type ClientBroadcastNotificaton = ReactionNotification | ContentfulNotification;

/** Sent to the 'subscribe' websocket event */
export type BroadcastSubscription = {
  /**
   * Event publishers generate a key for the event so that a client knows what to listen for. This
   * is some kind of grouping key based on event information. See events.ts in common package for
   * how to generate them. E.g. "eventType_field1_field2"
   */
  eventKey: string;
};

// Break out the maps

/**
 * WebSocket events that can be transmitted from the client to the server.
 *
 * The keys are event names and values are event handler signatures.
 */
export type BroadcastClientEventMap = {
  /** Subscribing to events will publish events using the 'eventKey' as event name. */
  subscribe: (subscription: BroadcastSubscription) => void;
  /** Removes the socket from the room of listeners for the 'eventKey'. */
  unsubscribe: (subscription: BroadcastSubscription) => void;
  echo: (message: string) => void;
};

/**
 * WebSocket events that can be transmitted from the server to the client.
 *
 * The * => broadcast notification because we can use any string as eventKey.
 */
export type BroadcastServerEventMap = Record<
  string,
  (notification: ClientBroadcastNotificaton) => void
>;

// ==================================================================================
// Bitmoji Reaction Service Socket Types
// ==================================================================================

/** Sent to the 'submitReaction' event on BRS. Contains reaction data. */
export type ReactionSubmitPayload = {
  bitmojiId: string;
  bitmojiReactionType: string;
  videoId: string;
  /** Video time in seconds or miliseconds */
  videoTime: number;
  /** Format of the video time */
  videoTimeFormat: VideoTimeFormat;
  /** Time stamp from the user when submitting */
  reactionTimestamp?: string;
};

/**
 * WebSocket events that can be transmitted from the client to the server.
 *
 * The keys are event names and values are event handler signatures.
 */
export type BrsClientEventMap = {
  /** Submit a bitmoji reaction to be propagated to live clients, and saved for replays */
  submitReaction: (payload: ReactionSubmitPayload) => void;
};
