import { css } from '@emotion/css';

import { mobileMediaQuery, smallMobileMediaQuery } from '../../constants';
import { m } from '../../motif';

// TODO(jm) -- these are styles, not classnames...
const baseImageCss = css`
  display: block;
  object-fit: cover;
`;

export const noWrapImageCss = css`
  ${baseImageCss}
  border-width: ${m('--media-border-width')};
  border-radius: ${m('--media-border-radius')};
  border-color: ${m('--media-border-color')};
  border-style: solid;
  height: auto;
  margin: auto;
  max-width: 100%;
  overflow: hidden;
  width: auto;
`;

export const wrappedImageCss = css`
  ${baseImageCss}
  height: 100%;
  width: 100%;
`;

export const wrappedVideoCss = css`
  ${wrappedImageCss}
  object-fit: contain;
`;

export const noWrapVideoCss = css`
  ${noWrapImageCss}
  object-fit: contain;
`;

export const wrappedBackgroundVideoCss = wrappedImageCss;

export const noWrapBackgroundVideoCss = noWrapImageCss;

export const phoneVideoCss = css`
  ${mobileMediaQuery} {
    margin-top: 0;
  }
  ${smallMobileMediaQuery} {
    margin-top: 0;
  }
`;

export const divStyleCss = css`
  position: fixed;
`;

export const macSafariCss = css`
  width: 297px;
  height: 550px;
`;

export const phoneContainerCss = css`
  border-radius: ${m('--media-border-radius')};
`;

export const shadowContainerCss = css`
  box-shadow: ${m('--box-shadow-xl')};
  border-radius: ${m('--media-border-radius')};
`;
