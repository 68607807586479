import type { BackgroundColor } from '@snapchat/snap-design-system-marketing';
import type { FC, ReactNode } from 'react';

import type { CustomFooterGroupProps } from '../FooterV3/FooterCollectionV3/FooterCollectionV3';
import { FooterCollectionV3 } from '../FooterV3/FooterCollectionV3/FooterCollectionV3';

export enum FooterType {
  FULL_FOOTER = 'Full Footer',
  NO_FOOTER = 'No Footer',
  SIMPLE_FOOTER = 'Simple Footer',
}

/**
 * Properties for the footer.
 *
 * Note that THIS IS PUBLIC so it cannot have any references to contentful, sys ids, or anything
 * that's related to MWP.
 */
export type GlobalFooterProps = {
  /** Color of the footer */
  backgroundColor?: BackgroundColor;
  /** Url for cookie settings page */
  cookieSettingsUrl?: string;
  /** Allow adding and overriding of columns */
  customColumns?: CustomFooterGroupProps[];
  /** Allow adding and overriding of bottom bar */
  customFooterBar?: CustomFooterGroupProps[];
  /** Use a custom logo instead of the default one that comes with global components */
  logo?: ReactNode;
  /** Renders at the top of the footer. Used to link to socials. */
  socialBar?: ReactNode;
  /** Controls what type of footer to use, Full, None, or Simple. Defaults to Full */
  footerType?: FooterType;
};

/**
 * Standard Footer for marketing sites.
 *
 * Note that this is the PUBLIC interface for the footer used by other teams who do not use
 * contentful nor MWP.
 */
export const GlobalFooter: FC<GlobalFooterProps> = ({
  footerType = FooterType.FULL_FOOTER,
  ...props
}) => {
  return <FooterCollectionV3 footerType={footerType} {...props} />;
};
