import type { FC, MouseEventHandler, PropsWithChildren } from 'react';

import type { BaseComponentProps } from '../../types';

export interface DropdownItem {
  id: string;
  title?: string;
  items?: DropdownItem[];
}

export interface DropdownItemProps extends DropdownItem {
  isSelected?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export interface DropdownMenuProps extends BaseComponentProps {
  items: DropdownItem[];
  selectedItemId?: string;
  /** Listener for whether the dropdown is visible or not. */
  onVisibleChange?: (visible: boolean) => void;
  /** Listener for a selected value change. */
  onItemSelect?: (item: DropdownItem) => void;
  /** Allows to provide a custom component for rendering the button that triggers the dropdown. */
  buttonComponent?: FC<DropdownMenuButtonProps>;
  ariaLabel?: string;
  /** Value that is displayed when a value hasn't been selected yet. */
  placeholderText?: string;
}

export type DropdownMenuButtonProps = PropsWithChildren & {
  onClick: () => void;
  isExpanded: boolean;
  ariaLabel?: string;
};
