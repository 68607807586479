import type { NavigatorItem as NavigatorItemType } from '@snapchat/mw-contentful-schema';
import {
  GlobalNavItem as GlobalNavItemSDS,
  NavigatorItemMobile as NavigatorItemMobileSDS,
} from '@snapchat/snap-design-system-marketing';
import { type FC, useContext } from 'react';

import { AppContext } from '../../AppContext';
import { isLocalUrl, shouldFlushLogs } from '../../helpers/getLocalPath';
import { logger } from '../../helpers/logging';
import { UserAction } from '../../types/events';
import { getContentfulInspectorProps } from '../../utils/contentful/getContentfulInspectorProps';
import type { NavigatorGroupProps } from '../Header/headerNavGroupUtils';
import { useConsumerContext } from '../useConsumerContext';

export const LocalNavItemMobile: FC<NavigatorGroupProps> = ({
  sys,
  url,
  title,
  isSelected,
  analytics,
  column1Label,
  column1Items,
  column2Label,
  column2Items,
  column3Label,
  column3Items,
  featuredMenuItem,
}) => {
  const { logEvent } = useConsumerContext();
  const { getCurrentUrl } = useContext(AppContext);
  const { contentfulDescriptionDataset } = getContentfulInspectorProps<NavigatorItemType>({
    entryId: sys.id,
    fieldIds: ['contentfulDescription'],
  });

  if (!title) {
    // Do nothing if there's no title.
    return null;
  }

  const handleClick = (url: string, analyticsId?: string): void => {
    logEvent?.({
      type: UserAction.Click,
      component: 'LocalNavItemMobile',
      label: analyticsId,
      url,
    });

    if (shouldFlushLogs(url)) {
      void logger.flush();
    }
  };

  // this should return true for relative paths too. Prevent adding tracking para
  const absoluteUrl = new URL(url ?? '', getCurrentUrl());
  const isLocal = isLocalUrl(absoluteUrl);

  const hasChildren = !!(
    column1Items?.length ||
    column2Items?.length ||
    column3Items?.length ||
    featuredMenuItem
  );

  return (
    <GlobalNavItemSDS
      showExternalIcon={!isLocal}
      href={url}
      title={title}
      onClick={() => handleClick(url ?? '', analytics?.label)}
      isSelected={isSelected}
      dataset={contentfulDescriptionDataset}
      addTrackingParams={!isLocal}
      isList={false}
    >
      {hasChildren && (
        <NavigatorItemMobileSDS
          col1Label={column1Label}
          col1Items={column1Items}
          col2Label={column2Label}
          col2Items={column2Items}
          col3Label={column3Label}
          col3Items={column3Items}
          featuredItem={featuredMenuItem}
          onClick={handleClick}
        />
      )}
    </GlobalNavItemSDS>
  );
};

LocalNavItemMobile.displayName = 'LocalNavItemMobile';
