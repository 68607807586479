import type { FC } from 'react';

import { footerSocialCss, footerSocialIconsWrapperCss, wrapperCss } from './styles';
import type { FooterSocialProps } from './types';

export const FooterSocial: FC<FooterSocialProps> = ({ title, children }) => {
  return (
    <section data-testid="footer-social" className={wrapperCss}>
      <div className={footerSocialCss}>
        {title && <h5>{title}</h5>}
        <div className={footerSocialIconsWrapperCss}>{children}</div>
      </div>
    </section>
  );
};
