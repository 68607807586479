import { css, injectGlobal } from '@emotion/css';
import type { FC, PropsWithChildren } from 'react';
import { useEffect } from 'react';

import { globalCss } from '../../utils';
import { FontFamily } from './fontConstants';
import { useFontStylesheet } from './useFontStylesheet';

const graphikFontFamilyCss = css`
  font-family: Graphik, sans-serif;
`;

/** @deprecated Use <MotifRoot> instead. */
export const Font: FC<PropsWithChildren> = ({ children }) => {
  useFontStylesheet(FontFamily.GRAPHIK);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.error(
      'SDS-M: Usage of <Font> is deprecated. Fonts should come installed when using <MotifRoot>'
    );

    injectGlobal(globalCss`
        html[lang='vi'] * {
          font-family: Helvetica, sans-serif;
        }
      `);
  }, []);

  return (
    <div className={graphikFontFamilyCss} data-id="font-family-wrapper">
      {children}
    </div>
  );
};

Font.displayName = 'Font';
