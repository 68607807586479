import { gql } from '@apollo/client';
import type { ContentfulMetadata } from '@snapchat/mw-contentful-schema';

import type { ContentfulSysProps } from '../../types/contentful';
import type { Items } from '../../types/Items';
import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';
import { fragments as imageFragments } from '../Image';
import type { ImageDataProps } from '../Image/types';
import type { GalleryTileProps } from '../Tile/query';
import { fragments } from '../Tile/query';
import type { GalleryTilesSortingType } from './types';

export const skipDelta = 12;

// =================================================================================================
// Gallery Dropdown Fragment
// =================================================================================================

export type DropdownItem = ContentfulSysProps & {
  title: string;
  contentfulMetadata: ContentfulMetadata;
};

const galleryDropdownItemFragment = gql`
  fragment GalleryDropdownItemAll on GalleryDropdownItem {
    ...ContentfulSysId
    title
    contentfulMetadata {
      tags {
        id
      }
    }
  }
  ${contentfulSysIdFragment}
`;

export type Dropdown = ContentfulSysProps & {
  title: string;
  matchAllOptionsTitle: string;
  dropdownItemsCollection: Items<DropdownItem>;
};
const galleryDropdownFragment = gql`
  fragment GalleryDropdownAll on GalleryDropdown {
    ...ContentfulSysId
    title
    matchAllOptionsTitle
    dropdownItemsCollection {
      items {
        ... on GalleryDropdownItem {
          ...GalleryDropdownItemAll
        }
      }
    }
  }
  ${galleryDropdownItemFragment}
  ${contentfulSysIdFragment}
`;

// =================================================================================================
// Gallery Fragment
// =================================================================================================
export const galleryFragment = gql`
  fragment GalleryFragment on Gallery {
    ...ContentfulSysId
    dropdownsCollection(limit: 5) {
      total
      items {
        ... on GalleryDropdown {
          ...GalleryDropdownAll
        }
      }
    }
    sortBy
    showDates
    showMedia
    showDescriptions
    defaultImage {
      ...ImageAll
    }
    emptyPlaceholder
    loadMoreCtaCopy
    mobileFiltersWrapperText
    clearFiltersCtaCopy
    itemsPerPage
    hidePagination
    hideFilters
  }
  ${contentfulSysIdFragment}
  ${galleryDropdownFragment}
  ${imageFragments.all}
`;

export type GalleryDataProps = ContentfulSysProps & {
  dropdownsCollection: Items<Dropdown>;
  tilesCollection: Items<Omit<GalleryTileProps, 'linkedFrom'>>;
  sortBy?: GalleryTilesSortingType;
  showDates?: boolean;
  showMedia?: boolean;
  showDescriptions?: boolean;
  defaultImage?: ImageDataProps | null;
  emptyPlaceholder?: string;
  loadMoreCtaCopy?: string;
  mobileFiltersWrapperText?: string;
  clearFiltersCtaCopy?: string;
  itemsPerPage?: number;
  hidePagination?: boolean;
  hideFilters?: boolean;
};

// =================================================================================================
// Gallery Query
// =================================================================================================

export const lazyGalleryQuery = gql`
  query GalleryQuery($preview: Boolean!, $locale: String!, $id: String!) {
    gallery(preview: $preview, locale: $locale, id: $id) {
      ...GalleryFragment
    }
  }
  ${galleryFragment}
`;

export type LazyGalleryQueryResponse = {
  gallery: Omit<GalleryDataProps, 'tilesCollection'>;
};

// =================================================================================================
// Tiles Query
// =================================================================================================

export const tileCollectionQuery = gql`
  query TileQuery($preview: Boolean!, $locale: String!, $ids: [String]!) {
    tileCollection(preview: $preview, locale: $locale, where: { sys: { id_in: $ids } }) {
      total
      items {
        ...TileAll
      }
    }
  }
  ${fragments.all}
`;

export type TileQueryResponse = {
  tileCollection: Items<GalleryTileProps>;
};
