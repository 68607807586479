import { cx } from '@emotion/css';
import { Orientation } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import { LocaleDropdown } from '../../LocaleDropdown/LocaleDropdown';
import type { FooterLocaleDropdownProps } from '../FooterV3Types';
import {
  dropdownCss,
  horizontalDropdownCss,
  localeDropdownContainerCss,
  titleCss,
} from './FooterLocaleDropdown.styles';

export const FooterLocaleDropdown: FC<FooterLocaleDropdownProps> = ({
  title,
  /**
   * @deprecated. It should be on the styles in the footer Group to align the locale dropdown, instead
   * of prop drilling it.
   */
  currentOrientation: orientation,
}) => {
  return (
    <div
      className={cx(dropdownCss, {
        [horizontalDropdownCss]: orientation === Orientation.Horizontal,
      })}
    >
      {title && <label className={titleCss}>{title}</label>}
      <LocaleDropdown className={localeDropdownContainerCss} />
    </div>
  );
};

FooterLocaleDropdown.displayName = 'FooterLocaleDropdown';
