import { useEffect } from 'react';

const canUseTouchEvent = typeof TouchEvent !== 'undefined';

/**
 * Stops the scroll events outside of the target element.
 *
 * Useful for when you want to prevent scrolling behind full-screen popups.
 *
 * Note that we tried doing this using CSS (to remove the scrollbar etc), but ultimately it was full
 * of unintended consequerences like: scrollbar appearing and disappearing, page scroll flashing
 * during css transitions etc. Disabling scroll events seems to be the better way.
 *
 * Implementation here disables the 'wheel' event if no element has anywhere to scroll between event
 * target and 'allowScrollParent'
 *
 * 'shouldAllowScrollEvent' callback allows consumer to prevent a scroll event from being cancelled
 * on a case by case basis.
 */
export function useDisableScrollOutside(
  allowScrollParent: HTMLElement | null,
  inTransition?: boolean,
  shouldAllowScrollEvent?: (event: Event) => boolean
): void {
  useEffect(() => {
    // Save the scroll position;
    const previousScrollX = window.scrollX;
    const previousScrollY = window.scrollY;

    let lastTouchStartY: number;

    const captureTouchStart: EventListener = event => {
      // Short circuit if touch events are not supported.This is separate
      // from the next if statement for typescript inference.
      if (!canUseTouchEvent) {
        return;
      }

      if (!(event instanceof TouchEvent)) {
        return;
      }

      // Skip multi-touch (zoom etc).
      if (event.changedTouches.length > 1) {
        return;
      }

      lastTouchStartY = event.changedTouches[0]!.clientY;
    };

    // Create event listener that prevents the default event behavior unless scroll is allowed.
    const preventScrollEvent: EventListener = event => {
      if (shouldAllowScrollEvent?.(event)) {
        return;
      }

      // We can't prevent non-cancelable events. Do nothing. I.e. faster scroll while scrolling.
      if (!event.cancelable) {
        return;
      }

      // This is for type-script inference.
      if (!(event.target instanceof HTMLElement)) {
        return;
      }

      // Parent isn't attached to dom yet. Wait.
      if (!allowScrollParent) {
        return;
      }

      // Skip multi-touch (zoom etc). Just let this happen.
      if (canUseTouchEvent && event instanceof TouchEvent && event.changedTouches.length > 1) {
        return;
      }

      // Deduce scroll direction.
      let scrollYDirection: 'down' | 'up' = 'down';

      if (event instanceof WheelEvent) {
        scrollYDirection = event.deltaY > 0 ? 'down' : 'up';
      }

      if (canUseTouchEvent && event instanceof TouchEvent) {
        scrollYDirection = lastTouchStartY > event.changedTouches[0]!.clientY ? 'down' : 'up';
      }

      let current: HTMLElement | null = event.target;
      let isInAllowedArea = false;
      let hasRoomToScroll = false;

      while (current) {
        // Not descendant of allowed area. Continue to prevent scroll event.
        if (current === document.body) {
          break;
        }

        // Look for any room to scroll.
        const scrollRoom = current.scrollHeight - current.offsetHeight;
        const canScrollUp = scrollYDirection === 'up' && current.scrollTop > 0;
        const canScrollDown = scrollYDirection === 'down' && current.scrollTop < scrollRoom;

        if (scrollRoom > 0 && (canScrollDown || canScrollUp)) {
          hasRoomToScroll = true;
        }

        // Check scope (after checking scroll).
        if (current === allowScrollParent) {
          isInAllowedArea = true;
          break;
        }

        current = current?.parentElement;
      }

      if (isInAllowedArea && hasRoomToScroll) {
        return;
      }

      // Cancelling this event stops the "scroll" event from ever happening.
      event.preventDefault();
    };

    // Options that ignore passive events to prevent exceptions trying to prevent passive events.
    // They aren't preventable.
    const activeListenerOptions: AddEventListenerOptions = { passive: false, capture: false };

    document.body.addEventListener('wheel', preventScrollEvent, activeListenerOptions);
    document.body.addEventListener('touchstart', captureTouchStart /* passiveOK */);
    document.body.addEventListener('touchmove', preventScrollEvent, activeListenerOptions);

    return () => {
      // Secondary scroll prevention measure. If scroll changes on the body via some other means
      // no supported here (like zooming out and zooming in to another place), we restore the
      // scroll to prevent unexepected UI change. This might cause flicker but avoid the "broken"
      // feeling.
      // In transition cases (such as navigation open and closing) we dont want to update scroll position.
      if (!inTransition) {
        window.scrollTo(previousScrollX, previousScrollY);
      }

      document.body.removeEventListener('wheel', preventScrollEvent, activeListenerOptions);
      document.body.removeEventListener('touchstart', captureTouchStart /* passiveOK */);
      document.body.removeEventListener('touchmove', preventScrollEvent, activeListenerOptions);
    };
  }, [allowScrollParent, inTransition, shouldAllowScrollEvent]);
}
