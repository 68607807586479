import { DeploymentType } from '@snapchat/mw-common';
import { lazyComponent } from '@snapchat/mw-common/client';
import type { AdjustedSeries, ChartDataRow } from '@snapchat/mw-contentful-schema';
import { getRawData } from '@snapchat/mw-contentful-schema';
import { ChartSkeleton } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useContext, useState } from 'react';

import { AppContext } from '../../../AppContext';
import { Config } from '../../../config';
import { logger } from '../../../helpers/logging';
import { SuspenseWrapper } from '../../SuspenseWrapper';
import { JSONTypes } from '../types';
import type { TableVisualizationRenderProps } from './types';

const dataFormatDate = 'date';

const LazyTableVisualization = lazyComponent(() =>
  import('./LazyTable').then(module => ({ default: module.Table }))
);

const getRowDataForKey = (
  series: AdjustedSeries,
  row: ChartDataRow,
  key: string,
  locale: string
) => {
  const properties = series.schema.items.properties;

  if (!properties) {
    throw new Error('Series schema is malformed!');
  }

  const data = row[key];
  const dataType = properties[key]!.type;
  const dataFormat = properties[key]!.format;

  // Numbers should be localized on the fly
  if (dataType === JSONTypes.integer || dataType === JSONTypes.number) {
    return (data as number).toLocaleString(locale);
  }

  // Dates should be localized on the fly
  if (dataFormat === dataFormatDate) {
    return (data as Date).toLocaleDateString(locale);
  }

  // Otherwise, data is a string and localization will have been
  // handled by Contentful
  return data as string;
};

const getColumns = (columns: string[], series: AdjustedSeries, locale: string) => {
  // We rely on Table Columns to determine the display order of headers;
  // therefore, we must validate that the provided table columns match our schema
  // Step 1: Number of keys should match number of columns
  const properties = series.schema.items.properties!;
  const propertyKeys = Object.keys(properties);

  if (propertyKeys.length !== columns?.length) {
    throw new Error(
      'The number of table columns must equal the number of properties on the schema!'
    );
  }

  // Step 2: Each key should exist in provided table columns
  const allKeysExist = propertyKeys.every(key => columns.includes(key));

  if (!allKeysExist) {
    throw new Error('Table columns and schema properties do not match!');
  }

  return columns.map(column => {
    // Try to use the (localizable) "Header Names" field for our column headers.
    // If that field is not available (maybe a user manually edited the
    // schema after uploading data) then fallback to the key name
    let headerName = column;

    if (series.headerNames?.[column]) {
      headerName = series.headerNames[column]!;
    }

    return {
      Header: headerName,
      accessor: (row: object) => getRowDataForKey(series, row as ChartDataRow, column, locale),
    };
  });
};

export const Table: FC<TableVisualizationRenderProps> = props => {
  const { chartData, tableColumns, enableSearch, initialRowCount } = props;
  const { currentLocale } = useContext(AppContext);

  const [error, setError] = useState<Error>();

  if (!chartData) return null;

  let rawData;
  let columns;

  try {
    rawData = getRawData(chartData);
    columns = getColumns(tableColumns, chartData.seriesName, currentLocale);
  } catch (err) {
    if (!error) {
      setError(err as Error);

      logger.logError({
        component: 'Table',
        error: err,
      });
    }
  }

  if (error && Config.deploymentType !== DeploymentType.PRODUCTION) {
    return (
      <section>
        <h5>Table Visualization Error</h5>
        <p>{`Error: ${error.message}`}</p>
        <p>{`Entry id: ${props.id}`}</p>
      </section>
    );
  }

  return (
    <SuspenseWrapper fallbackElement={<ChartSkeleton />}>
      <LazyTableVisualization
        data={rawData}
        columns={columns}
        searchable={enableSearch}
        initialRowCount={initialRowCount}
      />
    </SuspenseWrapper>
  );
};
