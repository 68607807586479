import type { InspectorMode } from '@contentful/live-preview/dist/inspectorMode';
import { injectGlobal } from '@emotion/css';
import { LoggingEventType } from '@snapchat/logging';
import type { FC } from 'react';
import { useContext, useInsertionEffect } from 'react';

import { Config } from '../../config';
import { logger } from '../../helpers/logging';
import { useContentfulContext } from '../useContentfulContext';
import { ContentfulLivePreviewContext } from './ContentfulLivePreviewContext';

/** Component used to initialize Contentful Live Preview */
export const ContentfulLivePreview: FC = () => {
  const { locale } = useContentfulContext();
  const { setHasLivePreview } = useContext(ContentfulLivePreviewContext);

  const shouldSetupLivePreview = !Config.isDeploymentTypeProd;

  useInsertionEffect(() => {
    async function setupContentfulLivePreview(): Promise<InspectorMode | null | undefined> {
      const { ContentfulLivePreview: livePreview } = await import('@contentful/live-preview');

      const preview = await livePreview.init({
        locale,
        enableInspectorMode: true,
        enableLiveUpdates: true,
      });

      // This could be null if we're not in an iframe
      if (preview) {
        const { livePreviewCss } = await import('./ContentfulLivePreview.styles');
        injectGlobal(livePreviewCss);
        setHasLivePreview?.(true);

        logger.logEvent({
          type: LoggingEventType.USER_ACTION,
          component: 'ContentfulLivePreview',
          action: 'Open',
          label: 'Live Preview Panel',
        });
      }

      return preview;
    }

    if (shouldSetupLivePreview) {
      void setupContentfulLivePreview();
    }
  }, [shouldSetupLivePreview, locale]);

  return null;
};
