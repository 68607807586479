import type { ReactElement, ReactNode } from 'react';

import type { BackgroundColor } from '../../constants';
import type { ButtonProps } from '../Button';

export interface LatestPostItem {
  title: string | ReactNode;
  author?: string | ReactNode;
  date?: string | ReactNode;
  category?: string;
}

export interface LatestPostsProps {
  title: string;
  items: LatestPostItem[];
  cta?: ReactElement<ButtonProps>;
  anchorId?: string;
  backgroundColor: BackgroundColor;
  scrollSnap?: boolean;
  className?: string;
  preChildren?: ReactNode;
  postChildren?: ReactNode;
}
