import type React from 'react';
import { useContext } from 'react';

import { MediaMode } from '../../../constants';
import { ToggleState } from '../../../hooks/useToggleState';
import { GlobalHeaderContext } from '../GlobalHeaderContext';
import { screenStateClassName } from './GlobalNavScreen.styles';
import { GlobalNavScreenDesktop } from './GlobalNavScreenDesktop';
import { GlobalNavScreenMobile } from './GlobalNavScreenMobile';
import type { GlobalNavScreenProps } from './types';

export const GlobalNavScreen: React.FC<GlobalNavScreenProps> = props => {
  const { screenState, mode } = useContext(GlobalHeaderContext);

  if (screenState === ToggleState.OFF) {
    return null;
  }

  const isMobile = mode === MediaMode.Mobile;
  return isMobile ? (
    <GlobalNavScreenMobile
      {...props}
      className={screenStateClassName(screenState!)}
      screenState={screenState}
    />
  ) : (
    <GlobalNavScreenDesktop
      {...props}
      className={screenStateClassName(screenState!)}
      screenState={screenState}
    />
  );
};

GlobalNavScreen.displayName = 'GlobalNavScreen';
