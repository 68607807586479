import { CarouselTextItem as CarouselTextItemSDS } from '@snapchat/snap-design-system-marketing';
import { type FC, useContext } from 'react';

import { UserAction } from '../../types/events';
import { renderRichText } from '../../utils/renderText/renderRichText';
import { ConsumerContext } from '../ConsumerContextProvider';
import type { CarouselV3TextItemProps } from './types';

export const CarouselV3TextItem: FC<{
  textItem: CarouselV3TextItemProps;
}> = ({ textItem }) => {
  const description = renderRichText(textItem?.description);
  const subtext = renderRichText(textItem?.subtext);

  const slug = textItem.slugReference?.slug;
  const url = slug ? `/${slug}` : textItem.url;

  const consumerContext = useContext(ConsumerContext);
  const { logEvent } = consumerContext;

  const onClick = (): void => {
    if (!url) {
      return;
    }

    logEvent?.({
      type: UserAction.Click,
      component: 'CarouselV3',
      label: `CarouselV3.TextItem - ${url}`,
      url,
    });
  };

  return (
    <CarouselTextItemSDS
      key={textItem.sys.id}
      description={description}
      subText={subtext}
      url={url}
      onClick={onClick}
    />
  );
};

CarouselV3TextItem.displayName = 'CarouselV3TextItem';
