import { cx } from '@emotion/css';
import type React from 'react';
import type { ChangeEventHandler, ReactElement } from 'react';
import { useEffect, useRef, useState } from 'react';

import { Black, Gray } from '../../constants';
import { MotifComponent, useMotifStyles } from '../../motif';
import { Icon } from '../Icon';
import { IconButton } from '../IconButton';
import { IconButtonSize } from '../IconButton/IconButton.types';
import {
  autoCompleteContainerCss,
  autocompleteListItemCss,
  cancelButtonCss,
  hiddenSuggestionsCss,
  inputCss,
  resultsContainerNoResultsCss,
  resultsContainerWithResultsCss,
  rowCss,
  searchButtonCss,
  searchIconCss,
  seeResultsCss,
  shortAutoCompleteCss,
} from './styles';

const selectedStyle = { background: `${Gray.V100}`, color: `${Black.V150}` };

type AutocompleteSuggestionProps<ResultType = string> = {
  term: ResultType;
  onClick: (term: ResultType) => void;
  setSelectedIndex: (index: number) => void;
  resultAccessor?: (item: ResultType) => string;
  isActive: boolean;
  index: number;
  listItemStyles?: string;
  loadingAutocompleteTerms?: boolean;
  loadingMessage?: string;
};

export function AutocompleteSuggestion<ResultType = string>({
  term,
  onClick,
  setSelectedIndex,
  isActive,
  listItemStyles,
  resultAccessor,
  loadingAutocompleteTerms,
  loadingMessage,
}: AutocompleteSuggestionProps<ResultType>): ReactElement {
  const ref = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (isActive) {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    }
  }, [isActive]);

  if (loadingAutocompleteTerms) {
    return <li className={autocompleteListItemCss}>{loadingMessage ?? 'Loading...'}</li>;
  }

  return (
    <li
      className={cx(autocompleteListItemCss, listItemStyles)}
      ref={ref}
      style={isActive ? selectedStyle : {}}
      key={typeof term === 'string' ? term : resultAccessor?.(term)}
      onMouseLeave={() => setSelectedIndex(-1)}
      onClick={e => {
        e.stopPropagation();
        onClick(term);
      }}
    >
      {resultAccessor ? resultAccessor(term) : String(term)}
    </li>
  );
}

type AutoCompleteProps<ResultType = string> = {
  onSelect: (terms: ResultType) => void;
  onChange: (term: string) => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  onSeeResults?: (term: string) => void;
  onCollapseChange?: (open: boolean) => void;
  resultAccessor?: (item: ResultType) => string;
  autocompleteResults: ResultType[];
  loadingAutocompleteTerms: boolean;
  placeholder: string;
  cancelMessage?: string;
  loadingMessage?: string;
  seeResultsMessage?: string;
  noResultsMessage?: string;
  initialText?: string;
  containerStyles?: string;
  inputStyles?: string;
  listItemStyles?: string;
  collapsible?: boolean;
  disabled?: boolean;
  shortBar?: boolean;
  hideSuggestions?: boolean;
  hideCancelButton?: boolean;
};

export function AutoComplete<ResultType = string>({
  onSelect,
  onChange,
  onKeyDown,
  onSeeResults,
  onCollapseChange,
  resultAccessor,
  autocompleteResults,
  loadingAutocompleteTerms,
  placeholder,
  cancelMessage,
  loadingMessage,
  seeResultsMessage,
  noResultsMessage,
  initialText,
  containerStyles,
  inputStyles,
  listItemStyles,
  collapsible,
  shortBar,
  disabled,
  hideSuggestions,
  hideCancelButton,
}: AutoCompleteProps<ResultType>): ReactElement {
  useMotifStyles(MotifComponent.AUTOCOMPLETE);
  const [currentSearchTerm, setCurrentSearchTerm] = useState(initialText ?? '');
  const [collapsedState, setCollapsedState] = useState(true);
  const [showSuggestions, setShowSuggestions] = useState(
    hideSuggestions ? !hideSuggestions : false
  );
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const inputEl = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (collapsedState === false) {
      inputEl.current?.focus();
    }
  }, [collapsedState]);

  const setShowSearchSuggestions = (show: boolean) => {
    if (!hideSuggestions) {
      setShowSuggestions(show);
    }
  };

  const collapseChange = (state: boolean) => {
    if (!state) {
      setShowSearchSuggestions(false);
    }
    setCollapsedState(state);

    if (collapsible && onCollapseChange) {
      onCollapseChange(collapsedState);
    }
  };

  const onInputChange: ChangeEventHandler<HTMLInputElement> = e => {
    onChange(e.target.value);

    setCurrentSearchTerm(e.target.value);
    const term = e.target.value.trimStart();
    setShowSearchSuggestions(term.length > 0);

    // don't search on just spaces
    if (term.length > 0) {
      setSelectedIndex(-1);
    }
  };

  const onClick = (term: ResultType) => {
    if (collapsible) {
      collapseChange(true);
    }

    if (showSuggestions) {
      setCurrentSearchTerm('');
      setSelectedIndex(-1);
    }

    onSelect(term);
  };

  const onCancel = () => {
    collapseChange(true);
    setCurrentSearchTerm('');
    setSelectedIndex(-1);
  };

  const onSeeResultsHandler = () => {
    if (collapsible) {
      collapseChange(true);
    }

    if (showSuggestions) {
      setCurrentSearchTerm('');
      setSelectedIndex(-1);
    }

    onSeeResults?.(currentSearchTerm);
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = event => {
    const autocompleteLength = autocompleteResults.length;

    if (event.key === 'Escape') {
      setShowSearchSuggestions(false);
    } else if (event.key === 'ArrowUp') {
      const newSelectedIndex = Math.max(selectedIndex - 1, 0) % autocompleteLength;
      setSelectedIndex(newSelectedIndex);
      event.preventDefault();
    } else if (event.key === 'ArrowDown') {
      const newSelectedIndex =
        Math.min(selectedIndex + 1, autocompleteLength - 1) % autocompleteLength;
      setSelectedIndex(newSelectedIndex);
      event.preventDefault();
    } else if (event.key === 'Enter') {
      if (autocompleteResults.length > 0 || hideSuggestions) {
        if (selectedIndex < 0 && onSeeResults) {
          return onSeeResultsHandler();
        }

        selectedIndex < 0
          ? onClick(autocompleteResults[0]!)
          : onClick(autocompleteResults[selectedIndex]!);
      }
      setSelectedIndex(0);
    }

    onKeyDown?.(event);
  };

  const renderSuggestions = () => (
    <div
      className={
        autocompleteResults.length === 0 || loadingAutocompleteTerms
          ? resultsContainerWithResultsCss
          : resultsContainerNoResultsCss
      }
    >
      {autocompleteResults.length > 0 ? (
        autocompleteResults.map((result: ResultType, index: number) => (
          <AutocompleteSuggestion<ResultType>
            term={result}
            onClick={onClick}
            index={index}
            // would have preferred this way but compiler forced me. Is there a better way? key={resultAccessor?.(result) ?? result}
            key={typeof result === 'string' ? result : resultAccessor?.(result)}
            isActive={selectedIndex === index}
            setSelectedIndex={setSelectedIndex}
            listItemStyles={listItemStyles}
            resultAccessor={resultAccessor}
            loadingAutocompleteTerms={loadingAutocompleteTerms}
            loadingMessage={loadingMessage}
          />
        ))
      ) : (
        <li className={autocompleteListItemCss}>{noResultsMessage ?? 'No results returned.'}</li>
      )}
    </div>
  );

  if (collapsible && collapsedState) {
    return (
      <IconButton
        size={IconButtonSize.LARGE}
        iconName="search"
        disabled={disabled}
        className={searchButtonCss}
        onClick={() => collapseChange(false)}
      />
    );
  }

  const showSeeResults =
    showSuggestions &&
    !loadingAutocompleteTerms &&
    seeResultsMessage &&
    autocompleteResults.length > 0;

  return (
    <div
      className={cx(MotifComponent.AUTOCOMPLETE, autoCompleteContainerCss, containerStyles, {
        [hiddenSuggestionsCss]: !showSuggestions,
        [shortAutoCompleteCss]: shortBar,
      })}
      onClick={() => {
        setShowSearchSuggestions(currentSearchTerm.length > 0);
        inputEl.current?.focus();
      }}
    >
      <div className={rowCss}>
        <Icon size={20} className={searchIconCss} name="search" />
        <input
          className={cx(inputCss, inputStyles)}
          ref={inputEl}
          onChange={onInputChange}
          onKeyDown={handleKeyDown}
          value={currentSearchTerm}
          placeholder={placeholder}
        />
        {collapsible && !hideCancelButton && (
          <button onClick={onCancel} className={cancelButtonCss}>
            {cancelMessage}
          </button>
        )}
      </div>
      {showSuggestions ? renderSuggestions() : undefined}
      {showSeeResults ? (
        <button
          onClick={onSeeResultsHandler}
          className={seeResultsCss}
        >{`${seeResultsMessage} "${currentSearchTerm}"`}</button>
      ) : undefined}
    </div>
  );
}
