/* eslint-disable no-console */
import { cx } from '@emotion/css';
import isNil from 'lodash-es/isNil';
import type { CSSProperties, FC, MouseEventHandler, PropsWithChildren, ReactNode } from 'react';
import { useCallback, useEffect, useRef } from 'react';

import { ToggleState, ToggleTarget, useToggleState } from '../../hooks/useToggleState';
import { MotifComponent, useMotifStyles } from '../../motif';
import { dataSetToAttributes } from '../../utils';
import { Icon } from '../Icon';
import {
  chevronCss,
  detailsAnimationCss,
  detailsCss,
  detailsSummaryAnimationDurationCssVar,
  summaryCss,
} from './DetailsSummary.styles';
import type { DetailsSummaryProps } from './types';

export const DetailsSummary: FC<PropsWithChildren<DetailsSummaryProps>> = ({
  showChevron = true,
  chevronProps,
  onToggle,
  summary,
  summaryProps,
  children,
  className: detailsClassName,
  fadeInAnimation = true,
  transitionDurationMs,
  open: forceOpen,
  detailsRef: parentDetailsRef,
  summaryRef,
  disableScrollToOnOpen,
  forceOpenAttribute,
  ...detailsProps
}) => {
  // If the 'forceOpen' is set it effectively takes state control away from this component.
  const parentHasStateControl = !isNil(forceOpen);
  useMotifStyles(MotifComponent.DETAIL_SUMMARY);

  const scrollTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  // we need this for scrolling into view on click
  const internalDetailsRef = useRef<HTMLDetailsElement | null>(null);

  // assignign details to two refs because the prop ref
  // isn't always available when we need it in the scrollTo handler
  const setDetailRefs = useCallback(
    (el: HTMLDetailsElement) => {
      if (parentDetailsRef) {
        parentDetailsRef.current = el;
      }
      internalDetailsRef.current = el;
    },
    [parentDetailsRef]
  );

  const { state: isOpenState, toggle: toggleIsOpenInternal } = useToggleState({
    initialValue: parentHasStateControl && forceOpen ? ToggleState.ON : ToggleState.OFF,
    transitionDurationMs,
  });

  // If parent controls the open state, then we need to update internal state to match.
  useEffect(() => {
    if (!parentHasStateControl) {
      return;
    }

    toggleIsOpenInternal(forceOpen ? ToggleTarget.ON : ToggleTarget.OFF);
  }, [parentHasStateControl, forceOpen, toggleIsOpenInternal]);

  // If parent has no control, then only notify 'onToggle'.
  useEffect(() => {
    if (parentHasStateControl || !onToggle) {
      return;
    }

    onToggle(isOpenState === ToggleState.ON ? ToggleTarget.ON : ToggleTarget.OFF);
  }, [onToggle, isOpenState, parentHasStateControl]);

  const toggleWithAnimation: MouseEventHandler<HTMLDetailsElement> = event => {
    // Stop the toggle event from happening. We fire it off manually later.
    event.preventDefault();

    // This notifies the parent of a click event and doesn't change internal state.
    if (parentHasStateControl) {
      onToggle?.(forceOpen ? ToggleTarget.OFF : ToggleTarget.ON);
    } else {
      toggleIsOpenInternal();
      // NOTE: 'onToggle' is notified separately (see above).
    }

    if (!disableScrollToOnOpen) {
      // clears any existing timeouts so we dont scroll when user clicks quickly
      clearTimeout(scrollTimeoutRef.current);

      // this is fired during the onClick, and we only need to scroll if the target
      const needsScroll = parentHasStateControl ? !forceOpen : !internalDetailsRef?.current?.open;

      if (needsScroll) {
        scrollTimeoutRef.current = setTimeout(() => {
          internalDetailsRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }, transitionDurationMs);
      }
    }
  };

  /**
   * Keeps details open of forceOpenAttribute is set. The parent should be responsible for
   * controlling the open attribute in this case since they may have animations that rely on the
   * open attribute.
   *
   * Otherwise, it is not a controlled component and the native open should just match the internal
   * toggle state.
   */
  const isNativeOpen = forceOpenAttribute || isOpenState !== ToggleState.OFF;

  const { className: summaryClassName, dataset, ...otherSummaryProps } = summaryProps ?? {};

  const getTitle = (summary: ReactNode) => {
    if (typeof summary !== 'string') return undefined;

    return summary;
  };

  return (
    <details
      ref={setDetailRefs}
      className={cx(MotifComponent.DETAIL_SUMMARY, detailsCss, detailsClassName, {
        [detailsAnimationCss]: fadeInAnimation,
      })}
      open={isNativeOpen}
      data-state={isOpenState}
      {...detailsProps}
      style={
        {
          ...detailsProps.style,
          [detailsSummaryAnimationDurationCssVar]: `${transitionDurationMs ?? 250}ms`,
        } as CSSProperties
      }
    >
      <summary
        ref={summaryRef}
        role="button"
        tabIndex={0}
        title={getTitle(summary)}
        className={cx(summaryCss, summaryClassName)}
        onClick={toggleWithAnimation}
        {...dataSetToAttributes(dataset)}
        {...otherSummaryProps}
      >
        {summary}
        {showChevron && (
          <Icon
            className={cx(chevronCss, chevronProps?.className)}
            name="chevron-down"
            fill={chevronProps?.fill}
          />
        )}
      </summary>
      {children}
    </details>
  );
};
DetailsSummary.displayName = 'DetailsSummary';
