import { useContentfulImages } from '@snapchat/mw-contentful-client';
import { Button as ButtonSDS, ButtonType } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import { shouldFlushLogs } from '../../helpers/getLocalPath';
import { logger } from '../../helpers/logging';
import { UserAction } from '../../types/events';
import { renderRichTextMarkingsOnly } from '../../utils/renderText/renderRichText';
import { useConsumerContext } from '../useConsumerContext';
import type { ButtonData } from './query';
import type { ButtonComponentProps } from './types';

export const Button: FC<ButtonData & ButtonComponentProps> = props => {
  const {
    url,
    onClick,
    buttonType,
    title,
    size,
    image,
    iconName,
    targetAsset,
    className,
    buttonTextDataset,
  } = props;
  const { logEvent } = useConsumerContext();
  const { getImageSources } = useContentfulImages();

  const onClickHandler = (): void => {
    onClick?.(); // Used by CallToAction to log a click on CTA. Kept for legacy reasons.
    logEvent?.({ type: UserAction.Click, component: 'Button', url });

    if (shouldFlushLogs(url)) {
      void logger.flush();
    }
  };

  const type = buttonType ?? ButtonType.Primary;
  const imgSrcs = getImageSources(image?.media?.url);
  return (
    <ButtonSDS
      className={className}
      link={targetAsset?.url ?? url}
      type={type}
      onClick={onClickHandler}
      size={size}
      iconName={iconName ?? undefined}
      imgSrcs={imgSrcs}
      imgAltText={image?.media?.title}
      buttonTextDataset={buttonTextDataset}
    >
      {renderRichTextMarkingsOnly(title)}
    </ButtonSDS>
  );
};

Button.displayName = 'Button';
