import type { Operation } from '@apollo/client';
import { ApolloLink } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import {
  globalComponentsApolloLink as importedLink,
  globalComponentsClientName,
} from '@snapchat/mw-global-components-schema';

// We need to import and export this because if we don't
// it will reference global-components-schema. By casting it
// here, we can force the type to be ApolloLink (which it is).
const globalComponentsApolloLink = importedLink as ApolloLink;

/**
 * Helper function for adding global components apollo link to an existing apollo client.
 *
 * Add this to your client like this:
 *
 *     const link = ...;
 *     const client = new ApolloClient({
 *       link: withGlobalComponentsApolloLink(link),
 *     })
 */
const withGlobalComponentsApolloLink = (link: ApolloLink): ApolloLink => {
  const retryLink = new RetryLink({
    delay: {
      max: 500,
    },
    attempts: {
      max: 5,
      retryIf: err => {
        if (err) {
          const error = err.toString();
          const is429Error = error.includes('429');
          const is5xxError = error.includes('500') || error.includes('502');
          return is429Error || is5xxError;
        }

        return false;
      },
    },
  });

  const globalLink: ApolloLink = ApolloLink.from([retryLink, globalComponentsApolloLink]);

  const isGlobal = (operation: Operation): boolean =>
    operation.getContext().clientName === globalComponentsClientName;

  return ApolloLink.split(isGlobal, globalLink, link);
};

export { globalComponentsApolloLink, withGlobalComponentsApolloLink };
