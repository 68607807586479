import type { FC } from 'react';

import type { ContentfulIdVariable } from '../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../hooks/useContentfulQuery';
import type {
  QuestionnaireProps,
  QuestionType,
} from '../../sites/for-business/components/Questionnaire';
import { Questionnaire } from '../../sites/for-business/components/Questionnaire';
import type { QuestionnaireAnswerCollection } from '.';
import { query } from './queries';
import type { QuestionnaireBlockProps } from './types';

export const QuestionnaireBlock: FC<{ id: string }> = props => {
  const { data } = useContentfulQuery<QuestionnaireBlockProps, ContentfulIdVariable>(query, {
    variables: { id: props.id },
  });

  const questionnaire = buildQuestionnaireFullObject(data);

  if (!questionnaire) {
    return null;
  }

  return <Questionnaire {...questionnaire} />;
};

QuestionnaireBlock.displayName = 'QuestionnaireBlock';

function buildQuestionnaireFullObject(data?: QuestionnaireBlockProps): QuestionnaireProps | null {
  if (!data) {
    return null;
  }

  const { questionnaireBlock: questionnaire } = data;
  const { questionsCollection } = questionnaire;

  const questions = questionsCollection.items.map(questionData => ({
    id: questionData.sys.id,
    question: questionData.question,
    body: questionData.body,
    questionType: questionData.questionType as QuestionType,
    image: questionData.backgroundImage?.url,
    answers: buildQuestionAnswers(questionData.answersCollection),
    required: questionData.required,
  }));

  return {
    title: questionnaire.questionnaireTitle,
    body: questionnaire.body,
    image: questionnaire.image?.url,
    submitLabel: questionnaire.submitLabel,
    startLabel: questionnaire.startLabel,
    nextLabel: questionnaire.nextLabel,
    backLabel: questionnaire.backLabel,
    skipLabel: questionnaire.skipLabel,
    questions,
  };
}

function buildQuestionAnswers(answersCollection?: QuestionnaireAnswerCollection) {
  if (!answersCollection) {
    return [];
  }

  const answers = answersCollection.items.map(answerData => ({
    id: answerData.sys.id,
    text: answerData.title,
    imageUrl: answerData.image?.url,
    isSelected: false,
  }));

  return answers;
}
