import { defaultLocale } from '../constants';
import { cleanAndJoinText } from './cleanText';
import { extractEntryText } from './extractEntryText';
import { getPlainText } from './getPlainText';
import type {
  AccordionType,
  BarChartGroupType,
  BarChartStackType,
  BarChartType,
  BlockType,
  ContentfulClient,
  ContentType,
  GeoVisualizationType,
  LineChartType,
  Locales,
  MultiVisualizationType,
  TableVisualizationType,
  TabsType,
} from './types';

/** Helper function to extract text from a Block content type */
export async function extractBlockText(
  block: BlockType,
  locales: Locales[],
  contentfulClient: ContentfulClient
): Promise<Record<Locales, string>> {
  // Extract block text for each locale
  const blockText = extractEntryText<BlockType['fields']>(block.fields, locales, [
    'eyebrow',
    'title',
    'subtitle',
  ]);

  // Extract content text for each locale
  const contentPromises = block.fields.contents?.[defaultLocale]?.map(async content => {
    if (content?.sys.contentType.sys.id === 'content') {
      return extractEntryText<ContentType['fields']>((content as ContentType).fields, locales, [
        'title',
        'subtitle',
        'body',
      ]);
    }

    if (content?.sys.contentType.sys.id === 'tabs') {
      return await extractTabsText(content.sys.id, locales, contentfulClient);
    }

    if (content?.sys.contentType.sys.id === 'accordion') {
      return await extractAccordionText(content.sys.id, locales, contentfulClient);
    }

    // All other visualizations
    const visualizationTypes = [
      'barChart',
      'barChartGroup',
      'barChartStack',
      'geoVisualization',
      'lineChart',
      'visualizationSelector',
      'tableVisualization',
    ];

    if (content && visualizationTypes.includes(content.sys.contentType.sys.id)) {
      return await extractChartTitle(content.sys.id, locales, contentfulClient);
    }

    // We don't index content from other content types
    return {} as Record<Locales, string>;
  });

  const contentChunks = await Promise.all(contentPromises ?? []);

  // Combine block text and content text for each locale, store it in a map
  const localesToBlockText: Record<Locales, string> = {};

  for (const locale of locales) {
    const localeBlockText = [blockText[locale], ...contentChunks.map(content => content[locale])];
    localesToBlockText[locale] = cleanAndJoinText(localeBlockText);
  }

  return localesToBlockText;
}

async function extractTabsText(
  tabsId: string,
  locales: Locales[],
  contentfulClient: ContentfulClient
): Promise<Record<Locales, string>> {
  // Extract tab text for each locale
  const tabsData = await contentfulClient.getEntries({
    'sys.id[in]': [tabsId],
    include: 2,
  });

  const tabs = tabsData.items[0] as TabsType;
  const tabItems = tabs?.fields.items?.[defaultLocale];

  // Extract tab item text for each locale
  const tabItemsText = (tabItems ?? []).map(tabItem => {
    const contents = tabItem.fields.contents?.[defaultLocale];

    // Extract tab item content text for each locale
    const contentsText = (contents ?? [])?.map(content => {
      if (content.sys.contentType.sys.id === 'content') {
        return extractEntryText<ContentType['fields']>(content.fields, locales, [
          'title',
          'subtitle',
          'body',
        ]);
      }

      return {} as Record<Locales, string>;
    });

    // Store tab item content text for each locale in a map
    const localeToContentTextMap: Record<Locales, string> = {};

    for (const locale of locales) {
      const contentsTextForLocale = contentsText.map(content => content[locale]);
      localeToContentTextMap[locale] = cleanAndJoinText(contentsTextForLocale);
    }

    return localeToContentTextMap;
  });

  // Combine tab and tab items text for each locale and store it in a map.
  const localeToTabTextMap: Record<Locales, string> = {};

  for (const locale of locales) {
    const tabsTextForLocale = tabItemsText.map(tab => tab[locale]);
    localeToTabTextMap[locale] = cleanAndJoinText(tabsTextForLocale);
  }

  return localeToTabTextMap;
}

async function extractAccordionText(
  accordionId: string,
  locales: Locales[],
  contentfulClient: ContentfulClient
) {
  const accordionData = await contentfulClient.getEntries({
    'sys.id[in]': [accordionId],
    include: 2,
  });

  const accordion = accordionData.items[0] as AccordionType;
  const accordionTitle = extractEntryText<AccordionType['fields']>(accordion.fields, locales, [
    'title',
  ]);

  const accordionItems = accordion?.fields.items?.[defaultLocale];

  // Get the accordion items text for each locale
  const accordionItemsText = (accordionItems ?? []).map(content => {
    if (content.sys.contentType.sys.id === 'content') {
      return extractEntryText<ContentType['fields']>(content.fields, locales, [
        'title',
        'subtitle',
        'body',
      ]);
    }

    return {} as Record<Locales, string>;
  });

  // Store the accordion items text in a locale map
  const localeToTextMap: Record<Locales, string> = {};

  for (const locale of locales) {
    const accordionItemsTextForLocale = accordionItemsText.map(items => items[locale]);

    localeToTextMap[locale] = cleanAndJoinText([
      accordionTitle[locale],
      ...accordionItemsTextForLocale,
    ]);
  }

  return localeToTextMap;
}

async function extractChartTitle(
  visualizationId: string,
  locales: Locales[],
  contentfulClient: ContentfulClient
): Promise<Record<Locales, string>> {
  const visualizationData = await contentfulClient.getEntries({
    'sys.id[in]': [visualizationId],
    // The include option fetches nested entries up to the given depth. We don't need any referenced entries, so set to 0.
    include: 0,
  });

  const visualization = visualizationData.items[0] as
    | BarChartType
    | BarChartGroupType
    | BarChartStackType
    | LineChartType
    | GeoVisualizationType
    | MultiVisualizationType
    | TableVisualizationType;

  const localeToTextMap: Record<Locales, string> = {};

  for (const locale of locales) {
    const chartTitle =
      visualization.fields?.chartTitle?.[locale] ??
      visualization.fields?.chartTitle?.[defaultLocale];

    localeToTextMap[locale] = getPlainText(chartTitle);
  }

  return localeToTextMap;
}
