/* eslint-disable react/jsx-key */
// biome-ignore lint/suspicious/noShadowRestrictedNames: TODO: See if this is necessary.
import flatMap from 'lodash-es/flatMap';
import unescape from 'lodash-es/unescape';
import type { ReactElement, ReactNode } from 'react';

export const convertNewLineToBr = (text: string): (string | ReactElement)[] => {
  return flatMap(text.split('\n'), (text, i) =>
    i > 0
      ? // biome-ignore lint/correctness/useJsxKeyInIterable: key makes it render more newlines than necessary.
        [<br />, text]
      : [text]
  );
};

// TODO: Remove this hack.
export const retainLtrText = (text: string | ReactElement): ReactNode => {
  if (typeof text === 'string' && text.includes('Snap Inc.')) {
    return text.split('Snap Inc.').flatMap((textSegment: string, index: number) => {
      if (index === 0) return [unescape(textSegment)];
      return [
        <span dir="ltr" key={textSegment}>
          Snap Inc.
        </span>,
        unescape(textSegment),
      ];
    });
  }

  const textToReturn = typeof text === 'string' ? unescape(text) : text;
  return [textToReturn];
};

/**
 * Inserts line breaks where new lines exist and other shananagans.
 *
 * TODO: Remove the ltr hack for snap inc. This code is horrible and should probably be fixed w/
 * non-break word markers instead (i.e. regex replace).
 */
export const renderMultiLineText: (text?: string) => ReactNode = (text = '') => {
  const textWithLineBreaks = convertNewLineToBr(text);
  const preservedLtrText = flatMap(textWithLineBreaks, retainLtrText);
  return preservedLtrText;
};
