import type {
  FooterGroup as CustomFooterGroup,
  FooterGroupItemsItem as CustomFooterGroupItems,
} from '@snapchat/mw-contentful-schema';
import type {
  CustomFooterGroupProps as GlobalCustomFooterGroupProps,
  FooterGroupItem as GlobalFooterGroupItemProp,
} from '@snapchat/mw-global-components';

const convertToGenericProps = (
  item: CustomFooterGroupItems,
  cookieSettingsUrl: string
): GlobalFooterGroupItemProp | undefined => {
  if (item.__typename === 'FooterCookiesSettingsLink') {
    return {
      id: item.sys.id,
      title: item.title ?? '',
      url: cookieSettingsUrl,
    };
  }

  if (item.__typename === 'FooterLocaleDropdown') {
    return undefined;
  }

  if (item.__typename === 'FooterItem') {
    if (!item.url || !item.title) {
      return undefined;
    }

    return {
      analyticsLabel: item?.analytics?.label,
      id: item.sys.id,
      title: item.title,
      url: item.url,
    };
  }

  return undefined;
};

export const filterCriteria = (
  filterFunc: (item: CustomFooterGroupItems | CustomFooterGroup) => boolean,
  cookieSettingsUrl: string,
  collection?: CustomFooterGroup[]
): GlobalCustomFooterGroupProps[] => {
  if (!collection || !collection.length) {
    return [];
  }

  const filterAndMapItems = (items: CustomFooterGroupItems[]): GlobalFooterGroupItemProp[] => {
    return items.reduce((outputItems, inputItem) => {
      if (filterFunc(inputItem)) {
        const genericItem = convertToGenericProps(inputItem, cookieSettingsUrl);

        if (genericItem) {
          outputItems.push(genericItem);
        }
      }

      return outputItems;
    }, [] as GlobalFooterGroupItemProp[]);
  };

  const filterAndMapGroups = (groups: CustomFooterGroup[]): GlobalCustomFooterGroupProps[] => {
    return groups.reduce((outputGroups, inputGroup) => {
      if (filterFunc(inputGroup)) {
        // if missing any key data, skip this group
        if (!inputGroup.groupKey || !inputGroup.itemsCollection?.items) {
          return outputGroups;
        }

        outputGroups.push({
          items: filterAndMapItems(inputGroup.itemsCollection.items),
          title: inputGroup.title ?? '',
          groupKey: inputGroup.groupKey,
        });
      }

      return outputGroups;
    }, [] as GlobalCustomFooterGroupProps[]);
  };

  return filterAndMapGroups(collection);
};
