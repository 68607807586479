import { useGlobalComponentsContentfulQuery } from '@snapchat/mw-global-components-schema';
import {
  BackgroundColor,
  FooterBar as FooterBarSDS,
  FooterColumns as FooterColumnsSDS,
  FooterV2 as FooterV2SDS,
  Logo,
  Orientation,
  PrimitivesProvider,
} from '@snapchat/snap-design-system-marketing';
import head from 'lodash-es/head';
import type { FC, ReactNode } from 'react';
import { useContext } from 'react';

import {
  defaultLocaleCode,
  GlobalComponentsContext,
} from '../../../context/GlobalComponentsContext';
import type { FooterV3AllType } from '../../../generated/contentful-types';
import { FooterV3CollectionQueryDocumentType } from '../../../generated/contentful-types';
import { FooterType } from '../../GlobalFooter/GlobalFooter';
import { FooterGroup } from '../FooterGroup/FooterGroup';
import {
  lightBgSnapLogoCss,
  localeDropdownContainerCss,
  logoWrapperCss,
} from '../FooterGroup/FooterGroup.styles';
import { FooterLocaleDropdown } from '../FooterLocaleDropdown/FooterLocaleDropdown';
import type { FooterGroupItem } from '../FooterV3Types';
import { filterFooterDomains, mergeFooterData } from './utils';

export type CustomFooterGroupProps = {
  id?: string;
  title: string;
  groupKey: string;
  items: FooterGroupItem[];
  analyticsLabel?: string;
};

interface LocaleDropdownData {
  id: string;
  title: string;
}

interface FooterCollectionV3Props {
  customColumns?: CustomFooterGroupProps[];
  customFooterBar?: CustomFooterGroupProps[];
  backgroundColor?: BackgroundColor;
  logo?: React.ReactNode;
  /** Renders at the top of the footer. Used to link to socials. */
  socialBar?: React.ReactNode;
  footerType?: FooterType;
  cookieSettingsUrl?: string;
}

export const FooterCollectionV3: FC<FooterCollectionV3Props> = ({
  customColumns,
  customFooterBar,
  backgroundColor = BackgroundColor.White,
  logo,
  socialBar,
  footerType = FooterType.FULL_FOOTER,
  cookieSettingsUrl,
}) => {
  const {
    Anchor,
    supportedLocales,
    currentLocale,
    hostname,
    isPreview,
    isSSR,
    globalApolloClient,
  } = useContext(GlobalComponentsContext);

  const { data, loading, error } = useGlobalComponentsContentfulQuery(
    FooterV3CollectionQueryDocumentType,
    {
      currentLocale: currentLocale ?? defaultLocaleCode,
      isPreview: isPreview ?? false,
      isSSR: isSSR ?? false,
    },
    { client: globalApolloClient }
  );

  if (loading || !!error || !hostname || !data) {
    return null;
  }

  const hasMultipleLanguages = Object.keys(supportedLocales || {}).length > 1;
  const footerProps = head(data.footerV3Collection.items) as FooterV3AllType;

  const globalFooterColumnsItems = footerProps?.columnsCollection.items ?? [];
  const globalFooterColumnsCollectionGroups = filterFooterDomains(
    globalFooterColumnsItems,
    hostname
  );

  const globalFooterBarItems = footerProps?.barCollection.items ?? [];
  const globalFooterBarCollectionGroups = filterFooterDomains(globalFooterBarItems, hostname);

  let localeDropdownData: LocaleDropdownData | undefined;

  globalFooterBarCollectionGroups.forEach(group => {
    const localeDropdownItem = group.itemsCollection.items.find(
      i => i.__typename === 'FooterLocaleDropdown'
    );

    if (localeDropdownItem && !localeDropdownItem.hideOnDomains?.includes(hostname)) {
      localeDropdownData = { title: localeDropdownItem.title, id: localeDropdownItem.sys.id };
    }
  });

  const columnsGroups = mergeFooterData(
    globalFooterColumnsCollectionGroups,
    customColumns ?? [],
    cookieSettingsUrl
  );

  const barGroups = mergeFooterData(
    globalFooterBarCollectionGroups,
    customFooterBar ?? [],
    cookieSettingsUrl
  );

  let finalLogo: ReactNode;

  if (barGroups.length) {
    if (logo) {
      finalLogo = logo;
    } else {
      const defaultLogo = (
        <Logo
          url={footerProps?.url}
          className={logoWrapperCss}
          innerLogoClassName={
            backgroundColor !== BackgroundColor.Black ? lightBgSnapLogoCss : undefined
          }
          logoType={'SnapInc'}
          openInNewTab
        />
      );

      finalLogo = defaultLogo;
    }

    if (finalLogo && !footerProps?.hideLogoOnDomains?.includes(hostname)) {
      //(NOTE: dvazbynd): adding the logo item to the right group to match position in the design
      barGroups[0]!.beforeItems = <li>{finalLogo}</li>;
    }

    // Add locale dropdown to end of bar groups
    if (localeDropdownData) {
      const localeDropdown = (
        <li
          key={localeDropdownData.id}
          id={localeDropdownData.id}
          className={localeDropdownContainerCss}
        >
          <FooterLocaleDropdown
            title={localeDropdownData.title}
            currentOrientation={Orientation.Horizontal}
          />
        </li>
      );

      // TODO: we don't want to modify bar groups like this,
      // and directly render localeDropdown instead. Likely paired
      // with a migration to move localeDropdown to its own field on footer.
      barGroups.push({
        id: 'localeDropdownGroup',
        title: '',
        items: [],
        orientation: Orientation.Horizontal,
        afterItems: localeDropdown,
      });
    }
  }

  let output: ReactNode = null;

  if (footerType === FooterType.NO_FOOTER) {
    // Handle no footer case
    if (!hasMultipleLanguages) {
      return null;
    }

    if (!localeDropdownData) return null;

    const localeDropdown = (
      <li
        key={localeDropdownData.id}
        id={localeDropdownData.id}
        className={localeDropdownContainerCss}
      >
        <FooterLocaleDropdown
          title={localeDropdownData.title}
          currentOrientation={Orientation.Horizontal}
        />
      </li>
    );

    output = (
      <FooterV2SDS backgroundColor={backgroundColor}>
        {socialBar}
        <FooterBarSDS>
          <FooterGroup
            key={'noFooter'}
            id={'localeDropdown'}
            title=""
            items={[]}
            orientation={Orientation.Horizontal}
            afterItems={localeDropdown}
          />
        </FooterBarSDS>
      </FooterV2SDS>
    );
  } else {
    // handle hideFooterLinks or full footer case
    const footerBar = (
      <FooterBarSDS>
        {barGroups.map((group, i) => {
          return (
            <FooterGroup
              key={`${i}-${group.id}`}
              id={group.id}
              title={group.title}
              items={group.items}
              orientation={Orientation.Horizontal}
              beforeItems={group.beforeItems}
              afterItems={group.afterItems}
              isFooterBar
            />
          );
        })}
      </FooterBarSDS>
    );

    if (footerType === FooterType.SIMPLE_FOOTER) {
      output = (
        <FooterV2SDS backgroundColor={backgroundColor}>
          {socialBar}
          {footerBar}
        </FooterV2SDS>
      );
    } else {
      const footerColumns = columnsGroups.map((group, i) => (
        <FooterGroup
          key={`${i}-${group.id}`}
          id={group.id}
          title={group.title}
          items={group.items}
        />
      ));

      output = (
        <FooterV2SDS backgroundColor={backgroundColor}>
          {socialBar}
          <FooterColumnsSDS>{footerColumns}</FooterColumnsSDS>
          {footerBar}
        </FooterV2SDS>
      );
    }
  }

  return <PrimitivesProvider value={{ Anchor }}>{output}</PrimitivesProvider>;
};
