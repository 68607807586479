import { gql } from '@apollo/client';

import type { ContentfulTypedSysProps } from '../../types/contentful';
import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';
import type { MediaDataProps } from '../Media';
import { assetFragment } from '../Media';

export interface PageAssetDataProps extends ContentfulTypedSysProps<'PageAsset'> {
  media: MediaDataProps;
}

// =================================================================================================
// Page Asset Fragment
// =================================================================================================
export const pageAssetFragment = gql`
  fragment PageAssetAll on PageAsset {
    ...ContentfulSysId
    media {
      ...AssetAll
    }
  }
  ${assetFragment}
  ${contentfulSysIdFragment}
`;
