import { cx } from '@emotion/css';
import type { FC, MouseEventHandler } from 'react';
import type React from 'react';
import { memo, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { BrowserFeaturesContext, isMobileOs } from '../../BrowserFeatures';
import { mobileMaxWidth } from '../../constants';
import { MotifComponent, useMotifStyles } from '../../motif';
import { dataSetToAttributes, getBackgroundClassName } from '../../utils';
import { useWindowSize } from '../../utils/useWindowSize';
import { Icon } from '../Icon';
import { SideNavigationLinks } from './SideNavigationLinks';
import {
  caratCss,
  containerCss,
  sectionButtonCss,
  sideNavIconCss,
  sideNavMobileIconCss,
  wrapperCss,
  wrapperShadowCss,
} from './styles';
import type { SideNavigationProps } from './types';

// TODO: refactor this component, does not follow our code style
export const SideNavigation: FC<SideNavigationProps> = memo(
  ({ className, items, isUrlCurrent, dataset, backgroundColor, mobileBackgroundColor }) => {
    useMotifStyles(MotifComponent.SIDE_NAVIGATION);

    const browserFeatures = useContext(BrowserFeaturesContext);
    const { width: windowWidth } = useWindowSize();
    const isMobile = isMobileOs(browserFeatures);
    const containerRef = useRef<HTMLElement | null>(null);
    const sectionsRefs = useRef<Array<HTMLButtonElement | null>>([]);
    const defaultSection = items.findIndex(section =>
      section.links.find(link => isUrlCurrent?.(link.url))
    );
    const [activeSection, setActiveSection] = useState<number | null>(defaultSection);
    const [isVisible, setIsVisible] = useState<boolean>(!isMobile);

    // This changes the active section when the URL changes.
    // TODO: Think if there's a more elegant solution.
    useEffect(() => {
      setActiveSection(defaultSection);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUrlCurrent]);

    const isRtl = (): boolean => {
      return !!containerRef?.current?.closest('[dir="rtl"]');
    };

    const handleSectionClick =
      (idx: number): MouseEventHandler =>
      event => {
        event.preventDefault();
        setActiveSection(idx === activeSection ? null : idx);
      };

    const handleSectionKeyPress = (idx: number) => (event: React.KeyboardEvent) => {
      // focus next section
      if (event.key === 'ArrowDown') {
        const next: HTMLButtonElement | null | undefined = sectionsRefs.current[idx + 1];

        if (next) {
          next.focus();
        }
      }

      // focus previous section
      if (event.key === 'ArrowUp') {
        const prev: HTMLButtonElement | null | undefined = sectionsRefs.current[idx - 1];

        if (prev) {
          prev.focus();
        }
      }

      // open/close section
      if (event.key === 'ArrowRight') {
        setActiveSection(isRtl() ? null : idx);
      }

      if (event.key === 'ArrowLeft') {
        setActiveSection(isRtl() ? idx : null);
      }
    };

    const focusSection = (idx: number) => {
      const section: HTMLButtonElement | null | undefined = sectionsRefs.current[idx];

      if (section) {
        section.focus();
      }
    };

    const toggleIsVisible = () => {
      setIsVisible(!isVisible);
    };

    const showTopBar = useMemo(() => windowWidth && windowWidth <= mobileMaxWidth, [windowWidth]);

    useEffect(() => {
      setIsVisible(!showTopBar);
    }, [showTopBar]);

    return (
      <section
        data-test-id="sdsm-side-navigation"
        className={cx(
          MotifComponent.SIDE_NAVIGATION,
          getBackgroundClassName(showTopBar ? mobileBackgroundColor : backgroundColor),
          wrapperCss,
          {
            [wrapperShadowCss]: !isVisible,
          },
          className
        )}
      >
        <button
          type="button"
          data-test-id="sdsm-side-navigation-mobile-toggle"
          aria-label={items[defaultSection]?.title ?? 'Secondary Navigation'}
          className={sectionButtonCss}
          onClick={toggleIsVisible}
        >
          <span>{items[defaultSection]?.title ?? 'Secondary Navigation'}</span>
          <Icon className={sideNavMobileIconCss} name={isVisible ? 'chevron-up' : 'chevron-down'} />
        </button>
        <nav
          data-test-id="sdsm-side-navigation-links"
          aria-label="Secondary Navigation"
          ref={containerRef}
          className={containerCss}
          style={{ display: isVisible ? 'block' : 'none' }}
          {...dataSetToAttributes(dataset)}
        >
          <ul role="menubar" aria-label="Secondary Navigation">
            {items.map((section, idx) => (
              <li key={idx}>
                <button
                  ref={el => {
                    sectionsRefs.current[idx] = el;
                  }}
                  type="button"
                  onKeyDown={handleSectionKeyPress(idx)}
                  onClick={handleSectionClick(idx)}
                  role="menuitem"
                  aria-haspopup={items[idx]!.links.length > 0}
                  aria-expanded={activeSection === idx}
                  tabIndex={0}
                  aria-label={section.title}
                >
                  <span {...dataSetToAttributes(section.dataset)}>{section.title}</span>
                  <span className={caratCss}>
                    <Icon
                      className={sideNavIconCss}
                      name={activeSection === idx ? 'chevron-up' : 'chevron-down'}
                      size={18}
                    />
                    <Icon
                      className={sideNavIconCss}
                      name={activeSection === idx ? 'chevron-up' : 'chevron-down'}
                      size={18}
                    />
                    <Icon
                      className={sideNavIconCss}
                      name={activeSection === idx ? 'chevron-up' : 'chevron-down'}
                      size={18}
                    />
                  </span>
                </button>

                <ul
                  role="menu"
                  aria-label={section.title}
                  style={{ display: activeSection === idx ? 'block' : 'none' }}
                >
                  <SideNavigationLinks
                    links={section.links}
                    isUrlCurrent={isUrlCurrent}
                    focusSection={() => focusSection(idx)}
                    isRTL={isRtl}
                  />
                </ul>
              </li>
            ))}
          </ul>
        </nav>
      </section>
    );
  }
);

SideNavigation.displayName = 'SideNavigation';
