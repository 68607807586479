import { css, cx } from '@emotion/css';

import { ZIndex } from '../../constants';
import { m, MotifComponent, useMotifStyles } from '../../motif';

export const keyframesAnimationCss = css`
  @keyframes loader-animation {
    0% {
      left: -60%;
    }
    50% {
      left: 60%;
    }
    100% {
      left: -60%;
    }
  }
`;

export const loadingBarContainerCss = css`
  position: absolute;
  height: 3.75px;
  width: 100%;
  overflow: hidden;
`;

export const barCss = css`
  position: relative;
  height: 5px;
  z-index: ${ZIndex.LOADING_BAR};
  width: 75%;
  margin: 0;
  background: linear-gradient(
    90deg,
    transparent 0%,
    ${m('--loading-bar-left-color')} 14%,
    ${m('--loading-bar-right-color')} 86%,
    transparent 100%
  );

  animation-name: loader-animation;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
`;

/** Creates the Loading Bar component */
export const LoadingBar: React.FC<{ className?: string }> = ({ className }) => {
  useMotifStyles(MotifComponent.LOADING_BAR);

  return (
    <div
      className={cx(
        MotifComponent.LOADING_BAR,
        loadingBarContainerCss,
        keyframesAnimationCss,
        className
      )}
    >
      <hr className={barCss} />
    </div>
  );
};
