import { cx } from '@emotion/css';
import type { FC } from 'react';

import { MotifComponent, useMotifStyles } from '../../motif';
import { spacerHeightFullCss, spacerHeightHalfCss, spacerWrapperCss } from './Break.styled';
import { BreakOverlayType } from './types';

export interface BreakSpacerProps {
  type: BreakOverlayType;
  location: 'top' | 'bottom';
}

export const BreakSpacer: FC<BreakSpacerProps> = ({ type, location }) => {
  // Uses Break styles.
  useMotifStyles(MotifComponent.BREAK);

  if (!type || type === BreakOverlayType.NONE) return null;

  // If this component is placed at the top of a block, check if the bottom of the previous break overlays the block
  // If this component is placed at the bottom of a block, check if the top of the next break overlays the block
  const isFullHeight =
    (location === 'top' && type === BreakOverlayType.BOTTOM) ||
    (location === 'bottom' && type === BreakOverlayType.TOP);

  const isHalfHeight =
    type === BreakOverlayType.TOP_HALF_AND_BOTTOM_HALF ||
    (location === 'top' && type === BreakOverlayType.BOTTOM_HALF) ||
    (location === 'bottom' && type === BreakOverlayType.TOP_HALF);

  if (!isFullHeight && !isHalfHeight) return null;

  return (
    <div
      className={cx(spacerWrapperCss, {
        [spacerHeightFullCss]: isFullHeight,
        [spacerHeightHalfCss]: isHalfHeight,
      })}
      data-testid="break-spacer"
    />
  );
};

BreakSpacer.displayName = 'BreakSpacer';
