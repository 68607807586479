import { cx } from '@emotion/css';
import type { FC, PropsWithChildren } from 'react';

import type { BackgroundColor } from '../../constants';
import { BackgroundStyle } from '../../constants/background';
import { MotifComponent, useMotifStyles } from '../../motif';
import type { BaseComponentProps, ImageSources } from '../../types';
import { getBackgroundClassName } from '../../utils';
import { Picture } from '../Picture';
import { Video } from '../Video';
import {
  backgroundMediaCss,
  containerCss,
  fixedBackgroundMediaCss,
  pageTransparentBgCss,
} from './Page.styled';
import { ScrollSnapContainer } from './ScrollSnapContainer';

export interface PageProps extends BaseComponentProps {
  backgroundImageSources?: ImageSources;
  backgroundColor?: BackgroundColor;
  backgroundVideoSource?: string;
  mobileBackgroundVideoSource?: string;
  backgroundMediaStyle?: BackgroundStyle; // Not CSS properties
  scrollSnap?: boolean;
}

export const Page: FC<PropsWithChildren<PageProps>> = ({
  backgroundColor: pageBackgroundColor,
  backgroundImageSources,
  mobileBackgroundVideoSource,
  backgroundVideoSource,
  backgroundMediaStyle = BackgroundStyle.Normal,
  className: pageClassName,
  scrollSnap,
  children,
}) => {
  useMotifStyles(MotifComponent.PAGE);

  const className = cx(
    MotifComponent.PAGE,
    containerCss,
    getBackgroundClassName(pageBackgroundColor),
    pageClassName,
    /**
     * Need set bg to transparent if bg img or video because we need to keep z-index: -1 on the
     * background media to ensure it's behind the content because some content does not set
     * position: relative. TODO: This is a temporary solution until we have a better way to handle
     * (specifically, cheerios is broken without this, can potentially be fixed by setting
     * position:relative on CheeriosStaticBlock) https://jira.sc-corp.net/browse/WEBP-9706
     */
    {
      [pageTransparentBgCss]: !!(backgroundImageSources || backgroundVideoSource),
    }
  );

  return (
    <div data-test-id="sdsm-page" className={className}>
      {backgroundImageSources && (
        <Picture
          imgSrcs={backgroundImageSources}
          imgClassName={
            backgroundMediaStyle === BackgroundStyle.Fixed
              ? fixedBackgroundMediaCss
              : backgroundMediaCss
          }
          fetchPriority="auto"
        />
      )}
      {backgroundVideoSource ? (
        <Video
          className={
            backgroundMediaStyle === BackgroundStyle.Fixed
              ? fixedBackgroundMediaCss
              : backgroundMediaCss
          }
          source={backgroundVideoSource}
          mobileSource={mobileBackgroundVideoSource}
          isBackgroundVideo
        />
      ) : undefined}
      {scrollSnap ? <ScrollSnapContainer>{children}</ScrollSnapContainer> : children}
    </div>
  );
};

Page.displayName = 'Page';
