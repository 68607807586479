import type { BackgroundColor } from '../../constants/backgroundColor';
import type { BaseComponentProps } from '../../types';

export interface NavigationButtonProps extends BaseComponentProps {
  onClick?: () => void;
  label?: string;
  showArrow?: boolean;
  isDisabled?: boolean;
}
export interface StepActionsProps {
  nextButton?: NavigationButtonProps;
  previousButton?: NavigationButtonProps;
  skipButton?: NavigationButtonProps;
}
export interface BlockNavigationProps {
  step: StepActionsProps;
  /** Defaults to White if not specified */
  backgroundColor?: BackgroundColor;
}
