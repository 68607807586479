export const globalNavOuterMargin = 'min(8vw, 112px)';
export const globalLinksClassName = 'global-links';
export const globalNavMobileLocalNavClassName = 'local-nav';

/** Height of the mobile header. Needs to be updated manually if off. */
export const mobileHeaderHeight = 64;
export const navHeaderShadowSize = 5;

export const globalHeaderHeight = 64;
export const globalHeaderAnimationDuration = 500;
/**
 * Duration for the transition between ON -> OFF states and vice-versa. This is slightly lower than
 * `globalHeaderAnimationDuration` so the animation would still play out during the React state
 * update to avoid flicker.
 */
export const globalHeaderTransitionDuration = 450;

export const globalNavNegativeOuterMargin = 'max(-8vw, -112px)';

export const globalNavGroupAnimationDurationMs = 300;

export const globalNavLevelClassName = 'level-tracker';
export const nextLevelLineHeightCssVar = '--next-level-line-height';

export const slideAnimationTiming = 'cubic-bezier(.04,.49,.54,.96)';

export const testIds = {
  headerTopLevel: 'sdsm-global-header',
  openButton: 'sdsm-global-header-open',
  navItems: 'sdsm-global-header-local-nav-items',
  siteName: 'sdsm-global-header-site-name',
  endContents: 'sdsm-global-header-end-contents',
};
