import { logError } from '../../helpers/logging';

export interface CookieOptions {
  expires?: Date;
  path?: string;
  secure?: boolean;
  httpOnly?: boolean;
  credentials?: string;
  domain?: string;
  sameSite?: 'strict' | 'lax' | 'none';
}

type JsonLike = Record<string, unknown> | boolean | number;

/** Node/browser agnostic cookie utility interface */
export abstract class CookieManager {
  public abstract getCookie: (name: string) => string | undefined;
  public abstract setCookie: (name: string, value: string, options: CookieOptions) => void;
  public abstract deleteCookie: (name: string) => void;

  /**
   * Returns the JSON value for the cookie.
   *
   * Returns `unedefined` if an error occurs.
   */
  public getCookieJson: (name: string) => JsonLike | undefined = name => {
    const value = this.getCookie(name);
    if (!value) return undefined;

    try {
      return JSON.parse(value);
    } catch (error) {
      logError({ component: 'CookieManager', message: `Error parsing cookie: "${value}"`, error });
      return undefined;
    }
  };

  /**
   * Sets the value of the cookie to the JSON serialized version of the value.
   *
   * This will store an '' if the value is nullish.
   */
  setCookieJson: (name: string, value: JsonLike, options: CookieOptions) => void = (
    name,
    value,
    options
  ) => {
    if (!value) {
      this.setCookie(name, '', options);
    } else {
      this.setCookie(name, JSON.stringify(value), options);
    }
  };
}
