import Cookies from 'js-cookie';

import { CategoryOptInCookie } from '../components/types';
import type { CategorizedCookie } from './createCookieMapping';

/** Output of the removeCookiesForNonacceptedCategories() function */
interface CookieRemovalResults {
  [cookieName: string]: {
    value: string;
    wasRemoved?: boolean;
  };
}

/** Helper function to encapsulate logic for cookie deletion. */
const removeCookie = (
  cookie: CategorizedCookie,
  cookieDomain: string,
  currentCookies: Record<string, string>,
  removedCookies: CookieRemovalResults
) => {
  const deleteOptions: Cookies.CookieAttributes = {
    domain: cookie.domain ?? cookieDomain,
    path: cookie.path,
    secure: cookie.secure,
    sameSite: cookie.sameSite,
  };

  if (cookie.regex) {
    // find all matching cookies by REGEX pattern
    // use case: multiple `_ga_{container}` cookies may exist under a single top level domain
    for (const cookieName of Object.keys(currentCookies)) {
      if (!cookie.regex!.test(cookieName)) continue;

      Cookies.remove(cookieName, deleteOptions);
      removedCookies[cookieName] = { value: currentCookies[cookieName] ?? '' };
    }
  } else if (cookie.name in currentCookies) {
    // remove the cookie by name
    Cookies.remove(cookie.name, deleteOptions);
    removedCookies[cookie.name] = { value: currentCookies[cookie.name] ?? '' };
  }

  // otherwise do nothing
};

const removeRelevantCookies = (
  cookieDomain: string,
  cookieType: string,
  currentCookies: Record<string, string>,
  cookieMappings: Map<string, Set<CategorizedCookie>>,
  removedCookies: CookieRemovalResults
): void => {
  // We do not delete Essential cookies
  if (cookieType === CategoryOptInCookie.Essential) return;

  const relevantCookies = cookieMappings.get(cookieType);

  if (relevantCookies) {
    for (const cookie of relevantCookies) {
      removeCookie(cookie, cookieDomain, currentCookies, removedCookies);
    }
  }
};

/**
 * Helper function that checks the user's current cookies and removes any known tracking cookies
 * that are mapped to a category that was opted out. Returns an object representing removal attempts
 * and successes for later logging.
 *
 * @param cookieDomain String, domain attribute used for deletion commands
 * @param newState Record, represents the users opt in state for each cookie category
 * @param cookieMappings A map where the key is the cookie category and the value is a set of all
 *   registered tracking cookies for that category.
 * @param enableCookieDeletion Boolean, feature flag that gates whether to delete tracking cookies
 * @returns Record, An object that tracks the cookies that were attempted to be removed, and which
 *   were successfully removed.
 */
export const removeCookiesForNonacceptedCategories = (
  cookieDomain: string,
  newState: Record<string, boolean>,
  cookieMappings: Map<string, Set<CategorizedCookie>>,
  enableCookieDeletion = false
): CookieRemovalResults => {
  const currentCookies = Cookies.get();

  const removedCookies: CookieRemovalResults = {};

  for (const cookieType in newState) {
    // user is opted into the category, noop
    if (newState[cookieType]) continue;

    // cookie deletion is not enabled, noop
    if (!enableCookieDeletion) continue;

    removeRelevantCookies(cookieDomain, cookieType, currentCookies, cookieMappings, removedCookies);
  }

  // get a second snapshot of active cookies and compare to identify what was successfully removed.
  const updatedCookies = Cookies.get();

  for (const cookieName in removedCookies) {
    removedCookies[cookieName]!.wasRemoved = !updatedCookies[cookieName];
  }

  return removedCookies;
};
