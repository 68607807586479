import { css } from '@emotion/css';
import { blockPadding, h3Css, imgWidth, m } from '@snapchat/snap-design-system-marketing';

import { totalHeaderHeightCssVar } from '../Header/headerSizeUtils';

const galleryWidth = 52;

const galleryWidths = [
  (imgWidth + galleryWidth) * 4,
  (imgWidth + galleryWidth) * 3,
  (imgWidth + galleryWidth) * 2,
  imgWidth + galleryWidth,
];

const screenWidths = galleryWidths.map(w => w + blockPadding * 2);

const mediaQueries = screenWidths.map((width, idx) => {
  if (idx === 0) {
    return `@media screen and (min-width: ${width + 1}px)`;
  }
  const prevWidth = screenWidths[idx - 1];
  return `@media screen and (min-width: ${width + 1}px) and (max-width: ${prevWidth}px)`;
});

export const galleryWrapperCss = css`
  margin-left: auto;
  margin-right: auto;
  margin-top: ${m('--spacing-m')};
  margin-bottom: ${m('--spacing-m')};
  scroll-margin-top: var(${totalHeaderHeightCssVar});

  ${mediaQueries[2]} {
    width: ${galleryWidths[2]}px;
  }

  ${mediaQueries[1]} {
    width: ${galleryWidths[1]}px;
  }

  ${mediaQueries[0]} {
    width: ${galleryWidths[0]}px;
  }
`;

export const galleryPlaceholderCss = css`
  text-align: center;
  margin-top: ${m('--spacing-xl')};
  margin-bottom: ${m('--spacing-xl')};

  ${h3Css}
`;

export const buttonWrapperCss = css`
  padding-top: ${m('--spacing-xl')};
  display: flex;
  justify-content: center;
`;
