import { gql } from '@apollo/client';

import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';

export const fragments = {
  all: gql`
    fragment CodeAll on Code {
      ...ContentfulSysId
      code
      language
    }
    ${contentfulSysIdFragment}
  `,
};
