import noop from 'lodash-es/noop';
import { useLayoutEffect } from 'react';

const isClient = typeof window !== 'undefined';

/**
 * A hook that runs useLayoutEffect but only on the client.
 *
 * On the server it throws a nastly warning (that's not supressible).
 *
 * This is a workaround suggested in
 * https://gist.github.com/gaearon/e7d97cdf38a2907924ea12e4ebdf3c85
 */
export const useBrowserLayoutEffect: typeof useLayoutEffect = isClient ? useLayoutEffect : noop;
