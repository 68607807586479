import type toJsonSchema from 'to-json-schema';

import type {
  ChartData as ContentfulChartData,
  Series as ContentfulSeries,
} from '../contentful-types';

export type JsonSchema = ReturnType<typeof toJsonSchema>;

export type ChartSchema = {
  type: string;
  items: JsonSchema;
};

export type ChartSeries = {
  name: string;
  schema: ChartSchema;
  headerNames?: Record<string, string>;
  localizableProperties?: string[];
};

export type ChartDataRow = Record<string, number | string | Date>;

// Notes:
//
// 1) If a content model has fields of type JSON, then the autogenerated type for that
// field in contentful-schema will be "unknown", which is not so useful. "Series" and "ChartData"
// content models both use JSON for a few of their fields, so here we re-export those autogenerated
// types but with the JSON fields replaced with useful types that model what the expected content
// of the JSON will be.
//
// 2) We define these "adjusted types" in contentful-schema because both the web package and
// contentful-app package make use of them.

export type AdjustedSeries = Omit<ContentfulSeries, 'schema' | 'headerNames'> & {
  schema: ChartSchema;
  headerNames?: Record<string, string>;
};

export type AdjustedChartData = Omit<ContentfulChartData, 'data' | 'localizedData' | 'series'> & {
  data: ChartDataRow[];
  localizedData: ChartDataRow[];
  seriesName: AdjustedSeries;
  label?: string;
};
