import { Black, Gray, White } from '../../constants';
import type { MotifComponent, MotifComponentProps } from '../../motif';
import { m } from '../../motif/m';

export const defaultAutoCompleteVars: MotifComponentProps<MotifComponent.AUTOCOMPLETE> = {
  '--autocomplete-active-border-color': '#275DC5',
  '--autocomplete-active-box-shadow': m('--box-shadow-s'),
  '--autocomplete-button-fg-color': '#275DC5',
  '--autocomplete-button-fg-hover-color': Black.V150,
  '--autocomplete-bg-color': White,
  '--autocomplete-border-color': Gray.V200,
  '--autocomplete-border-width': '2px',
  '--autocomplete-desktop-font-size': m('--text-desktop-font-size'),
  '--autocomplete-desktop-font-weight': m('--text-desktop-font-weight'),
  '--autocomplete-fg-color': Black.V200,
  '--autocomplete-hover-border-color': Gray.V300,
  '--autocomplete-hover-box-shadow': m('--box-shadow-xs'),
  '--autocomplete-mobile-font-size': m('--text-mobile-font-size'),
  '--autocomplete-mobile-font-weight': m('--text-mobile-font-weight'),
  '--autocomplete-placeholder-color': Gray.V300,
} as const;

export const contrastBlackAutoCompleteVars: MotifComponentProps<MotifComponent.AUTOCOMPLETE> = {
  ...defaultAutoCompleteVars,
  '--autocomplete-button-fg-color': White,
  '--autocomplete-button-fg-hover-color': White,
  '--autocomplete-bg-color': Black.V125,
  '--autocomplete-border-color': Black.V50,
  '--autocomplete-fg-color': White,
  '--autocomplete-placeholder-color': Gray.V250,
} as const;

export const constrastGrayAutoCompleteVars: MotifComponentProps<MotifComponent.AUTOCOMPLETE> = {
  ...defaultAutoCompleteVars,
  '--autocomplete-border-color': Gray.V250,
};
