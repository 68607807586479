import { cx } from '@emotion/css';
import type { FC } from 'react';
import { useContext } from 'react';

import { MotifComponent, useMotifStyles } from '../../../motif';
import { PrimitivesContext } from '../../Primitives';
import type { NavigatorItemProps } from '../NavigatorItemDesktop';
import { NavigatorItemFeaturedLink } from '../NavigatorItemFeaturedLink';
import { getColsWithDivider } from '../utils';
import {
  navigatorItemMobileColCss,
  navigatorItemMobileColLabelCss,
  navigatorItemMobileColWithDividerCss,
  navigatorItemMobileCss,
  navigatorItemMobileFeaturedItemCss,
  navigatorItemMobileTextCss,
} from './NavigatorItemMobile.styled';
import type { NavigatorItemMobileProps } from './types';

export const NavigatorItemMobile: FC<NavigatorItemMobileProps> = ({
  col1Label,
  col1Items = [],
  col2Label,
  col2Items = [],
  col3Label,
  col3Items = [],
  featuredItem,
  onClick,
}) => {
  useMotifStyles(MotifComponent.HEADER);
  const { Anchor } = useContext(PrimitivesContext);

  const colsWithDivider = getColsWithDivider({
    col1Label,
    col1Items,
    col2Label,
    col2Items,
    col3Label,
    col3Items,
  });

  /**
   * Very similar to the NavigatorItemLink component, but trimmed down for rendering on mobile
   * (omits descriptive text, icons, etc.)
   */
  const NavigatorItemLinkMobile: FC<NavigatorItemProps> = ({ url, title, analytics }) => (
    <div className={navigatorItemMobileTextCss}>
      <Anchor className="sdsm-nav-link" href={url} onClick={() => onClick?.(url, analytics?.label)}>
        {title}
      </Anchor>
    </div>
  );

  return (
    <div className={cx(navigatorItemMobileCss, 'sdsm-nav-item-mobile')}>
      {/* Column 1 */}
      {!!col1Items?.length && (
        <div
          className={cx(navigatorItemMobileColCss, {
            [navigatorItemMobileColWithDividerCss]: colsWithDivider.includes(1),
          })}
        >
          {!!col1Label && <div className={navigatorItemMobileColLabelCss}>{col1Label}</div>}
          {col1Items?.map(({ url, title, analytics }, i) => (
            <NavigatorItemLinkMobile key={i} url={url} title={title} analytics={analytics} />
          ))}
        </div>
      )}

      {/* Column 2 */}
      {!!col2Items?.length && (
        <div
          className={cx(navigatorItemMobileColCss, {
            [navigatorItemMobileColWithDividerCss]: colsWithDivider.includes(2),
          })}
        >
          {!!col2Label && <div className={navigatorItemMobileColLabelCss}>{col2Label}</div>}
          {col2Items?.map(({ url, title, analytics }, i) => (
            <NavigatorItemLinkMobile key={i} url={url} title={title} analytics={analytics} />
          ))}
        </div>
      )}

      {/* Column 3 */}
      {!!col3Items?.length && (
        <div
          className={cx(navigatorItemMobileColCss, {
            [navigatorItemMobileColWithDividerCss]: colsWithDivider.includes(3),
          })}
        >
          {!!col3Label && <div className={navigatorItemMobileColLabelCss}>{col3Label}</div>}
          {col3Items?.map(({ url, title, analytics }, i) => (
            <NavigatorItemLinkMobile key={i} url={url} title={title} analytics={analytics} />
          ))}
        </div>
      )}

      {/* Featured item */}
      {!!featuredItem && (
        <div className={navigatorItemMobileFeaturedItemCss}>
          <NavigatorItemFeaturedLink
            url={featuredItem.url}
            title={featuredItem.title}
            description={featuredItem.description}
            icon={featuredItem.icon}
            media={featuredItem.media}
            ctaLabel={featuredItem.ctaLabel}
            onClick={() => onClick?.(featuredItem.url, featuredItem.analytics?.label)}
          />
        </div>
      )}
    </div>
  );
};

NavigatorItemMobile.displayName = 'NavigatorItemMobile';
