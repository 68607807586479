import type {
  RunningTrace,
  Tracer,
  TracerEventListener,
  TracerListenerRemover,
} from './tracingTypes';
import { TraceLifeCycleState } from './tracingTypes';

export class BrowserTracer implements Tracer {
  private spanCount = 0;
  private listeners: Set<TracerEventListener> = new Set();
  private activeEvents = 0;

  startSpan: (name: string) => RunningTrace = (name: string) => {
    const markName = `${this.spanCount++}: ${name}`;

    // Pre event trace handling
    this.activeEvents++;

    this.listeners.forEach(listener =>
      listener(markName, name, TraceLifeCycleState.START, this.activeEvents)
    );

    performance.mark(`${markName} start`);
    return { endSpan: () => this.endSpan(name, markName) };
  };

  private endSpan: (name: string, internalTrackingName: string) => void = (name, markName) => {
    performance.mark(`${markName} end`);
    performance.measure(name, `${markName} start`, `${markName} end`);
    // Record performance in dev tools to see these spans.

    // Post event tracing handling
    this.activeEvents--;

    this.listeners.forEach(listener =>
      listener(markName, name, TraceLifeCycleState.END, this.activeEvents)
    );
  };

  addEventListener = (callback: TracerEventListener): TracerListenerRemover => {
    this.listeners.add(callback);

    return () => {
      this.listeners.delete(callback);
    };
  };
}
