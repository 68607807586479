import { cx } from '@emotion/css';
import type { FC } from 'react';

import { dropdownMenuItemCss } from './DropdownMenuItem.styled';
import type { DropdownItemProps } from './types';

export const DropdownMenuItem: FC<DropdownItemProps> = ({ title, onClick, id, isSelected }) => {
  return (
    <button
      data-test-id={id}
      data-selected={Boolean(isSelected)}
      className={cx('sdsm-dropdown-item', dropdownMenuItemCss, {
        selected: isSelected,
      })}
      onClick={onClick}
      role="option"
    >
      {title}
    </button>
  );
};

DropdownMenuItem.displayName = 'DropdownMenuItem';
