import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import type { Document } from '@contentful/rich-text-types';
import type { ClassNamesArg } from '@emotion/css';
import { cx } from '@emotion/css';
import type { FC } from 'react';
import { useContext } from 'react';

import { ContentfulContext } from '../../context';

interface Props {
  document: string | Record<string, unknown> | Document;
  className?: ClassNamesArg;
}

/**
 * Custom Component based on the existing `PlainRichText` component. This component is meant for use
 * ONLY with Cookie components and includes the following custom behaviors:
 *
 * - Renders with a wrapping `div` element
 * - Renders `hyperlinks` to open in a new tab
 * - Accepts a className prop for setting styles on the rendered HTML
 */
export const CookieRichText: FC<Props> = ({ document, className }) => {
  // Used when rendering hyperlinks to append the `lang` query parameter
  const { currentLocale } = useContext(ContentfulContext);

  const renderUri = (targetUrl: string) => {
    const uri = new URL(targetUrl);

    // specify this first so it can be overridden if explicitly set in targetUrl
    if (!uri.searchParams.has('lang')) {
      uri.searchParams.set('lang', currentLocale);
    }

    return uri.href;
  };

  return (
    <div
      data-testid="mwp-cookie-rich-text"
      className={cx('contentful-rich-text', className)}
      // biome-ignore lint/security/noDangerouslySetInnerHtml: Safe here because we use official renderer.
      dangerouslySetInnerHTML={{
        __html: documentToHtmlString(document as unknown as Document, {
          renderNode: {
            hyperlink: node => {
              const text = node.content
                .map(current => {
                  // NOTE: For now, we won't support text styles in the Hyperlink
                  if (!current || current.nodeType !== 'text') return;
                  return current.value;
                }, [])
                .join('');

              const uri = renderUri(node.data.uri);
              return `<a href="${uri}" target="_blank" rel="noopener">${text}</a>`;
            },
          },
        }),
      }}
    />
  );
};
