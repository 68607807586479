import { gql } from '@apollo/client';

import type { ContentfulSysProps, ContentfulTypedSysProps } from '../../types/contentful';
import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';
import { fragments as analyticsFragments } from '../Analytics/query';
import type { AnalyticsDataProps } from '../Analytics/types';
import type { ButtonData } from '../Button';
import { buttonFragment } from '../Button';

// ================================================================================================
// CTA Fragment
// ================================================================================================

export const ctaFragment = gql`
  fragment CallToActionAll on CallToAction {
    ...ContentfulSysId
    analytics {
      ...AnalyticsAll
    }
    presentation {
      ... on Entry {
        ...ContentfulSysId
      }
      ... on Button {
        ...ButtonAll
      }
    }
    url
  }
  ${buttonFragment}
  ${contentfulSysIdFragment}
  ${analyticsFragments.all}
`;

export interface CtaData extends ContentfulTypedSysProps<'CallToAction'> {
  analytics: AnalyticsDataProps;
  presentation: ContentfulSysProps | ButtonData;
  url?: string;
}

export const query = gql`
  query CallToActionQuery($preview: Boolean!, $locale: String!, $id: String!) {
    callToAction(preview: $preview, locale: $locale, id: $id) {
      ...CallToActionAll
    }
  }
  ${ctaFragment}
`;
