import { createContext } from 'react';

interface AccordionContextProps {
  multipleOpen: boolean;
  openIds: string[];
  setOpenIds: (ids: string[]) => void;
}

export const AccordionContext = createContext<AccordionContextProps>({
  multipleOpen: false,
  openIds: [],
  setOpenIds: () => null,
});
