import { css, cx } from '@emotion/css';
import isNil from 'lodash/isNil.js';
import type { FC, MutableRefObject } from 'react';
import { useEffect, useRef } from 'react';

import { Video } from '../Video';
import type { VideoItem as VideoItemType } from './MultiVideoBlock';

interface VideoItemProps {
  hasVideoSync: boolean;
  isSelected: boolean;
  video: VideoItemType;
  currentTime: MutableRefObject<number>;
}

const visibleVideoCss = css`
  visibility: visible;
`;

const videoCss = css`
  position: absolute;
  visibility: hidden;
`;

export const VideoItem: FC<VideoItemProps> = ({ hasVideoSync, video, currentTime }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (isNil(currentTime) || !videoRef.current || !hasVideoSync) {
      return;
    }

    videoRef.current!.currentTime = currentTime.current;
    // Reason: currentTime will cause the video to jump
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [video]);

  useEffect(() => {
    const video = videoRef.current;

    if (!video || !hasVideoSync) {
      return;
    }

    const handleTimeUpdate: EventListener = event => {
      const video = event.target as HTMLVideoElement;
      currentTime.current = video.currentTime;
    };

    video!.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      video!.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [currentTime, video, hasVideoSync]);

  return (
    <Video
      className={cx(videoCss, { [visibleVideoCss]: true })}
      source={video.media?.url}
      posterSource={video.thumbnailMedia?.url}
      isBackgroundVideo
      sourceType={video.media?.contentType}
      ref={videoRef}
    />
  );
};
