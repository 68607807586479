import { css } from '@emotion/css';

import { nonMobileMediaQuery } from '../../../constants';
import { m } from '../../../motif/m';

export const navigatorItemFeaturedLinkCss = css`
  align-items: flex-start;
  background-color: ${m('--global-header-menu-featured-item-bg-color')};
  border-radius: ${m('--border-radius-m')};
  color: ${m('--global-header-menu-featured-item-fg-color')};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: ${m('--spacing-s')};
  padding: ${m('--spacing-m')};
  text-decoration: none;
  transition: background-color 0.2s linear;

  :hover {
    background-color: ${m('--global-header-menu-featured-item-bg-hover-color')};
  }
`;

export const navigatorItemFeaturedLinkTitleCss = css`
  && {
    font-size: ${m('--p2-desktop-font-size')};
    font-weight: 500;
    line-height: ${m('--p2-desktop-font-line-height')};
    margin: 0;
  }
`;

export const navigatorItemFeaturedLinkDescriptionCss = css`
  && {
    font-size: ${m('--p3-desktop-font-size')};
    font-weight: 400;
    line-height: ${m('--p3-desktop-font-line-height')};
    margin: 0;
  }
`;

export const navigatorItemFeaturedLinkCtaContainerCss = css`
  && {
    align-items: center;
    display: flex;
    gap: ${m('--spacing-xs')};
    justify-content: end;
    margin: auto 0 0 0;
    width: 100%;
  }
`;

export const navigatorItemFeaturedLinkCtaTextCss = css`
  && {
    font-size: ${m('--p3-desktop-font-size')};
    font-weight: 400;
    line-height: ${m('--p3-desktop-font-line-height')};
    margin: auto 0 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${nonMobileMediaQuery} {
      opacity: 0;
      transform: translateX(${m('--spacing-xs')});
      transition: opacity 0.2s linear, transform 0.2s ease-in-out;

      [dir='rtl'] & {
        transform: translateX(calc(${m('--spacing-xs')} * -1));
      }

      ${`.${navigatorItemFeaturedLinkCss}`}:hover &,
    ${`.${navigatorItemFeaturedLinkCss}`}:focus & {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
`;

export const navigatorItemFeaturedLinkCtaIconCss = css`
  [dir='rtl'] & {
    transform: scaleX(-1);
  }
`;
