/* eslint-disable max-len */
import type { FC } from 'react';

import type { BreakPatternProps } from './breakPatternTypes';

export const Skirt2Break: FC<BreakPatternProps> = props => {
  const { topColor, bottomColor, ...rest } = props;
  return (
    <svg
      width="1440"
      height="96"
      viewBox="0 23 1440 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      role="presentation"
      {...rest}
    >
      <path
        d="m0 0v68.9c36.61-9.2 77.66-17.6 124.24-24.74 186.4-28.54 307.39-13.77
        404.55-1.53 41.35 6.13 84.69 9.78 129.18 10.87 76-0.59 152.99-6.71 228.44-18.16
        58.45-8.07 104.48-13.96 144.95-7.75 14.85 2.55 28.07 6.61 38.89 11.94s19.03
        11.82 24.13 19.09c3.89 6.62 11.02 12.48 20.83 17.15 9.77 2.58 21.07 3.81 32.86
        3.58 10.9-0.11 23.03-0.41 36.02-0.7 47.34-1.22 158.46 2.61 197.59 7.05
        30.03 3.3984 50.44 8.2328 57.32 10.3h1v-96h-1440z"
        fill={topColor}
      />
      <path
        d="m1184.1 77.65c-12.99 0.29-25.12 0.59-36.02 0.7-11.79
        0.23-23.09-1-32.86-3.58-9.81-4.67-16.94-10.53-20.83-17.15-5.1-7.27-13.31-13.76-24.13-19.09s-24.04-9.39-38.89-11.94c-40.47-6.21-86.5-0.32-144.95
        7.75-75.45 11.45-152.44 17.57-228.44 18.16-44.49-1.09-87.83-4.74-129.18-10.87-97.16-12.24-218.15-27.01-404.55 1.53-46.58 7.14-87.63 15.54-124.24
        24.74v28.1h1440s-19.18-6.87-58.32-11.3c-39.13-4.44-150.25-8.27-197.59-7.05z"
        fill={bottomColor}
      />
    </svg>
  );
};
