import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import type { Document } from '@contentful/rich-text-types';

/** Helper function, renders the passed data to a plain text string. */
export const getPlainText = (data: Document | string | undefined): string => {
  if (typeof data === 'string') {
    return data;
  }

  if (!data || !data?.content) {
    return '';
  }

  return documentToPlainTextString(data);
};
