import { getDprSrcSetSettingsByHeight, useContentfulImages } from '@snapchat/mw-contentful-client';
import type { ImageSources } from '@snapchat/snap-design-system-marketing';
import { SplitBlock as SplitBlockSDS } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import { combineImageSources } from '../../utils/combineImageSources';
import { renderRichTextWithElements } from '../../utils/renderText/renderRichText';
import { CallToAction } from '../CallToAction';
import type { SplitBlockDataProps } from './SplitBlock.query';

export const SplitBlock: FC<SplitBlockDataProps> = props => {
  const { getImageSources } = useContentfulImages();

  const {
    splitBlockTitle: title,
    splitBlockSubtitle: subtitle,
    splitBlockBody: body,
    media,
    callsToActionCollection,
    splitBlockBody,
    ...restProps
  } = props;

  const desktopMedia = media.media;
  const mobileMedia = media.mobileMedia;
  const backgroundMediaType = media.__typename;

  let imgSrcs: ImageSources | undefined;
  let videoSources;

  if (backgroundMediaType === 'Image') {
    imgSrcs = combineImageSources({
      desktop: getImageSources(
        desktopMedia.url,
        getDprSrcSetSettingsByHeight(800, media.media.height ?? 0)
      ),
      mobile: getImageSources(
        mobileMedia?.url,
        getDprSrcSetSettingsByHeight(800, media.mobileMedia?.height ?? 0)
      ),
    });
  }

  if (backgroundMediaType === 'Video') {
    videoSources = {
      videoSource: desktopMedia.url,
      mobileVideoSource: mobileMedia?.url,
      mediaContentType: desktopMedia.contentType,
      mobileMediaContentType: mobileMedia?.contentType,
    };
  }

  const callsToAction = callsToActionCollection?.items?.map(item => (
    <CallToAction key={item.sys.id} {...item} />
  ));

  return (
    <SplitBlockSDS
      title={title}
      subtitle={subtitle}
      body={renderRichTextWithElements(body)}
      callsToAction={callsToAction?.length ? callsToAction : undefined}
      imageSources={imgSrcs}
      {...videoSources}
      {...restProps}
    />
  );
};
