import { MessageContext } from '@snapchat/snap-design-system-marketing';
import uniq from 'lodash-es/uniq';
import type { FC, PropsWithChildren } from 'react';
import { createContext, useCallback, useContext } from 'react';

import { AppContext } from '../../AppContext';
import { Config } from '../../config';
import { useContentfulQuery } from '../../hooks/useContentfulQuery';
import { initialCartState, useCart } from '../Cart/hooks';
import type {
  ContentfulShopifyProduct,
  ContentfulShopifyProductsResponse,
  ProductAvailability,
  ShopifyProduct,
  ShopifyProviderProps,
  ShopifyState,
} from '../Cart/types';
import { Feature, useFeatureFlags } from '../FeatureFlags';
import { contentfulProductsQuery } from './contentfulProductsQuery';

export const ShopifyContext = createContext<ShopifyState>({
  products: {
    shopify: {},
    contentful: {},
    extras: {
      bundleItems: [],
      accessories: [],
    },
  },
  cart: initialCartState,
});

export const ShopifyProvider: FC<PropsWithChildren<ShopifyProviderProps>> = ({
  children,
  shopifyData,
}) => {
  const {
    userLocation: { country },
  } = useContext(AppContext);
  const { formatMessage } = useContext(MessageContext);
  const featureFlags = useFeatureFlags();
  const products = {
    shopify: {},
    contentful: {},
    extras: {},
  };
  const cart = useCart();

  const { data: contentfulProductData } = useContentfulQuery<ContentfulShopifyProductsResponse>(
    contentfulProductsQuery,
    {
      skip: !Config.shopify,
      ssr: true,
    }
  );

  const calculateAtp = useCallback(
    ({ available, atp = 0 }: ProductAvailability): string | null => {
      if (atp === 0 && available) {
        return formatMessage({
          id: 'shopifyATPTodayFreeShipping',
          defaultMessage: 'Ships for free today',
        });
      }

      if (atp > 0 && available) {
        if (atp < 7) {
          if (atp === 1) {
            return formatMessage({
              id: 'shopifyATPDayFreeShipping',
              defaultMessage: 'Ships for free in 1 day',
            });
          }

          return formatMessage(
            { id: 'shopifyATPDaysFreeShipping', defaultMessage: 'Ships for free in {days} days' },
            { days: String(atp) }
          );
        }
        const bufferRemainder = atp % 7;
        const weeks = (atp - bufferRemainder) / 7;
        const weekRange = `${weeks}-${weeks + 1}`;

        if (weeks === 1) {
          return formatMessage({
            id: 'shopifyATPWeekFreeShipping',
            defaultMessage: 'Ships for free in 1 week',
          });
        }

        return formatMessage(
          {
            id: 'shopifyATPWeeksFreeShipping',
            defaultMessage: 'Ships for free in {weeks} weeks',
          },
          { weeks: weekRange }
        );
      }

      return null;
    },
    [formatMessage]
  );

  if (!Config.shopify) {
    return <>{children}</>;
  }

  if (shopifyData) {
    products.shopify = shopifyData.products.edges.reduce((acc, { node }) => {
      const variant = node.variants.edges[0]!;

      const product: ShopifyProduct = {
        image: node.featuredImage?.url,
        price: Number(node.priceRange.maxVariantPrice.amount ?? '0'),
        available: node.availableForSale,
        id: variant.node.id,
        nodeId: node.id,
        title: node.title,
        handle: node.handle,
        sku: variant.node.sku,
      };

      const atpDays = variant.node.metafields.find(field => field?.key === 'ATPBufferDays');
      const maxQuantity = variant.node.metafields.find(field => field?.key === 'maxQuantity');

      if (atpDays) {
        product.atpMessage = calculateAtp({
          available: product.available,
          atp: Number.parseInt(atpDays.value),
        });
      }

      if (maxQuantity) {
        product.maxQuantity = Number.parseInt(maxQuantity.value);
      }

      acc[node.handle] = product;
      return acc;
    }, {} as Record<string, ShopifyProduct>);
  }

  if (contentfulProductData) {
    products.contentful = contentfulProductData.shopifyProductCollection.items.reduce(
      (acc: Record<string, ContentfulShopifyProduct>, item) => {
        acc[item.productHandle] = item;
        return acc;
      },
      {}
    );

    const contentfulProductsFromCart = Object.keys(cart.lineItems).map(
      lineItemId => (products.contentful as Record<string, ContentfulShopifyProduct>)[lineItemId]
    );

    products.extras = {
      bundleItems: uniq(
        contentfulProductsFromCart.flatMap(product =>
          (product?.bundleItemsCollection?.items ?? []).map(({ productHandle }) => productHandle)
        )
      ),
      accessories: uniq(
        contentfulProductsFromCart.flatMap(product =>
          (product?.accessoriesCollection?.items ?? []).map(({ productHandle }) => productHandle)
        )
      ),
    };
  }

  const enableEuropeanStores = featureFlags[Feature.ENABLE_PIXY_EUROPEAN_STORES] === 'true';

  const isHiddenBehindFeatureFlag = !enableEuropeanStores && (country === 'GB' || country === 'FR');
  const shopifyStore = Config.shopify?.stores[country];
  const store =
    shopifyStore && !isHiddenBehindFeatureFlag ? shopifyStore[Config.deploymentType] : undefined;

  return (
    <ShopifyContext.Provider
      value={{
        store,
        products,
        cart,
        currencyCode: shopifyData?.shop.paymentSettings.currencyCode,
      }}
    >
      {children}
    </ShopifyContext.Provider>
  );
};
