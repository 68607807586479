import { css } from '@emotion/css';
import { m, nonMobileMediaQuery } from '@snapchat/snap-design-system-marketing';

/**
 * Helps center logos vertically by ensuring wrapping <picture/> element is the same height as the
 * rendered image
 */
export const socialPictureCss = css`
  display: flex;
`;

export const socialAnchorCss = css`
  display: flex;
  align-items: center;
`;

export const logoWrapperCss = css`
  padding-block-end: ${m('--spacing-xs')};

  ${nonMobileMediaQuery} {
    padding-block-end: 0;
  }
`;
