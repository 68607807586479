import { css, cx } from '@emotion/css';
import { curveCardinal, curveLinear, curveStep } from '@visx/curve';
import { LegendOrdinal } from '@visx/legend';
import { DataContext, DataProvider, Grid, LineSeries, XYChart } from '@visx/xychart';
import { useContext } from 'react';

import { MotifComponent, useMotifStyles } from '../../../motif';
import { DefaultAxis, DefaultTooltip } from '../defaultComponents';
import { legendCss } from '../defaultComponents/styles';
import type { LineChartAccessors } from '../types';

type Curve = 'linear' | 'step' | 'cardinal';

const titleCss = css`
  text-align: center;
  margin-bottom: 0;
`;

const ChartLegend = () => {
  const { colorScale, margin } = useContext(DataContext);

  return (
    <LegendOrdinal
      direction="row"
      itemMargin="8px 8px 8px 0"
      scale={colorScale!}
      shape="line"
      style={{
        paddingLeft: margin?.left,
      }}
      className={legendCss}
    />
  );
};

interface ILineChart<Datum extends object> {
  accessors: LineChartAccessors<Datum, string | number, string | number>;
  chartTitle?: string;
  curve?: Curve;
  data: Datum[];
  height?: number;
  width?: number;
  xLabel?: string;
  yKeys: string[];
  yLabel?: string;
  isLoading?: boolean;
}

export function LineChart<Datum extends object>({
  accessors,
  chartTitle,
  curve = 'linear',
  data,
  height = 500,
  width,
  xLabel,
  yKeys,
  yLabel,
}: ILineChart<Datum>): JSX.Element {
  useMotifStyles(MotifComponent.LINE_CHART);

  const curveObj =
    (curve === 'cardinal' && curveCardinal) || (curve === 'step' && curveStep) || curveLinear;

  const realLines = (
    <>
      {yKeys.map(key => {
        return (
          <LineSeries
            key={key}
            dataKey={key}
            data={data}
            xAccessor={accessors.x[key]!}
            yAccessor={accessors.y[key]!}
            curve={curveObj}
          />
        );
      })}
    </>
  );

  return (
    <section className={cx(MotifComponent.LINE_CHART)}>
      <DataProvider
        xScale={{ type: 'band', paddingInner: 0.5 }}
        yScale={{ type: 'linear', nice: true }}
      >
        <section data-testid="lineChart">
          {chartTitle ? <h2 className={titleCss}>{chartTitle}</h2> : null}
          <ChartLegend />
          <XYChart height={height} width={width}>
            <DefaultAxis xLabel={xLabel} yLabel={yLabel} />
            {/* @ts-ignore: Fix this type later. Complains that object isn't valid as ? extends object. */}
            <DefaultTooltip accessors={accessors} />
            <Grid columns={false} left={70} />
            {realLines}
          </XYChart>
        </section>
      </DataProvider>
    </section>
  );
}
