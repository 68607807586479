import type { FC } from 'react';
import { useContext } from 'react';
import { Helmet } from 'react-helmet-async';

import { AppContext } from '../../AppContext';
import { directionFromLocale } from './languageDirection';

export const DirectionDirective: FC = () => {
  const appContext = useContext(AppContext);
  return (
    <Helmet>
      {/* biome-ignore lint/a11y/useHtmlLang: This is helment. Not complete. */}
      <html dir={directionFromLocale(appContext?.currentLocale)} />
    </Helmet>
  );
};

DirectionDirective.displayName = 'DirectionDirective';
