interface IsPopUpEligibleResponse {
  popupEnabled: boolean;
}

// TODO: Switch to 'https://web-platform.snap.com/cookies/is_cookie_popup_eligible'
const cookiesApiPopupEligibleEndpoint =
  'https://www.snapchat.com/cookies/api/is_cookie_popup_eligible';

/**
 * Helper function that calls relevant API endpoint to determine whether the Modal should be
 * displayed
 *
 * @returns Boolean, True if client device is in a location where Modal should display
 */
export const fetchShouldDisplayModal = async (): Promise<boolean> => {
  const response = await fetch(cookiesApiPopupEligibleEndpoint);

  if (response.status !== 200) {
    const { status, statusText } = response;
    throw new Error(`${status} : ${statusText}`);
  }
  const data: IsPopUpEligibleResponse = await response.json();
  return data.popupEnabled;
};
