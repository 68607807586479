import type { Entry } from '@snapchat/mw-contentful-schema';

import { Config } from '../../config';

type GetContentfulInspectorPropsArgs<T> = {
  entryId: string;
  locale?: string;
  fieldIds: (keyof T)[];
};

// These correspond to data-* attributes for inspector mode
type InspectorProps<T> = {
  contentfulFieldId: T;
  contentfulEntryId: string;
  contentfulLocale?: string;
};

/**
 * Field ids are generally 1:1 with the graphql schema types except for collections. The graphql
 * schema has a 'Collection' suffix, while the Content Model's fieldId does not. e.g.
 * "callsToActionCollection" vs 'callsToAction'. Collections refer to a collection of other
 * entries.
 *
 * We have a practice of not tagging reference fields with inspector tags, but sometimes it may make
 * sense to edit them as a group, e.g. a collection of small icons where the inspector box for each
 * icon would get too clumped together. But that's a rare case.
 */
type RemoveCollectionSuffix<T> = T extends `${infer Field}Collection` ? Field : T;

type WithDatasetSuffix<T extends string> = `${T}Dataset`;

type StringKeys<T> = Extract<keyof T, string>;

// This removes the 'Collection' suffix
type RekeyedContentType<ContentType> = {
  [Fields in StringKeys<ContentType> as RemoveCollectionSuffix<Fields>]: ContentType[Fields];
};

// This adds the 'Dataset' suffix
type InspectorDataset<RemappedContentType> = {
  [Fields in StringKeys<RemappedContentType> as WithDatasetSuffix<Fields>]: RemappedContentType[Fields];
};

type InspectorPropsMap<SuffixedT extends string, T> = Partial<Record<SuffixedT, InspectorProps<T>>>;

type InspectorPropsReturnType<T extends Entry> = InspectorPropsMap<
  keyof InspectorDataset<RekeyedContentType<T>>,
  keyof RekeyedContentType<T>
>;

/**
 * Helper function to generate Contentful inspector props.
 *
 * If you are getting type errors in fieldIds, make sure you are passing in a Contentful type as a
 * type parameter.
 *
 * @param entryId: The Contentful entry id that these fields belong to
 * @param fieldIds: A list of field ids corresponding those in the entry Content Model.
 * @param locale: The locale to preview in. Use it to override the initial locale if needed.
 * @returns
 */
export function getContentfulInspectorProps<T extends Entry>({
  entryId,
  fieldIds,
  locale,
}: GetContentfulInspectorPropsArgs<RekeyedContentType<T>>): InspectorPropsReturnType<T> {
  if (Config.isDeploymentTypeProd) {
    return {};
  }

  const map: InspectorPropsReturnType<T> = {};

  fieldIds.forEach(id => {
    const mapId = `${String(id)}Dataset` as keyof InspectorDataset<RekeyedContentType<T>>;

    map[mapId] = {
      contentfulEntryId: entryId,
      contentfulFieldId: id,
      contentfulLocale: locale,
    };
  });

  return map;
}
