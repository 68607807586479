import type {
  ContentfulIdProps,
  ContentfulIdsProps,
  ContentfulSysProps,
} from '../types/contentful';

export const isContentfulIdProps = <T>(
  props: T | ContentfulIdProps
): props is ContentfulIdProps => {
  return !!(props as ContentfulIdProps).id;
};

export const isContentfulSysProps = <T>(
  props: T | ContentfulSysProps
): props is ContentfulSysProps => {
  return (props as ContentfulSysProps).sys !== undefined;
};

export const isContentfulIdsProps = <T>(
  props: T | ContentfulIdsProps
): props is ContentfulIdsProps => {
  const propsAs = props as ContentfulIdsProps;
  return Array.isArray(propsAs.ids);
};

/** Returns whether the props contains any keys besides the shallow copy ones. */
export const isContentfulShallow = (
  props: ContentfulIdProps | ContentfulSysProps | ContentfulIdsProps
): boolean => {
  const allKeys = new Set(Object.keys(props));
  ['__typename', 'id', 'ids', 'sys'].forEach(key => allKeys.delete(key));
  return allKeys.size === 0;
};

/** Returns whether props are not shallow. */
export const isContentfulSysNotShallow = <D extends ContentfulSysProps>(
  props: ContentfulSysProps
): props is D => {
  const allKeys = new Set(Object.keys(props));
  ['__typename', 'sys'].forEach(key => allKeys.delete(key));
  return allKeys.size > 0;
};
