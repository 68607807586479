import { css } from '@emotion/css';

import { mobileMediaQuery, smallMobileMediaQuery } from '../../constants';
import { ZIndex } from '../../constants/zIndex';
import { m } from '../../motif/m';

export const buttonClassName = 'button';

const baseStyleCss = css`
  border-width: ${m('--button-border-width')};
  border-style: solid;
  border-radius: ${m('--button-border-radius')};
  cursor: pointer;
  /* Font-family on buttons defaults to Arial, so need to re-confirm. */
  font-family: ${m('--font-family')};
  font-size: ${m('--button-desktop-font-size')};
  font-weight: ${m('--button-desktop-font-weight')};
  letter-spacing: ${m('--button-desktop-font-letter-spacing')};
  line-height: ${m('--button-desktop-font-line-height')};
  text-decoration: none;
  transition: transform 150ms ease-in-out;
  white-space: nowrap;
  z-index: ${ZIndex.BUTTON};

  /* Preventing button from stretching containers.
     Important for mobile and extremely long text. */
  max-width: 100%;

  ${mobileMediaQuery} {
    font-size: ${m('--button-mobile-font-size')};
    font-weight: ${m('--button-mobile-font-weight')};
    letter-spacing: ${m('--button-mobile-font-letter-spacing')};
    line-height: ${m('--button-mobile-font-line-height')};
  }

  /* Align icon and text */
  display: inline-flex;
  align-items: center;

  ${smallMobileMediaQuery} {
    justify-content: center;
    width: 100%;
  }

  :disabled {
    cursor: not-allowed;
    :not(.button-loading) {
      opacity: 70%;
    }
  }

  *[dir='rtl'] & {
    margin-left: ${m('--spacing-xxs')};
  }

  box-shadow: none;

  &:hover:not([disabled]) {
    box-shadow: ${m('--button-hover-shadow')};
    transform: translateY(-2px);
  }
  &:active:not([disabled]) {
    box-shadow: ${m('--button-active-shadow')};
    transform: translateY(-1px);
  }
`;

// TODO: Split these out into separate styles and conditionally apply them.
/**
 * Size of the buttons is defined by the padding around the text.
 *
 * Note that we subtract 1px from these to allow 1px for the border.
 */
const sizeStylesCss = css`
  &.button-compact {
    padding: ${m('--button-compact-padding')};
  }
  &.button-regular {
    padding: ${m('--button-regular-padding')};
  }
  &.button-large {
    padding: calc(${m('--spacing-s')} - 1px) calc(${m('--spacing-xxxl')} - 1px);
  }
  &.button-flat {
    padding: 0;
  }
`;

// TODO: Split these out into separate styles and conditionally apply them.
const colorStylesCss = css`
  &.button-primary {
    background-color: ${m('--button-primary-bg-color')};
    color: ${m('--button-primary-fg-color')};
    border-color: ${m('--button-primary-border-color')};
  }

  &.button-primary:hover {
    background-color: ${m('--button-primary-hover-bg-color')};
    color: ${m('--button-primary-fg-color')};
    border-color: ${m('--button-primary-hover-border-color')};
  }

  &.button-secondary {
    background-color: ${m('--button-secondary-bg-color')};
    color: ${m('--button-secondary-fg-color')};
    border-color: ${m('--button-secondary-border-color')};
  }

  &.button-secondary:hover {
    background-color: ${m('--button-secondary-hover-bg-color')};
    color: ${m('--button-secondary-fg-color')};
    border-color: ${m('--button-secondary-hover-border-color')};
  }

  &.button-flat,
  &.button-flat:hover {
    background-color: transparent;
    color: ${m('--button-flat-fg-color')};
    border-width: 0px;
    border-color: transparent;
    box-shadow: none;
    transform: none;
  }
`;

const spacingCss = css`
  & > *:not(:last-child) {
    margin-right: ${m('--spacing-xs')};
  }
  /* Spacing between items in the button */
  *[dir='rtl'] & > *:not(:last-child) {
    margin-right: unset;
    margin-left: ${m('--spacing-xs')};
  }
`;

export const buttonCss = css`
  ${baseStyleCss}
  ${sizeStylesCss}
  ${colorStylesCss}
  ${spacingCss}

  & > picture > img {
    height: 24px;
    display: block;
  }

  & > i {
    font-size: 22px;
    line-height: 18px;
  }
`;

/** Hides text that would otherwise overflow Only really happens for long text on mobile buttons. */
export const childrenContainerCss = css`
  overflow: hidden;
  text-overflow: ellipsis;

  ${smallMobileMediaQuery} {
    text-align: center;
  }
`;

export const primarySpinnerButtonCss = css`
  &::before,
  &::after {
    border-color: ${m('--button-primary-fg-color')};
  }
`;

export const secondarySpinnerButtonCss = css`
  &::before,
  &::after {
    border-color: ${m('--button-secondary-fg-color')};
  }
`;
export const flatSpinnerButtonCss = css`
  &::before,
  &::after {
    border-color: ${m('--button-flat-fg-color')};
  }
`;

/** Makes the loading spinner appear in the margin on the left. Double-specificity for RTL styles. */
export const inlineSpinnerRegularCss = css`
  [dir='ltr'] &&& {
    margin-left: -16px;
  }
  [dir='rtl'] &&& {
    margin-right: -16px;
  }
`;

/** Makes the loading spinner appear in the margin on the left */
export const inlineSpinnerLargeCss = css`
  [dir='ltr'] &&& {
    margin-left: -24px;
  }
  [dir='rtl'] &&& {
    margin-right: -24px;
  }
`;
