import { css } from '@emotion/css';

import { Spacing } from '../../constants';

export const categoryCss = css`
  display: block;
  font-size: 15px;
  font-weight: 700;
  line-height: ${Spacing.XL}px;
`;
