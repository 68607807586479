import { css } from '@emotion/css';

import { mobileMediaQuery, nonMobileMediaQuery } from '../../../constants';
import { m, p3Css } from '../../../motif';

const arrowIconSize = '24px';
export const maxTextWidth = 700;

export const linkCss = css`
  color: ${m('--carousel-card-fg-color')};
  cursor: pointer;
  text-decoration: none;

  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  ${mobileMediaQuery} {
    padding: ${m('--spacing-l')};
  }
`;

export const contentCss = css`
  background: ${m('--carousel-card-bg-color')};
  border-radius: ${m('--border-radius-l')};
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.12);
  color: ${m('--carousel-card-fg-color')};
  display: flex;
  flex-direction: column;
  max-width: ${maxTextWidth}px;
  padding: ${m('--spacing-xl')};
  position: relative;
  user-select: none;
  width: 100%;

  ${mobileMediaQuery} {
    margin-inline: ${m('--spacing-xl')};
  }

  ${nonMobileMediaQuery} {
    padding: ${m('--spacing-xl')} ${m('--spacing-xxxl')};
  }
`;

export const descriptionCss = css`
  margin: 0;
`;

export const subTextCss = css`
  ${p3Css}
  margin-block: ${m('--spacing-m')} 0;
`;

export const arrowCss = css`
  fill: ${m('--action-default-color')};
  height: ${arrowIconSize};
  width: ${arrowIconSize};
  flex-shrink: 0;

  [dir='rtl'] & {
    transform: rotate(180deg);
    right: auto;
  }
`;
