/* eslint-disable import/no-unused-modules */
import { parseError } from '@snapchat/core';
import type {
  BaseEventListenerProps,
  BasePermission,
  EmptyObject,
  EventPayload,
  LoggedEvent,
} from '@snapchat/logging';
import { AbstractEventListener } from '@snapchat/logging';

import { customFetch } from '../utils/fetch/customFetch';

const mwpAuthTokenHeader = 'X-Snap-Mwp-Auth';

type Props<LoggingContext, CustomEvents> = {
  /** Interval in ms the events are exported */
  logTimeIntervalMs?: number;
  /** Actions to take when an error has been encountered exporting */
  onError?: (error: Error) => void;
  /** Function to use to perform post request */
  fetch?: typeof customFetch;
  /** Function to provide the current time in ms. Defaults to Date.now */
  clock?: () => number;
} & BaseEventListenerProps<LoggingContext, CustomEvents>;

/**
 * Event listener to export client events to /api/events. Includes logic to regularly sync the
 * events when they are needed.
 */
export class ClientExportEventListener<
  LoggingContext extends EmptyObject = EmptyObject,
  CustomEvents extends EmptyObject = EmptyObject,
  LoggingPermission extends BasePermission = BasePermission
> extends AbstractEventListener<LoggingContext, CustomEvents, LoggingPermission> {
  private readonly props: Props<LoggingContext, CustomEvents>;

  private events: EventPayload<CustomEvents, LoggingContext>[];
  private timeout?: ReturnType<typeof setTimeout>;
  private fetch: typeof customFetch;
  private readonly clock: () => number;

  // TODO: refactor to extract out syncing logic into a reusable class

  constructor(props: Props<LoggingContext, CustomEvents>) {
    super({ requiredPermissions: ['logging'], filter: props.filter });
    this.props = props;
    this.fetch = props.fetch ?? customFetch;
    this.events = [];
    this.clock = props.clock ?? Date.now;
  }

  /** Setup a timeout to trigger an export */
  private ensureTimeout(): void {
    if (this.timeout !== undefined) return;

    this.timeout = setTimeout(this.exportEvents, this.props.logTimeIntervalMs);
  }

  /** Export events to /api/events, including generating authentication token. */
  private exportEvents = async (): Promise<void> => {
    // clear timeout, we only set it again if we exported or get new events
    this.timeout = undefined;

    if (this.events.length === 0) {
      return;
    }

    const data = { events: this.getSerializedEvents(), timestamp: this.clock() };
    this.events = [];

    try {
      // Hash a subset of the payload
      const tokenData = { events: [data.events[0]], timestamp: data.timestamp };
      const hash = await hashData(tokenData);
      // Base64 encode it
      const authToken = window.btoa(hash);

      const response = await this.fetch('/api/events', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          [mwpAuthTokenHeader]: authToken,
          'Content-Type': 'application/json',
        },
      });

      // if response is not good, stop loop
      if (!response.ok) {
        const text = await response.text();
        throw new Error(
          `Events export request failed - status: ${response.status}, value:${text} `
        );
      }
    } catch (error) {
      this.props.onError?.(parseError(error));
    }

    this.ensureTimeout();
  };

  //==================================================
  // Event serialization
  //==================================================

  /**
   * Extracts the message from the Error object values.
   *
   * This is required because JSON.stringify(error) is always '{}' since error fields aren't
   * enumerable.
   *
   * This way we at least send the error message to the server.
   *
   * The extraction of the message typically happens when the error is logged, but we make an
   * exception here so that we don't lose the data.
   */
  getSerializedEvents = (): typeof this.events => {
    return this.events.map(payload => {
      if (payload.event.type !== 'error') {
        return payload;
      }

      const event = payload.event;

      if (event.message || !event.error) {
        return payload;
      }

      event.message = parseError(event.error).message;
      return payload;
    });
  };

  //==================================================
  // Event Listener overrides
  //==================================================

  /** @override */
  protected init = (): Promise<void> => {
    return Promise.resolve();
  };

  /** @override */
  protected logEventInternal(
    event: LoggedEvent<CustomEvents>,
    context: Partial<LoggingContext>
  ): void {
    // we just collect events to push later on
    this.events.push({ event, context: context ?? {} });

    this.ensureTimeout();
  }

  /** @override */
  public flushInternal = async (): Promise<void> => {
    if (this.timeout) clearTimeout(this.timeout);

    await this.exportEvents();
  };
}

async function hashData(input: string | object): Promise<string> {
  // Convert the input to a string representation
  const dataStr = typeof input === 'string' ? input : JSON.stringify(input);
  const encoder = new TextEncoder();
  const data = encoder.encode(dataStr);

  const hash = await window.crypto.subtle.digest('SHA-256', data); // Hash the bytes

  // Convert the hash from bytes to a hex string
  return Array.from(new Uint8Array(hash))
    .map(byte => byte.toString(16).padStart(2, '0'))
    .join('');
}
