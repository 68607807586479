import { gql } from '@apollo/client';

import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';
import { fragments as videoFragments } from '../Video/query';

export const fragments = {
  all: gql`
    fragment MultiVideoBlockAll on MultiVideoBlock {
      ...ContentfulSysId
      anchorId
      panelPosition
      primaryVideosCollection(limit: 10) {
        items {
          ... on Video {
            ...VideoAll
          }
        }
      }
      secondaryVideosCollection(limit: 10) {
        items {
          ... on Video {
            ...VideoAll
          }
        }
      }
      mobilePrimaryVideosCollection(limit: 10) {
        items {
          ... on Video {
            ...VideoAll
          }
        }
      }
      mobileSecondaryVideosCollection(limit: 10) {
        items {
          ... on Video {
            ...VideoAll
          }
        }
      }
    }
    ${videoFragments.all}
    ${contentfulSysIdFragment}
  `,
};

export const query = gql`
  query MultiVideoBlockQuery($preview: Boolean!, $locale: String!, $id: String!) {
    multiVideoBlock(preview: $preview, locale: $locale, id: $id) {
      ...MultiVideoBlockAll
    }
  }
  ${fragments.all}
`;
