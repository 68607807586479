import { cx } from '@emotion/css';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { LinkedinIcon, LinkedinShareButton, TwitterIcon, TwitterShareButton } from 'react-share';

import { MotifComponent, useMotifStyles } from '../../motif';
import type { BaseComponentProps } from '../../types';

interface SocialProps extends BaseComponentProps {
  title: string;
}

export const Social: FC<SocialProps> = ({ title, ...props }) => {
  useMotifStyles(MotifComponent.SOCIAL);
  const [shareUrl, setShareUrl] = useState<string>('');
  useMotifStyles(MotifComponent.SOCIAL);

  useEffect(() => {
    setShareUrl(window.location.origin + window.location.pathname);
  }, []);

  return (
    <article
      {...props}
      className={cx(MotifComponent.SOCIAL, props.className)}
      data-test-id="sdsm-social"
    >
      <LinkedinShareButton title={title} url={shareUrl}>
        <LinkedinIcon size={32} round />
      </LinkedinShareButton>
      <TwitterShareButton title={title} url={shareUrl}>
        <TwitterIcon size={32} round />
      </TwitterShareButton>
    </article>
  );
};

Social.displayName = 'Social';
