import { createContext } from 'react';

import type { MediaMode } from '../../constants';

interface FooterContextProps {
  mode?: MediaMode;
  expandedItem?: string;
  setExpandedItem?: (id?: string) => void;
}

export const FooterContext = createContext<FooterContextProps>({});
