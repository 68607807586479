import type { BackgroundColor } from '../constants';
import type { MotifScheme } from '../motif';
import { getMotifSchemeForLegacyBackgroundColor } from '../motif';

/**
 * Class name within which theme variables will be applied.
 *
 * Themes are toggled based on the background color.
 *
 * @deprecated Use `MotifScheme` directly instead.
 */
export const getBackgroundClassName = (
  backgroundColor?: BackgroundColor | MotifScheme
): string | undefined =>
  backgroundColor ? getMotifSchemeForLegacyBackgroundColor(backgroundColor) : undefined;
