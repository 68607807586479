import { Black, Gray, Yellow } from '../../constants';
import type { MotifComponent, MotifComponentProps } from '../../motif';
import { m } from '../../motif/m';

const subNavigationDefaultVars = {
  '--sub-navigation-item-color': m('--action-default-color'),
  '--sub-navigation-item-hover-color': m('--action-hover-color'),
  '--sub-navigation-item-active-color': m('--action-active-color'),
  '--sub-navigation-item-hover-decoration-color': Gray.V250,
};

export const subNavigationBlackBgVars: MotifComponentProps<MotifComponent.SUB_NAVIGATION> = {
  ...subNavigationDefaultVars,
  '--sub-navigation-item-active-decoration-color': Yellow.V100,
};

export const subNavigationYellowBgVars: MotifComponentProps<MotifComponent.SUB_NAVIGATION> = {
  ...subNavigationDefaultVars,
  '--sub-navigation-item-active-decoration-color': Black.V200,
};

export const subNavigationGrayBgVars: MotifComponentProps<MotifComponent.SUB_NAVIGATION> = {
  ...subNavigationDefaultVars,
  '--sub-navigation-item-active-decoration-color': Black.V200,
};

export const subNavigationWhiteBgVars: MotifComponentProps<MotifComponent.SUB_NAVIGATION> = {
  ...subNavigationDefaultVars,
  '--sub-navigation-item-active-decoration-color': Black.V200,
};
