import { useContext, useEffect, useState } from 'react';

import { BrowserFeaturesContext } from '../BrowserFeatures';
import { MediaMode, mobileMaxWidth } from '../constants';
import { useWindowSize } from '../utils';

/** Hook that returns whether the current rendering media mode. */
export function useMediaMode(): MediaMode {
  const { getLowEntropyHints, getCachedHighEntropyHints } = useContext(BrowserFeaturesContext);
  const requestedIsMobile = getLowEntropyHints().isMobile;
  const initialClientWidth = getCachedHighEntropyHints()?.viewportWidth;
  const defaultIsMobile = initialClientWidth
    ? initialClientWidth <= mobileMaxWidth
    : requestedIsMobile;

  const [mode, setMode] = useState<MediaMode>(
    defaultIsMobile ? MediaMode.Mobile : MediaMode.Desktop
  );
  const { width: windowWidth } = useWindowSize();

  useEffect(() => {
    const isMobile = (windowWidth ?? Number.POSITIVE_INFINITY) <= mobileMaxWidth;
    setMode(isMobile ? MediaMode.Mobile : MediaMode.Desktop);
  }, [windowWidth]);

  return mode;
}
