import { gql } from '@apollo/client';

import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';

const messageFragment = gql`
  fragment MessageAll on Message {
    ...ContentfulSysId
    key
    value
  }
  ${contentfulSysIdFragment}
`;

export const intlProviderQuery = gql`
  query IntlMessages($preview: Boolean!, $locale: String!) {
    messageCollection(preview: $preview, locale: $locale) {
      items {
        ...MessageAll
      }
    }
  }
  ${messageFragment}
`;
