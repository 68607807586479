export type HighlightCardData = {
  title: string;
  amount: number;
  locale?: string;
};

export enum VisualizationKey {
  BAR_CHART = 'BarChart',
  GEO_MAP = 'GeoVisualization',
  LINE_CHART = 'LineChart',
  TABLE = 'TableVisualization',
}

export type VisualizationMeta = {
  id: string;
  key: VisualizationKey;
  title?: string;
};

export type ChartMetadata = {
  id: string;
  label: string;
};
