import type { FC } from 'react';
import { useContext } from 'react';
import { __RouterContext } from 'react-router';

import { useExperiment } from '../../components/Experiment';
import type { PageShallowDataProps } from '../../components/Page';
import { PageShallow } from '../../components/Page/PageShallow';
import { SlugContext } from '../../components/Slug/SlugContext';
import { useContentfulQuery } from '../../hooks/useContentfulQuery';
import { NoMatchDefault } from './NoMatchDefault';
import type { Custom404CollectionData } from './query';
import { custom404CollectionQuery } from './query';

export const NoMatch: FC = () => {
  // For SSR we should set the status code to 404 so it can still gives
  // an http error code. Based on https://v5.reactrouter.com/web/guides/server-rendering/404-401-or-any-other-status
  const { staticContext } = useContext(__RouterContext);
  const { data } = useContentfulQuery<Custom404CollectionData>(custom404CollectionQuery);
  const { decideExperiment } = useExperiment();

  if (staticContext) {
    staticContext.statusCode = 404;
  }

  if (!data) {
    return null; // Loading
  }

  const custom404OrExperiment = data.slugCollection.items?.[0]?.page;

  if (!custom404OrExperiment) {
    return <NoMatchDefault />;
  }

  if (custom404OrExperiment.__typename === 'Page') {
    return (
      <section data-testid="mwp-404-page">
        <PageShallow {...custom404OrExperiment} />
      </section>
    );
  }

  const experimentResult = decideExperiment<PageShallowDataProps>(custom404OrExperiment, {
    logImpression: true,
  });

  return (
    <SlugContext.Provider
      value={{
        isShareable: false,
        replacements: experimentResult.replacements,
      }}
    >
      <section data-testid="mwp-404-page">
        <PageShallow {...experimentResult.decision} />
      </section>
    </SlugContext.Provider>
  );
};

NoMatch.displayName = 'NoMatch';
