import type { ChangeEvent, FC } from 'react';
import { memo, useCallback, useContext, useEffect } from 'react';

import { FormContext } from '../Form';
import { Field } from '../Form/Field';
import { FormEventType } from '../Form/Form.types';
import { InputRadio } from './InputRadio';
import { radioSelectOptionsWrapperCss } from './styles';
import type { RadioSelectFieldProps } from './types';

/**
 * A radio select field component.
 *
 * This component is used to render a group of radio buttons. It is a controlled component, meaning
 * that the value of the field is controlled by the parent component. The parent component is
 * responsible for providing the `options` and `onChange` props.
 */

export const RadioSelectField: FC<RadioSelectFieldProps> = memo(props => {
  const {
    error,
    helpText,
    initialValue,
    label,
    name,
    options = [],
    required = false,
    shouldResetToInitial = false,
  } = props;

  const { state, dispatch } = useContext(FormContext);

  useEffect(() => {
    dispatch({
      type: FormEventType.REGISTER_FIELD,
      name,
      field: {
        required,
        initialValue,
        shouldResetToInitial,
      },
    });
  }, [dispatch, initialValue, name, required, shouldResetToInitial]);

  const fieldMeta = state.fields[name];

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: FormEventType.CHANGE_FIELD_VALUE,
      name,
      value: e.target.value,
    });
  };

  const handleInvalid = useCallback(() => {
    dispatch({ type: FormEventType.INVALIDATE_FIELD, name });
  }, [dispatch, name]);

  if (options.length === 0) return null;

  return (
    <Field
      type="Radio"
      name={name}
      label={label}
      helpText={helpText}
      error={error}
      hasError={fieldMeta?.hasError}
    >
      <div className={radioSelectOptionsWrapperCss}>
        {options.map(({ key, value }) => (
          <InputRadio
            key={key}
            id={key}
            name={name}
            value={value ?? key}
            label={value ?? key}
            required={required}
            hasError={fieldMeta?.hasError}
            handleChange={handleChange}
            handleInvalid={handleInvalid}
          />
        ))}
      </div>
    </Field>
  );
});
