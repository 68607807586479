import { css } from '@emotion/css';

import { nonTwoColumnCollapseMediaQuery, White } from '../../../constants';
import { m } from '../../../motif';

export const skeletonWrapperCss = css`
  background-color: ${White};
  border-radius: ${m('--border-radius-xl')};
  display: flex;
  justify-content: center;
  padding: 20px;

  ${nonTwoColumnCollapseMediaQuery} {
    padding: ${m('--spacing-xxl')};
  }
`;

export const skeletonChartCss = css`
  background-color: ${m('--chart-skeleton-bg-color')};
  border-radius: ${m('--border-radius-xl')};
  overflow: hidden;
  position: relative;
  width: 100%;

  ${nonTwoColumnCollapseMediaQuery} {
    margin: 0px 112px;
  }
`;

export const skeletonSvgCss = css`
  height: 100%;
  width: 100%;
`;

export const skeletonSvgBackgroundCss = css`
  fill: url('#shimmerGradient');
  mask: url('#shimmerMask');
`;

export const skeletonSvgMaskCss = css`
  fill: ${m('--chart-skeleton-mask-color')};
`;

export const skeletonSvgGradientDarkColorCss = css`
  stop-color: ${m('--chart-skeleton-dark-animation-color')};
`;

export const skeletonSvgGradientLightColorCss = css`
  stop-color: ${m('--chart-skeleton-light-animation-color')};
`;
