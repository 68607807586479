import { gql } from '@apollo/client';

import type { ContentfulSysProps } from '../../../types/contentful';
import type { Items } from '../../../types/Items';
import { contentfulSysIdFragment } from '../../../utils/contentful/contentfulQuery';
import { fragments as analyticsFragments } from '../../Analytics/query';
import type { AnalyticsDataProps } from '../../Analytics/types';
import type { ContentDataProps } from '../../Content';

export const ChartToggleSize = {
  Large: 'Large',
  Small: 'Small',
} as const;

export interface ChartToggleDataHandlerProps {
  chartToggle: ChartToggleDataProps;
}

export interface ChartToggleItemDataProps extends ContentfulSysProps {
  analytics?: AnalyticsDataProps;
  title: string;
  contentsCollection: Items<ContentDataProps>;
}

export interface ChartToggleDataProps extends ContentfulSysProps {
  hasLinks: boolean;
  itemsCollection: Items<ChartToggleItemDataProps>;
  label: string;
}

export const chartToggleQuery = gql`
  query ChartToggleQuery($preview: Boolean!, $locale: String!, $id: String!) {
    chartToggle(preview: $preview, locale: $locale, id: $id) {
      ...ContentfulSysId
      label
      itemsCollection(limit: 10) {
        items {
          ...ContentfulSysId
          analytics {
            ...AnalyticsAll
          }
          title
          contentsCollection(limit: 10) {
            items {
              ... on Entry {
                ...ContentfulSysId
              }
            }
            total
          }
        }
      }
    }
  }
  ${contentfulSysIdFragment}
  ${analyticsFragments.all}
`;
